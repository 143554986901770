import React,{useState,useReducer} from "react";
import { MenuItem, Menu } from "@material-ui/core";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import CloseIcon from '@material-ui/icons/Close';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PaymentIcon from '@material-ui/icons/Payment';
import GetAppIcon from '@material-ui/icons/GetApp';
import {generateBulletin,genFicheDemandeExamen} from 'application'
import {getQuestionnaires} from 'redux/actions/apiActions'
import Divider from '@material-ui/core/Divider';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import ModalRapportMedecin from './ModalRapportMedecin'
import ModalMiseEnPlacePret from './ModalMiseEnPlacePret'
import ModalRachatPret from './ModalRachatPret'
import DialogBox from './DialogBox'
import ImportDocument from 'pages/Banking/Propositions/ImportDocument'
import ImportDocumentList from 'pages/Banking/Propositions/ImportDocumentList'
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import EditIcon from '@material-ui/icons/Edit';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import {change,registerField} from 'redux-form';
import {selectPretByUser,getDetailPret} from 'redux/actions/apiActions'
import {connect,useDispatch} from 'react-redux'
import { useHistory } from 'react-router-dom';
import StopIcon from '@material-ui/icons/Stop';
import ListIcon from '@material-ui/icons/List';
 function CustomMenu(props){
  const dispatch = useDispatch()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const [ropen, setRopen] = useState(false)
  const handleRopen =(etat)=>{
    setRopen(etat)
  }
  
  const [openi,setOpeni] = React.useState(false)
  const handleOpeni= ()=>{
    setOpeni(true)
  }
  const handleClosei= ()=>{
    setOpeni(false)
  }

  const modifierPret=(idPret)=>{
    getDetailPret(idPret).then(datapret=>{
      if(datapret){
       
        const {adherent,beneficiaires,questionnaire,...pret}=datapret
        if(!pret.hasOwnProperty('mode')){
         dispatch(registerField("addPret",'mode','Field'));
        }
        props.updateField('mode','UPDATE')
        history.push(`/${layoutRoot}/epret/add-pret`,{adherent,pret,beneficiaires,questionnaire,fonction:'ADD'});
      }
    }).catch(err=>{
      console.log(err);
    })
  }
  const handleShowDetail = (details)=>{
    if(details){
      history.push(`/${layoutRoot}/epret/pret-details/${details.id}`);
    }
  }
  const [mopen, setMopen] = useState(false)
  const handleMopen =(etat)=>{
    setMopen(etat)
  }
  const [dopen, setDopen] = useState(false)
  const handleDopen =(etat)=>{
    setDopen(etat)
  }
  const [dinfo, setDinfo] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      dmessage:null,
      ddescription:null,
      dTexteButton:null,
      dmode:null
    });
  
  
  const handleBulletin =async (data)=>{
    if(data.etat!==1){
      const mesQuestion =await getQuestionnaires(data.id,0)
      data.questionnaires = mesQuestion
      data.user = props.user 
      generateBulletin(data)
    }else{
      data.user = props.user 
      genFicheDemandeExamen(data)
    }
 }
 
const handleAnnulationRejet =()=>{
  setDinfo({
    ['dTitle']:"Voulez vous vraiment annuler/rejeter cet pêt ?",
    ['dTexteButton']:'Annuler/Rejeter',
    ['dmode']:1
  })
  setDopen(true)
}

const [openra,setOpenra] = React.useState(false)
  const handleOpenra= ()=>{
    setOpenra(true)
  }
  const handleClosera= ()=>{
    setOpenra(false)
  }

  const handleMenuClick=(type,donnees)=> {
    switch (type) {
      case "IMPORT_FILE":
        handleOpeni()
        break;
      case "LIST_FILE":
          handleDlopen()
          break;
        case 'EDIT_CONTRAT':
          modifierPret(donnees.id)
      break;
      case "TARIF":
          props.editFicheTarification(donnees)
        break;
      case "RAPPORT":
          props.raport(donnees)
        break;
      case "EXAMEN":
          handleRopen(true)
        break;
      case "BULLETIN":
        handleBulletin(donnees)
        break;
      case 'MPLACE':
        handleMopen(true)
        break;
      case 'RACHETER':
        handleOpenra(true)
        break;
      case 'ANNULER':
        handleAnnulationRejet()
        break;
      default:
        break;
    }
    handleClose()
  }

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }
  const [dlopen, setDlopen] = useState(false)
  const handleDlopen =()=>{
    setDlopen(true)
  }
  const handleDlClose =()=>{
    setDlopen(false)
  }

  const {data,layoutRoot,user} = props;
      return(
        <React.Fragment>
          <ModalRapportMedecin ropen={ropen} handleRopen={handleRopen}  data={data} />
          <ModalMiseEnPlacePret mopen={mopen} handleMopen={handleMopen} data={data}  />
          <ModalRachatPret mopen={openra} handleMopen={handleOpenra} handleClose={handleClosera} data={data}  />
          <DialogBox dopen={dopen} handleDopen={handleDopen} dinfo={dinfo} data={data} />
          <ImportDocument source='PRET' open={openi} handleOpen={handleOpeni} handleClose={handleClosei} data={data} />
          <ImportDocumentList source='PRET' open={dlopen} handleOpen={handleDlopen} handleClose={handleDlClose} data={data} />

          <IconButton
            data-id={data.id}
            aria-label="actions"
            aria-controls='long-menu'
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="card-actions-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          > {/*
            <MenuItem  onClick={()=>handleShowDetail(data)}>
                <ListItemIcon>
                    <ListIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Détail</Typography>
            </MenuItem>
            */}

            {(Number(data.etat)==-1 || Number(data.etat) ==1 || Number(data.etat) ==2 || Number(data.etape)==4)?(
              <MenuItem  onClick={()=>handleMenuClick('EDIT_CONTRAT',data) }>
                <ListItemIcon>
                    <EditIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Modifier la proposition</Typography>
            </MenuItem>
            
            ):null}
            {(data &&data.etat==1 && user && user.branche==='TECHNIQUE')&&(
              <MenuItem  onClick={()=>handleMenuClick('EXAMEN',data) }>
              <ListItemIcon>
                  <NoteAddIcon fontSize="small" color='primary' />
              </ListItemIcon>
              <Typography variant="inherit">Saisir prime rapport medecin</Typography>
            </MenuItem>
            )}
            
            {data.etat==2&&(
              <MenuItem onClick={()=>handleMenuClick('MPLACE',data) }>
              <ListItemIcon>
                    <DoneIcon fontSize="small" color='primary' />
              </ListItemIcon>
              <Typography variant="inherit">Mettre en place le pret</Typography>
            </MenuItem>

            )}
            {data.etat ==3&&(
              <MenuItem onClick={()=>handleMenuClick('RACHETER',data) }>
              <ListItemIcon>
                    <StopIcon fontSize="small" color='primary' />
              </ListItemIcon>
              <Typography variant="inherit">Racheter le pret</Typography>
            </MenuItem>
            )}
           
               <MenuItem disabled>
                <Divider />
              </MenuItem>
              {data.etat==3&&(
                <MenuItem onClick={()=>handleMenuClick('SINISTRE',data)}>
                <ListItemIcon>
                      <AssignmentIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Declarer un sinistre</Typography>
              </MenuItem>
              )}
              {data.etat==3&&(
                <MenuItem disabled>
                <Divider />
              </MenuItem>
              )}
               
            <MenuItem onClick={()=>handleMenuClick('LIST_FILE',data) } >
              <ListItemIcon>
                <AssignmentIcon fontSize="small" color='primary' />
              </ListItemIcon>
              <Typography variant="inherit">Liste des documents joints</Typography>
            </MenuItem>
            <MenuItem disabled>
             <Divider />
            </MenuItem>
           {(Number(data.etat)==-1 || Number(data.etat)==1 || Number(data.etat)==2 || Number(data.etape)==4)?(
             <MenuItem onClick={()=>handleMenuClick('IMPORT_FILE',data) } >
               <ListItemIcon>
                 <AssignmentReturnedIcon fontSize="small" color='primary' />
             </ListItemIcon>
               <Typography variant="inherit">Joindre des documents</Typography>
             </MenuItem>
         ):null}
         
            <MenuItem disabled>
             <Divider />
           </MenuItem>
            <MenuItem onClick={()=>handleMenuClick('BULLETIN',data)}>
              <ListItemIcon>
                    <InsertDriveFileIcon fontSize="small" color='primary' />
              </ListItemIcon>
              <Typography variant="inherit">Bulletin Individuel d’adhésion</Typography>
            </MenuItem>
            <MenuItem onClick={() =>  props.editFicheTarification(data)}>
                <ListItemIcon>
                      <PaymentIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit"> Fiche de tarification</Typography>
            </MenuItem>
            <MenuItem onClick={() => props.rapport()}>
                <ListItemIcon>
                    <GetAppIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit"> Exemplaire rapport d'examinateur</Typography>
            </MenuItem>
            <MenuItem disabled>
                <Divider />
            </MenuItem>
            {(data.etat <3 && data.etat !=0)&&(
            <MenuItem onClick={()=>handleMenuClick('ANNULER',data) }>
              <ListItemIcon>
                <CloseIcon fontSize="small" color='primary' />
              </ListItemIcon>
              <Typography variant="inherit">Marquer comme annuler/rejeter</Typography>
            </MenuItem>
            )
            }
          </Menu>
      </React.Fragment>
    );
      
  }
  const updateField =(field, data)=>(dispatch)=>dispatch(change('addPret', field, data))
  const mapStateToProps = (state) => ({
      layoutRoot:state.UI.layoutRoot
  });
  const mapActionsToProps = {updateField,selectPretByUser}
  export default connect(mapStateToProps,mapActionsToProps)(CustomMenu)
