import React,{useState,useReducer} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
  }));
export default function (props){
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {data}=props
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };
    
      const open = Boolean(anchorEl);
      const mid = open ? `simple-popover-${data.IdProduitGarantie}` : undefined;
      
    return (
        <div>
        <IconButton 
          data-id={mid}
          aria-label="description"
          aria-controls='simple-popover'
          edge="end" 
          aria-haspopup="true"
            onClick ={handleClick}
            onMouseOver={handleClick}
        >
          <HelpIcon />
        </IconButton>
        <Popover
          id={mid}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleClose}
          disableRestoreFocus
        >
          <Typography className={classes.typography}>{data.description}</Typography>
        </Popover>
      </div>

    )
}