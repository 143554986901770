import React,{useState,useReducer,useEffect} from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import {connect,useDispatch,useSelector} from 'react-redux'
import {reduxForm,change,registerField,getFormValues } from 'redux-form';
import {getAdherentBni} from 'redux/actions/apiActions'
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from "@material-ui/core/CircularProgress";
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import { Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));
function CheckClient(props) {
    const classes = useStyles();
    const {open,handleClose,user,data,ui:{loading}} = props
    const dispatch = useDispatch();
    const [adherentLoading,setAdherentLoading]  = useState(false)
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
    agence:user.hasOwnProperty('credentials')?user.credentials.agence:"",
    numerocompte:'',
    rib:'',
    });
    const handleInputChange =(event)=>{
        const {name,value} = event.target
        setInputField({
            [name]:value
        })
    }

    const initialField =()=>{
        setInputField({
            ['agence']:user.hasOwnProperty('credentials')?user.credentials.agence:"",
            ['numerocompte']:'',
            ['rib']:''
        })
    }
    useEffect(()=>{
        if(open==true){
            initialField()
        }
    },[open])

    dispatch({type:'STOP_LOADING_UI'})
    useEffect(() => {
        setInputField({
            ['agence']:user.hasOwnProperty('credentials')?user.credentials.agence:""
        })
        if(data.hasOwnProperty('numCompte')){
            setInputField({
                ['numerocompte']:data.numCompte
            })
        }
        if(data.hasOwnProperty('rib')){
            setInputField({
                ['rib']:data.rib
            })
        }
    }, [])
  

        const handleSelectClientBni = ()=>{
            if(inputField.numerocompte==="" && inputField.numerocompte.length !== 11){
            setAlertMessage("Numéro de compte incorrect (11 caractères) !")
            setOpenAlert(true)
            return
            } 
            if(inputField.agence==="" && inputField.agence.length !== 5){
                setAlertMessage("Code d'agence incorrect (5 caractères) !")
                setOpenAlert(true)
                return
            } 
            if(inputField.rib==="" && inputField.rib.length !== 2){
                setAlertMessage("La clé RIB est incorrecte (2 caractères) !")
                setOpenAlert(true)
                return
            }
            const dataSend = {numerocompte:inputField.agence+inputField.numerocompte,rib:inputField.rib}
            
            dispatch({type:'LOADING_UI'})
            setAdherentLoading(true)
            getAdherentBni(dataSend).then(adData=>{
                if(adData){
                   
                    if(adData.hasOwnProperty("code") && adData.hasOwnProperty("description")){
                        dispatch({type:'STOP_LOADING_UI'})
                        setAdherentLoading(false)
                        setAlertMessage(`${adData.code} - ${adData.description}`)
                        setOpenAlert(true)
                    }
                    if(adData && adData.length!==0){
                        const {client,compte}=adData
                            if(!props.data.hasOwnProperty('civiliteSous')){
                                props.dispatch(registerField("adForm",'civiliteSous','Field'));
                            }
                            if(client && client.civilite && client.civilite!==null)props.updateField('civiliteSous',client.civilite)
            
                            if(!props.data.hasOwnProperty('nomSous')){
                                props.dispatch(registerField("adForm",'nomSous','Field'));
                            }
                            if(client && client.nom && client.nom!==null)props.updateField('nomSous',client.nom) 
            
                            if(!props.data.hasOwnProperty('prenomSous')){
                                props.dispatch(registerField("adForm",'prenomSous','Field'));
                            }
                            if(client && client.prenom && client.prenom!==null)props.updateField('prenomSous',client.prenom) 
            
                            if(!props.data.hasOwnProperty('dateNaissanceSous')){
                                props.dispatch(registerField("adForm",'dateNaissanceSous','Field'));
                            }
                            if(client && client.datenaissance && client.datenaissance!==null)props.updateField('dateNaissanceSous',client.datenaissance.split('T')[0]) 
            
                            if(!props.data.hasOwnProperty('lieuNaissanceSous')){
                                props.dispatch(registerField("adForm",'lieuNaissanceSous','Field'));
                            }
                            if(client && client.lieunaissance && client.lieunaissance!==null)props.updateField('lieuNaissanceSous',client.lieunaissance) 
            
                            if(!props.data.hasOwnProperty('sexeSous')){
                                props.dispatch(registerField("adForm",'sexeSous','Field'));
                            }
                            if(client && client.genre && client.genre!==null)props.updateField('sexeSous',client.genre)
            
                            if(!props.data.hasOwnProperty('pieceIdentiteSous')){
                                props.dispatch(registerField("adForm",'pieceIdentiteSous','Field'));
                            }
                            if(client && client.numeropiece && client.numeropiece!=null)props.updateField('pieceIdentiteSous',client.numeropiece) 
            
                            if(!props.data.hasOwnProperty('naturePiece')){
                                props.dispatch(registerField("adForm",'naturePiece','Field'));
                            }
                            if(client && client.naturePiece && client.naturePiece!==null)props.updateField('naturePiece',client.naturePiece) 
            
                            if(!props.data.hasOwnProperty('lieuResidenceSous')){
                                props.dispatch(registerField("adForm",'lieuResidenceSous','Field'));
                            }
                            if(client && client.lieuresidence && client.lieuresidence!==null)props.updateField('lieuResidenceSous',client.lieuresidence) 
            
                            if(!props.data.hasOwnProperty('professionSous')){
                                props.dispatch(registerField("adForm",'professionSous','Field'));
                            }
                            if(client && client.profession && client.profession!==null)props.updateField('professionSous',client.profession) 
            
                            if(!props.data.hasOwnProperty('employeurSous')){
                                props.dispatch(registerField("adForm",'employeurSous','Field'));
                            }
                            if(client && client.secteuractivite && client.secteuractivite!==null)props.updateField('employeurSous',client.secteuractivite) 
            
                            //contact
                            if(!props.data.hasOwnProperty('adressepostale')){
                                props.dispatch(registerField("adForm",'adressepostale','Field'));
                            }
                           if(client && client.adressepostale && client.adressepostale!==null) props.updateField('adressepostale',client.adressepostale) 
            
                            if(!props.data.hasOwnProperty('telephoneSous')){
                                props.dispatch(registerField("adForm",'telephoneSous','Field'));
                            }
                            if(client && client.telephonebureau && client.telephonebureau!==null)props.updateField('telephoneSous',client.telephonebureau) 
            
                            if(!props.data.hasOwnProperty('emailSous')){
                                props.dispatch(registerField("adForm",'emailSous','Field'));
                            }
                            if(client && client.email && client.email!=='null' && client.email!==null)props.updateField('emailSous',client.email) 
            
                            if(!props.data.hasOwnProperty('mobileSous')){
                                props.dispatch(registerField("adForm",'mobileSous','Field'));
                            }
                            if(client && client.mobile1 && client.mobile1!==null)props.updateField('mobileSous',`+225${client.mobile1}`) 
            
                            if(!props.data.hasOwnProperty('mobile2Sous')){
                                props.dispatch(registerField("adForm",'mobile2Sous','Field'));
                            }
                            if(client && client.mobile2 && client.mobile2!==null) props.updateField('mobile2Sous',`+225${client.mobile2}`) 
                           
            
                            //compte
                            if(!props.data.hasOwnProperty('codebanque')){
                                props.dispatch(registerField("adForm",'codebanque','Field'));
                            }
                            if(compte && compte.codebanque && compte.codebanque!==null)props.updateField('codebanque',compte.codebanque) 
            
                            if(!props.data.hasOwnProperty('codeguichet')){
                                props.dispatch(registerField("adForm",'codeguichet','Field'));
                            }
                            if(compte && compte.codeguichet && compte.codeguichet!==null)props.updateField('codeguichet',compte.codeguichet) 
            
                            if(!props.data.hasOwnProperty('agence')){
                                props.dispatch(registerField("adForm",'agence','Field'));
                            }
                            if(compte && compte.codeguichet && compte.codeguichet!==null)props.updateField('agence',compte.codeguichet) 
            
                            if(!props.data.hasOwnProperty('numCompte')){
                                props.dispatch(registerField("adForm",'numCompte','Field'));
                            }
                            if(compte && compte.numerocompte && compte.numerocompte!==null)props.updateField('numCompte',compte.numerocompte)
            
                            if(!props.data.hasOwnProperty('rib')){
                                props.dispatch(registerField("adForm",'rib','Field'));
                            }
                            if(compte && compte.rib && compte.rib!==null)props.updateField('rib',compte.rib) 
                         
                            if(!props.data.hasOwnProperty('naturecompte')){
                                props.dispatch(registerField("adForm",'naturecompte','Field'));
                            }
                            if(compte && compte.naturecompte && compte.naturecompte!==null)props.updateField('naturecompte',compte.naturecompte) 
                            props.handleEtpapeParcourru(1)
                            props.setActiveStep(1)
                            handleClose()
                    }
                    setAdherentLoading(false)
                    dispatch({type:'STOP_LOADING_UI'})

                }else{
                    setAdherentLoading(false)
                    dispatch({type:'STOP_LOADING_UI'})
                }
            }).catch(err=>{
                dispatch({type:'STOP_LOADING_UI'})
                setAdherentLoading(false)
                console.log(err);
            })
        }
       
    return (
        <Dialog fullWidth maxWidth={props.width} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">
                <Grid  style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    <Typography variant="h6" className={classes.title}>
                            RECHERCHER UN ADHERENT
                        </Typography>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
              </DialogTitle>
                <Divider />
                <DialogContent>
                    
                    <form>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <Collapse in={openAlert}>
                                        <Alert
                                        action={
                                            <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setOpenAlert(false);
                                            }}
                                            >
                                            <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        >
                                       fermer
                                        </Alert>
                                    </Collapse>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <LabelledOutline>
                                            <GridContainer>
                                                <GridItem xs={12} sm={2} md={2} lg={2}>
                                                    <CustomInput
                                                        label="Code guichet"
                                                        id="agence"
                                                        name="agence"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        value ={inputField.agence}
                                                        type='texte'
                                                        variant="outlined"
                                                        onChange={handleInputChange}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={4} md={4} lg={4}>
                                                    <CustomInput
                                                        label="N° compte"
                                                        id="numerocompte"
                                                        name="numerocompte"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        type='texte'
                                                        InputProps={{
                                                            inputProps: { max:12,min:11},
                                                        }}
                                                        variant="outlined"
                                                        value ={inputField.numerocompte}
                                                        onChange={handleInputChange}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={2} md={2} lg={2}>
                                                    <CustomInput
                                                        label="Clé rib"
                                                        id="rib"
                                                        name="rib"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        type='number'
                                            
                                                        variant="outlined"
                                                        value ={inputField.rib}
                                                        onChange={handleInputChange}
                                                    />
                                                </GridItem>
                                                
                                                <GridItem xs={2} sm={2} md={2} lg={2} style={{marginTop:30}}>
                                                    <Button variant="contained" color='primary' onClick={handleSelectClientBni} disabled={loading}>
                                                        {!adherentLoading? ( <SearchIcon />):(<CircularProgress color='secondary' size={30}/>)}
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                        </LabelledOutline>
                                        <Divider orientation="horizontal" style={{marginBottom:20,marginTop:20}}/>
                                        </GridItem>
                            </GridContainer>
                    </form>
                </DialogContent>
                <DialogActions>

                </DialogActions>
        </Dialog>
    )
}

const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapPropsActions ={
    updateField
}
const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user,
    data:getFormValues('adForm')(state),
    ui:state.UI
  });
  CheckClient = withWidth()(CheckClient)
  export default connect(mapStateToProps,mapPropsActions)(reduxForm({
  form: 'adForm', // a unique identifier for this form
  destroyOnUnmount: false,})(CheckClient))


