import {
    SET_USER,
    SET_AUTHENTICATED,
    SET_UNAUTHENTICATED,
    LOADING_USER,
    MARK_NOTIFICATIONS_READ,
    SET_USER_DETAIL,
    SET_PRIVILEGES,
    LOADING_CORPS,
    STOP_LOADING_CORPS
  } from '../types';

  const initialState = {
    authenticated: false,
    loading: false,
    corpsloading:false,
    credentials: {},
    details:[],
    likes: [],
    notifications: [],
    privileges:[]
  };

  export default function(state = initialState, action) {
    switch (action.type) {
      case SET_AUTHENTICATED:
        return {
          ...state,
          authenticated: true
        };
      case SET_UNAUTHENTICATED:
        return initialState;
      case SET_USER:
        return {
          authenticated: true,
          loading: false,
          credentials:action.payload
        };
      case SET_PRIVILEGES:
        return{
          ...state,
          loading: false,
          privileges:action.payload
        }
      case SET_USER_DETAIL:
        return {
          ...state,
          loading: false,
          details:action.payload
        };
      case LOADING_USER:
        return {
          ...state,
          loading: true
        };
      case LOADING_CORPS:
        return {
          ...state,
          corpsloading: true
        };
      case STOP_LOADING_CORPS:
        return {
          ...state,
          corpsloading: false
        };
      case MARK_NOTIFICATIONS_READ:
        state.notifications.forEach((not) => (not.read = true));
        return {
          ...state
        };
      default:
        return state;
    }
  }