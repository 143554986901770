import React, { useState } from "react";
import { reduxForm, change, getFormValues } from 'redux-form';
import { connect } from 'react-redux'
import ProdFunctions from 'pages/Producteur/ProdFonctions'


function ProductionHome(props) {
    const [fonctionnalite, setFonctionalite] = useState('HOME')
    const handleCliqueFonction = element => {
        setFonctionalite(element)
    }
    return (
        <>
            {fonctionnalite === 'HOME' && <ProdFunctions history={props.history} handleCliqueFonction={handleCliqueFonction} />}

        </>
    )
}


const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    data: getFormValues('adForm')(state)
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({ destroyOnUnmount: false, })(ProductionHome))

