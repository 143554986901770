import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

// MUI Stuff
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Paper from '@material-ui/core/Paper';
// Redux stuff
import { connect } from 'react-redux';

import { loginUser } from '../redux/actions/userActions';
import {addSouscription} from '../redux/actions/apiActions'
import ProfilSouscipteur from './components/ProfilSouscipteur'
import ProduitSouscription from './components/ProduitSouscription'
import ProfilAssure from './components/ProfilAssure'
import ProfilBeneficiaire from './components/ProfilBeneficiaire'
import SouscriptionFicheresume from './components/SouscriptionFicheresume'
import ProfilSouscipteurInfo from './components/ProfilSouscipteurInfo'
import Container from '@material-ui/core/Container';
import DocumentSouscription from './components/DocumentSouscription'
const styles =(theme)=>({
    ...theme.spread,
    dividerFullWidth: {
        margin: `5px 0 0 ${theme.spacing(2)}px`,
      },
      root: {
        flexGrow: 1,
      },
    core:{
        
        padding:theme.spacing(2)
    },
    hTrait:{
        margin:10
    },
    hSousForm:{
        backgroundColor:'#fff'
    },
    cSousForm:{
        backgroundColor:'#fff'
    }, 
    sSousForm:{
        backgroundColor:'#fff'
    },  
    paper:{
      padding:10
    }  
});

class Souscription extends React.Component {
    state ={
        login:'',
        password:'',
        errors:{},
        loading:false,
        activeStep:0
    }
    handleRedirectSouscription =(data) =>{
      this.props.history.push('/',{souscription:data});
    }
    handleSubmit = (event) => {
        event.preventDefault();
        const userData = {
          login: this.state.login,
          password: this.state.password
        }; 
        this.props.loginUser(userData, this.props.history);
      };

      handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.value
        });
      };

      //stepper
      getSteps =()=> {
        return [
            'Produit', 
            'Informations personnelles', 
            'Assuré(e)s?', 
            'Bénéficiaires?',
            'Cotisation',
            'Documents',
            'Résumé'
        ];
      };

      getStepContent = (stepIndex)=> {
        switch (stepIndex) {
          case 0:
            return 'Quel type d\'investissement souhaitez vous faire?';
          case 1:
            return 'Vos informations personnelles';
          case 2:
            return 'Qui souhaitez vous assurer?';
          case 3:
            return 'Qui seront les bénéficiaires au terme du contrat ?';
          case 4:
            return 'Votre contrat';
          case 5:
              return 'Documents justicatifs';
          default:
            return 'Récapitulatif';
        }
      };
      
        resultat = (values) => {
          let dataSouscription = values;

          this.props.addSouscription(dataSouscription)
         
          this.props.history.push('/');
        }

        handleNext = () => {
            this.setState({
                activeStep: this.state.activeStep + 1
            });
        };

        handleBack = () => {
          if(this.state.activeStep>=1){
            this.setState({
              activeStep: this.state.activeStep - 1
          });
          }
            
        };

        handleReset = () => {
            this.setState({
                activeStep:0
            });
        };


    render(){
        const {
            classes
        } = this.props
        const { errors,loading,activeStep } = this.state;
        const steps = this.getSteps();
        return(
        <div>
          <Container maxWidth="lg">
          <Grid container  direction='column' spacing={1} className={classes.core}>
              <Grid item sm={12} xs={12}>
                <Paper className={classes.paper}>
                  <Typography variant="h4" >
                      Demande d'adhésion à un contrat LA LOYALE VIE
                  </Typography>
                  
                  <Divider component="hr" className={classes.hTrait}/>

                  <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map(label => (
                      <Step key={label}>
                          <StepLabel color='primary'>{label}</StepLabel>
                      </Step>
                      ))}
                  </Stepper>
                </Paper>
            </Grid>
          </Grid>
           <Grid container  spacing={2} className={classes.core}> 
              <Grid item sm={12} lg={12} xs={12}>
                <Paper className={classes.paper}>
                    <Typography variant="h4" >
                        {this.getStepContent(activeStep)}
                    </Typography>   
                    <Divider component="hr" className={classes.hTrait}/>
                      {activeStep ===0 ? (<ProduitSouscription handleBack={this.handleBack} step ={activeStep}  onSubmit={this.handleNext} />): null }  
                      {activeStep ===1 ? (<ProfilSouscipteur handleBack={this.handleBack}  step ={activeStep}  onSubmit={this.handleNext} />): null }
                      {activeStep ===2 ? (<ProfilAssure btnshow={true} handleBack={this.handleBack} step ={activeStep}  onSubmit={this.handleNext} />): null }         
                      {activeStep ===3 ? (<ProfilBeneficiaire btnshow={true} handleBack={this.handleBack}  step ={activeStep}  onSubmit={this.handleNext} />): null }
                      {activeStep ===4 ? (<ProfilSouscipteurInfo btnshow={true} handleBack={this.handleBack}  step ={activeStep}  onSubmit={this.handleNext} />): null }
                      {activeStep ===5 ? (<DocumentSouscription btnshow={true} handleBack={this.handleBack}  step ={activeStep}  onSubmit={this.handleNext} />): null }
                      {activeStep ===6 ? (<SouscriptionFicheresume handleBack={this.handleBack} handleRedirectSouscription={this.handleRedirectSouscription}  step ={activeStep}  onSubmit={this.resultat} />): null }         
                  </Paper>    
              </Grid>
              
        </Grid>
          </Container>
          

        </div>
        );
    }
}

Souscription.propTypes = {
    classes: PropTypes.object.isRequired,
    loginUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  const mapStateToProps = (state) => ({
    user: state.user
  });
  const mapActionsToProps = {
    loginUser,
    addSouscription
  };

  export default connect(
    mapStateToProps,
    mapActionsToProps
  )(withStyles(styles)(Souscription));
  



