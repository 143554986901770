import React from 'react'
import PretDetail from './detailspret'
import dayjs from 'dayjs'
import {formatNumber} from 'application'
function DetailPret({data}) {
  return (
    <PretDetail>
        <PretDetail.Inner direction ='column'>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Civilité : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.adherent['civilite']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Nom : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.adherent['nom']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Prénoms : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.adherent['prenom']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Date de naissance : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.adherent.datenaissance&&dayjs(data.adherent.datenaissance).format('DD/MM/YYYY')}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Lieu de naissance : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.adherent['lieunaissance']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'> N° {data['naturepiece']||""} : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.adherent['numeropiece']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Prénoms : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.adherent['prenom']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Profession : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.adherent['profession']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Secteur d'activité : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.adherent['employeur']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Email : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.adherent['email']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Téléphone : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.adherent['telephone']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Mobile : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.adherent['mobile']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Mobile 2 : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.adherent['mobile1']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Adresse postale : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.adherent['bp']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>N° Compte/Client: </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data['numerocompte']||""}`}</PretDetail.Text></PretDetail.Group>
        </PretDetail.Inner>
        <PretDetail.Inner direction ='column'>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Type : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data['libType']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Date Effet : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.effetgarantie&&dayjs(data.effetgarantie).format('DD/MM/YYYY')}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Echéance début : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.dateecheancedebut&&dayjs(data.dateecheancedebut).format('DD/MM/YYYY')}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Echéance fin : </PretDetail.Text><PretDetail.Text variant='h6'>{data.dateecheancefin&&dayjs(data.dateecheancefin).add(parseInt(parseInt(data.duree)/12),'year').format('DD/MM/YYYY')}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Rembourssement : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data['rembourssement']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Prériodicité : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data['periodicite']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Montant du pret (F CFA) : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${(data['montantpret']||"")}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Taux prime emprunteur (%) : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data['txprimeunique']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>prime emprunteur : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.primeht&&formatNumber(data.primeht)}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Prime YAKO: </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.primeobseque&&(data.typeadherent==='Groupe' ? formatNumber(parseInt(data.primeobseque)*parseInt(data.nbadherent)):formatNumber(data.primeobseque))}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Taux sur prime (%) : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data['txsurprime']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Taux prime def (%) : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data['txprimedef']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Prime (F CFA) : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.prime&&formatNumber(data.prime)}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Etat : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data.hasOwnProperty('etat')?(data.etat===-1?"En attente":(data.etat===-1?"En traitement":(data.etat===2?"En attente de mise en place":(data.etat===3?"Pret en cours":(data.etat===4?"Prêt réjété":(data.etat===5?"Prêt cloturé":(data.etat===6?"Prêt rachaté":"Inconnu"))))))):""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Date mise en place : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${(data.miseenplaceeffective && data.miseenplaceeffective!=='null')?dayjs(data.miseenplaceeffective).format('DD/MM/YYYY'):''}`}</PretDetail.Text></PretDetail.Group>
        </PretDetail.Inner>
        <PretDetail.Inner direction ='column'>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Taille : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data['taille']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Poids : </PretDetail.Text><PretDetail.Text variant='h6'> {`\xa0 ${data['poids']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Tension : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data['tension']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Fumeur ? : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data['fumezvous']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Nombre cirgarette/jour ? : </PretDetail.Text><PretDetail.Text variant='h6'>{`\xa0 ${data['nbcigarettejour']||""}`}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Buvez vous l'alcool ? : </PretDetail.Text><PretDetail.Text variant='h6'>{ (`\xa0 ${data['buvezvous']||""}`)}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Vos distractions : </PretDetail.Text><PretDetail.Text variant='h6'>{ (`\xa0 ${data['distraction']||""}`)}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Etes-vous atteint d'une infirmité ? </PretDetail.Text><PretDetail.Text variant='h6'>{(`\xa0 ${data['estinfirme']||""}`)}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Nature infirmité : </PretDetail.Text><PretDetail.Text variant='h6'>{(`\xa0 ${data['natureinfirmite']||""}`)}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Etes-vous en arrêt de travail? </PretDetail.Text><PretDetail.Text variant='h6'>{(`\xa0 ${data['estenarrettravail']||""}`)}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Date d'arrêt : </PretDetail.Text><PretDetail.Text variant='h6'>{(`\xa0 ${data.datearrettravail?dayjs(data.datearrettravail).format('DD/MM/YYYY'):''}`)}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Motif de l'arrêt : </PretDetail.Text><PretDetail.Text variant='h6'>{(`\xa0 ${data['motifarret']||""}`)}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Date réprise prévu : </PretDetail.Text><PretDetail.Text variant='h6'>{(`\xa0 ${data.datereprisetravail?dayjs(data.datereprisetravail).format('DD/MM/YYYY'):''}`)}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Cause de l'arrêt: </PretDetail.Text><PretDetail.Text variant='h6'>{(`\xa0 ${data['causearrettravail']||""}`)}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'> Date de la cause : </PretDetail.Text><PretDetail.Text variant='h6'>{ (`\xa0 ${data.datecausetravail?dayjs(data.datecausetravail).format('DD/MM/YYYY'):''}`)}</PretDetail.Text></PretDetail.Group>

            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Pratiquez-vous une activité sportive régulière? </PretDetail.Text><PretDetail.Text variant='h6'>{(`\xa0 ${data['estsportif']||""}`)}</PretDetail.Text></PretDetail.Group>
            <PretDetail.Group direction='row'><PretDetail.Text variant='subtitle2'>Quel type de sport pratiquez vous?</PretDetail.Text><PretDetail.Text variant='h6'>{(`\xa0 ${data['sportpratique']||""}`)}</PretDetail.Text></PretDetail.Group>
        </PretDetail.Inner>
    </PretDetail>
  )
}

export default DetailPret