import React,{useEffect, useState} from 'react'
import { Paper,Divider, Typography, IconButton, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Addbranche from './Updatebranche'
import {getBranche,deleteBranche} from 'redux/actions/settingsAction'
import MaterialTable from 'material-table';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import swal from 'sweetalert'
import CircularProgress from '@material-ui/core/CircularProgress';
import PeopleIcon from '@material-ui/icons/People';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import CustumModal from 'components/CustumModal'
import UserBranche from './UserBranche'
import ProduitBranche from './ProduitBranche'
import {Link} from 'react-router-dom'
const useStyle = makeStyles(theme=>({
  root:{
      display:'flex',
      flexDirection:'column',
      width:'75%',
      marginLeft:'auto',
      marginRight:'auto',
      [theme.breakpoints.down('sm')]: {
          width:'100%',
      },
      [theme.breakpoints.up('md')]: {
      width:'75%',
      },
      marginBottom:20,
      marginTop:20
  },
  hPaper:{
      padding:10,
      marginBottom:20,
      borderLeftColor: theme.palette.primary.main,
      borderLeftWidth:8
  },
  bPaper:{
    padding:10,
    marginBottom:20,
    borderRadius:0
},
  hContainer:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center'
  },
  loaddingContainer:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
  },
  bcontainer:{
    paddingTop:10,
    backgroundColor:'transparent',
    borderRadius:0
  },
  bhead:{
      padding:10
  }
}))

function BrancheList(props) {
  const history = useHistory()
  const location =  useLocation()

  const classes = useStyle()
    const [open,setOpen] = React.useState(false)
    const handleOpen =()=>{
        setOpen(true)
    }
    const handleClose =()=>{
        setOpen(false)
    }
    const [mode,setMode] = React.useState('ADD')
    const [data,setData] = React.useState({})
    const [brData,setBrData] =React.useState(null)
    const [loading,setLoading] =React.useState(false)
    useEffect(()=>{
      setLoading(true)
      getBranche().then(res=>{
            if(res){
              setLoading(false)
              setBrData(res)
            }else{
              setLoading(false)
            }
        }).catch(err=>{
          setLoading(false)
        })
    },[])
    const handleAdd = ()=>{
        setMode('ADD')
        setData({})
        handleOpen()
    }
    const handleEdit =(cdata)=>{
        setMode('UP')
        setData(cdata)
        handleOpen()
    }
    
    const handleAtualiz =()=>{
        getBranche().then(res=>{
            if(res){
                setBrData(res)
            }
        })
    }

    const handleDelete =(cdata)=>{
        swal({
            title: "Attention etes-vous sur de vouloir supprimé partenaire?",
            text: "Cette opération supprimera toutes les données rattachées à ce partenaire!",
            icon: "warning",
            buttons: ["Annuler", "Supprimer"],
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                deleteBranche(cdata['id']).then(res=>{
                    if(res){
                        handleAtualiz()
                        swal('Succes','Suppression effectué avec succès','succes')
                    }else{
                        swal('Warning',"Erreur lors de l'opération",'warning')
                    }
                }).catch(err=>{
                    swal('Error',"Erreur lors de l'opération",'error')
                
                })
            } else {
              swal("Suppression annuler!");
            }
          });   
    }
    const [cbranche,setCbranche] = useState()
  const [tabs,setTabs] = useState('BR')
  const handleTabs = (tb,branche)=>{
    setTabs(tb)
    setCbranche(branche)
  }
  const handleNavigate =(url)=>{
    history.push(url);
  }

 
  return (
    <div className={classes.root}>
        <Addbranche open={open} handleClose={handleClose} mode={mode} data={data} actualiz={handleAtualiz} />
        
        <CustumModal
          open={tabs==='US'}
          title={`Liste des responsables - branche ${cbranche&&cbranche.libelle}`}
          handleClose={()=>setTabs(null)}
        >
          <UserBranche branche={cbranche} />
        </CustumModal>

        <CustumModal
          open={tabs==='PD'}
          title={`Liste des produits - branche ${cbranche&&cbranche.libelle}`}
          handleClose={()=>setTabs(null)}
        >
          <ProduitBranche branche={cbranche} />
        </CustumModal>

        <Paper className={classes.hPaper} variant="outlined">
            <div className={classes.hContainer}>
                <Typography variant='h5'>Gestion des branches</Typography>
              
                <Tooltip title="Nouvelle branche"> 
                <Link to={`/settings/branche/add`}>
                    <IconButton  color='primary' className='cursor-pointer'>
                        <AddIcon style={{fontSize:40}} className='hover:bg-primary-main cursor-pointer' />
                    </IconButton>
                    </Link>
                </Tooltip>
            </div>
        </Paper>
        <Paper className={classes.bcontainer} variant="outlined">
            <div className={classes.bhead}>
              <Typography variant='body'>Liste des branches</Typography>
                {(brData && brData.length!==0)&&<Chip className='ml-4' label={`Total(s) ${brData&&brData.length}`} />}
              </div>
            
            <Divider />
            <Paper className={classes.bPaper} variant="outlined">
              {brData?(<MaterialTable
                    columns={[
                      { title: '#', field: 'id' },
                      { title: 'Code', field: 'codebranche'},
                      { title: 'Branche', field: 'libelle'},
                      { title: 'Actions', render:rowData=>(
                        <div>
                        <Tooltip title="Modifier">
                            <IconButton onClick={()=>handleEdit(rowData)}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip> 
                        <Tooltip title="Liste des produits">
                            <IconButton onClick={()=>handleTabs('PD',rowData)}>
                                <FolderSpecialIcon />
                            </IconButton>
                        </Tooltip> 
                        <Tooltip title="Liste des utilisateurs">
                            <IconButton onClick={()=>handleTabs("US",rowData)}>
                                <PeopleIcon />
                            </IconButton>
                        </Tooltip> 
                        <Tooltip title="Supprimer">
                            <IconButton onClick={()=>handleDelete(rowData)}>
                                <DeleteIcon />
                            </IconButton> 
                        </Tooltip>
                               
                        </div>
                      )},
                    ]}
                    data={brData}
                    title=""
                    localization={{
                      toolbar:{
                          searchPlaceholder:'Rechercher',
                          searchTooltip:'Rechercher'
                      },
                      body: {
                        emptyDataSourceMessage: 'Aucune donnée trouvée !'
                      },
                      pagination: {
                        labelRowsSelect: 'Ligne(s)',
                        labelDisplayedRows: '{count} sur {from}-{to}',
                        firstTooltip: 'Prémière page',
                        previousTooltip: 'Précédent',
                        nextTooltip: 'Suivant',
                        lastTooltip: 'Dernière page'
                      }
                    }}
                    
                    options={{
                      exportButton: true,
                      searchFieldStyle:{
                        fontSize:18,
                        width:'100%',
                        height:50,
                      },
                      actionsColumnIndex: -1
                    }}
               />):(
                <div className={classes.loaddingContainer}>
                    {loading?<CircularProgress />:<Typography variant='h5'>Aucune données</Typography>}
                </div>
              
            )}
          </Paper>
        </Paper>
    </div>
  )
}

export default BrancheList