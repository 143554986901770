import React, { useReducer,useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from "@material-ui/core/Typography";
import MaterialTable,{MTableToolbar } from 'material-table';
import {connect,useDispatch} from 'react-redux'
import Button from 'components/CustomButtons/Button'
import {Add} from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInput'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import {createRole,getRolesReseau} from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));
function RolesReseau(props){
    const {reseauData,roopen,handleCloseRole}=props
    const classes = useStyles();
    const theme = useTheme();
    const [rolesReseau,setRolesReseau]=React.useState([])
  
    useEffect(() => {
        getRolesReseau(reseauData.id).then(data=>{if(data){setRolesReseau(data);}})
    }, [])
    
  const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
    role:"",
    codereseau:reseauData.id
    });
    
  const handleChange = (event)=>{
    const {name,value} = event.target
    setInputField({
        [name]:value
    })
  }
  const handleSaveRoles =()=>{
    if(inputField.codereseau==='')return
    if(inputField.role.trim()==='')return
    createRole(inputField).then(dataRole=>{
        if(dataRole){
          getRolesReseau(reseauData.id).then(data=>{if(data){setRolesReseau(data);}})
        }else{
            return false
        }
    }).catch(err=>{
        console.log(err);
        return
    })
  }
    return (
        <Dialog
            maxWidth='sm'
            open={roopen} 
            disableBackdropClick 
            disableBackdropClick
            fullWidth
            onClose={handleCloseRole} 
            aria-describedby="alert-dialog-description"
            aria-labelledby="form-dialog-title">
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleCloseRole} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Gestion des roles - Reseau : - #{reseauData.libelle}
                    </Typography>
                   
                </Toolbar>
        </AppBar>

            <DialogContent>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <CustomInput
                                    autoFocus
                                    required
                                    variant="outlined"
                                    id="role"
                                    name ='role'
                                    onChange={handleChange}
                                    label="Role"
                                    type="text"
                                    value = {inputField.role}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Button color="primary" onClick={handleSaveRoles} disabled={(inputField.role==='' || inputField.codereseau==='')} style={{width:'100%',marginBottom:20}}> Ajouter</Button>
                    </GridItem>
                </GridContainer>
                <Divider />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <MaterialTable
                            columns={[
                              { title: '#', field: 'id' },
                              { title: 'Role', field: 'role'},
                            ]}
                            data={rolesReseau}
                            title="Liste des roles"
                            localization={{
                              toolbar:{
                                  searchPlaceholder:'Rechercher',
                                  searchTooltip:'Rechercher'
                              },
                              body: {
                                emptyDataSourceMessage: 'Aucune donnée trouvée !'
                              },
                              pagination: {
                                labelRowsSelect: 'Ligne(s)',
                                labelDisplayedRows: '{count} sur {from}-{to}',
                                firstTooltip: 'Prémière page',
                                previousTooltip: 'Précédent',
                                nextTooltip: 'Suivant',
                                lastTooltip: 'Dernière page'
                              }
                            }}
                            
                            options={{
                              exportButton: true,
                              searchFieldStyle:{
                                fontSize:18,
                                width:'100%',
                                height:50,
                              },
                              actionsColumnIndex: -1
                            }}
                            
                            
                          />
                    </GridItem>
                </GridContainer>
            </DialogContent>
      </Dialog>
    )
}
RolesReseau.propTypes = {
    reseauData: PropTypes.object.isRequired,
    roopen: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    settings: state.settings,
  });
  const mapActionsToProps = {}
export default connect(mapStateToProps,mapActionsToProps)(RolesReseau)