import React from 'react';
import PropTypes from 'prop-types';
import Footer from "components/Layout/Footer"
import { withStyles } from '@material-ui/styles';
import bg from "assets/images/backgroundLogin.jpg"
import Homer from 'components/Home'
const styles = (theme) => ({
  root: {
    display: 'flex',
    minHeight:'100%',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sx')]: {
      alignItems: 'center'
    },

  },

  main:{
    minHeight:'100%',
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  }
 


});
//const bg = require("assets/images/bg-home.jpg").default

const MinimaLayout = props => {
  const { children, classes } = props

  return (
    <Homer bg={bg} className={classes.root}>
        <main className={classes.main}>{children}</main>
    </Homer>

  )
}

MinimaLayout.propTypes = {
  children: PropTypes.node,
}
export default withStyles(styles)(MinimaLayout)