import React from "react";
import { Container, Inner, Body, Group } from './styles/home'

export default function Homer({ children, ...restProps }) {
    return <Container {...restProps}>{children}</Container>
}

Homer.Inner = function HomerIner({ children, ...restProps }) {
    return <Inner {...restProps}>{children}</Inner>
}

Homer.Body = function HomerBody({ children, ...restProps }) {
    return <Body {...restProps}>{children}</Body>
}

Homer.Group = function HomerGroup({ children, ...restProps }) {
    return <Group {...restProps}>{children}</Group>
}