import React, { useCallback, useEffect, useState } from 'react'
import MaterialTable,{MTableToolbar} from 'material-table'
import { makeStyles } from '@material-ui/styles'
import { 
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  Button,
  Tooltip,
  Divider
 } from '@material-ui/core'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm, change, registerField, getFormValues, reset, untouch } from 'redux-form';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import StorageIcon from '@material-ui/icons/Storage';
import GridOnIcon from '@material-ui/icons/GridOn';

const useStyle = makeStyles(theme=>({
  root:{
    maxWidth: 345,
    cursor:'pointer'
  },
  bcontainer:{
    backgroundColor:'transparent',
    borderRadius:10
  },
  bhead:{
    padding:10
  },
}))


function StepConventions(props) {

  const {handleNext,handleBack,steps,activeStep}=props
  const classes = useStyle()
  const dispatch =useDispatch()
  const handleCheckedProduit =(myrow)=>{
    setSelectedRow(myrow)
    if (!props.hasOwnProperty('produit')) {
        dispatch(registerField("adForm", 'produit', 'Field'));
    }
    props.updateField('produit', myrow)
    handleNext()
  }
  let produits = useSelector(state => state.api.produits);
  const [modeGrid,setModeGrid] = useState(false)
  const [selectedRow, setSelectedRow] =useState({})

  const [produitData, setProduitData] = React.useState({columns: [
    {
        title: 'code produit',
        field: 'codeProduit',
        cellStyle: { fontSize: 30, padding: 0, color: '#000', fontWeight: 'bold' },
        render: rowData =><List className={classes.list}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar variant="square" sizes={50}>
                        <img src={handleGetLogo(rowData.CodeProduit,'vert')} className='object-fit w-8' />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`${rowData.MonLibelle} - ${rowData['Formule'] || ""}`} secondary={
                    <React.Fragment>
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                            display='block'
                        >
                            {rowData.DureCotisationAns ? `Durée ${rowData.DureCotisationAns}` : ""}
                        </Typography>
                    </React.Fragment>
                } />
                <ListItemSecondaryAction>
                    <Button  variant='outlined' className='bg-primary-main flex-1 text-white hover:bg-secondary-main border-0 py-2 pointer' onClick={() => handleCheckedProduit(rowData)} >
                        Souscrire
                    </Button>
                </ListItemSecondaryAction>
            </ListItem>
        </List> ,
    },
    { title: 'Libelle', field: 'MonLibelle', hidden: true },
    { title: 'Durée cotisation', field: 'DureeCotisationAns', type: 'numeric', hidden: true },
    { title: 'Durée souscription', field: 'DureeSouscriptionAnnee', type: 'numeric', hidden: true },
    { title: 'Type', field: 'CodeProduitNature', hidden: true },
]
});
 
  const handleClientSelect = (event, myrow) => {
    setSelectedRow(myrow)
  }

  const customFilter = (columFilter, tableFilter, valueSeach) => {
    const isproduit = (item) => {
        let result = false
        for (const key of columFilter) {
            if (item[key]) {
                if (item[key].toUpperCase().indexOf(valueSeach.toUpperCase()) !== -1) {
                    result = true
                    break;
                } else {
                    result = false
                }
            } else {
                result = false
            }
        }
        return result
    }
    return tableFilter.filter(isproduit)
  }
  const handleGetLogo=(code,color)=>{
    try{
        return require(`assets/images/logo-produit/${code}-${color}.png`)
    }catch {
        return require("assets/images/logo-produit/YK.png")
    }
  }
  return (
    <div>
      <Paper className={classes.bcontainer} variant="outlined">
            <div className=" bg-slate-300">
                <div className='flex flex-row justify-between items-center p-2 rounded-e-lg'>
                  <Typography variant='h5'>Veuillez selectionnez une convention/produits</Typography>
                  <Tooltip title={modeGrid ? 'Mode table' : 'Mode Grille'} >
                  <IconButton className='pointer' onClick={()=>setModeGrid(!modeGrid)}>
                    {modeGrid ? <StorageIcon />:<GridOnIcon />}
                  </IconButton>
                  </Tooltip>
                  </div>
            </div>
            <Divider className='mb-3' />
            {modeGrid ? (
                <MaterialTable
                title={null}
                data={query => new Promise((resolve, reject) => {
                    const columnSearch = ['CodeProduit', 'MonLibelle', 'DureeCotisationAns', 'DureeSouscriptionAnnee', 'CodeProduitNature']
                    resolve({
                        data: customFilter(columnSearch, produits, query.search)
                    })
                })}
                onRowClick={((evt, selectedRow) => handleClientSelect(evt, selectedRow))}
                components={{
                    Toolbar: props => (
                        <div style={{ backgroundColor: '#e8eaf5',width:'100%' }}>
                            <MTableToolbar {...props} />
                        </div>
                    ),
                    Body:({renderData,...props})=>{
                        return <div className='flex flex-row flex-1 gap-3 flex-wrap p-2'>
                            {(renderData && renderData.length!==0)&&renderData.map(dat=>(
                                <Card className={classes.root} elevation={3} key={dat.CodeProduitFormule}>
                                <CardActionArea className='hover:bg-secondary-main pointer'>
                                  <CardMedia
                                    component="img"
                                    alt="Logo produit"
                                    height="80"
                                    image={handleGetLogo(dat.CodeProduit,'vert')}
                                    title={`${dat.MonLibelle} - ${dat.Formule}`}
                                  />
                                  <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {dat.MonLibelle}
                                    </Typography>
                                    <span >
                                        {dat.Formule}
                                    </span>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {dat.DureCotisationAns ? `Durée ${dat.DureCotisationAns}` : ""}
                                    </Typography>
                                  </CardContent>
                                </CardActionArea>
                                <CardActions>
                                  <Button onClick={() => handleCheckedProduit(dat)} variant='outlined' className='bg-primary-main flex-1 text-white hover:bg-secondary-main border-0 py-2 pointer' size="small" color="primary">
                                    Souscrire
                                  </Button>
                                </CardActions>
                              </Card>
                            ))}
                        </div>
                    }
                }}
                localization={{
                    toolbar: {
                        searchPlaceholder: 'Rechercher',
                        searchTooltip: 'Rechercher'
                    },
                    body: {
                        emptyDataSourceMessage: 'Aucune donnée trouvée !'
                    },
                    pagination: {
                        labelRowsSelect: 'Ligne(s)',
                        labelDisplayedRows: '{count} sur {from}-{to}',
                        firstTooltip: 'Prémière page',
                        previousTooltip: 'Précédent',
                        nextTooltip: 'Suivant',
                        lastTooltip: 'Dernière page'
                    }
                }}
                options={{
                    search: true,
                    searchFieldAlignment: "left",
                    paging:(produits.length>5),
                    toolbar:(produits.length>5),
                    pageSize: 5,
                    pageSizeOptions: [10, 20, 30, 40, 50, 100],
                    searchFieldStyle: {
                        fontSize: 18,
                        width: '100%',
                        height: 50,
                    },
                    header: false,
                    
                }}
            />
            ):(
                <MaterialTable
                title={null}
                columns={produitData.columns}
                data={query => new Promise((resolve, reject) => {
                    const columnSearch = ['CodeProduit', 'MonLibelle', 'DureeCotisationAns', 'DureeSouscriptionAnnee', 'CodeProduitNature']
                    resolve({
                        data: customFilter(columnSearch, produits, query.search)
                    })
                })}
                onRowClick={((evt, selectedRow) => handleClientSelect(evt, selectedRow))}
                components={{
                    Toolbar: props => (
                        <div style={{ backgroundColor: '#e8eaf5',width:'100%' }}>
                            <MTableToolbar {...props} />
                        </div>
                    ),
                }}
                localization={{
                    toolbar: {
                        searchPlaceholder: 'Rechercher',
                        searchTooltip: 'Rechercher'
                    },
                    body: {
                        emptyDataSourceMessage: 'Aucune donnée trouvée !'
                    },
                    pagination: {
                        labelRowsSelect: 'Ligne(s)',
                        labelDisplayedRows: '{count} sur {from}-{to}',
                        firstTooltip: 'Prémière page',
                        previousTooltip: 'Précédent',
                        nextTooltip: 'Suivant',
                        lastTooltip: 'Dernière page'
                    }
                }}
                options={{
                    search: true,
                    searchFieldAlignment: "left",
                    paging:(produits.length>5),
                    toolbar:(produits.length>5),
                    pageSize: 5,
                    pageSizeOptions: [10, 20, 30, 40, 50, 100],
                    searchFieldStyle: {
                        fontSize: 18,
                        width: '100%',
                        height: 50,
                    },
                    header: false,
                    rowStyle: rowData => ({
                        backgroundColor: (selectedRow && selectedRow.tableData?.id === rowData.tableData?.id) ? '#FFF1EB' : '#FFF'
                    })
                }}
            />
            )}
            
        </Paper>
    </div>
  )
}

const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {
    updateField
}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    data: getFormValues('adForm')(state),
    ui: state.UI
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({
    form: 'adForm', // a unique identifier for this form
    destroyOnUnmount: false,
})(StepConventions))

