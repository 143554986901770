import React, { useReducer,useEffect,useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import {connect} from 'react-redux'
import Button from 'components/CustomButtons/Button'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInput'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import {updateReseau,getBranche,getPartenaire} from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertPage from 'components/AlertPage'

function UpdateReseau(props){
    const {reseauData,ropen,handleCloseAdd}=props
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [branches,setBranches] = React.useState([])
    const [partenaires,setPartenaires] = React.useState([])

    const [loading,setLoading] = useState(false)
    const [alertInfo,setAlertInfo]=useState({})
    const handleAlertClose =(action=null)=>{
        setAlertInfo({open:false,message:"",succes:false})
        action&&action()
    }
    const handleAlertOpen =(message,succes=false,action)=>{
        setAlertInfo({open:true,message:message,succes:succes,action:action})
    }

    useEffect(() => {
        getBranche().then(data=>{if(data){setBranches(data);}})
        getPartenaire().then(data=>{
            if(data){
              setPartenaires(data);
            }
          })
    }, [])
    
  const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
    codereseau:reseauData.codereseau?reseauData.codereseau:"",
    libelle:reseauData.libelle?reseauData.libelle:"",
    coderesponsable:reseauData.coderesponsable?reseauData.coderesponsable:"",
    nomresponsable:reseauData.nomresponsable?reseauData.nomresponsable:"",
    codebranche:reseauData.codebranche?reseauData.codebranche:"",
    codepartenaire:reseauData.codepartenaire?reseauData.codepartenaire:""
    });
    
  const handleChange = (event)=>{
    const {name,value} = event.target
    setInputField({
        [name]:value
    })
  }
  const handleSaveResseau =()=>{
    if(inputField.codereseau.trim()==='')return
    if(inputField.libelle.trim()==='')return
    setLoading(true)
    props.updateReseau(reseauData.id,inputField).then(data=>{
        if(data){
            setLoading(false)
            handleAlertOpen("Modification effectuée avec succès",true,handleCloseAdd)
        }else{
            setLoading(false)
            handleAlertOpen("Erreur lors de la modification",false,null)
            return false
        }
    }).catch(err=>{
        console.log(err);
        setLoading(false)
        handleAlertOpen("Erreur lors de la modification",false,null)
        return
    })
  }
    return (
        <Dialog 
            fullScreen={fullScreen} 
            open={ropen} 
            onClose={handleCloseAdd} 
            aria-describedby="alert-dialog-description"
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Modifier un reseau - #{reseauData.id}</DialogTitle>
            <DialogContent>
            <AlertPage open={alertInfo['open']||false}  action={alertInfo['action']||null} message={alertInfo['message']||""} succes={alertInfo['succes']||false} handleAlertOpen={handleAlertOpen} handleAlertClose={handleAlertClose} />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4} lg={4}>
                                <CustomInput
                                    required
                                    autoFocus
                                    variant="outlined"
                                    id="codereseau"
                                    name='codereseau'
                                    label="code"
                                    type="text"
                                    value = {inputField.codereseau}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={handleChange}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8} lg={8}>
                                <CustomInput
                                    autoFocus
                                    required
                                    variant="outlined"
                                    id="libelle"
                                    name ='libelle'
                                    onChange={handleChange}
                                    label="Nom réseau"
                                    type="text"
                                    value = {inputField.libelle}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4} lg={4}>
                                <CustomInput
                                    required
                                    autoFocus
                                    variant="outlined"
                                    id="coderesponsable"
                                    name='coderesponsable'
                                    label="code responsable"
                                    type="text"
                                    value = {inputField.coderesponsable}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={handleChange}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8} lg={8}>
                                <CustomInput
                                    autoFocus
                                    required
                                    variant="outlined"
                                    id="nomresponsable"
                                    name ='nomresponsable'
                                    onChange={handleChange}
                                    label="Nom responsable"
                                    type="text"
                                    value = {inputField.nomresponsable}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                variant="outlined"
                                id="codepartenaire"
                                name ='codepartenaire'
                                select
                                label="Parténaire"
                                value={inputField.codepartenaire}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{marginTop:20,marginBottom:10}}
                                >
                                {(partenaires.length !==0)&& partenaires.map((option) => (
                                    <MenuItem key={option.code} value={option.code}>
                                    {option.designation}
                                    </MenuItem>
                                ))}
                                </TextField>
                            </GridItem>
                        </GridContainer> 
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    variant="outlined"
                                    id="codebranche"
                                    name ='codebranche'
                                    select
                                    label="Branche d'activité"
                                    value={inputField.codebranche}
                                    onChange={handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{marginTop:20,marginBottom:10}}
                                    >
                                    {(branches.length !==0)&& branches.map((option) => (
                                        <MenuItem key={option.codebranche} value={option.codebranche}>
                                        {option.libelle}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </GridItem>
                        </GridContainer>
                        
                    </GridItem>
                </GridContainer>
                <Divider />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseAdd}  variant='outlined' color="secondary">
                    Annuler
                </Button>
                <Button onClick={handleSaveResseau}  variant='outlined' color="primary">
                    Modifier
                </Button>
            </DialogActions>
      </Dialog>
    )
}
UpdateReseau.propTypes = {
    reseauData: PropTypes.object.isRequired,
    ropen: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    settings: state.settings,
  });
  const mapActionsToProps = {updateReseau}
export default connect(mapStateToProps,mapActionsToProps)(UpdateReseau)