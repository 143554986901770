import React, { useReducer, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import { reduxForm, change, getFormValues } from 'redux-form';
import { connect } from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { green, pink } from '@material-ui/core/colors';
import { Add } from "@material-ui/icons/";
import { Divider, Button } from "@material-ui/core";
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import DashBord from 'pages/components/dash'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { operationStatByUser } from 'redux/actions/apiActions'
import ModalListOperation from "./components/modal/ModalListOperation";
import {useRouteMatch} from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 20,
        '& :hover': {
            background: theme.palette.primary.main,
            border: 0,
            padding: '10 30px',
            textDecoration: 'none',
            color:'white'
        },
        marginRight: 20,
    },

    media: {
        height: 140,
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    paper: {
        padding: 10,
        marginBottom: 20
    },
    line: {
        marginBottom: 20
    },
    cardLogo:{
        display:'flex',
        flexDirection:'column',
        margin:20,
        justifyContent:'center',
        alignItems:'center'
    },
    img:{
        objectFit: 'fill',
        width:50,
        borderRadius:100,
        
    },
    eheader:{
        display:'flex',
        width:'100%',
        paddingTop:20,
        paddingBottom:20,
        backgroundColor:theme.palette.primary.main
    },
    boxheader:{
        display:'flex',
        width:'60%',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
        [theme.breakpoints.up('md')]: {
            width:'60%',
        },
        justifyContent:'space-between',
        alignItems:'center',
        marginLeft:'auto',
        marginRight:'auto'
    },
    btnAdd:{
        background:'transparent !important',
        paddingLeft:20,
        paddingRight:20,
        color:'white',
        border:'1px solid white',
        borderRadius:0,
        marginLeft:20,
        "&:hover":{
            backgroundColor:theme.palette.secondary.main,
        }
    },
    rcontainer:{
        display:'flex',
        flexDirection:'column',
        marginTop:75,
        width:'75%',
        marginLeft:'auto',
        marginRight:'auto'
    }
}));
function OperationsFonctions(props) {
    const classes = useStyles();
    const { handleCliqueFonction, user: { credentials } } = props
    const {path,url} =useRouteMatch()
    const {codepartenaire}=credentials
    const [currentStat, setCurrentStat] = useReducer((state, newState) => ({ ...state, ...newState }), {})
    const [currentStatSin, setCurrentStatSin] = useReducer((state, newState) => ({ ...state, ...newState }), {})
    const [currentStatPres, setCurrentStatPres] = useReducer((state, newState) => ({ ...state, ...newState }), {})
    const [currentStatMod, setCurrentStatMod] = useReducer((state, newState) => ({ ...state, ...newState }), {})
    const curapp = url.split('/')[2]

    useEffect(() => {
        let dataSend = (curapp!=="esinistre")?{ groupe: "ETAPE" }:{groupe: "ETAPE", groupeoperation: "SIN" }
        operationStatByUser(dataSend).then(res => {
            if (res) {
                let dataEl
                dataEl = res.filter(item => item.Libelle === 'nonTransmis')
                const nonTransmis = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'transmis')
                const transmis = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'accepter')
                const accepter = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'rejeter')
                const rejeter = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'annuler')
                const annuler = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                setCurrentStat({
                    ['nonTransmis']: nonTransmis,
                    ['transmis']: transmis,
                    ['accepter']: accepter,
                    ['rejeter']: rejeter,
                    ['annuler']: annuler,
                    ['total']: (Number(nonTransmis) + Number(transmis) + Number(accepter) + Number(rejeter) + Number(annuler)),
                })
            }
        })

        //Etat des traitements
        dataSend = { groupeoperation: "SIN" }
        operationStatByUser(dataSend).then(res => {
            if (res) {
                const sindata = res[0];
                setCurrentStatSin({
                    ['total']: sindata['Nombre'] || 0,
                })
            }
        })
        //Etat des sinistre
        dataSend = { groupe: "ETATTRT", groupeoperation: "SIN" }
        operationStatByUser(dataSend).then(res => {
            if (res) {
                let dataEl
                dataEl = res.filter(item => item.Libelle === 'En traitement')
                const EnTrt = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'Bon Emis')
                const bonEmis = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'Bon Edité')
                const bonEdite = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                setCurrentStatSin({
                    ['EnTrt']: EnTrt,
                    ['bonEmis']: bonEmis,
                    ['bonEdite']: bonEdite,
                })
            }
        })

        //Total sinistre
        dataSend = { groupe: "ETATTRT", groupeoperation: "SIN" }
        operationStatByUser(dataSend).then(res => {
            if (res) {
                let dataEl
                dataEl = res.filter(item => [4, 5].includes(item.etattraitement))
                let enAttRemise = 0
                if (dataEl && dataEl.length !== 0) {
                    dataEl.forEach(el => {
                        enAttRemise += el['Nombre']
                    })
                }
                dataEl = res.filter(item => [6, 7].includes(item.etattraitement))
                let remisArchi = 0
                if (dataEl && dataEl.length !== 0) {
                    dataEl.forEach(el => {
                        remisArchi += el['Nombre']
                    })
                }
                setCurrentStatSin({
                    ['enAttRemise']: enAttRemise,
                    ['remisArchi']: remisArchi,
                })
            }
        })
        //Gestion des prestations
        //total prestation
        dataSend = { groupeoperation: "PRES" }
        operationStatByUser(dataSend).then(res => {
            if (res) {
                const presdata = res[0];
                setCurrentStatPres({
                    ['total']: presdata['Nombre'] || 0,
                })
            }
        })
        dataSend = { groupe: "ETATTRT", groupeoperation: "PRES" }
        operationStatByUser(dataSend).then(res => {
            if (res) {
                let dataEl
                dataEl = res.filter(item => [0, 1, 2, 3].includes(item.etattraitement))
                let enTrt = 0
                if (dataEl && dataEl.length !== 0) {
                    dataEl.forEach(el => {
                        enTrt += el['Nombre']
                    })
                }

                dataEl = res.filter(item => [4, 5].includes(item.etattraitement))
                let enAttRemise = 0
                if (dataEl && dataEl.length !== 0) {
                    dataEl.forEach(el => {
                        enAttRemise += el['Nombre']
                    })
                }

                dataEl = res.filter(item => [6].includes(item.etattraitement))
                let chqRemis = 0
                if (dataEl && dataEl.length !== 0) {
                    dataEl.forEach(el => {
                        chqRemis += el['Nombre']
                    })
                }
                dataEl = res.filter(item => [7].includes(item.etattraitement))
                let archive = 0
                if (dataEl && dataEl.length !== 0) {
                    dataEl.forEach(el => {
                        archive += el['Nombre']
                    })
                }
                setCurrentStatSin({
                    ['enTrt']: enTrt,
                    ['enAttRemise']: enAttRemise,
                    ['chqRemis']: chqRemis,
                    ['archive']: archive,
                })
            }
        })
        //Gestion des autres demandes
        dataSend = { groupeoperation: "MOD" }
        operationStatByUser(dataSend).then(res => {
            if (res) {
                const presdata = res[0];
                setCurrentStatMod({
                    ['total']: presdata['Nombre'] || 0,
                })
            }
        })
        dataSend = { groupe: "ETATTRT", groupeoperation: "MOD" }
        operationStatByUser(dataSend).then(res => {
            if (res) {
                let dataEl
                dataEl = res.filter(item => [0, 1, 2, 3, 4, 5].includes(item.etattraitement))
                let enTrt = 0
                if (dataEl && dataEl.length !== 0) {
                    dataEl.forEach(el => {
                        enTrt += el['Nombre']
                    })
                }
                dataEl = res.filter(item => [6, 7].includes(item.etattraitement))
                let remisArchi = 0
                if (dataEl && dataEl.length !== 0) {
                    dataEl.forEach(el => {
                        remisArchi += el['Nombre']
                    })
                }
                setCurrentStatMod({
                    ['enTrt']: enTrt,
                    ['remisArchi']: remisArchi,
                })
            }
        })
    }, [])
    const [openList, setOpenList] = useState(false)
    const [etats, setEtates] = useReducer((state, newState) => ({ ...state, ...newState }), {
        etape: null,
        etattrt: null,
        etatedit: null,
        mode: null
    })

    const handleTrtOpenList = (mode, etap, ettrt, etedit) => {
        setEtates({
            ['etape']: etap ? etap : null,
            ['etattrt']: ettrt ? ettrt : null,
            ['etatedit']: etedit ? etedit : null,
            ['mode']: mode ? mode : null
        })
        setOpenList(true)
    }
    const handleTrtCloseList = () => {
        setEtates({
            ['etape']: null,
            ['etattrt']: null,
            ['etatedit']: null,
            ['mode']: null
        })
        setOpenList(false)
    }
    return (
        <div>
            <div className={classes.eheader}>
                <div className={classes.boxheader}>
                    <div className={classes.cardLogo}>
                        <img className={classes.img} src={require(`assets/images/${(codepartenaire &&codepartenaire!=='')?codepartenaire+'.jpg':"LLV.jpg"}`).default} />
                        <span style={{fontStyle:'bold',color:'white',fontSize:26}}>{credentials['partenaire']||""}</span>
                    </div>
                    <span style={{fontStyle:'bold',color:'white',fontSize:30}}>{curapp!=="esinistre"?"Bienvenue sur E-Prestations":"Bienvenue sur E-Sinistre"}</span>
                    <div> </div>
                </div>
            </div>
            {openList && <ModalListOperation open={openList} etats={etats} handleClose={handleTrtCloseList} credentials={credentials} />}
            <div className={classes.rcontainer}>
                
                    <Typography varaiant='h2' component='h2'>Nouvelles déclarations</Typography>
                    <Divider light className={classes.line} />
                    <div style={{display:'flex', justifyContent:'center'}}>
                    {curapp==="esinistre"&&<Card className={classes.root}>
                            <CardActionArea onClick={event => handleCliqueFonction('ADDOP', 'SIN')}>
                                <CardContent>
                                    <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large, classes.green)}><Add /></Avatar></div>
                                    <Typography align='center' gutterBottom variant="h5" component="h5">
                                        Déclarer un sinistre
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>}
                        {curapp!=="esinistre"&&<Card className={classes.root}>
                            <CardActionArea onClick={event => handleCliqueFonction('ADDOP', 'PRES')}>
                                <CardContent>
                                    <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><AddBoxIcon /></Avatar></div>
                                    <Typography align='center' gutterBottom variant="h5" component="h5">
                                        Demander une prestation
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>}
                        {curapp!=="esinistre"&&<Card className={classes.root}>
                            <CardActionArea onClick={event => handleCliqueFonction('ADDOP', 'MOD')}>
                                <CardContent>
                                    <div className={classes.avatar}>
                                        <Avatar aria-label="recipe" className={clsx(classes.large, classes.pink)}><EditIcon /></Avatar>
                                    </div>
                                    <Typography align='center' gutterBottom variant="h5" component="h5">
                                        Demander une modification
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>}
                    </div>
                
            </div>
            <div className={classes.rcontainer}>
                <Typography varaiant='h2' component='h2'>{curapp!=="esinistre"?"Gestion des prestations":"Gestion des sinistres"}</Typography>
                <DashBord>
                    <DashBord.Base>
                        <DashBord.Inner>
                            <DashBord.Text>{currentStat['total'] || ""}</DashBord.Text>
                            <DashBord.Label>Total opérations</DashBord.Label>
                            <DashBord.CButton onClick={(e) => handleCliqueFonction('LISTOP', 'ALL')}>Détails </DashBord.CButton>
                        </DashBord.Inner>
                        <Divider variant="middle" orientation="vertical" flexItem />
                        <DashBord.Inner>
                            <DashBord.Text>{currentStat['nonTransmis'] || ""}</DashBord.Text>
                            <DashBord.Label>En attente de transmission</DashBord.Label>
                            <DashBord.CButton onClick={(e) => handleCliqueFonction('LISTOP', 'NTR')}>Détails </DashBord.CButton>
                        </DashBord.Inner>
                        <Divider variant="middle" orientation="vertical" flexItem />
                        <DashBord.Inner>
                            <DashBord.Text>{currentStat['transmis'] || ""}</DashBord.Text>
                            <DashBord.Label>En attente d'acceptation</DashBord.Label>
                            <DashBord.CButton onClick={(e) => handleCliqueFonction('LISTOP', 'TRA')}>Détails </DashBord.CButton>
                        </DashBord.Inner>
                        <Divider variant="middle" orientation="vertical" flexItem />
                        <DashBord.Inner>
                            <DashBord.Text>{currentStat['accepter'] || ""}</DashBord.Text>
                            <DashBord.Label>En traitement</DashBord.Label>
                            <DashBord.CButton onClick={(e) => handleCliqueFonction('LISTOP', 'TRT')}>Détails </DashBord.CButton>
                        </DashBord.Inner>
                        <Divider variant="middle" orientation="vertical" flexItem />
                        <DashBord.Inner>
                            <DashBord.Text>{currentStat['rejeter'] || ""}</DashBord.Text>
                            <DashBord.Label>Total prestations ajournées</DashBord.Label>
                            <DashBord.CButton onClick={(e) => handleCliqueFonction('LISTOP', 'RJ')}>Détails </DashBord.CButton>

                        </DashBord.Inner>
                        <Divider variant="middle" orientation="vertical" flexItem />
                        <DashBord.Inner>
                            <DashBord.Text>{currentStat['annuler'] || ""}</DashBord.Text>
                            <DashBord.Label>Total prestations annulées</DashBord.Label>
                            <DashBord.CButton onClick={(e) => handleCliqueFonction('LISTOP', 'AN')}>Détails </DashBord.CButton>
                        </DashBord.Inner>

                    </DashBord.Base>
                </DashBord>
            </div>

            {curapp==='esinistre'&& <div className={classes.rcontainer}>
                <Typography varaiant='h2' component='h2'>Sinistres</Typography>
                <DashBord>
                    <DashBord.Base>
                        <DashBord.Inner style={{ flex: 1 }}>
                            <DashBord.Text>{currentStatSin['total'] || ""}</DashBord.Text>
                            <DashBord.Label>Total sinistres</DashBord.Label>
                            <DashBord.DisButton onClick={(e) => handleTrtOpenList("SIN")}><ExpandMoreIcon /> </DashBord.DisButton>
                        </DashBord.Inner>
                        <Divider variant="middle" orientation="vertical" flexItem />
                        <DashBord.Inner style={{ flex: 1 }}>
                            <DashBord.Text> {currentStatSin['enTrt'] || ""}</DashBord.Text>
                            <DashBord.Label>En instruction</DashBord.Label>
                            <DashBord.DisButton onClick={(e) => handleTrtOpenList("SIN", 3, "1,2")}><ExpandMoreIcon /> </DashBord.DisButton>
                        </DashBord.Inner>
                        <Divider variant="middle" orientation="vertical" flexItem />
                        <DashBord.Inner style={{ flex: 1 }}>
                            <DashBord.Text> {currentStatSin['enTrt'] || ""}</DashBord.Text>
                            <DashBord.Label>{`Validés (Bon pour paiement)\n/\nBon de prise en charge disponible`}</DashBord.Label>

                            <DashBord.DisButton onClick={(e) => handleTrtOpenList("SIN", 3, "3")}><ExpandMoreIcon /> </DashBord.DisButton>
                        </DashBord.Inner>
                        <Divider variant="middle" orientation="vertical" flexItem />
                        <DashBord.Inner style={{ flex: 1 }}>
                            <DashBord.Text>{currentStatSin['enAttRemise'] || ""}</DashBord.Text>
                            <DashBord.Label>Ordres de paiement</DashBord.Label>
                            <DashBord.DisButton onClick={(e) => handleTrtOpenList("SIN", 3, "4")}><ExpandMoreIcon /> </DashBord.DisButton>
                        </DashBord.Inner>
                        <Divider variant="middle" orientation="vertical" flexItem />

                        <DashBord.Inner style={{ flex: 1 }}>
                            <DashBord.Text>{currentStatSin['remisArchi'] || ""}</DashBord.Text>
                            <DashBord.Label>Réglé</DashBord.Label>
                            <DashBord.DisButton onClick={(e) => handleTrtOpenList("SIN", 3, "5,6,7")}><ExpandMoreIcon /> </DashBord.DisButton>
                        </DashBord.Inner>
                    </DashBord.Base>
                </DashBord>
            </div>}
            {curapp!=='esinistre'&&<div className={classes.rcontainer}>
                <Typography varaiant='h2' component='h2'>Prestations</Typography>
                <DashBord>
                    <DashBord.Base>
                        <DashBord.Inner style={{ flex: 1 }}>
                            <DashBord.Text>{currentStatPres['total'] || ""}</DashBord.Text>
                            <DashBord.Label>Total prestations</DashBord.Label>
                            <DashBord.DisButton onClick={(e) => handleTrtOpenList("PRES")}><ExpandMoreIcon /> </DashBord.DisButton>
                        </DashBord.Inner>
                        <Divider variant="middle" orientation="vertical" flexItem />
                        <DashBord.Inner style={{ flex: 1 }}>
                            <DashBord.Text>{currentStatPres['enTrt'] || ""}</DashBord.Text>
                            <DashBord.Label>En instruction</DashBord.Label>
                            <DashBord.DisButton onClick={(e) => handleTrtOpenList("SIN", 3, "1,2,3")}><ExpandMoreIcon /> </DashBord.DisButton>
                        </DashBord.Inner>
                        <Divider variant="middle" orientation="vertical" flexItem />
                        <DashBord.Inner style={{ flex: 1 }}>
                            <DashBord.Text>{currentStatPres['enTrt'] || ""}</DashBord.Text>
                            <DashBord.Label>Validées (Bon pour paiement)</DashBord.Label>
                            <DashBord.DisButton onClick={(e) => handleTrtOpenList("SIN", 3, "3")}><ExpandMoreIcon /> </DashBord.DisButton>
                        </DashBord.Inner>
                        <Divider variant="middle" orientation="vertical" flexItem />
                        <DashBord.Inner style={{ flex: 1 }}>
                            <DashBord.Text>{currentStatPres['enAttRemise'] || ""}</DashBord.Text>
                            <DashBord.Label>Ordres de paiement</DashBord.Label>
                            <DashBord.DisButton onClick={(e) => handleTrtOpenList("SIN", 3, "4")}><ExpandMoreIcon /> </DashBord.DisButton>
                        </DashBord.Inner>
                        <Divider variant="middle" orientation="vertical" flexItem />
                        <DashBord.Inner style={{ flex: 1 }}>
                            <DashBord.Text>{currentStatPres['chqRemis'] || ""}</DashBord.Text>
                            <DashBord.Label>Réglées</DashBord.Label>
                            <DashBord.DisButton onClick={(e) => handleTrtOpenList("SIN", 3, '5,6,7')}><ExpandMoreIcon /> </DashBord.DisButton>
                        </DashBord.Inner>

                    </DashBord.Base>
                </DashBord>
            </div>}
            {curapp!=='esinistre'&&<div className={classes.rcontainer}>
                <Typography varaiant='h2' component='h2'>Gestion des demandes de modification</Typography>
                <DashBord>
                    <DashBord.Base>
                        <DashBord.Inner style={{ flex: 1 }}>
                            <DashBord.Text>{currentStatMod['total'] || ""}</DashBord.Text>
                            <DashBord.Label>Total demandes</DashBord.Label>
                            <DashBord.DisButton onClick={(e) => handleTrtOpenList("MOD")}><ExpandMoreIcon /> </DashBord.DisButton>
                        </DashBord.Inner>
                        <Divider variant="middle" orientation="vertical" flexItem />
                        <DashBord.Inner style={{ flex: 1 }}>
                            <DashBord.Text>{currentStatMod['enTrt'] || ""}</DashBord.Text>
                            <DashBord.Label>En instruction</DashBord.Label>
                            <DashBord.DisButton onClick={(e) => handleTrtOpenList("MOD", 3, "1,2,3")}><ExpandMoreIcon /> </DashBord.DisButton>
                        </DashBord.Inner>
                        <Divider variant="middle" orientation="vertical" flexItem />
                        <DashBord.Inner style={{ flex: 1 }}>
                            <DashBord.Text>{currentStatMod['remisArchi'] || ""}</DashBord.Text>
                            <DashBord.Label>Traitement terminé</DashBord.Label>
                            <DashBord.DisButton onClick={(e) => handleTrtOpenList("PRES", 3, "4,5,6,7")}><ExpandMoreIcon /> </DashBord.DisButton>
                        </DashBord.Inner>

                    </DashBord.Base>
                </DashBord>
            </div>}
            
        </div>
    )
}


const updateField = (field, data) => (dispatch) => dispatch(change('addOper', field, data))
const mapPropsActions = {
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    ui: state.UI,
    data: getFormValues('addOper')(state)
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({ destroyOnUnmount: false, })(OperationsFonctions))