import React,{useState} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/llv//components/customInputStyle.js";
import TextField from '@material-ui/core/TextField';
const useStyles = makeStyles(styles);


export default function CustomDate(props) {
  const classes = useStyles();
  const {
    formControlProps,
    label,
    id,
    name,
    labelProps,
    InputProps,
    error,
    success,
    value,
    input,
    defaultValue,
    ...rest
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: label === undefined
  });

   
  return ( 
    <FormControl
    {...formControlProps}
    className={formControlProps.className + " " + classes.formControl}
  >
            <TextField
                id={id}
                name={name}
                label={label}
                variant="outlined"
                className={classes.labelClasses}
                InputLabelProps={{
                    shrink: true,
                    
                }}
                defaultValue={defaultValue}
                value={input?input.value:value}
                {...InputProps}
              onChange={input?input.onChange:props.onChange}
              {...rest}
        />
         {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl> 
  )
}

CustomDate.propTypes = {
  label: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  InputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool
};
