import React, { useEffect, useState, useReducer } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import { connect } from 'react-redux'
import { reduxForm, change} from 'redux-form';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from "components/CustomButtons/Button.js";
import { getOperationByUser, operationStatByUser, updateOperation } from 'redux/actions/apiActions'
import dayjs from 'dayjs'
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import SendIcon from '@material-ui/icons/Send';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import MaterialTable from 'material-table'
import FilterListIcon from '@material-ui/icons/FilterList';
import CircularProgress from '@material-ui/core/CircularProgress';
import XLSX from 'xlsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import AssignmentIcon from '@material-ui/icons/Assignment';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AttachmentIcon from '@material-ui/icons/Attachment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import {
    getEquipesReseau,
    getEquipesZones,
    getZonesReseau,
    getZonesNoDispatch,
    getEquipesNoDispatch
} from 'redux/actions/settingsAction'
import RefreshIcon from '@material-ui/icons/Refresh';
import DialogBox from './components/DialogBox'
import CustomAlert from 'components/CustomAlert/CustomAlert'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ImportDocument from 'pages/Banking/Propositions/ImportDocument';
import ImportDocumentList from 'pages/Banking/Propositions/ImportDocumentList'
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import {ficheOperation} from 'application';
import MiniHeader from 'components/MiniHeader';
import swal from 'sweetalert';
const exportToExcel = (columns, data) => {
    // determining header labels
    const columnInfo = columns.reduce(
        (acc, column) => {
            const headerLabel = column.title || column.field;
            acc.header.push(headerLabel);
            acc.map[column.field] = headerLabel;
            return acc;
        },
        { map: {}, header: [] }
    );

    const mappedData = data.map(row =>
        Object.entries(row).reduce((acc, [key, value]) => {
            if (columnInfo.map[key]) {
                acc[columnInfo.map[key]] = value;
            }
            return acc;
        }, {})
    );

    var ws = XLSX.utils.json_to_sheet(mappedData, {
        header: columnInfo.header, // garanties the column order same as in material-table
    });

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* generate an XLSX file and triggers the browser to generate the download*/
    XLSX.writeFile(wb, `LLV-liste-Operation.xlsx`);
};

const CustumLinearProgress = withStyles((theme) => ({
    root: {
        height: 20,
        borderRadius: 10,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 10,
        backgroundColor: props => props.currentColor,
    },
}))(LinearProgress);

const useStyles = makeStyles(theme => ({
    paper: {
        marginBottom: 20,
        padding: 20,
        width:'75%',
        marginLeft:'auto',
        marginRight:'auto',
    },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
    },

    hearderContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerTitle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    headerFilter: {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    headerState: {
        display: 'flex',
        flex: 2,
        justifyContent: 'center',
        alignItems: 'center'
    },
    headerButton: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    tablibOper: {
        display: 'flex',
        flexDirection: 'column'
    },
    filterContainer: {
        display: 'flex',
        flexWrap: "wrap"
    },
    filterItem: {
        display: 'flex',
        flexDirection: 'column'

    },
    filterBtn: {
        display: 'flex',
        flex: 1
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: 10
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    buttonSearch: {
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,

        color: '#FFF',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
        }
    }
}));

function OperationList(props) {
    const classes = useStyles();
    const { mode, currentstate, user: { credentials }, handleCliqueFonction } = props
    const [operList, setOperList] = useState(null)
    const [titre, setTitre] = useState("")
    const [operStat, setOperStat] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            total: 0,
            nonTransmis: 0,
            transmis: 0,
            accepter: 0,
            rejeter: 0,
            annuler: 0
        });


    const [loading, setLoading] = useState(false)
    const calcProgressValue = (currentval, maxmal) => {
        return parseInt((currentval * 100) / maxmal)
    }
    const [showMoreFilter, setShowMoreFilter] = useState(false)
    const handleClick = () => {

    }
    //retour à l'accueil
    const handleBackOpeHome = () => {
        handleCliqueFonction("HOME")
    }
    const handleClickFilter = () => {
        setShowMoreFilter(!showMoreFilter)
    }
    // selection des données 
    const [dataSelected, setDataSelected] = useState(null)
    const handleDataSelected = (dataRow) => {
        setDataSelected(dataRow);
    }

    
    //gestion d' nouvelle opération
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleOptionClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleOptionClose = () => {
        setAnchorEl(null);
    };
    const nouvelleOperation = (modeOpe) => {
        handleOptionClose()
        handleCliqueFonction('ADDOP', modeOpe)
    }

    //Gestion de la transmission
    const [dopen, setDopen] = useState(false)
    const handleDopen = (etape) => {
        setDopen(etape)
    }
    const [dinfo, setDinfo] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            ddata: [],
            dmessage: null,
            ddescription: null,
            dTexteButton: null,
            dmode: null,
            daction: null
        });

    const [aopen, setAopen] = useState(false)
    const [aInfo, setAinfo] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            amessage: null,
            amode: null,
        });
    
    const transmissionOperation = async (donnees) => {
        if (!donnees) { return }
        if (donnees.length !== 0) {
            await Promise.all(donnees.map((operation) => {
                return updateOperation({ id: operation.id, typeop: 'TR' }).then((ctr) => {
                    if (ctr) {
                        handleOperList()
                        return ctr
                    } else {
                        return null
                    }
                })
            })).then(resultat => {
                if (resultat) {
                    swal('Succès',"Tranmission effectué avec succès","succes")
                    handleDopen(false)
                    setAopen(true)
                } else {
                    swal('Erreur',"Erreur incconnu lors de la transmission","error")
                    handleDopen(false)
                    setAopen(true)
                }
            }).catch((err) => {
                setAinfo({
                    ['amessage']: "Erreur incconnu lors de la transmission",
                    ['amode']: "error"
                })
                handleDopen(false)
                setAopen(true)
            })
        }
    }
    const handleTransmission = () => {
        const datatrans = dataSelected && dataSelected.filter(item => item.etape == 1)
        if (datatrans && datatrans.length !== 0) {
            setDinfo({
                ['ddata']: datatrans,
                ['dmessage']: "Voulez-vous vraiment transmettre les opérations ?",
                ['dTexteButton']: "TRANSMETTRE",
                ['dmode']: 2,
                ['daction']: () => transmissionOperation(datatrans)
            })
            setDopen(true)
        } else {
            swal('Attention',"Veuillez selectionner au moins une opération","warning")
            setAopen(true)
        }

    }

    //Gestion de la Annulation
    const handleAnnulation = () => {
        const dataAnnul = dataSelected && dataSelected.filter(item => (item.etape == 1 || item.etape == 4))
        if (dataAnnul && dataAnnul.length !== 0) {
            setDinfo({
                ['ddata']: dataAnnul,
                ['dmessage']: "Voulez-vous vraiment annuler les opérations ?",
                ['dTexteButton']: "ANNULER",
                ['dmode']: 2,
                ['daction']: () => AnnulationOperation(dataAnnul)
            })
            setDopen(true)
        } else {
            swal('Attention',"Veuillez selectionner au moins une opération","warning")
            setAopen(true)
        }
    }
    const AnnulationOperation = async (donnees) => {
        if (!donnees) { return }
        if (donnees.length !== 0) {
            await Promise.all(donnees.map((operation) => {
                return updateOperation({ id: operation.id, typeop: 'AN' }).then((ctr) => {

                    if (ctr) {
                        handleOperList()
                        return ctr
                    } else {
                        return null
                    }
                })
            })).then(resultat => {
                if (resultat) {
                    setAinfo({
                        ['amessage']: "Annulation effectué avec succès",
                        ['amode']: "success"
                    })
                    handleDopen(false)
                    setAopen(true)
                } else {
                    swal('Erreur',"Erreur incconnu lors de l'annulation","error")
                    handleDopen(false)
                    setAopen(true)
                }
            }).catch((err) => {
                swal('Erreur',"Erreur incconnu lors de l'annulation","error")
                handleDopen(false)
                setAopen(true)
            })
        }
    }
    //Gestion de l'édition
    const editOperation = (dataEdit) => {
        handleCliqueFonction('EDITOP', dataEdit.groupeoperation, dataEdit)
    }

    //Gestion des documents
    const [anchorDocEl, setAnchorDocEl] = React.useState(null);
    const handleDocumentClick = (event) => {
        setAnchorDocEl(event.currentTarget);
    };
    const handleDocumentClose = () => {
        setAnchorDocEl(null);
    };

    //doumments
    const [currentData,setCurrentData] = useState([])
    const [opendoc,setOpendoc] = useState('')
    const handleDocClose =() =>{
        setOpendoc('')
    }
    const handleDocOpen =(tp) =>{
        setOpendoc(tp)
    }

    const handleListeDocuments = (dt) => {
        setCurrentData(dt);
        setAnchorDocEl(null);
        handleDocOpen('list')
    }
    const handleAttacheDocRow = (dt) => {
        setCurrentData(dt);
        handleDocOpen('att')
    }

    //Gestion paramètres d'actualisation
    const [filterField, setFilterField] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            partenaire: credentials ? credentials.codereseau : 0,
            zone: [5, 7, 10].includes(credentials.coderole) ? credentials.codezone : 0,
            agence: [5, 10].includes(credentials.coderole) ? credentials.codeequipe : 0,
            groupeoperation: mode,
            etape: currentstate,
            groupe: 'ETAPE'
        });
    const handleFilterChange = (event) => {
        const { name, value } = event.target
        setFilterField({
            [name]: value
        })
    }
    const handleOperList = () => {
        setLoading(true)
        switch (currentstate) {
            case 0:
                setTitre("Opérations annulées")
                break;
            case 1:
                setTitre("En attente de transmission")
                break;
            case 2:
                setTitre("En attente de validation")
                break;
            case 3:
                setTitre("Acceptés/En traitement")
                break;
            case 4:
                setTitre("Opération ajournées")
                break;
            default:
                setTitre("")
                break;
        }
        getOperationByUser(filterField).then(res => {
            if (res) {
                setOperList(res)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }).catch(err => {
            console.log(err);
            setLoading(false)
        })
        console.log(filterField);
        operationStatByUser(filterField).then(res => {
            let tot = 0
            if (res && res.length !== 0) {
                res.forEach(element => {
                    setOperStat({ [element['Libelle']]: element['Nombre'] })
                    tot += element['Nombre']
                });
                if (tot !== 0) {
                    setOperStat({ ['total']: tot })
                }
            }
        })
    }
    useEffect(() => {
        handleOperList()
    }, [currentstate])
    const [zoneList, setZoneList] = useState([])
    const handleZoneList = () => {
        if (filterField.partenaire && filterField.partenaire !== '') {
            getZonesReseau(filterField.partenaire).then(res => {
                if (res) {
                    setZoneList(res)
                }
            })
        } else {
            getZonesNoDispatch().then(res => {
                if (res) {
                    setZoneList(res)
                }
            })
        }
    }
    //récupération des agence/equipe
    const [agenceList, setAgenceList] = useState([])
    const handleAgenceList = () => {
        if (filterField.partenaire && filterField.partenaire !== '') {
            getEquipesReseau(filterField.partenaire).then(res => {
                if (res) {
                    setAgenceList(res)
                }
            })
        } else if (filterField.zone && filterField.zone !== '') {
            getEquipesZones(filterField.zone).then(res => {
                if (res) {
                    setAgenceList(res)
                }
            })
        } else {
            getEquipesNoDispatch().then(res => {
                if (res) {
                    setAgenceList(res)
                }
            })
        }
    }
    useEffect(() => {
        handleZoneList()
    }, [filterField.partenaire])
    useEffect(() => {
        handleAgenceList()
    }, [filterField.zone, filterField.partenaire])
    //Fin paramètres d'actualisation
    //Debut processus principal
    const editTicket = (dataOperation)=>{
        if(dataOperation){
            ficheOperation(dataOperation,credentials)
        }
    }
    return (
        <div>
            <MiniHeader titre="Gestion des prestations" showbtn={false} plateforme='prestation'/>
            <div style={{marginTop:40}}></div>
            <ImportDocumentList open={opendoc==='list'} source='OP' handleOpen={()=>handleListeDocuments('list')} handleClose={handleDocClose} data={currentData} />
            <ImportDocument open={opendoc==='att'} source='OP' handleOpen={()=>handleListeDocuments('att')} handleClose={handleDocClose} data={currentData} />
            <Paper elevation={0} className={classes.paper}>
                <div className={classes.headerTitle}>
                    <Typography className={classes.heading}>Liste des opérations</Typography>
                </div>
                <Divider />
            </Paper>

            <Paper elevation={0} className={classes.paper}>
                <div className={classes.hearderContainer}>
                    <div className={classes.headerFilter} >
                        <Tooltip title="Retour" placement="bottom-start">
                            <IconButton onClick={handleBackOpeHome} color="primary" aria-label="Retour">
                                <ArrowBackIcon fontSize='large' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={showMoreFilter ? "Masquer le filtrer" : "Plus de filtre"} placement="bottom-start">
                            <IconButton onClick={handleClickFilter} color={showMoreFilter ? "primary" : "secondary"} aria-label="Filtre">
                                <FilterListIcon fontSize='large' />
                            </IconButton>
                        </Tooltip>
                        <div className={classes.headerState}>
                            <Chip avatar={<Avatar>{operStat['total'] || ""}</Avatar>} label="Total" onClick={handleClick} />
                            <Chip avatar={<Avatar>{operStat['nonTransmis'] || ""}</Avatar>} label="Non transmis" onClick={handleClick} />
                            <Chip avatar={<Avatar>{operStat['transmis'] || ""}</Avatar>} label="Transmis" onClick={handleClick} />
                            <Chip avatar={<Avatar>{operStat['accepter'] || ""}</Avatar>} label="Acceptés" onClick={handleClick} />
                            <Chip avatar={<Avatar>{operStat['rejeter'] || ""}</Avatar>} label="Ajourné(s)" onClick={handleClick} />
                            <Chip avatar={<Avatar>{operStat['annuler'] || ""}</Avatar>} label="Annulé(s)" onClick={handleClick} />
                        </div>
                    </div>
                    <div className={classes.headerButton}>
                        <Tooltip title="Nouveau" placement="bottom-start">
                            <IconButton color="primary" aria-label="Nouveau" aria-controls="add-menu" aria-haspopup="true" onClick={handleOptionClick}>
                                <Add fontSize='large' />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="add-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleOptionClose}
                        >
                            <MenuItem onClick={() => nouvelleOperation('SIN')}>DECLARER UN SINISTRE</MenuItem>
                            <MenuItem onClick={() => nouvelleOperation('PRES')}>DEMANDER UNE PRESTATION</MenuItem>
                            <MenuItem onClick={() => nouvelleOperation('MODE')}>DEMANDER UNE MODIFICATION</MenuItem>
                        </Menu>
                        <Tooltip title="Transmesttre" placement="bottom-start">
                            <IconButton color="primary" aria-label="Transmettre" onClick={handleTransmission}>
                                <SendIcon fontSize='large' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Annulés" placement="bottom-start">
                            <IconButton color="primary" aria-label="Annulée" onClick={handleAnnulation}>
                                <HighlightOffIcon fontSize='large' />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Paper>
            {showMoreFilter && <Paper elevation={0} className={classes.paper}>
                <LabelledOutline label="Filtres">
                    <div className={classes.filterContainer}>
                        <div className={classes.filterItem}>
                            <TextField
                                id="saisiedu"
                                name="saisiedu"
                                label="Saisie du"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                            <TextField
                                id="saisieau"
                                name="saisieau"
                                label="Au"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                        </div>
                        <Divider orientation='vertical' />
                        <div className={classes.filterItem}>
                            <TextField
                                id="operationdu"
                                name="operationdu"
                                label="Opération du"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                            <TextField
                                id="operationau"
                                name="operationau"
                                label="Au"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                        </div>
                        <Divider orientation='vertical' />
                        <div className={classes.filterItem}>
                            <TextField
                                id="tranmisdu"
                                name="tranmisdu"
                                label="Transmis du"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                            <TextField
                                id="tranmisau"
                                name="tranmisau"
                                label="Au"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                        </div>
                        <Divider orientation='vertical' />
                        <div className={classes.filterItem}>
                            <TextField
                                id="accepterdu"
                                name="accepterdu"
                                label="Accepté du"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                            <TextField
                                id="accepterau"
                                name="accepterau"
                                label="Au"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                        </div>
                        <Divider orientation='vertical' />
                        <div className={classes.filterItem}>
                            <TextField
                                id="rejetdu"
                                name="rejetdu"
                                label="Réjét du"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                            <TextField
                                id="rejetau"
                                name="rejetau"
                                label="Au"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                        </div>
                        <Divider orientation='vertical' />
                        <div className={classes.filterItem}>
                            {([9, 8].includes(credentials.coderole)) && <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Zone / inspection</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="zone"
                                    name="zone"
                                    value={filterField.zone}
                                    onChange={handleFilterChange}
                                    label="Zone / Inspection"
                                >
                                    <MenuItem value="0">
                                        <em>Aucun</em>
                                    </MenuItem>
                                    {(zoneList && zoneList.length !== 0) && (zoneList.map(zone => <MenuItem key={`${zone.id}`} value={zone.id}>{zone.libelle}</MenuItem>))}
                                </Select>
                            </FormControl>}
                            {([7, 8, 9].includes(credentials.coderole)) && <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Agence/Equipe</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="agence"
                                    name="agence"
                                    value={filterField.agence}
                                    onChange={handleFilterChange}
                                    label="Agence/Equipe"
                                >
                                    <MenuItem value="0">
                                        <em>Aucun</em>
                                    </MenuItem>
                                    {(agenceList && agenceList.length !== 0) && agenceList.map(ag => (<MenuItem key={ag.id} value={`${ag.id}`}>{ag.libelleequipe}</MenuItem>))}

                                </Select>
                            </FormControl>}
                        </div>
                        <div className={classes.filterBtn}>
                            <Button variant='outlined' color='primary' className={classes.buttonSearch} onClick={handleOperList} >
                                <RefreshIcon fontSize="large" /> ACTUALISER
                            </Button>
                        </div>
                    </div>
                </LabelledOutline>
            </Paper>}

            {!loading ? (
                <Paper elevation={0} className={classes.paper}>
                    <CustomAlert aopen={aopen} setAopen={setAopen} message={aInfo.amessage} mode={aInfo.amode} />
                    <DialogBox data={dinfo.ddata} dopen={dopen} dinfo={dinfo} daction={dinfo.daction} handleDopen={handleDopen} />
                    <MaterialTable
                        title={titre}
                        columns={[
                            {
                                field: 'libelletypeoperation',
                                render: rowData => (<div className={classes.tablibOper}>
                                    <Typography variant='h5'>{rowData.libelletypeoperation}</Typography>
                                    <Typography variant='caption'>{`Ref : ${rowData.codeoperation} \n`}</Typography>
                                    <Typography variant='caption'>{`Date :${dayjs(rowData.dateoperation).format('DD/MM/YYYY')}`}</Typography>
                                    {rowData.delaitraitement && <Typography variant='caption'>{`Traitement : ${rowData.delaitraitement} jour(s)`}</Typography>}
                                    {rowData.datefinprevu && <Typography variant='caption'>{`Fin traitement :${dayjs(rowData.datefinprevu).format('DD/MM/YYYY')}`}</Typography>}
                                </div>)

                            },
                            {
                                field: "idproposition",
                                render: rowData => (
                                    <div className={classes.tablibOper}>
                                        <Typography variant='h5'>{rowData.adherent}</Typography>
                                        <Typography variant='caption'>{`#ID : ${rowData.idproposition}`}</Typography>
                                        <Typography variant='caption'>{`Produit :${rowData.libelleproduit}`}</Typography>
                                        {rowData.dateeffet && <Typography variant='caption'>{`Date d' effet :${dayjs(rowData.dateeffet).format('DD/MM/YYYY')}`}</Typography>}
                                    </div>
                                ),
                                filtering: false
                            },
                            {
                                field: 'etape',
                                title: 'Etat',
                                width: "2%",
                                render: rowData => {
                                    let cvalue = 0
                                    let valpro = calcProgressValue(rowData.etape, 3)
                                    if (valpro < 50) {
                                        cvalue = valpro
                                    } else if (valpro > 50 && valpro < 75) {
                                        cvalue = 75
                                    } else if (valpro > 75 && rowData.etape === 4) {
                                        cvalue = 50
                                        valpro = 50
                                    } else {
                                        cvalue = 100
                                    }

                                    return <div className={classes.tablibOper}>
                                        <Typography variant='caption'>{`${cetape[rowData.etape]}`}</Typography>
                                        <CustumLinearProgress
                                            currentColor={cvalue < 50 ? "#E35F5B" : (cvalue === 50 ? "#ffc107" : (cvalue === 75 ? "#cddc39" : "#4caf50"))}
                                            variant="determinate"
                                            valueLabelDisplay="on"
                                            value={cvalue} />
                                        <Typography variant="body2" color="textSecondary">{`${Math.round(valpro)}%`}</Typography>
                                    </div>
                                },
                                lookup: { 0: 'Annulée', 1: 'Non tranmis', 2: "En attente d'acceptation", 3: 'Acceptée', 4: 'Ajournée' },
                            },
                            {
                                field: 'etattraitement',
                                title: 'Niveau de traitement',
                                lookup: rowData => (rowData.groupeoperation === 'SIN' || rowData.groupeoperation === 'PRES') ? { 0: '', 1: 'En attente de traitement', 2: 'Début du Traitement', 3: 'Fin du traitement', 4: 'Soumission', 5: 'Chèque/OV établi', 6: 'Chèque/OV signé', 7: 'Chèque OV remis', 8: 'Opération archivé ' } : { 0: '', 1: 'En attente de traitement', 2: 'Début du Traitement', 3: 'Fin du traitement' },
                                render: rowData => {
                                    const maxVal = (rowData.groupeoperation === 'SIN' || rowData.groupeoperation === 'PRES') === 'SIN' ? 8 : 3
                                    const cvalue = calcProgressValue(rowData.etattraitement, maxVal)
                                    const trtTab = (rowData.groupeoperation === 'SIN' || rowData.groupeoperation === 'PRES') ? cetattrt : cetatmod
                                    return <div className={classes.tablibOper}>
                                        <Typography variant='caption'>{`${rowData.etattraitement ? trtTab[rowData.etattraitement] : trtTab[0]}`}</Typography>
                                        <CustumLinearProgress
                                            currentColor={cvalue < 50 ? "#E35F5B" : (cvalue === 50 ? "#ffc107" : (cvalue === 75 ? "#cddc39" : "#4caf50"))}
                                            variant="determinate"
                                            value={cvalue}

                                        />
                                        <Typography variant="body2" color="textSecondary">{`${Math.round(cvalue)}%`}</Typography>
                                    </div>
                                },
                                width: "2%"
                            }
                        ]}

                        data={operList ? operList : []}
                        localization={{
                            toolbar: {
                                searchPlaceholder: 'Trouver une opération',
                                searchTooltip: 'Trouver une opération',
                                exportTitle: 'Exportation',
                                exportAriaLabel: 'Exportation',
                                exportCSVName: 'Exporter en Excel',
                                exportPDFName: 'Exporter en PDF',
                            },
                            body: {
                                emptyDataSourceMessage: 'Aucune donnée trouvée !'
                            },
                            pagination: {
                                labelRowsSelect: 'Ligne(s)',
                                labelDisplayedRows: '{count} sur {from}-{to}',
                                firstTooltip: 'Prémière page',
                                previousTooltip: 'Précédent',
                                nextTooltip: 'Suivant',
                                lastTooltip: 'Dernière page'
                            }
                        }}
                        options={{
                            filtering: true,
                            selection: true,
                            selectionProps: rowData => ({
                                disabled: rowData.etape === 3,
                                color: 'primary'
                            }),
                            exportButton: true,
                            actionsColumnIndex: -1,
                            exportCsv: (columns, data) => exportToExcel(columns, data, "LLV-Liste-opération"),
                            searchFieldStyle: {
                                fontSize: 18,
                                width: '100%',
                                height: 50,
                            },

                            pageSize: 20,
                            pageSizeOptions: [20, 40, 80, 100, 200, 300, 400, 500, 1000]
                        }}
                        onSelectionChange={(rows) => handleDataSelected(rows)}
                        actions={[
                            {
                                icon: () => <AttachFileIcon />,
                                tooltip: <Typography variant='h6'>Documents</Typography>,
                                position: "row",
                                onClick: handleDocumentClick
                            },
                            {
                                icon: () => <AssignmentIcon />,
                                tooltip: <Typography variant='h6'>Détails</Typography>,
                                position: "row",
                                onClick: (event, rowData) => editOperation(rowData),
                            },
                            {
                                icon: () => <ConfirmationNumberIcon />,
                                tooltip: <Typography variant='h6'>Ticket</Typography>,
                                position: "row",
                                onClick: (event, rowData) => editTicket(rowData),
                            }
                        ]}
                    />
                    <Menu
                        id="documents-menu"
                        anchorEl={anchorDocEl}
                        keepMounted={true}
                        open={Boolean(anchorDocEl)}
                        onClose={handleDocumentClose}
                    >
                        <MenuItem onClick={handleListeDocuments}>

                            <ListItemIcon>
                                <InsertDriveFileIcon color='primary' fontSize='large' />
                            </ListItemIcon>
                            <Typography variant="inherit">Liste des documents attachés</Typography>
                        </MenuItem>

                        <MenuItem onClick={handleAttacheDocRow}>
                            <ListItemIcon>
                                <AttachmentIcon color='primary' fontSize='large' />
                            </ListItemIcon>

                            <Typography variant="inherit">Joindre un documents</Typography>
                        </MenuItem>
                    </Menu>
                </Paper>
            ) : (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress color="secondary" /></div>)}

        </div>
    )
}
const cetape = ['Annulée', 'En attente de tranmission', "En attente d'acceptation", 'Acceptée', 'Ajournée']
const cetattrt = ["Aucune action", "En attente de traitement", "Début du Traitement", "Fin du traitement", "Soumission", "Chèque/OV", "Chèque/OV signé", "Chèque OV remis", "Opération archivé"]
const cetatmod = ["Aucune action", "En attente de traitement", "Début du Traitement", "Fin du traitement"]

const updateField = (field, data) => (dispatch) => dispatch(change('addOper', field, data))
const mapPropsActions = {
    updateField,
}
const mapStateToProps = (state) => ({
    user: state.user,
});
export default connect(mapStateToProps, mapPropsActions)(reduxForm({ form: 'addOper', destroyOnUnmount: false })(OperationList))
