import React, { useState, useReducer, useEffect,useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Field, reduxForm, change, registerField, getFormValues, reset } from 'redux-form';
import MaterialTable, { MTableToolbar } from 'material-table';
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from 'components/CustomSelect/CustomSelect.js'
import Button from "components/CustomButtons/Button.js";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomRadio from 'components/CustomRadio/CustomRadio'
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CustomDate from 'components/CustomDate/CustomDate'
import AddIcon from '@material-ui/icons/Add';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import { connect, useDispatch, useSelector } from 'react-redux'
import { getProduit } from 'redux/actions/apiActions'
import "moment/locale/fr";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { addSouscription, getPrimesCapital, getAdherentBni } from 'redux/actions/apiActions'
import ProfilAssure from 'pages/Banking/components/ProfilAssure'
import ProfilBeneficiaire from 'pages/Banking/components/ProfilBeneficiaire'
import ProfilSouscipteurInfo from 'pages/Banking/components/ProfilSouscipteurInfo'
import SouscriptionFicheresume from 'pages/Banking/components/SouscriptionFicheresume'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import { Grid } from '@material-ui/core'
import DocumentSouscription from 'pages/Banking/components/DocumentSouscription'
import dayjs from 'dayjs';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput'
import CircularProgress from "@material-ui/core/CircularProgress";
import { getBanqueAgences, getGarantiesAssure, getCapitauxGaranies, getGarantiesProduit } from "redux/actions/apiActions";
import { getUserData, ageAssure } from 'redux/actions/userActions'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CheckClient from 'pages/Banking/Propositions/components/CheckClient'
import PropoTransmission from 'pages/Banking/Propositions/PropoTransmission'
import { handleMoimemeAssure } from 'application'
import MiniHeader from "components/MiniHeader";
function CusAlert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const customTyles = (theme, styles) => ({
    ...styles,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    addRoot:{
        width:'75%',
        [theme.breakpoints.down('xs')]:{
            width:'100%',
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
        },
        [theme.breakpoints.up('md')]:{
            width:'75%',
        },
        marginLeft:'auto',
        marginRight:'auto'
    },
    addheader:{
        display:'flex',
        flexDirection:'column'
    },
    rootfab:{
        display: 'flex',
        justifyContent:'space-between',
        gap:20
    },
    rlink:{
        textDecoration:'none',
        color:theme.palette.primary.main,
        fontSize:16,
        "&:hover":{
            color:theme.palette.secondary.main,
        }
    },
    rbtnadd:{
        backgroundColor:"transparent",
        borderStyle:'solid',
        borderColor:theme.palette.primary.main,
        borderWidth:1,
        borderRadius:0,
        color:theme.palette.primary.main,
        "&:hover":{
            backgroundColor:theme.palette.primary.main,
            color:'white',
        }
    },
    warntext:{
        marginTop: 20,
        fontSize:18,
        color:theme.palette.secondary.main,

    },
    hTrait:{
        marginTop:20,
        marginBottom:20
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    rootExpan: {
        width: '100%',
        border:'none'
    },
    btnRun: {
        backgroundColor: theme.palette.primary.main,
         color:'white',
         '&:hover': {
             backgroundColor: theme.palette.secondary.main,
         }
     },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
    },
    paper: {
        width: '100%'
    },
    list: {
        width: '100%',
        padding: 5
    },
    containerRoot: {

    },
    buttonAdd: {
        width: '100%',
        marginBottom: 10,
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        borderRadius: 20,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },
    buttonSelect: {
        width: '100%',
        marginBottom: 10,
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: 'transparent',
        borderRadius: 20,
        '&:hover': {
            borderColor: theme.palette.primary.main,
            backgroundColor: 'transparent',
        }
    },
    cadPrime: {
        borderLefColor: theme.palette.primary.main,
        borderWidth: 10,
        width: '100%',
        marginTop: 20
    },
    buttonPrime: {
        width: '100%',
        marginBottom: 10,
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        borderRadius: 20,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },
    fileZone: {

    },
    buttonAction: {
        marginLeft: 10,
        height: 50
    },
    btnback:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:"#f9b233",
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#3c3c3b",
        },
        borderRadius:10,
      },
      btnnext:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:theme.palette.primary.main,
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#f9b233",
        },
        borderRadius:10,
      },
})
const useStyles = makeStyles(customTyles)

function AddProposition(props) {
    const dispatch = useDispatch();
    let containerref = useRef()
    const { handleSubmit, user, data, ui: { loading } } = props;
    //mise à jour des paramètres du compte client
    const [cpteData, setCpteData] = React.useState(false);
    let produits = useSelector(state => state.api.produits);

    const [activeStep, setActiveStep] = useState(0)
    const [dataAdherent, setDataAdherent] = useState([])
    const [parcourru, setParcourru] = useState([])
    const [svData, setSvData] = useState(null)
    const [myLoading, setMyLoading] = React.useState(loading)
    const [myErrors, setMyErrors] = useState(null)
    const [mesAssures, setMesAssures] = useState([])
    const [mesGaranties, setMesGaranties] = useState(null)
    const [primeCalculer, setPrimecalculer] = useState(false)
    const [capitaux, setCapitaux] = useState(null)
    const [primeGarantie, setPrimeGarantie] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [initAss, setInitAss] = useState(false)
    const [initResume, setInitResume] = useState(false)
    //gestion alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const handleOpenAlert = () => {
        setOpenAlert(true);
    };
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
        setMyErrors(null)
    };

    useEffect(() => {
        if (data && data.hasOwnProperty('produit')) {
            dispatch({ type: "LOADING_UI" })
            props.getGarantiesProduit(data.produit.CodeProduit).then((dataRes) => {
                if (dataRes) {
                    setMesGaranties(dataRes)
                    dispatch({ type: "STOP_LOADING_UI" })
                }
            }).catch(err => {
                console.log(err);
                dispatch({ type: "STOP_LOADING_UI" })
            })
        }

    }, []);

    //affectation des durée
    const affectationDuree = (dureeCotisationMin, dureeCotisationMax, dureeContratMin, dureeContratMax, optionnelle) => {
        const { updateField } = props

        //durée cotisation mini
        if (!data.hasOwnProperty('dureecontratmin')) {
            props.dispatch(registerField("adForm", 'dureecontratmin', 'Field'));
        } else {
            if (optionnelle && (parseInt(dureeContratMin) > parseInt(data.dureecontratmin))) { dureeContratMin = props.data.dureecontratmin }
        }

        updateField('dureecontratmin', dureeContratMin)
        //durée cotisation maximum
        if (!data.hasOwnProperty('dureecontratmax')) {
            props.dispatch(registerField("adForm", 'dureecontratmax', 'Field'));
        } else {
            if (optionnelle && (parseInt(dureeContratMax) < parseInt(props.data.dureecontratmax))) { dureeContratMax = props.data.dureecontratmax }
        }
        updateField('dureecontratmax', dureeContratMax)
        //duré cotisation mini
        if (!props.data.hasOwnProperty('dureecotisationmin')) {
            props.dispatch(registerField("adForm", 'dureecotisationmin', 'Field'));
        } else {
            if (optionnelle && (parseInt(dureeCotisationMin) > parseInt(props.data.dureecotisationmin))) { dureeCotisationMin = props.data.dureecotisationmin }
        }
        updateField('dureecotisationmin', dureeCotisationMin)
        //durée cotisation max
        if (!props.data.hasOwnProperty('dureecotisationmax')) {
            props.dispatch(registerField("adForm", 'dureecotisationmax', 'Field'));
        } else {
            if (optionnelle && (parseInt(dureeCotisationMax) < parseInt(props.data.dureecotisationmax))) { dureeCotisationMax = props.data.dureecotisationmax }
        }
        updateField('dureecotisationmax', dureeCotisationMax)

    }
    const addAssure = (defaultData, moimeme) => {
        setMyLoading(true)

        const { updateField, getGarantiesAssure } = props

        //vérification de la présence des garanties
        if (mesGaranties && mesGaranties.length === 0) {
            setMyLoading(false)
            setMyErrors({ type: 'error', message: 'Aucune garanties trouvées' })
            handleOpenAlert()
            return false
        }

        //vérification de la présence des information sur l'assurée
        if (!defaultData) {
            setMyLoading(false)
            setMyErrors({ type: 'error', message: 'Erreur sur l\'assurée' })
            handleOpenAlert()
            return false
        }


        //selection des garanties
        let dureeCotisationMin = undefined
        let dureeCotisationMax = undefined
        let dureeContratMin = undefined
        let dureeContratMax = undefined

        let garantiesObligatoires
        let garantiesComplementaires
        let codeproduit
        let dateNaissance
        codeproduit = data.produit.CodeProduit
        dateNaissance = moimeme ? data.dateNaissanceSous : defaultData.dateNaissanceAssure
        //récupération des garanties pour l'assuré
        getGarantiesAssure(codeproduit, ageAssure(dayjs(dateNaissance).format('YYYY-MM-DD'))).then((garanties) => {

            if (garanties) {
                garantiesObligatoires = garanties.filter(item => item.estobligatoire == 1)
                garantiesComplementaires = garanties.filter(item => item.estobligatoire == 0)

                let garantiesParcourru
                defaultData.garantiesFacultatives = []
                if ((garantiesObligatoires && garantiesObligatoires.length !== 0) || (garantiesComplementaires && garantiesComplementaires.length !== 0)) {
                    let result = false
                    garantiesParcourru = garantiesObligatoires.length !== 0 ? garantiesObligatoires : garantiesComplementaires
                    defaultData.garantiesSouscrits = mesGaranties.filter((item) => {
                        result = false
                        garantiesParcourru.forEach(async (element) => {
                            if (element.codeproduitgarantie === item.CodeProduitGarantie) {
                                result = true;
                                item.estunique = element.estunique
                                item.description = element.description
                                item.checked = true
                                item.agemin = element.agemin
                                item.agemax = element.agemax
                                item.dureecotisationmin = element.dureecotisationmin
                                item.dureecotisationmax = element.dureecotisationmax
                                item.dureecontratmin = element.dureecontratmin
                                item.dureecontratmax = element.dureecontratmax
                                item.primemin = element.primemin
                                item.montantgarantie = element.montantgarantie
                                item.type = element.type
                                item.estobligatoire = element.estobligatoire
                                item.estprincipal = element.estprincipal
                                item.branche = element.branche
                                item.capitaux = (element.type === 'Decces') ? (await getPrimesCapital(data.produit.CodeProduit, item.CodeProduitGarantie)) : (await getCapitauxGaranies(data.produit.CodeProduit, item.CodeProduitGarantie))

                                if (dureeCotisationMin !== undefined && element.dureecotisationmin < dureeCotisationMin) {
                                    dureeCotisationMin = element.dureecotisationmin
                                } else {
                                    dureeCotisationMin = element.dureecotisationmin
                                }
                                if (dureeCotisationMax !== undefined && element.dureecotisationmax > dureeCotisationMax) {
                                    dureeCotisationMax = element.dureecotisationmax
                                } else {
                                    dureeCotisationMax = element.dureecotisationmax
                                }
                                if (dureeContratMin !== undefined && element.dureecontratmin < dureeContratMin) {
                                    dureeContratMin = element.dureecontratmin
                                } else {
                                    dureeContratMin = element.dureecontratmin
                                }
                                if (dureeContratMax !== undefined && element.dureecontratmax > dureeContratMax) {
                                    dureeContratMax = element.dureecontratmax
                                } else {
                                    dureeContratMax = element.dureecontratmax
                                }

                                if (element.estunique == 1) {
                                    let garantiesFree = mesGaranties.filter(g => g.CodeProduitGarantie !== item.CodeProduitGarantie)
                                    setMesGaranties(garantiesFree)
                                }
                                if (garantiesObligatoires.length !== 0 && element.estobligatoire === 0) {

                                    defaultData.garantiesFacultatives.push(item)
                                }
                                if (data.produit.CodeProduit === 'YKL_2004' || data.produit.CodeProduit === 'YKV_2004' || data.produit.CodeProduit === 'YKF_2004') {
                                    const maDuree = (70 - parseInt(ageAssure(dayjs(dateNaissance).format('YYYY-MM-DD'))))
                                    if (maDuree) {
                                        dureeCotisationMin = maDuree
                                        dureeCotisationMax = maDuree
                                        dureeContratMin = maDuree
                                        dureeContratMax = maDuree
                                    }
                                }
                                affectationDuree(dureeCotisationMin, dureeCotisationMax, dureeContratMin, dureeContratMax, false)
                                return
                            }
                        });
                        return result
                    })
                    //
                    if (garantiesObligatoires.length !== 0 && garantiesComplementaires.length !== 0) {

                        defaultData.garantiesFacultatives = mesGaranties.filter((item) => {

                            result = false
                            garantiesComplementaires.forEach(async (element) => {
                                if (element.codeproduitgarantie === item.CodeProduitGarantie) {

                                    result = true;
                                    item.checked = false
                                    item.estunique = element.estunique
                                    item.description = element.description
                                    item.agemin = element.agemin
                                    item.agemax = element.agemax
                                    item.dureecotisationmin = element.dureecotisationmin
                                    item.dureecotisationmax = element.dureecotisationmax
                                    item.dureecontratmin = element.dureecontratmin
                                    item.dureecontratmax = element.dureecontratmax
                                    item.dureecontratmax = element.dureecontratmax
                                    item.primemin = element.primemin
                                    item.montantgarantie = element.montantgarantie
                                    item.type = element.type
                                    item.estobligatoire = element.estobligatoire
                                    item.estprincipal = element.estprincipal
                                    item.branche = element.branche
                                    item.capitaux = (element.type === 'Decces') ? (await getPrimesCapital(data.produit.CodeProduit, item.CodeProduitGarantie)) : (await getCapitauxGaranies(data.produit.CodeProduit, item.CodeProduitGarantie))

                                    if (element.estunique == 1) {
                                        let garantiesFree = mesGaranties.filter(g => g.CodeProduitGarantie !== item.CodeProduitGarantie)
                                        setMesGaranties(garantiesFree)

                                    }
                                    return
                                }
                            });
                            return result
                        })
                    }

                    defaultData.monIndex = (mesAssures.length + 1)
                    let newAssure = [...mesAssures, defaultData]
                    props.updateField('assures', newAssure)
                    setMesAssures(newAssure)
                    setMyLoading(false)
                    setActiveStep(activeStep + 2)
                    handleEtpapeParcourru(activeStep + 2)
                } else {
                    setMyLoading(false)
                    setMyErrors({ type: 'error', message: 'Aucune garanties trouvée' })
                    handleOpenAlert()
                    return false
                }
            } else {
                setMyLoading(false)
                setMyErrors({ type: 'error', message: 'Aucune garanties trouvée' })
                handleOpenAlert()
                return false
            }
        }).catch((err) => {
            setMyLoading(false)
            setMyErrors({ type: 'error', message: 'Erreur lors de la récupération des garanties' })
            handleOpenAlert()
            return false
        });
    }

    const handleNext = (values) => {

        if (activeStep === 1 && (['PVRBNI','PVRPRE'].includes(values.produit.CodeProduit))) {
            const currentAss = handleMoimemeAssure(data)
            if (currentAss) {
                addAssure(currentAss, true)
            } else {
                setMyErrors({ type: 'error', message: 'Erreur de récupération de l\'assuré' })
                handleOpenAlert()
            }
        } else {
            setActiveStep(activeStep + 1)
            handleEtpapeParcourru(activeStep + 1)
        }
        if ((activeStep + 1) === 2) {
            setInitAss(true)
        } else {
            setInitAss(false)
        }

        if ((activeStep + 1) === 5) {
            setInitResume(true)
        } else {
            setInitResume(false)
        }
        containerref.current.offsetParent.scrollTop=0
    };

    const handleRedirectSouscription = (data) => {
        setActiveStep(0)
        setParcourru([])
        handleEtpapeParcourru(0)
        props.history.push('/bankass/propositions', { souscription: data });
    }

    const [snOpen, setSnOpen] = React.useState(false);
    const [snMessage, setSnMessage] = React.useState("")
    const handleSnClick = () => {
        setSnOpen(true);
    };

    const handleSnClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnOpen(false);
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1)
            setActiveStep(activeStep - 1)
        } else {
            setActiveStep(0)
            handleEtpapeParcourru(0)
        }
        if ((activeStep - 1) === 2) {
            setInitAss(true)
        } else {
            setInitAss(false)
        }
        if ((activeStep - 1) === 5) {
            setInitResume(true)
        } else {
            setInitResume(false)
        }
        containerref.current.offsetParent.scrollTop=0
    };

    //recherche adherent
    const [inputField, setInputField] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            agence: user.hasOwnProperty('credentials') ? user.credentials.agence : "",
            numerocompte: '',
            rib: '',
        });
    const handleInputChange = (event) => {
        const { name, value } = event.target
        setInputField({
            [name]: value
        })
    }
    //set data for adherent
    const handleSetDataAdherent = () => {
        if (dataAdherent && dataAdherent.length !== 0) {
            const { client, compte } = dataAdherent
            if (!props.data.hasOwnProperty('civiliteSous')) {
                props.dispatch(registerField("adForm", 'civiliteSous', 'Field'));
            }
            if (client && client.civilite && client.civilite !== null) props.updateField('civiliteSous', client.civilite)

            if (!props.data.hasOwnProperty('nomSous')) {
                props.dispatch(registerField("adForm", 'nomSous', 'Field'));
            }
            if (client && client.nom && client.nom !== null) props.updateField('nomSous', client.nom)

            if (!props.data.hasOwnProperty('prenomSous')) {
                props.dispatch(registerField("adForm", 'prenomSous', 'Field'));
            }
            if (client && client.prenom && client.prenom !== null) props.updateField('prenomSous', client.prenom)

            if (!props.data.hasOwnProperty('dateNaissanceSous')) {
                props.dispatch(registerField("adForm", 'dateNaissanceSous', 'Field'));
            }
            if (client && client.datenaissance && client.datenaissance !== null) props.updateField('dateNaissanceSous', client.datenaissance.split('T')[0])

            if (!props.data.hasOwnProperty('lieuNaissanceSous')) {
                props.dispatch(registerField("adForm", 'lieuNaissanceSous', 'Field'));
            }
            if (client && client.lieunaissance && client.lieunaissance !== null) props.updateField('lieuNaissanceSous', client.lieunaissance)

            if (!props.data.hasOwnProperty('sexeSous')) {
                props.dispatch(registerField("adForm", 'sexeSous', 'Field'));
            }
            if (client && client.genre && client.genre !== null) props.updateField('sexeSous', client.genre)

            if (!props.data.hasOwnProperty('pieceIdentiteSous')) {
                props.dispatch(registerField("adForm", 'pieceIdentiteSous', 'Field'));
            }
            if (client && client.numeropiece && client.numeropiece != null) props.updateField('pieceIdentiteSous', client.numeropiece)

            if (!props.data.hasOwnProperty('naturePiece')) {
                props.dispatch(registerField("adForm", 'naturePiece', 'Field'));
            }
            if (client && client.naturePiece && client.naturePiece !== null) props.updateField('naturePiece', client.naturePiece)

            if (!props.data.hasOwnProperty('lieuResidenceSous')) {
                props.dispatch(registerField("adForm", 'lieuResidenceSous', 'Field'));
            }
            if (client && client.lieuresidence && client.lieuresidence !== null) props.updateField('lieuResidenceSous', client.lieuresidence)

            if (!props.data.hasOwnProperty('professionSous')) {
                props.dispatch(registerField("adForm", 'professionSous', 'Field'));
            }
            if (client && client.profession && client.profession !== null) props.updateField('professionSous', client.profession)

            if (!props.data.hasOwnProperty('employeurSous')) {
                props.dispatch(registerField("adForm", 'employeurSous', 'Field'));
            }
            if (client && client.secteuractivite && client.secteuractivite !== null) props.updateField('employeurSous', client.secteuractivite)

            //contact
            if (!props.data.hasOwnProperty('adressepostale')) {
                props.dispatch(registerField("adForm", 'adressepostale', 'Field'));
            }
            if (client && client.adressepostale && client.adressepostale !== null) props.updateField('adressepostale', client.adressepostale)

            if (!props.data.hasOwnProperty('telephoneSous')) {
                props.dispatch(registerField("adForm", 'telephoneSous', 'Field'));
            }
            if (client && client.telephonebureau && client.telephonebureau !== null) props.updateField('telephoneSous', client.telephonebureau)

            if (!props.data.hasOwnProperty('emailSous')) {
                props.dispatch(registerField("adForm", 'emailSous', 'Field'));
            }
            if (client && client.email && client.email !== 'null' && client.email !== null) props.updateField('emailSous', client.email)

            if (!props.data.hasOwnProperty('mobileSous')) {
                props.dispatch(registerField("adForm", 'mobileSous', 'Field'));
            }
            if (client && client.mobile1 && client.mobile1 !== null) props.updateField('mobileSous', `+225${client.mobile1}`)

            if (!props.data.hasOwnProperty('mobile2Sous')) {
                props.dispatch(registerField("adForm", 'mobile2Sous', 'Field'));
            }
            if (client && client.mobile2 && client.mobile2 !== null) props.updateField('mobile2Sous', `+225${client.mobile2}`)


            //compte
            if (!props.data.hasOwnProperty('codebanque')) {
                props.dispatch(registerField("adForm", 'codebanque', 'Field'));
            }
            if (compte && compte.codebanque && compte.codebanque !== null) props.updateField('codebanque', compte.codebanque)

            if (!props.data.hasOwnProperty('codeguichet')) {
                props.dispatch(registerField("adForm", 'codeguichet', 'Field'));
            }
            if (compte && compte.codeguichet && compte.codeguichet !== null) props.updateField('codeguichet', compte.codeguichet)

            if (!props.data.hasOwnProperty('agence')) {
                props.dispatch(registerField("adForm", 'agence', 'Field'));
            }
            if (compte && compte.codeguichet && compte.codeguichet !== null) props.updateField('agence', compte.codeguichet)

            if (!props.data.hasOwnProperty('numCompte')) {
                props.dispatch(registerField("adForm", 'numCompte', 'Field'));
            }
            if (compte && compte.numerocompte && compte.numerocompte !== null) props.updateField('numCompte', compte.numerocompte)

            if (!props.data.hasOwnProperty('rib')) {
                props.dispatch(registerField("adForm", 'rib', 'Field'));
            }
            if (compte && compte.rib && compte.rib !== null) props.updateField('rib', compte.rib)

            if (!props.data.hasOwnProperty('naturecompte')) {
                props.dispatch(registerField("adForm", 'naturecompte', 'Field'));
            }
            if (compte && compte.naturecompte && compte.naturecompte !== null) props.updateField('naturecompte', compte.naturecompte)

        }
    }

    //select client bni by accoumnt number
    const handleSelectClientBni = () => {
        if (inputField.numerocompte === "") return
        const dataSend = { numerocompte: inputField.agence + inputField.numerocompte, rib: inputField.rib }

        dispatch({ type: 'LOADING_UI' })
        setAdherentLoading(true)
        setDataAdherent([])
        getAdherentBni(dataSend).then(adData => {
            if (adData) {
                setCpteData(false)
                if (adData.hasOwnProperty("code") && adData.hasOwnProperty("description")) {
                    setSnMessage(`${adData.code} - ${adData.description}`)
                    handleSnClick()
                }
                setDataAdherent(adData)
                setAdherentLoading(false)
                dispatch({ type: 'STOP_LOADING_UI' })

            } else {
                setAdherentLoading(false)
                dispatch({ type: 'STOP_LOADING_UI' })
            }
        }).catch(err => {
            dispatch({ type: 'STOP_LOADING_UI' })
            setAdherentLoading(false)
            console.log(err);
        })
    }

    const handlegetProduit = () => {
        if (user.credentials.hasOwnProperty('branche')) {
            dispatch(getProduit(user.credentials.branche))
        }
    };

    useEffect(() => {
        handlegetProduit();
        if (!props.hasOwnProperty('codeagent')) {
            props.dispatch(registerField("adForm", 'codeagent', 'Field'));
        }
        props.updateField('codeagent', user.credentials.codeagent)

        if (!props.hasOwnProperty('nomagent')) {
            props.dispatch(registerField("adForm", 'nomagent', 'Field'));
        }
        props.updateField('nomagent', `${user.credentials.nom} ${user.credentials.prenom}`)

        if (!props.hasOwnProperty('agence')) {
            props.dispatch(registerField("adForm", 'agence', 'Field'));
        }
        props.updateField('agence', user.credentials.agence)

    }, [user]);


    const [resume, setResume] = useState(false)
    const handleResume = () => {
        setResume(!resume)
    }
    const [banqueAgences, setBanqueAgences] = useState(null)
    const [adherentLoading, setAdherentLoading] = useState(false)
    const handleAgence = async (codebanque) => {
        const mesagences = await getBanqueAgences(codebanque)
        setBanqueAgences(mesagences)
    }
    useEffect(() => {
        document.title = "YNOV - Nouvelle proposition"
    })
    useEffect(() => {
        handleAgence('092')
        if (props.location.hasOwnProperty('state')) {
            if (props.location.state && props.location.state.hasOwnProperty('step')) {
                handleEtpapeParcourru(props.location.state.step)
                setActiveStep(props.location.state.step)
            }
        }
    }, [])

    useEffect(() => {
        handleSetDataAdherent()
    }, [dataAdherent])

    const handleEtpapeParcourru = (etape) => {
        const currentIndex = parcourru.findIndex(elt => elt === etape);
        const newParcours = [...parcourru];
        if (currentIndex === -1) {
            newParcours.push(etape);
        }
        setParcourru(newParcours)
    }

    const handlePrimeGaranties = (grantiesPrimes) => {
        setPrimeGarantie(grantiesPrimes)
    }
    const handleAlerte = (val) => {
        setMesErrors(null)
        setOpenAlert(val)
    }

    //mise à jour des erreurs
    const handleErrors = (errors) => {
        setMesErrors(errors)
        setOpenAlert(true)
    }
    //modale search
    const [open, setOpen] = useState(false)
    const handleOpenCleintSeach = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    //gestion des fichiers
    const classes = useStyles();
    const [mesErrors, setMesErrors] = useState(null)
    const [souscripteurData, setSouscripteurData] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            assures: '',
            beneficiaires: '',
            produitsSous: '',
        });

    //Fitre de la table de produit
    const customFilter = (columFilter, tableFilter, valueSeach) => {
        const isproduit = (item) => {
            let result = false
            for (const key of columFilter) {
                if (item[key]) {
                    if (item[key].toUpperCase().indexOf(valueSeach.toUpperCase()) !== -1) {
                        result = true
                        break;
                    } else {
                        result = false
                    }
                } else {
                    result = false
                }
            }
            return result
        }
        return tableFilter.filter(isproduit)
    }

    const findStep = (ref) => {
        if (parcourru.findIndex(elt => elt === ref) === -1) { return false } else { return true }
    }

    const [produitData, setProduitData] = React.useState({
        columns: [
            {
                title: 'code produit',
                field: 'codeProduit',
                cellStyle: { fontSize: 30, padding: 0, color: '#000', fontWeight: 'bold' },
                render: rowData => <List className={classes.list}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar variant="square" sizes={50}>
                                <ImageIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${rowData.MonLibelle}`} secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                    display='block'
                                >
                                    {rowData.DureCotisationAns ? `Durée ${rowData.DureCotisationAns}` : ""}
                                </Typography>
                            </React.Fragment>
                        } />
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => handleCheckedProduit(rowData)} style={{ backgroundColor: '#E35F5B', height: '100%', width: '100%' }} edge="end" aria-label="suivant">
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>,
            },
            { title: 'Libelle', field: 'MonLibelle', hidden: true },
            { title: 'Durée cotisation', field: 'DureeCotisationAns', type: 'numeric', hidden: true },
            { title: 'Durée souscription', field: 'DureeSouscriptionAnnee', type: 'numeric', hidden: true },
            { title: 'Type', field: 'CodeProduitNature', hidden: true },
        ]
    });



    let mesVilles = useSelector(state => state.api.villes)
    let professions = useSelector(state => state.api.professions)

    //selection d'une linge de produit
    const [openSearchCli, setOpenSearchCli] = useState(false)
    const handleCloseSearchCli = () => {
        setOpenSearchCli(false)
    }
    const handleOpenSearchCli = () => {
        setOpenSearchCli(true)
    }
    const handleClientSelect = (event, myrow) => {
        setSelectedRow(myrow)
        if (!props.hasOwnProperty('produit')) {
            props.dispatch(registerField("adForm", 'produit', 'Field'));
        }
        props.updateField('produit', myrow)
        switch (myrow.CodeProduit) {
            case 'YKE_2008': case 'YKS_2008': case 'YKF_2008': case 'YKL_2004': case 'YKV_2004': case 'YKF_2004':
                setPrimecalculer(true)
                getPrimesCapital(myrow.CodeProduit).then((data) => {
                    setCapitaux(data)
                })
                break;
            default:
                setPrimecalculer(false)
                break;
        }
    }
    const handleCheckedProduit = (myrow) => {
        setSelectedRow(myrow)
        if (!props.hasOwnProperty('produit')) {
            props.dispatch(registerField("adForm", 'produit', 'Field'));
        }
        props.updateField('produit', myrow)
        switch (myrow.CodeProduit) {
            case 'YKE_2008': case 'YKS_2008': case 'YKF_2008': case 'YKL_2004': case 'YKV_2004': case 'YKF_2004':
                setPrimecalculer(true)
                getPrimesCapital(myrow.CodeProduit).then((data) => {
                    setCapitaux(data)
                })
                break;
            default:
                setPrimecalculer(false)
                break;
        }
        //recheche de client
        handleOpenSearchCli()
    }

    //affichier numero de compte
    useEffect(() => {
        if (props.data && props.data.hasOwnProperty('numCompte')) {
            setInputField({
                ['numerocompte']: props.data.numCompte
            })
        }
        if (props.data && props.data.hasOwnProperty('rib')) {
            setInputField({
                ['rib']: props.data.rib
            })
        }
    }, [props.data])

    //stepper
    const getSteps = () => {
        return [
            'Produit',
            'Adhérent',
            'Assuré(e)s',
            'Bénéficiaires',
            'Paiement & périodicité',
            'Resumé',
            'Documents'
        ];
    };

    //rendu des erreurs
    const renderError = () => (
        <Dialog
            open={openAlert}
            onClose={() => handleAlerte(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='sm'
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{"Erreur"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {mesErrors && <Typography styles={{ color: 'red', fontSize: 14 }}>{mesErrors.general}</Typography>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleAlerte(false)} color="secondary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )

    //affichage liste des produits
    const renderProduitTable = () => (
        <MaterialTable
            title={null}
            columns={produitData.columns}
            data={query => new Promise((resolve, reject) => {
                const columnSearch = ['CodeProduit', 'MonLibelle', 'DureeCotisationAns', 'DureeSouscriptionAnnee', 'CodeProduitNature']
                resolve({
                    data: customFilter(columnSearch, produits, query.search)
                })
            })}
            onRowClick={((evt, selectedRow) => handleClientSelect(evt, selectedRow))}
            components={{
                Toolbar: props => (
                    <div style={{ backgroundColor: '#e8eaf5' }}>
                        <MTableToolbar {...props} />
                    </div>
                ),
            }}
            localization={{
                toolbar: {
                    searchPlaceholder: 'Trouver un produit',
                    searchTooltip: 'Trouver un produit'
                },
                body: {
                    emptyDataSourceMessage: 'Aucune donnée trouvée !'
                },
                pagination: {
                    labelRowsSelect: 'Ligne(s)',
                    labelDisplayedRows: '{count} sur {from}-{to}',
                    firstTooltip: 'Prémière page',
                    previousTooltip: 'Précédent',
                    nextTooltip: 'Suivant',
                    lastTooltip: 'Dernière page'
                }
            }}
            options={{
                search: true,
                searchFieldAlignment: "left",
                searchFieldStyle: {
                    fontSize: 18,
                    width: '100%',
                    height: 50,
                },
                header: false,
                rowStyle: rowData => ({
                    backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#FFF1EB' : '#FFF'
                })
            }}
        />
    )
    const NouvelleSouscription = () => {
        dispatch(reset('adForm'))
        props.history.push('/bankass/simulateurs');
    }



    const steps = getSteps()
    const [topen, setTopen] = useState(false)
    const handleTOpen = () => {
        setTopen(true)
    }
    const handleTClose = () => {
        setTopen(false)
    }
    const ageMaxAssure = ()=>{
        switch (props.data.produit.CodeProduit) {
            case 'YKV_2004':
            case 'YKF_2004':
            case 'YKL_2004':
            case 'YKL_2018':
                if (!props.data || !props.data.hasOwnProperty('agemax')) {
                    props.dispatch(registerField("adForm", 'agemax', 'Field'));
                }
                props.updateField('agemax', 74);    
                break;
            case 'PVRBNI':
            case 'PVRPRE':
                if (!props.data || !props.data.hasOwnProperty('agemax')) {
                    props.dispatch(registerField("adForm", 'agemax', 'Field'));
                }
                props.updateField('agemax', 55);
                break;   
            default:
                if (!props.data || !props.data.hasOwnProperty('agemax')) {
                    props.dispatch(registerField("adForm", 'agemax', 'Field'));
                }
                props.updateField('agemax', 106);   
                break;
        }
    }
    useEffect(()=>{
        ageMaxAssure()
    },[props.data.produit.CodeProduit])
    
    return (
        myLoading ? (<CircularProgress color="secondary" />) : (<div ref={containerref}>
            <MiniHeader titre="Nouvelle proposition" showbtn={false} plateforme='souscription' />
            <Snackbar open={snOpen} autoHideDuration={6000} onClose={handleSnClose}>
                <CusAlert onClose={handleSnClose} severity="error">
                    {snMessage}
                </CusAlert>
            </Snackbar>
            <CheckClient handleEtpapeParcourru={handleEtpapeParcourru} setActiveStep={setActiveStep} open={openSearchCli} handleClose={handleCloseSearchCli} />
            <div style={{marginTop:30}}>
                <GridItem sm={12} xs={12} md={12} lg={12} >
                    {(activeStep > 0) ? (
                        <>
                        <div className={classes.addheader}>
                            <div className={classes.rootfab} style={{ textAlign: "right", marginBottom: 20 }} >
                                <Link classes={classes.rlink} href="/bankass/esouscription/production" >Retour à la liste</Link>
                                <Button onClick={NouvelleSouscription} aria-label="add" className={classes.rbtnadd}>
                                    <AddIcon size='large' className={classes.extendedIcon} />Nouvelle souscription
                                </Button>
                            </div>
                            <Paper className={classes.paper}>
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {steps.map(label => (
                                        <Step key={label}>
                                            <StepLabel color='primary'>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Paper>
                             
                                <p className={classes.warntext}>Tous les champs marqués d'un <span>" * "</span> sont obligatoires</p>
                           
                        </div>  

                            {renderError()}
                            {(activeStep !== 5) ? (
                                <div className={classes.addRoot}>
                                    <Accordion
                                        className={classes.rootExpan}
                                        style={{ marginTop: 20 }}
                                        disabled={!findStep(1)}
                                        expanded={activeStep === 1}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>ADHERENT</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Paper elevation={0} className={classes.paper}>
                                                <form onSubmit={handleSubmit(handleNext)}>
                                                    <GridContainer container className={classes.containerRoot} >
                                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                                            
                                                                <GridContainer>
                                                                    <GridItem xs={12} sm={2} md={2} lg={2}>
                                                                        <CustomInput
                                                                            label="Code guichet"
                                                                            id="agence"
                                                                            name="agence"
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                            value={inputField.agence}
                                                                            type='texte'
                                                                            variant="outlined"
                                                                            onChange={handleInputChange}
                                                                        />
                                                                    </GridItem>
                                                                    <GridItem xs={12} sm={4} md={4} lg={4}>
                                                                        <CustomInput
                                                                            label="N° compte"
                                                                            id="numerocompte"
                                                                            name="numerocompte"
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                            type='texte'
                                                                            variant="outlined"
                                                                            value={inputField.numerocompte}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                    </GridItem>
                                                                    <GridItem xs={12} sm={2} md={2} lg={2}>
                                                                        <CustomInput
                                                                            label="Clé rib"
                                                                            id="rib"
                                                                            name="rib"
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                            type='number'
                                                                            variant="outlined"
                                                                            value={inputField.rib}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                    </GridItem>

                                                                   
                                                                </GridContainer>
                                                            
                                                            <Divider orientation="horizontal" style={{ marginBottom: 20, marginTop: 20 }} />
                                                        </GridItem>

                                                        <GridItem xs={12} sm={12} md={12} lg={12}>

                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={12}>
                                                                    <Field
                                                                        required
                                                                        label="Civilité "
                                                                        id="civiliteSous"
                                                                        name="civiliteSous"
                                                                        options={[{ "label": "Madame", "value": "Madame" }, { "label": "Mademoiselle", "value": "Mademoiselle" }, { "label": "Monsieur", "value": "Monsieur" }]}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        row
                                                                        component={CustomRadio}
                                                                    />
                                                                </GridItem>

                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={5}>
                                                                    <Field

                                                                        required
                                                                        label="Nom"
                                                                        id="nomSous"
                                                                        name="nomSous"
                                                                        variant="outlined"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomInput}
                                                                        autoFocus
                                                                    />
                                                                </GridItem>

                                                                <GridItem xs={12} sm={12} md={7}>
                                                                    <Field

                                                                        required
                                                                        label="Prénom"
                                                                        id="prenomSous"
                                                                        name="prenomSous"
                                                                        variant="outlined"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomInput}

                                                                    />
                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={5}>
                                                                    <Field
                                                                        required
                                                                        label="Date de naissance"
                                                                        id="dateNaissanceSous"
                                                                        name="dateNaissanceSous"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}
                                                                        InputProps={{
                                                                            type: 'date',
                                                                            inputProps: { max: dayjs().add(-12, 'year').format('YYYY-MM-DD'), min: dayjs().add(-props.data?.agemax || -100, 'year').format('YYYY-MM-DD') },
                                                                        }}
                                                                        component={CustomDate}
                                                                        variant="outlined"
                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={12} md={7}>
                                                                    <Field

                                                                        label="Lieu de naissance"
                                                                        id="lieuNaissanceSous"
                                                                        name="lieuNaissanceSous"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomSelect}
                                                                        options={mesVilles}
                                                                        variant="outlined"

                                                                    />
                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={5}>
                                                                    <Field

                                                                        required
                                                                        label="N° pièce d'identité"
                                                                        id="pieceIdentiteSous"
                                                                        name="pieceIdentiteSous"
                                                                        variant="outlined"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomInput}
                                                                        autoFocus

                                                                    />

                                                                </GridItem>
                                                                <GridItem xs={12} sm={12} md={7}>
                                                                    <Field

                                                                        label="Nature de la pièce"
                                                                        id="naturePiece"
                                                                        name="naturePiece"
                                                                        options={[{ "label": "Pièce d'identité (En cours de validité)", "value": "CNI" }, { "label": "Attestation", "value": "AT" }, { "label": "Passport", "value": "Passport" }]}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomRadio}
                                                                        row
                                                                        autoFocus

                                                                    />

                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={6}>
                                                                    <Field
                                                                        label="Lieu de résidence"
                                                                        id="lieuResidenceSous"
                                                                        name="lieuResidenceSous"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomSelect}
                                                                        options={mesVilles}
                                                                        variant="outlined"
                                                                    />

                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={6}>
                                                                    <Field

                                                                        label="Profession"
                                                                        id="professionSous"
                                                                        name="professionSous"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomSelect}
                                                                        options={professions}

                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={12} md={6}>
                                                                    <Field
                                                                        label="Secteur d'activité"
                                                                        id="employeurSous"
                                                                        name="employeurSous"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}

                                                                        options={secteurActivites}
                                                                        component={CustomSelect}

                                                                    />
                                                                </GridItem>
                                                            </GridContainer>

                                                        </GridItem>

                                                    </GridContainer>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ borderWidthLeft: 1, borderLefColor: 'secondary', }}>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={6}>
                                                                    <Field

                                                                        label="email"
                                                                        id="emailSous"
                                                                        name="emailSous"
                                                                        variant="outlined"
                                                                        component={CustomInput}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        type='email'
                                                                    />

                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={3} md={3} lg={3}>
                                                                    <Field
                                                                        required
                                                                        label="Mobile 1"
                                                                        id="mobileSous"
                                                                        name="mobileSous"
                                                                        variant="outlined"
                                                                        component={CustomPhoneInput}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        InputProps={{
                                                                            inputProps: { max:10, min:10 },
                                                                        }}

                                                                    />

                                                                </GridItem>
                                                                <GridItem xs={12} sm={3} md={3} lg={3}>
                                                                    <Field
                                                                        label="Mobile 2"
                                                                        id="mobile2Sous"
                                                                        name="mobile2Sous"
                                                                        variant="outlined"
                                                                        component={CustomPhoneInput}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        InputProps={{
                                                                            inputProps: { max:10, min:10 },
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={3} md={3} lg={3}>
                                                                    <Field
                                                                        label="Téléphone"
                                                                        id="telephoneSous"
                                                                        name="telephoneSous"
                                                                        variant="outlined"
                                                                        component={CustomPhoneInput}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        InputProps={{
                                                                            inputProps: { max:10, min:10 },
                                                                        }}
                                                                    />

                                                                </GridItem>


                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={12}>
                                                                    <LabelledOutline label="Personnes à contacter en cas de besoins">
                                                                        <GridContainer>
                                                                            <GridItem xs={12} sm={12} md={6}>
                                                                                <Field
                                                                                    required
                                                                                    label="Nom & prénom"
                                                                                    id="personneressource"
                                                                                    name="personneressource"
                                                                                    variant="outlined"
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    component={CustomInput}

                                                                                />
                                                                            </GridItem>
                                                                            <GridItem xs={12} sm={12} md={3} lg={3}>
                                                                                <Field
                                                                                    required
                                                                                    label="Contact"
                                                                                    id="contactpersonneressource"
                                                                                    name="contactpersonneressource"
                                                                                    variant="outlined"
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    component={CustomPhoneInput}
                                                                                    InputProps={{
                                                                                        inputProps: { max:10, min:10 },
                                                                                    }}
                                                                                />
                                                                            </GridItem>
                                                                        </GridContainer>
                                                                        <GridContainer>
                                                                            <GridItem xs={12} sm={12} md={6}>
                                                                                <Field
                                                                                    label="Nom & prénom"
                                                                                    id="personneressource2"
                                                                                    name="personneressource2"
                                                                                    variant="outlined"
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    component={CustomInput}

                                                                                />
                                                                            </GridItem>
                                                                            <GridItem xs={12} sm={12} md={3} lg={3}>
                                                                                <Field
                                                                                    label="Contact"
                                                                                    id="contactpersonneressource2"
                                                                                    name="contactpersonneressource2"
                                                                                    variant="outlined"
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    component={CustomPhoneInput}
                                                                                    InputProps={{
                                                                                        inputProps: { max:10, min:10 },
                                                                                    }}
                                                                                />
                                                                            </GridItem>
                                                                        </GridContainer>
                                                                    </LabelledOutline>
                                                                </GridItem>


                                                            </GridContainer>
                                                        </GridItem>
                                                    </GridContainer>
                                                    <Divider component="hr" className={classes.hTrait} />


                                                    <Grid container direction="row" justify="space-between" alignItems="center">

                                                        <Button type="button" className={classes.btnback} onClick={handleBack}>
                                                            Retour
                                                        </Button>
                                                        <Button type='submit' className={classes.btnnext} >
                                                            Continuer
                                                            <NavigateNextOutlinedIcon />
                                                        </Button>

                                                    </Grid>
                                                </form>
                                            </Paper>

                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion disabled={!findStep(2)} expanded={activeStep === 2}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"

                                        >
                                            <Typography className={classes.heading}>ASSURE(E)S</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Paper elevation={0} className={classes.paper}>
                                                {findStep(2) && <ProfilAssure handleBack={handleBack} step={activeStep} hBack={handleBack} initAss={initAss} onSubmit={handleNext} btnshow={true} prodGaranties={[...mesGaranties]} />}

                                            </Paper>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion disabled={!findStep(3)} expanded={activeStep === 3}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3a-content"
                                            id="panel3a-header"

                                        >
                                            <Typography className={classes.heading}>BENEFICIAIRES</Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Paper elevation={0} className={classes.paper}>
                                                {findStep(3) && <ProfilBeneficiaire handleBack={handleBack} step={activeStep} hBack={handleBack} onSubmit={handleNext} btnshow={true} />}
                                            </Paper>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion disabled={!findStep(4)} expanded={activeStep === 4}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3a-content"
                                            id="panel3a-header"
                                        >
                                            <Typography className={classes.heading}>CONDITIONS DE PAIEMENT DE LA PRIME & PERIODICITE</Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <Paper elevation={0} className={classes.paper}>
                                                <GridContainer>
                                                    {findStep(4) && <ProfilSouscipteurInfo handleResume={handleResume} handleBack={handleBack} step={activeStep} hBack={handleBack} onSubmit={handleNext} btnshow={true} />}
                                                </GridContainer>

                                            </Paper>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion disabled={!findStep(6)} expanded={activeStep === 6}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3a-content"
                                            id="panel3a-header"

                                        >
                                            <Typography className={classes.heading}>DOCUMENTS</Typography>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <DocumentSouscription step={activeStep} svdata={svData} handleTOpen={handleTOpen} handleRedirectSouscription={handleRedirectSouscription} handleBack={handleBack} onSubmit={handleNext} btnshow={true} />
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ) : (
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <SouscriptionFicheresume step={activeStep} initResume={initResume} setSvData={setSvData} handleResume={handleResume} handleBack={handleBack} handleNext={handleNext} onSubmit={handleSubmit} btnshow={true} handleRedirectSouscription={handleRedirectSouscription} />
                                    </GridItem>
                                </GridContainer>

                            )}

                        </>

                    ) :
                        (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <div style={{ borderWidth: 2, borderStyle: 'dashed', marginBottom: 20 }}>
                                        <Typography style={{ textAlign: 'center' }} variant='h5' component='h4' color='primary'>Veuillez selectionner un produit pour demarrer la souscription</Typography>
                                    </div>

                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    {renderProduitTable()}
                                </GridItem>
                            </GridContainer>
                        )
                    }
                </GridItem>
                {(setSvData && topen) && <PropoTransmission step={activeStep} open={topen} handleClose={handleTClose} data={svData} handleRedirectSouscription={handleRedirectSouscription} handleBack={handleBack} btnshow={true} />}
            </div>

        </div>)

    )
}

const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {
    addSouscription,
    updateField,
    getUserData,
    getGarantiesAssure,
    getGarantiesProduit
}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    data: getFormValues('adForm')(state),
    ui: state.UI
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({
    form: 'adForm', // a unique identifier for this form
    destroyOnUnmount: false,
})(AddProposition))

const secteurActivites = [
    { "MonLibelle": 'Agroalimentaire' },
    { "MonLibelle": 'Banque / Assurance' },
    { "MonLibelle": 'Bois / Papier / Carton / Imprimerie' },
    { "MonLibelle": 'BTP / Matériaux de construction' },
    { "MonLibelle": 'Édition / Communication / Multimédia' },
    { "MonLibelle": 'Électronique / Électricité' },
    { "MonLibelle": 'Études et conseils' },
    { "MonLibelle": 'Industrie pharmaceutique' },
    { "MonLibelle": 'Informatique / Télécoms' },
    { "MonLibelle": 'Machines et équipements / Automobile' },
    { "MonLibelle": 'Métallurgie / Travail du métal' },
    { "MonLibelle": 'Plastique / Caoutchouc' },
    { "MonLibelle": 'Santé/Medecine' },
    { "MonLibelle": 'Service public' },
    { "MonLibelle": 'Services aux entreprises' },
    { "MonLibelle": 'Textile / Habillement / Chaussure' },
    { "MonLibelle": 'Transports / Logistique' },
    { "MonLibelle": 'Autre' }
]
