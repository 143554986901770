import { handleGetLogo } from 'application'
import React from 'react'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { 
 
  Typography,
  IconButton,
  Button,
  Tooltip,
  Divider
 } from '@material-ui/core'
 import DescriptionIcon from '@material-ui/icons/Description';
 import ListAltIcon from '@material-ui/icons/ListAlt';
const ProduitCard = ({produit}) => {
  return (
    <Card className="w-[350px] cursor-pointer mb-3" elevation={3} key={produit.CodeProduitFormule}>
    <CardActionArea className='hover:bg-secondary-main pointer'>
      <CardMedia
        component="img"
        alt="Logo produit"
        height="80"
        image={handleGetLogo(produit.CodeProduit,'vert')}
        title={`${produit.MonLibelle} - ${produit.Formule}`}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
            {produit.MonLibelle}
        </Typography>
        <span >
            {produit.Formule}
        </span>
        <Typography variant="body2" color="textSecondary" component="p">
            {produit.DureCotisationAns ? `Durée ${produit.DureCotisationAns}` : ""}
        </Typography>
      
      </CardContent>
    </CardActionArea>
    <CardActions className='py-2 flex flex-col w-full'>
    <div className='flex flex-col w-full'>
      <Divider className='my-1' />
      <div className='flex flex-row w-full items-center'>
      <Tooltip title="Details">
        <IconButton className='flex-1 rounded-none'><DescriptionIcon /></IconButton>
        </Tooltip>
        <Tooltip title="Garanties">
        <IconButton className='flex-1 rounded-none border-l-[1px] border-l-solid border-l-gray-300'>
              <ListAltIcon />
        </IconButton>
        </Tooltip>
     </div>
    </div>
     
    </CardActions>
  </Card>
  )
}

export default ProduitCard