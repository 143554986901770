
import React, { useState, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import MaterialTable, { MTableToolbar } from 'material-table';
import withWidth from '@material-ui/core/withWidth';
import { connect } from 'react-redux'
import { searchPersonne } from 'redux/actions/apiActions'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import dayjs from 'dayjs';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        padding: '15px 15px'
    },

    buttonAdd: {
        width: '100%',
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 30,
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },
    textField: {
        marginRight: 10
    },
    buttonSearch: {
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        marginTop: 10,
        marginBottom: 10,
        color: '#FFF',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
        }
    }
}));

function SearchClient(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { open, handleClose, addClientSelect, mode, user: { details } } = props
    const [selectedRow, setSelectedRow] = useState(null)
    const handleClientSelect = (event, myrow) => {
        setSelectedRow(myrow)
    }

    const [adherentLoading, setAdherentLoading] = useState(false)
    const [dataSearch, setDataSearch] = React.useState(null)
    const [filterField, setFilterField] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            nom: "",
            prenom: "",
            dateNaissance: "",
            numerocompte: "",
            prospect: false
        });
    const handleFilterChange = (event) => {
        const { name, value } = event.target
        setFilterField({
            [name]: value
        })
    }
    const customFilter = (columFilter, tableFilter, valueSeach) => {
        const isproduit = (item) => {
            let result = false
            for (const key of columFilter) {
                if (item[key]) {
                    if (item[key].toUpperCase().indexOf(valueSeach.toUpperCase()) !== -1) {
                        result = true
                        break;
                    } else {
                        result = false
                    }
                } else {
                    result = false
                }
            }
            return result
        }
        return tableFilter.filter(isproduit)
    }

    const handleFilterData = () => {
        if (filterField.prospect == false && (filterField.nom.trim() !== "" || filterField.prenom.trim() !== "" || filterField.numerocompte.trim() !== "")) {
            searchPersonne(filterField).then(data => {
                if (data) {
                    setDataSearch(data)
                }
            })
        } else {
            return
        }

    }

    return (
        <Dialog fullWidth maxWidth={props.width} open={open} onClose={addClientSelect} aria-labelledby="form-dialog-title">
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
                <DialogTitle id="form-dialog-title">Trouver un client / propect</DialogTitle>
            </div>

            <Divider />
            <DialogContent>
                <div className={classes.root}>
                    <AppBar position="static">
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="Client" {...a11yProps(0)} />
                            <Tab label="Prospect" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>

                        <Paper elevation={3}>
                            <form className={classes.container} noValidate>
                                <TextField
                                    id="nom"
                                    name="nom"
                                    label="Nom"
                                    type="text"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleFilterChange}
                                    variant='outlined'
                                />
                                <TextField
                                    id="prenom"
                                    name="prenom"
                                    label="Prenom"
                                    type="text"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleFilterChange}
                                    variant='outlined'
                                />


                                <TextField
                                    id="dateNaissance"
                                    name="datenaissance"
                                    label="Date de naissance"
                                    type="date"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleFilterChange}
                                    variant='outlined'
                                />


                                <TextField
                                    id="numerocompte"
                                    name="numerocompte"
                                    label="numerocompte"
                                    type="text"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleFilterChange}
                                    variant='outlined'
                                />

                                <Button variant='outlined' color='primary' className={classes.buttonSearch} onClick={handleFilterData} >
                                    <SearchIcon />
                                </Button>
                            </form>
                        </Paper>

                        <MaterialTable
                            title={null}
                            columns={[
                                { title: 'Code', field: 'CodePersonne' },
                                { title: 'Nom', field: 'Nom' },
                                { title: 'Prenom', filed: 'Prenom' },
                                { title: 'Date de naissance', field: 'DateNaissance', render: rowData => dayjs(rowData.DateNaissance).format('DD/MM/YYYY') },
                                { title: 'Lieu de naissance', field: 'LieuNaissance' },
                                { title: 'Lieu de résidence', field: 'LieuResidence' },
                                { title: 'Profession', field: 'Profession' },
                                { title: 'N° Compte', field: 'NumCompte' },
                                { title: 'Code guichet', field: 'CodeGuichet' },
                                { title: 'Code banque', field: 'CodeSociete' },
                            ]}

                            data={(dataSearch && dataSearch.length !== 0) ? dataSearch : []}
                            onRowClick={((evt, selectedRow) => handleClientSelect(evt, selectedRow))}
                            components={{
                                Toolbar: props => (
                                    <div style={{ backgroundColor: '#e8eaf5' }}>
                                        <MTableToolbar {...props} />
                                    </div>
                                ),
                            }}
                            localization={{
                                toolbar: {
                                    searchPlaceholder: 'Trouver un produit',
                                    searchTooltip: 'Trouver un produit'
                                }
                            }}
                            options={{
                                searchFieldAlignment: "right",
                                searchFieldStyle: {
                                    fontSize: 18,
                                    width: '100%',
                                    minWidth: 500,
                                    height: 50,
                                },
                                pageSize: 5,
                                pageSizeOptions: [5, 10, 20, 40, 80, 100],
                                rowStyle: rowData => ({
                                    backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#ff6333' : '#FFF'
                                })

                            }}
                        />

                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        Item Two
                    </TabPanel>
                </div>

            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={() => addClientSelect(selectedRow)} color="primary">
                    Ajouter
                </Button>
            </DialogActions>
        </Dialog>


    )
}

const mapActionsToProps = {

};

const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user
});

export default connect(mapStateToProps, mapActionsToProps)(withWidth()(SearchClient))