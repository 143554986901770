import React,{useState,useEffect, useMemo} from 'react';
import { makeStyles } from '@material-ui/styles';
import {Paper,Tooltip,IconButton,Typography, Divider, Select, OutlinedInput, InputLabel, ListItemText,Checkbox, FormGroup,Chip,Avatar,TextField} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import SendIcon from '@material-ui/icons/Send';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {transformePeriode,exportCSVFile,exportToExcel,rapportPdf} from 'application';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { getTypeOperationList,getOperationByUser,operationStatByUser} from 'redux/actions/apiActions';
import { getBranche,getPartenaire,getReseauxPartenaires,getZonesReseau,getEquipesZones} from 'redux/actions/settingsAction'
import swal from 'sweetalert';
import { rapportAdd } from 'redux/actions/backendActions'
import { reduxForm, change, getFormValues, reset } from 'redux-form';
import { connect } from 'react-redux'
const useStyle = makeStyles(theme=>({
    root:{
        width:'75%',
        marginLeft:'auto',
        marginRight:'auto',
    },
    rheader:{
        padding:10,
        marginBottom:20,
        borderLeftColor: theme.palette.primary.main,
        borderLeftWidth:8,
        display:'flex',
        justifyContent:'space-between',
        
    },
    btnNew:{
        paddingTop:10,
        paddingBottom:10,
        paddingRight:20,
        marginRight:20,
        backgroundColor:theme.palette.primary.main,
        color:'white',
        "&:hover":{
          backgroundColor:theme.palette.secondary.main,
        }
    },
    rmain:{
      display:'flex',
      flexDirection:'column',
      marginBottom:75,
      padding:20
    },
    rtabs:{
      display:'flex',
      justifyContent:'space-between'
    },
    rtabsright:{
      display:'flex'
    },
    btntabs:{
      backgroundColor:'transparent !important',
      borderRadius:0,
      marginRight:20,
      paddingRight:20,
      paddingLeft:20,
      "&:hover":{
        backgroundColor:'transparent !important',
        color:theme.palette.primary.main,
      }
    },
    btntabsSelect:{
      backgroundColor:'transparent !important',
      borderColor:theme.palette.primary.main,
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      borderRadius:0,
      marginRight:20,
      paddingRight:20,
      paddingLeft:20,
      "&:hover":{
        backgroundColor:'transparent !important',
        color:theme.palette.primary.main,
      }
    },
    rempty:{
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      paddingTop:75,
  
    },
    tt:{
      margin:20
    },
    ritem:{
        display:'flex',
        flexDirection:'column',
        marginBottom:20,
        marginTop:20
    },
    cardGr:{
        display:'flex'
    },
    typeItem:{
        disolay:'flex',
        flex:1,
        flexDirection:'column',
        margin:10,
        cursor:'pointer',
        "&:hover":{
            boxShadow:"2px 2px 2px 2px #e6e6e6"
        }
    },
    typeItemChecked:{
        disolay:'flex',
        flex:1,
        flexDirection:'column',
        margin:10,
        cursor:'pointer',
        "&:hover":{
            boxShadow:"2px 2px 2px 2px #e6e6e6"
        },
        borderColor:theme.palette.primary.main,
        borderStyle: 'solid',
        borderWidth: 2,
        
    },
    title: {
    fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },

    rfooter:{
        position:'fixed',
        bottom:0,
        padding:20,
        width:'100%',
        zIndex:10
    },
    
    rfootcontent:{
        display:'flex',
        flex:1,
        width:'60%',
        justifyContent:'space-between',
        alignItems:'center',
        marginRight:'auto',
        marginLeft:'auto'
        
    },
    
    formControl: {
        margin: theme.spacing(1),
        display:'flex',
        flex:1
    },

    btnmore:{
        transform:"none",
        backgroundColor:'transparent !important',
        borderColor:theme.palette.primary.main,
        borderStyle: 'dashed',
        borderWidth: 1,
        color:theme.palette.primary.main,
        "&:hover":{
            backgroundColor:theme.palette.primary.main,
            color:'white',
        }
      },
      btnless:{
        transform:"none",
        backgroundColor:'transparent !important',
        borderColor:theme.palette.secondary.main,
        borderStyle: 'dashed',
        borderWidth: 1,
        color:theme.palette.secondary.main,
        "&:hover":{
            backgroundColor:theme.palette.secondary.main,
            color:'white',
        }
      }
}))
  
const initalValues ={
    type:null,
    titre:null,
    grOperations:[],
    grStatut:[],
    grEtat:[],
    dateoperation:"",
    operationdu:"",
    operationau:"",
    dateaceptation:"",
    accepterdu:"",
    accepterau:"",
    datesaisie:"",
    saisiedu:"",
    saisieau:"",
    grPeriode:[],
    format:"pdf"
  }

function OperationRapportAdd(props) {
    const classes = useStyle()
    const [rapportField,setRapportField] = useState(initalValues)
    const handlecheckType =(tp)=>{
        let titre
        switch (tp) {
            case "all":
                titre = "Rapport des opérations"
                break;
            case "ot":
                titre = "Rapport des transmissions d'opérations"
                break;
            case "oc":
                titre = "Rapport des opérations acceptées"
                break;
            case "or":
                titre = "Rapport des opérations rejétées"
                break;
            default:
                titre = "Etat des opérations"
                break;
        }
        setRapportField({...rapportField,"type":tp,'titre':titre})
    }

    const [operationList,setOperationList] = useState([])
    const handleTypeOperationList = () => {
        getTypeOperationList().then(res => {
            if (res) setOperationList(res)
        })
    }

    const handleChange = (event) => {
        const {
          target: { value,name },
        } = event;
        setRapportField({...rapportField,[name]:(typeof value === 'string' ? value.split(',') : value)});
    };
    const [moreFilter,setMoreFilter] = useState(false)
    const handleMoreFilter = ()=>{
        setMoreFilter(!moreFilter)
    }
    const handlechangePeriode = (e)=>{
        const {name,value} = e.target
        const {debut,fin} = transformePeriode(value)
        switch (name) {
          case 'dateperiode':
            setRapportField({...rapportField,[name]:value,["periodedu"]:debut,['periodeau']:fin})
            break;
          default:
            setRapportField({...rapportField,[name]:value})
            break;
        }
    }
    
    const handleChecked =(e,field)=>{
        const currentChecke = rapportField[field] || []
        if(currentChecke && currentChecke.indexOf(e.target.name) > -1){
            setRapportField({...rapportField,[field]:currentChecke.filter(item=>item!==e.target.name)})
        }else{
            setRapportField({...rapportField,[field]:[...currentChecke,e.target.name]})
        }
    }

    //filtres supplementaire
    const [branches,setBranches] = React.useState(null)
    const [partenaires,setPartenaires] = React.useState(null)
    const [reseau,setReseaux] =useState([])
    const [zoneList,setZoneList] = useState([])
    const [agenceList,setAgenceList] =useState([])

    const handlebranche = ()=>{
        getBranche().then(data=>{
            if(data){
                setBranches(data);
            }
        })
    }
    const handlePartenaire =()=>{
        getPartenaire().then(data=>{
            if(data){
                setPartenaires(data);
            }
        })
    }
    
    const handleReseau =()=>{
        if(rapportField.partenaire && rapportField.partenaire!==''){
            getReseauxPartenaires(rapportField.partenaire).then(res=>{
                if(res) setReseaux(res)
            })
        }
    }
    
    const handleZone = () =>{
        if(rapportField.reseau && rapportField.reseau!==''){
            getZonesReseau(rapportField.reseau).then(res=>{
                if(res) setZoneList(res)
            })
        }
    }

    const handleAgenceList =()=>{
        if(rapportField.zone && rapportField.zone!==''){
            getEquipesZones(rapportField.zone).then(res=>{
                if(res){
                setAgenceList(res)
                }
            })
        }
    }

      //cycle de vie
    useEffect(()=>{
        handleTypeOperationList()
        handlebranche()
        handlePartenaire()
    },[])

    //liste des reseaux par partenaires
    useEffect(()=>{
        handleReseau()
    },[rapportField.zone])
    //liste des zones par reseau
    useEffect(()=>{
        handleZone()
    },[rapportField.reseau])

    //liste des agence par zone
    useEffect(()=>{
        handleAgenceList()
    },[rapportField.zone])
    
    const [loading,setLoading] = useState(false)
    const handleGenerate = ()=>{
        setLoading(true)
        //selection des détails
        let dataSend ={...rapportField}
        if(rapportField['grOperations'] && rapportField.grOperations.length!==0){
            dataSend ={...dataSend,"grOperations":rapportField['grOperations']}
        }
        if(rapportField['grStatut'] && rapportField.grStatut.length!==0){
            dataSend ={...dataSend,"grStatut":rapportField['grStatut']}
        }
        if(rapportField['grEtat'] && rapportField.grEtat.length!==0){
            dataSend ={...dataSend,"grEtat":rapportField['grEtat']}
        }
        if((rapportField['grPeriode'] && rapportField.grPeriode.length!==0) && 
            (
                (rapportField['periodedu'] && rapportField.periodedu!=="") && 
                (rapportField['periodeau'] && rapportField.periodeau!=="")
            )){
            if(rapportField.grPeriode.indexOf('dateoperation') > -1){
                dataSend={...dataSend,'operationdu':rapportField['periodedu'],'operationau':rapportField['periodeau']}  
            }
            if(rapportField.grPeriode.indexOf('datetransmission') > -1){
                dataSend={...dataSend,'transmisdu':rapportField['periodedu'],'transmisau':rapportField['periodeau']}  
            }
            if(rapportField.grPeriode.indexOf('dateacceptation') > -1){
                dataSend={...dataSend,'accepterdu':rapportField['periodedu'],'accepterdu':rapportField['periodeau']}  
            }
            if(rapportField.grPeriode.indexOf('daterejet') > -1){
                dataSend={...dataSend,'rejetdu':rapportField['periodedu'],'rejetdu':rapportField['periodeau']}  
            }
            if(rapportField.grPeriode.indexOf('datesaisie') > -1){
                dataSend={...dataSend,'saisiedu':rapportField['periodedu'],'saisieau':rapportField['periodeau']}  
            }
        }
        if(moreFilter){
            if(rapportField.hasOwnProperty('codebranche')){
                dataSend={...dataSend,'branche':rapportField['codebranche']} 
            }
            if(rapportField.hasOwnProperty('codepartenaire')){
                dataSend={...dataSend,'partenaire':rapportField['codepartenaire']} 
            }
            if(rapportField.hasOwnProperty('codereseau')){
                dataSend={...dataSend,'reseau':rapportField['codereseau']} 
            }
            if(rapportField.hasOwnProperty('codezone')){
                dataSend={...dataSend,'zone':rapportField['codezone']} 
            }
            if(rapportField.hasOwnProperty('codeequipe')){
                dataSend={...dataSend,'equipe':rapportField['codeequipe']} 
            }
        }

        if(Object.keys(dataSend).length!==0){
           
            getOperationByUser(dataSend).then(res=>{
                if(res){
                   const listeOperation = res
                    rapportAdd(dataSend).then(reponse=>{
                        if(reponse){
                            switch (rapportField['format']) {
                                case 'excel':
                                    setLoading(false)
                                    break;
                                case 'csv':
                                    setLoading(false)
                                    break;
                                default:
                                    //pdf par defaut
                                    rapportPdf(reponse,listeOperation)
                                    setLoading(false)
                                    break;
                            }
                        }else{
                            setLoading(false)
                            swal("Warning!", "Nous n'avons pas pu créer le rapport", "warning");
                        }
                    }).catch(err=>{
                        setLoading(false)
                        swal("Erreur!", "Une erreur s'est produit lors de la création du rapport", "error");
                    })
                }else{
                    setLoading(false)
                    swal("Warning!", "Aucune opération associé aux paramètres fourni", "warning");
                }
            }).catch(err=>{
                setLoading(false)
                swal("Erreur!", "Une erreur s'est produit lors de la selection", "error");
            })
        }else{
            setLoading(false)
            swal("Attention!", "Veuillez fournir des paramètres de sélection", "warning");
        }    
    }
    return (
        <div>
            <div className={classes.root}>
                <Paper className={classes.rheader} variant="outlined">
                    <Tooltip title="Retour" placement="bottom-start">
                        <IconButton onClick={()=>props.handleCurrentView('RAPPORT')} color="primary" aria-label="Retour">
                            <ArrowBackIcon fontSize='large' />
                        </IconButton>
                    </Tooltip>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <Typography variant='h4'>Nouveau rapport</Typography>
                    </div>
                    <div></div>
                </Paper>

                <Paper className={classes.rmain} variant="outlined">
                    <div className={classes.ritem}>
                        <h3>Type de rapport</h3>
                            <div className={classes.cardGr}>
                            <Card className={!(rapportField['type']==='all')?classes.typeItem:classes.typeItemChecked} variant="outlined" onClick={()=>handlecheckType('all')}>
                                <CardContent>
                                    <AllInboxIcon style={{ color:'#f9b233', fontSize:40 }} />
                                    <Typography variant="h5" component="h3">
                                    Bordereau des opérations
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                    Toutes les opérations
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                    par période sélectionnée
                                    
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card className={!(rapportField['type']==='ot')?classes.typeItem:classes.typeItemChecked} onClick={()=>handlecheckType('ot')} variant="outlined">
                                <CardContent>
                                    <SendIcon style={{ color:'#f9b233', fontSize:40 }} />
                                    <Typography variant="h5" component="h3">
                                    Bordereau de transmission
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                    Opération tranmis uniquement
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                    par période sélectionnée
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card className={!(rapportField['type']==='oc')?classes.typeItem:classes.typeItemChecked} onClick={()=>handlecheckType('oc')} variant="outlined">
                                <CardContent>
                                    <PlaylistAddCheckIcon style={{ color:'#f9b233', fontSize:40 }} />
                                    <Typography variant="h5" component="h3">
                                    Bordereau d'acceptation
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                    Opérations acceptée
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                    par période sélectionnée
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card className={!(rapportField['type']==='or')?classes.typeItem:classes.typeItemChecked} onClick={()=>handlecheckType('or')} variant="outlined">
                                <CardContent>
                                    <CancelScheduleSendIcon style={{ color:'#f9b233', fontSize:40 }} />
                                    <Typography variant="h5" component="h3">
                                        Bordereau de rejets
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                        Opérations rejétées
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Par période sélectionnée
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Card className={!(rapportField['type']==='oe')?classes.typeItem:classes.typeItemChecked} onClick={()=>handlecheckType('oe')} variant="outlined">
                                <CardContent>
                                    <DonutLargeIcon  style={{ color:'#f9b233', fontSize:40 }} />
                                    <Typography variant="h5" component="h3">
                                    Etat par type d'opération
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                        Nombre d'opération part type
                                    </Typography>
                                
                                </CardContent>
                            </Card>
                        </div>
                        
                    </div>
                    <Divider />
                    <div className={classes.ritem}>
                        <h3>Récurrence et plage de dates</h3>
                        <div style={{display:'flex',justifyContent:'space-around'}}>
                            <FormControl component="fieldset">
                                <RadioGroup row aria-label="propriete" name="propriete" value={rapportField['propriete']} onChange={handleChange}>
                                    <FormControlLabel value="pontuel" control={<Radio />} label="Rapport ponctuel" />
                                    <FormControlLabel value="recurrent" control={<Radio />} label="Rapport récurrent" />
                                </RadioGroup>
                            </FormControl>
                            <FormControl>
                                <InputLabel id="operations"> Type d'opération</InputLabel>
                                <Select
                                    label="Type d'opération"
                                    labelId="grOperations"
                                    id="grOperations"
                                    name="grOperations"
                                    variant="outlined"
                                    select
                                    fullWidth
                                    style={{marginRight:20,marginBottom:20}}
                                    multiple
                                    value={rapportField['grOperations']||[]}
                                    onChange={handleChange}
                                    input={<OutlinedInput label="Type d'opération" />}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    <MenuItem key={0} value='Toute'>
                                        <Checkbox checked={rapportField['grOperations'].indexOf('Toute') > -1} />
                                        <ListItemText primary='Toute' />
                                    </MenuItem>
                                    {(operationList && operationList.length!==0)?(operationList.map(ope=>(
                                        <MenuItem key={ope['CodeAvenantType']} value={ope['MonLibelle']}>
                                        <Checkbox checked={rapportField['grOperations'].indexOf(ope['MonLibelle']) > -1} />
                                        <ListItemText primary={ope['MonLibelle']} />
                                    </MenuItem>
                                    ))): <MenuItem key={1} value=''>
                                        <ListItemText primary='Aucune donnée fournie' />
                                    </MenuItem>}
                                </Select>
                            </FormControl>
                        </div>
                        <Divider />
                        <div style={{display:'flex',justifyContent:'space-between',marginTop:20}}>
                            <div style={{display:'flex',flex:1,flexDirection:'column'}}>
                                <TextField
                                    label="Date "
                                    id="dateperiode"
                                    name="dateperiode"
                                    variant="outlined"
                                    select
                                    fullWidth
                                    value ={rapportField['dateperiode']||""}
                                    style={{marginRight:20,marginBottom:20}}
                                    onChange={handlechangePeriode}
                                    >
                                    <MenuItem key={0} value='day'>Aujourd'hui</MenuItem>
                                    <MenuItem key={1} value='yesterday'>Hier </MenuItem>
                                    <MenuItem key={2} value='last7week'>Les 7 derniers jours </MenuItem>
                                    <MenuItem key={3} value='currentweek'>Cette semaine </MenuItem>
                                    <MenuItem key={4} value='lastweek'>La semaine dernière </MenuItem>
                                    <MenuItem key={5} value='last30days'>Les 30 derniers jours </MenuItem>
                                    <MenuItem key={6} value='currentmonth'>Le mois en cours </MenuItem>
                                    <MenuItem key={7} value='lastmonth'>Le mois derniers </MenuItem>
                                    <MenuItem key={8} value='currentyear'>Cette année </MenuItem>
                                    <MenuItem key={9} value="lastyear">L'année dernière </MenuItem>
                                    <MenuItem key={10} value="personnalise">Personnalisé </MenuItem>
                                </TextField>

                                <div style={{display:'flex'}}>
                                    <TextField
                                        id="pediodedu"
                                        name="pediodedu"
                                        label="Période du"
                                        type="date"
                                        disabled={rapportField['dateperiode']!=='personnalise'}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value ={rapportField['periodedu']||""}
                                        onChange={handleChange}
                                        variant='outlined'
                                        style={{width:"50%",marginRight:20,marginBottom:20}}
                                    />
                                    <TextField
                                        id="periodeau"
                                        name="periodeau"
                                        disabled={rapportField['dateperiode']!=='personnalise'}
                                        label="Au"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value ={rapportField['periodeau']||""}
                                        onChange={handleChange}
                                        variant='outlined'
                                        style={{width:"50%",marginRight:20,marginBottom:20}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className={classes.ritem}>
                        <h3>Filtres</h3>
                        <div>
                            <FormGroup row style={{marginLeft:20,marginBottom:20}}>
                                <FormControlLabel
                                    control={<Checkbox checked={rapportField['grPeriode'].indexOf('dateoperation') > -1} onChange={(event)=>handleChecked(event,'grPeriode')} name="dateoperation" />}
                                    label="Date d'opération"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={rapportField['grPeriode'].indexOf('datetransmission') > -1} onChange={(event)=>handleChecked(event,'grPeriode')} name="datetransmission" />}
                                    label="Date de transmission"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={rapportField['grPeriode'].indexOf('dateacceptation') > -1} onChange={(event)=>handleChecked(event,'grPeriode')} name="dateacceptation" />}
                                    label="Date d'acceptation"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={rapportField['grPeriode'].indexOf('daterejet') > -1} onChange={(event)=>handleChecked(event,'grPeriode')} name="daterejet" />}
                                    label="Date de rejet"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={rapportField['grPeriode'].indexOf('datesaisie') > -1} onChange={(event)=>handleChecked(event,'grPeriode')} name="datesaisie" />}
                                    label="Date de saisie"
                                />
                            </FormGroup>
                        </div>
                        <div>
                            <FormControl style={{marginLeft:20,marginBottom:20}} component="fieldset" variant="standard">
                                <FormLabel component="legend">Statut</FormLabel>
                                <FormGroup row>
                                    <FormControlLabel
                                    control={
                                        <Checkbox checked={rapportField['grStatut'].indexOf("nonTransmis") > -1} onChange={(event)=>handleChecked(event,'grStatut')} name="nonTransmis" />
                                    }
                                    label="Non transmis"
                                    />
                                    <FormControlLabel
                                    control={
                                        <Checkbox checked={rapportField['grStatut'].indexOf("nonAccepter") > -1} onChange={(event)=>handleChecked(event,'grStatut')} name="nonAccepter" />
                                    }
                                    label="En attente acceptation"
                                    />
                                    <FormControlLabel
                                    control={
                                        <Checkbox checked={rapportField['grStatut'].indexOf("accepter") > -1} onChange={(event)=>handleChecked(event,'grStatut')} name="accepter" />
                                    }
                                    label="Acceptées"
                                    />
                                    <FormControlLabel
                                    control={
                                        <Checkbox checked={rapportField['grStatut'].indexOf("rejeter") > -1} onChange={(event)=>handleChecked(event,'grStatut')} name="rejeter" />
                                    }
                                    label="Rejétées"
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl style={{marginLeft:20,marginBottom:20}} component="fieldset" variant="standard">
                                <FormLabel component="legend">Niveau de traitement</FormLabel>
                                <FormGroup row>
                                    <FormControlLabel
                                    control={
                                        <Checkbox checked={rapportField['grEtat'].indexOf("instruction") > -1} onChange={(event)=>handleChecked(event,'grEtat')} name="instruction" />
                                    }
                                    label="Instruction"
                                    />
                                    <FormControlLabel
                                    control={
                                        <Checkbox checked={rapportField['grEtat'].indexOf("valider") > -1} onChange={(event)=>handleChecked(event,'grEtat')} name="valider" />
                                    }
                                    label="Validé (Bon pour paiement"
                                    />
                                    <FormControlLabel
                                    control={
                                        <Checkbox checked={rapportField['grEtat'].indexOf("odrepaiement") > -1} onChange={(event)=>handleChecked(event,'grEtat')} name="odrepaiement" />
                                    }
                                    label="Odre de paiement"
                                    />
                                    <FormControlLabel
                                    control={
                                        <Checkbox checked={rapportField['grEtat'].indexOf("regler") > -1} onChange={(event)=>handleChecked(event,'grEtat')} name="regler" />
                                    }
                                    label="Regler"
                                    />
                                    <FormControlLabel
                                    control={
                                        <Checkbox checked={rapportField['grEtat'].indexOf("terminer") > -1} onChange={(event)=>handleChecked(event,'grEtat')} name="terminer" />
                                    }
                                    label="Traitement terminé"
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                        <Divider />
                        
                        
                        {moreFilter&&(
                        <div style={{display:'flex',flexDirection:'column',marginTop:20}}>
                            <div style={{display:'flex'}}>
                                <TextField
                                    variant="outlined"
                                    id="codebranche"
                                    name ='codebranche'
                                    select
                                    label="Branche"
                                    value={rapportField['codebranche']}
                                    onChange={handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{marginLeft:20, marginBottom:10}}
                                    >
                                    <MenuItem key={0} value={""}>Aucun</MenuItem>
                                    {(branches && branches.length !==0)&& branches.map((option) => (
                                        <MenuItem key={option.codebranche} value={option.codebranche}>
                                        {option.libelle}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    variant="outlined"
                                    id="codepartenaire"
                                    name ='codepartenaire'
                                    select
                                    label="Partenaires"
                                    value={rapportField['codepartenaire']||""}
                                    onChange={handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{marginBottom:10}}
                                    >
                                    <MenuItem key={0} value={""}>Aucun</MenuItem>
                                        
                                    {(partenaires && partenaires.length !==0)&& partenaires.map((option) => (
                                        <MenuItem key={option.code} value={option.code}>
                                        {option.designation}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div style={{display:'flex', flex:1}}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Reseau commercialisation</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="partenaire"
                                    name="partenaire"
                                    value={rapportField["reseau"]||""}
                                    onChange ={handleChange}
                                    label="Partenaire"
                                    >
                                    <MenuItem value="0">
                                        <em>Aucun</em>
                                    </MenuItem>
                                    {(reseau && reseau.length!==0)&&(reseau.map(part=><MenuItem key={`${part.id}`} value={part.id}>{part.libelle}</MenuItem>) )}
                                    
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Zone / inspection</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="zone"
                                    name="zone"
                                    value={rapportField['zone']}
                                    onChange ={handleChange}
                                    label="Zone / Inspection"
                                    >
                                    <MenuItem value="0">
                                        <em>Aucun</em>
                                    </MenuItem>
                                    {(zoneList && zoneList.length!==0)&&(zoneList.map(zone=><MenuItem key={`${zone.id}`} value={zone.id}>{zone.libelle}</MenuItem>) )}
                                    
                                    
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Agence/Equipe</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="agence"
                                    name="agence"
                                    value={rapportField['agence']}
                                    onChange ={handleChange}
                                    label="Agence/Equipe"
                                    >
                                    <MenuItem value="0">
                                        <em>Aucun</em>
                                    </MenuItem>
                                    {(agenceList && agenceList.length!==0)&&agenceList.map(ag=>(<MenuItem key={ag.id} value={`${ag.id}`}>{ag.libelleequipe}</MenuItem>))}
                                    
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        )}
                        <Button onClick={handleMoreFilter} className={classes.btnmore}>{!moreFilter?"Plus de filtres":"Moins de filtres"}</Button>
                    </div>
                    <Divider />
                    <div className={classes.ritem}>
                        <h3>Options</h3>
                        <div style={{display:'flex',flexDirection:'column',padding:20}}>
                            <FormControl component="fieldset">
                            <FormLabel component="legend">Format de fichier</FormLabel>
                            <RadioGroup aria-label="format" name="format" value={rapportField['format']} onChange={handleChange}>
                                <FormControlLabel value="excel" control={<Radio />} label="Feuille de calcul excel (XLSX)" />
                                <FormControlLabel value="csv" control={<Radio />} label="Texte brut (CSV)" />
                                <FormControlLabel value="pdf" control={<Radio />} label="Fichier PDF" />
                            </RadioGroup>
                            </FormControl>
                            <TextField
                                variant="outlined"
                                id="destinataires"
                                name ='destinataires'
                                label="Destinataires"
                                value={rapportField['destinataire']}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <small> Veuillez inscrire l'adresse email des destinataire séparé par une virgule(,)</small>     
                        </div>
                    
                    </div>
                </Paper>
            </div>
            <Paper variant="outlined"  className={classes.rfooter}>
                <div className={classes.rfootcontent}>
                    <Button className={classes.btnDetail}> 
                        <h6>Voir le détail</h6>
                    </Button>
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <Button className={classes.btnDetail}> 
                            annuler
                        </Button>
                        <Button className={classes.btnNew} onClick={handleGenerate} disabled={loading}> Génerer un rapport</Button>
                    </div>
                    
                </div> 
            </Paper>
        </div>
    )
}



const mapPropsActions = {}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({ destroyOnUnmount: false, })(OperationRapportAdd))
