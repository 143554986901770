import React,{useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import MaterialTable,{MTableToolbar } from 'material-table';
import {connect,useDispatch} from 'react-redux'
import CustomMenu from './CustomMenu'
import Button from 'components/CustomButtons/Button'
import {Add} from '@material-ui/icons';
import AddReseau from './AddReseau'

import {
  addReseau,
  getReseauxNoDispatch
} from 'redux/actions/settingsAction'
import RefreshIcon from '@material-ui/icons/Refresh';
function ReseauList(props){
  
   const [reseauList,setReseauList] = useState([])
    const [addopen, setAddopen] = React.useState(false);
    const handleClickAddOpen = () => {
      setAddopen(true);
    };
    const handleCloseAdd = () => {
      setAddopen(false);
    };
    const handleRefleshReseau =()=>{
      getReseauxNoDispatch().then(res=>{
        if(res){
          setReseauList(res)
        }
      })
    }
    useEffect(()=>{
      handleRefleshReseau()
    },[])
    return (
        <GridContainer>
           <AddReseau mopen={addopen} handleCloseMod={handleCloseAdd} handleMopen={handleClickAddOpen} />
          <GridItem xs={12} sm={12} md={12} lg={12}>
              <MaterialTable
                    columns={[
                      { title: '#', field: 'id' },
                      { title: 'Code', field: 'codereseau'},
                      { title: 'Libelle', field: 'libelle'},
                      { title: 'Code responsable', field: 'coderesponsable'},
                      { title: 'Nom responsable.', field: 'nomresponsable'},
                    ]}
                    data={reseauList}
                    title="Reseaux"
                    localization={{
                      toolbar:{
                          searchPlaceholder:'Rechercher',
                          searchTooltip:'Rechercher'
                      },
                      body: {
                        emptyDataSourceMessage: 'Aucune donnée trouvée !'
                      },
                      pagination: {
                        labelRowsSelect: 'Ligne(s)',
                        labelDisplayedRows: '{count} sur {from}-{to}',
                        firstTooltip: 'Prémière page',
                        previousTooltip: 'Précédent',
                        nextTooltip: 'Suivant',
                        lastTooltip: 'Dernière page'
                      }
                    }}
                    
                    options={{
                      exportButton: true,
                      searchFieldStyle:{
                        fontSize:18,
                        width:'100%',
                        height:50,
                      },
                      actionsColumnIndex: -1
                    }}
                    
                    components={{
                    Action: props=> {  
                        return(  
                          <CustomMenu 
                            data={props.data}       
                            />
                          )
                    },
                    Toolbar: props => (
                        <div>
                        <MTableToolbar {...props} />
                          <div style={{padding: '0px 10px',display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
                            <Button onClick={handleClickAddOpen} variant='outlined' color='primary' >
                                <Add style={{color:'#fff',fontSize: 30}} />
                            </Button> 
                            <Button onClick={handleRefleshReseau} variant='outlined' color='inherit' >
                                <RefreshIcon style={{color:'#fff',fontSize: 30}} />
                            </Button> 
                          </div>
                          
                        </div>
                    )
                    }}
                    actions={[
                      {
                        icon: 'more_vert',
                        tooltip: 'Actions',
                        onClick: (event, rowData) =>alert("You want to add a new row"),
                      },
                    ]}
                    
               />
          </GridItem>
        </GridContainer>
    )
}
const mapStateToProps = (state) => ({
    user:state.user
  });
  const mapActionsToProps = {addReseau}
export default connect(mapStateToProps,mapActionsToProps)(ReseauList)