import React, { useReducer,useEffect } from 'react';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import {connect} from 'react-redux'
import Button from 'components/CustomButtons/Button'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInput'
import { useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import {updateEquipe,getReseauxNoDispatch,getZonesReseau} from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';

function UpdateEquipe(props){
    const {equipeData,ropen,handleCloseAdd}=props
    const theme = useTheme();
    const [reseaux,setReseaux] = React.useState([])
    const [zones,setZones] = React.useState([]);
    useEffect(() => {
        getReseauxNoDispatch().then(data=>{if(data){setReseaux(data);}})
        
    }, [])

  
  const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
    codereseau:'',
    codezone:equipeData.codezone?equipeData.codezone:"",
    libelleequipe:equipeData.libelleequipe?equipeData.libelleequipe:"",
    coderesponsable:equipeData.coderesponsable?equipeData.coderesponsable:"",
    nomresponsable:equipeData.nomresponsable?equipeData.nomresponsable:"",
    codeequipe:equipeData.codeequipe?equipeData.codeequipe:""
    });
    useEffect(() => {
        if(inputField.codereseau!==''){
          getZonesReseau(inputField.codereseau).then(datar=>{
            if(datar){
              setZones(datar)
            }
          })
        }
      },[inputField.codereseau]) 
  const handleChange = (event)=>{
    const {name,value} = event.target
    setInputField({
        [name]:value
    })
  }
  const handleSaveEquipe=()=>{
    if(inputField.codezone==='')return
    if(inputField.libelleequipe.trim()==='')return
    if(inputField.codeequipe.trim()==='')return
    props.updateEquipe(equipeData.id,inputField).then(data=>{
        if(data){
            handleCloseAdd()
        }else{
            return false
        }
    }).catch(err=>{
        console.log(err);
        return
    })
  }
    return (
        <Dialog 
            maxWidth='md' 
            fullWidth
            open={ropen} 
            onClose={handleCloseAdd} 
            aria-describedby="alert-dialog-description"
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Modifier une équipe/agence - #{equipeData.id}</DialogTitle>
            <DialogContent>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4} lg={4}>
                                <CustomInput
                                    required
                                    autoFocus
                                    variant="outlined"
                                    id="codeequipe"
                                    name='codeequipe'
                                    label="code"
                                    type="text"
                                    value = {inputField.codeequipe}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={handleChange}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8} lg={8}>
                                <CustomInput
                                    autoFocus
                                    required
                                    variant="outlined"
                                    id="libelleequipe"
                                    name ='libelleequipe'
                                    onChange={handleChange}
                                    label="Nom de l'équipe / agence"
                                    type="text"
                                    value = {inputField.libelleequipe}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4} lg={4}>
                                <CustomInput
                                    required
                                    autoFocus
                                    variant="outlined"
                                    id="coderesponsable"
                                    name='coderesponsable'
                                    label="code responsable"
                                    type="text"
                                    value = {inputField.coderesponsable}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={handleChange}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8} lg={8}>
                                <CustomInput
                                    autoFocus
                                    required
                                    variant="outlined"
                                    id="nomresponsable"
                                    name ='nomresponsable'
                                    onChange={handleChange}
                                    label="Nom responsable"
                                    type="text"
                                    value = {inputField.nomresponsable}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    variant="outlined"
                                    id="codereseau"
                                    name ='codereseau'
                                    select
                                    label="Réseau de commercialisation"
                                    value={inputField.codereseau}
                                    onChange={handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{marginTop:20,marginBottom:10}}
                                    >
                                         <MenuItem value='' />
                                    {(reseaux.length !==0)&& reseaux.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                        {option.libelle}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    variant="outlined"
                                    id="codezone"
                                    name ='codezone'
                                    select
                                    label="Zone/Inspection/Departement"
                                    value={inputField.codezone}
                                    onChange={handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{marginTop:20,marginBottom:10}}
                                    >
                                         <MenuItem value='' />
                                    {(zones.length !==0)&& zones.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                        {option.libellezone}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </GridItem>
                        </GridContainer> 
                    </GridItem>
                </GridContainer>
                <Divider />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseAdd}  variant='outlined' color="secondary">
                    Annuler
                </Button>
                <Button onClick={handleSaveEquipe}  variant='outlined' color="primary">
                    Modifier
                </Button>
            </DialogActions>
      </Dialog>
    )
}
UpdateEquipe.propTypes = {
    equipeData: PropTypes.object.isRequired,
    ropen: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    settings: state.settings,
  });
  const mapActionsToProps = {updateEquipe}
export default connect(mapStateToProps,mapActionsToProps)(UpdateEquipe)