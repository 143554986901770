import React,{useState,useEffect} from 'react'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {getDocument} from 'redux/actions/apiActions'
import DocSideItem from 'pages/Producteur/Propositions/components/DocSideItem'
import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CachedIcon from '@material-ui/icons/Cached';
function DocumentSide(props) {
    const {id} =props
    const [documents,setDocuments]=React.useState([])
    useEffect(() => {
        if(id){
            getDocument(id).then(res=>{
                if(res){
                    setDocuments(res.documents)
                }
            })
        }
       
    }, [id])
   
    return (
        <Paper style={{marginTop:20,padding:10,overflowY:"scroll",maxHeight:400}}>
            <div style={{display:'flex',flexDirection:'row'}}>
                <Typography component='h5' variant='h5'>Documents</Typography>
                <Button size="small" color="primary"><CachedIcon /></Button>
            </div>
            
            <Divider />
           
            {(documents && documents.length!==0)?(
              documents.map(doc=>(<DocSideItem data={doc}/>))   
):(<div>
    <Typography>Aucun document joint</Typography>
</div>)}
   
        </Paper>
    )
}


export default DocumentSide

