import React, { useEffect,useReducer,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from 'components/Grid/GridItem'
import MaterialTable,{MTableToolbar } from 'material-table';
import {connect,useDispatch} from 'react-redux'
import CustomMenu from './CustomMenu'
import Button from 'components/CustomButtons/Button'
import {Add} from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import {
    getUtilisateur,
    historiqueEquipeuser,
    getEquipesZones,
    getZonesReseau,
    getRolesReseau,
    getReseauxNoDispatch,
    getBranche,
    getPartenaire,
    getUtilisateurNoDispatch
  } from 'redux/actions/settingsAction'
import CircularProgress from '@material-ui/core/CircularProgress';
import dayjs from 'dayjs'
import Paper from '@material-ui/core/Paper';
import Typography  from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import CustomRadio from 'components/CustomRadio/CustomRadio'
import { Tooltip,IconButton,Chip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add'
import {useHistory} from 'react-router-dom'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    justifyContent:'center',
    alignItems:'center',
    marginTop:20,
    marginLeft:20,
    marginRight:20
  },
  hPaper:{
    padding:10,
    marginBottom:20,
    borderLeftColor: theme.palette.primary.main,
    borderLeftWidth:8
},
bPaper:{
  padding:10,
  marginBottom:20,
  borderRadius:0
},
hContainer:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
},
loaddingContainer:{
  display:'flex',
  justifyContent:'space-between',
  alignItems:'center'
},
bcontainer:{
  paddingTop:10,
  backgroundColor:'transparent',
  borderRadius:0
},
bhead:{
    padding:10
},
  details:{
    padding:20,
  },
  container: {
    display: 'flex',
    justifyContent:'stretch',
    flexWrap: 'wrap',
    width:'100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonSearch:{
    borderWidth:1,
    borderStyle:'dashed',
    borderColor:theme.palette.primary.main,
    backgroundColor:theme.palette.primary.main,
   
    color:'#FFF',
    '&:hover':{
      backgroundColor:theme.palette.secondary.main,
      borderColor:theme.palette.secondary.main,
    }
  }
}));

function UtilisateurList(props){
    const classes = useStyles();
    const {settings:{allUtilisateur},ui:{loading}}=props
    const  dispatch = useDispatch()
    const [histDataUser,sethistDataUser] = React.useState(null);
    const [selectedRow, setSelectedRow] = React.useState(null)
    const [users,setUsers]=React.useState([...allUtilisateur])
    const history = useHistory()
    const [zones,setZones] = useState(null)
    const [equipes,setEquipes] = useState(null)
    const [reseaux,setReseaux] = useState(null)
    const [rolesReseau,setRolesReseau] = useState(null)
    const [branches,setBranches] = React.useState(null)
    const [partenaires,setPartenaires] = React.useState(null)

    const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
      codepartenaire:'',
      codebranche:'',
      codezone:'',
      codeequipe:'',
      codereseau:'',
      coderole:'',
      typemembre:'',
      memberok:'all'
    });

    useEffect(()=>{
      if(inputField.codereseau!==''){
        getZonesReseau(inputField.codereseau).then(dataZone=>{
            if(dataZone){
                setZones(dataZone)
            }
        })
      }
      if(inputField.codereseau!==''){
        getRolesReseau(inputField.codereseau).then(dataRole=>{
            if(dataRole){
                setRolesReseau(dataRole)
            }
        })
      }
  },[inputField.codereseau])
  useEffect(() => {
    if(inputField.codezone!==''){
      getEquipesZones(inputField.codezone).then(dataEquipe=>{
        if(dataEquipe){
            setEquipes(dataEquipe)
        }
      })
    }
  },[inputField.codezone])

  useEffect(() => {
    getReseauxNoDispatch().then(drezo=>{
      if(drezo){
          setReseaux(drezo)
      }
    })
    getBranche().then(data=>{
      if(data){
          setBranches(data);
      }
    })
    getPartenaire().then(data=>{
      if(data){
          setPartenaires(data);
      }
    })
  },[])

  const handleHistDataUser =(userData)=>{
    setSelectedRow(userData)
    if(userData){
      historiqueEquipeuser(userData.idmembre).then(res=>{
        if(res){
          sethistDataUser({user:userData,hist:res})
        }else{
          sethistDataUser({user:userData,hist:null})
        }
      }).catch(err=>{
        sethistDataUser({user:userData,hist:null})
      })
    }
  }
  
    useEffect(() => {
        sethistDataUser(null)
    }, [])


    //filtrage des données
    const handleFilterData =()=>{
      getUtilisateurNoDispatch(inputField).then(datauser=>{
        if(datauser){
          dispatch({type:'SET_ALLUTILISATEUR',payload:datauser})
          dispatch({type:'CLEAR_ERRORS'})
          setUsers(datauser)
        }else{
          dispatch({type:'SET_ERRORS',payload:{'setttings':"Une erreur inconnu s'est produit lors de l'opération"}})
          return
        }
      }).catch(err=>{
        console.log(err);
        dispatch({type:'SET_ERRORS',payload:{'setttings':"Une erreur inconnu s'est produit lors de l'opération"}})
        return
      })
    }

 
    const handleChange = (event)=>{   
      const {name,value} = event.target
      setInputField({
          [name]:value
      })
    }
    const handleNewUser =()=>{
      console.log("AAA");
      history.push(`/settings/utilisateur/add`)
    }
  
    return (
        <div className='mx-2 my-3'>
           
           <Paper className={classes.hPaper} variant="outlined">
                <div className='flex flex-row items-center justify-between'>
                  <Typography variant='h5'>Gestion des utilisateurs</Typography>
                 
                  <Tooltip title="Nouvelle utilisateur"> 
                      <IconButton onClick={handleNewUser} color='primary'>
                          <AddIcon style={{fontSize:40}} />
                      </IconButton>
                  </Tooltip>
                </div>
            </Paper>
            <Paper className='my-3 p-2' >
                        <form className={classes.container} noValidate>
                            <div style={{padding:10,flex:1}}>
                              <TextField
                                  variant="outlined"
                                  id="codepartenaire"
                                  name ='codepartenaire'
                                  select
                                  label="Partenaires"
                                  value={inputField.codepartenaire&&inputField.codepartenaire}
                                  onChange={handleChange}
                                  fullWidth
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  style={{marginBottom:10}}
                                  >
                                  <MenuItem key={0} value={""}>Aucun</MenuItem>
                                      
                                  {(partenaires && partenaires.length !==0)&& partenaires.map((option) => (
                                      <MenuItem key={option.code} value={option.code}>
                                      {option.designation}
                                      </MenuItem>
                                  ))}
                              </TextField>
                              <TextField
                                  variant="outlined"
                                  id="codebranche"
                                  name ='codebranche'
                                  select
                                  label="Branche"
                                  value={inputField.codebranche}
                                  onChange={handleChange}
                                  fullWidth
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  style={{marginBottom:10}}
                                  >
                                    <MenuItem key={0} value={""}>Aucun</MenuItem>
                                  {(branches && branches.length !==0)&& branches.map((option) => (
                                      <MenuItem key={option.codebranche} value={option.codebranche}>
                                      {option.libelle}
                                      </MenuItem>
                                  ))}
                              </TextField>
                          </div>
                        <div style={{padding:10,flex:1}}>
                            <TextField
                                variant="outlined"
                                id="codereseau"
                                name ='codereseau'
                                select
                                label="Réseau de commercialisation"
                                value={inputField.codereseau&&inputField.codereseau}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{marginBottom:10}}
                                >
                                 <MenuItem key={0} value={""}>Aucun</MenuItem>
                                    
                                {(reseaux && reseaux.length !==0)&& reseaux.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                    {option.libelle}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                variant="outlined"
                                id="codezone"
                                name ='codezone'
                                select
                                label="Zone/Departement"
                                value={inputField.codezone}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{marginBottom:10}}
                                >
                                 <MenuItem key={0} value={""}>Aucun</MenuItem>
                                {(zones && zones.length !==0)&& zones.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                    {option.libellezone}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div style={{padding:10,flex:1}}>
                            <TextField
                                variant="outlined"
                                id="codeequipe"
                                name ='codeequipe'
                                select
                                label="Equipe/Agence"
                                value={inputField.codeequipe&&inputField.codeequipe}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{marginBottom:10}}
                                >
                                   <MenuItem key={0} value={""}>Aucun</MenuItem>
                                {(equipes && equipes.length !==0)&& equipes.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                    {option.libelleequipe}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                variant="outlined"
                                id="typemembre"
                                name ='typemembre'
                                select
                                label="Type membre"
                                value={inputField.typemembre&&inputField.typemembre}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{marginBottom:10}}
                                >
                                    <MenuItem key={0} value={""}>Aucun</MenuItem>
                          
                                    <MenuItem  value={3}>
                                        Client
                                    </MenuItem>
                                    <MenuItem  value={2}>
                                        Commercial 
                                    </MenuItem>
                                    <MenuItem  value={7}>
                                        Agent banque assurance
                                    </MenuItem>
                                    <MenuItem  value={8}>
                                       Courtier
                                    </MenuItem>
                            </TextField>
                        </div>
                        <div className='flex flex-col justify-center items-center mx-2'>
                        <FormControl>
                          <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="memberok"
                              onChange={handleChange}
                              fullWidth
                            >
                              <FormControlLabel value="on" control={<Radio />} label="Actif" />
                              <FormControlLabel value="off" control={<Radio />} label="Bloquer" />
                              <FormControlLabel value="all" control={<Radio />} label="Tous" />
                              
                            </RadioGroup>
                        </FormControl>
                       
                          <TextField
                                variant="outlined"
                                id="coderole"
                                name ='coderole'
                                select
                                label="Profil"
                                value={inputField.coderole}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{marginBottom:10}}
                                >
                                   <MenuItem key={0} value={""}>Aucun</MenuItem>
                                {(rolesReseau && rolesReseau.length !==0)&& rolesReseau.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                    {option.role}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <Button variant='outlined' onClick={handleFilterData} className='bg-primary-main text-white hover:bg-secondary-main'>
                          Afficher
                        </Button> 
                      </form>     
                    </Paper>
            <Paper className={classes.bcontainer} variant="outlined">
                <div className='p-2 flex flex-row justify-start items-center'>
                  <Typography variant='body'>Liste des utilisateurs</Typography>
                      <div className=' flex flex-row justify-center items-center mx-3'>
                            {(users && users.length!==0)&&<Chip label={`Total(s) ${users&&users.length}`} />}
                      </div>
                  </div>
            
              <Divider />
              {loading?(
                 <div className={classes.root}>
                   <CircularProgress color="primary" />
               </div>
              ):(
                <MaterialTable
                columns={[
                  { title: '#', field: 'idmembre' },
                  { title: 'Nom', field: 'nom'},
                  { title: 'Prénom', field: 'prenom'},
                  { title: 'Date naissence', field: 'datenaissance',render:rowDtat=>(rowDtat.datenaissance?dayjs(rowDtat.datenaissance).format('DD/MM/YYYY'):"")},
                  { title: 'Email', field: 'email'},
                  { title: 'Telephone', field: 'telephone',render:rowData=>((rowData.telephone && rowData.telephone2)?(rowData.telephone+" / "+rowData.telephone2):rowData.telephone)},
                  { title: 'Profile', field: 'role'},
                  { title: 'Reseau', field: 'libellereseau'},
                  { title: 'Zone', field: 'libellezone'},
                  { title: 'Equipe', field: 'libelleequipe'},
                ]}
                data={users}
                title=""
                localization={{
                  toolbar:{
                      searchPlaceholder:'Rechercher',
                      searchTooltip:'Rechercher'
                  },
                  body: {
                    emptyDataSourceMessage: 'Aucune donnée trouvée !'
                  },
                  pagination: {
                    labelRowsSelect: 'Ligne(s)',
                    labelDisplayedRows: '{count} sur {from}-{to}',
                    firstTooltip: 'Prémière page',
                    previousTooltip: 'Précédent',
                    nextTooltip: 'Suivant',
                    lastTooltip: 'Dernière page'
                  }
                }}
                
                
                options={{
                  exportButton: true,
                  searchFieldStyle:{
                    fontSize:18,
                    width:'100%',
                    height:50,
                  },
                  actionsColumnIndex: -1,
                  rowStyle: rowData => ({
                    backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#FFF1EB' : '#FFF'
                    })
                }}
                
                components={{
                Action: lprops=> {  
                    return(  
                      <CustomMenu 
                        data={lprops.data}
                        handleFilterData={handleFilterData}
                        history={props.history}       
                        />
                      )
                },
                
                }}
                actions={[
                  {
                    icon: 'more_vert',
                    tooltip: 'Actions',
                    onClick: (event, rowData) =>alert("You want to add a new row"),
                  },
                ]}
                
              />
              )}
            </Paper>
            {histDataUser&&(<GridItem xs={12} sm={12} md={4} lg={4} className={classes.details}>
                <Typography variant='h5'>Détails</Typography>
                <Paper elevation={0} className={classes.details}>
                  <Typography variant='subtitle1'>Dernière connexion :  </Typography>
                  <Typography variant='h6'>{(histDataUser)?(histDataUser.user.lastvisite?dayjs(histDataUser.user.lastvisite).format('DD/MM/YYYY'):""):""}  </Typography>
                 <Divider />
                 {(histDataUser && histDataUser.hasOwnProperty('hist'))?<>
                    <Typography variant='subtitle1'>Historique des mouvements en agence</Typography>
                    <Divider />
                    <Table>
                       <TableBody>
                          {histDataUser.hist.map(el=>(
                             <TableRow key={el['idequipeuser']||""}>
                                <TableCell align="left">{el['libelleequipe']}</TableCell>
                                <TableCell align="center">{el['dateadd']?dayjs(el['dateadd']).format('DD/MM/YYYY'):""}</TableCell>
                                <TableCell align="center">{el['dateupdate']?dayjs(el['dateupdate']).format('DD/MM/YYYY'):""}</TableCell>
                             </TableRow>
                          ))}
                       </TableBody>
                    </Table>
                 </>:null}
                </Paper>
            </GridItem>)}
        </div>
    )
}
const mapStateToProps = (state) => ({
    settings: state.settings,
    user:state.user,
    ui:state.UI
  });
  const mapActionsToProps = {getUtilisateur}
export default connect(mapStateToProps,mapActionsToProps)(UtilisateurList)