import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from "@material-ui/core/Typography";
import {connect} from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import MoneyIcon from '@material-ui/icons/Money';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PDFReader } from 'react-read-pdf'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Grid from '@material-ui/core/Grid';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    backgroundColor: theme.palette.background.paper,
    height: "100%",
  },
  tabs: {
    height:'100%',
    backgroundColor:'#f2f2f2',
    '&:hover':{
      
    }
  },
  tab: {
    margin:20,
    "&:active":{
      borderRight: `5px solid ${theme.palette.primary.main}`,
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  qrdetails:{
    backgroundColor:theme.palette.secondary
  },
  buttonDetails:{
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-start',
    borderRadius:0,
    color:theme.palette.secondary.main,
    '&:hover':{
      borderColor:theme.palette.primary.main,
    }
  },
  }));

function ProduitDiscover(props){
    const classes = useStyles();
    const theme = useTheme();
    //intialisation des élement du state
    const {qr,tb,prod}=props //recuperation des valieur du props
   
    const [value, setValue] = React.useState(tb);

    const [currentPage,setCurrentPage] = React.useState(1)
    const [totalPage,setTotalPage] = React.useState(1)

    const [currentPageForm,setCurrentPageForm] = React.useState(1)    
    const [totalPageForm,setTotalPageForm] = React.useState(1)
    
    const [currentPageGrid,setCurrentPageGrid] = React.useState(1)
    const [totalPageGrid,setTotalPageGrid] = React.useState(1)

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

   const handleNextPage =(type)=>{
      if(type ==="FICHE"){
        setCurrentPage(currentPage + 1)
      }
    
      if(type ==="FORM"){
        setCurrentPageForm(currentPageForm + 1)
      }

      if(type ==="GRID"){
        setCurrentPageGrid(currentPageGrid + 1)
      } 
   }

   const handleBackPage =(type)=>{
    
    if(type ==="FICHE"){
      if(currentPage>1){
        setCurrentPage(currentPage - 1)
      }
    }

    if(type ==="FORM"){
      if(currentPageForm>1){
        setCurrentPageForm(currentPageForm - 1)
      }
    }

    if(type ==="GRID"){
      if(currentPageGrid>1){
        setCurrentPageGrid(currentPageGrid - 1)
      }
    } 
   }
  const handleInfo =(allPage)=>{
    setTotalPage(allPage)
  }
  const handleInfoForm =(allPage)=>{
    setTotalPageForm(allPage)
  }
  const handleInfoGrid=(allPage)=>{
    setTotalPageGrid(allPage)
  }
  const guideLine=(type)=>{
    const setInitPage = type==="FICHE"?setCurrentPage:(type==="FORM"?setCurrentPageForm:setCurrentPageGrid)
    const localCurrentPage = type==="FICHE"?currentPage:(type==="FORM"?currentPageForm:currentPageGrid)
    const localTotalPage = type==="FICHE"?totalPage:(type==="FORM"?totalPageForm:totalPageGrid)
    return <div style={{ marginTop:75,marginLeft:25,marginRight:25}}>
      
      <GridContainer direction="row" justify="center" alignItems="center">
        <GridItem xs={1} sm={1} md={1} lg={1} >
            <IconButton color='inherit' className={classes.buttonDetails} disabled={!(localCurrentPage>1)} onClick={()=>setInitPage(1)}>
                  <FirstPageIcon color={(localCurrentPage>1)?'primary':"secondary"} style={{ fontSize: 50 }} />
            </IconButton>
        </GridItem>
       <GridItem xs={1} sm={1} md={1} lg={1} >
         <IconButton color='inherit' className={classes.buttonDetails}  disabled={!(localCurrentPage>1)} onClick={()=>handleBackPage(type)}>
               <NavigateBeforeIcon color={(localCurrentPage>1)?'primary':"secondary"} style={{ fontSize: 50 }} />
         </IconButton>
       </GridItem>
       <GridItem xs={1} sm={1} md={1} lg={1}>
         <IconButton color='inherit' className={classes.buttonDetails} disabled={!(localCurrentPage<localTotalPage)} onClick={()=>handleNextPage(type)}>
               <NavigateNextIcon color={(localCurrentPage<localTotalPage)?'primary':"secondary"} style={{ fontSize: 50 }} />
           </IconButton>
       </GridItem>

  </GridContainer>
      
    </div>
    }
    
    return (
       <div style={{ marginTop:75,marginLeft:25,marginRight:25}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={classes.tabsRoot}>
                <Grid container>
                  <Grid item>
                        <Tabs
                          orientation="vertical"
                          variant="scrollable"
                          value={value}
                          onChange={handleChange}
                          aria-label="Vertical tabs example"
                          className={classes.tabs}
                        >
                          <Tab className={classes.tab} icon={<AssignmentLateIcon />} label="Fiche produit" {...a11yProps(0)} />
                          <Tab icon={<LiveHelpIcon />} label="Questions & réponses" {...a11yProps(1)} />
                          <Tab icon={<BookmarksIcon />} label="Support de formation" {...a11yProps(2)} />
                          <Tab icon={<MoneyIcon />} label="Guide tarifaire" {...a11yProps(3)} />
                      </Tabs>
                  </Grid>
                  <Grid item xs={12} sm={10} md={10} lg={10}>   
                      <TabPanel value={value} index={0}>
                          {guideLine("FICHE")}
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              {prod==='YKE'?<PDFReader page={currentPage} onDocumentComplete={handleInfo} url={require('assets/pdf/Fiche_produit_YAKO_BNI.pdf')} />:(<PDFReader page={currentPage} onDocumentComplete={handleInfo} url={require('assets/pdf/Fiche_produit_PVR.pdf')} />)}
                              
                            </Grid>
                          </Grid>
      
                            {guideLine("FICHE")}
                      </TabPanel>
                  <TabPanel value={value} index={1}>
                    {(qr && qr.length!==0)?(
                      qr.map((q,idx)=>(
                        <Accordion key={idx}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant='h4' className={classes.heading} component='h4'>{q.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className={classes.qrdetails}>
                          <Typography variant='body1'> {q.reponse}</Typography>
                          </div>
                          
                        </AccordionDetails>
                      </Accordion>
                      ))
                    ):(<Typography className={classes.heading}>contenu non disponible</Typography>)}
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                      {guideLine("FORM")}
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              {prod==='YKE'?(<PDFReader page={currentPageForm} onDocumentComplete={handleInfoForm} url={require('assets/pdf/yako_bancassurance_BNI.pdf')} />):(<PDFReader page={currentPageForm} onDocumentComplete={handleInfoForm} url={require('assets/pdf/Formation_PVR_BNI.pdf')} />)}
                              
                            </Grid>
                        </Grid>
                        {guideLine("FORM")}
                  </TabPanel>
                    <TabPanel value={value} index={3}>
                    {guideLine("GRID")}
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              {prod==="YKE"?(<PDFReader page={currentPageGrid} onDocumentComplete={handleInfoGrid} url={require('assets/pdf/guide_tarifaire.pdf')} />):(<Typography  center className={classes.heading}>Contenu non disponible</Typography>)}
                            </Grid>
                        </Grid>
                        {guideLine("GRID")}
                      </TabPanel>
                  </Grid>
                </Grid>
               
                  
              </div>
            </Grid>
       </div>
    )
}
 const mapStateToProps = (state) => ({
    user:state.user
});
  const mapActionsToProps = {}
export default connect(mapStateToProps,mapActionsToProps)(ProduitDiscover)