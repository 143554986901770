import React, { useState,useEffect } from 'react'
import { getMedecins } from 'redux/actions/apiActions';
import Ask  from './components/ask'
import { Button, Checkbox, Divider,Paper, Typography } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { reduxForm, change, getFormValues, registerField } from 'redux-form'
import { connect } from 'react-redux';
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme=>({
    heading: {
        fontSize:26,
        fontWeight: 'bold',
    },
    paper:{
        padding:'20px',
    },
    btnnext:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:green[400],
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#f9b233",
        },
        borderRadius:10,
      },
      btnback:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:"#f9b233",
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#3c3c3b",
        },
        borderRadius:10,
    },
    table: {
        minWidth: 700,
    },
}))

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const InfoMedecin = (props) => {
    const classes = useStyles();
    const { handleSubmit,handleBack,handleNext,handleAttente,steps,activeStep,data } = props  
    const [medecinList,setMedecinList] = useState(null)
    const handleMedecin = ()=>{
        getMedecins().then(res=>{
            res && setMedecinList(res) 
        })
    }  
    useEffect(()=>{
        handleMedecin()
    },[])
    const [checked, setChecked] = React.useState(data.hasOwnProperty('medecin')?data.medecin:{});
    const handleChange = (data) => {
        if(data && data.id === checked.id){
            setChecked({});
            props.updateField('medecin',null)  
        }else{
            setChecked(data);
            props.dispatch(registerField("addPret",'medecin','Field'));
            props.updateField('medecin',data)
        }
      
    };
    
  return (
     <form onSubmit={handleSubmit(handleNext)}>
        <Paper elevation={0} className={classes.paper}>
            <Typography className={classes.heading}>Etape {activeStep?(activeStep+1):1} : {(steps && activeStep)?steps[activeStep]:""}</Typography>
            <Divider />
        </Paper>
        <Ask>
            <Paper variant='outline' style={{padding:20,margin:20,backgroundColor:red[100],color:red[500],fontSize:16,fontWeight:'bold'}}>
                ** Cette cotation nécessite l'avis d'un medecin, veuillez choisir dans la liste de nos medecins agrées ci-dessous selon la localisation de l'emprunteur **
                <div style={{padding:10}}>
                    <Typography style={{color:red[500]}} variant="h4" component="h4">Motifs : </Typography>
                      {(data && data.hasOwnProperty('motifcotation') && data.motifcotation && data.motifcotation.length !== 0) && (data.motifcotation.map((el, i) => <Typography variant="body1" style={{color:red[700],marginLeft:25}} component="h6">{`${i + 1} : ${el}`}</Typography>))}
                </div>
            </Paper>
            <Ask.Inner>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>N° ONMCI</StyledTableCell>
                                <StyledTableCell align="center">Nom</StyledTableCell>
                                <StyledTableCell align="center">Ville</StyledTableCell>
                                <StyledTableCell align="center">Téléphone</StyledTableCell>
                                <StyledTableCell align="center">Email</StyledTableCell>
                                <StyledTableCell align="center">Disponibilité</StyledTableCell>
                            </TableRow>
                        </TableHead>
                            <TableBody>
                                {(medecinList && medecinList.length !==0) ? (
                                    medecinList.map(medecin=><StyledTableRow>
                                        <StyledTableCell align="center">
                                            <Checkbox
                                                checked={medecin.id === checked.id}
                                                onChange={()=>handleChange(medecin)}
                                                inputProps={{'aria-label': 'primary checkbox' }}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell component="th" align="center">
                                            {medecin.numonmci}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" align="right">
                                            {`${medecin.titre} ${medecin.nom} ${medecin.prenom}`}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {`${medecin.ville}`}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {`${medecin.mobile} /${medecin.mobile}`}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {`${medecin.email}`}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {`${medecin.disponibilite}`}
                                        </StyledTableCell>
                                    </StyledTableRow>)
                                ) : (
                                    <StyledTableRow>
                                    <StyledTableCell colSpan={6} align="center">
                                       <p>Aucun médecin trouvé</p>
                                    </StyledTableCell>
                                   
                                </StyledTableRow>
                                )}
                                
                            </TableBody>
                       
                    </Table>
                </TableContainer>
            </Ask.Inner>
        </Ask>
        <Divider />
        <div style ={{display:"flex",justifyContent:"space-between",padding:20}}>
            <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.btnback}
                
            >
                Retour
            </Button>
            
            <Button
                variant="contained"
                color="primary"
                disabled={!checked.hasOwnProperty('id')}
                className={classes.btnnext}
                large
                type='submit'
            >
                {activeStep === steps.length - 1 ? 'Valider' : 'SUivant'}
            </Button>
        </div>
    </form>
  )
}

const mapStateToProps = (state) => ({
    user: state.user,
    api: state.api,
    ui: state.UI,
    data: getFormValues('addPret')(state)
  })
  
  
  
  const updateField = (field, data) => (dispatch) => dispatch(change('addPret', field, data))
  const mapActionsToProps = {
    updateField
  }

  export default connect(mapStateToProps, mapActionsToProps)(reduxForm({ form: 'addPret', destroyOnUnmount: false, })(InfoMedecin))
  

