import React, { useEffect, useState } from 'react'
import ConventionsAccueil from './ConventionsAccueil'
import { useSelector } from 'react-redux'

const ConventionsContainer = (props) => {
  const [fonctionShow,setFonctionShow] =useState('HOME')
  const handleCliqueFonction =(show,md,cdata)=>{
      if(show === 'UP' ){
        setMode('UP')
      }else{
        setMode(md)
      }
      setData(cdata)
      setFonctionShow(show)
  }
  const [data,setData] =useState(null)
  const [mode,setMode] =useState('ADD')

  return (
    <div>
        {(fonctionShow==='HOME')&&<ConventionsAccueil  handleCliqueFonction={handleCliqueFonction} />}
    </div>
  )
 
}

export default ConventionsContainer