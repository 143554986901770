import React,{useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/styles'
import {Paper,Tooltip,IconButton,Typography, Divider} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { getRapportAll,getrapport,deleteRapport } from 'redux/actions/backendActions'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import SendIcon from '@material-ui/icons/Send';
import dayjs from 'dayjs';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import swal from 'sweetalert';
import {rapportPdf,exportCSVFile,exportToExcel} from 'application'
const useStyle = makeStyles(theme=>({
  root:{
      width:'75%',
      marginLeft:'auto',
      marginRight:'auto',
      marginTop:75
  },
  rheader:{
      padding:10,
      marginBottom:20,
      borderLeftColor: theme.palette.primary.main,
      borderLeftWidth:8,
      display:'flex',
      justifyContent:'space-between',
      
  },
  btnNew:{
      padding:20,
      paddingTop:5,
      paddingBottom:5,
      paddingRight:20,
      marginRight:20,
      backgroundColor:theme.palette.primary.main,
      color:'white',
      "&:hover":{
        backgroundColor:theme.palette.secondary.main,
      }
  },
  rmain:{
    display:'flex',
    flexDirection:'column',
    marginBottom:75
  },
  rtabs:{
    display:'flex',
    justifyContent:'space-between'
  },
  rtabsright:{
    display:'flex'
  },
  btntabs:{
    backgroundColor:'transparent !important',
    borderRadius:0,
    marginRight:20,
    paddingRight:20,
    paddingLeft:20,
    "&:hover":{
      backgroundColor:'transparent !important',
      color:theme.palette.primary.main,
    }
  },
  btntabsSelect:{
    backgroundColor:'transparent !important',
    borderColor:theme.palette.primary.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    borderRadius:0,
    marginRight:20,
    paddingRight:20,
    paddingLeft:20,
    "&:hover":{
      backgroundColor:'transparent !important',
      color:theme.palette.primary.main,
    }
  },
  rempty:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    paddingTop:75,

  },
  tt:{
    margin:20
  }
}))

const BouttonNew =({handleClick,classes})=>(<Button className={classes.btnNew} onClick={handleClick}>
Créer un rapport
</Button>)

function OperationRapport(props) {
  const classes = useStyle()
  const [tabIsSelect,setTabIsSelect] = useState('rp')
  const [rapport,setRapport] =useState(null)
  const [rapportrecu,setRapportrecu] = useState(null)
  const handleRapportAll =()=>{
    getRapportAll().then(res=>{
      if(res){
        setRapport(res)
        setRapportrecu(res.filter(item=>item.propriete === 'recurrent'))
      }
    })
  }
  
 
  const handleSelectRapport=(rp)=>{
    setTabIsSelect(rp)
  }
  const handleNewRapport=()=>{
    props.handleCurrentView('RAPPORTNEW')
  }

  useEffect(()=>{
    handleRapportAll()
  },[])
  const [dataSel,setDataSet] =useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOptionsClick = (event,dt) => {
      setDataSet(dt)
      setAnchorEl(event.currentTarget);
  };
  const handleOptionsClose = () => {
      setDataSet(null)
      setAnchorEl(null);
  };
  //action to options
  const handleDownload = ()=>{
    if(dataSel && Object.keys(dataSel).length !==0){
        //créated params détails
       {/*  let dataSend ={}
        if(dataSel['grOperations'] && dataSel.grOperations.length!==0){
            dataSend ={...dataSend,"grOperations":dataSel['grOperations']}
        }
        if(dataSel['grStatut'] && dataSel.grStatut.length!==0){
            dataSend ={...dataSend,"grStatut":dataSel['grStatut']}
        }
        if(dataSel['grEtat'] && dataSel.grEtat.length!==0){
            dataSend ={...dataSend,"grEtat":dataSel['grEtat']}
        }
        if((dataSel['grPeriode'] && dataSel.grPeriode.length!==0) && 
            (
                (dataSel['periodedu'] && dataSel.periodedu!=="") && 
                (dataSel['periodeau'] && dataSel.periodeau!=="")
            )){
            if(dataSel.grPeriode.indexOf('dateoperation') > -1){
                dataSend={...dataSend,'operationdu':dataSel['periodedu'],'operationau':dataSel['periodeau']}  
            }
            if(dataSel.grPeriode.indexOf('datetransmission') > -1){
                dataSend={...dataSend,'transmisdu':dataSel['periodedu'],'transmisau':dataSel['periodeau']}  
            }
            if(dataSel.grPeriode.indexOf('dateacceptation') > -1){
                dataSend={...dataSend,'accepterdu':dataSel['periodedu'],'accepterdu':dataSel['periodeau']}  
            }
            if(dataSel.grPeriode.indexOf('daterejet') > -1){
                dataSend={...dataSend,'rejetdu':dataSel['periodedu'],'rejetdu':dataSel['periodeau']}  
            }
            if(dataSel.grPeriode.indexOf('datesaisie') > -1){
                dataSend={...dataSend,'saisiedu':dataSel['periodedu'],'saisieau':dataSel['periodeau']}  
            }
        }
   
            if(dataSel.hasOwnProperty('codebranche')){
                dataSend={...dataSend,'branche':dataSel['codebranche']} 
            }
            if(dataSel.hasOwnProperty('codepartenaire')){
                dataSend={...dataSend,'partenaire':dataSel['codepartenaire']} 
            }
            if(dataSel.hasOwnProperty('codereseau')){
                dataSend={...dataSend,'reseau':dataSel['codereseau']} 
            }
            if(dataSel.hasOwnProperty('codezone')){
                dataSend={...dataSend,'zone':dataSel['codezone']} 
            }
            if(dataSel.hasOwnProperty('codeequipe')){
                dataSend={...dataSend,'equipe':dataSel['codeequipe']} 
            }
        */}
    }else{
      swal("Warning","Aucune ligne sélectionnée","Warning")
    }
  }
  const handleDelete =()=>{

  }
  const handleClone = ()=>{

  }
  return (
    <div className={classes.root}>
          <Paper className={classes.rheader} variant="outlined">
            <Tooltip title="Retour" placement="bottom-start">
                <IconButton onClick={()=>props.handleCurrentView('HOME')} color="primary" aria-label="Retour">
                    <ArrowBackIcon fontSize='large' />
                </IconButton>
            </Tooltip>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <Typography variant='h4'>Rapport</Typography>
            </div>
            <BouttonNew handleClick={handleNewRapport} classes={classes} />
          </Paper>
          <div className={classes.rmain}>
              <div className={classes.rtabs}>
                <div className={classes.rtabsright}>
                  <Button className={(tabIsSelect==='rp')?classes.btntabsSelect:classes.btntabs} onClick={()=>handleSelectRapport('rp')}>
                    Rapports
                  </Button>
                  <Button className={(tabIsSelect==='rpr')?classes.btntabsSelect:classes.btntabs} onClick={()=>handleSelectRapport('rpr')}>
                    Rapports récurrents
                  </Button>
                </div>
                <div className={classes.rtabsleft}>
                  <IconButton onClick={()=>props.handleCurrentView('HOME')} color="primary" aria-label="filter">
                      <FilterListIcon fontSize='large' /> 
                  </IconButton>
                </div>
              </div>
              <div className={classes.rcorps}>
                {(tabIsSelect==='rp')?(
                  !(rapport && rapport.length!==0)?(
                    <div className={classes.rempty}>
                      <SentimentVeryDissatisfiedIcon style={{ color:'#f9b233', fontSize:70 }} />
                      <Typography className={classes.tt} variant='h5'>Vous n’avez actuellement aucun rapport</Typography>
                      <Typography className={classes.tt} variant='caption'>Créer un rapport pour voir les résultats</Typography>
                        <BouttonNew handleClick={handleNewRapport} classes={classes}/>
                    </div>
                  ):(
                      <div style={{display:'flex',flexDirection:'column',width:'60%',marginLeft:'auto',marginRight:'auto',marginTop:20,marginBottom:20}}>
                          {rapport.map(rp=>(
                            <Card style={{marginBottom:10}}>
                              <CardContent style={{display:'flex',alignItems:'center'}}>
                                <div style={{display:'flex',marginRight:5}}>
                                  {rp['type']==='all'&& <AllInboxIcon style={{ color:'#f9b233', fontSize:20 }} />}
                                  {rp['type']==='ot'&& <SendIcon style={{ color:'#f9b233', fontSize:20 }} />}
                                  {rp['type']==='oc'&& <PlaylistAddCheckIcon style={{ color:'#f9b233', fontSize:20 }} />}
                                  {rp['type']==='or'&& <CancelScheduleSendIcon style={{ color:'#f9b233', fontSize:20 }} />}
                                  {rp['type']==='oe'&& <DonutLargeIcon  style={{ color:'#f9b233', fontSize:20 }} />}
                                </div>
                                <div style={{display:'flex',flex:3,flexDirection:'column'}}>
                                  <spans style={{display:'flex',fontSize:16, fontStyle:'bold'}}>{rp['titre']}</spans>
                                  <div style={{display:'flex',fontSize:11,color:"#ccc"}}>
                                    <spans style={{marginRight:5}}>Date : <small style={{marginRight:5}}>{dayjs(rp['saisiele']).format('DD/MM/YYYY')}</small></spans>
                                    <spans style={{marginRight:5}}>Periode du : <small style={{marginRight:5}}>{`${dayjs(rp['periodedu']).format('DD/MM/YYYY')} - ${dayjs(rp['periodeau']).format('DD/MM/YYYY')}`}</small></spans>
                                    {rp['format']&&<spans style={{marginRight:5}}>Format du : <small style={{marginRight:5}}>{rp['format'].toUpperCase()}</small>PDF</spans>}
                                  </div>
                                </div>
                               
                                <div style={{display:'flex',justifyContent:'flex-end',flex:1}}>
                                  <Divider orientation="vertical" />
                                  <IconButton onClick={(event)=>handleOptionsClick(event,rp)}>
                                    <MoreVertIcon />
                                  </IconButton>
                                </div>
                              </CardContent>
                            </Card>
                          ))}
                      </div>
                  )
                ):( !(rapportrecu && rapportrecu.lenght!==0)?(
                  <div className={classes.rempty}>
                      <SentimentVeryDissatisfiedIcon style={{ color:'#f9b233', fontSize:70 }} />
                      <Typography className={classes.tt} variant='h5'>Vous n’avez actuellement aucun rapport</Typography>
                      <Typography className={classes.tt} variant='caption'>Créer un rapport pour voir les résultats</Typography>
                        <BouttonNew handleClick={handleNewRapport} classes={classes}/>
                    </div>
                ):(<div></div>))}
                 
              </div>
          </div>
          <Menu
              id="options-menu"
              anchorEl={anchorEl}
              keepMounted={true}
              open={Boolean(anchorEl)}
              onClose={handleOptionsClose}
            >
                <MenuItem>
                    <ListItemIcon>
                        <GetAppIcon color='primary' fontSize='small' />
                    </ListItemIcon>
                    <Typography variant="inherit">Télécharger</Typography>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <FileCopyIcon color='primary' fontSize='small' />
                    </ListItemIcon>
                    <Typography variant="inherit">Cloner</Typography>
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <DeleteIcon color='primary' fontSize='small' />
                    </ListItemIcon>
                    <Typography variant="inherit">Supprimer</Typography>
                </MenuItem>
            </Menu>
      </div>
  )
}

export default OperationRapport