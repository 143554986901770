import React,{useEffect} from 'react'
import { Field, reduxForm } from 'redux-form'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel';
import asyncValidate from '../../util/asyncValidate'
import {radioButton} from './RenderField';
import {Box,Grid } from '@material-ui/core'
import {getVille,getCivilite,getProfession,getPays,getSociete} from '../../redux/actions/apiActions'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import Divider from '@material-ui/core/Divider';
import CustomInput from 'components/CustomInput/CustomInput'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import CustomDate from 'components/CustomDate/CustomDate'
import CustomContrySelect from 'components/CustomContrySelect/CustomContrySelect'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import dayjs from 'dayjs'
const validate = values => {
    const errors = {}
    const requiredFields = [
      'nom',
      'prenom',
      'dateNaissance',
      'lieuNaissance',
      'numeroPiece',
      'email',
      'mobile'
    ]
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = 'Required'
      }
    })
    if (
      values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address'
    }
    return errors
  }

  const useStyles = makeStyles(theme => ({
    contacts: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
      },
    },
    btnNext:{
      backgroundColor:theme.palette.primary.main,
      height:40,
      width:150,
      borderRadius:20,
      color:theme.palette.primary.contrastText
    },
    btnGroupe:{
      justify:'flex-end'
    }
  }));

  const ProfilSouscipteur = props => {


    const { handleSubmit, pristine, reset,onChange, submitting,api:{villes,professions,societes,civilites} } = props
    const classes =useStyles();
    useEffect(() => {
      if(villes.length==0){
        props.getVille();
      } 
      if(civilites.length==0){
        props.getCivilite();
      }
      if(professions.length==0){
          props.getProfession();
      }
      if(societes.length==0){
        props.getSociete();
      }
    },[]);

   
    return (
      <form onSubmit={handleSubmit}>
        <GridContainer>
            <GridItem xs={12} sm={12} md={7}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl component="fieldset" direction='row'>
                    <FormLabel component="legend">Civilité</FormLabel>
                      <Field
                        required
                        name="civiliteSous"
                        component={radioButton}
                        label="Civilité"
                        options={[{"label":"Monsieur","value":"Monsieur"},{"label":"Madame","value":"Madame"},{"label":"Mademoiselle","value":"Mademoiselle"}]}
                      />
                  </FormControl>
                </GridItem>
              </GridContainer>
             <GridContainer>
                <GridItem xs={12} sm={12} md={5} ld={5}>
                  <Field 
                    required
                    label="Nom"
                    id="nomSous"
                    name="nomSous"
                    variant="outlined"
                    formControlProps={{
                        fullWidth: true
                    }}
                    autoFocus
                    component={CustomInput} 
                    
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={7} lg={7}>
                    <Field 
                      required
                      label="Prénom"
                      id="prenomSous"
                      name="prenomSous"
                      variant="outlined"
                      formControlProps={{
                          fullWidth: true
                      }}
                      autoFocus
                      component={CustomInput} 
                      
                    />
                </GridItem>
             </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <FormControl component="div" direction='row'>
                    <FormLabel component="legend">Sexe</FormLabel>
                    <Field 
                      required
                      name="sexeSouscripteur" 
                      component={radioButton}
                      label="Sexe" 
                      options={[{"label":"Masculin","value":"M"},{"label":"Feminin","value":"F"}]}  
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={5} lg={5}>
                  <Field
                    required
                    label="Date de naissance"
                    id="dateNaissanceSous"
                    name="dateNaissanceSous"
                    formControlProps={{
                        fullWidth: true,
                    }}
                    InputProps={{
                        type:'date',
                        inputProps: { max:dayjs().add(-12,'year').format('YYYY-MM-DD'),min:dayjs().add(-100,'year').format('YYYY-MM-DD')},
                    }}
                    onChange={onChange}
                    component={CustomDate}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={7} lg={7}>
                  <Field 
                    component ={CustomSelect}
                    options ={villes}
                    label="Lieu de naissance"
                    id="lieuNaissanceSous"
                    name="lieuNaissanceSous"
                    formControlProps={{
                        fullWidth: true
                    }}
                  />
                </GridItem>
              </GridContainer>
              
              <GridContainer alignContent='flex-end' justify='flex-end'>
                <GridItem xs={12} sm={12} md={4} lg={4}>
                  <Field 
                    required
                    label="N° Pièce"
                    id="pieceIdentiteSous"
                    name="pieceIdentiteSous"
                    variant="outlined"
                    formControlProps={{
                        fullWidth: true
                    }}
                    autoFocus
                    component={CustomInput} 
                      
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8} lg={8}>
                  <FormControl component="div" direction='row'>
                  <FormLabel component="legend">Nature de la pièce</FormLabel>
                  <Field 
                    name="naturePiece" 
                    component={radioButton}
                    label="Nature de la pièce" 
                    options={[{"label":"CNI","value":"CNI"},{"label":"Attestation","value":"AT"},{"label":"Passport","value":"Passport"}]}  
                  />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Field  
                      component ={CustomSelect}
                      options ={villes}
                      label="Lieu de résidence"
                      id="lieuResidenceSous"
                      name="lieuResidenceSous"
                      formControlProps={{
                          fullWidth: true
                      }}
                    />  
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <Field
                    label="Profession"
                    id="professionSous"
                    name="professionSous"
                    formControlProps={{
                        fullWidth: true
                    }}
                    options={professions}
                    component={CustomSelect}
                  />

                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <Field
                    label="Employeur"
                    id="employeurSous"
                    name="employeurSous"
                    formControlProps={{
                        fullWidth: true
                    }}
                    options={societes}
                    component={CustomSelect}
                  />
                </GridItem>
              </GridContainer>
              
            </GridItem>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}><Divider orientation="vertical" /></GridItem>
            </GridContainer>

            <GridItem xs={12} sm={12} md={4} lg={4}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <Field 
                          component ={CustomContrySelect}
                          label="Pays"
                          id="paysSous"
                          name="paysSous"
                          formControlProps={{
                              fullWidth: true
                          }}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Field
                         required 
                        component={CustomInput} 
                        label="email"
                        id="emailSous"
                        name="emailSous"
                        variant="outlined"
                       
                        formControlProps={{
                        fullWidth: true
                        }}
                        type='email'
                        autoFocus
                      />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <Field 
                        component={CustomInput} 
                        label="Bôte postable"
                        id="adressePostaleSous"
                        name="adressePostaleSous"
                        variant="outlined"
                       
                        formControlProps={{
                        fullWidth: true
                        }}
                        autoFocus
                      />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <Field 
                          component={CustomInput} 
                          label="Téléphone 1"
                          id="telephoneSous"
                          name="telephoneSous"
                          variant="outlined"
                          type='phone'
                          formControlProps={{
                          fullWidth: true
                          }}
                          autoFocus
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <Field 
                          component={CustomInput} 
                          label="Téléphone 2"
                          id="telephone2Sous"
                          name="telephone2Sous"
                          variant="outlined"
                          type='phone'
                          formControlProps={{
                          fullWidth: true
                          }}
                          autoFocus
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <Field 
                          required
                          component={CustomInput} 
                          label="Mobile 1"
                          id="mobileSous"
                          name="mobileSous"
                          variant="outlined"
                          type='phone'
                          formControlProps={{
                            fullWidth: true
                          }}
                          autoFocus
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        <Field 
                          component={CustomInput} 
                          label="Mobile 2"
                          id="mobile2Sous"
                          name="mobile2Sous"
                          variant="outlined"
                          type='phone'
                          formControlProps={{
                            fullWidth: true
                          }}
                          autoFocus
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer>
       
        <Divider component="hr" className={classes.hTrait}/>
        
       
        <Divider component="hr" className={classes.hTrait}/>
            <Grid container direction="row" justify="space-between" alignItems="center">        
             
                <Button type="button"  onClick={props.handleBack}>
                  Retour
                </Button>
             
              
                <Button type= 'submit' color='primary'   disabled={submitting}>
                  Continuer
                  <NavigateNextOutlinedIcon /> 
                </Button>
            
            </Grid>
      </form>
    )
  }
  

  const mapStateToProps = (state) => ({
    api: state.api,
  });
  
  const mapActionsToProps = {
    getVille,
    getPays,
    getProfession,
    getCivilite,
    getSociete
  };


  export default connect(
    mapStateToProps,
    mapActionsToProps
  )(reduxForm({
    form: 'adForm', // a unique identifier for this form
    validate,
    asyncValidate,
    destroyOnUnmount: false,
  })(ProfilSouscipteur))

  const mespays =[
    {
      "IM": "Île de Man",
      "HR": "Croatie",
      "GW": "Guinée-Bissau",
      "IN": "Inde",
      "KE": "Kenya",
      "LA": "Laos",
      "IO": "Territoire britannique de l'océan Indien",
      "HT": "Haïti",
      "LB": "Liban",
      "GY": "Guyana",
      "KG": "Kirghizistan",
      "HU": "Hongrie",
      "LC": "Sainte-Lucie",
      "IQ": "Irak",
      "KH": "Cambodge",
      "JM": "Jamaïque",
      "IR": "Iran",
      "KI": "Kiribati",
      "IS": "Islande",
      "MA": "Maroc",
      "JO": "Jordanie",
      "IT": "Italie",
      "JP": "Japon",
      "MC": "Monaco",
      "KM": "Comores",
      "MD": "Moldavie",
      "LI": "Liechtenstein",
      "KN": "Saint-Christophe-et-Niévès",
      "ME": "Monténégro",
      "NA": "Namibie",
      "MF": "Saint-Martin",
      "LK": "Sri Lanka",
      "KP": "Corée du Nord",
      "MG": "Madagascar",
      "NC": "Nouvelle-Calédonie",
      "MH": "Îles Marshall",
      "KR": "Corée du Sud",
      "NE": "Niger",
      "NF": "Île Norfolk",
      "MK": "Macédoine",
      "NG": "Nigéria",
      "ML": "Mali",
      "MM": "Myanmar (Birmanie)",
      "LR": "Libéria",
      "NI": "Nicaragua",
      "KW": "Koweït",
      "MN": "Mongolie",
      "LS": "Lesotho",
      "PA": "Panamá",
      "MO": "Macau",
      "LT": "Lituanie",
      "KY": "Îles Caïmans",
      "MP": "Îles Mariannes du Nord",
      "LU": "Luxembourg",
      "NL": "Pays-Bas",
      "KZ": "Kazakhstan",
      "MQ": "Martinique",
      "LV": "Lettonie",
      "MR": "Mauritanie",
      "PE": "Pérou",
      "MS": "Montserrat",
      "QA": "Qatar",
      "NO": "Norvège",
      "PF": "Tahïti (Polynésie française)",
      "MT": "Malte",
      "LY": "Libye",
      "NP": "Népal",
      "PG": "Papouasie-Nouvelle-Guinée",
      "MU": "Maurice",
      "PH": "Philippines",
      "MV": "Maldives",
      "OM": "Oman",
      "NR": "Nauru",
      "MW": "Malawi",
      "MX": "Mexico",
      "PK": "Pakistan",
      "MY": "Malaisie",
      "NU": "Niue",
      "PL": "Pologne",
      "MZ": "Mozambique",
      "PM": "Saint-Pierre-et-Miquelon",
      "PN": "Îles Pitcairn",
      "RE": "La Réunion",
      "SA": "Arabie saoudite",
      "SB": "Îles Salomon",
      "NZ": "Nouvelle-Zélande",
      "SC": "Seychelles",
      "SD": "Soudan",
      "PR": "Porto Rico",
      "SE": "Suède",
      "PS": "Territoires palestiniens occupés",
      "PT": "Portugal",
      "SG": "Singapour",
      "TC": "Îles Turques-et-Caïques",
      "SH": "Sainte-Hélène",
      "TD": "Tchad",
      "SI": "Slovénie",
      "PW": "Palaos",
      "SJ": "Svalbard et Jan Mayen",
      "UA": "Ukraine",
      "RO": "Roumanie",
      "TF": "Terres australes et antarctiques françaises",
      "SK": "Slovaquie",
      "PY": "Paraguay",
      "TG": "Togo",
      "SL": "Sierra Leone",
      "TH": "Thaïlande",
      "SM": "Saint-Marin",
      "SN": "Sénégal",
      "RS": "Serbie (Yougoslavie)",
      "TJ": "Tadjikistan",
      "VA": "Cité du Vatican",
      "SO": "Somalie",
      "TK": "Tokelau",
      "UG": "Ouganda",
      "RU": "Russie",
      "TL": "Timor oriental",
      "VC": "Saint-Vincent-et-les-Grenadines",
      "TM": "Turkménistan",
      "SR": "Suriname",
      "RW": "Rwanda",
      "TN": "Tunisie",
      "VE": "Venezuela",
      "TO": "Tonga",
      "ST": "Sao Tomé-et-Principe",
      "VG": "Îles Vierges britanniques",
      "SV": "Salvador",
      "UM": "Îles mineures éloignées des États-Unis",
      "TR": "Turquie",
      "VI": "Îles Vierges(US)",
      "WF": "Wallis-et-Futuna",
      "TT": "Trinité-et-Tobago",
      "SY": "Syrie",
      "SZ": "Swaziland",
      "TV": "Tuvalu",
      "TW": "Taïwan",
      "VN": "Viêt Nam",
      "US": "États-Unis(USA)",
      "TZ": "Tanzanie",
      "YE": "Yémen",
      "ZA": "Afrique du Sud",
      "XK": "République du Kosovo",
      "UY": "Uruguay",
      "VU": "Vanuatu",
      "UZ": "Ouzbékistan",
      "WS": "Samoa",
      "ZM": "Zambie",
      "AC": "Île de l'Ascension",
      "AD": "Andorre",
      "YT": "Mayotte",
      "AE": "Émirats arabes unis",
      "YU": "Serbia and Montenegro",
      "BA": "Serbie-et-Monténégro",
      "AF": "Afghanistan",
      "BB": "Barbade",
      "AG": "Antigua-et-Barbuda",
      "BD": "Bangladesh",
      "AI": "Anguilla",
      "BE": "Belgique",
      "CA": "Canada",
      "BF": "Burkina Faso",
      "BG": "Bulgarie",
      "ZW": "Zimbabwe",
      "AL": "Albanie",
      "CC": "Îles Cocos",
      "BH": "Bahreïn",
      "AM": "Arménie",
      "CD": "République démocratique du Congo",
      "BI": "Burundi",
      "AN": "Antilles néerlandaises",
      "BJ": "Bénin",
      "AO": "Angola",
      "CF": "République centrafricaine",
      "CG": "République du Congo",
      "AQ": "Antarctique",
      "CH": "Suisse",
      "BM": "Bermudes",
      "AR": "Argentine",
      "CI": "Côte d'Ivoire",
      "BN": "Brunei Darussalam",
      "DE": "Allemagne",
      "AS": "Samoa américaines",
      "BO": "Bolivie",
      "AT": "Autriche",
      "CK": "Îles Cook",
      "AU": "Australie",
      "CL": "Chili",
      "EC": "Équateur",
      "CM": "Cameroun",
      "BR": "Brésil",
      "AW": "Aruba",
      "CN": "Chine",
      "EE": "Estonie",
      "BS": "Bahamas",
      "DJ": "Djibouti",
      "AX": "Åland",
      "CO": "Colombie",
      "BT": "Bhoutan",
      "DK": "Danemark",
      "EG": "Égypte",
      "AZ": "Azerbaïdjan",
      "EH": "Sahara occidental",
      "BV": "Île Bouvet",
      "DM": "Dominique",
      "CR": "Costa Rica",
      "BW": "Botswana",
      "GA": "Gabon",
      "DO": "République dominicaine",
      "BY": "Biélorussie",
      "GB": "Royaume-Uni",
      "CU": "Cuba",
      "BZ": "Bélize",
      "CV": "Cap-Vert",
      "GD": "Grenade",
      "FI": "Finlande",
      "GE": "Géorgie",
      "FJ": "Fidji",
      "CX": "Île Christmas",
      "GF": "Guyane",
      "FK": "Îles Malouines",
      "CY": "Chypre",
      "GG": "Guernesey",
      "CZ": "République tchèque",
      "GH": "Ghana",
      "FM": "Micronésie",
      "ER": "Érythrée",
      "GI": "Gibraltar",
      "ES": "Espagne",
      "FO": "Îles Féroé",
      "ET": "Éthiopie",
      "GL": "Groenland",
      "DZ": "Algérie",
      "GM": "Gambie",
      "ID": "Indonésie",
      "FR": "France",
      "GN": "Guinée",
      "IE": "Irlande",
      "HK": "Hong Kong",
      "GP": "Guadeloupe",
      "GQ": "Guinée équatoriale",
      "HM": "Îles Heard-et-MacDonald",
      "GR": "Grèce",
      "HN": "Honduras",
      "JE": "Jersey",
      "GS": "Géorgie du Sud-et-les Îles Sandwich du Sud",
      "FX": "France métropolitaine",
      "GT": "Guatemala",
      "GU": "Guam",
      "IL": "Israël"
  }
  ]