import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  validationPropositionAssure,
  validationPropositionBeneficaire,
  validationPropositionDocument} from 'redux/actions/apiActions'
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function OptionMenu(props) {
  const {data,setMyErrors,setOpenAlert,mode,annulerrejet} =props
  
  const classes = useStyles();
  const [openOp, setOpenOp] = React.useState(false);
    const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpenOp((prevOpen) => !prevOpen);
  };

  const handleCloseOp = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenOp(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenOp(false);
    }
  }
  const integrerAssureGaranties =()=>{
    const dataSend ={refs:[data.id]}
    validationPropositionAssure(dataSend).then(res=>{
      if(res){
        setMyErrors({type:'success',message: "Intégration effectué avec succès"})
        setOpenAlert()
        handleCloseOp()
      }else{
        console.log(res);
        setMyErrors({type:'error',message: "Une erreur s'est produit lors de l'intégration"})
        setOpenAlert()
        handleCloseOp()
      }
    }).catch(err=>{
      console.log(err);
      setMyErrors({type:'error',message: "Une erreur s'est produit lors de l'intégration"})
      setOpenAlert()
      handleCloseOp()
    })
  }
  const integrerBeneficaires =()=>{
    const dataSend ={refs:[data.id]}
    validationPropositionBeneficaire(dataSend).then(res=>{
      if(res){
        setMyErrors({type:'success',message: "Intégration effectué avec succès"})
        setOpenAlert()
        handleCloseOp()
      }else{
        console.log(res);
        setMyErrors({type:'error',message: "Une erreur s'est produit lors de l'intégration"})
        setOpenAlert()
        handleCloseOp()
      }
    }).catch(err=>{
      console.log(err);
      setMyErrors({type:'error',message: "Une erreur s'est produit lors de l'intégration"})
      setOpenAlert()
      handleCloseOp()
    })
  }
  const integrerDocuments =()=>{
    const dataSend ={refs:[data.id]}
    validationPropositionDocument(dataSend).then(res=>{
      if(res){
        setMyErrors({type:'success',message: "Intégration effectué avec succès"})
        setOpenAlert()
        handleCloseOp()
      }else{
        console.log(res);
        setMyErrors({type:'error',message: "Une erreur s'est produit lors de l'intégration"})
        setOpenAlert()
        handleCloseOp()
      }
    }).catch(err=>{
      console.log(err);
      setMyErrors({type:'error',message: "Une erreur s'est produit lors de l'intégration"})
      setOpenAlert()
      handleCloseOp()
    })
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openOp);
  React.useEffect(() => {
    if (prevOpen.current === true && openOp === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openOp;
  }, [openOp]);


  return (
    <div className={classes.root}>
      <div>
      <Button
          ref={anchorRef}
          aria-controls={openOp ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MoreVertIcon />
        </Button>
        <Popper open={openOp} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseOp}>
                  <MenuList autoFocusItem={openOp} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                   {mode==='ACCEPTERM'&&<MenuItem onClick={integrerAssureGaranties}>intégration des assuré(e)s et garanties</MenuItem>} 
                   {mode==='ACCEPTERM'&&<MenuItem onClick={integrerBeneficaires}>Intégration des bénéficaires</MenuItem>}
                   {mode==='ACCEPTERM'&&<MenuItem onClick={integrerDocuments}>Intégration des documents joints</MenuItem>}
                   {mode==='REJETER'&&<MenuItem onClick={(e)=>annulerrejet(data)}>Annuler le réjet</MenuItem>}
                 
                  </MenuList>
                
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}