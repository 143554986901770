import React from 'react'
import { Container, Base, Inner, Group, Title, Text, Label, CButton, DisButton } from './style/pretDash'

export default function DashBord({ children, ...resProps }) {
    return <Container {...resProps}>{children}</Container>
}

DashBord.Base = function DashBase({ children, ...restProps }) {
    return <Base {...restProps}>{children}</Base>
}

DashBord.Inner = function DashInner({ children, ...restProps }) {
    return <Inner {...restProps}>{children}</Inner>
}

DashBord.Group = function DashGroup({ children, ...restProps }) {
    return <Group {...restProps}>{children}</Group>
}
DashBord.Title = function DashTitle({ children, ...restProps }) {
    return <Title {...restProps}>{children}</Title>
}

DashBord.Text = function DashText({ children, ...restProps }) {
    return <Text {...restProps}>{children}</Text>
}

DashBord.Label = function DashLabel({ children, ...restProps }) {
    return <Label {...restProps}>{children}</Label>
}

DashBord.CButton = function DashCButton({ children, ...restProps }) {
    return <CButton {...restProps}>{children}</CButton>
}


DashBord.DisButton = function DashDisButton({ children, ...restProps }) {
    return <DisButton {...restProps}>{children}</DisButton>
}

