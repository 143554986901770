import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import {bankass} from "routes";
import styles from "assets/jss/llv/layouts/adminStyle.js";
import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/images/logoYnovGreen.png";
import AddPret from 'pages/Banking/Prets/addprets'
import PretFicheresume from 'pages/Banking/Prets/components/PretFicheresume'
import AddProposition from 'pages/Banking/Propositions/AddProposition'
import ChangePassword from 'pages/Dashboard/ChangePassword'
import PretList from 'pages/Banking/Prets/PretList'
import {connect,useSelector} from 'react-redux'


let ps;

const switchRoutes = (
  <Switch>
    {bankass.map((prop, key) => {
      if (prop.layout === "/bankass" && prop.path) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Route path='/bankass/add-pret' component={AddPret} name='Nouvelle cotation' />
    <Route path='/bankass/pret-liste' component={PretList} name='Gestion des demandes de cotaion' />
    <Route path='/bankass/pret-fiche-resume' component={PretFicheresume} name='Fiche résumé' />
    <Route path='/bankass/nouvelle-proposition' component={AddProposition} name='Nouvelle proposition' />
    <Route path='/bankass/modification-proposition' component={AddProposition} name='Modification proposition' />
    <Route path='/bankass/modification-mot-passe' component={ChangePassword} name='Modification du mot de passe' />
    <Redirect from="/bankass" to="/bankass/tableau-de-bord"/>
  </Switch>
);

const useStyles = makeStyles(styles);

function Bankass({history, ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("green");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const getRoute = () => {
    return window.location.pathname !== "/bankass/maps";
  };
  
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  const user = useSelector(state=>state.user.credentials)
  const newBankass = bankass.filter(item=>item.partenaire.includes(user.codepartenaire))
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={newBankass}
        logoText={""}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={newBankass}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
          history={history}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              {switchRoutes}
          </div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}
      </div>
    </div>
  );
}

export default connect()(Bankass)
