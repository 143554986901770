import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from 'infinite-react-carousel';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { red } from '@material-ui/core/colors';
import bni1 from 'assets/images/slides/bni1.jpg';
import bni2 from 'assets/images/slides/bni2.png';
import { Divider } from '@material-ui/core';
import {connect} from 'react-redux'
const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: 130,
      marginBottom:'20px'
    },
    media: {
      height: 0,
      paddingTop: '100%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    base:{
      marginBottom:'20px'
    },
    ligne:{
      margin:'20px'
    }
  }));
function DashBordSlider (props){
    const classes = useStyles();
    const {user:{credentials}}= props
    const {branche,partenaire,codepartenaire}=credentials
    return (
        <GridContainer>
            <GridItem xs={12} sm={3} md={3} lg={3}  className={classes.base}>
                <Card className={classes.root}>
                    <CardMedia
                        className={classes.media}
                        image={require(`assets/images/${(codepartenaire &&codepartenaire!=='')?codepartenaire+'.jpg':"LLV.jpg"}`).default}
                        title="Logo partenaire"
                        src='img'
                        component='div'
                    />
                </Card>
            </GridItem>
            {credentials.codepartenaire==='092'&& <GridItem xs={12} sm={8} md={8} lg={8}>
                <Slider dots autoplay prevArrow={null} nextArrow={null}>
                    <div style={{width:'100%',height:'100%'}}> 
                        <img src={bni1} alt="produit bni" />
                    </div>
                    <div style={{width:'100%',height:'100%'}}>
                        <img src={bni2} alt="produit bni 2" />
                    </div>
                </Slider>
            </GridItem>}
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Divider className={classes.ligne} />
            </GridItem>
          
        </GridContainer>
       
    )
}
const mapActionsToProps = {
 
}

const mapStateProps =(state)=>({
  api:state.api,
  user:state.user
})

export default connect(mapStateProps,mapActionsToProps)(DashBordSlider)