import React from 'react'
import {Container,Header,Base,Title,Form,SideBar,Text,TextSmall,Group} from './style'

export  function Pret({children,...restProps}){
    return (<Container {...restProps}>{children}</Container>)
}


Pret.Header = function HeaderPret({children,...restProps}){
    return <Header {...restProps}>{children}</Header>
}


Pret.Base = function BasePret({children,...restProps}){
    return <Base {...restProps}>{children}</Base>
}

Pret.Group = function GroupPret({children,...restProps}){
    return <Group {...restProps}>{children} </Group>
}

Pret.Title = function TitlePret({children,...restProps}){
    return <Title {...restProps}>{children}</Title>
}

Pret.Form = function FormPret({children,...restProps}){
    return <Form {...restProps}>{children}</Form>
}

Pret.SideBar = function SideBarPret({children,...restProps}){
    return <SideBar {...restProps}>{children}</SideBar>
}

Pret.Text = function TextPret({children,...restProps}){
    return <Text {...restProps}>{children}</Text>
}

Pret.TextSmall = function TextSmallPret({children,...restProps}){
    return <TextSmall {...restProps}>{children}</TextSmall>
}



