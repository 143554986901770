import React, { useState } from 'react'
import Header from 'pages/Commons/components/Header'
import { Button, Divider, Paper } from "@material-ui/core";
import ConventionItem from './ConventionItem';
import { useSelector } from 'react-redux';
import ProduitCard from './ProduitCard';
const ConventionsAccueil = () => {
    const [tabActive,setTabActive] = useState('conventions')
    const [conventions,setConvention] = useState([])
    const handleConventionPartenaire =()=>{

    }
    const [produits,setProduits] = useState(useSelector(state=>state.api.produits))
   
   
  return (
    <div className='flex flex-col'>
           <Header title="Gestion des Conventions /Produits" />
           <div className='m-4 max-w-screen-sm md:max-w-screen-lg w-full mx-auto'>
                <div className='flex flex-col'>
                    <div className='flex flex-row justify-start'>
                        <Button onClick={()=>setTabActive('conventions')} className={`border-b border-b-4 py-4 px-4 cursor-pointer shadow-none rounded-0 ${tabActive==='conventions' ? "border-b-primary-main bg-slate-100" : "border-b-gray-300"}`} variant='outline'>Conventions</Button>
                        <Button onClick={()=>setTabActive('produits')} className={`border-b border-b-4 py-4 px-4 cursor-pointer shadow-none rounded-0 ${tabActive==='produits' ? "border-b-primary-main bg-slate-100" : "border-b-gray-300"}`} variant='outline'>Produits</Button>
                    </div>
                    <Divider />
                </div>

                {tabActive==='conventions'&&(
                <div className='p-2 mt-8 flex flex-row flex-wrap w-full'>
                        {(conventions && conventions.length!==0) ?(conventions.map(convention=>(
                            <ConventionItem convention={convention} />
                        ))
                            
                        ):<div className='flex flex-col py-4 items-center justify-center bg-slate-100 px-3 w-full border-[1px] border-dashed border-slate-400'>
                                <p className='font-bold text-[14px]'>Aucune convention trouvée</p>
                                <p>Veuillez contacter le service commercial de YAKO AFRICA </p>
                            </div>}
                </div>)}
                {tabActive==='produits'&&(
                <div className='p-2 mt-8 flex flex-row flex-wrap w-full gap-4'>
                        {(produits && produits.length!==0) ?produits.map(produit=>(
                            <ProduitCard produit={produit} />
                        )):<div className='flex flex-col py-4 items-center justify-center bg-slate-100 px-3 w-full border-[1px] border-dashed border-slate-400'>
                        <p className='font-bold text-[14px]'>Aucun produit trouvé</p>
                        <p>Veuillez contacter le service commercial de YAKO AFRICA </p>
                    </div>}
                </div>)}
        </div>
    </div>
  )
}

export default ConventionsAccueil
