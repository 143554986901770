const initialState = {
    rapports:[]
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
        case "value":
             return {...state}
            break;
        default:
              return state;
            break;
    }
  }