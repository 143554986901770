import DashboardPage from "pages/Dashboard/Dashboard.js";

//Production component
import { AccountCircle, Assignment, Add, } from "@material-ui/icons/";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import FolderIcon from '@material-ui/icons/Folder';
import HealingIcon from '@material-ui/icons/Healing';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SchoolIcon from '@material-ui/icons/School';
import BusinessIcon from '@material-ui/icons/Business';
import FunctionsIcon from '@material-ui/icons/Functions';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ViewModuleSharpIcon from '@material-ui/icons/ViewModuleSharp';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Dashboard from "@material-ui/icons/Dashboard";
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import MoneyIcon from '@material-ui/icons/Money';

//commans
import CommissionList from 'pages/Commons/Commissions/CommissionList'

//Settings components
import ReseauList from 'pages/Settings/Reseau/ReseauList'
import ModuleList from 'pages/Settings/Module/ModuleList'
import ZoneList from 'pages/Settings/Zone/ZoneList'
import EquipeList from 'pages/Settings/Equipe/EquipeList'
import RoleList from 'pages/Settings/Role/RoleList'
import UtilisateurList from 'pages/Settings/Utilisateur/UtilisateurList'
import PartenaireHome from "pages/Settings/Partenaires/PartenaireHome";
import BrancheList from "pages/Settings/Branche/BrancheList";
import AjouterBranche  from "pages/Settings/Branche/AjouterBranche";
import AddUtilisateur from "pages/Settings/Utilisateur/AddUtilisateur";
import UpdateUtilisateur from "pages/Settings/Utilisateur/UpdateUtilisateur";


//Banking components
import Simulateur from 'pages/Banking/Propositions/Simulateur'
import AdminHome from 'pages/Banking/Administration/AdminHome'
import ProductionHome from 'pages/Banking/ProdHome'
import EtatHomeBank from 'pages/Banking/Etats/EtatHome'
import FormationHome from 'pages/Banking/Formation/FormationHome'


//producteur components
import ProducteurHome from 'pages/Producteur/ProducteurHome'
import ProducteurPropoHome from 'pages/Producteur/Propositions/PropositionHome'
import ProducteurOpeHome from 'pages/Producteur/Operations/OperationHome'
import ProducteurPretHome from 'pages/Producteur/Prets/PretsHome'
import OperationsHome from 'pages/Operations/OperationsHome'

import ProductionCom from 'pages/Commercial/Propositions/ProductionHome'
import ProspectHome from 'pages/Commercial/Prospects/ProspectHome'
import SimulateurCom from 'pages/Commercial/Propositions/Simulateur'
import AddProposition from 'pages/Commercial/Propositions/AddProposition';


//courtage
import AccueilCourtage from "pages/Courtier/AccueilCourtage";
import ProductionContainer from "pages/Courtier/Production/ProductionContainer"
import OperationContainer from "pages/Courtier/Operation/OperationContainer"
import FacturationHome from "pages/Facturation/FacturationHome"

//autres routes
import Epret from "Layouts/Epret";
import ChangePassword from 'pages/Dashboard/ChangePassword'
import ConventionsContainer from "pages/Courtier/Conventions/ConventionsContainer";
import PropositionDetails from "pages/Producteur/Propositions/PropositionDetails";

                  

{/**                    
<Redirect from={`/${layoutRoot}/prets`} to={`/${layoutRoot}/epret`} />   
<Redirect from={`/${layoutRoot}/com`} to={`/${layoutRoot}/esouscription`} />

<Redirect from={`/${layoutRoot}`} to={`/${layoutRoot}/tableau-de-bord`} />
*/}
{/**
                    {layoutRoot==='settings'&&<Redirect from="/settings" to="/settings/utilisateur" />}
                    {layoutRoot==='bankass'&&<Redirect from={`/${layoutRoot}/prets`} to={`/${layoutRoot}/epret`} />}
                    {layoutRoot==='com'&&<Redirect from={`/${layoutRoot}/com`} to={`/${layoutRoot}/esouscription`} />}
                    {layoutRoot!=='settings'&&<Redirect from={`/${layoutRoot}`} to={`/${layoutRoot}/tableau-de-bord`} />}
                     */}

const shared =[
  {
    path: "/modification-mot-passe",
    name: "Modification du mot de passe",
    icon: AcUnitIcon,
    component:ChangePassword,
    layout: "()",
    partenaire:['ALL'],
    isRub:false,
    default:false
  },
  {
    path: "/modification-proposition",
    name: "Modification proposition",
    icon: AcUnitIcon,
    component:ProductionContainer,
    layout: "()",
    partenaire:['ALL'],
    isRub:false,
    default:false
  },
  {
    path: "/nouvelle-proposition",
    name: "Nouvelle proposition",
    icon: AcUnitIcon,
    component:AddProposition,
    layout: "()",
    partenaire:['ALL'],
    isRub:false,
    default:false
  },
]

//admin routes
const routes = [
  {
    path: "/tableau-de-bord",
    name: "Tableau de bord",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    partenaire:['ALL'],
    isRub:true,
    default:true
  },
  {
    path: "/propositions",
    name: "Production",
    icon: FolderOpenIcon,
    component: DashboardPage,
    layout: "/admin",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },

  {
    path: "/nouvelle-souscription",
    name: "Souscription",
    icon: Add,
    component: DashboardPage,
    layout: "/admin",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },
  {
    path: "/contrats",
    name: "Contrats",
    icon: Assignment,
    component: DashboardPage,
    layout: "/admin",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },
  {
    path: "/prestations",
    name: "Prestations",
    icon: FolderIcon,
    component: DashboardPage,
    layout: "/admin",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },
  {
    path: "/Sinistres",
    name: "Sinistres",
    icon: HealingIcon,
    component: EquipeList,
    layout: "/admin",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },

  {
    path: "/cotisation",
    name: "Etat d'encaissement",
    icon: AccountBalanceWalletIcon,
    component: DashboardPage,
    layout: "/admin",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },
  {
    path: "/formations",
    name: "Formation",
    icon: SchoolIcon,
    component: DashboardPage,
    layout: "/admin",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },

]

//commercials
const com = [
  {
    path: "/tableau-de-bord",
    name: "Tableau de bord",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/com",
    partenaire: ['LLV', 'COFINA'],
    isRub:true,
    default:true
  },
  {
    path: "/propositions",
    name: "Production",
    icon: FolderOpenIcon,
    component: ProductionCom,
    layout: "/com",
    partenaire: ['LLV', 'COFINA'],
    isRub:true,
    default:false
  },
  {
    path: "/simulateurs",
    name: "Simulation",
    icon: FunctionsIcon,
    component: SimulateurCom,
    layout: "/com",
    partenaire: ['LLV'],
    isRub:true,
    default:false
  },
  {
    path: "/commissions",
    name: "Commissions",
    icon: MoneyIcon,
    component: CommissionList,
    layout: "/com",
    partenaire: ['LLV'],
    isRub:true,
    default:false
  },
  {
    path: "/prospects",
    name: "Prospects",
    icon: FolderIcon,
    component: ProspectHome,
    layout: "/com",
    partenaire: ['LLV'],
    isRub:true,
    default:false
  },
  {
    path: "/equipes",
    name: "Equipes",
    icon: GroupWorkIcon,
    component: EquipeList,
    layout: "/com",
    partenaire: ['LLV'],
    isRub:true,
    default:false
  },
]
const bni =[
  {
    path: "/tableau-de-bord",
    name: "Tableau de bord",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/bankass",
    partenaire: ['092', 'AFC'],
    isRub:true,
    default:true
  },
  {
    path: "/production",
    name: "Production",
    icon: FolderOpenIcon,
    component: ProductionHome,
    layout: "/bankass",
    partenaire: ['092', 'AFC'],
    isRub:true,
    default:false
  },
  {
    path: "/suivi-operations",
    name: "Prestations",
    icon: FolderIcon,
    component: OperationsHome,
    layout: "/bankass",
    partenaire: ['092'],
    isRub:true,
    default:false
  },
  {
    path: "/etats",
    name: "Etats",
    icon: AccountBalanceWalletIcon,
    component: EtatHomeBank,
    layout: "/bankass",
    partenaire: ['092', 'AFC'],
    isRub:true,
    default:false
  },
  {
    path: "/formations",
    name: "Formation",
    icon: SchoolIcon,
    component: FormationHome,
    layout: "/bankass",
    partenaire: ['WF', '092', 'AFC'],
    isRub:true,
    default:false
  },
  {
    path: "/simulateurs",
    name: "Souscription",
    icon: Add,
    component: Simulateur,
    layout: "/bankass",
    partenaire: ['092', 'AFC'],
    isRub:true,
    default:false
  },
  {
    path: "/administration",
    name: "Administration",
    icon: AccountCircle,
    component: AdminHome,
    layout: "/bankass",
    partenaire: ['092'],
    isRub:true,
    default:false
  },
]

//banque assurance routes
const bankass = [
  {
    path: "/tableau-de-bord",
    name: "Tableau de bord",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/bankass",
    partenaire: ['WF','PAMFCI','CI032'],
    isRub:true,
    default:true
  },
  {
    path: "/propositions",
    name: "Production",
    icon: FolderOpenIcon,
    component: ProductionCom,
    layout: "/bankass",
    partenaire: ['CI032'],
    isRub:true,
    default:false
  },
  {
    path: "/suivi-operations",
    name: "Prestations",
    icon: FolderIcon,
    component: OperationsHome,
    layout: "/bankass",
    partenaire: ['CI032'],
    isRub:true,
    default:false
  },
  /*
  {
    path: "/etats",
    name: "Etats",
    icon: AccountBalanceWalletIcon,
    component: EtatHomeBank,
    layout: "/bankass",
    partenaire: ['092', 'AFC'],
    isRub:true,
    default:false
  },*/
  {
    path: "/epret",
    name: "Gestion des prêts",
    icon: CreditCardIcon,
    component: Epret,
    layout: "/bankass",
    partenaire: ['WF','PAMFCI','COFINA','CI032'],
    isRub:true,
    default:false
  }, /*
  {
    path: "/formations",
    name: "Formation",
    icon: SchoolIcon,
    component: FormationHome,
    layout: "/bankass",
    partenaire: ['WF', '092', 'AFC'],
    isRub:true,
    default:false
  },
 
  {
    path: "/simulateurs",
    name: "Souscription",
    icon: Add,
    component: Simulateur,
    layout: "/bankass",
    partenaire: ['092', 'AFC'],
    isRub:true,
    default:false
  },*/

  {
    path: "/administration",
    name: "Administration",
    icon: AccountCircle,
    component: AdminHome,
    layout: "/bankass",
    partenaire: ['WF','PAMFCI','COFINA','CI032'],
    isRub:true,
    default:false
  },
  
  
]

const courtage = [
  {
    path: "/tableau-de-bord",
    name: "Tableau de bord",
    icon: Dashboard,
    component: AccueilCourtage,
    layout: "/courtage",
    partenaire: ['ASC','NOVELIA'],
    isRub:true,
    default:true
  },
  {
    path: "/productions",
    name: "Souscriptions",
    icon: FolderOpenIcon,
    component: ProductionContainer,
    layout: "/courtage",
    partenaire: ['ASC','NOVELIA'],
    isRub:true,
    default:false
  },
  {
    path: "/operations",
    name: "Prestations",
    icon: FolderIcon,
    component: OperationContainer,
    layout: "/courtage",
    partenaire: ['ASC','NOVELIA'],
    isRub:true,
    default:false
  },
  {
    path: "/facturation",
    name: "Factures",
    icon: AccountBalanceWalletIcon,
    component: FacturationHome,
    layout: "/courtage",
    partenaire: ['ASC'],
    isRub:true,
    default:false
  },
  {
    path: "/conventions",
    name: "Conventions / produits",
    icon: ShoppingBasketOutlinedIcon,
    component: ConventionsContainer,
    layout: "/courtage",
    partenaire: ['ASC','NOVELIA'],
    isRub:true,
    default:false
  },
  {
    path: "/reseau",
    name: "Reseau",
    icon: GroupWorkIcon,
    component: AdminHome,
    layout: "/courtage",
    partenaire: ['ASC','NOVELIA'],
    isRub:true,
    default:false
  },
  
]


//parametrage
const settings = [
  {
    path: "/reseau",
    name: "Reseau",
    icon: BusinessIcon,
    component: ReseauList,
    layout: "/settings",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },
  {
    path: "/zone",
    name: "Zone",
    icon: BusinessCenterIcon,
    component: ZoneList,
    layout: "/settings",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },
  {
    path: "/equipe",
    name: "Equipe",
    icon: GroupWorkIcon,
    component: EquipeList,
    layout: "/settings",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },
  {
    path: "/modules",
    name: "Modules",
    icon: ViewModuleSharpIcon,
    component: ModuleList,
    layout: "/settings",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },
  {
    path: "/profil",
    name: "Profil",
    icon: SupervisedUserCircleIcon,
    component: RoleList,
    layout: "/settings",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },
  {
    path: "/utilisateur/update/:id",
    name: "Modifier un utilisateur",
    icon: PersonAddIcon,
    component:UpdateUtilisateur,
    layout: "/settings",
    partenaire:['ALL'],
    isRub:false,
    default:false
  },
  {
    path: "/utilisateur/add",
    name: "Nouvel utilisateur",
    icon: PersonAddIcon,
    component:AddUtilisateur,
    layout: "/settings",
    partenaire:['ALL'],
    isRub:false,
    default:false
  },
  {
    path: "/utilisateur",
    name: "Utilisateur",
    icon: AccountCircle,
    component: UtilisateurList,
    layout: "/settings",
    partenaire:['ALL'],
    isRub:true,
    default:true
  },
  
  {
    path: "/partenaire",
    name: "Partenaire",
    icon: ViewComfyIcon,
    component: PartenaireHome,
    layout: "/settings",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },{
    path: "/branche/add",
    name: "Branche",
    icon: AcUnitIcon,
    component:AjouterBranche,
    layout: "/settings",
    partenaire:['ALL'],
    isRub:false,
    default:false
  },
  {
    path: "/branche",
    name: "Branche",
    icon: AcUnitIcon,
    component: BrancheList,
    layout: "/settings",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },
  
  
  
]
//producteur routes
const producteur = [
  {
    path: "/home",
    name: "Accueil",
    icon: HomeIcon,
    component: ProducteurHome,
    layout: "/producteur",
    partenaire:['ALL'],
    isRub:true,
    default:true
  },
  {
    path: "/proposition-details",
    name: "Proposition détail",
    icon: LibraryBooksIcon,
    component: PropositionDetails,
    layout: "/producteur",
    partenaire:['ALL'],
    isRub:false,
    default:false
  },
  {
    path: "/suivi-proposition",
    name: "Proposition",
    icon: LibraryBooksIcon,
    component: ProducteurPropoHome,
    layout: "/producteur",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },

  {
    path: "/facturation",
    name: "Facturations",
    icon: AccountBalanceWalletIcon,
    component: FacturationHome,
    layout: "/producteur",
    partenaire:[],
    isRub:true,
    default:false
  },
  {
    path: "/suivi-operations",
    name: "Opérations",
    icon: FolderIcon,
    component: ProducteurOpeHome,
    layout: "/producteur",
    partenaire:[],
    isRub:true,
    default:false
  },
  {
    path: "/suivi-prets",
    name: "Gestion des prêts",
    icon: CreditCardIcon,
    component: ProducteurPretHome,
    layout: "/producteur",
    partenaire:[],
    isRub:true,
    default:false
  },
]
const technique =[
  {
    path: "/home",
    name: "Accueil",
    icon: HomeIcon,
    component: ProducteurHome,
    layout: "/technique",
    partenaire:['ALL'],
    isRub:true,
    default:true
  },
  {
    path: "/suivi-operations",
    name: "Opérations",
    icon: FolderIcon,
    component: ProducteurOpeHome,
    layout: "/technique",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },
]

//branche
const branche=[
  {
    path: "/tableau-de-bord",
    name: "Tableau de bord",
    icon: Dashboard,
    component: AccueilCourtage,
    layout: "/branche",
    partenaire:['ALL'],
    isRub:true,
    default:true
  },
  {
    path: "/productions",
    name: "Productions",
    icon: FolderOpenIcon,
    component: ProductionContainer,
    layout: "/branche",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },
  {
    path: "/conventions",
    name: "Produit / Conventions",
    icon: ShoppingBasketOutlinedIcon,
    component: FacturationHome,
    layout: "/branche",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },
  {
    path: "/partenaire",
    name: "Partenaires",
    icon: ViewComfyIcon,
    component: PartenaireHome,
    layout: "/branche",
    partenaire:['ALL'],
    isRub:true,
    default:false
  },
]
export { routes as aroutes, settings, bankass, producteur, com,courtage,branche,technique,shared,bni };

/*
  {
    path: "/pret-liste",
    name: "Gestion des prêts",
    icon: CreditCardIcon,
    component: PretList,
    layout: "/bankass"
  },*/