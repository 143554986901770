import axios from 'axios';

/*  Gestion des rapports */

  export const  rapportAdd =(data)=>{
    if(!data) return false
    return axios.post('/rapport-add',data).then(res=>{
        if(res.status ===201 || res.status ===200){
            return res.data
        }else{
            console.log(res.data)
            return false
        }
    }).catch(err=>{
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
  }

  export const  getRapportAll =()=>{
    return axios.get('/rapport-list').then(res=>{
        if(res.status ===201 || res.status ===200){
            return res.data
        }else{
            console.log(res.data)
            return false
        }
    }).catch(err=>{
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
 }

 export const getrapport =(id)=>{
    if(!id) return false
    return axios.get(`/rapport/${id}`).then(res=>{
        if(res.status ===201 || res.status ===200){
            return res.data
        }else{
            console.log(res.data)
            return false
        }
    }).catch(err=>{
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
 }

 export const updateRapport = (id,data)=>{
    if(!id || !data) return false
    return axios.put('/update-rapport',data).then(res=>{
        if(res.status ===201 || res.status ===200){
            return res.data
        }else{
            console.log(res.data)
            return false
        }
    }).catch(err=>{
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
 }

 export const deleteRapport = (id)=>{
    if(!id) return false
    return axios.delete(`/rapport/${id}`).then(res=>{
        if(res.status ===201 || res.status ===200){
            return res.data
        }else{
            console.log(res.data)
            return false
        }
    }).catch(err=>{
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}


/*  */