import React, {  useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse'; // web.cjs is required for IE 11 support
import { animated, inferTo, useSpring } from '@react-spring/web'
import { getStructurePartenaire } from 'redux/actions/settingsAction';
import { Paper, Typography,Button, Chip, IconButton, Tooltip } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddIcon from '@material-ui/icons/Add'

function MinusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 16, height: 16 }} {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
      </SvgIcon>
    );
  }
  
  function PlusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" style={{ width: 16, height: 16 }} {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
      </SvgIcon>
    );
  }
  
  function CloseSquare(props) {
    return (
      <SvgIcon className="close" fontSize="inherit" style={{ width: 16, height: 16 }} {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
      </SvgIcon>
    );
  }
  
  function TransitionComponent(props) {
    const style = useSpring({
      from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
      to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
    });
  
    return (
      <animated.div style={style}>
        <Collapse {...props} />
      </animated.div>
    );
  }
  
  TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
  };
  
  const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 30,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  }))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);
  
  const useStyles = makeStyles(theme=>({
    root: {
      height: '100%',
      flexGrow: 1,
      maxWidth: '100%',
    },
    pbPaper:{
      padding:10,
      borderRadius:0,
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      borderRadius:10,
      marginTop:20
    },
    pPaperStart:{
      padding:10,
      borderRadius:0,
      display:'flex',
      flexDirection:'column',
      justifyContent:'flex-sart',
      alignItems:'flex-sart',
      borderRadius:10,
      marginTop:20
    },
    addbtn:{
      width:'75%',
      padding:10,
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      background:'transparent',
      borderRadius:15,
      border:'1px solid #f6f6f6',
      color:theme.palette.primary.main,
      fontSize:40,
      "&:hover":{
        backgroundColor:theme.palette.primary.main,
        color:'#ffffff',
        border:'none',
      },
      },
      secondAdd:{
        backgroundColor:theme.palette.primary.main,
        borderRadius:15,
        "&:hover":{
          backgroundColor:theme.palette.secondary.main,
          color:'#ffffff',
          border:'none',
        },
      },
      headEl:{
        display:'flex',
        flex:1,
        justifyContent:'end',
        alignItems:'center',
        marginBottom:20
      },
      bodEl:{
        display:'flex',
        flexDirection:'column',
        marginBottom:20
      },
      addbtnin:{
        color:theme.palette.secondary.main,
        '&:hover':{
          color:theme.palette.primary.main,
        }
      },
      greenAdd:{
          backgroundColor:theme.palette.primary.main,
          color:'white',
          '&:hover':{
            backgroundColor:theme.palette.secondary.main,
          }
      }
  }));

const ReseauPartenaire = ({data}) => {
    const classes = useStyles();
    const [reseaux,setReseaux] = useState(null)
    const handlepartenaire =()=>{
      let params
      if(data){
        params = data['code']
      }
      getStructurePartenaire(params).then(res=>{
        if(res) setReseaux(res)
      })
    }
    useEffect(()=>{
      handlepartenaire()
    },[data])
  return (
    <div>
      <Paper className={classes.pbPaper} style={{marginBottom:20}} variant="outlined">
        {(!reseaux || (reseaux && reseaux.length===0))&& <Typography variant='h6'>Aucune donnée</Typography>}
        <Button className={classes.addbtn} style={{marginTop:25}}>
              <AddIcon sx={{ fontSize: 40 }} className={classes.prbtn} />
          </Button>
      </Paper>
        {(reseaux && reseaux.length!==0) ? (<Paper className={classes.pPaperStart} variant="outlined"><TreeView
      className={classes.root}
      defaultExpanded={['1']}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
     
    > 
      {reseaux.map((reseau,rx)=><StyledTreeItem nodeId={rx} label={<div style={{display:'flex', alignItems:'center',justifyContent:"space-between"}}><Typography>{reseau.libelle}</Typography> <Chip label={`${reseau.zones.length} zones/départements`}/> <Tooltip title='Ajouter un reseau'><IconButton className={classes.greenAdd}><AddIcon /></IconButton></Tooltip></div>}>
        {(reseau.hasOwnProperty('zones')&& reseau.zones.length !==0) ? (reseau.zones.map((zone,zx)=>(
          <StyledTreeItem nodeId={`${rx}-${zx}`} label={<div style={{display:'flex', alignItems:'center'}}><Typography variant='h6'>{zone.libellezone}</Typography> <span style={{ marginLeft:20,fontSize:12, fontStyle:'italic',fontWeight:500,color:'#ccc'}}>({zone.equipes.length} Equipes /Agences)</span></div>}>
               
                {(zone.hasOwnProperty('equipes')&& zone.equipes.length !==0) ? (zone.equipes.map((equipe,ex)=><StyledTreeItem nodeId={`${rx}-${zx}-${ex}`} label={<div style={{display:'flex', alignItems:'center'}}><Typography>{equipe.libelleequipe}</Typography> <span style={{ marginLeft:20,fontSize:12, fontStyle:'italic',fontWeight:500,color:'#ccc'}}>({equipe.agents.length} Agents /Conseillers)</span></div>}>
                      {(equipe.hasOwnProperty('agents')&& equipe.agents.length !==0) ? (equipe.agents.map((agent,ax)=>(
                         <StyledTreeItem nodeId={`${rx}-${zx}-${ex}-{ax}`} label={<div style={{display:'flex', alignItems:'center'}}><Typography>{agent['codeagent'] || ""} {agent.nom} {agent.prenom}</Typography> <span style={{ marginLeft:20,fontSize:12, fontStyle:'italic',fontWeight:500,color:'#ccc'}}>({agent['role']})</span></div>}/>
                      ))
                          
                      ) : (
                        <StyledTreeItem nodeId={`axa-${ex}`}  label={<span style={{fontWeight:'bold', fontSize:16, marginTop:20}}>Aucun agent / conseiller</span>} />
                      )}
                     
                      <StyledTreeItem nodeId={`ax-0`}  label={<Tooltip title="Ajouter un agent"><IconButton className={classes.addbtnin}><AddCircleOutlineIcon /></IconButton></Tooltip>} />
                     
                   </StyledTreeItem>)):(

                  <StyledTreeItem nodeId={`zx-${zx}-0`}  label="Aucune équipe / agence"/>
                )}
              
              <StyledTreeItem nodeId={`zxa-${zx}`}  label={<Tooltip title="Ajouter une équipe/agence"><IconButton className={classes.addbtnin}><AddCircleOutlineIcon /></IconButton></Tooltip>} />
             
          </StyledTreeItem>
        ))
           
        ) :(<StyledTreeItem nodeId={`${rx}-0`}  label="Aucun zone" />) }
        <StyledTreeItem nodeId={`rxa-${rx}`} label={<Tooltip title="Ajouter une Zone/département"><IconButton className={classes.addbtnin}><AddCircleOutlineIcon /></IconButton></Tooltip>} />
      </StyledTreeItem>)}
      
    </TreeView></Paper>) : (null)}
         
    </div>
  )
}

export default ReseauPartenaire