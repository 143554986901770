import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { reduxForm,change,getFormValues} from 'redux-form';
import {connect} from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { green, pink } from '@material-ui/core/colors';
import {Add} from "@material-ui/icons/";
import { Divider, Paper } from "@material-ui/core";
import PieChartIcon from '@material-ui/icons/PieChart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TableChartIcon from '@material-ui/icons/TableChart';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';
import LocalConvenienceStoreIcon from '@material-ui/icons/LocalConvenienceStore';
import Button from '@material-ui/core/Button';
import MiniHeader from "components/MiniHeader";
const useStyles = makeStyles((theme) =>({
    root: {
      '& :hover':{
        background: theme.palette.primary.main,
        border: 0,
        color:'white',
        textDecoration:'none',
      },
      margin:10,
      cursor:'pointer'
    },
    
    media: {
      height: 140,
    },
    avatar: {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        alignSelf:'center'
      },
      pink: {
        color:theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },
      green: {
        color: '#fff',
        backgroundColor: green[500],
      },
      large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
      },
      paper:{
          padding:10,
          marginBottom:20
      },
      line:{
        marginBottom:20
    },
    buttonNewSearch:{
        width:'100%',
        marginBottom:20,
        borderWidth:1,
        borderStyle:'dashed',
        borderColor:theme.palette.primary.main,
        backgroundColor:"#FFF",
        color:theme.palette.primary.main,
        '&:hover':{
          borderColor:theme.palette.secondary.main,
          color:theme.palette.primary.main,
        }
      },
      rcontainer:{
        display:'flex',
        flexDirection:'column',
        marginTop:75,
        width:'75%',
        marginLeft:'auto',
        marginRight:'auto'
    },
    cardTitre:{
        fontSize:18,fontWeight:300,
        textAlign:'center',
        marginTop:20
    },
   cardcontent:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center'
    }
  }));
function EtatFonctions(props){
    const classes = useStyles();
    const {handleCliqueFonction,user:{credentials}}=props
    return(
        <div>
            <MiniHeader titre= "Etat de gestion" />
            <div className={classes.rcontainer}>
        {credentials.codepartenaire!=='WF'&&<GridContainer spacing={3}>
             <GridItem xs={12} sm={12} md={12} lg={12}>
                <Button variant='outlined' color='primary' onClick={()=>handleCliqueFonction("ADD")} className={classes.buttonNewSearch}><Add /> Creer un état</Button>  
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                    <Typography varaiant='h2' component='h2'>PRODUCTION</Typography>
                    <Divider light className={classes.line} />
                    <GridContainer>
                        <GridItem xs={6} sm={4} md={3} lg={3}>
                            <Card className={classes.root}>
                                <CardActionArea onClick={()=>handleCliqueFonction("ETTPROD")}>
                                    <CardContent>
                                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><TableChartIcon /></Avatar></div>
                                    
                                        <Typography align='center' gutterBottom variant="body" component="body">
                                       Etat des productions
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        <GridItem xs={6} sm={4} md={3} lg={3}>
                            <Card className={classes.root}>
                                <CardActionArea  onClick={()=>handleCliqueFonction("ETTEVOPROD")}>
                                    <CardContent>
                                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><TrendingUpIcon /></Avatar></div>
                                    
                                        <Typography align='center' gutterBottom variant="body" component="body">
                                       Evolution de la production
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        <GridItem xs={6} sm={4} md={3} lg={3}>
                            <Card className={classes.root}>
                                <CardActionArea  onClick={()=>handleCliqueFonction("ETTPRODPROD")} >
                                    <CardContent>
                                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><PieChartIcon /></Avatar></div>
                                    
                                        <Typography align='center' gutterBottom variant="body" component="body">
                                     Production par produit
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        <GridItem xs={6} sm={4} md={3} lg={3}>
                            <Card className={classes.root}>
                                <CardActionArea onClick={()=>handleCliqueFonction("ETTPRODCON")}>
                                    <CardContent>
                                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><BubbleChartIcon /></Avatar></div>
                                    
                                        <Typography align='center' gutterBottom variant="body" component="body">
                                      Production par conseiller client
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        <GridItem xs={6} sm={4} md={3} lg={3}>
                            <Card className={classes.root}>
                                <CardActionArea onClick={()=>handleCliqueFonction("ETTPRODSYN")}>
                                    <CardContent>
                                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><ViewComfyIcon /></Avatar></div>
                                    
                                        <Typography align='center' gutterBottom variant="body" component="body">
                                     synthèse de la production par période
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        
                        <GridItem xs={6} sm={4} md={3} lg={3}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent onClick={()=>handleCliqueFonction("ETTPRODCON2")}>
                                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><PeopleIcon /></Avatar></div>
                                    
                                        <Typography align='center' gutterBottom variant="body" component="body">
                                       Production par conseiller et par produit                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        
                        <GridItem xs={6} sm={4} md={3} lg={3}>
                            <Card className={classes.root}>
                                <CardActionArea onClick={()=>handleCliqueFonction("ETTPRODAGENCE")}>
                                    <CardContent>
                                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><BusinessIcon /></Avatar></div>
                                        <Typography align='center' gutterBottom variant="body" component="body">
                                     Production par agence
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        
                        <GridItem xs={6} sm={4} md={3} lg={3}>
                            <Card className={classes.root}>
                                <CardActionArea onClick={()=>handleCliqueFonction("ETTPRODZONE")}>
                                    <CardContent>
                                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><LocalConvenienceStoreIcon /></Avatar></div>
                                    
                                        <Typography align='center' gutterBottom variant="body" component="body">
                                     Production par zone
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </Paper>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                    <Typography varaiant='h2' component='h2'>CHIFFRE D'AFFAIRE</Typography>
                    <Divider light className={classes.line} />
                    <div style={{display:'flex',flexWrap:'wrap'}}>

                        <Card className={classes.root} onClick={()=>handleCliqueFonction("ETTCACON")}>
                            <CardContent>
                                <div className={classes.cardcontent}>
                                    <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><ViewComfyIcon /></Avatar></div>
                                    <sapn className={classes.cardTitre}>
                                    Chiffre d'affaire par connseiller
                                    </sapn>
                                </div>
                            </CardContent>
                        </Card>
                        
                        
                        <Card className={classes.root} onClick={()=>handleCliqueFonction("ETTCAPROD")}>
                            <CardContent>
                                <div className={classes.cardcontent}>
                                    <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><ViewComfyIcon /></Avatar></div>
                                    <span className={classes.cardTitre}>
                                        Chiffre d'affaire par produit
                                    </span>
                                </div>
                            </CardContent>
                        </Card>
                       
                        <Card className={classes.root} onClick={()=>handleCliqueFonction("ETTCAAGENCE")}>
                            <CardContent>
                                <div className={classes.cardcontent}>
                                    <div className={classes.avatar}>
                                        <Avatar aria-label="recipe" className={clsx(classes.large)}><ViewComfyIcon /></Avatar>
                                    </div>
                                    <span className={classes.cardTitre}>
                                        Chiffre d'affaire par agence
                                    </span>
                                </div>
                            </CardContent>
                        </Card>
                      
                        <Card className={classes.root} onClick={()=>handleCliqueFonction("ETTCAZONE")}>
                            <CardContent>
                                <div className={classes.cardcontent}>
                                    <div className={classes.avatar}>
                                        <Avatar aria-label="recipe" className={clsx(classes.large)}><ViewComfyIcon /></Avatar>
                                    </div>
                                    <span className={classes.cardTitre}>
                                        Chiffre d'affaire par zone
                                    </span>
                                </div>
                            </CardContent>
                            
                        </Card>
                        
                            <Card className={classes.root} onClick={()=>handleCliqueFonction("ETTEVOCA")}>
                                <CardContent>
                                    <div className={classes.cardcontent}>
                                        <div className={classes.avatar}><Avatar aria-label="recipe" className={classes.large}><ViewComfyIcon /></Avatar></div>
                                
                                        <span className={classes.cardTitre}>
                                            Chiffre d'affaire par mois
                                        </span>
                                    </div>
                                </CardContent>
                            </Card>
                        
                            <Card className={classes.root} onClick={()=>handleCliqueFonction("ETTCASYN")}>
                                <CardContent>
                                    <div className={classes.cardcontent}>
                                        <div className={classes.avatar}>
                                            <Avatar aria-label="recipe" className={classes.large}><ViewComfyIcon /></Avatar>
                                        </div>
                                    
                                        <span className={classes.cardTitre}>
                                            Chiffre d'affaire par année
                                        </span>
                                    </div>
                                </CardContent>
                            </Card>
                      
                    </div>
                </Paper>
            </GridItem>
        </GridContainer>}
        
        <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <Typography varaiant='h2' component='h2'>Rapport de production sur les prêts</Typography>
                <Divider light className={classes.line} />
                <Paper elevation={3} style={{display:'flex'}}>
                    <CardActionArea onClick={()=>handleCliqueFonction("ETTPRODZONE")}>
                        <CardContent>
                            <div className={classes.avatar}>
                                <Avatar aria-label="recipe" className={clsx(classes.large)}>
                                    <LocalConvenienceStoreIcon />
                                </Avatar>
                            </div>
                            <Typography align='center' gutterBottom variant="body" component="body">
                                Rapport détaillés
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActionArea onClick={()=>handleCliqueFonction("ETTPRODZONE")}>
                        <CardContent>
                            <div className={classes.avatar}>
                                <Avatar aria-label="recipe" className={clsx(classes.large)}>
                                    <LocalConvenienceStoreIcon />
                                </Avatar>
                            </div>
                            <Typography align='center' gutterBottom variant="body" component="body">
                                Rapport de performance
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                <CardActionArea onClick={()=>handleCliqueFonction("ETTPRODZONE")}>
                    <CardContent>
                        <div className={classes.avatar}>
                            <Avatar aria-label="recipe" className={clsx(classes.large)}>
                                <LocalConvenienceStoreIcon />
                            </Avatar>
                        </div>
                        <Typography align='center' gutterBottom variant="body" component="body">
                            Rapport financier
                        </Typography>
                    </CardContent>
                </CardActionArea>
                </Paper>
            </GridItem>
        </GridContainer>
        </div>
        </div>
    )
}


const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapPropsActions ={
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user,
    data:getFormValues('adForm')(state)
  });

  export default connect(mapStateToProps,mapPropsActions)(reduxForm({destroyOnUnmount: false,})(EtatFonctions))