import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { reduxForm, change, getFormValues } from 'redux-form';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { green, pink } from '@material-ui/core/colors';
import Button from "components/CustomButtons/Button.js";
import { Grid, Box } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { formatNumber } from 'application'
import dayjs from 'dayjs'
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 10,
        padding: 10
    },

    loading: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonAdd: {
        width: '100%',
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 30,
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },

    buttonDetails: {
        width: '100%',
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        backgroundColor: '#fff',
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },

    buttonAddChild: {
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    buttonSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,

        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonReset: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonNewSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: "#FFF",
        color: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
        }
    },
    media: {
        height: 140,
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    dcard: {
        marginBottom: 10,
        paddingBottom: 10
    },
    paper: {
        marginBottom: 20,
        padding: 20
    },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
    },
    assureInfoRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    btnback:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:"#f9b233",
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#3c3c3b",
        },
        borderRadius:10,
      },
      btnnext:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:theme.palette.primary.main,
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#f9b233",
        },
        borderRadius:10,
      },
}));

function ResumeOperation(props) {
    const classes = useStyles();
    const { handleSubmit, handleNext, handleBack, activeStep, steps, dataForm, loading } = props
    console.log(dataForm);
    return (!loading ? (<form onSubmit={handleSubmit(handleNext)}>
        <Paper elevation={0} className={classes.paper}>
            <Typography className={classes.heading}>{(dataForm['groupeoperation'] === 'SIN') ? 'FICHE DE DECLARATION DE SINISTRE' : (dataForm['groupeoperation'] === 'PRES' ? 'COURRIER DE DEMANDE DE PRESTATION' : 'FICHE DE DEMANDE DE MODIFICATION')}</Typography>
            <Divider />
        </Paper>
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography variant='h5'>CONTRATS</Typography>
                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                #ID :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['idproposition'] || ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Code proposition :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['codeproposition'] || ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                N° police :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['codepolice'] || ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Date d'effet :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['dateeffet'] || ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Date échéance :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['dateecheance'] || ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Capital :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['capital'] ? formatNumber(parseInt(dataForm['capital']).toString()) : ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Produit :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['libelleproduit'] || ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Adherent :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['adherent'] || ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Prime :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['prime'] ? formatNumber(parseInt(dataForm['prime']).toString()) : ""}`}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Nombre d'émission :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['nombreemission'] ? formatNumber(parseInt(dataForm['nombreemission']).toString()) : ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Montant d'émission :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['montantemission'] ? formatNumber(parseInt(dataForm['montantemission']).toString()) : ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Nombre d'encaissement :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['nombreencaissement'] ? formatNumber(parseInt(dataForm['nombreencaissement']).toString()) : ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Montant d'encaissement :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['montantencaissement'] ? formatNumber(parseInt(dataForm['montantencaissement']).toString()) : ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Nombre d'impayés :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['nombreimpayes'] ? formatNumber(parseInt(dataForm['nombreimpayes']).toString()) : ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Montant d'impayés :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['montantimpayes'] ? formatNumber(parseInt(dataForm['montantimpayes']).toString()) : ""}`}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography variant='h5'>OPERATION</Typography>
                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Type :
                            </Typography>
                            <Typography variant="h6">
                                {`\xa0 ${dataForm['operation']['MonLibelle'] || ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Date opération :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dayjs().format('DD/MM/YYYY')}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Délai traitement :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['operation']['DelaiTraitement'] || ""} jour(s)`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Date fin traitement prévu :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['operation']['DelaiTraitement'] ? dayjs().add(parseInt(dataForm['operation']['DelaiTraitement']), 'day').format('DD/MM/YYYY') : ""}`}
                            </Typography>
                        </Box>
                    </Grid>

                    

                </Grid>
                
                <Divider />
                
            </CardContent>
        </Card>
        
        <Paper elevation={3} className={classes.paper}>
            <Divider style={{ marginTop: 20 }} />
            <div style={{ display: "flex", justifyContent: "space-between", padding: 20 }}>
                <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.btnback}

                >
                    Retour
                </Button>

                <Button
                    variant="contained"
                    type='submit'
                    className={classes.btnnext}
                    large
                >
                    {activeStep === steps.length - 1 ? 'Valider' : 'Suivant'}
                </Button>
            </div>

        </Paper>
    </form>) : (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress color="secondary" /></div>)

    )
}
const updateField = (field, data) => (dispatch) => dispatch(change('addOper', field, data))
const mapPropsActions = {
    updateField,
}
const mapStateToProps = (state) => ({
    user: state.user,
    dataForm: getFormValues('addOper')(state),
});
export default connect(mapStateToProps, mapPropsActions)(reduxForm({ form: 'addOper', destroyOnUnmount: false })(ResumeOperation))

