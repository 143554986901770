import React ,{useState,useReducer,useEffect,useCallback} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withWidth from '@material-ui/core/withWidth';
import { Typography,Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {useSelector,connect} from 'react-redux';
import {formValueSelector} from 'redux-form'
import {getFiliations} from 'redux/actions/apiActions'
import CustomInput from 'components/CustomInput/CustomInput'
import CustomRadio from 'components/CustomRadio/CustomRadio'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import CustomDate from 'components/CustomDate/CustomDate'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import dayjs from 'dayjs'
import { green } from '@material-ui/core/colors';
import { IconButton } from '@material-ui/core';
import {Grid} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import CustumModal from 'components/CustumModal';
const useStyles = makeStyles(theme => ({
  rootList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start'
  },
  rootTabs: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
  },
  dialogHead:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    padding:20
  },
  hbande:{
    height:20,
    width:'100%',
    backgroundColor:green[400]
  },
  btnback:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:"#f9b233",
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#3c3c3b",
    },
    borderRadius:10,
  },
  btnnext:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:theme.palette.primary.main,
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
  

function FromAssure(props) {
  const {open,handleClose,handleAddAssure,mesAssures} = props
  const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
  {
    numeroCniAssure:'',
    nomAssure: '', 
    prenomAssure: '',
    dateNaissanceAssure:'',
    lieuNaissanceAssure:'',
    lieuResidenceAssure:'',
    filiationAssure:'',
    telephoneAssure:'',
    emailAssure:'',
    typeGarantie:null,
    monIndex:mesAssures&&mesAssures.length+1
  });

  const classes = useStyles();
    //get value off villes
    let  mesVilles = useSelector(state=>state.api.villes)
    if(mesVilles.length===0){
      mesVilles=JSON.parse(localStorage.APIVilles);
    }
    //get filaiation value
    const [filiations, setFiliations] = useState(null)
    useEffect(() => {
      getFiliations().then((fil)=>{
        setFiliations(fil);
      })
    }, [])
    
    const handleChange =(event)=>{
      const {name,value}=event.target
      setInputField({
        [name]:value
      })
    }
    const initialField =()=>{
      setInputField({
        ['numeroCniAssure']:'',
        ['nomAssure']: '', 
        ['prenomAssure']: '',
        ['dateNaissanceAssure']:'',
        ['lieuNaissanceAssure']:'',
        ['lieuResidenceAssure']:'',
        ['filiationAssure']:'',
        ['telephoneAssure']:'',
        ['emailAssure']:'',
      })
    }
    const submit=(e)=>{
     
      e.preventDefault()
      let dataAssure ={...inputField}
    
      handleAddAssure({...dataAssure})
    }
    useEffect(()=>{
      if(open===true){
          initialField()
      }
    },[open])
   const handleSetTypeGaratie = useCallback(()=>{
      switch (inputField.filiationAssure) {
        case 'Conjoint':
          setInputField({
            ['typeGarantie']:'CONJ'
          })
          break;
        case 'Enfant':
          setInputField({
            ['typeGarantie']:'ENF'
          });
          break;
        case 'Lui même':
          setInputField({
            ['typeGarantie']:'ADH'
          });
          break;
        case 'Mère':
        case 'Père':
          setInputField({
            ['typeGarantie']:'ASC'
          });
          break;
        default:
          setInputField({
            ['typeGarantie']:null
          });
          break;
      }
   },[inputField.filiationAssure])
  
   useEffect(()=>{
    handleSetTypeGaratie()
   },[inputField.filiationAssure])
  
   const enabled =inputField.nomAssure.length > 0 &&inputField.prenomAssure.length > 0&&inputField.dateNaissanceAssure.length;
    return (
      
          <CustumModal open={open} handleClose={handleClose} title="Ajouter un assuré" width='md'>
          <form onSubmit={submit}>
            <div className='max-w-screen-sm mx-auto'>
            
                <div className='flex flex-col gap-3'>
                  <div className='flex flex-row gap-3'>
                    <CustomInput
                        required
                        label="Nom assuré *"
                        id="nomAssure"
                        name="nomAssure"
                        variant="outlined"
                        formControlProps={{
                            fullWidth: true
                        }}
                        autoFocus
                        value={(inputField.nomAssure && inputField.nomAssure!=="")&&inputField.nomAssure}
                        onChange={handleChange}        
                    />
                          
                    <CustomInput
                        required
                        label="Prénom assuré *"
                        id="prenomAssure"
                        name="prenomAssure"
                        variant="outlined"
                        formControlProps={{
                            fullWidth: true
                        }}
                        value={(inputField.prenomAssure && inputField.prenomAssure!=="")&&inputField.prenomAssure}
                        onChange={handleChange}
                      
                    />
                      </div>  
                      <div className='flex flex-row gap-3'>
                          <CustomInput
                              label="Numéro pièce d'indentité"
                              id="numeroCniAssure"
                              name="numeroCniAssure"
                              variant="outlined"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              value={(inputField.numeroCniAssure && inputField.numeroCniAssure!=="")&&inputField.numeroCniAssure}
                              onChange={handleChange}
                              
                          />
                        
                          <CustomRadio 
                            label="Nature de la pièce"
                            id="naturePiece"
                            name="naturePiece"
                            formControlProps={{
                                fullWidth: true
                            }}
                            value={inputField.naturePiece}
                            row
                            onChange={handleChange}
                            
                            options={[{"label":"CNI","value":"CNI"},{"label":"Attestation","value":"AT"},{"label":"Passport","value":"Passport"}]}  
                            
                          />
                        </div>
                        <div className='flex flex-row gap-3'>
                            <CustomDate
                                required
                                label="Date de naissance *"
                                id="dateNaissanceAssure"
                                name="dateNaissanceAssure"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                InputProps={{
                                    type:'date',
                                    value:(inputField.dateNaissanceAssure && inputField.dateNaissanceAssure!=="")&&inputField.dateNaissanceAssure,
                                    inputProps: { max:dayjs().add(-3,'year').format('YYYY-MM-DD'),min:dayjs().add(-100,'year').format('YYYY-MM-DD')}
                                }}
                                onChange={handleChange}
                                
                            />
                        
                            <CustomSelect
                                label="Lieu de naissance"
                                id="lieuNaissanceAssure"
                                name="lieuNaissanceAssure"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                value={(inputField.lieuNaissanceAssure && inputField.lieuNaissanceAssure!=="")&&(inputField.lieuNaissanceAssure.hasOwnProperty('MonLibelle')?inputField.lieuNaissanceAssure.MonLibelle:inputField.lieuNaissanceAssure)}
                                options={mesVilles}
                                onChange={handleChange}
                                
                            />
                        </div>
                            <CustomSelect
                                label="Lieu de résidence"
                                id="lieuResidenceAssure"
                                name="lieuResidenceAssure"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                value={(inputField.lieuResidenceAssure && inputField.lieuResidenceAssure!=="")&&(inputField.lieuResidenceAssure.hasOwnProperty('MonLibelle')?inputField.lieuResidenceAssure.MonLibelle:inputField.lieuResidenceAssure)}
                                options={mesVilles}
                                onChange={handleChange}
                                
                            />
                      
                        {filiations&&<CustomSelect
                            label="Lien de parenté *"
                            id="filiationAssure"
                            name="filiationAssure"
                            formControlProps={{
                                fullWidth: true
                            }}
                            value={(inputField.filiationAssure && inputField.filiationAssure!=="")&&(inputField.filiationAssure.hasOwnProperty('MonLibelle')?inputField.filiationAssure.MonLibelle:inputField.filiationAssure)}
                            options={filiations}
                            onChange={handleChange}
                            
                          />}
                      
                        <CustomInput
                            label="Téléphone"
                            id="telephoneAssure"
                            name="telephoneAssure"
                            variant="outlined"
                            formControlProps={{
                                fullWidth: true
                            }}
                            value={(inputField.telephoneAssure && inputField.telephoneAssure!=="")&&inputField.telephoneAssure}
                            onChange={handleChange}
                            
                          />
                      
                        <CustomInput
                            label="Addresse email"
                            id="emailAssure"
                            name="emailAssure"
                            type="email"
                            variant="outlined"
                            formControlProps={{
                                fullWidth: true
                            }}
                            value={(inputField.emailAssure && inputField.emailAssure!=="")&&inputField.emailAssure}
                            onChange={handleChange}
                          />
                        
                  </div>      
                </div>
                <Divider className='my-3' />
                <div className='flex flex-row justify-between items-center flex-1'>
              <Button onClick={handleClose} className="text-white bg-secondary-main border-0 px-3 py-2 cursor-pointer hover:bg-black" variant="outlined">
                Annuler
              </Button>
              <Button type='submit' className={`text-white  border-0 px-3 py-2 mx-3 cursor-pointer hover:bg-secondary-main ${!enabled ? 'bg-gray-300' :'bg-primary-main'}`} variant="outlined" disabled={!enabled} >
                Ajouter
              </Button>
          </div>
            </form> 
          </CustumModal>
         
              
              
              
      
      
    );
  }

  const selector = formValueSelector('adForm')
  const mapStateToProps = (state) => ({
    api: state.api,
    myValues:selector(state, 'produit') 
  });
  
  const mapActionsToProps = {
   
  };
  FromAssure = withWidth()(FromAssure)
  export default connect(mapStateToProps,mapActionsToProps)(FromAssure)