import React from "react";
import PretHome from 'pages/Banking/Prets/PretHome'
import ProductionHome from 'pages/Banking/Propositions/ProductionHome'
import { connect } from 'react-redux'
function ProdHome(props) {
    const { user: { credentials } } = props
    return (
        <>
            <ProductionHome />
            {(['WF'].includes(credentials.codepartenaire)) && <PretHome />}

        </>)
}

const mapPropsActions = {}
const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps, mapPropsActions)(ProdHome)