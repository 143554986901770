import React, { useReducer,useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from "@material-ui/core/Typography";
import {connect,useDispatch} from 'react-redux'
import CustomRadio from 'components/CustomRadio/CustomRadio'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';
import {saveUtilisateur,getEquipesZones,getZonesReseau,getRolesReseau} from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput'
import dayjs from 'dayjs'
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertPage from 'components/AlertPage'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
        justifyContent:'center',
        alignItems:'center'
    },
    flex:1,
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));

function UpdateUtilisateur(props){
    const classes = useStyles();
    const theme = useTheme();
    //intialisation des élement du state
    const {userData,mopen,handleCloseMod,handleMopen,reseaux}=props //recuperation des valieur du props
    const dispatch = useDispatch()
    const [zones,setZones] = useState([])
    const [equipes,setEquipes] = useState([])
    const [rolesReseau,setRolesReseau] = useState([])
    const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
    nom:userData.nom?userData.nom:"",
    prenom:userData.prenom?userData.prenom:"",
    datenaissance:userData.datenaissance?dayjs(userData.datenaissance).format('YYYY-MM-DD'):"",
    sexe:userData.sexe?userData.sexe:"",
    telephone:userData.telephone?userData.telephone:'',
    telephone2:userData.telephone2?userData.telephone2:'',
    email:userData.email?userData.email:'',
    codezone:userData.codezone?userData.codezone:'',
    codeequipe:userData.codeequipe?userData.codeequipe:'',
    codereseau:userData.codereseau?userData.codereseau:'',
    coderole:userData.coderole?userData.coderole:'',
    codeagent:userData.codeagent?userData.codeagent:'',
    profession:userData.profession?userData.profession:''
    });
    
    const [loading,setLoading] = useState(false)
    const [alertInfo,setAlertInfo]=useState({})
    const handleAlertClose =(action=null)=>{
        setAlertInfo({open:false,message:"",succes:false})
        action&&action()
    }
    const handleAlertOpen =(message,succes=false,action)=>{
        setAlertInfo({open:true,message:message,succes:succes,action:action})
    }
    const handleChange = (event)=>{   
        const {name,value} = event.target
        setInputField({
            [name]:value
        })
    }
    const handleChangePhone = (value,name)=>{   
        setInputField({
            [name]:value
        })
    }
  useEffect(() => {
      if(inputField.codereseau!=='' && zones.length===0){
        getZonesReseau(inputField.codereseau).then(dataZone=>{
            setZones(dataZone)
        })
      }
      if(inputField.codereseau!=='' && rolesReseau.length===0){
        getRolesReseau(inputField.codereseau).then(dataRole=>{
            setRolesReseau(dataRole)
        })
      }
      if(inputField.codezone!=='' && equipes.length===0){
        getEquipesZones(inputField.codezone).then(dataEquipe=>{
            setEquipes(dataEquipe)
        })
      }
  })
  const handleSaveUtilisateur =(event)=>{
     
    props.saveUtilisateur(userData.idmembre,inputField).then(dataUser=>{
        if(dataUser){
            setInputField({
                ['nom']:'',
                ['prenom']:'',
                ['login']:'',
                ['password']:'',
                ['passwordConfirm']:'',
                ['datenaissance']:"",
                ['telephone']:'',
                ['telephone2']:'',
                ['email']:'',
                ['profession']:''
            })
            setLoading(false)
            handleAlertOpen("Modification effectuée avec succès",true,handleCloseMod)
           
        }else{
            setLoading(false)
            handleAlertOpen("Erreur lors de l'enregistrement",false,null)
            return false
        }
        return
    }).catch(err=>{
        console.log(err);
        setLoading(false)
        handleAlertOpen(err,false,null)
        return
    })
    event.stopPropagation()
    return 
  }
   //processus principale
    return (
        <Dialog
            maxWidth='md'
            open={mopen}
            disableBackdropClick 
            disableBackdropClick
            onClose={handleCloseMod} 
            fullScreen
            aria-describedby="alert-dialog-description"
            aria-labelledby="form-dialog-title">
            <AppBar className={classes.appBar}>
                <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleCloseMod} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Modification de l'utilisateur - #{userData.idmembre} 
                    </Typography>
                    
                </Toolbar>
        </AppBar>
            <DialogContent>
            <AlertPage open={alertInfo['open']||false}  action={alertInfo['action']||null} message={alertInfo['message']||""} succes={alertInfo['succes']||false} handleAlertOpen={handleAlertOpen} handleAlertClose={handleAlertClose} />
            <form>
            {!loading?(<>
                <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={6}>

                                <LabelledOutline label="Informations personnelles" >
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <CustomInput
                                            required
                                            autoFocus
                                            variant="outlined"
                                            id="nom"
                                            name='nom'
                                            label="Nom"
                                            type="text"
                                            value = {inputField.nom}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            onChange={handleChange}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <CustomInput
                                            autoFocus
                                            required
                                            variant="outlined"
                                            id="prenom"
                                            name ='prenom'
                                            onChange={handleChange}
                                            label="Prénom"
                                            type="text"
                                            value = {inputField.prenom}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={7} md={7} lg={7}>
                                        <TextField
                                            required
                                            variant="outlined"
                                            id="datenaissance"
                                            name ='datenaissance'
                                            onChange={handleChange}
                                            label="Date de naissence"
                                            value = {inputField.datenaissance}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                type:'date',
                                                inputProps: {min:dayjs().add(-100,'year').format('YYYY-MM-DD'),max:dayjs().add(-12,'year').format('YYYY-MM-DD')},
                                            }}
                                            style={{marginTop:20,marginBottom:10}}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4} lg={4}>
                                    
                                        <CustomRadio
                                            required
                                            row
                                            variant="outlined"
                                            id="sexe"
                                            name ='sexe'
                                            onChange={handleChange}
                                            label="Sexe"
                                            value = {inputField.sexe}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            options={[{"label":"M","value":"M"},{"label":"F","value":"F"}]}  
                                        />
                                    </GridItem>
                                </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <CustomInput
                                                autoFocus
                                                variant="outlined"
                                                id="profession"
                                                name='profession'
                                                label="Profession"
                                                type="text"
                                                value = {inputField.profession}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                onChange={handleChange}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </LabelledOutline>
                                <LabelledOutline label="Contacts" >
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <CustomPhoneInput
                                                label="Mobile 1"
                                                id="telephone"
                                                name="telephone"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                onChange={(v)=>handleChangePhone(v,'telephone')}
                                                value = {inputField.telephone}
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <CustomPhoneInput
                                                label="Mobile 2"
                                                id="telephone2"
                                                name="telephone2"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                onChange={(v)=>handleChangePhone(v,'telephone2')}
                                                value = {inputField.telephone2}
                                                variant="outlined"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <CustomInput
                                            required
                                            variant="outlined"
                                            id="email"
                                            name ='email'
                                            onChange={handleChange}
                                            label="Email"
                                            type="email"
                                            value = {inputField.email}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    </GridContainer>
                                </LabelledOutline>
                                   
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={6}>
                                <LabelledOutline label="Reseau" >
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <TextField
                                                variant="outlined"
                                                id="codezone"
                                                name ='codezone'
                                                select
                                                label="Zone/Departement"
                                                value={inputField.codezone}
                                                onChange={handleChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{marginTop:20,marginBottom:10}}
                                                >
                                                    <MenuItem key={0} value={0} />
                                                {(zones.length !==0)&& zones.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                    {option.libellezone}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <TextField
                                                variant="outlined"
                                                id="codeequipe"
                                                name ='codeequipe'
                                                select
                                                label="Equipe/Agence"
                                                value={inputField.codeequipe}
                                                onChange={handleChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{marginTop:20,marginBottom:10}}
                                                >
                                                    <MenuItem key={0} value={0} />
                                                {(equipes.length !==0)&& equipes.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                    {option.libelleequipe}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </GridItem>
                                        
                                    </GridContainer>  
                                </LabelledOutline>
                                <LabelledOutline label="Compte" >
                                    <GridContainer>  
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <TextField
                                                variant="outlined"
                                                id="coderole"
                                                name ='coderole'
                                                select
                                                label="Profile"
                                                value={inputField.coderole}
                                                onChange={handleChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{marginTop:20,marginBottom:10}}
                                                >
                                                    <MenuItem key={0} value={0} />
                                                {(rolesReseau.length !==0)&& rolesReseau.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                    {option.role}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <CustomInput
                                            required
                                            variant="outlined"
                                            id="codeagent"
                                            name='codeagent'
                                            label="Code agent"
                                            type="text"
                                            value = {inputField.codeagent}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            onChange={handleChange}
                                        />
                                    </GridItem>
                                    </GridContainer>
                                </LabelledOutline>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Button  color="primary" onClick={handleSaveUtilisateur}  style={{width:'100%',marginTop:20,marginBottom:20}}>Enregistrer</Button>
                            </GridItem>
                        </GridContainer>
            </>):(<div className={classes.root}><CircularProgress color="primary" /></div>)}
                    
                </form>  
                
            </DialogContent>
      </Dialog>
    )
}
UpdateUtilisateur.propTypes = {
    userData: PropTypes.object.isRequired,
    mopen: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    reseaux:state.settings.reseaux,
    user:state.user
});
  const mapActionsToProps = {saveUtilisateur}
export default connect(mapStateToProps,mapActionsToProps)(UpdateUtilisateur)