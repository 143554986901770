import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { connect} from 'react-redux'
import { reduxForm, change, getFormValues } from 'redux-form';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from '@material-ui/core/Card';
import 'jspdf-autotable'
import { getContratsAgent } from 'redux/actions/apiActions'
import MaterialTable from 'material-table'
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { green, pink } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { EtatCotisation } from 'redux/actions/apiActions'
import { etatEncaissement } from 'application'
import MiniHeader from 'components/MiniHeader';
import MinToolsBar from 'components/ESMinToolsBar';
import { ListAlt } from '@material-ui/icons';
import { IconButton,Tooltip } from '@material-ui/core'
import Paper from '@material-ui/core/Paper';
function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
//style
const useStyles = makeStyles(theme => ({
  root: {
    display:'flex',
    flex:0.5,
    justifyContent:'space-between',
    marginBottom: 20,
    marginLeft:20,
    '& :hover': {
      background: theme.palette.primary.main,
      border: 0,
      padding: '10 30px',
      textDecoration: 'none'
    }
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },

  buttonAdd: {
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 30,
    color: theme.palette.secondary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    }
  },

  buttonDetails: {
    width: '100%',
    borderWidth: 1,
    borderColor: theme.palette.secondary.main,
    backgroundColor: '#fff',
    color: theme.palette.secondary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    }
  },

  buttonAddChild: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  media: {
    height: 140,
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  green: {
    color: '#fff',
    backgroundColor: green[500],
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  rcontainer:{
    display:'flex',
    flexDirection:'column',
    width:'75%',
    marginLeft:'auto',
    marginRight:'auto',
    [theme.breakpoints.down('sm')]:{
      width:'100',
      marginLeft:20,
      marginRight:20
    },
  },
  ritems:{
    display:'flex',
  }
}));

function ContratList(props) {
  const classes = useStyles();
  const { user: { credentials, privileges } } = props
  const [details, setDetails] = useState([])
  const [titleDetails, setTitleDetails] = useState('')
  const handleDetails = (dt, title) => {
    setDetails(dt)
    setTitleDetails(title)
    setShowResult(true)
  }
  const [showResult, setShowResult] = useState(false)
  const [contrat, setContrat] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      encours: "",
      suspendu: "",
      arrete: "",
      total: 0

    });
  const [detailContrat, setDetailContrat] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      detailEncours: "",
      detailSuspendu: "",
      detailArrete: "",
      detailAll: ""

    });
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    setShowResult(false)
    getContratsAgent(credentials.codeagent).then(resData => {
      if (resData) {

        const detailsAll = resData.details
        const productionsAll = resData.production
        let mytotal = 0
        if (productionsAll && productionsAll.lenght !== 0) {
          productionsAll.map(prod => {
            mytotal = mytotal + parseInt(prod.Nombre)

          })
        }

        if (detailsAll && detailsAll.lenght !== 0) {
          setContrat({
            ['encours']: productionsAll.filter(item => item.Etat == 1)[0],
            ['suspendu']: productionsAll.filter(item => item.Etat == 2)[0],
            ['arrete']: productionsAll.filter(item => item.Etat == 3)[0],
            ['total']: mytotal
          })
        }

        if (detailContrat && detailContrat.lenght !== 0) {
          setDetails(detailsAll)
          setDetailContrat({
            ['detailEncours']: detailsAll.filter(item => item.Etat === 'En cours'),
            ['detailSuspendu']: detailsAll.filter(item => item.Etat === 'Suspendu'),
            ['detailArrete']: detailsAll.filter(item => item.Etat === 'Arrêté'),
            ['detailsTout']: detailsAll
          })
        }

      } else {
        setShowResult(true)
      }

      setLoading(false)

    }).catch(err => {
      console.log(err);
      setLoading(false)
      setShowResult(true)
    })

  }, [])
  const handleEtatCotisation = (dataEtat) => {
    if (!dataEtat) return false
    EtatCotisation({ idContrat: dataEtat.id }).then(res => {
      if (res) {
        etatEncaissement(res)
      }
    })

  }
  return (
    <div style={{display:'flex',flexDirection:'column'}}>
        <MiniHeader titre="Liste des contrats" showbtn={false} plateforme='souscription'/>
        <MinToolsBar />
        <div style={{marginTop:75}}></div>
        <div className={classes.rcontainer}>
          <div className={classes.ritems}>
            {contrat.total && (
                <Card className={classes.root}>
                  <CardActionArea onClick={(e) => handleDetails(detailContrat.detailsTout, "Liste de mes contrats")}>
                    <CardContent>
                      <div className={classes.avatar}>
                        <Typography align='center' gutterBottom variant="caption" component="span">
                          Total
                        </Typography>
                      </div>
                      <Typography align='center' variant="h4" color="textPrimary" component="h4">
                        {(contrat.total && contrat.total !== 0) && formatNumber(contrat.total)}
                      </Typography>
                      <Button onClick={(e) => handleDetails(detailContrat.detailsTout, "Liste de mes contrats")} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                    </CardContent>
                  </CardActionArea>
                </Card>
            )}
            {(contrat.encours && contrat.encours !== '') && (
                <Card className={classes.root}>
                  <CardActionArea onClick={(e) => handleDetails(detailContrat.detailEncours, "Liste de mes contrats actifs")} >
                    <CardContent>
                      <div className={classes.avatar}>
                        <Typography align='center' gutterBottom variant="caption" component="span">
                          Mes contrats actifs
                        </Typography>
                      </div>
                      <Typography align='center' variant="h4" color="textPrimary" component="h4">
                        {formatNumber(contrat.encours.Nombre)}
                      </Typography>
                      <Button onClick={(e) => handleDetails(detailContrat.detailEncours, "Liste de mes contrats actifs")} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                    </CardContent>
                  </CardActionArea>
                </Card>
              )}
            {(contrat.suspendu && contrat.suspendu !== '') && (
                <Card className={classes.root}>
                  <CardActionArea onClick={(e) => handleDetails(detailContrat.detailSuspendu, "Liste de mes contrats suspendus")}>
                    <CardContent>
                      <div className={classes.avatar}><Typography align='center' gutterBottom variant="caption" component="span">
                        Mes contrats suspendus
                      </Typography></div>
                      <Typography align='center' variant="h4" color="textPrimary" component="h4">
                        {formatNumber(contrat.suspendu.Nombre)}
                      </Typography>
                      <Button onClick={(e) => handleDetails(detailContrat.detailSuspendu, "Liste de mes contrats suspendus")} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                    </CardContent>
                  </CardActionArea>
                </Card>
            )}
            {(contrat.arrete && contrat.arrete !== '') && (
                <Card className={classes.root}>
                  <CardActionArea onClick={(e) => handleDetails(detailContrat.detailArrete, "Liste de mes contrats arrêtés")}>
                    <CardContent>
                      <div className={classes.avatar}>
                        <Typography align='center' gutterBottom variant="caption" component="span">
                          Mes contrats arrêtés
                        </Typography></div>
                      <Typography align='center' variant="h4" color="textPrimary" component="h4">
                        {formatNumber(contrat.arrete.Nombre)}
                      </Typography>
                      <Button onClick={(e) => handleDetails(detailContrat.detailArrete, "Liste de mes contrats arrêtés")} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                    </CardContent>
                  </CardActionArea>
                </Card>
            )}
        </div>
      
        {(details && details.length !== 0 && showResult) ? (
            <MaterialTable
              columns={[
                {title:'Contrat', field:'Idcontrat',render:rowData=>(<div className={classes.tablibOper}>
                    <Typography variant='h5'>#ID : {rowData.id}</Typography>
                    <Typography variant='h5'>{rowData.Produit}</Typography>
                    <Typography variant='caption'>{`Date d\'effet :${rowData.DateEffet?rowData.DateEffet:""}`}</Typography>
                    <Typography variant='caption'>{`Date d\'échéance : ${rowData.DateEcheance?rowData.DateEcheance:""}`}</Typography>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <Typography variant='caption'>{`Prime :${parseInt(rowData.Prime) ? formatNumber(parseInt(parseInt(rowData.Prime))) : ""}`}</Typography>
                    <Typography variant='caption'>{`Capital :${parseInt(rowData.Capital) ? formatNumber(parseInt(parseInt(rowData.Capital))) : ""}`}</Typography>
                    </div>
                    <Typography variant='caption'>{`Etat : ${rowData.Etat} \n`}</Typography>
                </div>)},
                { title: 'Adhérent', field: 'Adherent',render: rowData=>(<div className={classes.tablibOper}>
                     <Typography variant='h5'> {rowData.Adherent}</Typography>
                     <Typography variant='caption'>{`Date de naissance :${rowData.DateNaissance?rowData.DateNaissance:""}`}</Typography>
                </div>)},
                { title:"Cotisation", render:rowData=>(<div className={classes.tablibOper}>
                    <Paper className={classes.pCot} >
                      <Typography variant='caption'>{`Nb d\'émission :${parseInt(rowData.NombreEmission) ? formatNumber(parseInt(rowData.NombreEmission)) : ""}`}</Typography>
                      <Typography variant='caption'>{`Mt d\'émission :${parseInt(rowData.MontantEmission) ? formatNumber(parseInt(rowData.MontantEmission)) : ""}`}</Typography>
                    </Paper>
                    <Paper className={classes.pCot}>
                      <Typography variant='caption'>{`Nb d\'enc. :${parseInt(rowData.NombreEncaissement) ? formatNumber(parseInt(rowData.NombreEncaissement)) : ""}`}</Typography>
                      <Typography variant='caption'>{`Mt d\'enc. :${parseInt(rowData.MontantEncaissement) ? formatNumber(parseInt(parseInt(rowData.MontantEncaissement))) : ""}`}</Typography>
                    </Paper>
                    <Paper className={classes.pCot}>
                      <Typography variant='caption'>{`Nb d\'impayé :${parseInt(rowData.NombreImpayes) ? formatNumber(parseInt(parseInt(rowData.NombreImpayes))) : ""}`}</Typography>
                      <Typography variant='caption'>{`Mt d\'impayé :${parseInt(rowData.MontantImpayes) ? formatNumber(parseInt(parseInt(rowData.MontantImpayes))) : ""}`}</Typography>
                    </Paper>
                </div>),
                headerStyle: {
                  textAlign: 'center'
                }    
              },
              {title:'Compte',filed:'Compte',render: rowData=>(<div>
                <div style={{display:'flex',flexDirection:'column',padding:10,justifyContent:'center'}}>
                      <Typography variant='caption'>{`Code guichet :${rowData['CodeGuichet'] || ""} \n`}</Typography><br />
                      <Typography variant='caption'>{`N° Compte :${rowData['NumCompte'] || ""}\n`}</Typography><br />
                      <Typography variant='caption'>{`Rib :${rowData['Rib'] || ""}\n`}</Typography><br />
                </div>
              </div>),
               headerStyle: {
                textAlign: 'center'
              }      
            },
                { title:"Actions", render:rowData=>(
                <div style={{display:'flex',justifyContent:'flex-end'}}>
                  <Tooltip title="Etat de cotisation">
                    <IconButton onClick={() => handleEtatCotisation(rowData)}>
                      <ListAlt />
                    </IconButton>
                  </Tooltip>
                 
                  
                </div>),
                headerStyle: {
                  textAlign: 'right'
                }  
              }
              ]}
              data={details}
              title={titleDetails}
              localization={{
                toolbar: {
                  searchPlaceholder: 'Rechercher',
                  searchTooltip: 'Rechercher'
                },
                body: {
                  emptyDataSourceMessage: 'Aucune donnée trouvée !'
                },
                pagination: {
                  labelRowsSelect: 'Ligne(s)',
                  labelDisplayedRows: '{count} sur {from}-{to}',
                  firstTooltip: 'Prémière page',
                  previousTooltip: 'Précédent',
                  nextTooltip: 'Suivant',
                  lastTooltip: 'Dernière page'
                }
              }}

              options={{
                exportButton: true,
                searchFieldStyle: {
                  fontSize: 18,
                  width: '100%',
                  height: 50,
                },
                actionsColumnIndex: -1
              }}
            />
           
            ) : (

              (!loading && (details && details.length === 0)) && (<GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Alert variant="filled" severity="error">
                    Aucune données trouvées ou l'opération à rencontrer une erreur !
                  </Alert>
                </GridItem>
              </GridContainer>)

            )}
            {loading && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress color="secondary" />
              </div>
            )}
       </div>
    </div>
  )
}



const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {
  updateField,
}
const mapStateToProps = (state) => ({
  user: state.user,
  data: getFormValues('adForm')(state),
  ui: state.UI
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({ destroyOnUnmount: false, })(ContratList))

