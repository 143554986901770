import React, { useReducer, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { reduxForm} from 'redux-form';
import { connect } from 'react-redux'
import { Divider } from "@material-ui/core";

import DashBord from 'pages/components/dash'
import { operationStatByUser } from 'redux/actions/apiActions'
const useStyles = makeStyles(theme=>({

}))

function OperationStat(props) {
    const classes = useStyles();
    const { user: { credentials } } = props
 
  
    const [currentStat, setCurrentStat] = useReducer((state, newState) => ({ ...state, ...newState }), {})
    const [currentStatSin, setCurrentStatSin] = useReducer((state, newState) => ({ ...state, ...newState }), {})
    const [currentStatPres, setCurrentStatPres] = useReducer((state, newState) => ({ ...state, ...newState }), {})
    const [currentStatMod, setCurrentStatMod] = useReducer((state, newState) => ({ ...state, ...newState }), {})
    useEffect(() => {
        let dataSend = { groupe: "ETAPE" }
        operationStatByUser(dataSend).then(res => {
            if (res) {
                let dataEl
                dataEl = res.filter(item => item.Libelle === 'nonTransmis')
                const nonTransmis = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'transmis')
                const transmis = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'accepter')
                const accepter = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'rejeter')
                const rejeter = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'annuler')
                const annuler = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                setCurrentStat({
                    ['nonTransmis']: nonTransmis,
                    ['transmis']: transmis,
                    ['accepter']: accepter,
                    ['rejeter']: rejeter,
                    ['annuler']: annuler,
                    ['total']: (Number(nonTransmis) + Number(transmis) + Number(accepter) + Number(rejeter) + Number(annuler)),
                })
            }
        })

        //Etat des traitements
        dataSend = { groupeoperation: "SIN" }
        operationStatByUser(dataSend).then(res => {
            if (res) {
                const sindata = res[0];
                setCurrentStatSin({
                    ['total']: sindata['Nombre'] || 0,
                })
            }
        })
        //Gestion des prestations
        //total prestation
        dataSend = { groupeoperation: "PRES" }
        operationStatByUser(dataSend).then(res => {
            if (res) {
                const presdata = res[0];
                setCurrentStatPres({
                    ['total']: presdata['Nombre'] || 0,
                })
            }
        })
        
        //Gestion des autres demandes
        dataSend = { groupeoperation: "MOD" }
        operationStatByUser(dataSend).then(res => {
            if (res) {
                const presdata = res[0];
                setCurrentStatMod({
                    ['total']: presdata['Nombre'] || 0,
                })
            }
        })
        
    }, [])

  return (
    <div>
        <div className={classes.rcontainer}>
            <DashBord>
                <DashBord.Base>
                    <DashBord.Inner>
                        <DashBord.Text>{currentStat['total'] || ""}</DashBord.Text>
                        <DashBord.Label>Total opérations</DashBord.Label>
                    </DashBord.Inner>
                    <Divider variant="middle" orientation="vertical" flexItem />
                    <DashBord.Inner>
                        <DashBord.Text>{currentStat['nonTransmis'] || ""}</DashBord.Text>
                        <DashBord.Label>En attente de transmission</DashBord.Label>
                    </DashBord.Inner>
                    <Divider variant="middle" orientation="vertical" flexItem />
                    <DashBord.Inner>
                        <DashBord.Text>{currentStat['transmis'] || ""}</DashBord.Text>
                        <DashBord.Label>En attente d'acceptation</DashBord.Label>
                    </DashBord.Inner>
                    <Divider variant="middle" orientation="vertical" flexItem />
                    <DashBord.Inner>
                        <DashBord.Text>{currentStat['accepter'] || ""}</DashBord.Text>
                        <DashBord.Label>En traitement</DashBord.Label>
                    </DashBord.Inner>
                    <Divider variant="middle" orientation="vertical" flexItem />
                    <DashBord.Inner>
                        <DashBord.Text>{currentStat['rejeter'] || ""}</DashBord.Text>
                        <DashBord.Label>Total prestations rejétées</DashBord.Label>
                    </DashBord.Inner>
                    <Divider variant="middle" orientation="vertical" flexItem />
                    <DashBord.Inner>
                        <DashBord.Text>{currentStat['annuler'] || ""}</DashBord.Text>
                        <DashBord.Label>Total prestations annulées</DashBord.Label>
                    </DashBord.Inner>
                </DashBord.Base>
            </DashBord>
        </div>
    </div>
  )
}

const mapPropsActions = {}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    ui: state.UI,
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({ destroyOnUnmount: false, })(OperationStat))
