import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import AlertSucces from '@material-ui/lab/Alert';
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import CheckIcon from '@material-ui/icons/Check';
const styles =(theme)=>({
    root:{
      marginBottom:10,
      padding:10
    },
    btnNext:{
      backgroundColor:theme.palette.primary.main,
      height:40,
      width:150,
      borderRadius:20,
      color:theme.palette.primary.contrastText
    },
    assureInfoRow:{
      display:'flex',
      flexDirection:'row',
      justifyContent:'flex-between'
    }
    ,
    pwarning:{
      backgroundColor:'#ff9800',
      padding:20,
      width:'%100'
    },
    chipRoot: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(1),
      marginBottom: 10,
    },
    chip: {
      margin: theme.spacing(1),
    },
  })

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  //button succès
  const SuccesButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
  }))(Button);
  //bouton error
  const ErrorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
  }))(Button);

function AlertPage({classes,message,succes,open,action,handleAlertClose}){
   
    return(<Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{
          succes?(<AlertSucces icon={<CheckIcon fontSize="inherit" />} severity="success">
            <Typography variant='h4' color={green[400]}>{message}</Typography> </AlertSucces>):(
            <AlertSucces severity="error"><Typography variant='h4' color={red[400]}>{message}</Typography></AlertSucces>
          )
        }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {succes?(
          <SuccesButton onClick={()=>handleAlertClose(action)} variant="contained" color="primary" className={classes.margin}>
          OK
        </SuccesButton>
        ):(
          <ErrorButton onClick={()=>handleAlertClose(action)} variant="contained" color="primary" className={classes.margin}>
          OK
        </ErrorButton>
        )}
        
      </DialogActions>
    </Dialog>)
}

AlertPage = withStyles(styles)(AlertPage)

export default AlertPage