import React,{useRef,useState,useEffect} from 'react'
import { Paper,Tooltip,IconButton,Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import HomeIcon from '@material-ui/icons/Home';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { connect,useDispatch} from 'react-redux'
import { reduxForm, change, getFormValues,reset} from 'redux-form';
import StepConventions from './components/StepConventions';
import StepAdherent from './components/StepAdherent';
import StepBeneficaire from './components/StepBeneficaire';
import StepConditionsPrime from './components/StepConditionsPrime';
import StepRessume from './components/StepRessume';
import StepDocuments from './components/StepDocuments';
import StepAssure from './components/StepAssure';
import {uploadDocuments,getGarantiesProduit} from 'redux/actions/apiActions'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';



const useStyle = makeStyles(theme=>({
  root:{
    width:'85%',
    marginLeft:'auto',
    marginRight:'auto'
  },
  hPaper:{
    padding:10,
    marginBottom:20,
    marginTop:30,
    borderLeftColor: theme.palette.primary.main,
    borderLeftWidth:8,
    display:'flex',
    justifyContent:'space-between'
  },
  hPaperup:{
    padding:10,
    marginBottom:20,
    marginTop:30,
    borderLeftColor: "#f9b233",
    borderLeftWidth:8,
    display:'flex',
    justifyContent:'space-between'
  },
  hContainer:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
  },
  bCorps:{
    display:'flex',
    justifyContent:'space-between',
    padding:20
  },
  formContainer:{
    width:'75%',
    padding:20
  }
}))

function  getSteps(){
  return [
    'Conventions/produit',
    'Adhérent',
    'Assuré(e)s',
    'Bénéficiaires',
    'Paiement & périodicité',
    'Documents',
    'Resumé'
  ]
}
function AddProposition(props) {
  const location = useLocation()
  const {handleFonctionShow,data,ui:{mode}} = props
  const classes = useStyle()
  let containerref =useRef()
  const dispatch =useDispatch()
  const resetForm =()=>{
    dispatch(reset('adForm'))
    dispatch({type:'SET_MODE',payload:'ADD'})
    handleFonctionShow('HOME')
  }
  const [currentDocs,setCurrentDocs] = useState(null)
  const [reloadAs,setReloadAs] =useState(false)
  const [prodGaranties,setProdGaranties] = useState(null)
  let currentProd = (data && data.hasOwnProperty('produit')) && data.produit
  const steps = getSteps()
  const [activeStep, setActiveStep] = useState(0)
  
  const handleNext = (values) => {
    if (activeStep<steps.length) {
      setActiveStep(activeStep + 1)
    }
    containerref.current.offsetParent.scrollTop=0
  };
  const handleBack = () => {
    if (activeStep > 0) {
        setActiveStep(activeStep - 1)
    } else {
        setActiveStep(0)
    }
    containerref.current.offsetParent.scrollTop=0
  };
  const handleRedirectSouscription = (data,mode) => {
    if(mode==='PAI'){
      setActiveStep(0)
      dispatch(reset('adForm'))
      handleFonctionShow(mode, Array.isArray(data) ? data[0] : data)
    }else{
      setActiveStep(0)
      dispatch(reset('adForm'))
      handleFonctionShow('HOME')
    }
   
  }
const handleSaveDocument =(id,titres)=>{

  uploadDocuments(id,currentDocs,'ES',titres).then(res=>{
   
  }).catch(err=>{
    console.log(err);
  })
}
const handleInitilaiseGarantie = ()=>{
  if (data && data.hasOwnProperty('produit')) {
    props.getGarantiesProduit(data.produit.CodeProduit).then((res) => {
        if (res) {
          setProdGaranties(res)
          setReloadAs(true)
        }else{
          setReloadAs(false)
        }
    })
  }
}

useEffect(()=>{
  handleInitilaiseGarantie()
},[currentProd])
console.log(location);
useEffect(()=>{
  if (location && location.hasOwnProperty('state')) {
    if (location?.state && location.state.hasOwnProperty('step')) {
        setActiveStep(location.state.step)
    }
  }
},[])

  return (
    <div className={classes.root} ref={containerref}>
        <Paper className={mode==='UP'?classes.hPaperup:classes.hPaper} variant="outlined">
            <div style={{display:'flex'}}>
                <Tooltip title="Retour"> 
                    <IconButton onClick={()=>handleFonctionShow('HOME')} color='primary'>
                        <HomeIcon style={{fontSize:40}} />
                    </IconButton>
                </Tooltip>
                <div className={classes.hContainer}>
                    <Typography variant='h5'>{mode==='UP'?`Modification de la proposition N° #${data['id']}`:"Ajouter une nouvelle proposition"}</Typography>
                </div>
            </div>
            <Tooltip title="Retour"> 
                <IconButton onClick={resetForm} color='primary'>
                    <RotateLeftIcon style={{fontSize:40}} />
                </IconButton>
            </Tooltip>
        </Paper>
      <div className={classes.bCorps}>
        <div className={classes.formContainer}>
            {activeStep===0&&<StepConventions handleNext={handleNext} handleBack={handleBack} steps={steps} activeStep={activeStep} />}
            {activeStep===1&&<StepAdherent handleNext={handleNext} handleBack={handleBack} steps={steps} activeStep={activeStep}  />}
            {activeStep===2&&<StepAssure handleNext={handleNext} prodGaranties={prodGaranties} reloadAs setInitAss={setReloadAs} handleBack={handleBack} steps={steps} activeStep={activeStep}  />}
            {activeStep===3&&<StepBeneficaire handleNext={handleNext} handleBack={handleBack} steps={steps} activeStep={activeStep}  />}
            {activeStep===4&&<StepConditionsPrime handleNext={handleNext} handleBack={handleBack} steps={steps} activeStep={activeStep}  />}
            {activeStep===5&&<StepDocuments setCurrentDocs ={setCurrentDocs} handleNext={handleNext} handleBack={handleBack} steps={steps} activeStep={activeStep}  />}
            {activeStep===6&&<StepRessume saveDocuments={handleSaveDocument} handleNext={handleNext} handleBack={handleBack} steps={steps} activeStep={activeStep} handleRedirectSouscription={handleRedirectSouscription}  />}
        </div>
        <div>
          <Stepper activeStep={activeStep}  orientation="vertical">
              {steps.map(label => (
                  <Step key={label}>
                      <StepLabel color='primary'>{label}</StepLabel>
                  </Step>
              ))}
          </Stepper>
        </div>
      </div>
      
    </div>
  )
}

const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {updateField,getGarantiesProduit}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    data: getFormValues('adForm')(state),
    ui: state.UI
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({
    form: 'adForm', // a unique identifier for this form
    destroyOnUnmount: false,
})(AddProposition))

