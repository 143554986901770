import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";



const useStyles = makeStyles(theme => ({
  grid: {

    [theme.breakpoints.up('sx')]: {
      padding: "0 10px !important"
    },
    [theme.breakpoints.up('sm')]: {
      padding: "0 10px !important"
    },
    [theme.breakpoints.up('md')]: {
      padding: "0 15px !important"
    },
    [theme.breakpoints.up('lg')]: {
      padding: "0 15px !important"
    }
  }
}));

export default function GridItem(props) {
  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <Grid item {...rest} className={classes.grid}>
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  children: PropTypes.node
};
