/*eslint-disable*/
import React from "react";


export default function FooterNew(props) {
  return (
    <p className="flex items-end justify-center gap-1">
      <span className="text-[14px] text-slate-300">
        Copyright &copy; 2019 - {1900 + new Date().getYear()}{" "}
        <a
          href="https://yakoafricassur.com"
          target="_blank"
          className=" text-white font-bold"
        >
          YAKO AFRICA ASSURANCES VIE 
        </a>
        {" "}
        tous droits réservés
      </span>
    </p>
  );
}
