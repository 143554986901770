import React from "react";
import {Container,Inner,Group,Text,SmallText} from './style'

export default function PretDetail({children,...restProps}){
    return <Container {...restProps}>{children}</Container>
}

PretDetail.Inner = function PretDetailInner({children,...restProps}){
    return <Inner {...restProps}>{children}</Inner>
}

PretDetail.Group = function PretDetailGroup({children,...restProps}){
    return <Group {...restProps}>{children}</Group>
}

PretDetail.Text = function PretDetailText({children,...restProps}){
    return <Text {...restProps}>{children}</Text>
}

PretDetail.SmallText = function PretDetaiSmallText({children,...restProps}){
    return <SmallText {...restProps}>{children}</SmallText>
}