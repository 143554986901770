import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Add } from "@material-ui/icons/";
import { Button } from "@material-ui/core";
import { reduxForm,  getFormValues} from 'redux-form';
import { connect } from 'react-redux'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ExtensionIcon from '@material-ui/icons/Extension';
import AccessibleForwardIcon from '@material-ui/icons/AccessibleForward';
import AssignmentIcon from '@material-ui/icons/Assignment';
const useStyles = makeStyles(theme=>({
  cardLogo:{
    display:'flex',
    flexDirection:'column',
    margin:20,
    justifyContent:'center',
    alignItems:'center'
},
img:{
    objectFit: 'fill',
    width:50,
    borderRadius:100,
    
},
eheader:{
    display:'flex',
    width:'100%',
    paddingTop:25,
    backgroundColor:theme.palette.primary.main
},
boxheader:{
    display:'flex',
    width:'60%',
    [theme.breakpoints.down('sm')]: {
        width:'100%',
    },
    [theme.breakpoints.up('md')]: {
        width:'60%',
    },
    justifyContent:'space-between',
    alignItems:'center',
    marginLeft:'auto',
    marginRight:'auto'
},
}))
function MiniHeader(props) {
  const {user: { privileges,credentials },ui: { layoutRoot },titre,showbtn,handleAction,plateforme } = props
  const {branche,partenaire,codepartenaire}=credentials
  const classes =useStyles()
  return (
    <div className={classes.eheader}>
        <div className={classes.boxheader}>
            <div className={classes.cardLogo}>
                <img className={classes.img} src={require(`assets/images/${(codepartenaire &&codepartenaire!=='')?codepartenaire+'.jpg':"LLV.jpg"}`).default} />
                <span style={{fontStyle:'bold',color:'white',fontSize:26}}>{credentials['partenaire']||""}</span>
            </div>
            <span style={{fontStyle:'bold',color:'white',fontSize:30}}>{titre}</span>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
              {showbtn&&<Button className={classes.btnAdd} onClick={handleAction}>  <Add/>Nouveau</Button>}
              {plateforme=='souscription'? <AssignmentIcon style={{ color:'white', fontSize:60 }} />:(
                plateforme=='prestation'?<ExtensionIcon style={{ color:'white', fontSize:60 }} />:(
                plateforme=='sinistre'?<AccessibleForwardIcon style={{ color:'white', fontSize:60 }} />:(
                  plateforme=='pret'?<AccountBalanceIcon style={{ color:'white', fontSize:60 }} />:null
                )
              ))}
              <span style={{color:'white',fontWeight:300}}>{plateforme=='souscription'? "E-Souscriptions":(
                plateforme=='prestation'?"E-Prestations":(
                plateforme=='sinistre'?"E-Sinistres":(
                  plateforme=='pret'?"E-Prêts":null
                )
              ))}</span>
            </div>
        </div>
    </div>
  )
}



const mapPropsActions = {}
const mapStateToProps = (state) => ({
    user: state.user,
    ui: state.UI,
    data: getFormValues('adForm')(state)
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({ destroyOnUnmount: false, })(MiniHeader))