import React, { useReducer, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from "@material-ui/core/Typography";
import MaterialTable from 'material-table';
import { connect } from 'react-redux'
import Button from 'components/CustomButtons/Button'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import { getProduitFormules, getProduitAll } from 'redux/actions/apiActions'
import { addProduitReseau, getProduitReseau, deleteProduitReseau, changeStateProduitReseau } from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import CircularProgress from '@material-ui/core/CircularProgress';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/Check';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import swal from 'sweetalert'
import ListItemText  from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        padding:10,
    },
  },
};

function ProduitReseau(props) {
    const { reseauData, open, handleClose } = props
    const classes = useStyles();
    const [produits, setProduits] = React.useState([])
    const [formules, setFormules] = React.useState([])
    const [produitRezo, setProduitRezo] = React.useState([])
    const [loading, setLoading] = useState(false)
    
    const handleChangeFormule = (event) => {
        const {
          target: { value },
        } = event;
        setInputField(
          {['codeformule']:typeof value === 'string' ? value.split(',') : value}
        );
      };


    const [inputField, setInputField] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            codeproduit:"",
            codeformule:[],
            codereseau: reseauData.id
        });

    //initialisation de la table des produits
    useEffect(() => {
        getProduitAll().then(data => { if (data) { setProduits(data); } })
        getProduitReseau(reseauData.id).then(data => { if (data) { setProduitRezo(data); } })
    }, [])

    useEffect(()=>{
        setInputField({
            ['codeformule']: []
        })
    },[inputField.codeproduit,])

    //selection des formules de produit
    const handleFormuleProduit = () => {
        if (inputField['codeproduit'] !== "") {
            getProduitFormules(inputField.codeproduit).then(data => { if (data) { setFormules(data); } })
        }
    }
    useEffect(() => {
        handleFormuleProduit()
    }, [inputField.codeproduit])

    const handleChange = (event) => {
        const { name, value } = event.target
        setInputField({
            [name]: value
        })
    }

    //sauvegarder un produit
    const handleSaveProduitReseau = () => {
        if (inputField.codeproduit === '') return
        if (inputField.codereseau === '') return
        if (inputField.codeformule && inputField.codeformule.length===0){swal("Warning","Aucune formule selectionné",'warning')}
        setLoading(true)
        addProduitReseau(inputField).then(data => {
            if (data) {
                getProduitReseau(reseauData.id).then(data => { if (data) { setProduitRezo(data); } })
                swal("Succes","Enregistrement effectué avec succès",'success')
                setLoading(false)
            } else {
                setLoading(false)
                swal("Erreur","Erreur lors de l'enregistrement",'error')
                return false
            }
        }).catch(err => {
            console.log(err);
            setLoading(false)
            swal("Erreur","Erreur lors de l'enregistrement",'error')
            return
        })
    }

    //suppression d'un produit
    const handleDeleteProduit = (id) => {
        if (id) {
            deleteProduitReseau(id).then(res => {
                if (res) {
                    getProduitReseau(reseauData.id).then(data => { if (data) { setProduitRezo(data); } })
                    swal("Succes","Suppression effectué avec succès",'success')
                } else {
                    setLoading(false)
                    swal("Erreur","Erreur lors de la suppression",'error')
                    return false
                }
            }).catch(err => {
                console.log(err);
                swal("Erreur","Erreur lors de la suppression",'error')
                return
            })
        }
    }
    
    //changer le statut d'un produit
    const handleChangeState = (id) => {
        if (id) {
            changeStateProduitReseau({ id }).then(res => {
                if (res) {
                    getProduitReseau(reseauData.id).then(data => { if (data) { setProduitRezo(data); } })
                    swal("Succes","Modification effectuée avec succès",'success')
                } else {
                    setLoading(false)
                    swal("Erreur","Erreur lors de la modification",'error')
                    return false
                }
            }).catch(err => {
                console.log(err);
                swal("Erreur","Erreur lors de la modification",'error')
                return
            })
        }
    }
    return (
        <Dialog
            maxWidth='md'
            open={open}
            disableBackdropClick
            fullWidth
            onClose={handleClose}
            aria-describedby="alert-dialog-description"
            aria-labelledby="form-dialog-title">
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Gestion des produits reseau : - #{reseauData.libelle}
                    </Typography>
                </Toolbar>
            </AppBar>
            
            <DialogContent>
                <LabelledOutline label="Nouveau">
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>

                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        variant="outlined"
                                        id="codeproduit"
                                        name='codeproduit'
                                        select
                                        label="Produit"
                                        value={inputField.codeproduit}
                                        onChange={handleChange}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ marginTop: 20, marginBottom: 10 }}
                                    >
                                        <MenuItem value='' />
                                        {(produits.length !== 0) && produits.map((option) => (
                                            <MenuItem key={option.id} value={option.CodeProduit}>
                                                {option.MonLibelle}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                <FormControl style={{ marginTop: 20, marginBottom: 10,width:'100%' }} >
                                    <InputLabel variant='outlined' id="label-codeformule">Formule</InputLabel>
                                    <Select
                                         labelId="label-codeformule"
                                         id="codeformule"
                                         multiple
                                         value={inputField.codeformule}
                                         onChange={handleChangeFormule}
                                         label='Formules'
                                         renderValue={(selected) => selected.join(', ')}
                                         variant="outlined"
                                         name='codeformule'
                                         MenuProps={MenuProps}
                                    >
                                        <MenuItem value='' />
                                        {(formules.length !== 0) && formules.map((option) => (
                                            <MenuItem key={option.CodeProduitFormule} value={option.CodeProduitFormule}>
                                                <Checkbox checked={inputField.codeformule.indexOf(option.CodeProduitFormule) > -1} />
                                                <ListItemText primary={option.MonLibelle} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Button color="primary" onClick={handleSaveProduitReseau} disabled={(inputField.codeproduit === '' || inputField.codeformule === '' || loading)} style={{ width: '100%', marginBottom: 20 }}>
                                {loading ? (<CircularProgress color="secondary" />) : ("Ajouter")}

                            </Button>
                        </GridItem>
                    </GridContainer>
                </LabelledOutline>
                <Divider />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        {(produitRezo && produitRezo.length!==0) && <MaterialTable
                                        columns={[
                                            { title: '#', field: 'id' },
                                            { title: 'Code produit', field: 'codeproduit' },
                                            { title: 'Produit', field: 'libelleproduit' },
                                            { title: 'Code version', field: 'codeproduitformule' },
                                            { title: 'Formule', field: 'libelleformule' },
                                            { title: 'Etat', field: 'estactif', render: rowData => (rowData.estactif === 1 ? "Actif" : "Bloquer") },
                                            {title:'Actions',render:rowData=>{return (
                                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                                     <IconButton aria-label="Supprimer le produit" onClick ={()=>handleDeleteProduit(rowData.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    <IconButton aria-label={rowData.estactif === 1 ? 'Bloquer le produit' : "Activer le produit"} onClick ={(event, rowData) => handleChangeState(rowData.id)}>
                                                    {rowData.estactif === 1 ? <HighlightOffIcon /> : <CheckIcon />}
                                                    </IconButton>
                                                </div>
                                            )}}
                                        ]}
                                        data={produitRezo}
                                        title="Produit associées"
                                        localization={{
                                            toolbar: {
                                                searchPlaceholder: 'Rechercher',
                                                searchTooltip: 'Rechercher'
                                            },
                                            body: {
                                                emptyDataSourceMessage: 'Aucune donnée trouvée !'
                                            },
                                            pagination: {
                                                labelRowsSelect: 'Ligne(s)',
                                                labelDisplayedRows: '{count} sur {from}-{to}',
                                                firstTooltip: 'Prémière page',
                                                previousTooltip: 'Précédent',
                                                nextTooltip: 'Suivant',
                                                lastTooltip: 'Dernière page'
                                            }
                                        }}

                                        options={{
                                            exportButton: true,
                                            searchFieldStyle: {
                                                fontSize: 18,
                                                width: '100%',
                                                height: 50,
                                            },
                                            actionsColumnIndex: -1
                                        }}
                                    />}

                    </GridItem>
                </GridContainer>
            </DialogContent>
        </Dialog>
    )
}
ProduitReseau.propTypes = {
    reseauData: PropTypes.object.isRequired,
    eopen: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    settings: state.settings,
});
const mapActionsToProps = {}
export default connect(mapStateToProps, mapActionsToProps)(ProduitReseau)