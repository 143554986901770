import React,{useState} from "react";
import { MenuItem, Menu } from "@material-ui/core";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Divider from '@material-ui/core/Divider';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateUtilisateur from './UpdateUtilisateur'
import {connect} from 'react-redux'
import {deleteUtilisateur,debloquerUser,bloquerUser } from "redux/actions/settingsAction";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {verifRule} from 'application'
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import LockIcon from '@material-ui/icons/Lock';
import ChangerEquipeUser from './ChangerEquipeUser'
import ChangerProfilUser from './ChangerProfilUser'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
function CustomMenu (props){
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ropen, setRopen] = useState(false)
  const {data,user:{privileges}} = props;     
  const handleRopen =()=>{
    setRopen(true)
    handleClose()
  }
  const handleCloseAdd =()=>{
    setRopen(false)
    handleClose()
  }
  
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }
  
  
const handleDelete=()=>{
  deleteUtilisateur(data.idmembre).then(mrx=>{
    if(mrx){props.handleFilterData()}
  })
}

//active changement d'equipe
const [eopen, setEopen] = useState(false)
const handleEopen =()=>{
  setEopen(true)
  handleClose()
}
const handleCloseEopen =()=>{
  setEopen(false)
  handleClose()
}

//active changement de profil
const [popen, setPopen] = useState(false)
const handlePopen =()=>{
  setPopen(true)
  handleClose()
}
const handleClosePopen =()=>{
  setPopen(false)
  handleClose()
}
const handlelocked =()=>{
  bloquerUser(data.idmembre).then(mrx=>{
    if(mrx){props.handleFilterData()}
  })
}
const handleUnLocked =()=>{
  debloquerUser(data.idmembre).then(mrx=>{
    if(mrx){props.handleFilterData()}
  })
}

      return(
        <React.Fragment>
         {ropen&&<UpdateUtilisateur userData={data} mopen={ropen} handleCloseMod={handleCloseAdd}/>}
         {eopen&&<ChangerEquipeUser userData={data} mopen={eopen} handleCloseMod={handleCloseEopen}/>}
         {popen&&<ChangerProfilUser userData={data} mopen={popen} handleCloseMod={handleClosePopen}/>}
       
          <IconButton
            data-id={data.id}
            aria-label="actions"
            aria-controls='long-menu'
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="card-actions-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
          {verifRule('user','update',privileges)&&(<MenuItem onClick={handleRopen}>
                <ListItemIcon>
                    <NoteAddIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Modifier</Typography>
            </MenuItem> )}
            {verifRule('user','update',privileges)&&( <MenuItem disabled>
                <Divider />
            </MenuItem>)} 
            {verifRule('user','update',privileges)&&<MenuItem disabled>
                <Divider />
            </MenuItem>}
            {verifRule('user','update',privileges)&& <MenuItem onClick={handleEopen}>
                <ListItemIcon>
                    <Rotate90DegreesCcwIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Changer d'agence</Typography>
            </MenuItem>}
            {verifRule('user','update',privileges)&&<MenuItem onClick={handlePopen}>
                <ListItemIcon>
                    <SupervisorAccountIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Changer de profil</Typography>
            </MenuItem>}
            {verifRule('user','update',privileges)&&<MenuItem disabled>
                <Divider />
            </MenuItem>}
            {(data && data.memberok===0)&& <MenuItem onClick={handlelocked}>
                <ListItemIcon>
                    <LockIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Bloquer</Typography>
            </MenuItem>}

            {(data && data.memberok===0)&&<MenuItem onClick={handleUnLocked}>
                <ListItemIcon>
                    <LockOpenIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Debloquer</Typography>
            </MenuItem>}
              
            <MenuItem disabled>
                <Divider />
            </MenuItem>
            {verifRule('user','delete',privileges)&&( <MenuItem onClick={handleDelete} >
                <ListItemIcon>
                      <DeleteIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Supprimer</Typography>
              </MenuItem>
            )}    
        </Menu>
            
      </React.Fragment>
    );
      
  }
  const mapStateToProps = (state) => ({user:state.user,});
  const mapActionsToProps = {}
  export default connect(mapStateToProps,mapActionsToProps)(CustomMenu)
