import React, { useReducer,useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from "@material-ui/core/Typography";

import {connect,useDispatch} from 'react-redux'

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';

import {createRole,getModules,getModulesReseau,addPrivilegeModule,getPrivilegeModule,addPrivilegeRole} from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import GridList from '@material-ui/core/GridList';
import FormGroup from '@material-ui/core/FormGroup';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const ExpansionPanel = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
    
  })(MuiAccordion);
  
  const ExpansionPanelSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);
const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    gridList: {
        height: 450,
      },
  }));

  function OptionsPrivileges (props){
      
      const {privilege,desactiverPrivilege} = props
      const [isChecked,setIsChecked] = useState(privilege.check)
      const handleChecked =(event)=>{
        setIsChecked(event.target.checked)
        desactiverPrivilege(privilege)
      }
      return(
        <FormControlLabel
            control={<Checkbox checked={isChecked} onClick={handleChecked} name={privilege.key} />}
            label={privilege.libelleprivilege}
        />
      )
  }
 
function AddRole(props){
    const classes = useStyles();
    const theme = useTheme();
    //intialisation des élement du state
    const {roleData,mopen,handleCloseMod,handleMopen,reseaux}=props //recuperation des valieur du props
    const dispatch = useDispatch()
    const [newRoleStep,setNewRoleStep]=React.useState(1); //gère les étape d'ajout d'un nouveau module
    const [privilegesChecked,setPrivilegesChecked] =React.useState([]); // géré la selection de module existant

    const handlePrivilegesCheked = (codemodule,coderole)=>{
        let newPrivilege =[...privilegesChecked]                         
        return getPrivilegeModule(codemodule).then(dataPrivilege=>{
            if(dataPrivilege){
                dataPrivilege.forEach(el=>{
                    if(el){
                        const thisindx = `${codemodule}_${el.id}`
                        if(newPrivilege.length!==0){
                            const indexFound = newPrivilege.findIndex(item=>item.key===thisindx)
                            if(indexFound===-1){
                                newPrivilege.push({codemodule:codemodule,coderole:coderole,codeprivilege:el.id,libelleprivilege:el.libelle,key:`${codemodule}_${el.id}`,check:true})
                            }else{
                                const currentPri = newPrivilege[indexFound]
                                
                                currentPri.check = false
                                newPrivilege.splice(indexFound, 1);
                                newPrivilege.push(currentPri)
                            }
                        }else{
                            newPrivilege.push({codemodule:codemodule,coderole:coderole,codeprivilege:el.id,libelleprivilege:el.libelle,key:`${codemodule}_${el.id}`,check:true})    
                        }
                    }
                })
            }
            return newPrivilege
        })
    } 
    const desactiverPrivilege =(row)=>{
        const newChecked = privilegesChecked
        const indexRow = newChecked.findIndex(item=>item.key===row.key)
        if(indexRow!==-1){
            const currentRow = newChecked[indexRow];
            currentRow.check= !row.check
            newChecked[indexRow] = currentRow
        }
        setPrivilegesChecked(newChecked)
    }

  const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
    role:"",
    codereseau:''
    });
    
  const handleChange = (event)=>{
    const {name,value} = event.target
    setInputField({
        [name]:value
    })
  }
 
  //selection des modules 
  const [modulesReseau, setModulesReseau] = React.useState([]);
  const handleModulesReseau =(data)=>{
      if(data){
          getModulesReseau(data.codereseau).then(datamr=>{
              if(datamr){
                  let allCheckedPri =[]
                setModulesReseau(datamr)
                const mycheData = Promise.all(datamr.map(element => {return handlePrivilegesCheked(element.id,data.id)}))
                mycheData.then(newData=>{
                    for (const value of newData) {
                        allCheckedPri =[...allCheckedPri,...value]
                      }
                    setPrivilegesChecked(allCheckedPri)
                })
              }
          })
      }
  }


  //savegade roles
  const handleSaveRole =(event)=>{
    if(inputField.codereseau==='')return
    if(inputField.role.trim()==='')return
    createRole(inputField).then(dataRole=>{
        if(dataRole){
            setInputField({
                ['role']:'',
            })
           setInputPrivilege({
               ['coderole']:dataRole.id,
               ['libellerole']:dataRole.role,
               ['codereseau']:dataRole.codereseau
           })
           handleModulesReseau(dataRole)
           setNewRoleStep(2)
        }else{
            return false
        }
        return
    }).catch(err=>{
        console.log(err);
        return
    })
    event.stopPropagation()
    return 
  }
  

//fonctions et etats lié au privilèges
const [inputPrivilege,setInputPrivilege]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
        coderole:"",
        libellerole:"",
        codereseau:""
    });
    
  const savePrivilege =() =>{
      if(privilegesChecked.length===0) return
      addPrivilegeRole(inputPrivilege.coderole,privilegesChecked).then(prilegesdata=>{
          if(prilegesdata){
            setNewRoleStep(1)
            handleModulesReseau(inputPrivilege)
          }
      }).catch(err=>{
          console.log(err);
          return
      })
  }
  
                        
//fonctions gestion du rendu des différents écrans
  const renderModuleStep =()=>(
      <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h6" className={classes.title}>Attrubution des droits & privilèges au rôle #{inputPrivilege.libellerole}</Typography>
          </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <GridList  className={classes.gridList} cols={1}>
                    {modulesReseau&&(modulesReseau.map(module=>{
                        const currentModPrivilege = privilegesChecked.filter(item=>item.codemodule===module.id)
                      
                        return(
                            <ExpansionPanel square defaultExpanded key={module.id}>
                                <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
                                    <Typography>{module.libelle.toUpperCase()}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <FormGroup row>
                                    {currentModPrivilege&&(currentModPrivilege.map(privilege=>(
                                        <OptionsPrivileges key={privilege.key} privilege={privilege} desactiverPrivilege={desactiverPrivilege} /> 
                                    )))}
                                       
                                </FormGroup>
                                   
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    }))}
                </GridList>
            
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Button  color="primary" onClick={savePrivilege} disabled={(privilegesChecked.length===0)} style={{width:'100%',marginBottom:20}}>Enregistrer</Button>
            </GridItem>
      </GridContainer>
  )
  

    return (
        <Dialog
            maxWidth='md'
            open={mopen}
            disableBackdropClick 
            disableBackdropClick
            onClose={handleCloseMod} 
            fullWidth
            aria-describedby="alert-dialog-description"
            aria-labelledby="form-dialog-title">
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleCloseMod} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Nouveau rôle 
                    </Typography>
                </Toolbar>
        </AppBar>
            <DialogContent>
          
                {(newRoleStep===2)?(
                    renderModuleStep()
                ):(
                    
                    <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <CustomInput
                                            autoFocus
                                            required
                                            variant="outlined"
                                            id="role"
                                            name ='role'
                                            onChange={handleChange}
                                            label="Libelle role"
                                            type="text"
                                            value = {inputField.role}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <TextField
                                            variant="outlined"
                                            id="codereseau"
                                            name ='codereseau'
                                            select
                                            label="Réseau de commercialisation"
                                            value={inputField.codereseau}
                                            onChange={handleChange}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{marginTop:20,marginBottom:10}}
                                            >
                                            {(reseaux.length !==0)&& reseaux.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                {option.libelle}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Button  color="primary" onClick={handleSaveRole} disabled={(inputField.role==='' || inputField.codereseau==='')} style={{width:'100%',marginBottom:20}}>Ajouter</Button>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>  
                   
                )}
                
            </DialogContent>
      </Dialog>
    )
}

const mapStateToProps = (state) => ({
    reseaux:state.settings.reseaux
});
  const mapActionsToProps = {}
export default connect(mapStateToProps,mapActionsToProps)(AddRole)