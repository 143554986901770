import React,{useState,useEffect} from 'react'
import { makeStyles,withStyles } from '@material-ui/styles'
import { Paper,Typography,Divider, Button,Tooltip,IconButton } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import AddIcon from '@material-ui/icons/Add';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MaterialTable from 'material-table';
import {getProductionAgentParProduit,getTotProductionAgent} from 'redux/actions/apiActions'
import { useDispatch,useSelector } from 'react-redux';
import { connect } from 'react-redux';
import HomeIcon from '@material-ui/icons/Home';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import AirlineSeatIndividualSuiteIcon from '@material-ui/icons/AirlineSeatIndividualSuite';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Badge from '@material-ui/core/Badge';
import Header from 'pages/Commons/components/Header';
const useStyle = makeStyles(theme=>({
    root:{
        
        marginLeft:'auto',
        marginRight:'auto',
    },
    ppaper:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding:20,
        marginBottom:25
    },
    ppaperItem:{
        display:'flex',
        flex:1,
        flexDirection:'column',
        alignItems:'center',
        margin:10,
    },
    textegreen:{
        color:theme.palette.primary.main,
    },
    texteblue:{
        color:"#00FF00",
    },
    textered:{
        color:red[400],
    },
    texteyel:{
        color:'#f9b233',
    },
    actionContainer:{
        display:'flex',
        justifyContent:'center',
        marginTop:20,
        flexWrap:'wrap'
    },
    btnadd:{
        display:'flex',
        flex:1,
        paddingLeft:20,
        paddingRight:20,
        paddingTop:10,
        paddingBottom:10,
        backgroundColor:'#fff',
        border:'1px solid #f6f6f6',
        margin:20,
        borderRadius:10,
        '&:hover':{
            backgroundColor:theme.palette.primary.main,
            color:'#fff',
        }

    },
    incorpContainer:{
        display:'flex',
        justifyContent:'center',
        marginTop:25,
        marginBottom:20,
        marginLeft:20,
        marginRight:20,
        padding:20
    },
    incorpTable:{
        display:'flex',
        flex:1,
        marginTop:20,
        justifyContent:'center',
       
    },
    incorppaper:{
        padding:20,
        borderRadius:10,
        marginRight:20 
    },
    tabTitle:{
        marginBottom:10
    },
    hPaper:{
      padding:10,
      marginBottom:20,
      borderLeftColor: theme.palette.primary.main,
      borderLeftWidth:8,
      display:'flex',
      justifyContent:'space-between'
    },
    hContainer:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
      padding:20
    },
}))


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function OperationAccueil(props) {
  const classes = useStyle()
    const {handleCliqueFonction,user: { credentials }} = props
    const dispatch =useDispatch()
    const handleNewProposition =(e)=>{
        e.preventDefault()
        handleCliqueFonction('ADD',null)
        dispatch({ type: 'SET_MODE',payload:'ADD' })
    }
    const handleNewOperation =()=>{

    }
  return (
    <div className={classes.root}>
        <Header  title="Gestion des prestations" />
        <div className='flex flex-col m-4'>
        <Typography variant='h5'> Actions</Typography>
      <Divider />
      <div className={classes.actionContainer}>
            <Button className={classes.btnadd} onClick={event => handleCliqueFonction('ADD', 'PRESS')}>
                <AddCircleOutlineIcon fontSize='large'/> Nouvelle prestation
            </Button>
          
            <Button className={classes.btnadd} onClick={event => handleCliqueFonction('ADD', 'SIN')}>
                <AirlineSeatIndividualSuiteIcon  fontSize='large' /> Déclarer un sinistre
            </Button>
            <Button className={classes.btnadd} onClick={event => handleCliqueFonction('ADD', 'MOD')}>
                <EditIcon fontSize='large'/> Demander une modification
            </Button>
            <Button className={classes.btnadd} onClick={()=>handleCliqueFonction('LIST')}>
                <ListAltIcon fontSize='large' /> Liste des opérations
            </Button>
      </div>
      <div className={classes.incorpContainer}>
        <Paper className={classes.incorppaper} variant="outlined">
            <Typography className={classes.tabTitle} variant='h5'>Dernières opérations</Typography>
            <Divider />
            <div className={classes.incorpTable}>
                <MaterialTable 
                    columns={[
                        
                    ]}
                    data={[]}
                    localization={{
                        body: {
                          emptyDataSourceMessage: 'Aucune donnée trouvée !'
                        },
                        pagination: {
                          labelRowsSelect: 'Ligne(s)',
                          labelDisplayedRows: '{count} sur {from}-{to}',
                          firstTooltip: 'Prémière page',
                          previousTooltip: 'Précédent',
                          nextTooltip: 'Suivant',
                          lastTooltip: 'Dernière page'
                        }
                      }}
                    options={{
                        exportButton: false,
                        toolbar:false,
                        actionsColumnIndex: -1,
                        paging:false
                      }}
                />
            </div>
        </Paper>
        <Paper className={classes.incorppaper} variant="outlined">
            <Typography className={classes.tabTitle} variant='h5'>Dernier traitements</Typography>
            <Divider />
            <div className={classes.incorpTable}>
                <MaterialTable 
                    columns={[
                    
                    ]}
                    data={[]}
                    localization={{
                        body: {
                          emptyDataSourceMessage: 'Aucune donnée trouvée !'
                        },
                        pagination: {
                          labelRowsSelect: 'Ligne(s)',
                          labelDisplayedRows: '{count} sur {from}-{to}',
                          firstTooltip: 'Prémière page',
                          previousTooltip: 'Précédent',
                          nextTooltip: 'Suivant',
                          lastTooltip: 'Dernière page'
                        }
                      }}
                    options={{
                        exportButton: false,
                        toolbar:false,
                        actionsColumnIndex: -1,
                        paging:false
                      }}
                />
            </div>
        </Paper>
        <Paper className={classes.incorppaper} variant="outlined">
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableBody>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          Opérations en attente
                        </StyledTableCell>
                        <StyledTableCell align="right"> <Badge badgeContent={10} color="primary" /></StyledTableCell>
                        <StyledTableCell align="right">
                          <Tooltip title="détails"> 
                            <IconButton color='primary'>
                                <KeyboardArrowDownIcon />
                            </IconButton>
                          </Tooltip>
                      </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          Transmis
                        </StyledTableCell>
                        <StyledTableCell align="right"> <Badge badgeContent={10} color="primary" /></StyledTableCell>
                        <StyledTableCell align="right">
                          <Tooltip title="détails"> 
                            <IconButton color='primary'>
                                <KeyboardArrowDownIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          En traitement
                        </StyledTableCell>
                        <StyledTableCell align="right"> <Badge badgeContent={10} color="primary" /></StyledTableCell>
                        <StyledTableCell align="right">
                          <Tooltip title="détails"> 
                            <IconButton color='primary'>
                                <KeyboardArrowDownIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          En traitement
                        </StyledTableCell>
                        <StyledTableCell align="right"> <Badge badgeContent={10} color="primary" /></StyledTableCell>
                        <StyledTableCell align="right">
                          <Tooltip title="détails"> 
                            <IconButton color='primary'>
                                <KeyboardArrowDownIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
      </div>
      </div>
    </div>
  )
}
const mapActionsToProps = {}

const mapStateProps =(state)=>({
  api:state.api,
  user:state.user
})
export default connect(mapStateProps,mapActionsToProps)(OperationAccueil)
