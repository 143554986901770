import React, { useEffect,useState } from 'react'
import { Paper,Divider,IconButton } from '@material-ui/core'
import { makeStyles,withStyles } from '@material-ui/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import PrintIcon from '@material-ui/icons/Print';
import fdec from 'assets/pdf/fiche_de_declaration_sinistre.pdf';
import fren from "assets/pdf/fiche_de_renseignement.pdf";
import modFiche from "assets/pdf/fiche_demande_ modification.pdf";
import {red} from '@material-ui/core/colors'

const useStyles = makeStyles(theme=>({
    core:{
        padding:20,
        marginBottom:20
    },
    heaer:{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      backgroundColor:red[100],
      borderRadius:10,
      padding:10,
      paddingTop:20,
      '& p':{
        fontSize:'1.2rem',
        fontWeight:400,
        color:red[700],
        marginBottom:20
      } 
    }
}))

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  const docs ={
    "SIN":[
        {"code":1,"libelle":"Fiche de déclaration de sinistre","lien":fdec,"ob":true},
        {"code":2,"libelle":"Fiche de renseignement","lien":fren,"ob":false},
        ],
    "PRES":[
        {"code":1,"libelle":"Fiche de demande de prestation","lien":"","ob":false},
        {"code":2,"libelle":"Fiche de renseignement","lien":"","ob":false},
     ],
    "MOD":[
        {"code":1,"libelle":"Fiche de demande de modification","lien":modFiche,"ob":true},
    ]
  }
function FicheDeclaration({mode}) {
    const classes = useStyles()
    const [currentDocs,setCurrentDocs] = useState(null)
    useEffect(()=>{
        setCurrentDocs(docs[mode])
    },[mode])

    const handlePrintDoc =(lien)=>{
       window.open(lien)
       return false;
    }
 
  return (
    <div>
        <Paper className={classes.core}>
            <div className={classes.heaer}>
                  <p>Veuillez imprimer la fiche de déclaration / fiche de demande et la faire remplir par le client avant de poursuivre l'opération</p>
            </div>
            <Divider style={{marginBottom:20}} />

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableBody>
                    {currentDocs&& currentDocs.map(doc=>(
                        <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {doc.libelle.toUpperCase()}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            <IconButton onClick={()=>handlePrintDoc(doc.lien)}>
                                <PrintIcon />
                            </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
        </Paper>
    </div>
  )
}

export default FicheDeclaration