import React,{useState,useEffect,useReducer} from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { reduxForm,change,getFormValues,reset } from 'redux-form';
import {connect} from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { green, pink } from '@material-ui/core/colors';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { Divider, Paper } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import {getProducteurData} from 'redux/actions/apiActions'
import {formatNumber} from 'application'

const useStyles = makeStyles((theme) =>({
    root: {
      marginBottom:20,
      '& :hover':{
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        padding: '10 30px',
        textDecoration:'none'
      }
    },
    chip: {
        margin: theme.spacing(0.5),
        fontSize:20
      },
    media: {
      height: 140,
    },
    avatar: {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        alignSelf:'center'
      },
      pink: {
        color:theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },
      green: {
        color: '#fff',
        backgroundColor: green[500],
      },
      large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
      },
      line:{
          marginBottom:20
      },
      buttonDetails:{
        width:'100%',
        borderWidth:1,
        borderColor:theme.palette.secondary.main,
        backgroundColor:'#fff',
        color:theme.palette.secondary.main,
        '&:hover':{
          borderColor:theme.palette.primary.main,
        }
      },
  }));
function PretsHome(props){
    const classes = useStyles();
    const {handleCliqueFonction}=props
    const [producteurData,setProducteurData]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
        propoEnAttente:"",
        propoEnAttenteDteials:"",
        propoAccepterNM:"",
        propoAccepterNMDetails:'',
        propoAccepterM:"",
        propoAccepterMdetails:'',
        propoRejeter:"",
        propoRejeterDetails:'',
        total:0 
    });
    const displayProducteurDetail=(mode)=>{
        let dataDetails=[]
        let titre=""
        switch (mode) {
            case 'ENATTENTE':
                dataDetails = producteurData.propoEnAttenteDteials
                titre="Liste des propositions saisies non transmis"
                break;
            case 'ACCEPTERNM':
                dataDetails = producteurData.propoAccepterNMDetails
                titre="Liste des propositions en attente de validation"
                break;
            case 'ACCEPTERM':
                dataDetails = producteurData.propoAccepterMdetails
                titre="Liste des propositions acceptées migré"
                break;
            case 'REJETER':
                dataDetails = producteurData.propoRejeterDetails
                titre="Liste des propositions réjétées"
                break;
            default:
                break;
        }
        props.history.push('/producteur/proposition-details',{mode:mode,details:dataDetails,titre:titre})
    }
    useEffect(() => {
        getProducteurData().then(resData=>{
            console.log(resData);
            if(resData){
                const {propoEnAttente,propoEnAttenteDetails,propoAccepterNM,propoAccepterNMDetails,propoAccepterM,propoAccepterMDetails,propoRejeter,propoRejeterDetails} = resData.dataProduction
                setProducteurData({
                    ['propoEnAttente']:propoEnAttente,
                    ['propoEnAttenteDteials']:propoEnAttenteDetails,
                    ['propoAccepterNM']:propoAccepterNM,
                    ['propoAccepterNMDetails']:propoAccepterNMDetails,
                    ['propoAccepterM']:propoAccepterM,
                    ['propoAccepterMdetails']:propoAccepterMDetails,
                    ['propoRejeter']:propoRejeter,
                    ['propoRejeterDetails']:propoRejeterDetails
                });
            }
        })
    }, [])
    return(
        <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Typography varaiant='h2' component='h2'>GESTION DES PRETS</Typography>
                    <Divider light className={classes.line} />
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><HourglassFullIcon /></Avatar></div>
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                        En attentes de validations
                                        </Typography>
                                        <Button className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><HourglassFullIcon /></Avatar></div>
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                        Prêts  validées
                                        </Typography>
                                        <Button className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><HourglassFullIcon /></Avatar></div>
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                        Prêts  mis en place
                                        </Typography>
                                        <Button className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><HourglassFullIcon /></Avatar></div>
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                        Prêts  clôturés
                                        </Typography>
                                        <Button className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><HourglassFullIcon /></Avatar></div>
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                        Prêts réjétés
                                        </Typography>
                                        <Button className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
        </GridContainer>
    )
}


const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapPropsActions ={
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user,
    data:getFormValues('adForm')(state)
  });

  export default connect(mapStateToProps,mapPropsActions)(reduxForm({destroyOnUnmount: false,})(PretsHome))