import React, { useState,useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import {reduxForm,change,registerField,getFormValues} from 'redux-form';
import { connect } from 'react-redux';
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Typography from '@material-ui/core/Typography';
import CustomRadio from 'components/CustomRadio/CustomRadio'
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect'
import { makeStyles } from '@material-ui/core/styles';
import {formatNumber} from 'application';
import {verifRule} from 'application'
import TitleBox from 'components/TitleBox';
import swal from 'sweetalert';
import {Checkbox, FormControlLabel, FormGroup, MenuItem,  TextField } from '@material-ui/core';
import CustomDate from 'components/CustomDate/CustomDate';
import dayjs from 'dayjs';
import {getPrimesCapital,getPrimePrincipales} from 'redux/actions/apiActions'
import CustomCapitalSelect from 'components/CustomCapitalSelect/CustomCapitalSelect';
import { ageAssure } from 'redux/actions/userActions';
import { getPrimeGarantie } from 'redux/actions/apiActions';
import { getGarantiesAssure } from 'redux/actions/apiActions';
import { compareWithObject } from 'application';
import { getPrimeReference } from 'redux/actions/apiActions';
const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    
    buttonAdd:{
      width:'100%',
      marginBottom:20,
      borderWidth:1,
      borderStyle:'dashed',
      borderColor:theme.palette.primary.main,
      backgroundColor:theme.palette.primary.main,
     
      color:'#FFF',
      '&:hover':{
        borderColor:theme.palette.secondary.main,
      }
    },
    textItem:{
        marginRight:10
    },
    formControl:{
        marginTop:20
    },
    prime:{
        fontSize:30,
        color:theme.palette.primary.main
    },
  }));
 
function Simulateur(props){
    const classes = useStyles();
    const [inputField,setInputField] =useState({
        produit:'',
        datenaissanceassure:'',
        ageassure:'',
        capital:'',
        montantrente:'',
        periodiciterente:'',
        dureerente:'',
        duree:'',
        periodicite:'',
        CodeProduit:'',
        prime:0,
        isValid:false,
        isEvaluate:false,
        remboursementok:false,
        conjointok:false,
        enfantok:false,
        addsenior:false,
        sureteok:false,
        decesaccidentelok:false,

    }) 
    const [capitaux,setCapitaux] =useState([]) 
    const {api:{produits},ui:{layoutRoot}} = props  
    const handleChange =(event)=>{
        const {value,name} = event.target
        setInputField({
            ...inputField,
            [name]:value
        })
    }
    const handleChangeProd=(event)=>{
        const {value,name} = event.target
        setInputField({
            ...inputField,
            [name]:value,
            'produit':(produits && produits.length!==0)&&produits.filter(item=>item.CodeProduitFormule=== value)[0]
        })
    }
    const handleChangeSelect =(event,name)=>{
        const {value} = event.target
        setInputField({
            ...inputField,
            [name]:value
        })
    }

    //mise à jour du titre
    useEffect(() => {document.title ="YNOV - Simulateur"})

   
    
    const [loadingui,setLoading] = React.useState(false)

    const EvaluerLaprime = (e)=>{
        e.preventDefault()
        setLoading(true)
        setInputField({...inputField,isEvaluate:false})
        const produit = {...inputField.produit}
        let garantieListe =[]
        let infoxprime
        let detailPrime=[]
        switch (inputField.produit.CodeProduit) {
            case 'YKE_2008':
            case 'YKE_2018':
            case 'YKS_2008':
            case 'YKF_2008':    
            case 'DOIHOO':    
            case 'CAD_EDUCPLUS': 
            case 'YKR_2021':
            case 'YKS_2018':   
                  
                    if(!inputField.datenaissanceassure || !dayjs(inputField.datenaissanceassure).isValid()){
                        setErrors([...errors,{datenaissanceassure:"Date de naissance incorrect"}])
                    }
                    if(!inputField.capital || inputField.capital==0){
                        setErrors([...errors,{capital:"Capital souscrit incorrect"}])
                    }
                    if(!inputField.periodicite || inputField.periodicite.trim()===''){
                        setErrors([...errors,{periodicite:"Périodicité incorrect"}])
                    }
                    if(inputField.duree && (!inputField.duree || inputField.duree==0)){
                        setErrors([...errors,{duree:"Durée"}])
                    }
                    
                    if(produit){
                        props.getGarantiesAssure(produit['CodeProduit'],null).then(async (garanties)=>{
                            garanties.sort((a,b)=>compareWithObject(a,b,'estprincipal'))
                            garantieListe = [...garanties]
                            if(['YKF_2008'].includes(produit.CodeProduit) && !inputField.conjointok){
                               
                                garantieListe = garantieListe.filter(item=>item.codeproduitgarantie!=='OBSQCONJT')
                            }

                            if(['YKF_2008'].includes(produit.CodeProduit) && !inputField.enfantok){
                                garantieListe = garantieListe.filter(item=>item.codeproduitgarantie!=='OBSQENFT')
                            }
                            
                            if(['YKF_2008','YKS_2008'].includes(produit.CodeProduit) && !inputField.remboursementok){
                                garantieListe = garantieListe.filter(item=>item.codeproduitgarantie!=='REMB')
                            }

                           
                            if(garantieListe && garantieListe.length!==0){
                                const allGaranties= await Promise.all(garantieListe.map(async (garantie)=>{
                                   
                                    const params={
                                        CodeProduit:produit.CodeProduit,
                                        CodeProduitFormule:['YKE_2008','YKS_2008','YKF_2008','CAD_EDUCPLUS'].includes(produit.CodeProduit) ? null :  produit.CodeProduitFormule,
                                        codePeriodicite:inputField.periodicite,
                                        duree:inputField.duree,
                                        capitalSouscrit:parseInt(inputField.capitalSouscrit),
                                        age: garantie.codeproduitgarantie =='OBSQENFT' ? 12 : ageAssure(dayjs(inputField.datenaissanceassure).format('YYYY-MM-DD')),
                                        codeGarantie:garantie.codeproduitgarantie,
                                        dateEffet:dayjs().add(1,'month').set('date', 1).format('YYYY-MM-DD')
                                    }

                                    if(['YKS_2008','YKF_2008'].includes(produit.CodeProduit) && garantie.codeproduitgarantie ==='REMB'){
                                        if(inputField.hasOwnProperty(`prime-garantie-${garantie.codeproduitgarantie}`) && inputField[`prime-garantie-${garantie.codeproduitgarantie}`]!==""){
                                            infoxprime={
                                                prime:  inputField[`prime-garantie-${garantie.codeproduitgarantie}`],
                                                capitalGarantie: 0,
                                                primeTotal:parseInt( inputField[`prime-garantie-${garantie.codeproduitgarantie}`]) ,
                                                codeproduitgarantie:garantie.codeproduitgarantie,
                                                libelle:garantie.libelle
                                            }
                                        }else{
                                            infoxprime = await getPrimeReference({
                                                CodeProduit: produit.CodeProduit,
                                                periodicite: inputField.periodicite,
                                                duree: inputField.duree,
                                                capitalgarantie: parseInt(inputField.capitalSouscrit),
                                                age: ageAssure(dayjs(inputField.datenaissanceassure).format('YYYY-MM-DD')),
                                                codeGarantie: garantie.codeproduitgarantie
                                              }).then(garPrime=>{
                                                 if(garPrime && garPrime.length!==0){
                                                    
                                                     return {
                                                         prime: garPrime[0].Prime,
                                                         capitalGarantie: garPrime[0].Capital,
                                                         primeTotal:parseInt(garPrime[0].Prime),
                                                         codeproduitgarantie:garantie.codeproduitgarantie,
                                                         libelle:garantie.libelle
                                                     }
                                                 }
                                              })
                                        }
                                       
                                    }else{
                                        infoxprime = await getPrimeGarantie(params).then(garPrime=>{
                                            if(garPrime && garPrime.length!==0){
                                                return {
                                                    prime: garPrime[0].Prime,
                                                    capitalGarantie: garPrime[0].Capital,
                                                    primeTotal:parseInt(garPrime[0].Prime),
                                                    codeproduitgarantie:garantie.codeproduitgarantie,
                                                    libelle:garantie.libelle
                                                }
                                            }
                                        })
                                    }

                                    return infoxprime&&{...garantie,...infoxprime}
                                }))
                                setLoading(false)
                               
                                if(allGaranties){
                                    let maPrime =0
                                    allGaranties.forEach(el=>{
                                        if(el){
                                            maPrime +=el['primeTotal']
                                            detailPrime.push({code:el['codeproduitgarantie'],libelle:el['libelle'],prime:el['primeTotal']})
                                        }
                                    })
                                    inputField.addsenior && (maPrime = parseInt(maPrime)+parseInt(inputField.primesenior))
                                    setInputField({...inputField,'prime':maPrime,details:detailPrime,'fraisadhesion':7500,'isEvaluate':true})
                                }
                            }
                        })
                    }else{
                        setErrors([...errors,{produit:"Erreur lors de la récupération du produit"}])
                    }
                    

                    if(errors && errors.length!==0){
                        setLoading(false)
                        console.log(errors);
                        return
                    }
                
                break;
            case 'CADENCE':
                if(inputField.sureteok && (!inputField.datenaissanceassure || !dayjs(inputField.datenaissanceassure).isValid())){
                    setErrors([...errors,{datenaissanceassure:"Date de naissance incorrect"}])
                }
                if(inputField.sureteok && (!inputField.capital || inputField.capital==0)){
                    setErrors([...errors,{capital:"Capital souscrit incorrect"}])
                }
                if(!inputField.periodicite || inputField.periodicite.trim()===''){
                    setErrors([...errors,{periodicite:"Périodicité incorrect"}])
                }
                if(inputField.duree && (!inputField.duree || inputField.duree==0)){
                    setErrors([...errors,{duree:"Durée"}])
                }
                if(inputField.prime && (!inputField.prime || inputField.prime==0)){
                    setErrors([...errors,{duree:"Durée"}])
                }
                if(produit){
                    props.getGarantiesAssure(produit['CodeProduit'],null).then(async (garanties)=>{
                        garanties.sort((a,b)=>compareWithObject(a,b,'estprincipal'))
                        garantieListe = [...garanties]
                        if(garantieListe && garantieListe.length!==0){
                            const allGaranties= await Promise.all(garantieListe.map(async (garantie)=>{

                                if(inputField.sureteok){
                                    const params={
                                        CodeProduit:produit.CodeProduit,
                                        CodeProduitFormule:null,
                                        codePeriodicite:inputField.periodicite,
                                        duree:inputField.duree,
                                        capitalSouscrit:parseInt(inputField.capitalSouscrit),
                                        age:ageAssure(dayjs(inputField.datenaissanceassure).format('YYYY-MM-DD')),
                                        codeGarantie:garantie.codeproduitgarantie,
                                        dateEffet:dayjs().add(1,'month').set('date', 1).format('YYYY-MM-DD')
                                    }
                                    if(garantie.codeproduitgarantie ==='SUR'){
                                        infoxprime = await getPrimeGarantie(params).then(garPrime=>{
                                            if(garPrime && garPrime.length!==0){
                                                return {
                                                    prime: garPrime[0].Prime,
                                                    capitalGarantie: garPrime[0].Capital,
                                                    primeTotal:parseInt(garPrime[0].Prime),
                                                    codeproduitgarantie:garantie.codeproduitgarantie,
                                                    libelle:garantie.libelle
                                                }
                                            }
                                        })
                                       
                                    }else if(garantie.codeproduitgarantie ==='DECESACC'){
                                        if(inputField.decesaccidentelok){
                                            const primeDescesAcc = parseInt(inputField.prime)-(parseInt(inputField.prime)*0.95)
                                            infoxprime={
                                                prime: primeDescesAcc,
                                                capitalGarantie: inputField.capitalSouscrit,
                                                primeTotal:primeDescesAcc,
                                                codeproduitgarantie:garantie.codeproduitgarantie,
                                                libelle:garantie.libelle
                                            }
                                        }else{
                                            infoxprime={
                                                prime: 0,
                                                capitalGarantie: inputField.capitalSouscrit,
                                                primeTotal:0,
                                                codeproduitgarantie:garantie.codeproduitgarantie,
                                                libelle:garantie.libelle
                                            }
                                        }
                                        
                                    }else{
                                        const primeGar = (parseInt(inputField.prime)/2)
                                        infoxprime={
                                            prime: primeGar,
                                            capitalGarantie: inputField.capitalSouscrit,
                                            primeTotal:primeGar,
                                            codeproduitgarantie:garantie.codeproduitgarantie,
                                            libelle:garantie.libelle 
                                        }
                                    }
                                }else{
                                    if(!['DECESACC','SUR'].includes(garantie.codeproduitgarantie)){
                                        const primeGar = (parseInt(inputField.prime)/2)
                                        infoxprime={
                                            prime: primeGar,
                                            capitalGarantie: inputField.capitalSouscrit,
                                            primeTotal:primeGar,
                                            codeproduitgarantie:garantie.codeproduitgarantie,
                                            libelle:garantie.libelle 
                                        }
                                    }
                                }
                                
                                return infoxprime&&{...garantie,...infoxprime}
                            }))

                            setLoading(false)
                            if(allGaranties){
                                let maPrime =0
                                allGaranties.forEach(el=>{
                                    if(el){
                                        maPrime +=el['primeTotal']
                                        detailPrime.push({"code":el['codeproduitgarantie'],"libelle":el['libelle'],"prime":el['primeTotal']})
                                    }
                                
                                })
                                inputField.addsenior && (maPrime = parseInt(maPrime)+parseInt(inputField.primesenior))
                                setInputField({...inputField,'prime':maPrime,details:detailPrime,'fraisadhesion':7500,'isEvaluate':true})
                            }
                        }
                    })
                }else{
                    setErrors([...errors,{produit:"Erreur lors de la récupération du produit"}])
                }
                if(errors && errors.length!==0){
                    setLoading(false)
                    console.log(errors);
                    return
                }
                break;
            case 'PFA_IND':
            case 'PFA_COL':
                if(inputField.sureteok && (!inputField.datenaissanceassure || !dayjs(inputField.datenaissanceassure).isValid())){
                    setErrors([...errors,{datenaissanceassure:"Date de naissance incorrect"}])
                }
                if(inputField.sureteok && (!inputField.capital || inputField.capital==0)){
                    setErrors([...errors,{capital:"Capital souscrit incorrect"}])
                }
                if(!inputField.periodicite || inputField.periodicite.trim()===''){
                    setErrors([...errors,{periodicite:"Périodicité incorrect"}])
                }
                if(inputField.duree && (!inputField.duree || inputField.duree==0)){
                    setErrors([...errors,{duree:"Durée"}])
                }
                if(inputField.prime && (!inputField.prime || inputField.prime==0)){
                    setErrors([...errors,{duree:"Durée"}])
                }
                if(produit){
                    props.getGarantiesAssure(produit['CodeProduit'],null).then(async (garanties)=>{
                        garanties.sort((a,b)=>compareWithObject(a,b,'estprincipal'))
                        garantieListe = [...garanties]
                        if(garantieListe && garantieListe.length!==0){
                            const allGaranties= await Promise.all(garantieListe.map(async (garantie)=>{

                                if(inputField.sureteok){
                                    const params={
                                        CodeProduit:produit.CodeProduit,
                                        CodeProduitFormule:null,
                                        codePeriodicite:inputField.periodicite,
                                        duree:inputField.duree,
                                        capitalSouscrit:parseInt(inputField.capitalSouscrit),
                                        age:ageAssure(dayjs(inputField.datenaissanceassure).format('YYYY-MM-DD')),
                                        codeGarantie:garantie.codeproduitgarantie,
                                        dateEffet:dayjs().add(1,'month').set('date', 1).format('YYYY-MM-DD')
                                    }
                                    if(garantie.codeproduitgarantie ==='SUR'){
                                        infoxprime = await getPrimeGarantie(params).then(garPrime=>{
                                            if(garPrime && garPrime.length!==0){
                                                return {
                                                    prime: garPrime[0].Prime,
                                                    capitalGarantie: garPrime[0].Capital,
                                                    primeTotal:parseInt(garPrime[0].Prime),
                                                    codeproduitgarantie:garantie.codeproduitgarantie,
                                                    libelle:garantie.libelle
                                                }
                                            }
                                        })
                                       
                                    }else if(garantie.codeproduitgarantie ==='DECESACC'){
                                        if(inputField.decesaccidentelok){
                                            const primeDescesAcc = parseInt(inputField.prime)-(parseInt(inputField.prime)*0.95)
                                            infoxprime={
                                                prime: primeDescesAcc,
                                                capitalGarantie: inputField.capitalSouscrit,
                                                primeTotal:primeDescesAcc,
                                                codeproduitgarantie:garantie.codeproduitgarantie,
                                                libelle:garantie.libelle
                                            }
                                        }else{
                                            infoxprime={
                                                prime: 0,
                                                capitalGarantie: inputField.capitalSouscrit,
                                                primeTotal:0,
                                                codeproduitgarantie:garantie.codeproduitgarantie,
                                                libelle:garantie.libelle
                                            }
                                        }
                                        
                                    }else{
                                        const primeGar = (parseInt(inputField.prime)/2)
                                        infoxprime={
                                            prime: primeGar,
                                            capitalGarantie: inputField.capitalSouscrit,
                                            primeTotal:primeGar,
                                            codeproduitgarantie:garantie.codeproduitgarantie,
                                            libelle:garantie.libelle 
                                        }
                                    }
                                }else{

                                    if(garantie.codeproduitgarantie==='PERF'){
                                        const primegar = (parseInt(inputField.prime)*(40/100))
                                        infoxprime={
                                            prime: primegar,
                                            capitalGarantie: inputField.capitalSouscrit,
                                            primeTotal:primegar,
                                            codeproduitgarantie:garantie.codeproduitgarantie,
                                            libelle:garantie.libelle 
                                        }
                                    }else if(garantie.codeproduitgarantie==='SECU'){
                                        const primegar = (parseInt(inputField.prime)*(60/100))
                                        infoxprime={
                                            prime: primegar,
                                            capitalGarantie: inputField.capitalSouscrit,
                                            primeTotal:primegar,
                                            codeproduitgarantie:garantie.codeproduitgarantie,
                                            libelle:garantie.libelle 
                                        }
                                    }else{
                                        infoxprime={
                                            prime: 0,
                                            capitalGarantie: inputField.capitalSouscrit,
                                            primeTotal:0,
                                            codeproduitgarantie:garantie.codeproduitgarantie,
                                            libelle:garantie.libelle 
                                        }
                                    }
                                }
                                
                                return infoxprime&&{...garantie,...infoxprime}
                            }))

                            setLoading(false)
                            if(allGaranties){
                                let maPrime =0
                                allGaranties.forEach(el=>{
                                    if(el){
                                        maPrime +=el['primeTotal']
                                        detailPrime.push({"code":el['codeproduitgarantie'],"libelle":el['libelle'],"prime":el['primeTotal']})
                                    }
                                
                                })
                                inputField.addsenior && (maPrime = parseInt(maPrime)+parseInt(inputField.primesenior))
                                setInputField({...inputField,'prime':maPrime,details:detailPrime,'fraisadhesion':7500,'isEvaluate':true})
                            }
                        }
                    })
                }else{
                    setErrors([...errors,{produit:"Erreur lors de la récupération du produit"}])
                }
                if(errors && errors.length!==0){
                    setLoading(false)
                    console.log(errors);
                    return
                }
                break;
            default:
                break;
        }
       
                
        }
   
    const nouvelleSouscription =  ()=>{
       
        if(!props.data || !props.data.hasOwnProperty('primePrincipale')){
            props.dispatch(registerField("adForm",'primePrincipale','Field'));
        }
        props.updateField('primePrincipale',inputField.prime)
       

        if(!props.data || !props.data.hasOwnProperty('totalPrime')){
          props.dispatch(registerField("adForm",'totalPrime','Field'));
        }
        props.updateField('totalPrime',(inputField.prime))

        if(!props.data || !props.data.hasOwnProperty('montantrente')){
            props.dispatch(registerField("adForm",'montantrente','Field'));
        }
        props.updateField('montantrente',inputField.montantrente)
        
        if(!props.data || !props.data.hasOwnProperty('periodiciterente')){
            props.dispatch(registerField("adForm",'periodiciterente','Field'));
        }
        props.updateField('periodiciterente',inputField.periodiciterente)

        if(!props.data || !props.data.hasOwnProperty('dureerente')){
            props.dispatch(registerField("adForm",'dureerente','Field'));
        }
        props.updateField('dureerente',inputField.dureerente)

          if(!props.data || !props.data.hasOwnProperty('duree')){
            props.dispatch(registerField("adForm",'duree','Field'));
           }
          props.updateField('duree',inputField.duree)

          if(!props.data || !props.data.hasOwnProperty('periodicite')){
            props.dispatch(registerField("adForm",'periodicite','Field'));
           }
          props.updateField('periodicite',inputField.periodicite);

          const {produits} = props.api;
          if(produits){
            const currentProduit= produits.filter(item=>item.CodeProduit==='PVRBNI')[0]
            if(!props.data || !props.data.hasOwnProperty('produit')){
                props.dispatch(registerField("adForm",'produit','Field'));
            }
            props.updateField('produit',currentProduit);
              
              props.history.push(`/${layoutRoot}/nouvelle-proposition`,{step:1});
          }
    }
    const {privileges,credentials} = props.user
    useEffect(() => {
        if(inputField.produit.hasOwnProperty('CodeProduit') && inputField.produit!==''){
            getPrimesCapital(inputField.produit.CodeProduit).then((capital)=>{
                if(capital){
                 if(['YKL_2004','YKV_2004','YKF_2004'].includes(inputField.produit.CodeProduit)){
                   const currentCapitaux = []
                   capital.map(cp=>{
                     if(cp.capital ==430000){
                       cp.Libelle ="Option Honneur"
                     }
                     if(cp.capital ==750000){
                       cp.Libelle ="Option Elite"
                     }
                     if(cp.capital ==1400000 ){
                       cp.Libelle ="Option Prestige"
                     }
                     currentCapitaux.push(cp)
                   })
                   setCapitaux(currentCapitaux);
                 }else{
                   setCapitaux(capital);
                 }
                }
               })
        }
        
      }, [inputField.produit])

      const handleIsValid = ()=>{
        if(inputField.produit?.CodeProduit){
            switch (inputField.produit?.CodeProduit) {
                case 'YKE_2008':
                case 'YKE_2018':  
                case 'YKS_2008':  
                case 'YKF_2008':  
                case 'DOIHOO':  
                case 'CAD_EDUCPLUS':
                    if((inputField.datenaissanceassure && inputField.datenaissanceassure!=='') && (inputField.capitalSouscrit && inputField.capitalSouscrit!=='' && inputField.capitalSouscrit!==0) && (inputField.duree && inputField.duree!=='' && inputField.duree!==0) && (inputField.periodicite && inputField.periodicite!=='')){
                        setInputField({...inputField,"isValid":true})
                    }
                    break;
                case 'CADENCE':
                    if((inputField.produit?.CodeProduit && inputField.produit?.CodeProduit!=='') && 
                            (inputField.prime && inputField.prime!=='' && inputField.prime!==0) && 
                            (inputField.duree && inputField.duree!=='' && inputField.duree!==0) && 
                            (inputField.periodicite && inputField.periodicite!=='') && !inputField.sureteok){
                            setInputField({...inputField,"isValid":true})
                    }else{
                        if(inputField.sureteok && (inputField.produit?.CodeProduit && inputField.produit?.CodeProduit!=='') && 
                            (inputField.prime && inputField.prime!=='' && inputField.prime!==0) && 
                            (inputField.duree && inputField.duree!=='' && inputField.duree!==0) && 
                            (inputField.periodicite && inputField.periodicite!=='') && 
                            (inputField.datenaissanceassure && inputField.datenaissanceassure!=='') && 
                            (inputField.capitalSouscrit && inputField.capitalSouscrit!=='' && inputField.capitalSouscrit!==0)){
                            setInputField({...inputField,"isValid":true})
                        }
                    }
                case 'PFA_IND':
                        if((inputField.produit?.CodeProduit && inputField.produit?.CodeProduit!=='') && 
                            (inputField.prime && inputField.prime!=='' && inputField.prime!==0) && 
                            (inputField.duree && inputField.duree!=='' && inputField.duree!==0) && 
                            (inputField.periodicite && inputField.periodicite!=='')){
                            setInputField({...inputField,"isValid":true})
                        }
                    break;
                default:
                    break;
            }
            
        }
    }
    const handleAutoSetDure =()=>{
        if(inputField.produit && inputField.produit.DureeCotisationAns && inputField.produit.DureeCotisationAns!=='null'){
            setInputField({
                ...inputField,
                "duree":parseInt(inputField.produit.DureeCotisationAns)
            })
        }else{
            if(['YKF_2008','YK8_2008'].includes(inputField.produit.CodeProduit)){
                setInputField({
                    ...inputField,
                    "duree":10
                })
            }
            if(['DOIHOO'].includes(inputField.produit.CodeProduit)){
                setInputField({
                    ...inputField,
                    "duree":8
                })
            }
        }
    } 
    //réinitialisation de certains champs au changement du produit
    const reniInfosProduit =()=>{
        setInputField({
            ...inputField,
        duree:'',
        montantrente:'',
        periodiciterente:'',
        dureerente:'',
        isValid:false,
        isEvaluate:false,
        remboursementok:false,
        conjointok:false,
        addsenior:false,
        sureteok:false,
        decesaccidentelok:false,
        })
    }
    useEffect(()=>{
        handleAutoSetDure()
        handleIsValid()
    },[inputField.produit?.CodeProduit,inputField.periodicite])
    useEffect(()=>{
        reniInfosProduit()
        handleAutoSetDure()
        handleIsValid()
    },[inputField.produit?.CodeProduit])
    const [errors,setErrors] = useState([])
    const handleChangeCheckBox =(e)=>{
        const {name,checked} = e.target
        setInputField({...inputField,[name]:checked})
    }

    return(
        <div className='py-4 mx-4'>
            <TitleBox titre ="Simulateur de prime" />
            <div style={{width:'60%',marginLeft:'auto',marginRight:'auto'}}>
                <form onSubmit={EvaluerLaprime}>
                <Paper style={{padding:20}}> 
                    {(produits && produits.length !==0) &&<TextField
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={inputField.CodeProduit}
                            name='CodeProduit'
                            onChange={handleChangeProd}
                            label="Produit"
                            select
                            variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            style={{marginTop:20}}
                            >
                            <MenuItem value="">
                                <em>Aucun</em>
                            </MenuItem>
                            {produits.map(produit=> <MenuItem key={produit.CodeProduitFormule}  value={produit.CodeProduitFormule}>{produit.Formule}</MenuItem>)}
                            
                        </TextField>}
                        {(inputField.CodeProduit && inputField.CodeProduit!=="" && ['CADENCE','PFA_IND','PFA_COL'].includes(inputField.produit.CodeProduit)) ? <CustomInput
                                        required
                                        id='prime'
                                        name="prime" 
                                        label="Prime de référence"
                                        formControlProps={{
                                            fullWidth: true,
                                        
                                        }}
                                        value={inputField['prime'] || ""}
                                        
                                        InputProps={{
                                            type:'number',
                                            inputProps: { min: 15000 },
                                        }}
                                        variant="outlined"
                                        onChange={handleChange}
                                    />:null}   

                              {(inputField.produit && inputField.produit!=="" && inputField.prime && inputField.prime!=='' && inputField.prime!==0 && ['CADENCE','PFA_IND','PFA_COL'].includes(inputField.produit.CodeProduit)) ? <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={inputField.sureteok} onChange={handleChangeCheckBox} name="sureteok" />}
                                        label="Appliquer la garantie surété"
                                    />
                                </FormGroup> : null}    

                        {((inputField.CodeProduit && inputField.CodeProduit!=="" && !['CADENCE','PFA_IND','PFA_COL'].includes(inputField.produit.CodeProduit) ) || ((inputField.CodeProduit && inputField.CodeProduit!=="" && ['CADENCE','PFA_IND','PFA_COL'].includes(inputField.produit.CodeProduit) && inputField.sureteok && inputField.prime!=="" && inputField.prime!==0)) ) ? <CustomDate
                                        required
                                        label="Date de naissance de l'assuré"
                                        id="datenaissanceassure"
                                        name="datenaissanceassure"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        InputProps={{
                                            type: 'date',
                                            inputProps: { max:['CAD_EDUCPLUS','CADENCE'].includes(inputField.produit.CodeProduit) ? 0 : dayjs().add(-18, 'year').format('YYYY-MM-DD'), min: dayjs().add(-100, 'year').format('YYYY-MM-DD') },
                                        }}
                                        onChange={handleChange}
                                        variant="outlined"
                                    />:null
                                }
                               {(inputField.datenaissanceassure && inputField.datenaissanceassure!=="" && (['INV_2020','YKS_2021'].includes(inputField.produit.CodeProduit))) ?
                                    <CustomDate
                                        required
                                        label="Date de naissance du souscripteur"
                                        id="datenaissancesouscripteur"
                                        name="datenaissancesouscripteur"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        InputProps={{
                                            type: 'date',
                                            inputProps: { max: dayjs().add(inputField.produit?.AgeMiniAdh ? parseInt(inputField.produit?.AgeMiniAdh):-12, 'year').format('YYYY-MM-DD'), min: dayjs().add(inputField.produit?.AgeMaxiAdh ? parseInt(inputField.produit?.AgeMaxiAdh):-106, 'year').format('YYYY-MM-DD') },
                                        }}
                                        onChange={handleChange}
                                        variant="outlined"
                                    /> : null
                                } 
                                {((['CADENCE','PFA_IND','PFA_COL'].includes(inputField.produit.CodeProduit) && inputField.sureteok && inputField.datenaissanceassure && inputField.datenaissanceassure!=='') || (!['CADENCE','PFA_IND','PFA_COL'].includes(inputField.produit.CodeProduit) && inputField.datenaissanceassure && inputField.datenaissanceassure!=='') ) ?
                                    <CustomCapitalSelect
                                        required
                                        id="capitalSouscrit" 
                                        name="capitalSouscrit" 
                                        devis ={credentials['devis']||""}
                                        label={['YKE_2008','YKE_2018','YKS_2008','YKF_2008'].includes(inputField.produit['CodeProduit'])?"Capital de référence":"Capital surété"}
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        onChange={(e)=>handleChangeSelect(e,"capitalSouscrit")}
                                        options={capitaux}
                                    /> : null
                             }
                                {(inputField.produit && inputField.produit!=="" && inputField.prime && inputField.prime!=='' && inputField.prime!==0 && ['CADENCE'].includes(inputField.produit.CodeProduit) && inputField.sureteok) ? <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={inputField.decesaccidentelok} onChange={handleChangeCheckBox} name="decesaccidentelok" />}
                                        label="Appliquer la garantie décès accidentel"
                                    />
                                </FormGroup> : null} 
                            {(
                                inputField.datenaissanceassure && 
                                inputField.datenaissanceassure!=='' && 
                                ['PFA_IND'].includes(inputField.produit['CodeProduit'])
                            ) ?
                                    <CustomSimpleSelect
                                        required
                                        id="montantrente"
                                        name="montantrente"
                                        label="Montant de la rente"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        value={inputField.montantrente}
                                        variant="outlined"
                                        options={[50000,75000,100000,150000,200000,250000,500000,1000000]}
                                        onChange={(e)=>handleChangeSelect(e,"montantrente")}
                                    /> : null
                                }
                                {(
                                    inputField.datenaissanceassure && 
                                    inputField.datenaissanceassure!=='' && 
                                    ['PFA_IND'].includes(inputField.produit['CodeProduit']) && 
                                    inputField.montantrente && 
                                    inputField.montantrente!==''
                                ) ?
                                    <CustomRadio
                                        required
                                        name="periodiciterente"
                                        id='periodiciterente'
                                        label="Périodicité de la rente"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        value={inputField.periodiciterente}
                                        row
                                        options={[
                                            {"label":"Mois","value":"M"},
                                            {"label":"Trimestre","value":"T"},
                                            {"label":"Semestre","value":"S"},
                                            {"label":"Année","value":"A"}
                                        ]}
                                        onChange={handleChange}
                                    /> : null
                               }
                                {(
                                    inputField.datenaissanceassure && 
                                    inputField.datenaissanceassure!=='' && 
                                    ['PFA_IND'].includes(inputField.produit['CodeProduit']) && 
                                    inputField.periodiciterente && 
                                    inputField.periodiciterente!==''
                                ) ?
                                    <CustomSimpleSelect
                                        required
                                        id="dureerente"
                                        name="dureerente"
                                        label="Durée du service de la rente (mois)"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        value={inputField.dureerente}
                                        options={[12,24,36]}
                                        onChange={handleChange}
                                    /> : null
                                }
                                
                               {(
                                    inputField.produit && 
                                    inputField.produit!=="" && 
                                    (
                                        (
                                            inputField.datenaissanceassure && 
                                            inputField.datenaissanceassure!=='' && 
                                            inputField.capitalSouscrit && 
                                            inputField.capitalSouscrit!==''
                                        ) || 
                                        (
                                            ['CADENCE','PFA_IND','PFA_COL'].includes(inputField.produit.CodeProduit) &&
                                            
                                            inputField.prime && 
                                            inputField.prime!=="" && 
                                            inputField.prime!==0
                                        )
                                    )
                                ) ? 
                                    <CustomInput
                                        required
                                        id='duree'
                                        name="duree" 
                                        label="Durée de cotisation en années"
                                        formControlProps={{
                                            fullWidth: true,
                                        
                                        }}
                                        disabled={['YKE_2008','YKE_2018'].includes(inputField.produit.CodeProduit)}
                                        value={inputField.duree}
                                        type='number'
                                        variant="outlined"
                                        onChange={handleChange}
                                    /> : null
                               }
                                {(inputField.produit && inputField.produit!=="" && ((!['CADENCE','PFA_IND','PFA_COL'].includes(inputField.produit.CodeProduit) && inputField.datenaissanceassure && inputField.datenaissanceassure!=='' && inputField.capitalSouscrit && inputField.capitalSouscrit!=='') || (['CADENCE','PFA_IND','PFA_COL'].includes(inputField.produit.CodeProduit) && inputField.prime && inputField.prime!=="" && inputField.prime!==0 && inputField.duree!=="" && inputField.duree!==0))) ? 
                                    <CustomRadio
                                        name="periodicite"
                                        id='periodicite'
                                        onChange={handleChange}
                                        label="Périodicité de la cotisation à payer"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        row
                                        value={inputField.periodicite}
                                        options={[
                                            {"label":"Mois","value":"M"},
                                            {"label":"Trimestre","value":"T"},
                                            {"label":"Semestre","value":"S"},
                                            {"label":"Année","value":"A"}
                                        ]}
                                        /> : null
                               }
                               {(inputField.produit && inputField.produit!=="" && inputField.datenaissanceassure && inputField.datenaissanceassure!=='' && inputField.capitalSouscrit && inputField.capitalSouscrit!=='' && ['YKE_2008','YKE_2018','YKS_2008'].includes(inputField.produit.CodeProduit)) ? <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={inputField.addsenior} onChange={handleChangeCheckBox} name="addsenior" />}
                                        label="Appliquer la garantie sénior"
                                    />
                                </FormGroup> : null}
                               {inputField.addsenior ? <CustomInput
                                    required
                                    id="primesenior"
                                    name="primesenior"
                                    label="Montant de la prime sénior"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    value={inputField.primesenior}
                                    type='numeric'
                                    InputProps={{
                                        inputProps: { min: 225 },
                                    }}
                                    onChange={handleChange}
                                    variant="outlined"
                                /> : null }
                                {(inputField.produit && inputField.produit!=="" && inputField.datenaissanceassure && inputField.datenaissanceassure!=='' && inputField.capitalSouscrit && inputField.capitalSouscrit!=='' && ['YKF_2008'].includes(inputField.produit.CodeProduit)) ? <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={inputField.conjointok} onChange={handleChangeCheckBox} name="conjointok" />}
                                        label="Appliquer la garantie du conjoint"
                                    />
                                </FormGroup> : null}
                                {(inputField.produit && inputField.produit!=="" && inputField.datenaissanceassure && inputField.datenaissanceassure!=='' && inputField.capitalSouscrit && inputField.capitalSouscrit!=='' && ['YKF_2008'].includes(inputField.produit.CodeProduit)) ? <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={inputField.enfantok} onChange={handleChangeCheckBox} name="enfantok" />}
                                        label="Appliquer la garantie enfant"
                                    />
                                </FormGroup> : null}
                                {(inputField.produit && inputField.produit!=="" && inputField.datenaissanceassure && inputField.datenaissanceassure!=='' && inputField.capitalSouscrit && inputField.capitalSouscrit!=='' && ['YKF_2008','YKS_2008'].includes(inputField.produit.CodeProduit)) ? <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={inputField.remboursementok} onChange={handleChangeCheckBox} name="remboursementok" />}
                                        label="Appliquer la garantie remboursement"
                                    />
                                </FormGroup> : null}
                                {(inputField.produit && inputField.produit!=="" && inputField.datenaissanceassure && inputField.datenaissanceassure!=='' && inputField.capitalSouscrit && inputField.capitalSouscrit!=='' && inputField.remboursementok) ? 
                                    <CustomInput
                                        required
                                        id='prime-garantie-REMB'
                                        name="prime-garantie-REMB" 
                                        label="Prime garantie remboursement"
                                        formControlProps={{
                                            fullWidth: true,  
                                        }}
                                        value={inputField['prime-garantie-REMB'] || ""}
                                        InputProps={{
                                            type:'number',
                                            inputProps: { min: 100, max: 8000 },
                                        }}
                                        variant="outlined"
                                        onChange={handleChange}
                                    /> : null
                               }
                               {(inputField.hasOwnProperty('isEvaluate') &&inputField.isEvaluate)&&<div className=' flex-1 my-3'>
                                        <Divider />
                                        <div className='flex flex-row items-center justify-start gap-3 my-2'>
                                            <div className='flex flex-col'>
                                                <span className='font-bold text-[16px] italic text-slate-400'>Prime Mensuelle: </span>
                                                <h6 className={classes.prime}>{inputField.prime ? formatNumber(parseInt(inputField.prime)) : 0} F CFA </h6>
                                            </div>
                                            <Divider orientation='vertical' style={{marginRight:10,marginLeft:10}} />
                                            <div className='flex flex-col'>
                                                <span className='font-bold text-[16px] italic text-slate-400'>Frais d'adhésion: </span>
                                                <h6 className={classes.prime}>{inputField.fraisadhesion ? formatNumber(parseInt(inputField.fraisadhesion)) : 0} F CFA </h6>
                                            </div>
                                            <Divider orientation='vertical' style={{marginRight:10,marginLeft:10}} />
                                            <div className='flex flex-col'>
                                                <span className='font-bold text-[16px] italic text-slate-400'>Durée de cotisation: </span>
                                                <h6 className={classes.prime}>{inputField.duree ? formatNumber(parseInt(inputField.duree)) : 0} </h6>
                                            </div>
                                            <Divider orientation='vertical' style={{marginRight:10,marginLeft:10}} />
                                            <div className='flex flex-col '>
                                                <span className='font-bold text-[16px] italic text-slate-400'>Capital : </span>
                                                <h6 className={classes.prime}>{inputField['capitalSouscrit'] ? formatNumber(parseInt(inputField['capitalSouscrit'])) : 0} F CFA</h6>
                                            </div>
                                        </div>
                                        <Divider />
                                </div>}
                       
                    {inputField.isValid&& <div className='flex flex-row justify-between items-center gap-2'>
                        
                            <Button variant='outlined' type='submit' className="flex-1 bg-secondary-main hover:bg-black text-white py-3 font-bold">
                                Evaluer la prime
                            </Button>   
                    
                        {((inputField.isValid || verifRule('prop','add',privileges)) && inputField.isEvaluate)&&(
                            <Button variant='outlined' className="flex-1 bg-primary-main hover:bg-black text-white py-3 font-bold"  disabled={(inputField.prime===0 && inputField.isEvaluate)} onClick={nouvelleSouscription}>
                                Nouvelle souscription
                            </Button>   
                       )}
                        
                    </div>}
                    
                </Paper>
                </form>
            </div>
        </div>
        
    )
}


const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapPropsActions ={
    updateField,
    getPrimeGarantie,
    getGarantiesAssure
}
const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user,
    ui:state.UI,
    data:getFormValues('adForm')(state)
  });

  export default connect(mapStateToProps,mapPropsActions)(reduxForm({form: 'adForm',destroyOnUnmount: false,})(Simulateur))
