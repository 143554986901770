import React,{useState,useEffect} from "react";
import { reduxForm,change,getFormValues } from 'redux-form';
import {connect} from 'react-redux'
import PretFonctions from 'pages/Banking/Prets/components/PretFonctions'
import PretList from 'pages/Banking/Prets/PretList'
import PretSimulation from "./components/PretSimulation";
import { useLocation } from "react-router-dom";

function PretHome(props){
    const location = useLocation()
    const [fonctionnalite,setFonctionalite]=useState(location.state?.fontion? location.state?.fontion: 'HOME')
    const handleCliqueFonction = element=>{
        setFonctionalite(element)
    }
    
    const {state} =useLocation()
    useEffect(()=>{
        if(state && state.hasOwnProperty('mode')){
            handleCliqueFonction(state['mode'])
        }
    },[])
    return(
        <div>
            {fonctionnalite==='HOME'&&  <PretFonctions history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='SIMUL'&&  <PretSimulation history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {(fonctionnalite==='ALL' || fonctionnalite==='ENATTENTE' || fonctionnalite==='ENTRAITEMENT' || fonctionnalite==='VALIDER' || fonctionnalite==='MISENPLACE' || fonctionnalite==='REJETES' || fonctionnalite==='RACHETER' || fonctionnalite==='FERMER' || fonctionnalite==='ANNULER')&&  <PretList element={fonctionnalite} history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
        </div>
    )
}


const updateField =(field, data)=>(dispatch)=>dispatch(change('addPret', field, data))
const mapPropsActions ={
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user,
    data:getFormValues('addPret')(state)
  });

  export default connect(mapStateToProps,mapPropsActions)(reduxForm({form:'addPret',destroyOnUnmount:false,})(PretHome))
  
