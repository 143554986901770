import React, { useReducer, useEffect } from "react";
import DashBord from 'pages/components/dash'
import { Divider } from "@material-ui/core";
import { connect } from 'react-redux'
import { totProductionAgent, getEvolutionProductionPret } from 'redux/actions/apiActions'
import { formatNumber } from "application";
import dayjs from 'dayjs'
import Chart from "util/chart";
function DashBordPret(props) {
  const { user: { credentials } } = props
  const [pretData, setPretData] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      pretEnAttente: 0,
      pretEnCotation: 0,
      pretValide: 0,
      pretMisEnPlace: 0,
      pretEchu: 0,
      pretRejeter: 0,
      pretRacheter: 0,
      pretAnnuler: 0,
      total: 0
    });
  useEffect(() => {
    totProductionAgent(credentials.idmembre).then(res => {
      if (res) {
        const { general,totaux } = res
        setPretData({
          ['pretEnAttente']: general.enAttente?.nbCotation,
          ['pretEnCotation']: general.cotations?.nbCotation,
          ['pretValide']: general.valides?.nbValide,
          ['pretMisEnPlace']: general.enplaces?.nbMisEnPlace,
          ['pretEchu']: general.echus?.nbEchu,
          ['pretRejeter']: general.rejetes?.nbrejeter,
          ['pretRacheter']: general.rachetes?.nbRacheter,
          ['pretAnnuler']: general.annules?.nbreannuler,
          ['total']: general.all?.total,
        })
      }
    })
  }, [])

  const canvasRef = React.createRef();
  const [graphe, setGraphe] = React.useReducer((state, newState) => ({ ...state, ...newState }),
    {
      grCurrentMonth: [],
      grLastMonth: [],

    });

  const handleDataEvolution = (data) => {
    setGraphe({
      ['grCurrentMonth']: data.dataCurrentMonth,
      ['grLastMonth']: data.datatLastMonth
    })
  }
  const reduceDataMonth = (mdata, dateDebut, dateFin) => {
    let response = []
    let result = 0
    if (mdata && mdata.length !== 0) {
      for (let d = dateDebut; d <= dateFin; d.setDate(d.getDate() + 1)) {
        result = 0
        mdata.forEach(el => {
          if (dayjs(el.saisiele).format('YYYY-MM-DD') === dayjs(d).format('YYYY-MM-DD')) {
            result = el.total
            return
          } else {
            return false
          }
        })
        response.push(result)
      }
    }
    return response
  }

  useEffect(() => {
    getEvolutionProductionPret(credentials.idmembre).then(resData => {
      if (resData) {
        const { currentMonth, lastMonth, periodes } = resData

        const data = {
          dataCurrentMonth: reduceDataMonth(currentMonth, new Date(periodes.currentDeb), new Date(periodes.currentFin)),
          datatLastMonth: reduceDataMonth(lastMonth, new Date(periodes.lastDeb), new Date(periodes.lastFin))
        }
        handleDataEvolution(data)
      }
    })
  }, [])
  const chartData = {
    title: "",
    labels: Array.from(new Array((graphe.grCurrentMonth.length - graphe.grLastMonth.length) > 0 ? graphe.grCurrentMonth.length : graphe.grLastMonth.length), (_, i) => (i === 0 ? 1 : i)),
    datasets: [
      {
        label: "Mois en cours",
        fill: "start",
        data: graphe.grCurrentMonth,
        backgroundColor: "rgba(0,123,255,0.1)",
        borderColor: "rgba(0,123,255,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgb(0,123,255)",
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 3
      },
      {
        label: "Mois dernier",
        fill: "start",
        data: graphe.grLastMonth,
        backgroundColor: "rgba(255,65,105,0.1)",
        borderColor: "rgba(255,65,105,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgba(255,65,105,1)",
        borderDash: [3, 3],
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 2,
        pointBorderColor: "rgba(255,65,105,1)"
      }
    ]
  }
  useEffect(() => {
    //autre traitement
    const chartOptions = {
      ...{
        responsive: true,
        legend: {
          position: "top"
        },
        elements: {
          line: {
            // A higher value makes the line look skewed at this ratio.
            tension: 0.3
          },
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: false,
              ticks: {
                callback(tick, index) {
                  // Jump every 7 values on the X axis labels to avoid clutter.
                  return index % 7 !== 0 ? "" : tick;
                }
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                suggestedMax: 100,
                callback(tick, index) {
                  return tick;
                }
              }
            }
          ]
        },
        hover: {
          mode: "nearest",
          intersect: false
        },
        tooltips: {
          custom: false,
          mode: "nearest",
          intersect: false
        }
      },
      ...props.chartOptions
    };

    const DashEvolutionProd = new Chart(canvasRef.current, {
      type: "LineWithLine",
      data: chartData,
      options: chartOptions
    });

    // They can still be triggered on hover.
    const buoMeta = DashEvolutionProd.getDatasetMeta(0);
    if (chartData.datasets[0].data.length !== 0) {
      buoMeta.data[0]._model.radius = 0;
      buoMeta.data[
        chartData.datasets[0].data.length - 1
      ]._model.radius = 0;
    }
    // Render the chart.
    DashEvolutionProd.render();
  }, [graphe])
  return (
    <>
      <DashBord>
        <DashBord.Title>PERFORMANCE DE PRODUCTION</DashBord.Title>
        <Divider />
        <DashBord.Base>
          <DashBord.Inner>
            <DashBord.Text>{pretData.total&&formatNumber(parseInt(pretData.total))}</DashBord.Text>
            <DashBord.Label>Total production</DashBord.Label>
          </DashBord.Inner>
          <Divider variant="middle" orientation="vertical" flexItem />

          <DashBord.Inner>
            <DashBord.Text>{pretData.pretEnCotation && formatNumber(pretData.pretEnCotation)}</DashBord.Text>
            <DashBord.Label>En traitement</DashBord.Label>
          </DashBord.Inner>
          <Divider variant="middle" orientation="vertical" flexItem />
          <DashBord.Inner>
            <DashBord.Text>{pretData.pretValide && formatNumber(pretData.pretValide)}</DashBord.Text>
            <DashBord.Label>En attente de mise en place</DashBord.Label>
          </DashBord.Inner>
          <Divider variant="middle" orientation="vertical" flexItem />
          <DashBord.Inner>
            <DashBord.Text>{pretData.pretMisEnPlace && formatNumber(pretData.pretMisEnPlace)}</DashBord.Text>
            <DashBord.Label>Total mis en place</DashBord.Label>
          </DashBord.Inner>
          <Divider variant="middle" orientation="vertical" flexItem />
          <DashBord.Inner>
            <DashBord.Text>{pretData.pretRacheter && formatNumber(pretData.pretRacheter)}</DashBord.Text>
            <DashBord.Label>Total racheté</DashBord.Label>
          </DashBord.Inner>
          <Divider variant="middle" orientation="vertical" flexItem />
          <DashBord.Inner>
            <DashBord.Text>{pretData.pretEchu && formatNumber(pretData.pretEchu)}</DashBord.Text>
            <DashBord.Label>Total échu</DashBord.Label>
          </DashBord.Inner>
          <Divider variant="middle" orientation="vertical" flexItem />
          <DashBord.Inner>
            <DashBord.Text>{pretData.pretRejeter && formatNumber(pretData.pretRejeter)}</DashBord.Text>
            <DashBord.Label>Total rejetés</DashBord.Label>
          </DashBord.Inner>
        </DashBord.Base>
      </DashBord>

      <DashBord elevation={3}>
        <DashBord.Title>EVOLUTION DE LA PRODUCTION SUR LES DEUX (2) DERNIERS MOIS</DashBord.Title>
        <Divider />
        <DashBord.Base>
          <canvas
            height="120"
            ref={canvasRef}
            style={{ maxWidth: "100% !important" }}
          />
        </DashBord.Base>
      </DashBord>



    </>
  )
}

const mapStateToProps = (state) => ({
  api: state.api,
  user: state.user
});
const mapActionsToProps = {}
export default connect(mapStateToProps, mapActionsToProps)(DashBordPret)