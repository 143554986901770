import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { connect, useSelector } from 'react-redux'
import { reduxForm, change, Field, registerField, getFormValues } from 'redux-form';
import Paper from '@material-ui/core/Paper';
import CustomInput from "components/CustomInput/CustomInput.js";
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { green, pink } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect'
import { verifRule, compare } from 'application'
import CustomDate from 'components/CustomDate/CustomDate'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from "components/CustomButtons/Button.js";
import CustomSelect from 'components/CustomSelect/CustomSelect.js'
import { getCentres, getMaladies, getActeurSinistre, getDetailActeursSinistre, pompesFunebresVille, pompesFunebres } from 'redux/actions/apiActions'
import CustomRadio from 'components/CustomRadio/CustomRadio'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import dayjs from 'dayjs'
import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 20,
        '& :hover': {
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            border: 0,
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            padding: '10 30px',
            textDecoration: 'none'
        }
    },

    sswitch: {
        width: 60,
        height: 40,
        padding: 0,
        margin: theme.spacing(1)
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            // This is the part that animates the thumb when the switch is toggled (to the right)
            transform: 'translateX(16px)',
            // This is the thumb color
            color: theme.palette.common.white,
            '& + $track': {
                // This is the track's background color (in this example, the iOS green)
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },

    },
    thumb: {
        width: 36,
        height: 36,
    },
    track: {
        borderRadius: 19,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[300],
        height: 30,
        opacity: 1,
        marginTop: 4,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    switcLabel: {
        fontSize: 18
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    loading: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonAdd: {
        width: '100%',
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 30,
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },

    buttonDetails: {
        width: '100%',
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        backgroundColor: '#fff',
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },

    buttonAddChild: {
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    buttonSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,

        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonReset: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonNewSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: "#FFF",
        color: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
        }
    },
    media: {
        height: 140,
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    dcard: {
        marginBottom: 10,
        paddingBottom: 10
    },
    paper: {
        marginBottom: 20,
        padding: 20
    },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
    },
    btnback:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:"#f9b233",
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#3c3c3b",
        },
        borderRadius:10,
      },
      btnnext:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:theme.palette.primary.main,
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#f9b233",
        },
        borderRadius:10,
      },
}));

function DetailsOperation(props) {
    const classes = useStyles();
    const { handleSubmit, handleNext, handleBack, activeStep, steps, dataForm } = props
    const [state, setState] = React.useState({
        decesaccidentel: false,
        hunimationok: false,
        acteursType: null
    });
    const [assList, setAssList] = React.useState(null)
    const [checked, setChecked] = React.useState(null)
    const [expanded, setExpanded] = React.useState(false);
    const handleexpanded = (val) => {
        setExpanded(val ? val : !expanded)
    }
    const [centres, setCentres] = React.useState(null)
    const [maladies, setMaladies] = React.useState(null)
    const handleSwithcChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        if (!props.hasOwnProperty(`${event.target.name}`)) {
            props.dispatch(registerField("addOper", `${event.target.name}`, 'Field'));
        }
        props.updateField(`${event.target.name}`, event.target.checked)
    }
    const [mesVilles, setMesVilles] = React.useState(null)
    const handleVilles = () => {
        pompesFunebresVille().then(vl => {

            if (vl) setMesVilles(vl)
        })
    }

    const [pompes, setPompes] = React.useState(null)
    const handlePompesFunebres = () => {
        if (dataForm.hasOwnProperty('villeconservcorps')) {
            pompesFunebres(dataForm.villeconservcorps).then(pf => {
                if (pf) setPompes(pf)
            })
        } else {
            pompesFunebres().then(pf => {
                if (pf) setPompes(pf)
            })
        }
    }

    //let mesVilles = useSelector(state => state.api.villes).sort(compare)
    let professions = useSelector(state => state.api.professions).sort(compare)

    const handleCentre = () => {
        getCentres().then(res => {
            if (res) setCentres(res);
        })
    }
    const handleMaladie = () => {
        getMaladies().then(res => {
            if (res) setMaladies(res);
        })
    }

    const handleActeurSinistres = () => {
        getActeurSinistre().then(res => {
            if (res) {
                let acteurs = []
                acteurs = res.map(act => {
                    return { label: act.libelle, value: act.codetypedecces }
                })
                acteurs.push({ label: "AUTRE", value: "AUTRE" })
                if (acteurs && acteurs.length !== 0) {
                    setState({ ...state, acteursType: acteurs });
                }
            }
        })
    }
    useEffect(() => {
        handleCentre()
        handleMaladie()
        handleActeurSinistres()
        handleVilles()
        handlePompesFunebres()
    }, [])


    const handleActeursSinsitre = () => {
        if (dataForm.hasOwnProperty('typeacteur') && dataForm.hasOwnProperty('codeproposition')) {

            getDetailActeursSinistre({ type: dataForm.typeacteur, proposition: dataForm.codeproposition }).then(dataRes => {

                if (dataRes) {
                    if (dataRes.length > 1) {
                        setAssList(dataRes)
                        handleexpanded()
                    } else {
                        if (!props.hasOwnProperty('nomdefunt')) {
                            props.dispatch(registerField("addOper", 'nomdefunt', 'Field'));
                        }
                        props.updateField('nomdefunt', dataRes[0].Nom)

                        if (!props.hasOwnProperty('prenomdefunt')) {
                            props.dispatch(registerField("addOper", 'prenomdefunt', 'Field'));
                        }
                        props.updateField('prenomdefunt', dataRes[0].Prenom)

                        if (!props.hasOwnProperty('datenaissancedefunt')) {
                            props.dispatch(registerField("addOper", 'datenaissancedefunt', 'Field'));
                        }
                        props.updateField('datenaissancedefunt', dayjs(dataRes[0].DateNaissance).format('YYYY-MM-DD'))

                        if (!props.hasOwnProperty('lieuresidencedefunt')) {
                            props.dispatch(registerField("addOper", 'lieuresidencedefunt', 'Field'));
                        }
                        props.updateField('lieuresidencedefunt', dataRes[0].Lieuresidence)

                        if (!props.hasOwnProperty('lieunaissancedefunt')) {
                            props.dispatch(registerField("addOper", 'lieunaissancedefunt', 'Field'));
                        }
                        props.updateField('lieunaissancedefunt', dataRes[0].Lieunaissance)

                        if (!props.hasOwnProperty('professiondefunt')) {
                            props.dispatch(registerField("addOper", 'professiondefunt', 'Field'));
                        }
                        props.updateField('professiondefunt', dataRes[0].Profession)
                        handleexpanded()
                    }
                }
            })
        }
    }
    const handleSelectAssInList = (dataAss) => {
        setChecked(null)
        if (dataAss) {
            setChecked(dataAss.CodePersonne)
            if (!props.hasOwnProperty('nomdefunt')) {
                props.dispatch(registerField("addOper", 'nomdefunt', 'Field'));
            }
            props.updateField('nomdefunt', dataAss.Nom)

            if (!props.hasOwnProperty('prenomdefunt')) {
                props.dispatch(registerField("addOper", 'prenomdefunt', 'Field'));
            }
            props.updateField('prenomdefunt', dataAss.Prenom)

            if (!props.hasOwnProperty('datenaissancedefunt')) {
                props.dispatch(registerField("addOper", 'datenaissancedefunt', 'Field'));
            }
            props.updateField('datenaissancedefunt', dayjs(dataAss.DateNaissance).format('YYYY-MM-DD'))

            if (!props.hasOwnProperty('lieuresidencedefunt')) {
                props.dispatch(registerField("addOper", 'lieuresidencedefunt', 'Field'));
            }
            props.updateField('lieuresidencedefunt', dataAss.Lieuresidence)

            if (!props.hasOwnProperty('lieunaissancedefunt')) {
                props.dispatch(registerField("addOper", 'lieunaissancedefunt', 'Field'));
            }
            props.updateField('lieunaissancedefunt', dataAss.Lieunaissance)

            if (!props.hasOwnProperty('professiondefunt')) {
                props.dispatch(registerField("addOper", 'professiondefunt', 'Field'));
            }
            props.updateField('professiondefunt', dataAss.Profession)
        }

    }
    useEffect(() => {
        handleActeursSinsitre()
    }, [dataForm.typeacteur])
    useEffect(() => {
        if (dataForm) handlePompesFunebres()
    }, [dataForm.villeconservcorps])
   
    //processus principal
    return (
        <form onSubmit={handleSubmit(handleNext)}>
            <Paper elevation={0} className={classes.paper}>
                <Typography className={classes.heading}>
                    Etape {activeStep ? (activeStep + 1) : 1} : {(steps) ? steps[activeStep] : ""}
                </Typography>
                <Divider />
            </Paper>
            <Paper elevation={3} className={classes.paper}>
                <LabelledOutline label="Description du sinistre">
                    <GridContainer>
                        <GridItem sm={12} xs={12} md={6} lg={6}>
                            <Field
                                required
                                id="naturesinistre"
                                name="naturesinistre"
                                component={CustomSimpleSelect}
                                label="Quelle nature du sinistre ?"
                                formControlProps={{
                                    fullWidth: true
                                }}

                                options={['DECES', 'INVALIDITE']}
                            />
                            {centres && <Field
                                required
                                label="Dans quel etablissement le sinistre a eu lieu ?"
                                id="lieusinistre"
                                name="lieusinistre"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                component={CustomSelect}
                                options={centres}
                                variant="outlined"
                            />}
                            {maladies && <Field
                                required
                                label="Quelle est la cause du sinistre ?"
                                id="causesinistre"
                                name="causesinistre"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                component={CustomSelect}
                                options={maladies}
                                variant="outlined"
                            />}
                            <LabelledOutline label="Type de sinistre">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        classes={{ label: classes.switcLabel }}
                                        control={<Switch classes={{
                                            root: classes.sswitch,
                                            switchBase: classes.switchBase,
                                            thumb: classes.thumb,
                                            track: classes.track,
                                            checked: classes.checked,
                                        }} checked={state.decesaccidentel} onChange={handleSwithcChange} color="primary" name="decesaccidentel" />}
                                        label="Le décès est-il accidentel ?"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        classes={{ label: classes.switcLabel }}
                                        control={<Switch classes={{
                                            root: classes.sswitch,
                                            switchBase: classes.switchBase,
                                            thumb: classes.thumb,
                                            track: classes.track,
                                            checked: classes.checked,
                                        }} checked={state.hunimationok} onChange={handleSwithcChange} color="primary" name="hunimationok" />}
                                        label="L'hunimation à t'elle déjà eu lieu ?"
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                            </LabelledOutline>
                        </GridItem>
                        <GridItem sm={12} xs={12} md={6} lg={6}>
                            <Field
                                required
                                id="datesinistre"
                                name="datesinistre"
                                component={CustomDate}
                                label="A quelle date le sinistre a eu lieu ?"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                InputProps={{
                                    type: 'date',
                                }}
                            />
                            <LabelledOutline label="Lieu de concervation du corps">
                                {mesVilles && <Field
                                    label="Ville"
                                    id="villeconservcorps"
                                    name="villeconservcorps"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    component={CustomSelect}
                                    options={mesVilles}
                                    variant="outlined"
                                />}
                                {pompes && <Field
                                    required
                                    id="lieuconservcorps"
                                    name="lieuconservcorps"
                                    component={TextField}
                                    label="Etablissement"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    select
                                    variant="outlined"
                                >
                                    <MenuItem value="0">
                                        <em>Aucun</em>
                                    </MenuItem>
                                    {pompes.map((option) => (
                                        <MenuItem key={option.id} value={option.designation}>
                                            {`${option.designation}-${option.commune}(${option.localisation})`}
                                        </MenuItem>
                                    ))}
                                </Field>}
                            </LabelledOutline>

                            <Field
                                required
                                id="dateinhumation"
                                name="dateinhumation"
                                component={CustomDate}
                                label="Quelle est la date l'enterrement ?"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                InputProps={{
                                    type: 'date',
                                }}
                            />
                            <Field
                                required
                                id="lieuinhumation"
                                name="lieuinhumation"
                                component={CustomInput}
                                label="Quel est le lieu de l'entrerrement ?"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                type='texte'
                                variant="outlined"
                            />
                        </GridItem>
                    </GridContainer>
                </LabelledOutline>
            </Paper>

            <Paper elevation={3} className={classes.paper}>
                <LabelledOutline label="Type acteur au contrat">
                    <Field
                        required
                        label=""
                        id="typeacteur"
                        name="typeacteur"
                        options={state.acteursType && state.acteursType}
                        formControlProps={{
                            fullWidth: true
                        }}
                        row
                        component={CustomRadio}
                    />
                </LabelledOutline>
                <Accordion expanded={expanded} onChange={handleexpanded}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant='h5'>{(assList && assList.length !== 0) ? "Selectionnez le défunt" : "Informations sur le défunt"}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {(assList && assList.length !== 0) ? (<TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" >#</TableCell>
                                        <TableCell align="center" >Nom</TableCell>
                                        <TableCell align="center">Né(e) le</TableCell>
                                        <TableCell align="center">Lieu de naissance</TableCell>
                                        <TableCell align="center">Lieu de residence</TableCell>
                                        <TableCell align="center">Profession</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {assList.map(ass => (
                                        <TableRow key={ass.CodePersonne}>
                                            <TableCell align="center" component="th" scope="row">
                                                <Checkbox
                                                    checked={(checked === ass.CodePersonne)}
                                                    onChange={(e) => handleSelectAssInList(ass)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </TableCell>
                                            <TableCell align="center">{`${ass.Nom} ${ass.Prenom}`}</TableCell>
                                            <TableCell align="center">{dayjs(ass.DateNaissance.toString()).isValid() ? dayjs(ass.DateNaissance).format('DD/MM/YYYY') : ""}</TableCell>
                                            <TableCell align="center">{ass.Lieunaissance}</TableCell>
                                            <TableCell align="center">{ass.lieuresidence}</TableCell>
                                            <TableCell align="center">{ass.Profession}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>) : (<GridContainer>
                            <GridItem sm={12} xs={12} md={6} lg={6}>
                                <Field
                                    id="nomdefunt"
                                    name="nomdefunt"
                                    component={CustomInput}
                                    label="Nom du défunt"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    type='texte'
                                    variant="outlined"
                                />
                                <Field
                                    id="datenaissancedefunt"
                                    name="datenaissancedefunt"
                                    label="Date de naissance"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    component={CustomDate}
                                    InputProps={{
                                        type: 'date',
                                    }}
                                />
                                <Field
                                    label="Lieu de résidence"
                                    id="lieuresidencedefunt"
                                    name="lieuresidencedefunt"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    component={CustomSelect}
                                    options={mesVilles}
                                    variant="outlined"

                                />
                                <Field
                                    label="Profession"
                                    id="professiondefunt"
                                    name="professiondefunt"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    component={CustomSelect}
                                    options={professions}

                                />
                            </GridItem>
                            <GridItem sm={12} xs={12} md={6} lg={6}>
                                <Field
                                    id="prenomdefunt"
                                    name="prenomdefunt"
                                    component={CustomInput}
                                    label="Préom du défunt"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    type='texte'
                                    variant="outlined"
                                />
                                <Field
                                    label="Lieu de naissance"
                                    id="lieunaissancedefunt"
                                    name="lieunaissancedefunt"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    component={CustomSelect}
                                    options={mesVilles}
                                    variant="outlined"

                                />
                                <Field
                                    label="Secteur d'activité"
                                    id="secteuractivite"
                                    name="secteuractivite"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    options={secteurActivites}
                                    component={CustomSelect}
                                />
                            </GridItem>
                        </GridContainer>)}

                    </AccordionDetails>
                </Accordion>
            </Paper>
            <Paper elevation={3} className={classes.paper}>
                <Divider style={{ marginTop: 20 }} />
                <div style={{ display: "flex", justifyContent: "space-between", padding: 20 }}>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}

                    >
                        Retour
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        type='submit'
                        className={classes.button}
                        large
                    >
                        {activeStep === steps.length - 1 ? 'Valider' : 'Suivant'}
                    </Button>
                </div>
            </Paper>
        </form>
    )
}


const updateField = (field, data) => (dispatch) => dispatch(change('addOper', field, data))
const mapPropsActions = {
    updateField,
}
const mapStateToProps = (state) => ({
    user: state.user,
    api: state.api,
    dataForm: getFormValues('addOper')(state),
});
export default connect(mapStateToProps, mapPropsActions)(reduxForm({ form: 'addOper', destroyOnUnmount: false })(DetailsOperation))



const secteurActivites = [
    { "MonLibelle": 'Agroalimentaire' },
    { "MonLibelle": 'Banque / Assurance' },
    { "MonLibelle": 'Bois / Papier / Carton / Imprimerie' },
    { "MonLibelle": 'BTP / Matériaux de construction' },
    { "MonLibelle": 'Édition / Communication / Multimédia' },
    { "MonLibelle": 'Électronique / Électricité' },
    { "MonLibelle": 'Études et conseils' },
    { "MonLibelle": 'Industrie pharmaceutique' },
    { "MonLibelle": 'Informatique / Télécoms' },
    { "MonLibelle": 'Machines et équipements / Automobile' },
    { "MonLibelle": 'Métallurgie / Travail du métal' },
    { "MonLibelle": 'Plastique / Caoutchouc' },
    { "MonLibelle": 'Services aux entreprises' },
    { "MonLibelle": 'Textile / Habillement / Chaussure' },
    { "MonLibelle": 'Transports / Logistique' },
    { "MonLibelle": 'Autre' }
]
