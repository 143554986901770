import React, { useEffect } from 'react'
import { makeStyles,withStyles } from '@material-ui/core/styles';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { connect } from 'react-redux'
import { reduxForm, change,  registerField, getFormValues } from 'redux-form';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import {Typography,IconButton} from '@material-ui/core';
import { green, pink } from '@material-ui/core/colors';
import MaterialTable from 'material-table'
import Button from "components/CustomButtons/Button.js";
import { getFichierOperation } from 'redux/actions/apiActions'
import DocumentCard from 'pages/Operations/components/DocumentCard';
import PrintIcon from '@material-ui/icons/Print';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

//import de fichier
import annulation from "assets/pdf/prestations/annulation_de_contrat.pdf";
import arret_garantie_rem from "assets/pdf/prestations/arret_garantie_rembourssement.pdf";
import arret_prelevement_conservation_de_capital from "assets/pdf/prestations/arret_prelevement_conservation_de_capital.pdf";
import rachat_partiel from "assets/pdf/prestations/rachat_partiel.pdf";
import rachat_total from "assets/pdf/prestations/rachat_total.pdf";
import trop_percu_apres_arret_contrat from "assets/pdf/prestations/remboursement _trop_percu_apres_arret_contrat.pdf";
import trop_percu_apres_sinistre from "assets/pdf/prestations/remboursement_trop_percu_apres_sinistre.pdf";
import trop_percu_pendant_cotisation from "assets/pdf/prestations/remboursement_trop_percu_pendant_cotisation.pdf";
import trop_percu_apres_fin_cotisation from "assets/pdf/prestations/remboursement_trop-percu_apres_fin_cotisation.pdf";
import renonciation from "assets/pdf/prestations/renonciation.pdf";
import resiliation from "assets/pdf/prestations/resiliation.pdf";
import terme from "assets/pdf/prestations/terme.pdf";
import terme_cotisations_YAKO from "assets/pdf/prestations/terme_cotisations_YAKO.pdf";
import {red} from '@material-ui/core/colors'
const fiches =[
  {op:"",fichier:annulation,libelle:"Courrier de demande d'annulation"},
  {op:"32",fichier:arret_garantie_rem,libelle:"Courrier de demande d'arrêt de la garantie rembourssement"},
  {op:"35",fichier:arret_prelevement_conservation_de_capital,libelle:"Courrier de demande d'arrêt de prélèvement avec conservation du capital"},
  {op:"24",fichier:rachat_partiel,libelle:"Courrier de demande de rachat partiel"},
  {op:"23",fichier:rachat_total,libelle:"Courrier de demande de rachat total"},
  {op:"31",fichier:trop_percu_apres_arret_contrat,libelle:"Courrier de demamnde de rembourssement trop perçu après arrêt du contrat"},
  {op:"44",fichier:trop_percu_apres_sinistre,libelle:"Courrier de demande de rembourssement trop perçu après sinistre"},
  {op:"41",fichier:trop_percu_apres_fin_cotisation,libelle:"Courrier de demande de rembourssement trop perçu après arrêt de cotisation"},
  {op:"40",fichier:trop_percu_pendant_cotisation,libelle:"Courrier de demande de rembourssement trop perçu pendant cotisation"},
  {op:"22",fichier:renonciation,libelle:"Courrier de demande de renonciation"},
  {op:"36",fichier:resiliation,libelle:"Courrier de demande de résiliation"},
  {op:"27",fichier:terme,libelle:"Courrier de contrat à terme"},
  {op:"37",fichier:terme_cotisations_YAKO,libelle:"Courrier de demande terme cotisation"}
]

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 20,
        '& :hover': {
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            border: 0,
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            padding: '10 30px',
            textDecoration: 'none'
        }
    },

    sswitch: {
        width: 60,
        height: 40,
        padding: 0,
        margin: theme.spacing(1)
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            // This is the part that animates the thumb when the switch is toggled (to the right)
            transform: 'translateX(16px)',
            // This is the thumb color
            color: theme.palette.common.white,
            '& + $track': {
                // This is the track's background color (in this example, the iOS green)
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },

    },
    thumb: {
        width: 36,
        height: 36,
    },
    track: {
        borderRadius: 19,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[300],
        height: 30,
        opacity: 1,
        marginTop: 4,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    switcLabel: {
        fontSize: 18
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    loading: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonAdd: {
        width: '100%',
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 30,
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },

    buttonDetails: {
        width: '100%',
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        backgroundColor: '#fff',
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },

    buttonAddChild: {
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    buttonSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,

        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonReset: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonNewSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: "#FFF",
        color: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
        }
    },
    media: {
        height: 140,
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    dcard: {
        marginBottom: 10,
        paddingBottom: 10
    },
    paper: {
        marginBottom: 20,
        padding: 20
    },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
    },
    btnback:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:"#f9b233",
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#3c3c3b",
        },
        borderRadius:10,
    },
    btnnext:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:theme.palette.primary.main,
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#f9b233",
        },
        borderRadius:10,
    },
    header:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:red[100],
        borderRadius:10,
        padding:10,
        paddingTop:20,
        marginBottom:20,
        '& p':{
          fontSize:'1.1rem',
          fontWeight:400,
          color:red[700],
          marginBottom:20
        } 
      }
}));


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);


function DocumentsOperation(props) {
    const { handleNext, handleBack, activeStep, steps, dataForm,user:{credentials} } = props
    const classes = useStyles();

    const [documents, setDocuments] = React.useState([])
    const handleMesDocs = (docs) => {
        props.setMesDoct(docs)
    }
    const handleFichierOperation = () => {
        let fichiers = []
        let dataSend = {}
        if (dataForm.hasOwnProperty('codeproduit')) {
            dataSend['codeproduit'] = dataForm.codeproduit
        }
        if (dataForm.hasOwnProperty('groupeoperation')) {
            dataSend['groupeoperation'] = dataForm.groupeoperation
        }
        if (dataForm.hasOwnProperty('typeacteur') && dataForm.hasOwnProperty('groupeoperation') && dataForm.groupeoperation === "SIN") {
            dataSend['typedeces'] = dataForm.typeacteur
        }
        if (dataForm.hasOwnProperty('hunimationok')) {
            if (dataForm.hasOwnProperty('groupeoperation') && dataForm.groupeoperation === 'SIN') {
                if (dataForm.hunimationok === true) {
                    dataSend['tardive'] = 1
                } else {
                    dataSend['atemps'] = 1
                }

            }
        } else {
            if (dataForm.hasOwnProperty('groupeoperation') && dataForm.groupeoperation === 'SIN') {
                dataSend['atemps'] = 1
            }
        }
        if (dataForm.hasOwnProperty('operation')) {
            dataSend['codeoperation'] = dataForm['operation']['CodeTypeAvenant']
        }
        if (dataSend && Object.keys(dataSend).length !== 0) {
            getFichierOperation(dataSend).then(fileData => {
                if (fileData) {
                    fichiers = fileData.map(f => {
                        return { id: f.id, titre: f.libelle }
                    })
                }
                if (fichiers && fichiers.length !== 0) {
                    setDocuments(fichiers)
                }
            })
        }

    }

    const handleDataSelected = (row) => {
        if (row && row.length !== 0) {
            let libDoc = ""
            row.forEach(el => {
                if (libDoc === "") {
                    libDoc = el.titre
                } else {
                    libDoc = libDoc + "||" + el.titre
                }
            })
            if (libDoc !== "") {
                if (!props.hasOwnProperty('docs')) {
                    props.dispatch(registerField("addOper", 'docs', 'Field'));
                }
                props.updateField('docs', libDoc)
            }
        }
    }

    useEffect(() => {
        handleFichierOperation()
    }, [dataForm.codeproduit, dataForm.groupeoperation])
    const downloadFichier = (lien)=>{
        if(lien){window.open(lien)}
    }
      const [currentDocs,setCurrentDocs] = React.useState(null)
      useEffect(()=>{
        if(dataForm && dataForm['operation'] && credentials['codepartenaire']==='092'){
            setCurrentDocs(fiches.filter(item=>item.op === dataForm['operation']['CodeTypeAvenant']))
        }
      },[dataForm])
   
    return (
        <div>
            <Paper elevation={0} className={classes.paper}>
                <Typography className={classes.heading}>Etape {activeStep ? (activeStep + 1) : 1} : {(steps) ? steps[activeStep] : ""}</Typography>
                <Divider />
            </Paper>
            {(currentDocs && !['SIN','MOD'].includes(dataForm.groupeoperation))&&<Paper className={classes.paper}>
                <div className={classes.header}>
                    <p>Veuillez imprimer le courrier de demande de prestation et le faire remplir par le client avant de poursuivre l'opération</p>
                </div>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableBody>
                            {currentDocs&& currentDocs.map(doc=>(
                                <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                {doc.libelle.toUpperCase()}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <IconButton onClick={()=>downloadFichier(doc.fichier)}>
                                        <PrintIcon />
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>}
            <Paper elevation={3} className={classes.paper}>
                <GridContainer>
                    {(documents && documents.length !== 0) && <GridItem xs={12} sm={12} md={12} lg={12}>
                        <LabelledOutline label="Liste des documents attendus">
                            <small style={{marginBottom:20}}>Veuillez selectionner les documents à attacher et cliquez dans le cadre telecharger pour joindre vos documments</small>
                            <MaterialTable
                                columns={[
                                    {
                                        field: 'titre',
                                        render: rowData => (<div className={classes.tablibOper}>
                                            <Typography variant='h5'>{rowData.titre.charAt(0).toUpperCase() + rowData.titre.slice(1).toLowerCase()}</Typography>
                                        </div>)
                                    }
                                ]}
                                data={documents ? documents : []}
                                localization={{
                                    body: { emptyDataSourceMessage: 'Aucune donnée trouvée !' },
                                }}
                                options={{
                                    header: false,
                                    showFirstLastPageButtons: false,
                                    showTitle: false,
                                    toolbar: false,
                                    showTextRowsSelected: false,
                                    selection: true,
                                    search: false,
                                    paging: false,
                                    selectionProps: rowData => ({ color: 'primary' }),
                                    actionsColumnIndex: -1,
                                }}
                                onSelectionChange={(rows) => handleDataSelected(rows)}
                            />
                        </LabelledOutline>
                    </GridItem>}
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <DocumentCard
                            id='documents'
                            name='documents'
                            component={DocumentCard}
                            documentType={documents}
                            handleFile={handleMesDocs}
                        />
                        {/*<DocumentCard documentType={documents} handleFile={handleMesDocs} />*/}
                    </GridItem>
                </GridContainer>
            </Paper>
            <Paper elevation={3} className={classes.paper}>
                <Divider style={{ marginTop: 20 }} />
                <div style={{ display: "flex", justifyContent: "space-between", padding: 20 }}>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.btnback}

                    >
                        Retour
                    </Button>

                    <Button
                        variant="contained"
                        onClick={handleNext}
                        className={classes.btnnext}
                        large
                    >
                        {activeStep === steps.length - 1 ? 'Valider' : 'Suivant'}
                    </Button>
                </div>

            </Paper>
        </div>
    )
}

const updateField = (field, data) => (dispatch) => dispatch(change('addOper', field, data))
const mapPropsActions = {
    updateField,
}
const mapStateToProps = (state) => ({
    user: state.user,
    api: state.api,
    dataForm: getFormValues('addOper')(state),
});
export default connect(mapStateToProps, mapPropsActions)(reduxForm({ form: 'addOper', destroyOnUnmount: false })(DocumentsOperation))

