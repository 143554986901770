import React,{useEffect,useReducer,useState} from "react";
import clsx from 'clsx'
import { makeStyles } from "@material-ui/core/styles";

// core components
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import "assets/styles/shards-dashboards.1.1.0.min.css";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Button from "components/CustomButtons/Button.js";
import {changePassword,logoutUser} from 'redux/actions/userActions'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {connect,useSelector} from 'react-redux'
const useStyles = makeStyles(theme=>({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
      },
    paper:{
        padding:20  
    },
    line:{
        marginTop:20,
        marginBottom:20,
    },
    input:{
        marginBottom:20
    },
   
      margin: {
        margin: theme.spacing(1),
      },
      withoutLabel: {
        marginTop: theme.spacing(3),
      },
      textField: {
        width: '25ch',
      },
     messageErr:{
          fontSize:20,
          fontWeight:'bold',
          textAlign:'center',
          backgroundColor:theme.palette.primary.light2,
          padding:20,
          borderRadius:5,
          marginRight:20
      },
      messageSucces:{
        fontSize:20,
        fontWeight:'bold',
         textAlign:'center',
         backgroundColor:'#c8e6c9',
         padding:20,
         borderRadius:5,
         marginRight:20
     }
}))

 function ChangePassword(props) {
    const classes = useStyles();
    useEffect(() => {
      document.title ="ENOV - Modification de votre mot de passe"
    })
    const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
        password:'',
        passwordnew:'',
        passwordConfirm:'',
        showpassword: false,
        showpasswordNew:false,
        showpasswordConfirm:false
    })

    
    const [errors,setErrors] = useState(null)
    const [errorsAll, setErrorsAll]=useState(null)
    
    const [result,setResult]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
      type:null,
      message:""
    })
    const initresult =()=>{
        setResult({['type']:null,['message']:''})
    }
    const handleChange=(event)=>{
        const {name,value} = event.target
        setInputField({
            [name]:value
        })
    }
 
    const [timeInt,setTimeInt] =useState(0)
    const [seconds, setSeconds] = useState(5);
    const startTime =()=>{
        setTimeInt(setInterval(() => {setSeconds(seconds => seconds - 1);}, 2000));
    }
    useEffect(() => {
        if(seconds===0){
            clearInterval(timeInt);
            props.logoutUser()
        } 
    });
    
   const passRegex =/^(?=.*[A-Za-z0-9]).{8,32}$/g
   
    const changeMyPassword=()=>{
        setErrorsAll(null)
        
        if(inputField.password.trim()===''){
            setErrors({'password':"Champ obligatoire"}) 
            return 
        }
        if(inputField.passwordnew.trim()===''){
            setErrors({'passwordnew':"Champ obligatoire"}) 
            return 
        }
        if(inputField.passwordConfirm.trim()===''){
            setErrors({'passwordConfirm':"Champ obligatoire"}) 
            return 
        }

        if(inputField.passwordnew.trim()!==inputField.passwordConfirm.trim()){
            setErrors({'passwordnew':"Impossible de confirmer le mot de passe",'passwordConfirm':""}) 
          
            return
        }
       
        if(!inputField.passwordnew.match(passRegex)){
            setErrors({'passwordnew':""}) 
            setErrorsAll("Le mot de passe doit comporter au moins 8 caractères, pas plus de 32 caractères, et doit inclure au moins une lettre majuscule, une lettre minuscule et un chiffre numérique.")
            setErrorOpen(true)
            return
        }
        if(!inputField.passwordConfirm.match(passRegex)){
            setErrors({'passwordConfirm':""}) 
            setErrorsAll("Le mot de passe doit comporter au moins 8 caractères, pas plus de 32 caractères, et doit inclure au moins une lettre majuscule, une lettre minuscule et un chiffre numérique.")
            setErrorOpen(true)
            return
        }
      
        let mesDonnees =JSON.stringify(inputField,null,2)
        mesDonnees =JSON.parse(mesDonnees)
        
        props.changePassword(mesDonnees).then(res=>{
            
            if(res){
                setResult({
                    ['type']:"SUCCES",
                    ['message']:res[0]
                })
                startTime()
            }else{
                setResult({
                    ['type']:"ERROR",
                    ['message']:res
                })
            }
        })
    }


    const handleClickShowPassword = (name) => {
        setInputField({[`show${name}`]: !(eval(`${'inputField.show'+name}`)) });
      };
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
      const [errorOpen, setErrorOpen] = React.useState(false);
      const timer =()=>{
            setTimeout(setErrorOpen,6000,false);  
      }
     useEffect(() => {
         if(errorOpen){
            timer()
         }
     }, [errorOpen])
     const serrors =useSelector(state => state.UI.errors)
    return (
      <div className="mt-8">
        <GridContainer justify="center"  alignItems="center">
            <GridItem xs={12} sm={12} md={6} lg={6}>
                <Paper elevation={3} className={classes.paper}>
                    <Typography component='h3' variant='h3'> Modifier votre mot de passe</Typography>
                    <Divider  className={classes.line} />
                    {result.type&&(
                        <GridContainer justify="center"  alignItems="center">
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                {(result.type==='ERROR')&&(
                                    <div className={classes.messageErr}>
                                        <ErrorOutlineIcon color='primary' size={50} />
                                <Typography variant='body' component='h5' color='primary'>{serrors}</Typography>
                                        <Button onClick={initresult}  color="primary"  style={{marginTop:20,marginBottom:20}}>Recommencer</Button>
                                    </div>
                                )}
                                
                            {(result.type==='SUCCES')&&(
                                <div className={classes.messageSucces}>
                                    <CheckCircleOutlineIcon color='#4caf50' size={50} />
                                    <Typography variant='body' component='h5' color='#66bb6a'>{`${result.message} rédirection dans ...`} </Typography>
                                    <Typography variant='h4' component='h4' color='#66bb6a'>{seconds} s</Typography>
                                </div>
                            )}
                                
                        
                            </GridItem>
                        </GridContainer>
                    )}
                    
                    {!result.type&&(
                       <>
                            <GridContainer justify="center"  alignItems="center">
                        {errorsAll&&(<GridItem xs={12} sm={12} md={12} lg={12}>  
                            <Collapse in={errorOpen}><Alert action={<IconButton aria-label="close" color="inherit" size="small" onClick={() => {setErrorOpen(false)}}><CloseIcon fontSize="inherit" /></IconButton>} severity="error"> {errorsAll}</Alert></Collapse>
                        </GridItem>)}
                        <GridItem xs={12} sm={12} md={8} lg={8}>
                            <FormControl fullWidth className={clsx(classes.margin )} variant="outlined">
                                <InputLabel htmlFor="password">Votre mot de passe actuel</InputLabel>
                                <OutlinedInput
                                    error={(errors && errors.password)?true:false}
                                    id="password"
                                    name="password"
                                    type={inputField.showpassword ? 'text' : 'password'}
                                    onChange={handleChange}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={(e)=>handleClickShowPassword('password')}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        >
                                        {inputField.showpassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    
                                    }
                                    fullWidth
                                    labelWidth={200}
                                    
                                />
                                {(errors && errors.password)&&( <FormHelperText error >{errors.password}</FormHelperText>)}
                            </FormControl>
                        </GridItem>
                        <Divider />
                        <GridItem xs={12} sm={12} md={8} lg={8}>
                            <FormControl fullWidth className={clsx(classes.margin )} variant="outlined">
                                <InputLabel htmlFor="password">Nouveau mot de passe</InputLabel>
                                <OutlinedInput
                                    error={(errors && errors.passwordnew)?true:false}
                                    id="passwordnew"
                                    name="passwordnew"
                                    type={inputField.showpasswordNew ? 'text' : 'password'}
                                    onChange={handleChange}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle passwordNew visibility"
                                        onClick={(e)=>handleClickShowPassword('passwordNew')}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        >
                                        {inputField.showpasswordNew ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    fullWidth
                                    labelWidth={200}
                                />
                                 {(errors && errors.passwordnew)&&( <FormHelperText error >{errors.passwordnew}</FormHelperText>)}
                            </FormControl>
                       
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8} lg={8}>
                            <FormControl fullWidth className={clsx(classes.margin)} variant="outlined">
                                <InputLabel htmlFor="password">Confirmation du mot de passe</InputLabel>
                                <OutlinedInput
                                    error={(errors && errors.passwordConfirm)?true:false}
                                    id="passwordConfirm"
                                    name="passwordConfirm"
                                    type={inputField.showpasswordConfirm ? 'text' : 'password'}
                                    onChange={handleChange}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle passwordConfirm visibility"
                                        onClick={(e)=>handleClickShowPassword('passwordConfirm')}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        >
                                        {inputField.showpasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    fullWidth
                                    labelWidth={200}
                                />
                                {(errors && errors.passwordConfirm)&&( <FormHelperText error >{errors.passwordConfirm}</FormHelperText>)}
                            </FormControl>
                        </GridItem>
                       
                       
                    </GridContainer>
                    <GridContainer direction='row' justify="center"  alignItems="center">
                        <GridItem xs={12} sm={12} md={8} lg={8} >
                            <Button onClick={changeMyPassword} variant='outlined' className='bg-primary-main w-full hover:bg-secondary-main' >Modifier</Button>
                        </GridItem>
                    </GridContainer>
                       </> 
                    )}
                    
                </Paper>
            </GridItem>
        </GridContainer>
      </div>
    );
  }
  const mapStateToProps = (state) => ({
    user:state.user,
    ui:state.UI
});
  const mapActionsToProps = {logoutUser,changePassword}
  export default connect(mapStateToProps,mapActionsToProps)(ChangePassword)
 // export default ChangePassword