import styled from 'styled-components/macro'
import Paper from '@material-ui/core/Paper'
export const Container = styled(Paper)`
    display:flex;
    flex-direction:column;
    padding:10px;
    margin:20px;
    @media(max-width: 600px){
        padding:10px;
    }
`
export const Base = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-top:10px;
    @media(max-width: 600px){
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:space-between;
    }
`
export const Inner = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    padding:5px;
`
export const Title = styled.h1`
    color:#666;
    font-size:16px;
    @media(max-width: 600px){
        font-size:12px;
    }
`
export const Group = styled.div`
    display: flex;
    flex-direction:${(direction) => direction}
`
export const Text = styled.h1`
    color:#333;
    font-size:26px;
    font-weight:500;
    @media(max-width: 600px){
        font-size:16px;
    }
`
export const Label = styled.p`
    color:#666;
    font-size:15px;
    @media(max-width: 600px){
        font-size:10px;
    }
`

export const CButton = styled.button`
    display:flex;
    align-items:center;
    background-color:#fff;
    border:1px solid #f2f2f2;
    border-radius:30px;
    height:14px;
    &:hover {
        background-color:#3d8a41;
        border:none;
    };
`

export const DisButton = styled.button`
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:#fff;
    border-top:1px solid #f2f2f2;
    height:12px;
    border-radius:0px;
    text-align: center;
    text-decoration: none;
    width:100%;
    &:hover {
        background-color:#3d8a41;
        border:none;
    };
`