import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { yellow,grey } from '@material-ui/core/colors';
const useStyles = makeStyles((theme) => ({
    paper: {
      padding: '6px 16px',
    },
    firstdary: {
        backgroundColor: "#f9b233",
      },
    secondary: {
      backgroundColor: "#2b6338",
    },
    thirddary: {
        backgroundColor: "#3c3c3b",
      },
  }));

function Activites() {
  const classes = useStyles();
 
  return (
    <div className={classes.root}>
        <Timeline>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot className={classes.firstdary}>
                        <AccountBalanceWalletIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                        <Typography variant="h6" component="h1">
                        Emission de factures
                        </Typography>
                        <Typography>Dernier montant : 200 000 FCFA</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot className={classes.secondary}>
                        <GroupAddIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                        <Typography variant="h6" component="h1">
                            Incorporation 
                        </Typography>
                        <Typography>Jean Emmanuel KOUASSI </Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineSeparator>
                <TimelineDot className={classes.thirddary}>
                    <AssignmentIcon />
                </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">
                    Prestation
                    </Typography>
                    <Typography>Rachat total</Typography>
                </Paper>
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    </div>
  )
}

export default Activites