import React, { useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable, { MTableToolbar } from 'material-table';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { reduxForm, change, registerField, getFormValues } from 'redux-form';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { green } from '@material-ui/core/colors';
const styles = (theme) => ({
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
    },
    paper: {
        padding: '20px',
    },
    line: {
        marginBottom: '20px'
    },
    btnback:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:"#f9b233",
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#3c3c3b",
        },
        borderRadius:10,
      },
      btnnext:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:theme.palette.primary.main,
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#f9b233",
        },
        borderRadius:10,
      },
      bnex:{ 
        backgroundColor: theme.palette.primary.main, 
        height: '100%', 
        color:'white',
        width: '100%',
        "&:hover":{
            backgroundColor: theme.palette.secondary.main, 
        }
    }
})
const useStyles = makeStyles(styles)

function OperationType(props) {
    const classes = useStyles();
    const { operations, handleNext,activeStep, steps } = props
    const [selectedRow, setSelectedRow] = React.useState(null)

    const handleOperationSelect = (event, myrow) => {
        setSelectedRow(myrow)
        if (!props.hasOwnProperty('operation')) {
            props.dispatch(registerField("addOper", 'operation', 'Field'));
        }
        props.updateField('operation', myrow)
    }

    const handleCheckedProduit = (myrow) => {
        setSelectedRow(myrow)
        if (!props.hasOwnProperty('operation')) {
            props.dispatch(registerField("addOper", 'operation', 'Field'));
        }
        props.updateField('operation', myrow)

        handleNext()
    }
    const customFilter = (columFilter, tableFilter, valueSeach) => {
        const isproduit = (item) => {
            let result = false
            for (const key of columFilter) {
                if (item[key]) {
                    if (item[key].toUpperCase().indexOf(valueSeach.toUpperCase()) !== -1) {
                        result = true
                        break;
                    } else {
                        result = false
                    }
                } else {
                    result = false
                }
            }
            return result
        }
        return tableFilter.filter(isproduit)
    }
    const [typeOpeData, setTypeOpeData] = React.useState({
        columns: [
            {
                title: 'code opération',
                field: 'CodeTypeAvenant',
                cellStyle: { fontSize: 30, padding: 0, color: '#000', fontWeight: 'bold' },
                render: rowData => <List className={classes.list}>
                    <ListItem>
                        <ListItemText primary={`${rowData.MonLibelle}`} secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                    display='block'
                                >
                                    {rowData.DelaiTraitement ? `Durée traitement ${rowData.DelaiTraitement} jour(s)` : ""}
                                </Typography>
                            </React.Fragment>
                        } />
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => handleCheckedProduit(rowData)} className={classes.bnex}  edge="end" aria-label="suivant">
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>,
            },
            { title: 'Libelle', field: 'MonLibelle', hidden: true },
            { title: 'Durée traitement', field: 'DelaiTraitement', type: 'numeric', hidden: true }
        ]
    });


    //affichage liste des produits
    const renderOperationTable = () => (
        <MaterialTable
            title={null}
            columns={typeOpeData.columns}
            data={query => new Promise((resolve, reject) => {
                const columnSearch = ['CodeTypeAvenant', 'MonLibelle', 'DelaiTraitement']
                resolve({
                    data: customFilter(columnSearch, operations, query.search)
                })
            })}
            onRowClick={((evt, selectedRow) => handleOperationSelect(evt, selectedRow))}
            components={{
                Toolbar: props => (
                    <div style={{ backgroundColor: '#e8eaf5' }}>
                        <MTableToolbar {...props} />
                    </div>
                ),
            }}
            localization={{
                toolbar: {
                    searchPlaceholder: 'Trouver une opération',
                    searchTooltip: 'Trouver une opération'
                },
                body: {
                    emptyDataSourceMessage: 'Aucune donnée trouvée !'
                },
                pagination: {
                    labelRowsSelect: 'Ligne(s)',
                    labelDisplayedRows: '{count} sur {from}-{to}',
                    firstTooltip: 'Prémière page',
                    previousTooltip: 'Précédent',
                    nextTooltip: 'Suivant',
                    lastTooltip: 'Dernière page'
                }
            }}
            options={{
                search: true,
                searchFieldAlignment: "left",
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40, 50, 100],
                searchFieldStyle: {
                    fontSize: 18,
                    width: '100%',
                    height: 50,
                },
                header: false,
                rowStyle: rowData => ({
                    backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? green[50] : '#FFF'
                })
            }}
        />
    )

    return (
        <Paper elevation={0} className={classes.paper}>
            <Typography className={classes.heading}>Etape {activeStep ? (activeStep + 1) : 1} : {(steps) ? steps[activeStep] : ""}</Typography>
            <Divider className={classes.line} />
            {operations ? renderOperationTable() : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress color="secondary" />
            </div>}

        </Paper>
    )
}
const updateField = (field, data) => (dispatch) => dispatch(change('addOper', field, data))
const mapPropsActions = {
    updateField,
}
const mapStateToProps = (state) => ({
    user: state.user,
    api: state.api,
    data: getFormValues('addOper')(state),
    ui: state.UI
});
export default connect(mapStateToProps, mapPropsActions)(reduxForm({ form: 'addOper', destroyOnUnmount: false })(OperationType))
