import React, { useState, useReducer, useEffect } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table'
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { getOperationByUser, operationStatByUser } from 'redux/actions/apiActions'
import Core from 'pages/components/core'
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDate from 'components/CustomDate/CustomDate'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Box } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import dayjs from 'dayjs'
import {
    getEquipesReseau,
    getEquipesZones,
    getZonesReseau,
    getZonesNoDispatch,
    getEquipesNoDispatch

} from 'redux/actions/settingsAction'
import RefreshIcon from '@material-ui/icons/Refresh';
import DialogBox from 'pages/Operations/components/DialogBox'
import CustomAlert from 'components/CustomAlert/CustomAlert'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Divider from '@material-ui/core/Divider';
import XLSX from 'xlsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },


});
const useStyles = makeStyles(theme => ({
    dcard: {
        marginBottom: 10,
        paddingBottom: 10,
        flexWrap: 'wrap'
    },
    headerTitle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    coreCustum: {
        borderRadius: 0,
        borderLeft: "10px solid #E35F5B"
    },
    itemTitle: {
        fontStyle: "italic"
    },
    assureInfoRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    paper: {
        marginBottom: 20,
        backgroundColor: "#FFF1EB",
        padding: 10
    },
    buttonSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,

        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonReset: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    headerTitle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    headerFilter: {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    headerState: {
        display: 'flex',
        flex: 2,
        justifyContent: 'center',
        alignItems: 'center'
    },
    headerButton: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    tablibOper: {
        display: 'flex',
        flexDirection: 'column'
    },
    filterContainer: {
        display: 'flex',
        flexWrap: "wrap"
    },
    filterItem: {
        display: 'flex',
        flexDirection: 'column'

    },
    filterBtn: {
        display: 'flex',
        flex: 1
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: 10
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    buttonSearch: {
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,

        color: '#FFF',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
        }
    }
}))
function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const exportToExcel = (columns, data) => {
    // determining header labels
    const columnInfo = columns.reduce(
        (acc, column) => {
            const headerLabel = column.title || column.field;
            acc.header.push(headerLabel);
            acc.map[column.field] = headerLabel;
            return acc;
        },
        { map: {}, header: [] }
    );

    const mappedData = data.map(row =>
        Object.entries(row).reduce((acc, [key, value]) => {
            if (columnInfo.map[key]) {
                acc[columnInfo.map[key]] = value;
            }
            return acc;
        }, {})
    );

    var ws = XLSX.utils.json_to_sheet(mappedData, {
        header: columnInfo.header, // garanties the column order same as in material-table
    });

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* generate an XLSX file and triggers the browser to generate the download*/
    XLSX.writeFile(wb, `LLV-liste-Operation.xlsx`);
};

const CustumLinearProgress = withStyles((theme) => ({
    root: {
        height: 20,
        borderRadius: 10,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 10,
        backgroundColor: props => props.currentColor,
    },
}))(LinearProgress);
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function ModalListOperation(props) {
    const classes = useStyles();
    const { open, handleClose, etats, credentials } = props
    const [loading, setLoading] = useState(false)
    const [titre, setTitre] = useState("")
    const [operList, setOperList] = useState(null)
    const [showMoreFilter, setShowMoreFilter] = useState(false)
    const [filterField, setFilterField] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            partenaire: credentials ? credentials.codereseau : 0,
            zone: [5, 7, 10].includes(credentials.coderole) ? credentials.codezone : 0,
            agence: [5, 10].includes(credentials.coderole) ? credentials.codeequipe : 0,
            groupeoperation: etats['mode'] || null,
            etattraitement: etats['etattrt'] || null,
            etape: etats['etape'] || null,
            etatedit: etats['etatedit'] || null,
            groupe: 'ETATTRT'
        });
    const handleFilterChange = (event) => {
        const { name, value } = event.target
        setFilterField({
            [name]: value
        })
    }
    const handleClickFilter = () => {
        setShowMoreFilter(!showMoreFilter)
    }
    const [operStat, setOperStat] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            total: 0,
            nonTransmis: 0,
            transmis: 0,
            accepter: 0,
            rejeter: 0,
            annuler: 0
        });

    const handleOperList = () => {
        setLoading(true)
        const { etattrt, etape, mode, etatedit } = etats
        if (etattrt) {
            switch (etattrt) {
                case 0:
                    setTitre("En attente de traitement")
                    break;
                case 1:
                case 2:
                case 3:
                    if (mode === "SIN") {
                        (etattrt == 1) && setTitre("En traitement/Bon de commande Emis")
                            (etattrt == 2) && setTitre("En traitement/Bon de commande édité")
                    } else {
                        setTitre("En traitement")
                    }
                    break;
                case 4:
                case 5:
                    setTitre("En attente de rémise")
                    break;
                case 6:
                case 7:
                    setTitre("Remis/archivé")
                    break;
                default:
                    setTitre("")
                    break;
            }
        }

        getOperationByUser(filterField).then(res => {
            if (res) {
                setOperList(res)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }).catch(err => {
            console.log(err);
            setLoading(false)
        })
        operationStatByUser(filterField).then(res => {
            let tot = 0
            if (res && res.length !== 0) {
                res.forEach(element => {
                    setOperStat({ [element['Libelle']]: element['Nombre'] })
                    tot += element['Nombre']
                });
                if (tot !== 0) {
                    setOperStat({ ['total']: tot })
                }
            }
        })
    }
    useEffect(() => {
        handleOperList()
    }, [etats])
    const [zoneList, setZoneList] = useState([])
    const handleZoneList = () => {
        if (filterField.partenaire && filterField.partenaire !== '') {
            getZonesReseau(filterField.partenaire).then(res => {
                if (res) {
                    setZoneList(res)
                }
            })
        } else {
            getZonesNoDispatch().then(res => {
                if (res) {
                    setZoneList(res)
                }
            })
        }
    }
    const calcProgressValue = (currentval, maxmal) => {
        return parseInt((currentval * 100) / maxmal)
    }
    // selection des données 
    const [dataSelected, setDataSelected] = useState(null)
    const handleDataSelected = (dataRow) => {
        setDataSelected(dataRow);
    }
    //récupération des agence/equipe
    const [agenceList, setAgenceList] = useState([])
    const handleAgenceList = () => {
        if (filterField.partenaire && filterField.partenaire !== '') {
            getEquipesReseau(filterField.partenaire).then(res => {
                if (res) {
                    setAgenceList(res)
                }
            })
        } else if (filterField.zone && filterField.zone !== '') {
            getEquipesZones(filterField.zone).then(res => {
                if (res) {
                    setAgenceList(res)
                }
            })
        } else {
            getEquipesNoDispatch().then(res => {
                if (res) {
                    setAgenceList(res)
                }
            })
        }
    }
    useEffect(() => {
        handleZoneList()
    }, [filterField.partenaire])
    useEffect(() => {
        handleAgenceList()
    }, [filterField.zone, filterField.partenaire])
    return (
        <Dialog
            onClose={handleClose}
            fullWidth
            disableBackdropClick
            maxWidth={'md'}
            aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {`Liste des opérations  - `}
            </DialogTitle>
            <DialogContent dividers>
                {!loading ? <div>
                    <div> <Tooltip title={showMoreFilter ? "Masquer le filtrer" : "Plus de filtre"} placement="bottom-start">
                        <IconButton onClick={handleClickFilter} color={showMoreFilter ? "primary" : "secondary"} aria-label="Filtre">
                            <FilterListIcon fontSize='large' />
                        </IconButton>
                    </Tooltip></div>
                    {showMoreFilter && <Paper elevation={0} className={classes.paper}>
                        <LabelledOutline label="Filtres">
                            <div className={classes.filterContainer}>
                                <div className={classes.filterItem}>
                                    <TextField
                                        id="saisiedu"
                                        name="saisiedu"
                                        label="Saisie du"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleFilterChange}
                                        variant='outlined'
                                    />
                                    <TextField
                                        id="saisieau"
                                        name="saisieau"
                                        label="Au"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleFilterChange}
                                        variant='outlined'
                                    />
                                </div>
                                <Divider orientation='vertical' />
                                <div className={classes.filterItem}>
                                    <TextField
                                        id="operationdu"
                                        name="operationdu"
                                        label="Opération du"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleFilterChange}
                                        variant='outlined'
                                    />
                                    <TextField
                                        id="operationau"
                                        name="operationau"
                                        label="Au"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleFilterChange}
                                        variant='outlined'
                                    />
                                </div>
                                <Divider orientation='vertical' />
                                <div className={classes.filterItem}>
                                    <TextField
                                        id="tranmisdu"
                                        name="tranmisdu"
                                        label="Transmis du"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleFilterChange}
                                        variant='outlined'
                                    />
                                    <TextField
                                        id="tranmisau"
                                        name="tranmisau"
                                        label="Au"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleFilterChange}
                                        variant='outlined'
                                    />
                                </div>
                                <Divider orientation='vertical' />
                                <div className={classes.filterItem}>
                                    <TextField
                                        id="accepterdu"
                                        name="accepterdu"
                                        label="Accepté du"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleFilterChange}
                                        variant='outlined'
                                    />
                                    <TextField
                                        id="accepterau"
                                        name="accepterau"
                                        label="Au"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleFilterChange}
                                        variant='outlined'
                                    />
                                </div>
                                <Divider orientation='vertical' />
                                <div className={classes.filterItem}>
                                    <TextField
                                        id="rejetdu"
                                        name="rejetdu"
                                        label="Réjét du"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleFilterChange}
                                        variant='outlined'
                                    />
                                    <TextField
                                        id="rejetau"
                                        name="rejetau"
                                        label="Au"
                                        type="date"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleFilterChange}
                                        variant='outlined'
                                    />
                                </div>
                                <Divider orientation='vertical' />
                                <div className={classes.filterItem}>
                                    {([9, 8].includes(credentials.coderole)) && <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-outlined-label">Zone / inspection</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="zone"
                                            name="zone"
                                            value={filterField.zone}
                                            onChange={handleFilterChange}
                                            label="Zone / Inspection"
                                        >
                                            <MenuItem value="0">
                                                <em>Aucun</em>
                                            </MenuItem>
                                            {(zoneList && zoneList.length !== 0) && (zoneList.map(zone => <MenuItem key={`${zone.id}`} value={zone.id}>{zone.libelle}</MenuItem>))}
                                        </Select>
                                    </FormControl>}
                                    {([7, 8, 9].includes(credentials.coderole)) && <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-outlined-label">Agence/Equipe</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="agence"
                                            name="agence"
                                            value={filterField.agence}
                                            onChange={handleFilterChange}
                                            label="Agence/Equipe"
                                        >
                                            <MenuItem value="0">
                                                <em>Aucun</em>
                                            </MenuItem>
                                            {(agenceList && agenceList.length !== 0) && agenceList.map(ag => (<MenuItem key={ag.id} value={`${ag.id}`}>{ag.libelleequipe}</MenuItem>))}

                                        </Select>
                                    </FormControl>}
                                </div>
                                <div className={classes.filterBtn}>
                                    <Button variant='outlined' color='primary' className={classes.buttonSearch} onClick={handleOperList} >
                                        <RefreshIcon fontSize="large" /> ACTUALISER
                                    </Button>
                                </div>
                            </div>
                        </LabelledOutline>
                    </Paper>}
                    <MaterialTable
                        title={titre}
                        columns={[
                            {
                                field: 'libelletypeoperation',
                                render: rowData => (<div className={classes.tablibOper}>
                                    <Typography variant='h5'>{rowData.libelletypeoperation}</Typography>
                                    <Typography variant='caption'>{`Ref : ${rowData.codeoperation} \n`}</Typography>
                                    <Typography variant='caption'>{`Date :${dayjs(rowData.dateoperation).format('DD/MM/YYYY')}`}</Typography>
                                    {rowData.delaitraitement && <Typography variant='caption'>{`Traitement : ${rowData.delaitraitement} jour(s)`}</Typography>}
                                    {rowData.datefinprevu && <Typography variant='caption'>{`Fin traitement :${dayjs(rowData.datefinprevu).format('DD/MM/YYYY')}`}</Typography>}
                                </div>)

                            },
                            {
                                field: "idproposition",
                                render: rowData => (
                                    <div className={classes.tablibOper}>
                                        <Typography variant='h5'>{rowData.adherent}</Typography>
                                        <Typography variant='caption'>{`#ID : ${rowData.idproposition}`}</Typography>
                                        <Typography variant='caption'>{`Produit :${rowData.libelleproduit}`}</Typography>
                                        {rowData.dateeffet && <Typography variant='caption'>{`Date d' effet :${dayjs(rowData.dateeffet).format('DD/MM/YYYY')}`}</Typography>}
                                    </div>
                                ),
                                filtering: false
                            },
                            {
                                field: 'etape',
                                title: 'Etat',
                                width: "2%",
                                render: rowData => {
                                    const cvalue = calcProgressValue(rowData.etape, 4)
                                    return <div className={classes.tablibOper}>
                                        <Typography variant='caption'>{`${cetape[rowData.etape]}`}</Typography>
                                        <CustumLinearProgress
                                            currentColor={cvalue < 50 ? "#E35F5B" : (cvalue === 50 ? "#ffc107" : (cvalue === 75 ? "#cddc39" : "#4caf50"))}
                                            variant="determinate"
                                            value={cvalue} />
                                    </div>
                                },
                                lookup: { 0: 'Annulée', 1: 'Non tranmis', 2: 'En attente de validation', 3: 'Acceptée', 4: 'Réjétée' },
                            },
                            {
                                field: 'etattraitement',
                                title: 'Niveau de traitement',
                                lookup: rowData => (rowData.groupeoperation === 'SIN' || rowData.groupeoperation === 'PRES') ? { 0: '', 1: 'En attente de traitement', 2: 'Début du Traitement', 3: 'Fin du traitement', 4: 'Soumission', 5: 'Chèque/OV établi', 6: 'Chèque/OV signé', 7: 'Chèque OV remis', 8: 'Opération archivé ' } : { 0: '', 1: 'En attente de traitement', 2: 'Début du Traitement', 3: 'Fin du traitement' },
                                render: rowData => {
                                    const maxVal = (rowData.groupeoperation === 'SIN' || rowData.groupeoperation === 'PRES') === 'SIN' ? 8 : 3
                                    const cvalue = calcProgressValue(rowData.etattraitement, maxVal)
                                    const trtTab = (rowData.groupeoperation === 'SIN' || rowData.groupeoperation === 'PRES') ? cetattrt : cetatmod
                                    return <div className={classes.tablibOper}>
                                        <Typography variant='caption'>{`${rowData.etattraitement ? trtTab[rowData.etattraitement] : trtTab[0]}`}</Typography>
                                        <CustumLinearProgress
                                            currentColor={cvalue < 50 ? "#E35F5B" : (cvalue === 50 ? "#ffc107" : (cvalue === 75 ? "#cddc39" : "#4caf50"))}
                                            variant="determinate"
                                            value={cvalue} />
                                    </div>
                                },
                                width: "2%"
                            }
                        ]}

                        data={operList ? operList : []}
                        localization={{
                            toolbar: {
                                searchPlaceholder: 'Trouver une opération',
                                searchTooltip: 'Trouver une opération',
                                exportTitle: 'Exportation',
                                exportAriaLabel: 'Exportation',
                                exportCSVName: 'Exporter en Excel',
                                exportPDFName: 'Exporter en PDF',
                            },
                            body: {
                                emptyDataSourceMessage: 'Aucune donnée trouvée !'
                            },
                            pagination: {
                                labelRowsSelect: 'Ligne(s)',
                                labelDisplayedRows: '{count} sur {from}-{to}',
                                firstTooltip: 'Prémière page',
                                previousTooltip: 'Précédent',
                                nextTooltip: 'Suivant',
                                lastTooltip: 'Dernière page'
                            }
                        }}
                        options={{
                            filtering: true,
                            selection: true,
                            selectionProps: rowData => ({
                                disabled: rowData.etape === 3,
                                color: 'primary'
                            }),
                            exportButton: true,
                            actionsColumnIndex: -1,
                            exportCsv: (columns, data) => exportToExcel(columns, data, "LLV-Liste-opération"),
                            searchFieldStyle: {
                                fontSize: 18,
                                width: '100%',
                                height: 50,
                            },
                            pageSize: 20,
                            pageSizeOptions: [20, 40, 80, 100, 200, 300, 400, 500, 1000]
                        }}
                        onSelectionChange={(rows) => handleDataSelected(rows)}

                    />
                </div> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color="secondary" />
                </div>}
            </DialogContent>
        </Dialog>
    )
}
const cetape = ['Annulée', 'En attente de tranmission', 'En attente de validation', 'Acceptée', 'Réjétée']
const cetattrt = ["Aucune action", "En attente de traitement", "Début du Traitement", "Fin du traitement", "Soumission", "Chèque/OV", "Chèque/OV signé", "Chèque OV remis", "Opération archivé"]
const cetatmod = ["Aucune action", "En attente de traitement", "Début du Traitement", "Fin du traitement"]
export default ModalListOperation