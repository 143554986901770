import React, { useReducer,useEffect } from 'react'
import DocumentCard from 'pages/Banking/components/DocumentCard'
import DocumentCardOp from 'pages/Operations/components/DocumentCard';
import { Grid } from '@material-ui/core'
import { uploadDocuments,getFichierOperation } from 'redux/actions/apiActions'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button.js";
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline';
import MaterialTable from 'material-table';
import { connect, useSelector } from 'react-redux'
import { reduxForm, change, Field, registerField, getFormValues } from 'redux-form';
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));
function ImportDocument(props) {
    const { open, handleClose, handleOpen, source,data } = props
    const classes = useStyles();
    const [mesdocs, setMesDoct] = React.useState([])
    const handleMesDocs = (docs) => {
        setMesDoct(docs)
    }
    const [info, setInfo] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            type: 'error',
            message: "",
        });
    const [mopen, setMopen] = React.useState(false)
    const handleMclose = () => {
        setMopen(false)
    }
    const handleSaveDoc = () => {

        if (mesdocs && mesdocs.length !== 0) {
            uploadDocuments(props.data.id, mesdocs, source).then(res => {
                if (res) {
                    setInfo({
                        ['type']: 'success',
                        ['message']: 'Importation effectuée avec succès'
                    })
                    setMopen(true)

                } else {
                    setInfo({
                        ['type']: 'error',
                        ['message']: "Erreur d'importation des documents, veuillez reassayer"
                    })
                    setMopen(true)

                }

            }).catch(err => {
                console.log(err);
                setInfo({
                    ['type']: 'error',
                    ['message']: 'Erreur lors de l\'importation'
                })
                setMopen(true)
            })
        }
    }
    const handleDataSelected = (row) => {
        if (row && row.length !== 0) {
            let libDoc = ""
            row.forEach(el => {
                if (libDoc === "") {
                    libDoc = el.titre
                } else {
                    libDoc = libDoc + "||" + el.titre
                }
            })
            if (libDoc !== "") {
                if (!props.hasOwnProperty('docs')) {
                    props.dispatch(registerField("addOper", 'docs', 'Field'));
                }
                props.updateField('docs', libDoc)
            }
        }
    }
    const [documents, setDocuments] = React.useState([])
    const handleFichierOperation = () => {
        let fichiers = []
        let dataSend = {}
        if (data.hasOwnProperty('codeproduit')) {
            dataSend['codeproduit'] = data.codeproduit
        }
        if (data.hasOwnProperty('groupeoperation')) {
            dataSend['groupeoperation'] = data.groupeoperation
        }
        if (data.hasOwnProperty('typedeces') && data.hasOwnProperty('groupeoperation') && data.groupeoperation === "SIN") {
            dataSend['typedeces'] = data.typeacteur
        }
        if (data.hasOwnProperty('hunimationok')) {
            if (data.hasOwnProperty('groupeoperation') && data.groupeoperation === 'SIN') {
                if (data.hunimationok === true) {
                    dataSend['tardive'] = 1
                } else {
                    dataSend['atemps'] = 1
                }

            }
        } else {
            if (data.hasOwnProperty('groupeoperation') && data.groupeoperation === 'SIN') {
                dataSend['atemps'] = 1
            }
        }
        if (data.hasOwnProperty('codetypeoperation')) {
            dataSend['codeoperation'] = data['codetypeoperation']
        }
        if (dataSend && Object.keys(dataSend).length !== 0) {
            getFichierOperation(dataSend).then(fileData => {
                if (fileData) {
                    fichiers = fileData.map(f => {
                        return { id: f.id, titre: f.libelle }
                    })
                }
                if (fichiers && fichiers.length !== 0) {
                    setDocuments(fichiers)
                }
            })
        }

    }
    useEffect(() => {
        if(source && source==="OP"){
            handleFichierOperation()
        }
    }, [source])
    return (
        <Dialog
            maxWidth='md'
            disableBackdropClick
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <Snackbar anchorOrigin={{ vertical: 'center', horizontal: 'center' }} open={mopen} autoHideDuration={6000} onClose={handleMclose}>
                <Alert onClose={handleMclose} severity={info.type}>
                    {info.message}
                </Alert>
            </Snackbar>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Importation de document {source=="ES"?"proposition":(source==='OP'?"opération":"demande prét ")} N°: #{props.data.id}
                    </Typography>

                    <IconButton edge="start" color="inherit" onClick={handleSaveDoc} aria-label="close">
                        <AssignmentReturnedIcon fontSize="large" />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <DialogContent>
                {( (source && source==="OP") && (documents && documents.length !== 0))?(<GridContainer><GridItem xs={12} sm={12} md={12} lg={12}>
                        <LabelledOutline label="Liste des documents attendus" style={{marginTop:20,marginBottom:20}}>
                            <small>Veuillez selectionnez les documents à attacher et cliquez dans le cadre telecharger pour joindre vos documents</small>
                            <MaterialTable
                                columns={[
                                    {
                                        field: 'titre',
                                        render: rowData => (<div className={classes.tablibOper}>
                                            <Typography variant='h5'>{rowData.titre.charAt(0).toUpperCase() + rowData.titre.slice(1).toLowerCase()}</Typography>
                                        </div>)
                                    }
                                ]}
                                data={(documents && documents.length!==0) ? documents : documenttype}
                                localization={{
                                    body: { emptyDataSourceMessage: 'Aucune donnée trouvée !' },
                                }}
                                options={{
                                    header: false,
                                    showFirstLastPageButtons: false,
                                    showTitle: false,
                                    toolbar: false,
                                    showTextRowsSelected: false,
                                    selection: true,
                                    search: false,
                                    paging: false,
                                    selectionProps: rowData => ({ color: 'primary' }),
                                    actionsColumnIndex: -1,
                                }}
                                onSelectionChange={(rows) => handleDataSelected(rows)}
                            />
                        </LabelledOutline>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <DocumentCardOp
                            id='documents'
                            name='documents'
                            component={DocumentCard}
                            documentType={(documents && documents.length!==0) ? documents : documenttype}
                            handleFile={handleMesDocs}
                        />              
                    
                        </GridItem>
                    </GridContainer>):(
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <DocumentCard documentType={(documents && documents.length!==0) ? documents : documenttype} handleFile={handleMesDocs} />
                            </GridItem>
                        </GridContainer>
                    ) 
                }
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Button type="button" onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button type='submit' color='primary' onClick={handleSaveDoc}  >
                        Importer
                        <NavigateNextOutlinedIcon />
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
const updateField = (field, data) => (dispatch) => dispatch(change('addOper', field, data))
const mapPropsActions = {
    updateField,
}
const mapStateToProps = (state) => ({
    user: state.user,
    api: state.api,
    dataForm: getFormValues('addOper')(state),
});
export default connect(mapStateToProps, mapPropsActions)(reduxForm({ form: 'addOper', destroyOnUnmount: false })(ImportDocument))

const documenttype = [
    { titre: "Bulletin de souscription", fichier: "" },
    { titre: "Pièce d\'identité en cours de validité", fichier: "" },
    { titre: "Autres pièces", fichier: "" }
]