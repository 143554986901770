import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import GridItem from "components/Grid/GridItem.js";
import { reduxForm, change, getFormValues, reset } from 'redux-form';
import { connect } from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Assignment, Add, Folder } from "@material-ui/icons/";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { verifRule } from 'application'
import { useHistory,useRouteMatch } from "react-router-dom";
import { Button, IconButton,Paper } from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import { EtatCotisation } from 'redux/actions/apiActions'
import { etatEncaissement } from 'application'
import CustomInput from "components/CustomInput/CustomInput.js";
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import swal from "sweetalert";
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
    root:{
        width:'100%',
        display:'flex',
        flexDirection:'column',
        flex:1
    },

    card: {
        marginBottom: 20,
        borderRadius:10,
        marginRight:20,
        marginLeft:20,
        cursor:'pointer',
        '& :hover': {
            color:'white',
            padding: '10px 20px',
            border: 0,
            textDecoration: 'none',
            background: theme.palette.primary.main,
        }
    },

   
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
    },
    
   
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    hr: {
        marginTop: 10,
        marginBottom: 20,
    },
    corps:{
        width:'60%',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
        [theme.breakpoints.up('md')]: {
            width:'60%',
        },
        display:'flex',
        flexWrap:'wrap',
        marginLeft:'auto',
        marginRight:'auto',
        marginTop:75
    },
    boxItem:{
        display:'flex',
        flexWrap:'wrap'
    },
    cardLogo:{
        display:'flex',
        flexDirection:'column',
        margin:20,
        justifyContent:'center',
        alignItems:'center'
    },
    img:{
        objectFit: 'fill',
        width:50,
        borderRadius:100,
        
    },
    eheader:{
        display:'flex',
        width:'100%',
        paddingTop:20,
        paddingBottom:20,
        backgroundColor:theme.palette.primary.main
    },
    boxheader:{
        display:'flex',
        width:'60%',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
        [theme.breakpoints.up('md')]: {
            width:'60%',
        },
        justifyContent:'space-between',
        alignItems:'center',
        marginLeft:'auto',
        marginRight:'auto'
    },
    btnAdd:{
        background:'transparent !important',
        paddingLeft:20,
        paddingRight:20,
        color:'white',
        border:'1px solid white',
        borderRadius:0,
        marginLeft:20,
        "&:hover":{
            backgroundColor:theme.palette.secondary.main,
        }
    },
    editContainer:{
        position:"absolute",
        width:"100%",
        height:'100%',
        display:'flex',
        alignItems:'center',
        backgroundColor:"rgba(0,0,0,0.3)",
        top:0,
        right:0,
        zIndex:2000
    },
    boxEdit:{
        width:'40%',
        [theme.breakpoints.down('sm')]:{
            width:'75%',
        },
        [theme.breakpoints.down('xs')]:{
            width:'100%',
        },
        marginLeft:'auto',
        marginRight:"auto",
        boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3)",
    },
    editCorps:{
        display:'flex',
        alignItems:'center',
        padding:20,
        marginTop:20,
        marginBottom:30,
        marginRight:20
    },
    btnEdit:{
        backgroundColor:theme.palette.primary.main,
        "&:hover":{
            backgroundColor:theme.palette.secondary.main,
        },
        marginLeft:20,
        marginTop:20
    }
}));
function ProdFonctions(props) {
    const classes = useStyles();
    const { user: { privileges,credentials }} = props
   
    const {codepartenaire}=credentials

    let history = useHistory();
    const {path,url} = useRouteMatch()

    const nouvelleproposition = () => {
        props.dispatch(reset('adForm'))
        history.push(`${path}/simulateurs`)
    }
    const handleClickOption =(act)=>{
        switch (act) {
            case "PROD":
                history.push(`${path}/production`)
                break;
            case "CONTRAT":
                history.push(`${path}/contrats`)
                break;
            case "SEARCH":
                history.push(`${path}/recherche-contrat`)
                break;
            case "COTISATION":
                history.push(`${path}/etat-cotisation`)
                break;
            default:
                break;
        }
    }
    const[loading,setLoading] = useState(false)
    const [open,setOpen] = useState(false)
    const handleOpen =()=>{
        setOpen(true)
    }
    const handleClose =()=>{
        setOpen(false)
    }
    const [inputField,setInputField] = useState("")

    const handleEtatCotisation = () => {
        if (!inputField && inputField===""){
            swal("Attention","Veuillez fournir un id de contrat","warning")
            return
        }
        EtatCotisation({ idContrat: inputField }).then(res => {
          if (res) {
            etatEncaissement(res)
          }else{
            swal("Oopps","Aucun contrat trouvé","warning")  
          }
        }).catch(err=>{
            swal("Erreur","Une erreur s'est produite","error")
        })
    }

 
    return (
        <div className={classes.root}>
            
            {open&&<div className={classes.editContainer}> 
                    <Paper className={classes.boxEdit}>
                        <div style={{display:'flex',padding:20,justifyContent:'space-between'}}>
                            <Typography variant="h5">Edition état de cotisation</Typography>
                            <IconButton onClick={handleClose}><CloseIcon /></IconButton>
                        </div>
                        <Divider />
                        <div className={classes.editCorps}>
                            <CustomInput
                                required
                                id="idproposition"
                                name="idproposition"
                                label="Id contrat"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                value={inputField}
                                type='numeric'
                                InputProps={{
                                    inputProps: { min: 3 },
                                }}
                                onChange={(event)=>setInputField(event.target.value)}
                                variant="outlined"
                            />
                            <Button disabled={inputField=="" || loading} onClick={handleEtatCotisation} className={classes.btnEdit}>
                                {loading?<CircularProgress color="secondary" />:<LocalPrintshopIcon style={{fontSize:40,color:'white'}} />}
                            </Button>
                        </div>
                    </Paper>
            </div>}
            <div className={classes.eheader}>
                <div className={classes.boxheader}>
                    <div className={classes.cardLogo}>
                        <img className={classes.img} src={require(`assets/images/${(codepartenaire &&codepartenaire!=='')?codepartenaire+'.jpg':"LLV.jpg"}`).default} />
                        <span style={{fontStyle:'bold',color:'white',fontSize:26}}>{credentials['partenaire']||""}</span>
                    </div>
                    <span style={{fontStyle:'bold',color:'white',fontSize:30}}>Bienvenue sur e-Souscription</span>
                    <Button className={classes.btnAdd} onClick={nouvelleproposition}>  <Add/>Nouveau</Button>
                </div>
            </div>
        
            <div className={classes.corps}>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Typography variant='h5'>Gestion de la production</Typography>
                    <Divider className={classes.hr} />
                </GridItem>

                <div className={classes.boxItem}>
                    {verifRule('prop', 'add', privileges) && (
                        <Card className={classes.card} onClick={nouvelleproposition}>
                            <CardContent>
                                <div className={classes.avatar}>
                                    <Avatar aria-label="recipe" className={clsx(classes.large)}>
                                        <Add style={{fontSize:40}} />
                                    </Avatar>
                                </div>
                                <Typography align='center' variant="h5" component="h5">
                                    Nouvelle proposition
                                </Typography>
                                <Typography align='center' variant="caption" color="textSecondary" component="p">
                                
                                </Typography>
                            </CardContent>
                        </Card>)}
                    {verifRule('prop','read',privileges)&& (
                        <Card className={classes.card} onClick={event => handleClickOption('PROD')}>
                            <CardContent>
                                <div className={classes.avatar}>
                                    <Avatar aria-label="recipe" className={clsx(classes.large)}>
                                        <Folder style={{fontSize:40}} />
                                    </Avatar>
                                </div>
                                <Typography align='center' variant="h5" component="h5">
                                    Mes propositions
                                </Typography>
                                <Typography align='center' variant="caption" color="textSecondary" component="p">
                                    Gestion des propositions (Liste,transmettre,editer, ...)
                                </Typography>
                            </CardContent>
                        </Card>)}
                </div>

                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Typography variant='h5'>Contrats</Typography>
                    <Divider className={classes.hr} />
                </GridItem>
                <div className={classes.boxItem}>
                    {verifRule('ctr','read',privileges)&&(
                        <Card className={classes.card} onClick={event => handleClickOption('CONTRAT')}>
                            <CardContent>
                                <div className={classes.avatar}>
                                    <Avatar aria-label="recipe" className={clsx(classes.large)}>
                                        <Assignment style={{fontSize:40}} />
                                    </Avatar>
                                </div>
                                <Typography align='center' variant="h5" component="h5">
                                    Mes contrats
                                </Typography>
                                <Typography align='center' variant="caption" color="textSecondary" component="p">
                                    Gestion des contrats (liste, cotisation,...)
                                </Typography>
                            </CardContent>
                        </Card>
                    )}
                    {verifRule('ctr','search',privileges)&&(
                        <Card className={classes.card} onClick={event => handleClickOption('SEARCH')}>
                            <CardContent>
                                <div className={classes.avatar}>
                                    <Avatar aria-label="recipe" className={clsx(classes.large)}>
                                        <FindInPageIcon style={{fontSize:40}} />
                                    </Avatar>
                                </div>
                                <Typography align='center' variant="h5" component="h5">
                                    Rechercher un contrat
                                </Typography>
                                <Typography align='center' variant="caption" color="textSecondary" component="p">
                                    Trouver des contrats,editer les états de cotisation
                                </Typography>
                            </CardContent>
                        </Card>
                    )}
                    <Card className={classes.card} onClick={handleOpen}>
                        <CardContent>
                            <div className={classes.avatar}>
                                <Avatar aria-label="recipe" className={clsx(classes.large)}>
                                    <DescriptionIcon style={{fontSize:40}} />
                                </Avatar>
                            </div>
                            <Typography align='center' variant="h5" component="h5">
                                Editer un état de cotisation
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    )
}


const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    ui: state.UI,
    data: getFormValues('adForm')(state)
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({ destroyOnUnmount: false, })(ProdFonctions))