import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Paper, Typography,Divider,IconButton, Badge, Checkbox, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import Card from '@material-ui/core/Card';
import AppsIcon from '@material-ui/icons/Apps';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CardContent from '@material-ui/core/CardContent';
import ExtensionIcon from '@material-ui/icons/Extension';
import AccessibleForwardIcon from '@material-ui/icons/AccessibleForward';
import EvolutionProduction from 'pages/stats/EvolutionProduction'
import ProductionParproduit from 'pages/stats/ProductionParproduit'
import {connect} from 'react-redux'
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import { getTotProductionAgent } from 'redux/actions/apiActions'
import { useHistory } from 'react-router-dom';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import OperationStat from 'pages/Operations/components/OperationStat';
import PretStat from 'pages/Banking/Prets/components/PretStat';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import CreateIcon from '@material-ui/icons/Create';
import PeopleIcon from '@material-ui/icons/People';
import SendIcon from '@material-ui/icons/Send';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import { green, red } from '@material-ui/core/colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Ask from 'pages/Commercial/components/ask';
import { getPatientsMedecin } from 'redux/actions/apiActions';
import dayjs from 'dayjs';
import BlockIcon from '@material-ui/icons/Block';
const useStyle = makeStyles(theme=>({
    root:{
       display:'flex',
       flexDirection:'column'
    },
    homhead:{
        width:'100%',
       marginBottom:20,
       marginTop:0,
       backgroundColor:theme.palette.primary.main,
    },
    hpaper:{
        width:'85%',
        marginRight:'auto',
        marginLeft:'auto',
        [theme.breakpoints.down('sm')]: {
          width:'100%',
        },
        [theme.breakpoints.up('md')]: {
          width:'75%',
        },
        [theme.breakpoints.up('lg')]: {
          width:'60%',
        },
        marginTop:20,
        padding:20,
        border:'none',
        background:'transparent !important'
      
    },
    servicesBox:{
        display:'flex',
        flexDirection:'column',
        marginRight:'auto',
        marginLeft:'auto',
        [theme.breakpoints.down('sm')]: {
          width:'100%',
        },
        [theme.breakpoints.up('md')]: {
          width:'75%',
        },
        [theme.breakpoints.up('lg')]: {
          width:'50%',
        },
        marginTop:30,
        padding:20,
        
    },
    marge:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    texte:{
      color:'#fff'
    },
    card:{
      display:'flex',
      flex:1,
      flexDirection:'column',
      justifyContent:'space-around',
      padding:20,
      margin:20,
      alignItems:'center'
    },
    activityCard:{
      padding:20,
      margin:20,
    },
    activityContent:{
      display:'flex',
      justifyContent:'sapce-between'
    },
    activityItem1:{
      flex:2,
      margin:10
    },
    activityItem2:{
      flex:1,
      margin:10
    },
    mainitem:{
        display:'flex',
        flexDirection:'column',
        boxShadow: "-4px 6px 15px 5px rgba(171,171,171,0.3);",
        paddingBottom:20
    },
    iheader:{
        display:'flex',
        marginBottom:10,
        alignItems:'center',
        padding:10
    },
    icorps:{
        marginTop:10,
        padding:20,
        display:'flex'
    },
    icard:{
        display:'flex',
        justifyContent:'center',
        flex:1,
        "&:hover":{
            boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3);"
        },
        cursor:'pointer',
        margin:20
    },
    iStat:{
        display:'flex',
        marginTop:20,
        marginBottom:20
    },
    igraph:{
        display:'flex',
        justifyContent:'space-around'
    },
    cardLogo:{
        position:'absolute',
        top:-50,
        width:100,
        height:100
    },
    img:{
        objectFit: 'fill',
        width:100
    },
    cardState:{
        display:'flex',
        width:'100%',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
    cardpar:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        boxShadow: "-4px 6px 15px 5px rgba(171,171,171,0.3)",
        marginBottom:20,
        position:'relative',
        padding:20,
        marginTop:70
    },
    itcard:{
        display:'flex',
        flex:1,
        boxShadow: "-4px 6px 15px 5px rgba(171,171,171,0.3);",
        "&:hover":{
            boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3)"
        },
        cursor:'pointer'
    },
    numberState:{
        fontSize:35,
        color:theme.palette.primary.main,
        textAlign:'center'
    },
    numberAj:{
        fontSize:35,
        color:red[500],
        textAlign:'center'
    },
    labelState:{
        color:'#666',
        fontWeight:'bold',
        textAlign:'center'
    },
    cardadd:{
        marginLeft:20,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:theme.palette.primary.main,
        borderRadius:0,
        boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3);",
        cursor:'pointer',
        "&:hover":{
            backgroundColor:theme.palette.secondary.main,
            boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3);"
        }
    },
    stateBox:{
        display:'flex',
        padding:20,
        marginBottom:20,
        border:'0px 10px 0px 10px',
        borderRadius:'0px 20px 0px 20px',
        borderColor:green[500],
        boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3);"
    },
    cardrapide:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        borderRadius:0,
        flex:1,
        cursor:'pointer',
        "&:hover":{
            backgroundColor:theme.palette.primary.main,
            color:'white'
        }
    },
    contentar:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
   
  }))

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
const ListePatient = (props) => {
    const classes = useStyle()
    const history = useHistory()
    const [patientList,setPatientList] = useState(null)
    const handlePatients =()=>{
        const dataSend = {rapportOk:0}
        getPatientsMedecin(dataSend).then(res=>{
            if(res)setPatientList(res)
        })
    }
    useEffect(()=>{
        handlePatients()
    },[])
    const handlerapport =(pat)=>{
        props.history.push('/medecin/add-rapport-medical',{patient:pat});
    }
  return (
    <div className={classes.root}>
          <div className={classes.homhead}>
            <Paper className={classes.hpaper} variant="outlined">
                    <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                        <IconButton onClick={()=>history.push('/medecin/accueil')}>
                            <ArrowBackIcon style={{fontSize:40,color:'white'}} />
                        </IconButton>
                        <Typography className={classes.texte} variant='h4'> Liste des nouveaux patients</Typography>
                    </div>
            </Paper> 
        </div>
        <div className={classes.servicesBox}>
            <Ask>
                <Ask.Inner>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Patient</StyledTableCell>
                                <StyledTableCell>Contacts</StyledTableCell>
                                <StyledTableCell align="center">Date du dossier</StyledTableCell>
                                <StyledTableCell align="center">Examens démandés</StyledTableCell>
                                <StyledTableCell align="center">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                            <TableBody>
                                {(patientList && patientList.length !==0) ? (
                                    patientList.map(patient=><StyledTableRow>
                                        <StyledTableCell component="th" align="right">
                                            {`${patient.adherent.nom} ${patient.adherent.prenom}`}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {`${patient.adherent.mobile} /${patient.adherent.mobile}`}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {dayjs(patient.saisiele).format('DD/MM/YYYY')}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {`${patient.examens}`}
                                        </StyledTableCell>
                                        
                                        <StyledTableCell align="center">
                                            <div style={{display:'flex'}}>
                                           <Tooltip title="Rediger un rapport">
                                                <IconButton onClick={()=>handlerapport(patient)}>
                                                <CreateIcon style={{ color:'#f9b233'}} />
                                                </IconButton>
                                           </Tooltip>
                                           <Tooltip title="Annuler la prestation">
                                                <IconButton>
                                                <BlockIcon style={{ color:red[500]}}/>
                                                </IconButton>
                                           </Tooltip>
                                           </div>
                                        </StyledTableCell>
                                    </StyledTableRow>)
                                ) : (
                                    <StyledTableRow>
                                    <StyledTableCell colSpan={6} align="center">
                                       <p>Aucun médecin trouvé</p>
                                    </StyledTableCell>
                                   
                                </StyledTableRow>
                                )}
                                
                            </TableBody>
                       
                    </Table>
                </TableContainer>
                </Ask.Inner>
            </Ask>
        </div>
    </div>
  )
}

export default ListePatient
