import React from 'react';
import clsx from 'clsx';
import { Switch, Route, useHistory,useRouteMatch,Redirect } from "react-router-dom";
import {connect} from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import logo from "assets/images/logoYnovGreen.png";
import classNames from "classnames";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Badge, Button } from '@material-ui/core';
import logo2 from "assets/images/faviconGreen.png";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChangePassword from 'pages/Dashboard/ChangePassword'
import Footer from "components/Footer/Footer.js";
import Welcome from 'pages/Dashboard/Welcome';
import ExtensionIcon from '@material-ui/icons/Extension';
import AccessibleForwardIcon from '@material-ui/icons/AccessibleForward';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SchoolIcon from '@material-ui/icons/School';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks";
import Esouscription from './Esouscription';
import Eprestation from './Eprestation';
import Esinistre from './Esinistre';
import Epret from './Epret';
import SettingsIcon from '@material-ui/icons/Settings';
import AdminHome from 'pages/Banking/Administration/AdminHome'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AppsIcon from '@material-ui/icons/Apps';
import EtatHome from 'pages/Banking/Etats/EtatHome';
import OperationJournal from 'pages/Commons/Journaux/OperationJournal';
import OperationRapport from 'pages/Commons/Rapport/OperationRapport';
import FormationHome from 'pages/Banking/Formation/FormationHome';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MedecinHome from 'pages/Medecin/MedecinHome'
import AddRapportMedical from 'pages/Medecin/AddRapportMedical'
import HistoriqueRapport from 'pages/Medecin/HistoriqueRapport'
import ListePatient from 'pages/Medecin/ListePatient'

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  titre:{
    fontSize:16,
    fontWeight:'bold',
    color:theme.palette.primary.main,
    marginLeft:10
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor:"white"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color:theme.palette.primary.main
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 10,
    whiteSpace: 'wrap',
    backgroundColor:theme.palette.primary.main
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  too:{
    padding: theme.spacing(1, 1),
  },

  content: {
    flexGrow: 1,
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-between',
    padding: theme.spacing(5,0),
  },
  

  logoContainer:{
    width:'150px'
   },
    img:{
        width:'100%',
        cursor:'pointer'
    },
    imgicon:{
        width:'50%',
        cursor:'pointer'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
      },
      sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      btnnew:{
        backgroundColor:theme.palette.secondary.main,
        borderRadius:50,
        padding:"10px 20px",
        textTransform: 'capitalize',
        "&:hover":{
            backgroundColor:theme.palette.primary.main,
        }
      },
      iconMenu:{ 
        color:theme.palette.primary.main,
        fontSize:40, 
        "&:hover":{
          color:theme.palette.secondary.main,
        }
      },
      item:{
        backgroundColor:theme.palette.secondary.main,
        "&:nth-child(-n + 3)":{
          color:'white'
        }
      },
}));

let ps;



function SimpleLayout(props) {
  const { ui: { layoutRoot },user:{credentials},titre } = props
  const classes = useStyles();
  const theme = useTheme();
  const {path,url} =useRouteMatch()
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const history = useHistory()
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const [cmenu,setCmenu] =React.useState('home')
  const handleSelectMenu =(op)=>{
    setCmenu(op)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {[classes.appBarShift]: open,})}
      >
        <Toolbar>
            <div className={classes.logoContainer}>
                <a
                  href={`/${layoutRoot}`}
                  className={classNames(classes.logoLink, {
                  [classes.logoLinkRTL]: props.rtlActive
                  })}
                >
                    <img src={logo} alt="Logo" className={classes.img} />
                </a>
                <span className={classes.titre}>{titre?titre:"Espace medecin"}</span>
            </div>
            <div className={classes.grow} />
            
            <div className={classes.grow} />
          
            <div className={classes.sectionDesktop}>
              <AdminNavbarLinks />
                {/**
                 <IconButton aria-label="show 4 new mails" color="inherit">
                <Badge badgeContent={4} color="primary">
                    <MailIcon />
                </Badge>
                </IconButton>
                <IconButton aria-label="show 17 new notifications" color="inherit">
                <Badge badgeContent={17} color="primary">
                    <NotificationsIcon />
                </Badge>
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  color="inherit"
                >
                <AccountCircleIcon />
                </IconButton>
                */}
            </div>
             
        </Toolbar>
      </AppBar>
      
        <main className={classes.content}>
          <div className={classes.too} />
            <div style={{dispaly:'flex',justifyContent:'flex-end'}}>
                <Switch>
                  <Route path={`${path}/accueil`} component={MedecinHome} name='Accueil espace medecin' />
                  <Route path={`${path}/add-rapport-medical`} component={AddRapportMedical} name='Rediger un rapport' />
                  <Route path={`${path}/liste-rapport-medical`} component={HistoriqueRapport} name='Liste des rapports' />
                  <Route path={`${path}/liste-patient`} component={ListePatient} name='Liste des patients' />
                  <Redirect from={`/${layoutRoot}`} to="/medecin/accueil"/>
                </Switch>
            </div> 
            <Footer />
        </main>
        
    </div>
  );
}

const mapStateToProps = (state) => ({
  ui: state.UI,
  user: state.user
});

const mapActionsToProps = {}
export default connect(mapStateToProps, mapActionsToProps)(SimpleLayout)