import React, { useState, useEffect, useCallback } from 'react'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table'
import { connect } from 'react-redux'
import {getBulletinComList,getBulletinComDetail} from "redux/actions/userActions"
import swal from 'sweetalert';
import { Typography } from '@material-ui/core';
import {bulletinCommission} from 'application'
function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

const useStyle = makeStyles((theme) => ({
    filterBox:{
        display:'flex',
        justifyContent:'space-between'
    },
    textField: {
        marginRight:10
    },
    buttonSearch: {

    },
    agentBox:{
        display:'flex',
        backgroundColor :"#ffe7d9",
        justifyContent:"space-between",
        color:"#7a0c2e",
        padding:"10px 20px",
        [theme.breakpoints.down('sm')]: {
            flexDirection:'column'
        },
    },
    row:{
        display:'flex',
        alignItems:'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection:'column',
        },
    },
    col:{
        display:'flex',
        flexDirection:'column'
    }
}))
//#b72136
function CommissionList({user}) {
    const {credentials} =user
    const classes = useStyle()
    const [inputField, setInputField] = useState({})
    const handleFilterChange = (event) => {
        setInputField({ ...inputField, [event.target.name]: event.target.value })
    }
    const [infoAgent,setInfosAgent] =useState(null)
    const [comList, setComList] = useState([])
    const handleComList = useCallback(() => {
        if(credentials){
            const dataSend = {codeagent:credentials.codeagent}
            if(inputField['periodedu'] && inputField['periodeau']){
                dataSend["periodeDeb"] = inputField.periodedu
                dataSend["periodeFin"] = inputField.periodeau
            }
            getBulletinComList(dataSend).then(res=>{
                if(res){
                    setComList(res) 
                    setInfosAgent(res['ficheAgent'][0])
                }else{
                    swal({
                        title: "Erreur",
                        text: "Aucune donnée trouvé",
                        icon: "warning",
                        button: "OK",
                        dangerMode: true,
                      });
                }
            })
        }
    }, [user])
    
    useEffect(()=>{
        handleComList()
    },[])
    const handlePrintBulletin =(ref)=>{
        getBulletinComDetail(ref).then(res=>{
            if(res){
                bulletinCommission(res,credentials,`${ref['periodeDeb']} - ${ref['periodeFin']}`)
            }else{
                swal({
                    title: "Erreur",
                    text: "Aucune donnée trouvé",
                    icon: "warning",
                    button: "OK",
                    dangerMode: true,
                  });
            }
        })
    }
    return (
        <GridContainer>
            {infoAgent&& <GridItem xs={12} sm={12} md={12} lg={12}>
                <Paper elevation={0} className={classes.agentBox}>
                    <div className={classes.col}>
                        <div className={classes.row} >
                            <Typography variant='h6'>Code :</Typography> 
                            <Typography color='#7a0c2e' variant='body2'>{infoAgent['CodeIntermediaire'] || ""}</Typography>
                        </div>
                        <div className={classes.row} >
                            <Typography variant='h6'>Reseau :</Typography>
                            <Typography color='#7a0c2e' variant='body2'>{infoAgent['NomInspection'] || ""}</Typography>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.row} >
                            <Typography variant='h6'>Nom et Prénoms :</Typography>
                            <Typography color='#7a0c2e' variant='body2'>{infoAgent['NomAgent'] || ""}</Typography>
                        </div>    
                        <div className={classes.row} >
                            <Typography variant='h6'>Equipe :</Typography>
                            <Typography color='#7a0c2e' variant='body2'>{infoAgent['NomEquipe'] || ""}</Typography>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.row} >
                            <Typography variant='h6'>Fonction :</Typography>
                            <Typography color='#7a0c2e' variant='body2'>{infoAgent['fonctionAgent'] || ""}</Typography>
                        </div>
                        <div className={classes.row} >
                            <Typography variant='h6'>Famille :</Typography>
                            <Typography color='#7a0c2e' variant='body2'>{infoAgent['familleReseau'] || ""}</Typography>
                        </div>
                    </div>
                </Paper>
               
            </GridItem>}
            
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <Paper>
                    <LabelledOutline>
                        <div className={classes.filterBox}> 
                        <div>
                            <TextField
                                id="periodedu"
                                name="periodedu"
                                label="Prédiode du"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                            <TextField
                                id="periodeau"
                                name="periodeau"
                                label="Au"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                        </div>
                        <Button variant='outlined' color='primary' className={classes.buttonSearch} onClick={handleComList} >
                            <RefreshIcon fontSize="large" /> AFFICHER
                        </Button>
                        </div>
                    </LabelledOutline>
                </Paper>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                    <MaterialTable
              columns={[
                { title: '#', field: 'IDTblReseauCommissionPaie' },
                { title: 'Reseau', field: 'Reseau' },
                { title: 'Unite', field: 'Unite' },
                { title: 'Fonction', field: 'CodeFonction' },
                { title: 'Début période', field: 'PeriodeDateDebut' },
                { title: 'Fin période', field: 'PeriodeDateFin' },
                { title: 'Montant (F CFA)', field:'NetApayer',render:(rowData)=>formatNumber(parseInt(rowData.NetApayer)) }
              ]}
              data={comList?comList['bulletins']:[]}
              title='Liste des bulletins'
              localization={{
                toolbar: {
                  searchPlaceholder: 'Rechercher',
                  searchTooltip: 'Rechercher'
                },
                body: {
                  emptyDataSourceMessage: 'Aucune donnée trouvée !'
                },
                pagination: {
                  labelRowsSelect: 'Ligne(s)',
                  labelDisplayedRows: '{count} sur {from}-{to}',
                  firstTooltip: 'Prémière page',
                  previousTooltip: 'Précédent',
                  nextTooltip: 'Suivant',
                  lastTooltip: 'Dernière page'
                }
              }}

              options={{
                exportButton: true,
                searchFieldStyle: {
                  fontSize: 18,
                  width: '100%',
                  height: 50,
                },
                actionsColumnIndex: -1
              }}
              actions={[
                {
                    icon: 'print',
                    tooltip: 'Imprimer',
                    onClick: (event, rowData) =>handlePrintBulletin({codeagent:rowData.CodeIntermediaire,periodeDeb:rowData.PeriodeDateDebut,periodeFin:rowData.PeriodeDateFin})
                  },
              ]}

            />
            </GridItem>

        </GridContainer>
    )
}

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps)(CommissionList)