import React,{useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {formatNumber} from 'application'
import { green } from '@material-ui/core/colors';
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  margin: {
    height: theme.spacing(3),
  },
  customWidth: {
    maxWidth: 500,
  },
  inputField:{
    display:"flex"
  },
  textInput:{
    fontSize:50
  }
}));
function ValueLabelComponent(props) {
  const { children, open, value } = props;
  const classes = useStyles();
  return (
    <Tooltip open={open} classes={{ tooltip: classes.customWidth }} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};
const PrettoSlider = withStyles({
  root: {
    color: green[400],
    height: 8,
  },
  thumb: {
    height: 30,
    width: 30,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 15,
    borderRadius: 4,
  },
  rail: {
    height: 15,
    borderRadius: 4,
  },
})(Slider);





export default function CustomSlider({titre,min,max,input,...restProps}) {
   
    const classes = useStyles();
    const [value, setValue] = React.useState(input?(input.value?input.value:min):(restProps.hasOwnProperty('value')?restProps.value:min));

    const handleSliderChange = (event, newValue) => {
      const eValue = newValue&&newValue.toString().replace(/ /g,'')
      const currentValue = isNaN(Number(eValue))?"":(eValue === '' ? '' : formatNumber(Number(eValue)))
      setValue(currentValue);
      if(input){
        input.onChange(currentValue)
      }else{
        restProps.hasOwnProperty('onChange')&&restProps.onChange(currentValue)
      }
    };
  
    const handleInputChange = (event) => {
      const eValue = event.target.value.replace(/ /g,'')
      const currentValue = isNaN(Number(eValue))?"":(eValue === '' ? '' : formatNumber(Number(eValue)))
      setValue(currentValue);
      if(input){
        input.onChange(currentValue)
      }else{
        restProps.hasOwnProperty('onChange')&&restProps.onChange(currentValue)
      }
    };
  
    const handleBlur = (e) => {
      const eValue = value.toString().replace(/ /g,'')
      if (Number(eValue) < min) {
        setValue(formatNumber(min));
        input?input.onChange(formatNumber(min)):(restProps.hasOwnProperty('onChange')&&restProps.onChange(formatNumber(min)))
      } else if (value > max) {
        setValue(formatNumber(max));
        input?input.onChange(formatNumber(max)):(restProps.hasOwnProperty('onChange')&&restProps.onChange(formatNumber(max)))
      }
    };
    const handleAdd =()=>{
      const eValue = value.toString().replace(/ /g,'')
      if (Number(eValue) > max) {
        setValue(formatNumber(max));
        input?input.onChange(formatNumber(max)):(restProps.hasOwnProperty('onChange')&&restProps.onChange(formatNumber(max)))
      } else {
        setValue(formatNumber(Number(eValue)+1));
        input?input.onChange(formatNumber(Number(eValue)+1)):(restProps.hasOwnProperty('onChange')&&restProps.onChange(formatNumber(Number(eValue)+1)))
      }
    }
    const handleMinus =()=>{
      const eValue = value.toString().replace(/ /g,'')
      if (Number(eValue) < min) {
        setValue(formatNumber(min));
        input?input.onChange(formatNumber(min)):(restProps.hasOwnProperty('onChange')&&restProps.onChange(formatNumber(min)))
      } else {
        setValue(formatNumber(Number(eValue)-1));
        input?input.onChange(formatNumber(Number(eValue)-1)):(restProps.hasOwnProperty('onChange')&&restProps.onChange(formatNumber(Number(eValue)-1)))
      }
    }

    return (
      <div className={classes.root}>
        
        <Typography gutterBottom>{titre}</Typography>
        <Grid className={classes.inputField} item>
          <IconButton  color='primary' size="medium" onClick ={handleMinus}>
            <RemoveIcon/>
          </IconButton>
          <TextField
            value={value&&value}
            required={restProps.required}
            size="large"
            onChange={handleInputChange}
            onBlur={handleBlur}
            variant="outlined"
            fullWidth
            inputProps={{
              step: 100,
              min: min,
              max: max,
              type: 'text',
              'aria-labelledby': 'input-slider',
              classes: {
                input: classes.textInput,
              },
            }}
          />
          <IconButton size="medium" color='primary' onClick ={handleAdd}><AddIcon /></IconButton>
        </Grid>
        <PrettoSlider 
          value={value&&value.toString().replace(/ /g,'')} 
          onChange={handleSliderChange} 
          ValueLabelComponent={ValueLabelComponent} 
          min={min} 
          max={max} 
          valueLabelDisplay="auto" 
          aria-label={`${titre} slider`} 
          defaultValue={min} />
        
      </div>
    );
  }