import React, { useReducer,useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from "@material-ui/core/Typography";
import MaterialTable,{MTableToolbar } from 'material-table';
import {connect,useDispatch} from 'react-redux'
import CustomMenu from './CustomMenu'
import {Add} from '@material-ui/icons';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import {getPrivilegeRoleByModule,getPrivilegeModule,updateRole,updatePrivilegeRole,getModulesReseau} from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import LabelledOutline from 'components/LabelledOutline/LabelledOutline'

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import GridList from '@material-ui/core/GridList';
import FormGroup from '@material-ui/core/FormGroup';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));
  const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
    
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);

  function OptionsPrivileges (props){
      
    const {privilege,desactiverPrivilege} = props
    const [isChecked,setIsChecked] = useState(privilege.check)
    const handleChecked =(event)=>{
      setIsChecked(event.target.checked)
      desactiverPrivilege(privilege)
    }
    return(
      <FormControlLabel
          control={<Checkbox checked={isChecked} onClick={handleChecked} name={privilege.key} />}
          label={privilege.libelleprivilege}
      />
    )
}
function UpdateRole(props){
    const classes = useStyles();
    const theme = useTheme();
    //intialisation des élement du state
    const {roleData,mopen,handleCloseMod,handlemopen,reseaux}=props //recuperation des valieur du props
    const dispatch = useDispatch()
    const [loading,setLoading]=useState(false)
    const [newRoleStep,setNewRoleStep]=React.useState(1); //gère les étape d'ajout d'un nouveau module
    const [privilegesChecked,setPrivilegesChecked] =React.useState([]); // géré la selection de module existant
    const [privilegesModChecked,setPrivilegesModChecked] =React.useState([]); // géré la selection de module existant
    const handlePrivilegesCheked = (refModule)=>{
        let newPrivilege =[...privilegesChecked]
        setLoading(true)                   
        return getPrivilegeRoleByModule(roleData.id,refModule).then(dataPrivilege=>{
            if(dataPrivilege){
                dataPrivilege.forEach(el=>{
                    if(el){
                        const thisindx = `${el.codemodule}_${el.codeprivilege}`
                        if(newPrivilege.length!==0){
                            const indexFound = newPrivilege.findIndex(item=>item.key===thisindx)
                            if(indexFound ===- 1){
                                newPrivilege.push({codemodule:el.codemodule,coderole:el.coderole,codeprivilege:el.codeprivilege,libelleprivilege:el.libelleprivilege,key:`${el.codemodule}_${el.codeprivilege}`,check:el.estactif==1?true:false})
                            }else{
                                newPrivilege[indexFound]={codemodule:el.codemodule,coderole:el.coderole,codeprivilege:el.codeprivilege,libelleprivilege:el.libelleprivilege,key:`${el.codemodule}_${el.codeprivilege}`,check:el.estactif==1?true:false}
                            }
                        }else{
                            newPrivilege.push({codemodule:el.codemodule,coderole:el.coderole,codeprivilege:el.codeprivilege,libelleprivilege:el.libelleprivilege,key:`${el.codemodule}_${el.codeprivilege}`,check:el.estactif==1?true:false})
                        }
                    }
                })
            }  
            setLoading(false)  
            return newPrivilege
        })
    } 
    const handlePrivilegesModuleChecked =(refModule)=>{
        let newPrivilege =[...privilegesModChecked] 
        setLoading(true)                        
        return getPrivilegeModule(refModule).then(dataPrivilege=>{
           
            if(dataPrivilege){
                dataPrivilege.forEach(el=>{
                    if(el){
                        const thisindx = `${refModule}_${el.id}`
                        if(newPrivilege.length!==0){
                            const indexFound = newPrivilege.findIndex(item=>item.key===thisindx)
                            if(indexFound===-1){
                                newPrivilege.push({codemodule:refModule,coderole:roleData.id,codeprivilege:el.id,libelleprivilege:el.libelle,key:`${refModule}_${el.id}`,check:false})
                            }else{
                                const currentPri = newPrivilege[indexFound]
                                
                                currentPri.check = false
                                newPrivilege.splice(indexFound, 1);
                                newPrivilege.push(currentPri)
                            }
                        }else{
                            newPrivilege.push({codemodule:refModule,coderole:roleData.id,codeprivilege:el.id,libelleprivilege:el.libelle,key:`${refModule}_${el.id}`,check:false})    
                        }
                    }
                })
            }
            setLoading(false)
            return newPrivilege
        })
    }
    const desactiverPrivilege =(row)=>{
        const newChecked = (privilegesChecked && privilegesChecked.length!==0)?privilegesChecked:privilegesModChecked
        const indexRow = newChecked.findIndex(item=>item.key===row.key)
        if(indexRow!==-1){
            const currentRow = newChecked[indexRow];
            currentRow.check= !row.check
            newChecked[indexRow] = currentRow
        }else{

        }
        setPrivilegesChecked(newChecked)
    }
   
    const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
    role:roleData.role?roleData.role:"",
    codereseau:roleData.idreseau?roleData.idreseau:''
    });
    
  const handleChange = (event)=>{
    const {name,value} = event.target
    setInputField({
        [name]:value
    })
  }
  
  //selection des modules 
  const [modulesReseau, setModulesReseau] = React.useState([]);
  const handleModulesReseau =(data)=>{
      if(data){
          getModulesReseau(data.codereseau).then(datamr=>{
              if(datamr){
                  let allCheckedPri =[]
                setModulesReseau(datamr)
                //privilèges checked
                const mycheData = Promise.all(datamr.map(element => {return handlePrivilegesCheked(element.id)}))
                mycheData.then(newData=>{
                    for (const value of newData) {
                        allCheckedPri =value?[...allCheckedPri,...value]:allCheckedPri
                    }
                   
                    setPrivilegesChecked([...allCheckedPri])
                })
                //privilèges modules
                let allCheckedModPri =[]
                const mymodData = Promise.all(datamr.map(elt => {return handlePrivilegesModuleChecked(elt.id)}))
                mymodData.then(newData=>{
                    for (const value of newData) {
                        allCheckedModPri =[...allCheckedModPri,...value]
                    }
                    setPrivilegesModChecked([...allCheckedModPri])
                })
              }
          })
      }
  }

  //savegade roles
  const handleSaveRole =(event)=>{
   
    if(inputField.codereseau==='')return
    if(inputField.role.trim()==='')return
    setLoading(true)
    updateRole(roleData.id, inputField).then(dataRole=>{
        if(dataRole){
            savePrivilege()
            setLoading(false)
            setInputPrivilege({
                ['coderole']:dataRole.id,
                ['libellerole']:dataRole.role,
                ['codereseau']:roleData.idreseau
            })
        }else{
            return false
        }
    }).catch(err=>{
        setLoading(false)
        console.log(err);
        return
    })
    event.stopPropagation()
    return 
  }
  
 //fonctions et etats lié au privilèges
const [inputPrivilege,setInputPrivilege]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
        coderole:"",
        libellerole:"",
        codereseau:""
    });

    const savePrivilege =() =>{
        if(privilegesChecked.length===0) return
        updatePrivilegeRole(roleData.id,privilegesChecked).then(prilegesdata=>{
            handleCloseMod()
        }).catch(err=>{
            console.log(err);
            return
        })
    }

useEffect(() => {
    if(roleData){
        const myData = {codereseau:roleData.idreseau}
        handleModulesReseau(myData)
    }
}, [roleData])

  return (
      <Dialog
          maxWidth='md'
          open={mopen}
          disableBackdropClick 
          disableBackdropClick
          onClose={handleCloseMod} 
          fullWidth
          aria-describedby="alert-dialog-description"
          aria-labelledby="form-dialog-title">
            <AppBar className={classes.appBar}>
              <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={handleCloseMod} aria-label="close">
                  <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                      Modification  rôle # {roleData.role}
                  </Typography>
              </Toolbar>
            </AppBar>
                <DialogContent>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                        <LabelledOutline label="Information sur le role">
                            <GridContainer>
                            
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <CustomInput
                                        autoFocus
                                        required
                                        variant="outlined"
                                        id="role"
                                        name ='role'
                                        onChange={handleChange}
                                        label="Libelle role"
                                        type="text"
                                        value = {inputField.role}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        variant="outlined"
                                        id="codereseau"
                                        name ='codereseau'
                                        select
                                        label="Réseau de commercialisation"
                                        value={inputField.codereseau}
                                        onChange={handleChange}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{marginTop:20,marginBottom:10}}
                                        >
                                        {(reseaux.length !==0)&& reseaux.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                            {option.libelle}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </GridItem>
                            
                            </GridContainer>
                            </LabelledOutline>
                        </GridItem>
                    </GridContainer> 
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="h6" className={classes.title}>Droits & privilèges </Typography>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <GridList  className={classes.gridList} cols={1}>
                                {(modulesReseau && modulesReseau.length!==0)&&(modulesReseau.map(module=>{
                                   
                                    let currentModPrivilege = privilegesChecked.filter(item=>item.codemodule===module.id)
                                   if(currentModPrivilege && currentModPrivilege.length===0){
                                        currentModPrivilege = privilegesModChecked.filter(item=>item.codemodule===module.id)
                                        
                                    }
                                    
                                    return(
                                        <Accordion square defaultExpanded key={module.id}>
                                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                <Typography>{module.libelle.toUpperCase()}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                            <FormGroup row>
                                                {(currentModPrivilege && currentModPrivilege.length!==0) &&(currentModPrivilege.map(privilege=>(
                                                    <OptionsPrivileges key={privilege.key} privilege={privilege} desactiverPrivilege={desactiverPrivilege} /> 
                                                )))}
                                                    
                                            </FormGroup>
                                                
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                }))}
                            </GridList>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                            <Button  color="primary" onClick={handleSaveRole} disabled={(inputField.role==='' || inputField.codereseau==='')} style={{width:'100%',marginBottom:20}}>{loading?<CircularProgress color="secondary" />:"Eregistrer"}</Button>
                        </GridItem>
                    </GridContainer> 
                </DialogContent>
            </Dialog>
  )
}

const mapStateToProps = (state) => ({
    reseaux:state.settings.reseaux
});
  const mapActionsToProps = {}
export default connect(mapStateToProps,mapActionsToProps)(UpdateRole)