import React, { useEffect, useState } from 'react'
import CustomInput from 'components/CustomInput/CustomInput'
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput';
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect';
import { selectPartenaire } from 'redux/actions/settingsAction';
import { Paper, Typography,Divider, Button} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { PhotoCamera } from "@material-ui/icons";
import { green } from '@material-ui/core/colors';
import { IconButton } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { updatePartenaire } from 'redux/actions/settingsAction';
import { validatePartenaire } from 'util/validators';
import swal from 'sweetalert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const UpdatePartenaire = ({partenaire,handleClose}) => {
    const [inputField,setInputField] = useState({})
    const handleChange =(e)=>{
        const {name,value} = e.target
        setInputField({...inputField,[name]:value})
    }
    const [open, setOpen] = React.useState(false);
    const [state,setState] =useState({
        "message":"",
        "severity":"success"
    })
    const handleClick = () => {
        setOpen(true);
    };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  
    const handleGetPartenaire =()=>{
        selectPartenaire(partenaire).then(res=>{
            setInputField({...inputField,...res})
        })
    }
    useEffect(()=>{
        handleGetPartenaire()
    },[])
 const [selectedFile, setSelectedFile] = React.useState(null);
  const handleCapture = ({target}) => {
    setSelectedFile(target.files[0]);
  };
  const [preview, setPreview] = useState()
  useEffect(() => {
    if (!selectedFile) {
        setPreview(undefined)
        return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

    const handlesave =(e)=>{
        e.preventDefault()
        const { valid, errors } = validatePartenaire(inputField)
        if (!valid) {
            swal("Attention !",`Veuillez remplir les champs obligatoires\n${errors.join()}`,"warning")
            return
        }
        let formdata = new FormData()
    if(inputField.hasOwnProperty('code') && inputField['code']!==null){
      formdata.append('code',inputField['code'])
    }
    if(inputField.hasOwnProperty('designation') && inputField['designation']!==null){
      formdata.append('designation',inputField['designation'])
    }
    if(inputField.hasOwnProperty('activitesprincipales') && inputField['activitesprincipales']!==null){
      formdata.append('activitesprincipales',inputField['activitesprincipales'])
    }
    if(inputField.hasOwnProperty('capital') && inputField['capital']!==null){
      formdata.append('capital',inputField['capital'])
    }
    if(inputField.hasOwnProperty('formejuridique') && inputField['formejuridique']!==null){
      formdata.append('formejuridique',inputField['formejuridique'])
    }
    if(inputField.hasOwnProperty('comptecontribuable') && inputField['comptecontribuable']!==null){
      formdata.append('comptecontribuable',inputField['comptecontribuable'])
    }
    if(inputField.hasOwnProperty('nrc') && inputField['nrc']!==null){
      formdata.append('nrc',inputField['nrc'])
    }
    if(inputField.hasOwnProperty('telephone') && inputField['telephone']!==null){
      formdata.append('telephone',inputField['telephone'])
    }
    if(inputField.hasOwnProperty('mobile1') && inputField['mobile1']!==null){
      formdata.append('mobile1',inputField['mobile1'])
    }
    if(inputField.hasOwnProperty('mobile2') && inputField['mobile2']!==null){
      formdata.append('mobile2',inputField['mobile2'])
    }
    if(inputField.hasOwnProperty('adresseemail') && inputField['adresseemail']!==null){
      formdata.append('adresseemail',inputField['adresseemail'])
    }
    if(inputField.hasOwnProperty('siteweb') && inputField['siteweb']!==null){
      formdata.append('siteweb',inputField['siteweb'])
    }
    if(inputField.hasOwnProperty('logo') && inputField['logo']!==null){
      formdata.append('logo',inputField['logo'])
    }
    if(inputField.hasOwnProperty('tauxcom') && inputField['tauxcom']!==null){
      formdata.append('tauxcom',inputField['tauxcom'])
    }
   
    updatePartenaire(partenaire,inputField).then(res=>{
            if(res){
                handleClick()
                setState({
                    ...state,
                    "message":"Modification effectué avec succès",
                    "severity":"success"
                })
                handleGetPartenaire()
            }else{
                handleClick()
                setState({
                    ...state,
                    "message":"Erreur lors de l'opération !",
                    "severity":"error"
                })
            }
        }).catch(err=>{
            handleClick()
            setState({
                ...state,
                "message":err,
                "severity":"error"
            })
        })
    }

  return (
    <div>
    <Snackbar open={open} autoHideDuration={6000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} severity={state?.severity}>
            {state?.message}
        </Alert>
    </Snackbar>
    <form onSubmit={handlesave}>
        <div className='flex flex-row gap-3'>

            <div>
                <CustomInput
                    required
                    variant="outlined"
                    id="code"
                    disabled
                    name='code'
                    label="Code"
                    type="text"
                    value = {inputField.code}
                    formControlProps={{
                        fullWidth: true
                    }}
                    onChange={handleChange}
                />
                <CustomInput
                    required
                    variant="outlined"
                    id="designation"
                    name ='designation'
                    onChange={handleChange}
                    label="Raison sociale"
                    type="text"
                    value = {inputField.designation}
                    formControlProps={{
                        fullWidth: true
                    }}
                    
                />
                <CustomInput
                    variant="outlined"
                    id="activitesprincipales"
                    name ='activitesprincipales'
                    onChange={handleChange}
                    label="Activité principale"
                    type="text"
                    value = {inputField.activitesprincipales}
                    formControlProps={{
                        fullWidth: true
                    }}
                        
                />
                <CustomInput
                    variant="outlined"
                    id="capital"
                    name='capital'
                    label="Capital"
                    type="number"
                    value = {inputField.capital}
                    formControlProps={{
                        fullWidth: true
                    }}
                    onChange={handleChange}
                />
            </div>
            <div className='w-[40%]'>
                <div>
                        <h4>Logo</h4>
                        <Divider />
                    <div className='mt-3'>
                        <input
                            accept="image/jpeg"
                            className='hidden'
                            id="faceImage"
                            type="file"
                            onChange={handleCapture}
                        />
                        <Tooltip title="Selectionnez un logo">
                            <label htmlFor="faceImage">
                            <IconButton
                                className='bg-green-200'
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                            >
                                <PhotoCamera fontSize="large" />
                            </IconButton>
                            </label>
                        </Tooltip>
                        <label>{selectedFile ? selectedFile.name : "Cliquez sur l'icon pour selectionner un logo"}</label>. . .
                    </div>
                    {selectedFile&&<div className='flex flex-row justify-end'><img className='w-[100%]' src={preview} /></div>}
                </div>       
            </div>
            </div>
            <div className='py-3'>            
                <CustomSimpleSelect
                id='formejuridique'
                name="formejuridique" 
                label="Forme juridique"
                formControlProps={{
                    fullWidth: true,
                }}
                variant="outlined"
                onChange={handleChange}
                value = {inputField['formejuridique'] ||""}
                options={statutJuridiques}
            />
            <CustomInput
                variant="outlined"
                id="comptecontribuable"
                name ='comptecontribuable'
                onChange={handleChange}
                label="Compte contribuable"
                type="text"
                value = {inputField.comptecontribuable}
                formControlProps={{
                    fullWidth: true
                }}
                
            />
            <CustomInput
                variant="outlined"
                id="nrc"
                name ='nrc'
                onChange={handleChange}
                label="N° RC"
                type="text"
                value = {inputField.nrc}
                formControlProps={{
                    fullWidth: true
                }}
                
            />
            <h4>Contacts</h4>
            <Divider />
            <CustomPhoneInput
                variant="outlined"
                id="mobile1"
                name ='mobile1'
                onChange={(v)=>setInputField({...inputField,['mobile1']:v})}
                label="Mobile 1"
                type="text"
                value = {inputField["mobile1"] ||""}
                formControlProps={{
                    fullWidth: true
                }}
                
            />
            <CustomPhoneInput
                variant="outlined"
                id="mobile2"
                name ='mobile2'
                onChange={(v)=>setInputField({...inputField,['mobile2']:v})}
                label="Mobile 2"
                type="text"
                value = {inputField["mobile2"] || ""}
                formControlProps={{
                    fullWidth: true
                }}
            
            />
            <CustomInput
                variant="outlined"
                id="adresseemail"
                name='adresseemail'
                label="Adresse email"
                type="email"
                value = {inputField["adresseemail"] || ""}
                formControlProps={{
                    fullWidth: true
                }}
                onChange={handleChange}
            />
            <CustomInput
                variant="outlined"
                id="siteweb"
                name='siteweb'
                label="Site web"
                type="text"
                value = {inputField.siteweb}
                formControlProps={{
                    fullWidth: true
                }}
                onChange={handleChange}
            />
        </div>
            <Divider />
        <div className='flex flex-row justify-between items-center flex-1 py-3'>
            <Button onClick={handleClose} className='rounded-full py-2 px-3 bg-secondary-main text-white hover:bg-black cursor-pointer'>Annuler</Button>
            <Button type='submit' className='rounded-full py-2 px-8 bg-primary-main text-white hover:bg-green-300 hover:text-primary-main hover:font-bold cursor-pointer'>Modifier</Button>
        </div>
        </form>
    </div>
  )
}

export default UpdatePartenaire

const statutJuridiques=[
    'EIRL',
    'EURL',
    'SA',
    'SAS',
    'SARL',
    'SNC',
    'SCA',
    'SCS',
    'Syndicat de copropriété',
    'Association',
    'Groupement de personnes',
    'Coopérative'
    ]
