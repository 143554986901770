import React,{useEffect} from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  FormSelect,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from "shards-react";
import Typography from "@material-ui/core/Typography";
import Chart from "util/chart";
import {getProductionAgentParProduit} from 'redux/actions/apiActions'
import { connect } from 'react-redux';
function ProductionParproduit(props) {
  
  const canvasRef = React.createRef();
  const { title,user:{credentials} } = props;
  const [graphe,setGraphe]=React.useReducer((state, newState) => ({ ...state, ...newState }),
  {
    grProdData:[],
    grProdLabel:[]
  });

  const chartData= {
    title:title,
    datasets: [
      {
        hoverBorderColor: "#ffffff",
        data: graphe.grProdData,
        backgroundColor: [
          "rgba(0,123,255,0.9)",
          "rgba(0,123,255,0.5)",
          "rgba(0,123,255,0.3)"
        ]
      }
    ],
    labels: graphe.grProdLabel
  }
  useEffect(() => {
    const chartConfig = {
      type: "pie",
      data: chartData,
      options: {
        ...{
          legend: {
            position: "bottom",
            labels: {
              padding: 25,
              boxWidth: 20
            }
          },
          cutoutPercentage: 0,
          tooltips: {
            custom: false,
            mode: "index",
            position: "nearest"
          }
        },
        ...props.chartOptions
      }
    };

    new Chart(canvasRef.current, chartConfig);
  }, [graphe])

 useEffect(() => {
  getProductionAgentParProduit(credentials.idmembre).then(resData=>{
    if(resData){
      const {production}=resData
      setGraphe({
        ['grProdData']:(production && production.length!==0)?production.map(el=>el.total):[],
        ['grProdLabel']:(production && production.length!==0)?production.map(el=>el.libelleproduit):[],
      })
    }
  })
 }, [])
 
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
        <Typography className="m-0" variant='h5' component='h5'> {title}</Typography>
         
        </CardHeader>
        <CardBody className="d-flex py-0">
          <canvas
            height="220"
            ref={canvasRef}
            className="blog-users-by-device m-auto"
          />
        </CardBody>
      </Card>
    );
  }


ProductionParproduit.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart config object.
   */
  chartConfig: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object,
  /**
   * The chart data.
   */
  chartData: PropTypes.object
};


const mapActionsToProps = {}

const mapStateProps =(state)=>({
  api:state.api,
  user:state.user
})
export default connect(mapStateProps,mapActionsToProps)(ProductionParproduit)

