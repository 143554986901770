import React,{useEffect} from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInput'
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'components/CustomButtons/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {addPartenairePersonnes} from 'redux/actions/settingsAction'
import swal from 'sweetalert';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles'
import { green } from '@material-ui/core/colors';
import { IconButton } from '@material-ui/core';
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput';
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect'

const useStyle =makeStyles(theme=>({
  dialogHead:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
  },
  hbande:{
    height:20,
    width:'100%',
    backgroundColor:green[400]
  },
  btContainer:{
    display:'flex',
    justifyContent:'space-between',
    padding:10
  },
}))

function AddPersonnesRessources(props) {
  const {mode,data,handleClose,actualizPerso} =props

  const [inputField,setInputField] = React.useState({})
  const handleChange =(e)=>{
    const {name,value} = e.target
    setInputField({...inputField,[name]:value})
  }

  //ajouter une personne resosurces
  const addPersonneRessource =(e)=>{
    e.preventDefault()
    let currentData ={...inputField}
   if(mode === 'UP'){
    addPartenairePersonnes(data['id'],currentData).then(res=>{
      if(res){
        setInputField({})
        actualizPerso()
        handleClose()
      }else{
        swal("Warning","Une erreur s'est produit lors de l'opération",'warning')
      }
    }).catch(err=>{
      console.log(err);
      swal("Error","Une erreur s'est produit lors de l'opération",'error')
    })
   }
    if(mode==='ADD'){
      if(currentData['nom'] && currentData['prenom']){
        let dataPersonnes
        if(data.hasOwnProperty('personnesressources')){
          dataPersonnes = data['personnesressources']
        }else{
          dataPersonnes=[]
        }
        currentData['id']=dataPersonnes.length+1
        let newdataPersonnes = [...dataPersonnes,currentData]
       if(props.hasOwnProperty('changedata')){
        props.changedata({...data,['personnesressources']:newdataPersonnes})
       }
        setInputField({})
      }else{
        return false
      }
      handleClose()
    }
  }

  return (
    <div>
      <form onSubmit={addPersonneRessource}>
        <CustomInput
            required
            autoFocus
            variant="outlined"
            id="nom"
            name='nom'
            label="Nom"
            type="text"
            value = {inputField.nom}
            formControlProps={{
                fullWidth: true
            }}
            onChange={handleChange}
        />
        
        <CustomInput
            autoFocus
            required
            variant="outlined"
            id="prenom"
            name ='prenom'
            onChange={handleChange}
            label="Prénom"
            type="text"
            value = {inputField.prenom}
            formControlProps={{
                fullWidth: true
            }}
        />
         
        <CustomInput
          autoFocus
          required
          variant="outlined"
          id="email"
          name ='email'
          onChange={handleChange}
          label="email"
          type="text"
          value = {inputField.email}
          formControlProps={{
              fullWidth: true
          }}
        />
         
        <CustomPhoneInput
              required
              variant="outlined"
              id="telephone"
              name ='telephone'
              onChange={(v)=>setInputField({...inputField,['telephone']:v})}
              label="Téléphone"
              type="text"
              value = {inputField.telephone}
              formControlProps={{
                  fullWidth: true
              }}
              
          />
       
        <CustomPhoneInput
            required
            variant="outlined"
            id="mobile"
            name ='mobile'
            onChange={(v)=>setInputField({...inputField,['mobile']:v})}
            label="Mobile"
            type="text"
            value = {inputField.mobile}
            formControlProps={{
                fullWidth: true
            }}
              
        />
      
        <CustomSimpleSelect
            required
            id='fonction'
            name="fonction" 
            label="Fonction"
            formControlProps={{
              fullWidth: true,
            }}
            variant="outlined"
            onChange={handleChange}
            value = {inputField['fonction'] ||""}
            options={["Directeur générale","Directeur/Responsable ressource humaimne","Directeur/responsable commerciale","Agent commerciale","Autre"]}
        />
          <Divider />
          <div className='flex flex-row justify-between items-center flex-1 py-3'>
                <Button className='rounded-full py-2 px-3 bg-secondary-main text-white hover:bg-black cursor-pointer' onClick={handleClose}  variant='outlined' color="warning">Annuler</Button>
                <Button type='submit' className='rounded-full py-2 px-8 bg-primary-main text-white hover:bg-green-300 hover:text-primary-main hover:font-bold cursor-pointer'variant='outlined'>Ajouter</Button>
          </div>
         </form>
      </div>
  )
}

export default AddPersonnesRessources