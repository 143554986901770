import React,{useState} from 'react'
import PageComponent from '../components/PageComponent'
import { Tooltip,IconButton, Paper, Button, Divider, Typography } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import theme from 'util/theme';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import PaymentIcon from '@material-ui/icons/Payment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomDate from 'components/CustomDate/CustomDate';
import { DropzoneArea } from 'material-ui-dropzone';
import dayjs from 'dayjs';
import CircularProgress from '@material-ui/core/CircularProgress';
import { savePaiement } from 'redux/actions/apiActions';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { formatNumber } from 'application';
import { useEffect } from 'react';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import SentimentDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentDissatisfiedOutlined';
import { blue, red } from '@material-ui/core/colors';
import KeyboardReturnOutlinedIcon from '@material-ui/icons/KeyboardReturnOutlined';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import MailIcon from '@material-ui/icons/Mail';
const step={"EBANK":['Selectionnez votre moyen de paiement','Votre reseau mobile',"Votre numéro","Confirmarmation"],
"VRC":['Selectionnez votre moyen de paiement',"Détail de votre paiement","Confirmarmation"],
"CHQ":['Selectionnez votre moyen de paiement',"Détail de votre paiement","Confirmarmation"],
"OTHER":['Selectionnez votre moyen de paiement',"Détail de votre paiement","Confirmarmation"],
}

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const operateurs = [
    {
        id:1,
        name:"Orange money",
        codemode:"PAIEMENTMARCHANDOMPAYCIDIRECT",
        logo:"https://storage.yakoafricassur.com/paiement/orangemoney.png",
        groupe:'Mobile monney'
    },
    {
        id:2,
        name:"MTN money",
        codemode:"PAIEMENTMARCHAND_MTN_CI",
        logo:"https://storage.yakoafricassur.com/paiement/mtnmoney.png",
        groupe:'Mobile monney'
    },
    {
        id:3,
        name:"Moov money",
        codemode:"PAIEMENTMARCHAND_MOOV_CI",
        logo:"https://storage.yakoafricassur.com/paiement/moovmoney.png",
        groupe:'Mobile monney'
    },
    {
        id:4,
        name:"Wave",
        codemode:"CI_PAIEMENTWAVE_TP",
        logo:"https://storage.yakoafricassur.com/paiement/wave.png",
        groupe:'Mobile monney'
    },
]


const Paiement = ({data,handleFonction}) => {
    const [response,setResponse] = useState(null)
    let [paieField, setPaieField] = useState({
        modepaiement:null,
        datepaiement:"",
        refpaiement:"",
        codereseau:'',
        order_number:"",
        amount:25,
        city: "",
        email: "",
        clientFirstname: "BENOIT",
        clientLastname: "KOUADIO",
        clientPhone:"",
        otp:""
    })
    const [loading,setLoading] = useState(false)
    const [errors,setErrors] = useState([])
    const [activeStep,setActiveStep] = useState(0)
    const handlepaie = ()=>{
        if(activeStep===0 && !paieField.modepaiement){
            setErrors([...errors,"Mode de paiement obligatoire"])
            return
        }
        if(activeStep===1 && paieField.modepaiement=='EBANK' && paieField.codereseau==''){
            setErrors([...errors,"Canal de paiement obligatoire"])
            return
        }
        if(activeStep===2 && paieField.modepaiement=='EBANK'){
            let isError =false
            if(!paieField.clientPhone || paieField.clientPhone ==""){
                setErrors([...errors,"Le numéro de téléphone ne doit pas être vide"]) 
                isError =true
            }
            if(!paieField.clientFirstname || paieField.clientFirstname ==""){
                setErrors([...errors,"Le prénom ne doit pas être vide"])
                isError =true
            }
            if(!paieField.clientLastname || paieField.clientLastname ==""){
                setErrors([...errors,"Le nom ne doit pas être vide"])
                isError =true
            }
            if(!paieField.email || paieField.email ==""){
                setErrors([...errors,"L'adresse email ne doit pas être vide"])
                isError =true
            }
            
            if(paieField.clientPhone && paieField.clientPhone.length !== 10){
                setErrors([...errors,"Le numéro de téléphone doit contenir 10 chiffre"])
                isError =true
            }

            switch (paieField.codereseau) {
                case "PAIEMENTMARCHANDOMPAYCIDIRECT":
                    if(paieField.clientPhone && paieField.clientPhone.substring(0,2)!=='07'){
                        setErrors([...errors,"Votre numéro de téléphone ne semble pas correspondre"])
                        isError =true
                    }
                    break;
                case "PAIEMENTMARCHAND_MTN_CI":
                    if(paieField.clientPhone && paieField.clientPhone.substring(0,2)!=='05'){
                        setErrors([...errors,"Votre numéro de téléphone ne semble pas correspondre"])
                        isError =true
                    }
                    break;
                case "PAIEMENTMARCHAND_MOOV_CI":
                    if(paieField.clientPhone && paieField.clientPhone.substring(0,2)!=='01'){
                        setErrors([...errors,"Votre numéro de téléphone ne semble pas correspondre"])
                        isError =true
                    }
                    break;
                default:
                    break;
            }
            if(isError ===true)return
            
        }
        if(activeStep===4 && paieField.modepaiement==='EBANK' && paieField.codereseau==='PAIEMENTMARCHANDOMPAYCIDIRECT'){

        }

        setActiveStep(activeStep + 1)
        setErrors([])
        if(paieField && ((paieField.modepaiement ==='EBANK' && activeStep===3 && paieField.codereseau !=='PAIEMENTMARCHANDOMPAYCIDIRECT') || ((paieField.modepaiement ==='EBANK' && activeStep===4 && paieField.codereseau ==='PAIEMENTMARCHANDOMPAYCIDIRECT')))){
            handlePaiement()
            setActiveStep(5)
        }
        if(paieField && paieField.modepaiement !=='EBANK' && activeStep===3){
            handlePaiement()
            setActiveStep(5)
        }
    }
    const handleBack=()=>{
        setActiveStep(activeStep - 1)
    }
    const handleInputChange = (e)=>{
        const {name,value} = e.target
        setPaieField({...paieField,[name]:value})
    }
    const handleFileChange = (file) => {
        setPaieField({...paieField,"document":file})
    }
    const handleFileDelete = () => {
        const {document,...resfield} = paieField
        setPaieField(resfield)
    }
    const handleChargeField = ()=>{
        const rdata ={}
        if(data){
            if(data.hasOwnProperty('prime')){
                rdata['amount'] = data['prime']
            }
            if(data.hasOwnProperty('montant')){
                rdata['amount'] = data['montant']
            }
            if(data.hasOwnProperty('lieuresidence')){
                rdata['city'] = data['lieuresidence']
            }
            if(data.hasOwnProperty('email')){
                rdata['email'] = data['email']
            }
            if(data.hasOwnProperty('prenom')){
                rdata['clientFirstname'] = data['prenom']
            }
            if(data.hasOwnProperty('nom')){
                rdata['clientLastname'] = data['nom']
            }
            if(data.hasOwnProperty('mobile')){
                rdata['clientPhone'] = data['mobile']
            }
            if(data.hasOwnProperty('details')){
                rdata['details'] = data['details']
            }
            setPaieField({...paieField,...rdata})
        }
    }
    useEffect(()=>{
        handleChargeField()
    },data)

    const handlePaiement = async ()=>{
        const dataSend = {
            totalpay:(paieField.amount+(paieField.amount*0.02)),
            numpay:paieField.clientPhone,
            preselect:paieField['details'] || [],
            typepay:"P",
            primeresumer:"",
            typeReference:"ES",
            referenceSource:data['id'] || "",
            nombreDePrime:data['details']?.length,
            num_souscripteur:data['mobile1'],
            frais_adhesion:paieField['frais_adhesion'] || 0,
            code_produit:data['codeproduit'],
            additionnalInfos: {
                recipientEmail: paieField.email,
                recipientFirstName: paieField.clientFirstname,
                recipientLastName:  paieField.clientLastname,
                destinataire: paieField.clientPhone,
            },
            "serviceCode": paieField.codereseau
        }
        if(paieField.codereseau && paieField.codereseau ==='PAIEMENTMARCHANDOMPAYCIDIRECT'){
            dataSend['additionnalInfos']['otp'] = paieField.otp
        }
        
        savePaiement(dataSend).then((res)=>{
            if(res){
                if(res.hasOwnProperty('status')){
                    switch (res.status) {
                        case 200: case 'SUCCESSFUL':
                            setResponse("SUCCESSFUL")
                            break;
                        case 'INITIATED': case 'PENDING':
                            setResponse("PENDING")
                            break;
                        default:
                            setResponse("ERRORS")
                            break;
                    }
                    setActiveStep(5)
                    setLoading(false)
                }else{
                    setResponse("ERRORS")
                    setLoading(false)
                }
                setActiveStep(5)
            }else{
                console.log(res);
                setResponse("ERRORS")
                setLoading(false)
            }
        }).catch(err=>{
            console.log(err);
            setResponse("ERRORS")
            setLoading(false)
        })
    }
   

  return (
    <PageComponent titre="Paiement de prime" size='md' leftButtons={()=>(
        <Tooltip>
             <IconButton  color='primary'>
                 <HomeIcon style={{fontSize:40}} />
            </IconButton>
        </Tooltip>
    )} >
       <div className='relative grid grid-cols-1 md:grid-cols-2 gap-4 shadow-md md:rounded-md lg:max-w-screen-md md:max-w-screen-md mx-auto w-full md:mt-10'>
            {activeStep===5&&<div className='absolute flex flex-col justify-start left-0 top-0 w-full h-full bg-white'>
                <div className='flex justify-between items-center px-4 mb-4'>
                    <h1 className='text-center font-bold'>Résultat de votre paiement</h1>
                    <IconButton onClick={()=>handleFonction('HOME')}><CloseRoundedIcon /></IconButton>
                </div>
                
               {(response && activeStep === 5 && response==='SUCCESSFUL' && paieField.modepaiement ==='EBANK')&&<div className='flex gap-2 flex-col justify-center items-center'>
                    <div className='p-5 mx-4 flex flex-col gap-2 justify-center items-center rounded-md bg-green-200'>
                        <Typography className='text-green-700' variant='h5'>Félicitation !</Typography> 
                        <span className='font-bold text-[36px] text-green-700 flex flex-col flex-wrap  p-3'>
                            <br/>Votre paiement a été effectué avec succès
                        </span>
                        <CheckCircleRoundedIcon style={{fontSize:60,color:theme.palette.primary.main}} />
                    </div>
                    <button className="py-1 px-4 flex justify-center items-center border-2 gap-2 border-slate-300">Imprimer votre réçu <PrintRoundedIcon /></button>        
                </div>}
                

                {(response && activeStep === 5 && response==='PENDING' && paieField.modepaiement ==='EBANK')&&<div className='flex gap-2 flex-col justify-center items-center'>
                    <div className='p-5 mx-4 flex flex-col gap-4 justify-center items-center rounded-md bg-blue-200'>
                        <Typography className='text-blue-700 ' variant='h5'>Merci !</Typography> 
                        <span className='font-bold text-[36px] text-blue-700 flex flex-col flex-wrap  p-3'>
                            Votre paiement est en cours de traitement..., vous recevrez une notification après validation
                        </span>
                        <HourglassEmptyOutlinedIcon style={{fontSize:60,color:blue[700]}} />
                    </div>
                    <button className="py-1 px-4 flex justify-center items-center border-2 gap-2 border-slate-300">Continuer <NavigateNextOutlinedIcon /></button>        
                </div>}

                {(response && activeStep === 5 && response==='ERRORS' && paieField.modepaiement ==='EBANK')&&<div className='flex gap-2 flex-col justify-center items-center'>
                    <div className='p-5 flex flex-col gap-4 justify-center items-center rounded-md bg-red-200'>
                        <Typography className='text-red-700 text-[36px]' variant='h5'>Merci !</Typography> 
                        <span className='font-bold text-red-700 flex flex-col flex-wrap  p-3'>
                            Echèc du paiement, veuillez réssayer plustard
                        </span>
                        <SentimentDissatisfiedOutlinedIcon style={{fontSize:60,color:red[700]}} />
                    </div>
                    <button className="py-1 px-4 flex justify-center items-center border-2 gap-2 border-slate-300">Retour <KeyboardReturnOutlinedIcon /></button>        
                </div>} 


                {/* SAISIE DE LA PREUVE DE PAIEMENT */}
                {(response && activeStep === 5 && response==='SUCCESFULL' && paieField.modepaiement !=='EBANK')&&<div className='flex gap-2 flex-col justify-center items-center'>
                    <div className='p-5 mx-4 flex flex-col gap-4 justify-center items-center rounded-md bg-blue-200'>
                        <Typography className='text-blue-700 ' variant='h5'>Merci !</Typography> 
                        <span className='font-bold text-[36px] text-blue-700 flex flex-col flex-wrap  p-3'>
                            Votre preuve de paiement à bien été enregistrer, vous recevrez une notification après validation par nos services

                        </span>
                        <div className='p-2 flex flex-col justify-center items-center'> 
                            <div className='flex gap-2 font-bold'><MailIcon /><h4>infos@yakoafricassur.com </h4></div>
                            <div className='flex gap-2 font-bold'><PhoneInTalkIcon /><h4>+(225) 27 20 33 15 00 </h4></div>
                        </div>
                        
                        <HourglassEmptyOutlinedIcon style={{fontSize:60,color:blue[700]}} />
                    </div>
                    <button className="py-1 px-4 flex justify-center items-center border-2 gap-2 border-slate-300">Continuer <NavigateNextOutlinedIcon /></button>        
                </div>}
            </div>}
            {(response && activeStep === 5 && response==='ERRORS' && paieField.modepaiement !=='EBANK')&&<div className='flex gap-2 flex-col justify-center items-center'>
                    <div className='p-5 flex flex-col gap-4 justify-center items-center rounded-md bg-red-200'>
                        <Typography className='text-red-700 text-[36px]' variant='h5'>Merci !</Typography> 
                        <span className='font-bold text-red-700 flex flex-col flex-wrap  p-3'>
                            Une erreur s'est produite lors de l'enregistrement, veuillez réssayer plustard
                        </span>
                        <SentimentDissatisfiedOutlinedIcon style={{fontSize:60,color:red[700]}} />
                    </div>
                    <button onClick={()=>setActiveStep(0)}className="py-1 px-4 flex justify-center items-center border-2 gap-2 border-slate-300">Retour <KeyboardReturnOutlinedIcon /></button>        
                </div>} 


            <div className='row-span-1 flex flex-col p-4 bg-slate-300 rounded-s-md gap-4'>
                <div className='flex flex-col justify-center'>
                    <span className='text-black font-bold'>N° facture</span>
                    <span className={`text-lg font-bold text-[${theme.palette.primary.main}]`}>{(data && data.hasOwnProperty('numerofacture')) ? data['numerofacture'] : "###"}</span>
                </div>
                <div className='flex flex-col justify-center'>
                    <span className='text-black font-bold'>Date facture</span>
                    <span className={`text-lg font-bold text-[${theme.palette.primary.main}]`}>{(data && data.hasOwnProperty('datefacture')) ? data['datefacture'] : dayjs().format('DD/MM/YYYY')}</span>
                </div>
                <div className='flex flex-col justify-center'>
                    <span className='text-black font-bold'>Réf. contrat</span>
                    <span className={`text-lg font-bold text-[${theme.palette.primary.main}]`}>{(data && data.hasOwnProperty('id')) ? data['id'] : ((data && data.hasOwnProperty('idproposition')) ?  data['idproposition'] : "###")}</span>
                </div>
                <div className='flex flex-col justify-center'>
                    <span className='text-black font-bold'>Payeur</span>
                    <span className={`text-lg font-bold text-[${theme.palette.primary.main}]`}>{(data && data.hasOwnProperty('nom') && data.hasOwnProperty('prenom')) ? data['nom']+" "+data['prenom'] : ((data && data.hasOwnProperty('souscripteur')) ?  data['souscripteur'] :((data && data.hasOwnProperty('client')) ? data['client'] : "###"))}</span>
                </div>
                <div className='flex flex-col justify-center'>
                    <span className='text-black font-bold'>Montant</span>
                    <span className={`text-[26px] font-bold text-[${theme.palette.primary.main}]`}>FCFA <br /> {(data && data.hasOwnProperty('prime')) ? formatNumber(data['prime']) : ((data && data.hasOwnProperty('montant')) ? formatNumber(data['montant']) : "###")}</span>
                </div>
                {(activeStep >= 2 && paieField.modepaiement ==='EBANK')&&<div className='flex flex-col justify-center'>
                    <span className='text-black font-bold'>Frais</span>
                    <span className={`text-[26px] font-bold text-[${theme.palette.primary.main}]`}>FCFA <br /> {(data && data.hasOwnProperty('prime')) ? formatNumber(parseInt(data['prime'])*0.02) : ((data && data.hasOwnProperty('montant')) ? formatNumber(parseInt(data['montant'])*0.02) : "###")}</span>
                </div>}
                {(activeStep >= 2 && paieField.modepaiement ==='EBANK')&&<div className='flex flex-col justify-center'>
                    <span className='text-black font-bold'>A payer</span>
                    <span className={`text-[26px] font-bold text-[${theme.palette.primary.main}]`}>  {(data && data.hasOwnProperty('prime')) ? formatNumber((parseInt(data['prime'])+parseInt(data['prime'])*0.02)) : ((data && data.hasOwnProperty('montant')) ? formatNumber(parseInt((data['montant'])+parseInt(data['montant'])*0.2)) : "###")}</span>
                </div>}
                
            </div>
            <div className='row-span-1 flex flex-col p-3 gap-4'>
                <div className='flex items-center gap-1'>
                    {(activeStep!==0&& activeStep!==5)&&<IconButton onClick={handleBack}>
                        <ArrowBackIosIcon />
                    </IconButton>}
                    
                    <h1 className='text-[20px] font-bold'>{paieField.modepaiement ? step[paieField.modepaiement][activeStep] : "Selectionnez votre moyen de paiement"}</h1>
                </div>
                {/* Erreor zone */}
                {(errors && errors.length!==0)&&  <div className='flex flex-col rounded-md bg-red-200 pb-2'>
                        <div className='flex justify-end'><IconButton onClick={()=>setErrors([])}><CloseRoundedIcon style={{fontSize:15, color:red[700]}} /></IconButton></div>
                        <div className='flex flex-col justify-center items-center'>
                            {errors.map(error=><span className='font-bold text-[14px] text-red-700'>{`* ${error}`}</span>)}
                        </div>
                        
                    </div>}
                    
                    {activeStep===0&&<div className='flex flex-col gap-4'>
                        <Paper onClick={()=>setPaieField({...paieField,"modepaiement":"EBANK"})} className={classNames(
                            "p-2 rounded-2xl group cursor-pointer shadow hover:shadow-lg",(paieField && paieField.modepaiement==='EBANK') ? `border-[#f9b233] border-2` 
                            : `border-slate-500 border-2 hover:border-0`
                            )}  variant='outlined'>
                            <MobileScreenShareIcon className={`text-[40px] group-hover:text-[#f9b233] ${(paieField && paieField.modepaiement==='EBANK')?"text-[#f9b233]":"text-[#3d8a41]"}`} />
                            <span> Mobile money</span>
                        </Paper>
                        <Paper onClick={()=>setPaieField({...paieField,"modepaiement":"VRC"})} className={classNames(
                            "p-2 rounded-2xl group cursor-pointer shadow hover:shadow-lg",(paieField && paieField.modepaiement==='VRC') ? `border-[#f9b233] border-2` 
                            : `border-slate-500 border-2 hover:border-0`
                            )}  variant='outlined'>
                            <ReceiptIcon className={`text-[40px] group-hover:text-[#f9b233] ${(paieField && paieField.modepaiement==='VRC')?"text-[#f9b233]":"text-[#3d8a41]"}`}  />
                            <span> Saisir un versement sur compte</span>
                        </Paper>
                        <Paper onClick={()=>setPaieField({...paieField,"modepaiement":"CHQ"})} className={classNames(
                            "p-2 rounded-2xl group cursor-pointer shadow hover:shadow-lg",(paieField && paieField.modepaiement==='CHQ') ? `border-[#f9b233] border-2` 
                            : `border-slate-500 border-2 hover:border-0`
                            )}  variant='outlined'>
                            <ConfirmationNumberIcon className={`text-[40px] group-hover:text-[#f9b233] ${(paieField && paieField.modepaiement==='CHQ')?"text-[#f9b233]":"text-[#3d8a41]"}`}  />
                            Saisir un chèque
                        </Paper>
                        <Paper onClick={()=>setPaieField({...paieField,"modepaiement":"OTHER"})} className={classNames(
                            "p-2 rounded-2xl group cursor-pointer shadow hover:shadow-lg",(paieField && paieField.modepaiement==='OTHER') ? `border-[#f9b233] border-2` 
                            : `border-slate-500 border-2 hover:border-0`
                            )}  variant='outlined'>
                            <PaymentIcon className={`text-[40px]  group-hover:text-[#f9b233] ${(paieField && paieField.modepaiement==='OTHER')?"text-[#f9b233]":"text-[#3d8a41]"}`}  />
                            Autres
                        </Paper>
                    </div>}
                    {activeStep===1&&paieField.modepaiement==='EBANK'&&<div className='flex flex-col gap-4'>
                        {operateurs.map(operateur=>(
                            <Paper key={operateur['id']} onClick={()=>setPaieField({...paieField,"codereseau":operateur['codemode']})} className={classNames(
                                "p-2 flex items-center gap-4 group cursor-pointer shadow hover:shadow-lg",(paieField && paieField.codereseau===operateur['codemode']) ? `border-[#f9b233] border-2` 
                                : `border-slate-500 border-2 hover:border-0`
                                )}  variant='outlined'>
                                <div className="w-16 h-16">
                                    <img src={operateur['logo']} className='h-full w-full rounded-md' />
                                </div>
                        
                                {operateur['name']}
                            </Paper>
                        ))}
                    </div>}
                    {activeStep===2&&paieField.modepaiement==='EBANK'&&<div className='flex flex-col gap-2 py-2'>
                        <CustomPhoneInput
                            required
                            label=""
                            id="clientPhone"
                            name="clientPhone"
                            variant="outlined"
                            value={paieField["clientPhone"] ||""}
                            formControlProps={{
                            fullWidth: true}}
                            onChange={(v)=>setPaieField({...paieField,'clientPhone':v})}
                        />
                        <CustomInput
                            required
                            label="Adresse e-mail"
                            id="email"
                            name="email"
                            variant="outlined"
                            value={paieField["email"] ||""}
                            formControlProps={{
                                type:'email',
                            fullWidth: true}}
                            onChange={handleInputChange}
                        />
                        <CustomInput
                            required
                            label="Nom"
                            id="clientLastname"
                            name="clientLastname"
                            variant="outlined"
                            value={paieField["clientLastname"] ||""}
                            formControlProps={{
                               
                            fullWidth: true}}
                            onChange={handleInputChange}
                        />
                         <CustomInput
                            required
                            label="Prénoms"
                            id="clientFirstname"
                            name="clientFirstname"
                            variant="outlined"
                            value={paieField["clientFirstname"] ||""}
                            formControlProps={{
                            fullWidth: true}}
                            onChange={handleInputChange}
                        />
                    </div>}
                    {activeStep===1&&paieField.modepaiement!=='EBANK'&&<div className='flex flex-col gap-4'>
                               <CustomInput 
                                    label="N°"
                                    id="refpaiement"
                                    name="refpaiement"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    value={paieField['refpaiement'] ||""}
                                    type='texte'
                                    variant="outlined"
                                    onChange={handleInputChange}
                               />
                               <CustomDate 
                                    required
                                    label="Date"
                                    id="datepaiement"
                                    name="datepaiement"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    InputProps={{
                                        type: 'date',
                                    }}
                                    onChange={handleInputChange}
                                    variant="outlined"
                               />
                              
                                <DropzoneArea
                                    acceptedFiles={['image/jpeg', 'image/png', 'application/pdf']}
                                    filesLimit={1}
                                    dropzoneText={`Télécharger votre preuve de paiement ici`}
                                    onDrop={(file) => handleFileChange(file)}
                                    dropzoneClass={{
                                        minHeight: '0px !important',
                                        minWidth:'100%',
                                        marginTop:0
                                    }}
                                    onDelete={handleFileDelete}
                                /> 
                            </div>}
                            {((activeStep===2&&paieField.modepaiement!=='EBANK') || (activeStep===3&&paieField.modepaiement==='EBANK'))&&(
                                <div className='flex flex-col gap-4 ml-8'>
                                    <div className='bg-green-400 p-2 rounded-md flex flex-col justify-center items-center'>
                                        <Typography variant='h4'>Votre compte sera débité d'un montant total de :</Typography>
                                        <h1 className='font-bold text-[36px]'>{formatNumber(paieField.amount+paieField.amount*0.02)} FCFA</h1>
                                    </div>
                                    <div className='flex flex-col justify-center'>
                                        <span className='text-black font-bold'>Mode de paiement</span>
                                        <span className={`text-lg font-bold text-[${theme.palette.primary.main}]`}>
                                            {paieField.hasOwnProperty('modepaiement') ? (
                                                paieField.modepaiement==='EBANK' ? "Mobile money":(paieField.modepaiement==='CHQ' ? "Chèque":(paieField.modepaiement==='VRC' ? "Versement sur compte":"Autres"))
                                            ) : ""}
                                        </span>
                                    </div>
                                    {paieField.modepaiement==='EBANK'&&<div className='flex flex-col justify-center'>
                                        <span className='text-black font-bold'>Canal</span>
                                        <span className={`text-lg font-bold text-[${theme.palette.primary.main}]`}>
                                            { operateurs.filter(item=>item.codemode === paieField.codereseau)[0]['name']}
                                        </span>
                                    </div>}
                                    <div className='flex flex-col justify-center'>
                                        <span className='text-black font-bold'>{paieField.modepaiement==='EBANK' ? "Téléphone":"N°"}</span>
                                        <span className={`text-lg font-bold text-[${theme.palette.primary.main}]`}>
                                            {paieField.modepaiement==='EBANK' ? (paieField['clientPhone']):(paieField['refpaiement']) }
                                        </span>
                                    </div>
                                    <div className='flex flex-col justify-center'>
                                        <span className='text-black font-bold'>Date paiement</span>
                                        <span className={`text-lg font-bold text-[${theme.palette.primary.main}]`}>
                                            {paieField.modepaiement==='EBANK' ? dayjs().format("DD/MM/YYYY") :(paieField['datepaiement'] ? dayjs(paieField['datepaiement']).format("DD/MM/YYYY") :"") }
                                        </span>
                                    </div>
                                    <Divider/>
                                        <span>Client</span>
                                    <Divider style={{marginBottom:20}} />
                                    <div className='flex flex-col justify-center'>
                                        <span className='text-black font-bold'>Nom</span>
                                        <span className={`text-lg font-bold text-[${theme.palette.primary.main}]`}>{paieField['clientLastname'] || ""}</span>
                                    </div>
                                    <div className='flex flex-col justify-center'>
                                        <span className='text-black font-bold'>Prénoms</span>
                                        <span className={`text-lg font-bold text-[${theme.palette.primary.main}]`}>{paieField['clientFirstname'] || ""}</span>
                                    </div>
                                    <div className='flex flex-col justify-center'>
                                        <span className='text-black font-bold'>Email</span>
                                        <span className={`text-lg font-bold text-[${theme.palette.primary.main}]`}>{paieField['email'] || ""}</span>
                                    </div>
                                               
                                </div>)}

                                {(activeStep===4 && paieField.modepaiement==='EBANK' && paieField.codereseau==='PAIEMENTMARCHANDOMPAYCIDIRECT')&&<div className='flex flex-col gap-4 ml-8'>
                                    <div className='flex flex-col justify-center'>
                                        <p className='text-black font-bold text-md'>Composez le <strong>#144*82#</strong> , ensuite votre code secret pour générer un code d'accès temporaire, utilisez ce code dans l'interface de paiement de Orange Money.</p>
                                        <CustomInput
                                            required
                                            label="Code OTP"
                                            id="otp"
                                            name="otp"
                                            variant="outlined"
                                            value={paieField["otp"] ||""}
                                            formControlProps={{
                                            type:'number',
                                            fullWidth: true}}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>}
                                
                            {loading&&<div className='absolute z-[99999] flex justify-center items-center left-0 top-0 w-full h-full bg-[#0000008a]'>
                                 <CircularProgress color="success" />
                            </div>}

                    {(paieField && paieField.modepaiement!=="" && activeStep!==5)&&<Button onClick={handlepaie} className={`bg-[#3d8a41] hover:bg-[#f9b233] py-2 rounded-2xl text-white font-bold border-0`} variant='outlined'>
                    
                    {((activeStep===2&&paieField.modepaiement!=='EBANK') || (activeStep===3&&paieField.modepaiement==='EBANK' && paieField.codereseau!=='PAIEMENTMARCHANDOMPAYCIDIRECT') || (activeStep===4&&paieField.modepaiement==='EBANK' && paieField.codereseau==='PAIEMENTMARCHANDOMPAYCIDIRECT')) ? "Confirmez votre paiement" : "Suivant ..."}
                    
                    </Button>}
            </div>
       </div>

    </PageComponent>
  )
}

export default Paiement