import React,{ useState, useEffect, useCallback  } from 'react'
import { makeStyles } from '@material-ui/styles'
import { green, pink } from '@material-ui/core/colors';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import { 
  IconButton,
  Typography,
  Tooltip,
  Paper,
  Divider,
  Select,
  OutlinedInput,
  InputLabel,
  FormControl,
  ListItemText,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Chip,
  Avatar   } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AssignmentIcon from '@material-ui/icons/Assignment';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AttachmentIcon from '@material-ui/icons/Attachment';
import FilterListIcon from '@material-ui/icons/FilterList';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { getTypeOperationList,getOperationByUser,operationStatByUser } from 'redux/actions/apiActions'
import dayjs from 'dayjs';
import Menu from '@material-ui/core/Menu';
import MaterialTable from 'material-table'
import ImportDocument from 'pages/Banking/Propositions/ImportDocument';
import ImportDocumentList from 'pages/Banking/Propositions/ImportDocumentList'
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {ficheOperation,transformePeriode,filtertable,exportCSVFile,exportToExcel} from 'application';
import CircularProgress from '@material-ui/core/CircularProgress'


const useStyle = makeStyles(theme=>({
    root:{
      display:'flex',
      flexDirection:'column',
      marginTop:75,
      marginLeft:25,
      marginRight:25
    },
    jheader:{
      padding:10,
      marginBottom:20,
      borderLeftColor: theme.palette.primary.main,
      borderLeftWidth:8,
      display:'flex',
      justifyContent:'flex-start',
      
    },
    jmain:{
      display:'flex',
    },
    jfilter:{
      display:'flex',
      flexDirection:'column',
      padding:10,
      width:'25%',
      overflow:'auto'
    },
    jfilterHead:{
      display:'flex',
      justifyContent:'space-between',
      marginBottom:10
    },
    jfilterCorps:{
      display:'flex',
      flexDirection:'column',
      marginTop:20
    },
    jdetails:{
      display:'flex',
      flexDirection:'column',
      width:'75%',
      marginLeft:20,
      padding:20
    },
    jdetailsHeader:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
      marginBottom:20
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: 10
    },
    btnExport:{
      padding:20,
      paddingTop:15,
      paddingBottom:15,
      paddingRight:20,
      backgroundColor:theme.palette.primary.main,
      "&:hover":{
        backgroundColor:theme.palette.secondary.main,
      }
    }
}))

const initalValue ={
  "grOperations":[],
  "grStatut":[],
  "grEtat":[],
  'groupe':'ETAPE',
  "dateoperation":"",
  "operationdu":"",
  "operationau":"",
  "dateaceptation":"",
  "accepterdu":"",
  "accepterau":"",
  "datesaisie":"",
  "saisiedu":"",
  "saisieau":"",
  "initial":true
} 
function OperationJournal(props) {
  const classes = useStyle()
  const [filterField,setFilterField] = useState(initalValue)

  const [operStat, setOperStat] = useState({
    total: 0,
    nonTransmis: 0,
    transmis: 0,
    accepter: 0,
    rejeter: 0,
    annuler: 0
  });
  
  
  const handleFilterChange = (e)=>{
    setFilterField({...filterField,[e.target.name]:e.target.value})
  }
  const handleChange = (event) => {
    const {
      target: { value,name },
    } = event;
    setFilterField({...filterField,[name]:(typeof value === 'string' ? value.split(',') : value)});
  };
  
  const handlechangePeriode = (e)=>{
    const {name,value} = e.target
    const {debut,fin} = transformePeriode(value)
    switch (name) {
      case 'dateoperation':
          setFilterField({...filterField,[name]:value,["operationdu"]:debut,['operationau']:fin})
        break;
      case 'dateaceptation':
          setFilterField({...filterField,[name]:value,["accepterdu"]:debut,['accepterau']:fin})
        break;
      case 'datesaisie':
          setFilterField({...filterField,[name]:value,["saisiedu"]:debut,['saisieau']:fin})
        break;
      default:
          setFilterField({...filterField,[name]:value})
        break;
    }
  }

  const [operationList,setOperationList] = useState([])
  const handleTypeOperationList = () => {
    getTypeOperationList().then(res => {
      if (res) { setOperationList(res)}
    })
  }
  
  //gestion de la liste des demande d'opération
  const [operList, setOperList] = useState(null)     
  const handleListOperation =()=>{
    getOperationByUser(filterField).then(res=>{
      if(res){
        setOperList(res)
        setFilterField({...filterField,["initial"]:false})
      }
    })
    operationStatByUser(filterField).then(res=>{
        let tot = 0
        let refStat ={}
        if (res && res.length !== 0) {
            res.forEach(element => {
                refStat = {...refStat, [element['Libelle']]: element['Nombre'], }
                tot +=element['Nombre']
            });
            if (tot !== 0) {
              setOperStat({...refStat,['total']: tot })
            }
        }
    })
  }
  useEffect(() => {
    handleTypeOperationList()
  }, [])
  useEffect(()=>{
    if(filterField.initial){
      handleListOperation()
    }
  },[filterField.initial])
  const actualizByFilter =useCallback(()=>{
    getOperationByUser(filterField).then(res=>{
      if(res){
        setOperList(res)
      }else{
        setOperList([])
      }
    })
    operationStatByUser(filterField).then(res=>{
        let tot = 0
        let refStat ={}
        if (res && res.length !== 0) {
            res.forEach(element => {
                refStat = {...refStat, [element['Libelle']]: element['Nombre'], }
                tot +=element['Nombre']
            });
            if (tot !== 0) {
                setOperStat({...refStat,['total']: tot })
            }
        }else{
          setOperStat({
            total: 0,
            nonTransmis: 0,
            transmis: 0,
            accepter: 0,
            rejeter: 0,
            annuler: 0
          })
        }
    })
  },[
    filterField.operationdu,
    filterField.operationau,
    filterField.accepterdu,
    filterField.accepterdu,
    filterField.saisiedu,
    filterField.saisieau,
    filterField.grOperations,
    filterField.grStatut,
    filterField.grEtat,
  ])
  useEffect(()=>{
    if( 
      (dayjs(filterField['operationdu']).isValid() && dayjs(filterField['operationau']).isValid()) ||
      (dayjs(filterField['accepterdu']).isValid() && dayjs(filterField['accepterdu']).isValid()) ||
      (dayjs(filterField['saisiedu']).isValid() && dayjs(filterField['saisieau']).isValid()) ||
      (filterField['grOperations']  && filterField['grOperations'].length!==0 ) ||
      (filterField.hasOwnProperty('grStatut')  && filterField['grStatut'].length!==0 ) ||
      (filterField.hasOwnProperty('grEtat')  && filterField['grEtat'].length!==0 )
      ){
        actualizByFilter()
      }
  },[filterField])

  const handleChecked =(e,field)=>{
    const currentChecke = filterField[field]
    if(currentChecke && currentChecke.indexOf(e.target.name) > -1){
      setFilterField({...filterField,[field]:currentChecke.filter(item=>item!==e.target.name)})
    }else{
      setFilterField({...filterField,[field]:[...currentChecke,e.target.name]})
    }
  }

  
  //récupération liste des partenaires
  //récupération liste des zones
  //récupération liste des reseau
  //récupération liste des agence/equipe
  //récupération liste des utilisateurs
    

  //gestion d' nouvelle opération
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOptionClick = (event) => {
      setAnchorEl(event.currentTarget);
  };
  const handleOptionClose = () => {
      setAnchorEl(null);
  };
  //exportation menu
  const [anchorExpEl, setAnchorExpEl] = React.useState(null);
  const handleExportClick = (event) => {
    setAnchorExpEl(event.currentTarget);
  };
  const handleExportClose = () => {
      setAnchorExpEl(null);
  };

  //Gestion des documents
  const [anchorDocEl, setAnchorDocEl] = React.useState(null);
  const handleDocumentClick = (event,dt) => {
      setAnchorDocEl(event.currentTarget);
      setCurrentData(dt)
  };
  const handleDocumentClose = () => {
      setAnchorDocEl(null);
  };

  
  // selection des données 
  const [dataSelected, setDataSelected] = useState(null)
  const handleDataSelected = (dataRow) => {
      setDataSelected(dataRow);
  }
  const editTicket = (dataOperation)=>{
    if(dataOperation){
        {/*ficheOperation(dataOperation,credentials)*/}
    }
}
const handleFicheresume = (dt) => {
  props.handleSetData(dt)
  props.handleCurrentView("FICHE")
}

const reinitialiserFilter = ()=>{
  setFilterField({...initalValue})
}
    //doumments
    const [currentData,setCurrentData] = useState(null)

    const handleListeDocuments = (dt) => {
        setCurrentData(dt);
        handleDlopen()
    }
    const handleAttacheDocRow = (dt) => {
        setCurrentData(dt);
        handleOpeni()
    }

    const [openi,setOpeni] = useState(false)
    const handleOpeni =()=>{
        setOpeni(true)
    }
    const handleClosei =()=>{
        setOpeni(false)
    }
    const [dlopen,setDopen] = useState(false)
    const handleDlopen =()=>{
        setDopen(true)
    }
    const handleDlClose =()=>{
        setDopen(false)
    }

  return (
    <div className={classes.root}>
       {(dlopen && currentData)&&<ImportDocumentList open={dlopen} source='OP' handleOpen={handleDlopen} handleClose={handleDlClose}  data={currentData} /> }
       {(openi && currentData)&&<ImportDocument open={openi} source='OP' handleOpen={handleOpeni} handleClose={handleClosei} data={currentData} />}
           
      <Paper className={classes.jheader} variant="outlined">
        <Tooltip title="Retour" placement="bottom-start">
            <IconButton onClick={()=>props.handleCurrentView('HOME')} color="primary" aria-label="Retour">
                <ArrowBackIcon fontSize='large' />
            </IconButton>
        </Tooltip>
        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <Typography variant='h4'>Journaux</Typography>
        </div>
        
      </Paper>
      
      <div  className={classes.jmain}>
          <Paper className={classes.jfilter}>
              <div className={classes.jfilterHead}>
                <div style={{display:'flex',flexDirection:'column'}}>
                  <FilterListIcon fontSize='large' /> <Typography>Filtres</Typography>
                </div>
                  
                  <Button onClick={reinitialiserFilter}>   
                    Réinitialiser les filtres
                  </Button>
              </div>
              <Divider />
              <div className={classes.jfilterCorps}>
              <FormControl sx={{ m: 1}} >
                  <InputLabel id="operations"> Type d'opération</InputLabel>
                  <Select
                    label="Type d'opération"
                    labelId="grOperations"
                    id="grOperations"
                    name="grOperations"
                    variant="outlined"
                    select
                    fullWidth
                    style={{marginRight:20,marginBottom:20}}
      
                    multiple
                    value={filterField['grOperations']||[]}
                    onChange={handleChange}
                    input={<OutlinedInput label="Type d'opération" />}
                    renderValue={(selected) => selected.join(', ')}
                  >
                      <MenuItem key={0} value='Toute'>
                          <Checkbox checked={filterField['grOperations'].indexOf('Toute') > -1} />
                          <ListItemText primary='Toute' />
                      </MenuItem>
                      {(operationList && operationList.length!==0)?operationList.map(oper=><MenuItem key={oper['CodeAvenantType']} value={operationList['MonLibelle']}>
                        <Checkbox checked={filterField['grOperations'].indexOf(operationList['MonLibelle']) > -1} />
                        <ListItemText primary={operationList['MonLibelle']} />
                      </MenuItem>): <MenuItem key={1} value=''>
                          <ListItemText primary='Aucune donnée fournie' />
                      </MenuItem>}
                </Select>
              </FormControl>
                  <TextField
                      label="Date d'opération "
                      id="dateoperation"
                      name="dateoperation"
                      variant="outlined"
                      select
                      fullWidth
                      value ={filterField['dateoperation']||""}
                      style={{marginRight:20,marginBottom:20}}
                      onChange={handlechangePeriode}
                    >
                    <MenuItem key={0} value='day'>Aujourd'hui</MenuItem>
                    <MenuItem key={1} value='yesterday'>Hier </MenuItem>
                    <MenuItem key={2} value='last7week'>Les 7 derniers jours </MenuItem>
                    <MenuItem key={3} value='currentweek'>Cette semaine </MenuItem>
                    <MenuItem key={4} value='lastweek'>La semaine dernière </MenuItem>
                    <MenuItem key={5} value='last30days'>Les 30 derniers jours </MenuItem>
                    <MenuItem key={6} value='currentmonth'>Le mois en cours </MenuItem>
                    <MenuItem key={7} value='lastmonth'>Le mois derniers </MenuItem>
                    <MenuItem key={8} value='currentyear'>Cette année </MenuItem>
                    <MenuItem key={9} value="lastyear">L'année dernière </MenuItem>
                    <MenuItem key={10} value="personnalise">Personnalisé </MenuItem>
              </TextField>
                
                  <TextField
                        id="operationdu"
                        name="operationdu"
                        label="Opération du"
                        type="date"
                        disabled={filterField['dateoperation']!=='personnalise'}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value ={filterField['operationdu']||""}
                        onChange={handleFilterChange}
                        variant='outlined'
                        style={{marginRight:20,marginBottom:20}}
                    />
                    <TextField
                        id="operationau"
                        name="operationau"
                        disabled={filterField['dateoperation']!=='personnalise'}
                        label="Au"
                        type="date"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value ={filterField['operationau']||""}
                        onChange={handleFilterChange}
                        variant='outlined'
                        style={{marginRight:20,marginBottom:20}}
                    />
                
                <FormControl style={{marginLeft:20,marginBottom:20}} component="fieldset" variant="standard">
                  <FormLabel component="legend">Statut</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={filterField['grStatut'].indexOf("nonTransmis") > -1} onChange={(event)=>handleChecked(event,'grStatut')} name="nonTransmis" />
                      }
                      label="Non transmis"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={filterField['grStatut'].indexOf("nonAccepter") > -1} onChange={(event)=>handleChecked(event,'grStatut')} name="nonAccepter" />
                      }
                      label="En attente acceptation"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={filterField['grStatut'].indexOf("accepter") > -1} onChange={(event)=>handleChecked(event,'grStatut')} name="accepter" />
                      }
                      label="Acceptée"
                    />
                     <FormControlLabel
                      control={
                        <Checkbox checked={filterField['grStatut'].indexOf("rejeter") > -1} onChange={(event)=>handleChecked(event,'grStatut')} name="rejeter" />
                      }
                      label="Rejéter"
                    />
                  </FormGroup>
                </FormControl>

                <FormControl style={{marginLeft:20,marginBottom:20}} component="fieldset" variant="standard">
                  <FormLabel component="legend">Niveau de traitement</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={filterField['grEtat'].indexOf("instruction") > -1} onChange={(event)=>handleChecked(event,'grEtat')} name="instruction" />
                      }
                      label="Instruction"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={filterField['grEtat'].indexOf("valider") > -1} onChange={(event)=>handleChecked(event,'grEtat')} name="valider" />
                      }
                      label="Validé (Bon pour paiement"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={filterField['grEtat'].indexOf("odrepaiement") > -1} onChange={(event)=>handleChecked(event,'grEtat')} name="odrepaiement" />
                      }
                      label="Odre de paiement"
                    />
                     <FormControlLabel
                      control={
                        <Checkbox checked={filterField['grEtat'].indexOf("regler") > -1} onChange={(event)=>handleChecked(event,'grEtat')} name="regler" />
                      }
                      label="Regler"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={filterField['grEtat'].indexOf("terminer") > -1} onChange={(event)=>handleChecked(event,'grEtat')} name="terminer" />
                      }
                      label="Traitement terminé"
                    />
                  </FormGroup>
                </FormControl>
                
               
                  <TextField
                      label="Date d'acceptation "
                      id="dateaceptation"
                      name="dateaceptation"
                      variant="outlined"
                      select
                      fullWidth
                      value ={filterField['dateaceptation']||""}
                      style={{marginRight:20,marginBottom:20}}
                      onChange={handlechangePeriode}
                    >
                    <MenuItem key={0} value='day'>Aujourd'hui</MenuItem>
                    <MenuItem key={1} value='yesterday'>Hier </MenuItem>
                    <MenuItem key={2} value='last7week'>Les 7 derniers jours </MenuItem>
                    <MenuItem key={3} value='currentweek'>Cette semaine </MenuItem>
                    <MenuItem key={4} value='lastweek'>La semaine dernière </MenuItem>
                    <MenuItem key={5} value='last30days'>Les 30 derniers jours </MenuItem>
                    <MenuItem key={6} value='currentmonth'>Le mois en cours </MenuItem>
                    <MenuItem key={7} value='lastmonth'>Le mois derniers </MenuItem>
                    <MenuItem key={8} value='currentyear'>Cette année </MenuItem>
                    <MenuItem key={9} value="lastyear">L'année dernière </MenuItem>
                    <MenuItem key={10} value="personnalise">Personnalisé </MenuItem>
              </TextField>
                
                  <TextField
                        id="accepterdu"
                        name="accepterdu"
                        label="Acceptée du"
                        type="date"
                        disabled={filterField['dateaceptation']!=='personnalise'}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value ={filterField['accepterdu']||""}
                        onChange={handleFilterChange}
                        variant='outlined'
                        style={{marginRight:20,marginBottom:20}}
                    />
                    <TextField
                        id="accepterau"
                        name="accepterau"
                        disabled={filterField['dateaceptation']!=='personnalise'}
                        label="Au"
                        type="date"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value ={filterField['accepterau']||""}
                        onChange={handleFilterChange}
                        variant='outlined'
                        style={{marginRight:20,marginBottom:20}}
                    />
                      <Divider />
                  <TextField
                      label="Date de saisie"
                      id="datesaisie"
                      name="datesaisie"
                      variant="outlined"
                      select
                      fullWidth
                      value ={filterField['datesaisie']||""}
                      style={{marginRight:20,marginBottom:20,marginTop:20}}
                      onChange={handlechangePeriode}
                    >
                    <MenuItem key={0} value='day'>Aujourd'hui</MenuItem>
                    <MenuItem key={1} value='yesterday'>Hier </MenuItem>
                    <MenuItem key={2} value='last7week'>Les 7 derniers jours </MenuItem>
                    <MenuItem key={3} value='currentweek'>Cette semaine </MenuItem>
                    <MenuItem key={4} value='lastweek'>La semaine dernière </MenuItem>
                    <MenuItem key={5} value='last30days'>Les 30 derniers jours </MenuItem>
                    <MenuItem key={6} value='currentmonth'>Le mois en cours </MenuItem>
                    <MenuItem key={7} value='lastmonth'>Le mois derniers </MenuItem>
                    <MenuItem key={8} value='currentyear'>Cette année </MenuItem>
                    <MenuItem key={9} value="lastyear">L'année dernière </MenuItem>
                    <MenuItem key={10} value="personnalise">Personnalisé </MenuItem>
              </TextField>
           
                  <TextField
                        id="saisiedu"
                        name="saisiedu"
                        label="Saisie du"
                        type="date"
                        disabled={filterField['datesaisie']!=='personnalise'}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value ={filterField['saisiedu']||""}
                        onChange={handleFilterChange}
                        variant='outlined'
                        style={{marginRight:20,marginBottom:20}}
                    />
                    <TextField
                        id="saisieau"
                        name="saisieau"
                        disabled={filterField['datesaisie']!=='personnalise'}
                        label="Au"
                        type="date"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value ={filterField['saisieau']||""}
                        onChange={handleFilterChange}
                        variant='outlined'
                        style={{marginRight:20,marginBottom:20}}
                    />
              </div>
                 
          </Paper>
          
          <div className={classes.jdetails}>
              <div className={classes.jdetailsHeader}>
                    <div className={classes.headerState}>
                        <Chip avatar={<Avatar>{operStat['total'] || ""}</Avatar>} label="Total" />
                        <Chip avatar={<Avatar>{operStat['nonTransmis'] || ""}</Avatar>} label="Non transmis" />
                        <Chip avatar={<Avatar>{operStat['transmis'] || ""}</Avatar>} label="Transmis" />
                        <Chip avatar={<Avatar>{operStat['accepter'] || ""}</Avatar>} label="Accepté" />
                        <Chip avatar={<Avatar>{operStat['rejeter'] || ""}</Avatar>} label="Réjété"/>
                        <Chip avatar={<Avatar>{operStat['annuler'] || ""}</Avatar>} label="Annulé"/>
                    </div>
                    <Button onClick={handleExportClick} className={classes.btnExport}>   
                      Exporter
                    </Button>
              </div>
              <div className={classes.jdetailsCorps}>
              {( operList && operList.length!==0)?(<MaterialTable
                        title=""
                        columns={[
                            {
                                field: 'libelletypeoperation',
                                render: rowData => (<div className={classes.tablibOper}>
                                    <Typography variant='h5'>{rowData.libelletypeoperation}</Typography>
                                    <Typography variant='caption'>{`Ref : ${rowData.codeoperation} \n`}</Typography>
                                    <Typography variant='caption'>{`Date :${dayjs(rowData.dateoperation).format('DD/MM/YYYY')}`}</Typography>
                                    {rowData.delaitraitement && <Typography variant='caption'>{`Traitement : ${rowData.delaitraitement} jour(s)`}</Typography>}
                                    {rowData.datefinprevu && <Typography variant='caption'>{`Fin traitement :${dayjs(rowData.datefinprevu).format('DD/MM/YYYY')}`}</Typography>}
                                </div>)

                            },
                            {
                                field: "idproposition",
                                render: rowData => (
                                    <div className={classes.tablibOper}>
                                        <Typography variant='h5'>{rowData.adherent}</Typography>
                                        <Typography variant='caption'>{`#ID : ${rowData.idproposition}`}</Typography>
                                        <Typography variant='caption'>{`Produit :${rowData.libelleproduit}`}</Typography>
                                        {rowData.dateeffet && <Typography variant='caption'>{`Date d' effet :${dayjs(rowData.dateeffet).format('DD/MM/YYYY')}`}</Typography>}
                                    </div>
                                ),
                            },
                            {
                              field:'Actions',
                              render:rowData=>(
                                <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                                    <Tooltip title="Documents" placement="bottom-start">
                                      <IconButton onClick={(event) =>handleDocumentClick(event,rowData)} color="primary" aria-label="Documents">
                                          <AttachFileIcon fontSize='large' />
                                      </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Détails" placement="bottom-start">
                                      <IconButton onClick={()=>handleFicheresume(rowData)} color="primary" aria-label="Détails">
                                          <AssignmentIcon fontSize='large' />
                                      </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Ticket" placement="bottom-start">
                                      <IconButton onClick={()=>editTicket(rowData)} color="primary" aria-label="Ticket">
                                          <ConfirmationNumberIcon fontSize='large' />
                                      </IconButton>
                                  </Tooltip>
                                </div>
                              )
                            }
                            
                        ]}
                        data={query => new Promise((resolve, reject) => {
                          const columnSearch = ['adherent','libelleproduit','libelletypeoperation','idproposition', 'codeoperation', 'dateoperation','delaitraitement']
                          resolve({
                              data: filtertable(columnSearch, operList, query.search)
                          })
                        })}
                        localization={{
                            toolbar: {
                                searchPlaceholder: 'Trouver une opération',
                                searchTooltip: 'Trouver une opération',
                                exportTitle: 'Exportation',
                                exportAriaLabel: 'Exportation',
                                exportCSVName: 'Exporter en Excel',
                                exportPDFName: 'Exporter en PDF',
                            },
                            body: {
                                emptyDataSourceMessage: 'Aucune donnée trouvée !'
                            },
                            pagination: {
                                labelRowsSelect: 'Ligne(s)',
                                labelDisplayedRows: '{count} sur {from}-{to}',
                                firstTooltip: 'Prémière page',
                                previousTooltip: 'Précédent',
                                nextTooltip: 'Suivant',
                                lastTooltip: 'Dernière page'
                            }
                        }}
                        options={{
                            selectionProps: rowData => ({
                                disabled: rowData.etape === 3,
                                color: 'primary'
                            }),
                            searchFieldAlignment:'left',
                            searchFieldStyle:{
                              borderRadius:50
                            },
                            exportButton: false,
                            actionsColumnIndex: -1,
                            pageSize: 10,
                            pageSizeOptions: [10,20, 40, 80, 100, 200, 300, 400, 500, 1000]
                        }}
                        onSelectionChange={(rows) => handleDataSelected(rows)}
                      
                    />):(<div><CircularProgress /></div>)}
                    <Menu
                        id="documents-menu"
                        anchorEl={anchorDocEl}
                        keepMounted={true}
                        open={Boolean(anchorDocEl)}
                        onClose={handleDocumentClose}
                    >
                        <MenuItem onClick={handleListeDocuments}>
                            <ListItemIcon>
                                <InsertDriveFileIcon color='primary' fontSize='large' />
                            </ListItemIcon>
                            <Typography variant="inherit">Liste des documents attachés</Typography>
                        </MenuItem>

                        <MenuItem onClick={handleAttacheDocRow}>
                            <ListItemIcon>
                                <AttachmentIcon color='primary' fontSize='large' />
                            </ListItemIcon>

                            <Typography variant="inherit">Joindre un documents</Typography>
                        </MenuItem>
                    </Menu>

                    <Menu
                        id="export-menu"
                        anchorEl={anchorExpEl}
                        keepMounted={true}
                        open={Boolean(anchorExpEl)}
                        onClose={handleExportClose}
                    >
                        <MenuItem onClick={()=>exportCSVFile(null,operList,"liste-operation")}>
                            <ListItemText primary='Exporter en CSV' />
                        </MenuItem>

                        <MenuItem onClick={()=>exportToExcel(null,operList,"liste-operation")}>
                            <ListItemText primary='Exporter en Excel' />
                        </MenuItem>
                    </Menu>
              </div>
          </div>
      </div>
      
    </div>
  )
}

export default OperationJournal