import React, { useState } from 'react'
import {Field, reduxForm, change,getFormValues,registerField } from 'redux-form'
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import DocumentCard from './DocumentCard'
import Button from '@material-ui/core/Button';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
const useStyles = makeStyles(theme=>({
  bcontainer:{
    paddingTop:10,
    backgroundColor:'transparent',
    borderRadius:0
  },
  bPaper:{
    padding:10,
    borderRadius:0,
    borderRadius:10
  },
  btnContainer:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    padding:20
  },
  btnback:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:"#f9b233",
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#3c3c3b",
    },
    borderRadius:10,
  },
  btnnext:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:theme.palette.primary.main,
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
  bhead:{
    padding:10
  }
}))
function StepDocuments(props) {
  const { handleSubmit, handleNext,pristine,submitting, handleBack, activeStep, steps, data } = props
    const classes = useStyles();
    const handleDataSelected = (row) => {
      if (row && row.length !== 0) {
          let libDoc = ""
          row.forEach(el => {
              if (libDoc === "") {
                  libDoc = el.titre
              } else {
                  libDoc = libDoc + "||" + el.titre
              }
          })
          if (libDoc !== "") {
              if (!props.hasOwnProperty('docstitre')) {
                  props.dispatch(registerField("addOper", 'docstitre', 'Field'));
              }
              props.updateField('docstitre', libDoc)
          }
      }
  }
  const handleSetDocument = (mydocs) => {
    props.setCurrentDocs(mydocs)
  }

  return (
    <div>
      <Paper className={classes.bcontainer} variant="outlined">
          <div className={classes.bhead}>
              <Typography variant='caption'>Documents à fournir</Typography>
          </div>
          <MaterialTable
              columns={[
                  {
                      field: 'titre',
                      render: rowData => (<div className={classes.tablibOper}>
                          <Typography variant='h5'>{rowData.titre.charAt(0).toUpperCase() + rowData.titre.slice(1).toLowerCase()}</Typography>
                      </div>)
                  }
              ]}
              data={documents ? documents : []}
              localization={{
                  body: { emptyDataSourceMessage: 'Aucune donnée trouvée !' },
              }}
              options={{
                  header: false,
                  showFirstLastPageButtons: false,
                  showTitle: false,
                  toolbar: false,
                  showTextRowsSelected: false,
                  selection: true,
                  search: false,
                  paging: false,
                  selectionProps: rowData => ({ color: 'primary' }),
                  actionsColumnIndex: -1,
              }}
              onSelectionChange={(rows) => handleDataSelected(rows)}
          />
          <Field 
            id='documents'
            name='documents'
            component={DocumentCard}
            documentType={documents}
            handleFile={handleSetDocument}
          />
          <div className={classes.btnContainer}>      
            <Button type="button" className={classes.btnback}   onClick={props.handleBack}>
              Retour
            </Button>
              
            <Button  className={classes.btnnext}  type="submit" onClick={handleNext}  disabled={pristine || submitting}>
              Continuer
              <NavigateNextOutlinedIcon /> 
            </Button>
          </div>
      </Paper>
    </div>
  )
}




const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapActionsToProps = {
  updateField
}

const mapStateProps =(state)=>({
  data:getFormValues('adForm')(state),
})

export default connect(mapStateProps,mapActionsToProps)(reduxForm({form: 'adForm',destroyOnUnmount: false})(StepDocuments))


const documents=[
  {titre:"Bulletin de souscription",fichier:""},
  {titre:"Pièce justificatif d\'identité",fichier:""},
  {titre:"Autres pièces",fichier:""}
]