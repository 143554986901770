import React, { Fragment,useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Field, reduxForm,reset } from 'redux-form'
import { connect,useDispatch } from 'react-redux';
import { Grid,Box } from '@material-ui/core'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import ProduitCard from './ProduitCard'
import Typography from "@material-ui/core/Typography";
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';


const produits =[
    {"CodeProduit":"YKE_2008",
    "MonLibelle":"YAKO ETERNITE",
    "Description":"Assurance obsèque, en cas de décès d’un assuré, vous bénéficiez des prestation suivantes:  Enlèvement du corps, conservation du corps, levée de corps, allocation cercueil et transport du corps et une part financière.  Vous cotisez sur 5 ans et estes couvert toutes la vie"
    ,"categorie":"OBSEQUES"},
    {"CodeProduit":"YKS_2008",'MonLibelle':"YAKO SOLO","Description":"","categorie":"OBSEQUES"},
    {"CodeProduit":"YKF_2008",'MonLibelle':"YAKO FAMILLE","Description":"","categorie":"OBSEQUES"},
    {"CodeProduit":"PFA_IND",'MonLibelle':"PERFORMA","Description":"","categorie":"MULTISUPPORT"},
    {"CodeProduit":"CADENCE",'MonLibelle':"CADENCE","Description":"","categorie":"EPARGNE"},
    {"CodeProduit":"PRR_1997",'MonLibelle':"PRE RETRAITE","Description":"","categorie":"EPARGNE"},
]

const useStyles = makeStyles(theme =>({
  root: {
    margin:20,
    justifyContent:'center',
    alignItem:'center'
  },
  filterContanin:{
    marginBottom:10
  },
  btnAction:{
    width:80,
    height:80,
    backgroundColor:'#fff',
    color:theme.palette.primary.main,
    '&:hover':{
      backgroundColor:theme.palette.primary.main,
      color:theme.palette.primary.contrastText
    }
  },
  
}));

const ProduitSouscription = props=>{
    const classes = useStyles()
    const [currentV, setCurrentV] = React.useState('recents')
    const [produitList,setProduitList] = React.useState(produits)
    const dispatch = useDispatch()
    const handleChange = (event,newValue) => {  
 
      let mesProduit = produits.filter(produit=>produit.categorie === newValue)
      setCurrentV(newValue);
      setProduitList(mesProduit.length===0 ? produits:mesProduit)
    }
    const handleNext= ()=>{
      return props.handleSubmit()
    }
    const handleBack =()=>{
      return props.handleBack()
    }
    const renderProdField = ({ input, label, type, meta: { touched, error } }) =>(
      <Grid container direction='row' spacing={4}>
      <Grid item sm={12} xs={12} className={classes.filterContanin}> 
          <Box>
            <FilterListOutlinedIcon fontSize='small' />
              <Typography variant={"subtitle1"} display='inline'> Filtrer</Typography>{`\n`}
          </Box>
          <BottomNavigation  value={currentV} onChange={handleChange} className={classes.root}>
            <BottomNavigationAction showLabel className={classes.btnAction}  label="ALL" value="all" />
            <BottomNavigationAction showLabel className={classes.btnAction}  label="OBSEQUES" value="OBSEQUES" icon={<RestoreIcon />} />
            <BottomNavigationAction showLabel className={classes.btnAction} label="EPARGNE" value="EPARGNE" icon={<AccountBalanceWalletOutlinedIcon />} />
            <BottomNavigationAction showLabel className={classes.btnAction} label="MIXTE" value="MIXTE" icon={<LocationOnIcon />} />
            <BottomNavigationAction showLabel className={classes.btnAction} label="UNITE DE COMPTE" value="MULTISUPPORT" icon={<FolderIcon />} />
          </BottomNavigation> 
      </Grid>

      <Grid item sm={12} xs={12} >
          <Grid container direction='row' alignItems="stretch" >
            {produitList.map((produit)=>{
                return <ProduitCard key={produit.CodeProduit} handleBack={handleBack} produit={produit} onSubmit={handleNext} input={input}/>
              })}
          </Grid>
      </Grid>
    </Grid>
    )
     const { handleSubmit, pristine, reset, submitting,api:{villes,professions,societes,civilites} } = props
    return (
      <Fragment>
          <Field 
            name='produit'
            component={renderProdField}
          />
      </Fragment>
      
    )

}


const mapStateToProps = (state) => ({
    api: state.api,
  });
  
  const mapActionsToProps = {};


  export default connect(
    mapStateToProps,
    mapActionsToProps
  )(reduxForm({
    form: 'adForm', // a unique identifier for this form
    destroyOnUnmount: false,
  })(ProduitSouscription))

