import React,{useState,useReducer,useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Field, reduxForm,change,registerField,FieldArray,getFormValues,reset } from 'redux-form';
import CustomTitle from 'components/CustomTitle'
import {connect,useDispatch,useSelector} from 'react-redux'
import { Typography } from "@material-ui/core";
import AddEtat from 'pages/Banking/Etats/AddEtat'
import EtatFonctions from 'pages/Banking/Etats/EtatFonctions'
import {
    EtatProduction,
    ETTEvolutionProduction,
    ETTProdParProduit,
    ETTProdParConseiller,
    ETTProdParConseiller2,
    ETTProdParAgence,
    ETTProdParZone,
    ETTProdSynthese
} from 'pages/Banking/Etats/Production'

import {
    ETTEvolutionCa,
    ETTCaParProduit,
    ETTCaParConseiller,
    ETTCaParConseiller2,
    ETTCaParAgence,
    ETTCaParZone,
    ETTCaSynthese
} from 'pages/Banking/Etats/CA'

function EtatHome(props){
    const [fonctionnalite,setFonctionalite]=useState('HOME')
    useEffect(() => {
        document.title ="ENOV - Etats de gestion"
    })
    const handleCliqueFonction = element=>{
        setFonctionalite(element)
    }
    return(
  
        <div>
            {fonctionnalite==='HOME'&&  <EtatFonctions history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='ADD'&&  <AddEtat history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='ETTPROD'&&  <EtatProduction history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='ETTEVOPROD'&&  <ETTEvolutionProduction history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='ETTPRODPROD'&&  <ETTProdParProduit history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='ETTPRODCON'&&  <ETTProdParConseiller history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='ETTPRODCON2'&&  <ETTProdParConseiller2 history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='ETTPRODZONE'&&  <ETTProdParZone history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='ETTPRODAGENCE'&&  <ETTProdParAgence history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='ETTPRODSYN'&&  <ETTProdSynthese history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='ETTEVOCA'&&  <ETTEvolutionCa history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='ETTCAPROD'&&  <ETTCaParProduit history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='ETTCACON'&&  <ETTCaParConseiller history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='ETTCACON2'&&  <ETTCaParConseiller2 history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='ETTCAZONE'&&  <ETTCaParZone history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='ETTCAAGENCE'&&  <ETTCaParAgence history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='ETTCASYN'&&  <ETTCaSynthese history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
        
        </div>
    )
}


const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapPropsActions ={
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user,
    data:getFormValues('adForm')(state)
  });

  export default connect(mapStateToProps,mapPropsActions)(reduxForm({destroyOnUnmount: false,})(EtatHome))
  
