import React from 'react'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { makeStyles } from '@material-ui/core/styles';
import { reduxForm, reset } from 'redux-form'
import { connect, useDispatch } from 'react-redux';
import { Home, Assignment, Add, Folder } from "@material-ui/icons/";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { useHistory } from 'react-router-dom'
const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    alignItem: 'center',
    width: 500,
    marginBottom: 40
  },
  fonctionAction: {
    marginBottom: 20
  },
  btnAction: {
    width: 80,
    height: 80,
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    '&active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  },

}));


function PretHeader(props) {
  const { handleFonctionMini, ui: { layoutRoot } } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [currentV, setCurrentV] = React.useState('recents')
  const handleChange = (event, newValue) => {
    setCurrentV(newValue);
    handleFonctionMini(newValue)
    if (newValue === 'ADD') {
      dispatch(reset('addPret'))
      history.push(`/${layoutRoot}/add-pret`);
    }
  }

  return (

    <BottomNavigation value={currentV} component='div' onChange={handleChange} className={classes.root}>
      <BottomNavigationAction showLabel className={classes.btnAction} label="" value="HOME" icon={<Home />} />
      <BottomNavigationAction showLabel className={classes.btnAction} label="Nouvelle cotation" value="ADD" icon={<Add />} />
      <BottomNavigationAction showLabel className={classes.btnAction} label="Liste des cotations" value="ALL" icon={<Folder />} />
      <BottomNavigationAction showLabel className={classes.btnAction} label="Cotations en attente" value="ENATTENTE" icon={<Assignment />} />
      <BottomNavigationAction showLabel className={classes.btnAction} label="Cotations Acceptés" value="VALIDER" icon={<FindInPageIcon />} />
      <BottomNavigationAction showLabel className={classes.btnAction} label="Prets mis en place" value="MISENPLACE" icon={<FindInPageIcon />} />
    </BottomNavigation>

  )
}


const mapStateToProps = (state) => ({
  api: state.api,
  ui: state.UI
});

export default connect(mapStateToProps)(reduxForm({ destroyOnUnmount: false })(PretHeader))


