import {
    SET_RESEAUX,
    SET_ZONES,
    SET_EQUIPES,
    SET_MODULES,
    SET_ALLPRIVILEGE,
    SET_ALLUTILISATEUR
  } from '../types';
  
  const initialState = {
    reseaux: [],
    zones:[],
    equipes:[],
    modules:[],
    allPrivilege:[],
    allUtilisateur:[] 
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case SET_RESEAUX:
        return {
          ...state,
          loading: false,
          reseaux: action.payload
        };
      case SET_ZONES:
        return {
            ...state,
            loading: false,
            zones: action.payload
        };
      case SET_EQUIPES:
        return {
            ...state,
            loading: false,
            equipes: action.payload
        };
      case SET_MODULES:
        return {
            ...state,
            loading: false,
            modules: action.payload
        };
      case SET_ALLPRIVILEGE:
        return {
            ...state,
            loading: false,
            allPrivilege: action.payload
        };
      case SET_ALLUTILISATEUR:
        return {
            ...state,
            loading: false,
            allUtilisateur: action.payload
        };
      default:
        return state;
    }
  }
  