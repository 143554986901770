import React ,{useState} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Grid,Box} from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import { withStyles } from '@material-ui/core/styles';
import ProfilModalFormBenef from './ProfilModalFormBenef'
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import {formValueSelector} from 'redux-form'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const styles = theme =>({
  root: {
    backgroundColor:theme.palette.primary.main,
    borderRadius:50,
    border: 1,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    marginBottom:20,
    '&:hover':{
      backgroundColor:theme.palette.secondary.main,
      boxShadow: '0 3px 5px 2px rgba(6, 6, 6, .3)',
    }
  },
    table: {
        minWidth: 650,
      },
      btnAdd:{
          
      },
      btnAction:{
        width:80,
        height:80,
        backgroundColor:'#fff',
        color:theme.palette.primary.main,
        '&:hover':{
          backgroundColor:theme.palette.primary.main,
          color:theme.palette.primary.contrastText
        }
      },
      btnNext:{
        backgroundColor:theme.palette.primary.main,
        height:40,
        width:150,
        borderRadius:20,
        color:theme.palette.primary.contrastText
      },
})


  

 function ProfilBeneficiaire(props) {
    const { handleSubmit, pristine, reset, submitting,classes,className,myValues } = props
    const [mesAssures,setMesAssures] = useState([])
    const [open, setOpen] = React.useState(false);
    const [moimemeBeneficiaire, setMoimemeBeneficiaire] = React.useState(false);
    const [modeOpen,setModeOpen] = React.useState('autreBeneficaire')
 
        const handleOpen = () => {
          setOpen(true);
        };

        const handleClose = () => {
          setOpen(false);
        };
        const handleMoimemeBeneficaire =()=>{
          const moimeme =!moimemeBeneficiaire;
          setMoimemeBeneficiaire(moimeme)
          if(moimeme){
           
           const currentBeneficiaire ={
              nomBeneficiaire: myValues.nomSous && myValues.nomSous, 
              prenomBeneficiaire: myValues.prenomSous && myValues.prenomSous,
              dateNaissanceBeneficiaire: myValues.dateNaissanceSous && myValues.dateNaissanceSous,
              lieuNaissanceBeneficiaire: myValues.lieuNaissanceSous && myValues.lieuNaissanceSous,
              lieuResidenceBeneficiaire: myValues.lieuResidenceSous && myValues.lieuResidenceSous,
              filiationBeneficiaire: 'Moi même',
              emailBeneficiaire: myValues.emailSous && myValues.emailSous,
              telephoneBeneficiaire: myValues.mobileSous && myValues.mobileSous,
              taux:'100',
              monIndex: (mesAssures.length+1)
            }  
            setMesAssures([...mesAssures,currentBeneficiaire])
          }else{
            setModeOpen('autreBeneficiaire')
          }
        }
        const handleAddAssure =(mesdonne)=>{
        // e.preventDefault();
          if(mesdonne){setMesAssures([...mesAssures,mesdonne])}  
        }
      const deletebeneficaire =(assure)=>{
          let crurrentAsuree = mesAssures    
          setMesAssures(crurrentAsuree.filter((item=>item.monIndex!==assure.monIndex)))
      }

    const renderBeneficiaire =(props)=>{
      props.input.onChange(mesAssures)
     return( mesAssures.length!==0? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" >Nom</TableCell>
                <TableCell align="center" >Prénoms</TableCell>
                <TableCell align="center">Né(e) le</TableCell>
                <TableCell align="center">Lieu de naissance</TableCell>
                <TableCell align="center">Lieu de residence</TableCell>
                <TableCell align="center">Filiation</TableCell>
                <TableCell align="center">Téléphone</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Taux (%)</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {mesAssures.map(assure => (
                <TableRow key={assure.nomBeneficiaire}>
                  <TableCell align="center" component="th" scope="row">
                    {assure.nomBeneficiaire}
                  </TableCell>
                  <TableCell align="center">{assure.prenomBeneficiaire}</TableCell>
                  <TableCell align="center">{assure.dateNaissanceBeneficiaire}</TableCell>
                  <TableCell align="center">{assure.lieuNaissanceBeneficiaire}</TableCell>
                  <TableCell align="center">{assure.lieuResidenceBeneficiaire}</TableCell>
                  <TableCell align="center">{assure.filiationBeneficiaire}</TableCell>
                  <TableCell align="center">{assure.telephoneBeneficiaire}</TableCell>
                  <TableCell align="center">{assure.emailBeneficiaire}</TableCell>
                  <TableCell align="center">{assure.taux}</TableCell>
                  <TableCell align="center">
                  <IconButton
                      data-id={assure.numeroCniAssure}
                      aria-label="actions"
                      aria-haspopup="true"
                      onClick={()=>deletebeneficaire(assure)}
                    >
                    <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
      </TableContainer>):<Typography>Aucun aucun bénéficiaire </Typography>
     )}
  
    return (

    <Grid item sm={12} xs={12} >
         <FormControlLabel
            control={
              <Checkbox checked={moimemeBeneficiaire} onChange={handleMoimemeBeneficaire} value="moimeme" />
            }
            label="Je suis moi même ayant droit ? (Adhérent égale Bénéficaire)"
          />
        <Box display='flex' flexDirection='flex-end'>
          <Button className ={clsx(classes.root, className)}  onClick={handleOpen}> <AddIcon fontSize='large' />
          <Typography>Ajouter un autre ayant droit (bénéficiaire)</Typography>
          </Button>
        </Box>     
        
        <ProfilModalFormBenef handleClose={handleClose} handleOpen={handleOpen} open={open} handleAddAssure={handleAddAssure} />
        <Field 
            name="beneficiaires"
            component={renderBeneficiaire}
        /> 
       
            <Divider component="hr" className={classes.hTrait}/>
            <Grid container direction="row" justify="space-between" alignItems="center">        
             
             <Button type="button" onClick={props.handleBack}>
               Retour
             </Button>
          
           
             <Button type= 'submit' color='primary' onClick={handleSubmit} disabled={mesAssures.length===0?true:false}>
               Continuer
               <NavigateNextOutlinedIcon /> 
             </Button>
         
         </Grid>

      
</Grid>
      
    );
  }

  const selector = formValueSelector('adForm')
  const mapStateProps =(state)=>{
    return{
      myValues:selector(state, 'produit','nomSous','prenomSous','dateNaissanceSous','lieuNaissanceSous','pieceIdentiteSous','lieuResidenceSous','emailSous','mobileSous')
    }
  }


  ProfilBeneficiaire =withStyles(styles)(ProfilBeneficiaire)
  
  export default connect(mapStateProps)(reduxForm({form: 'adForm',destroyOnUnmount: false})(ProfilBeneficiaire))
