import React,{useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/styles'
import { Paper,Typography,Divider, Button, Chip, IconButton } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import AddIcon from '@material-ui/icons/Add';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MaterialTable from 'material-table';
import {getTotProductionAgent,derniereProposition} from 'redux/actions/apiActions'
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { formatNumber } from 'application';
import Header from 'pages/Commons/components/Header';
const useStyle = makeStyles(theme=>({
    root:{
        marginLeft:'auto',
        marginRight:'auto',
    },
    ppaper:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding:20,
        marginBottom:25
    },
    ppaperItem:{
        display:'flex',
        flex:1,
        flexDirection:'column',
        alignItems:'center',
        margin:10,
    },
    textegreen:{
        color:theme.palette.primary.main,
    },
    texteblue:{
        color:"#00FF00",
    },
    textered:{
        color:red[400],
    },
    texteyel:{
        color:'#f9b233',
    },
    actionContainer:{
        display:'flex',
        justifyContent:'center',
        marginTop:20
    },
    btnadd:{
        display:'flex',
        flex:1,
        paddingLeft:20,
        paddingRight:20,
        paddingTop:10,
        paddingBottom:10,
        backgroundColor:'#fff',
        border:'1px solid #f6f6f6',
        margin:20,
        borderRadius:10,
        '&:hover':{
            backgroundColor:theme.palette.primary.main,
            color:'#fff',
        }

    },
    incorpContainer:{
        display:'flex',
        justifyContent:"flex-start",
        marginTop:25,
        marginBottom:20,
    },
    incorpTable:{
        display:'flex',
        flex:1,
        marginTop:20,
        justifyContent:'center',
       
    },
    incorppaper:{
        flex:1,
        borderRadius:10,
        marginRight:20
        
    },
    tabTitle:{
        marginBottom:10
    }

}))

function AccueilProduction(props) {
    const classes = useStyle()
    const {handleFonctionShow,user: { credentials }} = props
    const dispatch =useDispatch()
    const [stateValue,setStateValue] = useState({
        totol:"00",
        nontransmis:'00',
        transmi:'00',
        accepter:'00',
        rejeter:'00',
       
    })
    const [derniereprop,setDerniereprop] =  useState([])
    const handleNewProposition =(e)=>{
        e.preventDefault()
        handleFonctionShow('ADD',null)
        dispatch({ type: 'SET_MODE',payload:'ADD' })
    }
    const handleStateProposition = ()=>{
        getTotProductionAgent(credentials.idmembre).then(resData => {
            if(resData){
                const newState ={
                    totol:resData['general']['all']['total'],
                    nontransmis:resData['general']['saisies']['nbSaisie'],
                    transmi:resData['general']['transmis']['nbTransmis'],
                    accepter:resData['general']['acceptes']['nbAccepter'],
                    rejeter:resData['general']['rejetes']['nbrejeter'],
                }
                setStateValue({...newState})
            }
        }).catch(err=>{
            console.log(err);
        })
    } 
    
    useEffect(()=>{
        handleStateProposition()
        derniereProposition().then(res=>{
            if(res){setDerniereprop(res)}
        })
    },[])

    const handleShowproposition =(data)=>{
        if(data){

        }
    }
  return (
    <div className={classes.root}>
        <Header title="Espace souscription" />
       <div className='flex flex-col p-4'>
        <Paper className={classes.ppaper} variant="outlined">
            <div className={classes.ppaperItem}>
                <Typography variant='body1'> Total(s) proposition(s)</Typography>
                <Typography variant='h3'> {stateValue.totol}</Typography>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className={classes.ppaperItem}>
                <Typography variant='body1' className={classes.texteyel}> Proposition (s) non transmise(s)</Typography>
                <Typography variant='h3' className={classes.texteyel}> {stateValue.nontransmis}</Typography>
            </div>
            <Divider orientation="vertical" flexItem/>
            <div className={classes.ppaperItem}>
                <Typography variant='body1' className={classes.texteblue}> Proposition (s) en attente d'acceptation</Typography>
                <Typography variant='h3' className={classes.texteblue}> {stateValue.transmi}</Typography>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className={classes.ppaperItem}>
                <Typography variant='body1' className={classes.textegreen}> Proposition (s) accepté(s)</Typography>
                <Typography variant='h3' className={classes.textegreen}> {stateValue.accepter}</Typography>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className={classes.ppaperItem}>
                <Typography variant='body1' className={classes.textered}> Proposition (s) rejetée(s)</Typography>
                <Typography variant='h3'  className={classes.textered}>  {stateValue.rejeter}</Typography>
            </div>
        
        </Paper>
        <Typography variant='h5'> Actions</Typography>
      <Divider />
      <div className={classes.actionContainer}>
            <Button className={classes.btnadd} onClick ={handleNewProposition}>
                <AddIcon fontSize='large'/> Nouvelle souscription
            </Button>
            <Button className={classes.btnadd} onClick={()=>handleFonctionShow('INC')}>
                <GroupAddIcon fontSize='large' /> Ajouter un d'assuré(e)
            </Button>
            <Button className={classes.btnadd} onClick={()=>handleFonctionShow('LIST')}>
                <ListAltIcon fontSize='large' /> Liste des proposition
            </Button>
      </div>
      <div className={classes.incorpContainer}>
        <Paper className={classes.incorppaper} variant="outlined">
            <div className='flex flex-row bg-slate-200 p-3 rounded-t-md'>
                <Typography className={classes.tabTitle} variant='h5'>Dernières propositions</Typography>
            </div>
            <Divider />
            <div className="flex p-3 w-full">
                <MaterialTable 
                    columns={[
                        { title: '#Ref', field: 'id' },
                        { title: 'Souscripteur', render:rowData=><span>{`${rowData['nom']} ${rowData['prenom']}`}</span> },
                        { title: 'Produit', field: 'libelleproduit'},
                        { title: 'Capital',render:rowData=>formatNumber(rowData.capital)  },
                        { title: 'Prime', render:rowData=>formatNumber(rowData.prime) },
                        { title: 'Statut', render:rowData=><Chip 
                        label={rowData.etape===1?"En attente":(rowData.etape===2?"Transmis":(rowData.etape===3?"Accepté":(rowData.etape===4?"Rejeté":"Annulé")))} 
                        color={rowData.etape===1?"info":(rowData.etape===2?"warning":(rowData.etape===3?"primary":(rowData.etape===4?"danger":null)))} /> },
                        { title: '', render: rowData=><IconButton onClick={()=>handleShowproposition(rowData)}><VisibilityIcon /></IconButton>},
                    ]}
                    data={derniereprop}
                    localization={{
                        body: {emptyDataSourceMessage: 'Aucune donnée trouvée !'},
                    }}
                    options={{
                        exportButton: false,
                        toolbar:false,
                        actionsColumnIndex: -1,
                        paging:false,
                        sorting:false
                    }}
                />
            </div>
        </Paper>
        <Paper className={classes.incorppaper} variant="outlined">
            <div className='flex flex-row bg-slate-200 p-3 rounded-t-md'>
                <Typography className={classes.tabTitle} variant='h5'>Dernières incorporations</Typography>
            </div>
            <Divider />
            <div lassName="flex p-3 w-full">
                <MaterialTable 
                    columns={[ ]}
                    data={[]}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'Aucune donnée trouvée !'
                        },
                    }}
                    options={{
                        exportButton: false,
                        toolbar:false,
                        actionsColumnIndex: -1,
                        paging:false,
                        sorting:false
                    }}
                />
            </div>
        </Paper>
      </div>
      </div>
    </div>
  )
}
const mapActionsToProps = {}

const mapStateProps =(state)=>({
  api:state.api,
  user:state.user
})
export default connect(mapStateProps,mapActionsToProps)(AccueilProduction)

