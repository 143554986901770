import styled from 'styled-components/macro'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import {Button as MaterialButton} from '@material-ui/core'
import Typography from '@material-ui/core/Typography';

export const Container = styled.div`
    display:flex;
    flex-direction:${({direction})=>direction||'row'};
    Justify-content:${({palign})=>palign||'flex-start'};
    align-items:${({salign})=>salign||'flex-start'};
    background-color: #b9f6ca
    ;
    margin:20px
`

export const Inner =styled.div`
    display:flex;
    flex:${(flex)=>flex || 1};
    flex-direction:${({direction})=>direction || 'row'};
    Justify-content:${({palign})=>palign||'flex-start'};
    align-items:${({salign})=>salign||'flex-start'};
    padding:10px;
`
export const Group =styled.div`
    display:flex;
    flex:${(flex)=>flex || 1};
    flex-direction:${({direction})=>direction || 'row'};
    Justify-content:${({palign})=>palign||'flex-start'};
    align-items:${({salign})=>salign||'flex-start'};
    padding:10px;
`

export const Text = styled(Typography)`
`
export const SmallText = styled.p`
    font-size:12px;
`
