import React, { Fragment,useState,useEffect } from 'react'
import {createMuiTheme,makeStyles,ThemeProvider, withStyles } from '@material-ui/core/styles';
import { reduxForm,formValueSelector,change,registerField,getFormValues,reset } from 'redux-form'
import { connect,useDispatch } from 'react-redux';
import { Grid,Box } from '@material-ui/core'
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import dayjs from 'dayjs'
import MuiAlert from '@material-ui/lab/Alert';
import {ageAssure} from 'redux/actions/userActions'
import {addSouscription,uploadDocuments,getPrimeGarantie,getPrimeAccessoireProduit,getCapitalReference,getPrimeReference} from 'redux/actions/apiActions'

import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import AlertSucces from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import {formatNumber} from 'application'
import ModalLoading from 'components/ModalLoading'
const styles =(theme)=>({
  root:{
    marginBottom:10,
    padding:10
  },
  btnNext:{
    backgroundColor:theme.palette.primary.main,
    height:40,
    width:150,
    borderRadius:20,
    color:theme.palette.primary.contrastText
  },
  assureInfoRow:{
    display:'flex',
    flexDirection:'row'
  }
})
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
//button succès
const SuccesButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);
//bouton error
const ErrorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

function SouscriptionFicheresume (props){
    const {classes,
      data:{civiliteSous,nomSous,prenomSous,sexeSous,dateNaissanceSous,lieuNaissanceSous,pieceIdentiteSous,naturePiece,
      lieuResidenceSous,professionSous,employeurSous,paysSous,emailSous,adressePostaleSous,telephoneSous,telephone2Sous,
      mobileSous,mobile2Sous,produit,assures,beneficiaires,modePaiement,organismePayeur,dateEffet,duree,periodicite,
      agence,numCompte,capitalSouscrit,primePrincipale,totalPrime,periodiciteRente,montantRente,dureeRente},
    user,
       handleSubmit,
       UI:{loading} 
    } = props
  useEffect(() => {
    if(props.data.hasOwnProperty('civiliteSous')){
      const valueurSexe = (civiliteSous==="M")?"M":"F"
      props.dispatch(registerField("adForm","sexeSous",'Field'));
      props.updateField("sexeSous",valueurSexe);
    }
  });

    const [succes, setSucces] = useState(false)
    const [newContrat,setNewContrat] = useState(null)
    const dispatch = useDispatch()
    const [loadingui,setLoading] = React.useState(false)
    const saveSouscription =(values)=>{
      let mesDonnees
      mesDonnees =JSON.stringify(values,null,2)
      mesDonnees =JSON.parse(mesDonnees)
      mesDonnees.etat = 1
      {assures.map((assure)=>{
        let garanties = assure.garantiesFacultatives?(assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>(item.checked===true&&item.CodeProduitGarantie!=='SENIOR')))):(assure.garantiesSouscrits)
        let rows =null
        
         
          if(garanties){
           return  garanties.map((garantie)=>(

               <TableRow key={garantie.CodeProduitGarantie}>
                <TableCell component="th" scope="row" align='left'>
                  {`${assure.nomAssure}  ${assure.prenomAssure}`}
                </TableCell>
                <TableCell align="center">{garantie.MonLibelle}</TableCell>
                <TableCell align="center">{garantie.capitalGarantie? formatNumber(parseInt(garantie.capitalGarantie)):''}</TableCell>
                <TableCell align="center">{garantie.prime?formatNumber(parseInt(garantie.prime)):''}</TableCell>
                <TableCell align="center">{garantie.primeAccesoire?formatNumber(garantie.primeAccesoire):''}</TableCell>
                <TableCell align="right">{garantie.primeTotal? formatNumber(garantie.primeTotal):''}</TableCell>
              </TableRow>

            ))
          }
         
        })
      }
      props.addSouscription(values,user.authenticated).then(async (response)=>{
        if(response){
          //upload de documents
          let newdata =JSON.stringify(response,null,2)
          newdata =JSON.parse(newdata)
          
          const mesDocs=props.data.documents
          if(mesDocs && mesDocs.length!==0){
            let formData = new FormData();
            formData.append('id',newdata.contrat.id)
            mesDocs.forEach(element => {
              formData.append('documents[]',element)
            });
            const docUploaded = await uploadDocuments(formData)
          } 
         
          setNewContrat(newdata.contrat)
          setSucces(true)
          handleClickOpen()
        }else{
          setSucces(false)
          handleClickOpen()
        }
      })
    }    
    const [myErrors,setMyErrors]= useState(null)
    const [openAlert, setOpenAlert] = React.useState(false);
   
    const handleOpenAlert = () => {
      setOpenAlert(true);
    };
    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenAlert(false);
      setMyErrors(null)
    };  
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
      if(succes){
        generateBulletin(newContrat)
        props.handleRedirectSouscription(newContrat)
        dispatch(reset('adForm'))
        props.handleRedirectSouscription(newContrat);
      }
    };
    const AlerteForSave =()=>{
      return(
        <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{
            succes?(<AlertSucces icon={<CheckIcon fontSize="inherit" />} severity="success">
              <Typography variant='h4' color={green[400]}>Succès !!!</Typography> </AlertSucces>):(
              <AlertSucces severity="error"><Typography variant='h4' color={red[400]}>Erreur!</Typography></AlertSucces>
            )
          }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {succes?(
            <SuccesButton onClick={handleClose} variant="contained" color="primary" className={classes.margin}>
            OK
          </SuccesButton>
          ):(
            <ErrorButton onClick={handleClose} variant="contained" color="primary" className={classes.margin}>
            OK
          </ErrorButton>
          )}
          
        </DialogActions>
      </Dialog>
    </div>
  

      )
    }
    const evaluationContrat = async (values) =>{
      setLoading(true)
      const {produit,assures,periodicite,duree,capitalSouscrit,dateEffet,primePrincipale}=values
      let garantieCalcul =[]
      let mesGaranties = []
      let totalPrime =0
      let surPrime =0
      let primeAss =0
      if(assures && assures.length===0){
        setLoading(false)
        setMyErrors({type:'warning',message:'Aucun assuré fourni'})
        handleOpenAlert()
        return false
      }
      if(!periodicite || periodicite==='') {
        setLoading(false)
        setMyErrors({type:'warning',message:'Veuillez renseigner la périodicité'})
        handleOpenAlert()
        return false
      }
      if(!duree || duree==='') {
        setLoading(false)
        setMyErrors({type:'warning',message:'Veuillez renseigner la durée'})
        handleOpenAlert()
        return false
      }
      

        switch (produit.CodeProduit) {
          case 'YKE_2008': case 'YKS_2008': case 'YKF_2008': case 'YKL_2004': case 'YKV_2004': case 'YKF_2004':
              //traitement des produit YAKO
             // assures.garantiesSouscrits =garantieCalcul
              if(!capitalSouscrit || capitalSouscrit==='') {
                setLoading(false)
                setMyErrors({type:'warning',message:'Veuillez renseigner le capital'})
                handleOpenAlert()
                return false
              }   
           
                      
              await Promise.all(assures.map(async (assure)=>{
                //création des paramètres de selection de la prime 
                const setParams = maGarantie=>{return new Promise((resolve,reject)=>{
                    const capitalRef = (maGarantie.estobligatoire===1)?capitalSouscrit:props.data[`capital-${maGarantie.CodeProduitGarantie}`] 
                    resolve({
                        CodeProduit:produit.CodeProduit,
                        codePeriodicite:periodicite,
                        duree:duree,
                        capitalSouscrit:capitalSouscrit,
                        dateEffet:dateEffet,
                        age:ageAssure(assure.dateNaissanceAssure),
                        codeGarantie:maGarantie.CodeProduitGarantie
                    })
                })}

              // création paramètre de selection de la prime de référence par produit
              const setParamsRef = maGarantie=>{return new Promise((resolve,reject)=>{
                const capitalRef2 = (maGarantie.estobligatoire===1)?capitalSouscrit:props.data[`capital-${maGarantie.CodeProduitGarantie}`] 
                resolve({
                    CodeProduit:produit.CodeProduit,
                    periodicite:periodicite,
                    duree:duree,
                    primePrincipale:primePrincipale,
                    capitalgarantie:parseInt(capitalRef2),
                    age:ageAssure(assure.dateNaissanceAssure),
                    codeGarantie:maGarantie.CodeProduitGarantie
                })
              })}
                if(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0){
                  garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>item.checked===true&&item.CodeProduitGarantie!=='SENIOR'))
                }else{
                  garantieCalcul = assure.garantiesSouscrits
                }
                
                return await Promise.all(garantieCalcul.map(async (garantie) => {
                    return getPrimeGarantie(await setParams(garantie)).then(async(data)=>{
                      if(data){
                        if(garantie.estprincipal===1){
                          primeAss = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)
                          primeAss = (primeAss && primeAss[0])? primeAss[0].montant :0   
                        } else{
                          primeAss=0
                        }                        
                        garantie.prime = data[0].Prime
                        garantie.capitalGarantie = data[0].Capital
                        garantie.primeAccesoire =  primeAss
                        garantie.primeTotal = (parseInt(data[0].Prime)+parseInt(primeAss))
                        totalPrime+=(parseInt(data[0].Prime)+parseInt(primeAss))
                        mesGaranties.push(garantie)
                      }else{
                        const capitalRef3 = (garantie.estobligatoire===1)?capitalSouscrit:props.data[`capital-${garantie.CodeProduitGarantie}`] 
                        const autreGarantie = await getPrimeReference({
                          CodeProduit:produit.CodeProduit,
                          periodicite:periodicite,
                          duree:duree,
                          capitalgarantie:parseInt(capitalRef3),
                          age:ageAssure(assure.dateNaissanceAssure),
                          codeGarantie:garantie.CodeProduitGarantie
                      })
                      
                      if(autreGarantie && autreGarantie!==undefined && autreGarantie.length!==0 ){
                        garantie.prime = autreGarantie[0].Prime
                        garantie.capitalGarantie = autreGarantie[0].Capital
                        garantie.primeAccesoire =  0
                        garantie.primeTotal = (parseInt(autreGarantie[0].Prime))
                        totalPrime+=(parseInt(autreGarantie[0].Prime))
                        mesGaranties.push(garantie)
                      }else{
                        //affectation des prime pour la garantie senio YAKO Eternite
                         
                        if(garantie.CodeProduitGarantie ==='SENIOR' && props.data.hasOwnProperty(`prime-${garantie.CodeProduitGarantie}`)){
                         
                          garantie.prime = props.data[`prime-${garantie.CodeProduitGarantie}`]
                          garantie.capitalGarantie =""
                          garantie.primeAccesoire =  0
                          garantie.primeTotal = (parseInt(props.data[`prime-${garantie.CodeProduitGarantie}`]))    
                          totalPrime+=(parseInt(props.data[`prime-${garantie.CodeProduitGarantie}`]))
                          mesGaranties.push(garantie)
                        }
                      }
                        
                      }
                        return garantie
                    })

                })).then(mesgaranties=>{
                     assure.garantiesAssures = mesgaranties
                     return assure
                })
              })).then(async (mesAssures)=>{
                  //calcul de la primeprincipale
                  surPrime = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)
                  surPrime =(surPrime && surPrime[0])?surPrime[0].montant:0
                  if(!props.hasOwnProperty('primePrincipale')){
                    props.dispatch(registerField("adForm",'primePrincipale','Field'));
                   }
                  props.updateField('primePrincipale',totalPrime)
                  //assuré(e)s
                  if(!props.hasOwnProperty('assures')){
                    props.dispatch(registerField("adForm",'assures','Field'));
                   }
                  props.updateField('assures',mesAssures)
                 //surprime
                 if(!props.hasOwnProperty('surPrime')){
                  props.dispatch(registerField("adForm",'surPrime','Field'));
                 }
                 props.updateField('surPrime',surPrime)
                 //totalPrime
                 if(!props.hasOwnProperty('totalPrime')){
                  props.dispatch(registerField("adForm",'totalPrime','Field'));
                 }
                 props.updateField('totalPrime',(totalPrime))
                 props.onSubmit()
                 setLoading(false)
              })
            break;
          case 'CADENCE':
            
            let partPrime
            let nbGarantieObligatoire
            if(!primePrincipale || primePrincipale==='') {
              setLoading(false)
              setMyErrors({type:'warning',message:'Veuillez renseigner la prime principale'})
              handleOpenAlert()
              return false
            }
            
           
            //get capital référence
            
            await Promise.all(assures.map(async (assure)=>{
              //récupération de la garantie
              const setParamsCapital ={
                CodeProduit:produit.CodeProduit,
                periodicite:periodicite,
                duree:duree,
                primePrincipale:primePrincipale,
                age:ageAssure(assure.dateNaissanceAssure)
              }
              getCapitalReference(setParamsCapital).then(data=>{
                
                if(!props.hasOwnProperty('capitalSouscrit')){
                  props.dispatch(registerField("adForm",'capitalSouscrit','Field'));
                }
                props.updateField('capitalSouscrit',data.capital3)
                if(!props.hasOwnProperty('capitalSouscrit5')){
                  props.dispatch(registerField("adForm",'capitalSouscrit5','Field'));
                }
                props.updateField('capitalSouscrit',data.capital5)

                if(!props.hasOwnProperty('cumulCotisation')){
                  props.dispatch(registerField("adForm",'cumulCotisation','Field'));
                }
                props.updateField('cumulCotisation',data.cumulcotisation)
              })

              const setParams = maGarantie=>{return new Promise((resolve,reject)=>{
                  resolve({
                      CodeProduit:produit.CodeProduit,
                      codePeriodicite:periodicite,
                      duree:duree,
                      dateEffet:dateEffet,
                      capitalSouscrit:props.data[`capital-${maGarantie.CodeProduitGarantie}`],
                      age:ageAssure(assure.dateNaissanceAssure ),
                      codeGarantie:maGarantie.CodeProduitGarantie
                  })
              })}
              
              if(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0){
                garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>item.checked===true&&item.CodeProduitGarantie!=='SENIOR'))
              }else{
                garantieCalcul = assure.garantiesSouscrits
              }
              
              nbGarantieObligatoire= garantieCalcul.filter(item=>item.estobligatoire===1).length
              partPrime =(parseInt(primePrincipale)/nbGarantieObligatoire)
              return await Promise.all(garantieCalcul.map(async (garantie) => {
                if(garantie.CodeProduitGarantie==='SUR'){
                  return getPrimeGarantie(await setParams(garantie)).then(async (data)=>{
                    if(data){
                      garantie.prime = data[0].Prime
                      garantie.primeAccesoire =  0
                      garantie.primeTotal = parseInt(data[0].Prime)
                      garantie.capitalGarantie = props.data[`capital-${garantie.CodeProduitGarantie}`]

                      totalPrime+=parseInt(data[0].Prime)
                      mesGaranties.push(garantie)
                      if(!props.hasOwnProperty('capitalSouscrit')){
                        props.dispatch(registerField("adForm",'capitalSouscrit','Field'));
                      }
                      props.updateField('capitalSouscrit',props.data[`capital-${garantie.CodeProduitGarantie}`])
                    }
                    return garantie
                  })
                }else if(garantie.CodeProduitGarantie==='DECESACC'){
                  const maprimeDecess =   parseInt(primePrincipale)-(parseInt(primePrincipale)*0.95)
                  garantie.prime =maprimeDecess
                  garantie.primeAccesoire =  0
                  garantie.primeTotal =maprimeDecess
                  totalPrime+=maprimeDecess
                  mesGaranties.push(garantie)
                  return garantie
                }else{
                  if(garantie.estprincipal===1){
                    primeAss =await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)   
                    primeAss = (primeAss && primeAss.length!==0)? primeAss[0].montant :0
                  } else{
                    primeAss=0
                  }
                  garantie.prime = partPrime
                  garantie.primeAccesoire =  primeAss
                  garantie.primeTotal = (parseInt(partPrime)+parseInt(primeAss))

                  totalPrime+=parseInt(partPrime)+primeAss
                  mesGaranties.push(garantie)
                  return garantie
                } 
              })).then(mesgaranties=>{
                   assure.garantiesAssures = mesgaranties
                   return assure
              })

            })).then(async (mesAssures)=>{
                //calcul de la primeprincipale
                surPrime = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)
                surPrime =surPrime?surPrime[0].montant:0 
                //assuré(e)s
                if(!props.hasOwnProperty('assures')){
                  props.dispatch(registerField("adForm",'assures','Field'));
                 }
                props.updateField('assures',mesAssures)
               //surprime
               if(!props.hasOwnProperty('surPrime')){
                props.dispatch(registerField("adForm",'surPrime','Field'));
               }
               props.updateField('surPrime',surPrime)
               //totalPrime
               if(!props.hasOwnProperty('totalPrime')){
                props.dispatch(registerField("adForm",'totalPrime','Field'));
               }
               setLoading(false)
               props.updateField('totalPrime',(totalPrime+surPrime))
               props.onSubmit()
            })
            break;
          case 'PFA_IND': case 'PFA_COL': case 'PFA_BNI':
          
            if(!primePrincipale || primePrincipale==='') {
              setLoading(false)
              setMyErrors({type:'warning',message:'Veuillez renseigner la prime principale'})
              handleOpenAlert()
              return false
            }

            await Promise.all(assures.map(async (assure)=>{
              
              const setParams = maGarantie=>{return new Promise((resolve,reject)=>{
                  resolve({
                      CodeProduit:produit.CodeProduit,
                      codePeriodicite:periodicite,
                      duree:duree,
                      dateEffet:dateEffet,
                      capitalSouscrit:props.data[`capital-${maGarantie.CodeProduitGarantie}`],
                      age:ageAssure(assure.dateNaissanceAssure ),
                      codeGarantie:maGarantie.CodeProduitGarantie
                  })
              })}
              
               
              if(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0){
                garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>item.checked===true&&item.CodeProduitGarantie!=='SENIOR'))
              }else{
                garantieCalcul = assure.garantiesSouscrits
              }
              
              return await Promise.all(garantieCalcul.map(async (garantie) => {
                if(garantie.CodeProduitGarantie==='SUR'){
                  return getPrimeGarantie(await setParams(garantie)).then(data=>{
                    if(data){
                      garantie.prime = data[0].Prime
                      garantie.primeAccesoire =  0
                      garantie.primeTotal = parseInt(data[0].Prime)
                      garantie.capitalGarantie = props.data[`capital-${garantie.CodeProduitGarantie}`]

                      totalPrime+=parseInt(data[0].Prime)
                      mesGaranties.push(garantie)
                      if(!props.hasOwnProperty('capitalSouscrit')){
                        props.dispatch(registerField("adForm",'capitalSouscrit','Field'));
                      }
                      props.updateField('capitalSouscrit',props.data[`capital-${garantie.CodeProduitGarantie}`])
                    
                    }
                    return garantie
                })
                }else if (garantie.CodeProduitGarantie==='PERF'){
                  if(garantie.estprincipal===1){
                    primeAss =await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)   
                    primeAss = (primeAss && primeAss.length!==0)? primeAss[0].montant :0
                  } else{
                    primeAss=0
                  }
                  const maprimePerf =  (parseInt(primePrincipale)*(40/100))  
                  garantie.prime =maprimePerf
                  garantie.primeAccesoire =  primeAss
                  garantie.primeTotal =maprimePerf
                  totalPrime+=maprimePerf
                  mesGaranties.push(garantie)  
                  return garantie
                }else{
                    if(garantie.estprincipal===1){
                      primeAss =await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)   
                      primeAss = (primeAss && primeAss.length!==0)? primeAss[0].montant :0
                    } else{
                      primeAss=0
                    }
                    const maprimeSecu = (parseInt(primePrincipale)*60/100)  
                    garantie.prime =maprimeSecu
                    garantie.primeAccesoire =  primeAss
                    garantie.primeTotal =maprimeSecu
                    totalPrime+=maprimeSecu
                    mesGaranties.push(garantie)  
                }  
              })).then(mesgaranties=>{
                   assure.garantiesAssures = mesgaranties
                   return assure
              })

            })).then(async (mesAssures)=>{
                //calcul de la primeprincipale
                surPrime = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite) 
                surPrime = surPrime?surPrime[0].montant:0
                //assuré(e)s
                if(!props.hasOwnProperty('assures')){
                  props.dispatch(registerField("adForm",'assures','Field'));
                 }
                props.updateField('assures',mesAssures)
               //surprime
               if(!props.hasOwnProperty('surPrime')){
                props.dispatch(registerField("adForm",'surPrime','Field'));
               }
               props.updateField('surPrime',surPrime)
               //totalPrime
               if(!props.hasOwnProperty('totalPrime')){
                props.dispatch(registerField("adForm",'totalPrime','Field'));
               }
               setLoading(true)
               props.updateField('totalPrime',(totalPrime+surPrime))
               props.onSubmit()
            })
            break;
          case 'PVRBNI':
                const {dureeRente,montantRente,periodiciteRente} =values
                if(!dureeRente || dureeRente==='') {
                    setLoading(false)
                    setMyErrors({type:'warning',message:'Veuillez renseigner la durée de la rente'})
                    handleOpenAlert()
                    return false
                }
                if(!montantRente || montantRente==='') {
                    setLoading(false)
                    setMyErrors({type:'warning',message:'Veuillez renseigner le montant de la rente'})
                    handleOpenAlert()
                    return false
                }
                if(!periodiciteRente || periodiciteRente==='') {
                    setLoading(false)
                    setMyErrors({type:'warning',message:'Veuillez renseigner la périodicite de la rente'})
                    handleOpenAlert()
                    return false
                }
                //determination de la prime net 1
                const dureeCotisation = duree
                const i_c = 0.035; const fg_c =0.05; const i_net_c = i_c*(1-fg_c)
                let per_c =12
                switch (periodicite) {
                    case 'M':
                        per_c=12
                        break;
                    case 'T':
                        per_c=4
                        break;
                    case 'S':
                        per_c=2
                        break;
                    default:
                        per_c=1
                        break;
                };
                const i_per =(Math.pow((1+i_net_c),(1/per_c)))-1
                let v_c = 1/(1+i_per)
                let d_c = i_per/(1+i_per)
                let valeur_a_c=(1-Math.pow(v_c,(dureeCotisation*per_c)))/d_c

                const tx_fg =0.04
                const tx_fa =0.05
                const tx_investi = 1-tx_fg-tx_fa
                const prime_net1 = (valeur_a_c*tx_investi)

                // determination prime rente
                const i_r = 0.035
                const fg_r =0.05
                const i_net_r = i_r*(1-fg_r)
                let per_r =12
                switch (periodiciteRente) {
                    case 'M':
                        per_r=12
                        break;
                    case 'T':
                        per_r=4
                        break;
                    case 'S':
                        per_r=2
                        break;
                    default:
                        per_r=1
                        break;
                };
                const i_per_r =(Math.pow((1+i_net_r),(1/per_r)))-1
                const v_r = 1/(1+i_per_r)
                const d_r = i_per_r/(1+i_per_r)
                const dureeRentea = parseInt(dureeRente)/12
                const dureeTotal = dureeRentea+parseInt(dureeCotisation)
                const valeur_a_r =(1-Math.pow(v_r,((dureeTotal-dureeCotisation)*per_r)))/d_r
                const vn = Math.pow(v_r,dureeCotisation*per_r)

                const tx_fg_r = (1+0.03)
                const primeRelle = parseInt((parseInt(montantRente)*tx_fg_r*valeur_a_r*vn)/prime_net1)
                await Promise.all(assures.map(async (assure)=>{

                    if(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0){
                        garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>item.checked===true&&item.CodeProduitGarantie!=='SENIOR'))
                    }else{
                        garantieCalcul = assure.garantiesSouscrits
                    }

                    return await Promise.all(garantieCalcul.map(async (garantie) => {
                        garantie.prime = primeRelle
                        garantie.primeAccesoire =  0
                        garantie.primeTotal = parseInt(primeRelle)
                        garantie.capitalGarantie = 0

                        totalPrime+=primeRelle
                        return garantie

                    })).then(mesgaranties=>{
                        assure.garantiesAssures = mesgaranties
                        return assure
                    })

                })).then(async (mesAssures)=>{
                    //calcul de la primeprincipale

                    if(!props.hasOwnProperty('assures')){
                        props.dispatch(registerField("adForm",'primePrincipale','Field'));
                    }
                    props.updateField('primePrincipale',primeRelle)

                    surPrime = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)
                    surPrime =0; //surPrime?surPrime[0].montant:0
                    //assuré(e)s

                    if(!props.hasOwnProperty('assures')){
                        props.dispatch(registerField("adForm",'assures','Field'));
                    }
                    props.updateField('assures',mesAssures)
                    //surprime
                    if(!props.hasOwnProperty('surPrime')){
                        props.dispatch(registerField("adForm",'surPrime','Field'));
                    }
                    props.updateField('surPrime',surPrime)
                    //totalPrime
                    if(!props.hasOwnProperty('totalPrime')){
                        props.dispatch(registerField("adForm",'totalPrime','Field'));
                    }
                    setLoading(true)
                    props.updateField('totalPrime',(totalPrime+surPrime))
                    props.onSubmit()
                })
            break;
          default:
            break;
        }
       dispatch({type:'STOP_LOADING_UI'})
    }
  //code de génération du bulletin de sosucription
    const generateBulletin = (refContrat) =>{
      
      const headRows1 = ()=> ([{ libelle: 'Libelle', valeur: 'Valeur'}])
     
      let doc = new jsPDF();

      let img = new Image(40, 10)
      img.src = require('assets/images/logo_llv_n.jpg')
      doc.addImage(img, "JPEG", 15, 10, 45, 15);
      const {credentials} = props.user
      const {branche, partenaire}=credentials
      if(branche!=='BANKASS'){
        let logoEnov =new Image(25, 10)
        logoEnov.src = require('assets/images/logo.png')
        doc.addImage(logoEnov, "PNG", 180, 10, 10, 10);
      }else{
        let logoPart = new Image(25, 10)
        logoPart.src = require('assets/images/BNI.jpeg')
        doc.addImage(logoPart, "JPEG", 180, 10, 20, 20);
      }

      let codeRef = produit.CodeProduit.substring(0,3)
      if(partenaire && partenaire.trim()!==''){
        codeRef = partenaire.toUpperCase()+'-'+codeRef
      }

      doc.text(80, 25, 'BULLETIN DE SOUSCRIPTION');
      doc.setLineWidth(0.1);
      doc.line(15, 30, 195, 30);
      doc.setFontStyle('bold')
      doc.setFontSize(14)
      doc.text(80,35,'N° : '+codeRef+'-'+dayjs().format('MMYYYY')+refContrat.id)

      doc.autoTable({
        startY: 38,
        head:[{ 
        libelle: 'Libelle',valeur:'Valeur',
        libelle: 'Libelle',valeur:'Valeur',
        libelle: 'Libelle',valeur:'Valeur'}],
        body: [
          ['Produit :',produit&& produit.MonLibelle,'Conseiller :',refContrat.codeConseiller,'Agence :',credentials.agence?credentials.agence:""],
          [],
        ],
        styles: { overflow: 'ellipsize',cellWidth:'auto',fontSize:9,valign:'center'},
        showHead: false,
        columnStyles: {
          libelle: { textColor: 0, fontStyle: 'bold',fillColor:[255, 255, 255], },
          valeur: { textColor: 0, fontStyle: 'normal',halign:'left',fillColor:[255, 255, 255] },
        },
        columns: [
          { header: 'Libelle', dataKey: 'libelle' },
          { header: 'Valeur', dataKey: 'valeur' },
          { header: 'Libelle', dataKey: 'libelle' },
          { header: 'Valeur', dataKey: 'valeur' },
          { header: 'Libelle', dataKey: 'libelle' },
          { header: 'Valeur', dataKey: 'valeur' },
        ],
  
      });
      
     
      let finalY = doc.previousAutoTable.finalY
     
      doc.autoTable({
        startY: finalY+3,
        head:[{ libelle: 'Libelle'}],
        body: [
          [{ content: 'ADHERENT / SOUSCRIPTEUR'}],
        ],
        showHead: false,
        styles: { overflow: 'ellipsize',cellWidth: 'auto',lineWidth:0.1,lineColor:0,fontSize:9},
        columnStyles: {
          libelle: { textColor: 0, fontStyle: 'bold',fillColor:[255, 255, 255], },
        },
        columns: [
          { header: 'Libelle',dataKey:'libelle'},
        ],
      })


      finalY = doc.previousAutoTable.finalY
      
      doc.autoTable({
        startY: finalY+3,
        head:headRows1(),
        body: [
          ["N° ",`${naturePiece?naturePiece:" CNI"} : ${naturePiece?naturePiece:""}`],
          ["Nom : ",nomSous],
          ["Prénom : ",prenomSous],
          ["Né(e) le : ",dateNaissanceSous?dayjs(dateNaissanceSous).format('DD/MM/YYYY'):""],
          [" A : ",`${lieuNaissanceSous?lieuNaissanceSous:""}`],
          ["Lieu de résidence : ",`${lieuResidenceSous?lieuResidenceSous:""}`],
        ],
        showHead: false,
        styles: { overflow: 'ellipsize',cellWidth: 'auto',fontSize:9, valign:'center'},
        columnStyles: {
          libelle: { textColor: 0, fontStyle: 'bold',fillColor:[255, 255, 255], },
          valeur: { textColor: 0, fontStyle: 'normal',halign:'left',fillColor:[255, 255, 255]},
        },
        columns: [
          { header: 'Libelle', dataKey: 'libelle' },
          { header: 'valeur', dataKey: 'valeur' },
        ],
        tableWidth:75,
      })
      doc.autoTable({
        startY: finalY+3,
        head:headRows1(),
        body: [
          ["Profession :",professionSous?professionSous:""],
          ["Employeur : ",employeurSous?employeurSous:""],
          ["Email : ",emailSous?emailSous:""],
          ["Téléphone : ",`${telephoneSous?telephoneSous:""}`],
          ["Mobile : ",`${mobileSous?mobileSous:""} ${mobile2Sous?"/"+mobile2Sous:""}`],
        ],
        showHead: false,
        styles: { overflow: 'ellipsize',cellWidth: 'auto',fontSize:9},
        columnStyles: {
          libelle: { textColor: 0, fontStyle: 'bold',fillColor:[255, 255, 255], },
          valeur: { textColor: 0, fontStyle: 'normal',halign:'left',fillColor:[255, 255, 255]},
        },
        columns: [
          { header: 'Libelle', dataKey: 'libelle' },
          { header: 'Valeur', dataKey: 'valeur' },
        ],
        margin: { left: 105 },
        tableWidth:75,
      })
    
      finalY = doc.previousAutoTable.finalY

 //Affectation des assurées
      doc.autoTable({
        startY: finalY+10,
        head:[{ libelle: 'Libelle'}],
        body: [
          [{ content: 'ASSURES'},],
        ],
        showHead: false,
        styles: { overflow: 'ellipsize',cellWidth: 'auto',lineWidth:0.1,lineColor:0 ,fontSize:9},
        columnStyles: {
          libelle: { textColor: 0, fontStyle: 'bold',fillColor:[255, 255, 255], },
        },
        columns: [
          { header: 'Libelle', dataKey: 'libelle' }
        ],
      })
      finalY = doc.previousAutoTable.finalY

     
     const headAssure =()=>([
      { nomAssure: 'Nom', filiationAssure: 'Filiation',dateNaissanceAssure:'Né(e) le',lieuNaissanceAssure:'Lieu naissance',lieuResidenceAssure:'Lieu Résidence'}
     ]);
     const bodyAssure =()=>{
      let body = []
        if(assures){
          assures.map((assure)=>{
            body.push({
              nomAssure:assure.nomAssure + ' ' + assure.prenomAssure,
              filiationAssure:assure.filiationAssure,
              dateNaissanceAssure:dayjs(assure.dateNaissanceAssure).format('DD/MM/YYYY'),
              lieuNaissanceAssure:assure.lieuNaissanceAssure,
              lieuResidenceAssure:assure.lieuResidenceAssure
            })
          })
        }
       
        return body
     }
    doc.autoTable({
      startY: finalY+3,
      head:headAssure(),
      body: bodyAssure(),
      showHead: true,
      styles: { overflow: 'ellipsize',cellWidth: 'auto',fontSize:9},
      bodyStyles:{fillColor:[255, 255, 255]},
      headStyles:  { textColor:0, fontStyle:'bold',fillColor:[255, 255, 255] },
    });
    finalY = doc.previousAutoTable.finalY

    //Affectation des bénéficiaire
    doc.autoTable({
      startY: finalY+3,
      head:[{ libelle: 'Libelle'}],
      body: [
        [{ content: 'BENEFICIAIRES / REPRESENTANTS DESIGNES'},],
      ],
      showHead: false,
      styles: { overflow: 'ellipsize',cellWidth: 'auto',lineWidth:0.1,lineColor:0,fontSize:9 },
      columnStyles: {
        libelle: { textColor: 0, fontStyle: 'bold',fillColor:[255, 255, 255], },
      },
      columns: [
        { header: 'Libelle', dataKey: 'libelle' },
      ],
    })

    finalY = doc.previousAutoTable.finalY

    const headBenficaire =()=>([
      { nomBeneficiaire: 'Nom', filiationBeneficiaire: 'Filiation',dateNaissanceBeneficiaire:'Né(e) le',lieuNaissanceBeneficiaire:'Lieu naissance',telephoneBeneficiaire:'Téléphone'}
     ]);
     const bodyBeneficaire =()=>{
      let body = []
        if(beneficiaires){
          beneficiaires.map((beneficiaire)=>{
            body.push({
              nomBeneficiaire:beneficiaire.nomBeneficiaire + ' ' + beneficiaire.prenomBeneficiaire,
              filiationBeneficiaire:beneficiaire.filiationBeneficiaire,
              dateNaissanceBeneficiaire:dayjs(beneficiaire.dateNaissanceBeneficiaire).format('DD/MM/YYYY'),
              lieuNaissanceBeneficiaire:beneficiaire.lieuNaissanceBeneficiaire,
              telephoneBeneficiaire:beneficiaire.telephoneBeneficiaire
            })
          })
        }
        return body
     }
    doc.autoTable({
      startY: finalY+3,
      head:headBenficaire(),
      body: bodyBeneficaire(),
      showHead: true,
      styles: { overflow: 'ellipsize',cellWidth: 'auto',fontSize:9},
      bodyStyles:{fillColor:[255, 255, 255]},
      headStyles:  { textColor:0, fontStyle:'bold',fillColor:[255, 255, 255] },
    });

    //Assuré garantie
    finalY = doc.previousAutoTable.finalY
    doc.autoTable({
      startY: finalY+3,
      head:[{ libelle: 'Libelle'}],
      body: [
        [{ content: 'GARANTIES & PRIMES'},],
      ],
      showHead: false,
      styles: { overflow: 'ellipsize',cellWidth: 'auto',lineWidth:0.1,lineColor:0,fontSize:9 },
      columnStyles: {
        libelle: { textColor: 0, fontStyle: 'bold',fillColor:[255, 255, 255], },
      },
      columns: [
        { header: 'Libelle', dataKey: 'libelle' }
      ],
    })
    finalY = doc.previousAutoTable.finalY

    const headGaranties =()=>([
      { nomAssure: 'Nom', garantie: 'Garantie',capitalGarantie:'Capital',primeGarantie:'Prime',primeAccesoire:'Accéssoire',totalprime:'Total prime'}
     ]);
     const bodyGaranties =()=>{
      let body = []
      let monAssure ={}
      let garanties
        if(assures){
         
          assures.map((assure)=>{
            if(assure.garantiesFacultatives){
              garanties = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>(item.checked===true&&item.CodeProduitGarantie!=='SENIOR')))
            }else{
              garanties = assure.garantiesSouscrits
            }
            
            if(garanties){
              garanties.map((garantie)=>{
                body.push({
                  nomAssure:assure.nomAssure + ' ' + assure.prenomAssure,
                  garantie:garantie.MonLibelle,
                  capitalGarantie:garantie.capitalGarantie?formatNumber(parseInt(garantie.capitalGarantie)) :'',
                  primeGarantie:garantie.prime?formatNumber(parseInt(garantie.prime)):'',
                  primeAccesoire:garantie.primeAccesoire?formatNumber(garantie.primeAccesoire):'',
                  totalprime:garantie.primeTotal? formatNumber(garantie.primeTotal):''
                })
              })
            }
          })
        }
       
        
        return body
     }
    doc.autoTable({
      startY: finalY+3,
      head:headGaranties(),
      body: bodyGaranties(),
      styles: { overflow: 'ellipsize',cellWidth: 'auto',fontSize:9},
      bodyStyles:{fillColor:[255, 255, 255]},
      headStyles: { textColor:0, fontStyle:'bold',fillColor:[255, 255, 255] },
      columnStyles: {
        totalprime: { textColor: 0, fontStyle: 'bold',halign:'right',fontSize:'10' },
      },
    });
    finalY = doc.previousAutoTable.finalY

    doc.setFontSize(12)
    doc.setFontStyle('bold')
    doc.text('PRIME TOTALE : '+formatNumber(totalPrime),135,finalY+5)
   

    doc.setLineWidth(0.1);
    doc.line(60, finalY+8, 150, finalY+8);

    doc.autoTable({
      startY: finalY+10,
      head:[{capitalSous:'Capital souscrit',dateeffet:'Date effet',dure:'Durée',echeance:'Echéance'}],
      body: [[`${capitalSouscrit?formatNumber(parseInt(capitalSouscrit)):""}`,`${dateEffet?dayjs(dateEffet).format('DD/MM/YYYY'):""}`,`${duree?duree:""}`,`${(duree && dateEffet)?dayjs(dateEffet).add(duree,'year').format('DD/MM/YYYY'):""}`]],
      showHead: true,
      styles: { overflow: 'ellipsize',cellWidth: 'auto',fontSize:9},
      bodyStyles:{fillColor:[255, 255, 255]},
      headStyles: { textColor:0,fontStyle:'bold',fillColor:[255, 255, 255]},
    });
    //paiement des PRIMES
    finalY = doc.previousAutoTable.finalY
    doc.autoTable({
      startY: finalY+3,
      head:headRows1(),
      body: [
        [{ content: 'PAIEMENT DES PRIMES'},],
      ],
      showHead: false,
      styles: { overflow: 'ellipsize',cellWidth: 'auto',lineWidth:0.1,lineColor:0, fontSize:9 },
      columnStyles: {
        libelle: { textColor: 0, fontStyle: 'bold',fillColor:[255, 255, 255], },
      },
      columns: [
        { header: 'Libelle', dataKey: 'libelle' },
      ],
    })
    finalY = doc.previousAutoTable.finalY
    doc.autoTable({
      startY: finalY+3,
      head:[{libelle:'Libelle',valeur:'Valeur',libelle:'Libelle',valeur:'Valeur',libelle:'Libelle',valeur:'Valeur',libelle:'Libelle',valeur:'Valeur'}],
      body: [
        ['Mode de paiement : ',modePaiement, 'Organisme :',organismePayeur,'Agence :',agence,'N° Compte',numCompte],
      ],
      styles: { overflow: 'ellipsize',cellWidth: 'auto',fontSize:9, valign:'center',halign:'left' },
      showHead: false,
      columnStyles: {
        libelle: { textColor: 0, fontStyle: 'bold',fillColor:[255, 255, 255], },
        valeur: { textColor: 0, fontStyle: 'normal',halign:'left',fillColor:[255, 255, 255], cellPadding:0.5 },
      },
      columns: [
        { header: 'Libelle', dataKey: 'libelle' },
        { header: 'Valeur', dataKey: 'valeur' },

        { header: 'Libelle', dataKey: 'libelle' },
        { header: 'Valeur', dataKey: 'valeur' },

        { header: 'Libelle', dataKey: 'libelle' },
        { header: 'Valeur', dataKey: 'valeur' },

        { header: 'Libelle', dataKey: 'libelle' },
        { header: 'Valeur', dataKey: 'valeur' },
      ],
    })
   

    finalY = doc.previousAutoTable.finalY
    doc.setFontSize(9)
    doc.setFontStyle('bold')
    doc.text(15,finalY+15,'Fait à ..................... le '+ new Date().toLocaleDateString())
 
    doc.text(20,finalY+25,` LA LOYALE VIE                                                SOUSCRIPTEUR                                                  ${(branche==='BANKASS')?partenaire.toUpperCase():'L\'ASSURE(E)'}`)
    
    doc.output('dataurlnewwindow');
    }  
    useEffect(() => {
      dispatch({type:'LOADING_UI'})
      evaluationContrat(props.data)
    }, [])
    return (
      <Fragment>
           {AlerteForSave()}
        {myErrors&&(
          <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
          
        >
          <Alert onClose={handleCloseAlert} severity={myErrors.type}>
            {myErrors.message}
        </Alert>
        </Snackbar>
        )}
        {!loading? (
          <>
            <Card className={classes.root} variant="outlined">

              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  SOUSCRIPTEUR
                </Typography>

                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Titre :
                      </Typography>
                      <Typography variant="subtitle1">
                          {civiliteSous}
                      </Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Nom :
                      </Typography>
                      <Typography variant="subtitle1">
                          {nomSous}
                      </Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Prénoms :
                        </Typography>
                        <Typography variant="subtitle1"> {prenomSous}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Sexe :
                    </Typography>
                        <Typography variant="subtitle1">
                            {sexeSous}
                        </Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Date de naissance :
                        </Typography>
                        <Typography variant="subtitle1"> {dayjs(dateNaissanceSous).format('DD/MM/YYYY')}</Typography>
                    </Box>
               
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Lieu de naissance:
                        </Typography>
                        <Typography variant="subtitle1"> {lieuNaissanceSous}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Lieu de résidence:
                        </Typography>
                        <Typography variant="subtitle1"> {lieuResidenceSous}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          N° {naturePiece} :
                        </Typography>
                        <Typography variant="subtitle1"> {pieceIdentiteSous}</Typography>
                    </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                    <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Profession :
                </Typography>
                    <Typography variant="subtitle1">
                        {professionSous}
                    </Typography>
                </Box>
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                       Employeur:
                    </Typography>
                    <Typography variant="subtitle1"> {employeurSous}</Typography>
                </Box>
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Pays :
                    </Typography>
                    <Typography variant="subtitle1"> {paysSous}</Typography>
                </Box>
                <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Email :
                </Typography>
                    <Typography variant="subtitle1">
                        {emailSous}
                    </Typography>
                </Box>
                
               
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                       Téléphone:
                    </Typography>
                    <Typography variant="subtitle1"> {telephoneSous}</Typography>
                </Box>
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                       Mobile :
                    </Typography>
                    <Typography variant="subtitle1"> {mobileSous} / {mobile2Sous}</Typography>
                </Box>
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                       Adresse postale :
                    </Typography>
                    <Typography variant="subtitle1"> {adressePostaleSous} </Typography>
                </Box>
                    </Grid>
                </Grid>
                
              </CardContent>
              
            </Card> 
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                CONDITIONS DE PAIEMENT DE LA PRIME & PERIODICITE
                </Typography>
                <Grid container>
                    <Grid item sm={6} xs={12}>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Produit :
                      </Typography>
                          <Typography variant="subtitle1">
                              {produit&& produit.MonLibelle}
                          </Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Date Effet:
                        </Typography>
                        <Typography variant="subtitle1"> {dayjs(dateEffet).format('DD/MM/YYYY')}</Typography>
                    </Box>
               
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Prime principale:
                        </Typography>
                        <Typography variant="subtitle1"> {primePrincipale?formatNumber(primePrincipale):""}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Echéance paiement :
                        </Typography>
                        <Typography variant="subtitle1"> {dayjs(dateEffet).add(duree,'year').format('DD/MM/YYYY')}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Capital désiré :
                      </Typography>
                          <Typography variant="subtitle1">
                              {capitalSouscrit?formatNumber(parseInt(capitalSouscrit)) :""}
                          </Typography>
                      </Box>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Mode paiement:
                        </Typography>
                        <Typography variant="subtitle1"> {modePaiement}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Organisme payeur :
                      </Typography>
                      <Typography variant="subtitle1"> {organismePayeur}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Agence :
                      </Typography>
                      <Typography variant="subtitle1"> {agence}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      N° Compte :
                    </Typography>
                    <Typography variant="subtitle1">
                        {numCompte}
                    </Typography>
                </Box>
                
                    </Grid>
                </Grid>
                
              </CardContent>
              
            </Card> 
            <Card className={classes.root} variant="outlined">

              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  ASSURE(E)S
                </Typography>
                <Grid container>
                    <Grid item sm={12} xs={12}>
                      {assures?(<TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <TableRow>

                                <TableCell>Nom</TableCell>
                                <TableCell>Prénoms</TableCell>
                                <TableCell align="center">Né(e) le</TableCell>
                                <TableCell align="center">Lieu de naissance</TableCell>
                                <TableCell align="center">Lieu de residence</TableCell>
                                <TableCell align="right">Filiation</TableCell>
                                <TableCell align="right">Garanties</TableCell>
                                <TableCell align="center">Téléphone</TableCell>
                                <TableCell align="right">Email</TableCell>
                                <TableCell>N° CNI</TableCell>
                                <TableCell align="right">Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {assures.map(assure => (
                                <TableRow key={assure.nomAssure}>

                                  <TableCell component="th" scope="row">
                                    {assure.nomAssure}
                                  </TableCell>
                                  <TableCell align="right">{assure.prenomAssure}</TableCell>
                                  <TableCell align="right">{dayjs(assure.dateNaissanceAssure).format('DD/MM/YYYY')}</TableCell>
                                  <TableCell align="right">{assure.lieuNaissanceAssure}</TableCell>
                                  <TableCell align="right">{assure.lieuResidenceAssure}</TableCell>
                                  <TableCell align="right">{assure.filiationAssure}</TableCell>
                                  <TableCell align="right"><>
                                    {
                                      assure.garantiesSouscrits.length!==0 && assure.garantiesSouscrits.map((garantie)=>(<Typography key={garantie.CodeProduitGarantie}> - {garantie.MonLibelle +'\n'}</Typography> ))
                                    }
                                    {(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0) && assure.garantiesFacultatives.map((garantie)=>{
                                        if(garantie.checked===true && garantie.CodeProduitGarantie!=='SENIOR'){
                                          return(<Typography key={garantie.CodeProduitGarantie}> - {garantie.MonLibelle +'\n'}</Typography>)
                                        }else{
                                          return null
                                        }
                                      })}
                                    </>
                                    </TableCell>
                                  <TableCell align="right">{assure.telephoneAssure}</TableCell>
                                  <TableCell align="right">{assure.emailAssure}</TableCell>
                                  <TableCell component="th" scope="row">
                                    {assure.numeroCniAssure}
                                  </TableCell>
                                  <TableCell align="right"></TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                      </TableContainer>):<Typography>Aucun aucun Assuré </Typography>}
                
                    </Grid>
                </Grid>
                
              </CardContent>
              
            </Card>   
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  BENEFICIAIRE(S)
                </Typography>
                <Grid container>
                    <Grid item sm={12} xs={12}>
                      {beneficiaires?(<TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Nom</TableCell>
                                <TableCell>Prénoms</TableCell>
                                <TableCell align="center">Né(e) le</TableCell>
                                <TableCell align="center">Lieu de naissance</TableCell>
                                <TableCell align="center">Lieu de residence</TableCell>
                                <TableCell align="center">Filiation</TableCell>
                                <TableCell align="center">Téléphone</TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">Taux (%)</TableCell>
                                <TableCell align="center">Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {beneficiaires.map(beneficiaire => (
                                <TableRow key={beneficiaire.nomBeneficiaire}>
                                  <TableCell component="th" scope="row">
                                    {beneficiaire.nomBeneficiaire}
                                  </TableCell>
                                  <TableCell align="center">{beneficiaire.prenomBeneficiaire}</TableCell>
                                  <TableCell align="center">{dayjs(beneficiaire.dateNaissanceBeneficiaire).format('DD/MM/YYYY')}</TableCell>
                                  <TableCell align="center">{beneficiaire.lieuNaissanceBeneficiaire}</TableCell>
                                  <TableCell align="center">{beneficiaire.lieuResidenceBeneficiaire}</TableCell>
                                  <TableCell align="center">{beneficiaire.filiationBeneficiaire}</TableCell>
                                  <TableCell align="center">{beneficiaire.telephoneBeneficiaire}</TableCell>
                                  <TableCell align="center">{beneficiaire.emailBeneficiaire}</TableCell>
                                  <TableCell align="center">{beneficiaire.taux}</TableCell>
                                  <TableCell align="center"></TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                      </TableContainer>):<Typography>Aucun aucun bénéficiaire </Typography>}
                
                    </Grid>
                </Grid>
                
              </CardContent>
              
            </Card>
            <Card className={classes.root} variant="outlined">

              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  GARANTIES(S) & PRIMES
                </Typography>
                <Grid container>
                    <Grid item sm={12} xs={12}>
                      {assures?(<TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Nom</TableCell>
                                <TableCell>Garantie</TableCell>
                                <TableCell align="center">Capital</TableCell>
                                <TableCell align="center">Prime</TableCell>
                                <TableCell align="center">Accesoire</TableCell>
                                <TableCell align="center">Total prime</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {assures.map((assure)=>{
                              let garanties = assure.garantiesFacultatives?(assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>(item.checked===true&&item.CodeProduitGarantie!=='SENIOR')))):(assure.garantiesSouscrits)
                              let rows =null
                              
                               
                                if(garanties){
                                 return  garanties.map((garantie)=>(

                                     <TableRow key={garantie.CodeProduitGarantie}>
                                      <TableCell component="th" scope="row" align='left'>
                                        {`${assure.nomAssure}  ${assure.prenomAssure}`}
                                      </TableCell>
                                      <TableCell align="center">{garantie.MonLibelle}</TableCell>
                                      <TableCell align="center">{garantie.capitalGarantie? formatNumber(parseInt(garantie.capitalGarantie)):''}</TableCell>
                                      <TableCell align="center">{garantie.prime?formatNumber(parseInt(garantie.prime)):''}</TableCell>
                                      <TableCell align="center">{garantie.primeAccesoire?formatNumber(garantie.primeAccesoire):''}</TableCell>
                                      <TableCell align="right">{garantie.primeTotal? formatNumber(garantie.primeTotal):''}</TableCell>
                                    </TableRow>
                    
                                  ))
                                }
                               
                              })
                            }
                             <TableRow>
                               <TableCell colSpan={4} align='center'><Typography variant='h5'>TOTAL</Typography></TableCell>
                               <TableCell colSpan={2} align='right'><Typography variant='h5'>{totalPrime? formatNumber(totalPrime):''}</Typography></TableCell></TableRow>
                            </TableBody>
                          </Table>
                      </TableContainer>):<Typography>Aucun aucun garantie </Typography>}
                
                    </Grid>
                </Grid>
                
              </CardContent>
              
            </Card>
            <Divider component="hr" className={classes.hTrait}/>
            
             <Grid container direction="row" justify="space-between" alignItems="center">        
             
             <Button type="button"  onClick={props.handleBack}>
               Retour
             </Button>
             <Button className={classes.btnNext}  type="submit" onClick={handleSubmit(saveSouscription)}>
                Valider
              <NavigateNextOutlinedIcon /> 
            </Button>
         </Grid>
          </>
        ):(
          <ModalLoading myopen={loading} />
        )}
       
            
      </Fragment>
      
    )

}
const selector = formValueSelector('adForm')

const mapStateToProps = (state) => ({
  user:state.user,
  api: state.api,
  UI:state.UI,
  data:getFormValues('adForm')(state)
})  
  const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
  const mapActionsToProps = {
    updateField,
    addSouscription
  }
  SouscriptionFicheresume = withStyles(styles)(SouscriptionFicheresume)


  export default connect(mapStateToProps,mapActionsToProps)(reduxForm({form: 'adForm',destroyOnUnmount: false,})(SouscriptionFicheresume))
