import React,{useState,useEffect} from 'react'
import AddProspect from 'pages/Commercial/Prospects/AddProspect'
import ProspectList from 'pages/Commercial/Prospects/ProspectList'
function ProspectHome() {
    const [fonctionnalite, setFonctionalite] = useState('LIST')
    const handleCliqueFonction = (element) => {
        setFonctionalite(element)
    }
    useEffect(() => {
        document.title = "ENOV - Gestion des prospect"
    })
  return (
    <div>
        {fonctionnalite === 'LIST' && <ProspectList  handleCliqueFonction={handleCliqueFonction} />}
        {fonctionnalite === 'ADDPRO' && <AddProspect  handleCliqueFonction={handleCliqueFonction} />}
    </div>
  )
}

export default ProspectHome