import React,{useState,useReducer,useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import {connect,useDispatch} from 'react-redux'
import dayjs from 'dayjs'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import MaterialTable,{MTableAction} from 'material-table'
import MuiAlert from '@material-ui/lab/Alert';
import HeaderFonction from 'pages/Producteur/Propositions/components/HeaderFonction'
import { reduxForm,change} from 'redux-form';
import {formatNumber} from 'application'
import InfoSouscription from 'pages/Producteur/Propositions/components/InfoSouscription'
import {
  validationPropositionDocument,validationPropositionDocumentIndiv,getProducteurData,updateProposition
} from 'redux/actions/apiActions'
import Snackbar from '@material-ui/core/Snackbar';
import ImportDocumentList from 'pages/Producteur/Propositions/components/ImportDocumentList'
import OptionMenu from './components/OptionMenu'
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import XLSX from 'xlsx';
import Button from '@material-ui/core/Button';
import {getReseauxNoDispatch,getEquipesReseau,getEquipesZones,getZonesReseau,getZonesNoDispatch,getEquipesNoDispatch} from 'redux/actions/settingsAction'
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonSearch:{
    borderWidth:1,
    borderStyle:'dashed',
    borderColor:theme.palette.primary.main,
    backgroundColor:theme.palette.primary.main,
   
    color:'#FFF',
    '&:hover':{
      backgroundColor:theme.palette.secondary.main,
      borderColor:theme.palette.secondary.main,
    }
  }
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function PropositionDetails(props){
  const classes = useStyles();
  const dispatch =useDispatch()
  const {api:{bordereauxValidation},worker:{currentDetails}}=props
    const [fonctionnalite,setFonctionalite]=useState('HOME')
    const handleCliqueFonction = element=>{
        setFonctionalite(element)
    }
    const [myErrors,setMyErrors]= useState(null)
    const [openAlert, setOpenAlert] = React.useState(false);
    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenAlert(false);
      setMyErrors(null)
    }; 

    const [ficheData,setFicheData] = React.useState([])
   
    const {mode,titre}=props.location.state
    const handleShowFiche =(rowData)=>{
      setFicheData(rowData)
      handleShow()
    }
    
    const [show,setShow] =React.useState(false)
    const handleShow=()=>{
      setShow(true)
    }
    const handleUnShow =()=>{
      setFicheData([])
      setShow(false)
    }
    const handleUploadDoc =(data)=>{
      if(data){
        validationPropositionDocument({refs:[data.id]}).then(docRes=>{
          if(docRes){
            setMyErrors({type:'success',message: "Document téléchargé avec succès"})
            setOpenAlert(true)
          }else{
            console.log(docRes);
            setMyErrors({type:'error',message: "Erreur lors du téléchargement"})
            setOpenAlert(true)
          }
        }).catch(err=>{
          console.log(err);
          setMyErrors({type:'error',message: "Erreur lors du téléchargement"})
          setOpenAlert(true)
        })
      }
    }

    const handleUploadDocindiv =(data,refDoc)=>{
      if(data && refDoc){
        validationPropositionDocumentIndiv({refs:[data.id]},refDoc).then(docRes=>{
          if(docRes){
            setMyErrors({type:'success',message: "Document téléchargé avec succès"})
            setOpenAlert(true)
          }else{
            setMyErrors({type:'error',message: "Erreur lors du téléchargement"})
            setOpenAlert(true)
          }
        }).catch(err=>{
          console.log(err);
          setMyErrors({type:'error',message: "Erreur lors du téléchargement"})
          setOpenAlert(true)
        })
      }
    }
    const [docData,setDocData] =useState(null)
    const [dlopen, setDlopen] = useState(false)
    const handleDlopen =(data)=>{
      setDocData(data)
      setDlopen(true)
    }
    const handleDlClose =()=>{
      setDlopen(false)
    }

    const [filterField,setFilterField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
        partenaire:0,
        agence:0
    });
    const handleFilterChange =(event)=>{
      const {name,value} = event.target
      setFilterField({
          [name]:value
      })
    }
    const [partenaireList,setPartenaireList] =useState([])
    const handlePartenaireList =()=>{
      getReseauxNoDispatch().then(res=>{
        if(res){
          setPartenaireList(res)
        }
      })
    }
    const [zoneList,setZoneList] = useState([])
   
    const [agenceList,setAgenceList] =useState([])
    const handleAgenceList =()=>{
      if(filterField.partenaire && filterField.partenaire!==''){
        getEquipesReseau(filterField.partenaire).then(res=>{
          if(res){
            setAgenceList(res)
          }
        })
      }else if(filterField.zone && filterField.zone!==''){
        getEquipesZones(filterField.zone).then(res=>{
          if(res){
            setAgenceList(res)
          }
        })
      }else{
        getEquipesNoDispatch().then(res=>{
          if(res){
            setAgenceList(res)
          }
        })
      }
    }
    useEffect(() => {
      handlePartenaireList()
    }, [])
    useEffect(() => {
      handleAgenceList()
    }, [filterField.zone,filterField.partenaire])

    const handleFilterData =()=>{
      const dataSend = filterField
      getProducteurData(dataSend).then(resData=>{
        if(resData){
            const {propoEnAttenteDetails,propoAccepterNMDetails,propoAccepterMDetails,propoRejeterDetails} = resData.dataProduction
            dispatch({type:"SET_PRODUCTEUR_DATA",payload:resData.dataProduction})
            switch (mode) {
              case 'ENATTENTE':
                dispatch({type:"SET_PROD_CURRENT_DETAIL",payload:propoEnAttenteDetails})
                break;
              case 'ACCEPTERNM':
                dispatch({type:"SET_PROD_CURRENT_DETAIL",payload:propoAccepterNMDetails})
                break;
              case 'ACCEPTERM':
                dispatch({type:"SET_PROD_CURRENT_DETAIL",payload:propoAccepterMDetails})
                break;
              case 'REJETER':
                dispatch({type:"SET_PROD_CURRENT_DETAIL",payload:propoRejeterDetails})
             
                break;
              default:
                break;
            }
        }
      })
    }
   
    const annulerrejet =(data)=>{
      props.updateProposition({id:data.id,typeop:'RJ'}).then((ctr)=>{
        if(ctr){
          return ctr
        }else{
          setMyErrors({type:'error',message: "Erreur lors de l'opération"})
          setOpenAlert(true)
        }
      }).catch(err=>{
        console.log(err);
        setMyErrors({type:'error',message: "Erreur lors de l'opération"})
        setOpenAlert(true)
      }) 
    }

    const exportToExcel = (columns, data) => {
      // determining header labels
     
      const columnInfo = columns.reduce(
          (acc, column) => {
              const headerLabel = column.title || column.field;
              acc.header.push(headerLabel);
              acc.map[column.field] = headerLabel;
              return acc;
          },
          { map: {}, header: [] }
      );
      
      const mappedData = data.map(row =>
      
          Object.entries(row).reduce((acc, [key, value]) => {
           
            acc['Ref'] = `${row.id}`;
            acc['Souscripteur'] = `${row['nom']} ${row['prenom']}`;
            acc['Produit'] = `${row['libelleproduit']}`;
            acc['Date d’effet'] = `${dayjs(row.dateeffet).format('DD/MM/YYYY')}`;
            acc['Durée'] = `${row['duree']}`;
            acc['Etat'] = `${row['etape']}`;
            acc['Périodicité'] = `${getPeriodicite(row['periodicite'])}`;
            acc['Prime'] = `${(row['prime'] && row['prime']!=='null') ? row['prime']:""}`;
            acc['Capital'] = `${(row['capital'] && row['capital']!=='null') ? row['capital']:""}`;
            acc['Date de saisie'] = `${dayjs(row.saisiele).format('DD/MM/YYYY')}`;
            acc['Date de transmission'] = `${(row.transmisle && row.transmisle!=='null') ? dayjs(row.transmisle).format('DD/MM/YYYY'):""}`;
            acc['Date d’acceptation '] = `${(row.accepterle && row.accepterle!=='null') ? dayjs(row.accepterle).format('DD/MM/YYYY'):""}`;
            acc['Agence'] = `${row['nomagence']}`;
            acc['Conseiller'] = `${row['nomagent']}`;
            if(row.motifrejet && row.motifrejet!=='null'){
              acc['Motif réjet'] = `${row['motifrejet']}`;
            }
            if(row.rejeterpar && row.rejeterpar!=='null'){
              acc['Rejeter par'] = `${row['rejeterpar']}`;
            }
              return acc;
          }, {})
      );
    
      var ws = XLSX.utils.json_to_sheet(mappedData, {
          //header: columnInfo.header, // garanties the column order same as in material-table
      });
    
      /* add to workbook */
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
      /* generate an XLSX file and triggers the browser to generate the download*/
      XLSX.writeFile(wb, `LLV-liste-Proposition.xlsx`);
    };
    const getPeriodicite =(per)=>{
      switch (per) {
        case 'M':
          return 'Mensuelle'
        case 'T':
          return 'Trimestrielle'
        case 'S':
          return 'Semestrielle'
        case 'A':
          return 'Annuelle'
        case 'U':
          return 'Unique'
        default:
          return 'Inconnu'
      }
    }
    return(
       <div className='p-3'>
       {(dlopen && docData)&&<ImportDocumentList mode={mode} handleUploadDoc={handleUploadDoc} handleUploadDocindiv={handleUploadDocindiv} open={dlopen} handleOpen={handleDlopen} handleClose={handleDlClose} data={docData} /> }
       {myErrors&&(
          <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}  
        >
          <Alert onClose={handleCloseAlert} severity={myErrors.type}>
            {myErrors.message}
        </Alert>
        </Snackbar>
        )}
          {(show & ficheData.length!==0)?(
            <InfoSouscription  data={ficheData} unShow={handleUnShow} />
          ):(
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}><Typography>{titre}</Typography></GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}><HeaderFonction history={props.history} handleCliqueFonction={handleCliqueFonction}/></GridItem>
              <GridItem style={{marginTop:20,marginBottom:20}} xs={12} sm={12} md={12} lg={12}>
                <LabelledOutline label="Filtres">
                  <Paper elevation={3}>
                    <div style ={{padding:10}}>
                      <Grid container>
                        <form className={classes.container} noValidate>
                          <div style={{border:"1px solid #f3f3", padding:10}}><TextField
                            id="dateSaisieDebut"
                             name="dateSaisieDebut"
                            label="Saisie du"
                            type="date"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange ={handleFilterChange}
                            variant='outlined'
                          />
                          <TextField
                            id="dateSaisieFin"
                            name="dateSaisieFin"
                            label="Au"
                            type="date"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange ={handleFilterChange}
                            variant='outlined'
                          /></div>
                          <div style={{border:"1px solid #f3f3", padding:10}}><TextField
                            id="dateEffetDebut"
                            name="dateEffetDebut"
                            label="Effet du"
                            type="date"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange ={handleFilterChange}
                            variant='outlined'
                          />
                          <TextField
                            id="dateEffetFin"
                            name="dateEffetFin"
                            label="Au"
                            type="date"
                            onChange ={handleFilterChange}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant='outlined'
                          /></div>
                          <div style={{border:"1px solid #f3f3", padding:10}}>
                              <FormControl variant="outlined" className={classes.formControl}>
                                  <InputLabel id="demo-simple-select-outlined-label">Partenaire</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="partenaire"
                                    name="partenaire"
                                    value={filterField.partenaire}
                                    onChange ={handleFilterChange}
                                    label="Partenaire"
                                  >
                                    <MenuItem value="0">
                                      <em>Aucun</em>
                                    </MenuItem>
                                    {(partenaireList && partenaireList.length!==0)&&(partenaireList.map(part=><MenuItem key={`${part.id}`} value={part.id}>{part.libelle}</MenuItem>) )}
                                   
                                  </Select>
                                </FormControl>
                                <FormControl variant="outlined" className={classes.formControl}>
                                  <InputLabel id="demo-simple-select-outlined-label">Zone / inspection</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="zone"
                                    name="zone"
                                    value={filterField.zone}
                                    onChange ={handleFilterChange}
                                    label="Zone / Inspection"
                                  >
                                    <MenuItem value="0">
                                      <em>Aucun</em>
                                    </MenuItem>
                                    {(zoneList && zoneList.length!==0)&&(zoneList.map(zone=><MenuItem key={`${zone.id}`} value={zone.id}>{zone.libelle}</MenuItem>) )}
                                   
                                   
                                  </Select>
                                </FormControl>
                                <FormControl variant="outlined" className={classes.formControl}>
                                  <InputLabel id="demo-simple-select-outlined-label">Agence/Equipe</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="agence"
                                    name="agence"
                                    value={filterField.agence}
                                    onChange ={handleFilterChange}
                                    label="Agence/Equipe"
                                  >
                                    <MenuItem value="0">
                                      <em>Aucun</em>
                                    </MenuItem>
                                    {(agenceList && agenceList.length!==0)&&agenceList.map(ag=>(<MenuItem key={ag.id} value={`${ag.id}`}>{ag.libelleequipe}</MenuItem>))}
                                    
                                  </Select>
                                </FormControl>
                            </div>
                            <Button variant='outlined' color='primary' className={classes.buttonSearch} onClick={handleFilterData} >
                                    FILTRER
                            </Button> 
                      </form>
                        
                      </Grid>
                    </div>
                  </Paper>
                </LabelledOutline>
              </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <MaterialTable
                    columns={[
                      { title: '#', field: 'id' },
                      { title: 'Proposition', field: 'Adherent',render:rowData=>(<div className='flex flex-col'>
                          <div className='flex flex-row gap-2'>
                              <span className='italic text-gray-400'>Souscripteur:</span>
                              <span className='font-bold'>{rowData.nom + " " + rowData.prenom}</span>
                          </div>
                          <div className='flex flex-row gap-2'>
                              <span className='italic text-gray-400'>Date d'effet:</span>
                              <span className='font-bold'>{rowData.dateeffet?dayjs(rowData.dateeffet).format("DD/MM/YYYY"):""}</span>
                          </div>
                          <div className='flex flex-row gap-2'>
                              <span className='italic text-gray-400'>Produit:</span>
                              <span className='font-bold'>{rowData.libelleproduit}</span>
                            </div>

                          </div>
                          ),
                          width:'15%'
                      },
                      
                      { title: 'Tarif', field: 'prime',render:rowData=>(
                        <div className='flex flex-col'>
                          <div className='flex flex-row gap-2'>
                            <span className='italic text-gray-400'>Prime :</span><span className='font-bold'>{ parseInt(rowData.prime)?`${formatNumber(parseInt(rowData.prime))} F CFA`:""}</span>
                          </div>
                          <div className='flex flex-row gap-2'>
                            <span className='italic text-gray-400'>Durée (année):</span><span className='font-bold'>{rowData.duree}</span>
                          </div>
                          <div className='flex flex-row gap-2'>
                            <span className='italic text-gray-400'>Périodicite:</span><span className='font-bold'>{`${getPeriodicite(rowData.periodicite)}`}</span>
                          </div>
                          <div className='flex flex-row gap-2'>
                            <span className='italic text-gray-400'>Capital (F CFA):</span><span className='font-bold'>{parseInt(rowData.capital)?`${formatNumber(parseInt(rowData.capital))} F CFA`:""}</span>
                          </div>
                           
                        </div>
                      )},
                      { title: 'Etat', field: 'etape'},

                      { title: 'Date', field: 'saisiele',render:rowData=>(
                        <div className='flex flex-col'>
                             <div className='flex flex-row gap-2'>
                              <span className='italic text-gray-400'>Saisie le:</span><span className='font-bold'>{dayjs(rowData.saisiele).format('DD/MM/YYYY')}</span>
                            </div>
                            {rowData.transmiele&& <div className='flex flex-row gap-2'>
                                <span className='italic text-gray-400'>Transmis le:</span><span className='font-bold'>{(rowData.transmisle && rowData.transmisle!==null)?dayjs(rowData.transmisle).format("DD/MM/YYYY"):""}</span>
                              </div>}
                              
                              {rowData.accepterle&& <div className='flex flex-row gap-2'>
                                <span className='italic text-gray-400'>Accepter le:</span><span className='font-bold'>{(rowData.accepterle && rowData.accepterle!==null)?dayjs(rowData.accepterle).format("DD/MM/YYYY"):""}</span>
                              </div>}

                       
                        </div>
                        )
                      },
                      
                      { title: 'Conseiller', field: 'utilisateur',render:rowData=>(
                        <div className='flex flex-col'>
                            <div className='flex flex-row gap-2'>
                              <span className='italic text-gray-400'>Conseiller (ère) :</span><span className='font-bold'>{rowData.nomagent}</span>
                            </div>
                            <div className='flex flex-row gap-2'>
                              <span className='italic text-gray-400'>Agence :</span><span className='font-bold'>{rowData.nomagence}</span>
                            </div>

                        </div>
                      ) },
                      { title: 'traitement', field: 'nomaccepterpar',render:rowData=>(
                        <div className='flex flex-col'>
                           <div className='flex flex-row gap-2'>
                              <span className='italic text-gray-400'>Accepter/rejeter par:</span><span className='font-bold'>{(rowData.nomaccepterpar && rowData.nomaccepterpar!==null)?rowData.nomaccepterpar:""}</span>
                            </div>
                            <div className='flex flex-row gap-2'>
                              <span className='italic text-gray-400'>ID Proposition:</span><span className='font-bold'>{(rowData.idproposition && rowData.idproposition!==null)?rowData.idproposition:""}</span>
                            </div>
                            <div className='flex flex-row gap-2'>
                              <span className='italic text-gray-400'>Code Proposition:</span><span className='font-bold'>{(rowData.codeproposition && rowData.codeproposition!==null)?rowData.codeproposition:""}</span>
                            </div>
                            <div className='flex flex-row gap-2'>
                              <span className='italic text-gray-400'>Motif rejet :</span><span className='font-bold'>{(rowData.motifrejet && rowData.motifrejet!==null)?rowData.motifrejet:""}</span>
                            </div>
                        </div>
                      )},
                    ]}
                    data={currentDetails?currentDetails:[]}
                    title={titre}
                    localization={{
                      toolbar:{
                          searchPlaceholder:'Rechercher',
                          searchTooltip:'Rechercher',
                          exportTitle: 'Exportation',
                          exportAriaLabel: 'Exportation',
                          exportCSVName: 'Exporter en Excel',
                          exportPDFName: 'Exporter en PDF', 
                      },
                      body: {
                        emptyDataSourceMessage: 'Aucune donnée trouvée !'
                      },
                      pagination: {
                        labelRowsSelect: 'Ligne(s)',
                        labelDisplayedRows: '{count} sur {from}-{to}',
                        firstTooltip: 'Prémière page',
                        previousTooltip: 'Précédent',
                        nextTooltip: 'Suivant',
                        lastTooltip: 'Dernière page'
                      }
                    }}
                    
                    options={{
                      exportButton: true,
                      exportCsv:(columns, data)=> exportToExcel(columns, data,"LLV-Liste-Proposotion"),
                      searchFieldStyle:{
                        fontSize:18,
                        width:'100%',
                        height:50,
                      },
                      actionsColumnIndex: -1,
                      pageSize:50,
                      pageSizeOptions:[50,100,150,200,300,400,500,1000],
                      rowStyle: rowData => ({
                        backgroundColor: (bordereauxValidation.findIndex(item=>item.id===rowData.id)!==-1) ? '#EEE' : '#FFF'
                      })
                    }}
                    
                    components={{
                      Action: (props) => {
                        if (props?.action?.icon === "expand_more_icon") {
                          return (<OptionMenu mode={mode} annulerrejet={annulerrejet} data={props.data} setMyErrors={setMyErrors} setOpenAlert={setOpenAlert} />);
                        } else return <MTableAction {...props} />;
                      },
                    }}
                    actions={[
                      {
                        icon: 'visibility_icon',
                        tooltip: 'Afficher',
                        onClick: (event, rowData) =>handleShowFiche(rowData),
                      },
                      (mode==='ACCEPTERM' || mode==='REJETER' )&&{
                        icon: 'description_icon',
                        tooltip: 'Documents',
                        onClick: (event, rowData) =>handleDlopen(rowData),
                      },
                      (mode==='ACCEPTERM' || mode==='REJETER')&&{
                        icon: 'expand_more_icon',
                        tooltip: 'Plus d\'actions',
                        onClick: (event, rowData) =>handleDlopen(rowData),
                      },
                    ]}
                    
                  />
              </GridItem>
            </GridContainer>
          )}
          
        </div>
    )
}


const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapPropsActions ={
    updateField,
    updateProposition
}
const mapStateToProps = (state) => ({
    worker:state.worker,
    api: state.api,
    user:state.user
  });

  export default connect(mapStateToProps,mapPropsActions)(reduxForm({destroyOnUnmount: false,})(PropositionDetails))