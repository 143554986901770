/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import classNames from "classnames";

import { makeStyles } from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import styles from "assets/jss/llv/components/customInputStyle.js";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
const useStyles = makeStyles(styles)

const ConseillerSelect = (props)=> {
    const classes = useStyles();
    const {formControlProps,label,id,name,labelProps,inputProps,options,error,success,value,children,input,...custom} = props
    
    const labelClasses = classNames({
      [" " + classes.labelRootError]: error,
      [" " + classes.labelRootSuccess]: success && !error
    });

    const underlineClasses = classNames({
      [classes.underlineError]: error,
      [classes.underlineSuccess]: success && !error,
      [classes.underline]: true
    });
    const marginTop = classNames({
      [classes.marginTop]: label === undefined
    });
  
  return (
    <FormControl 
        {...formControlProps}
        className={formControlProps.className + " " + classes.formControl}
    >
       <TextField
          id={id}
          select
          label={label}
          value={input?input.value:value}
          onChange={input?input.onChange:props.onChange}
          SelectProps={{
            native: true,
           
          }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          name={name}
          {...custom}
        >
          <option  value=''></option>
          {options.map(option => (
            <option key={option.CodeIntermediaire} value={option.CodeIntermediaire}>
              {option.NomAgent}
            </option>
          ))}
        </TextField>
     {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}
export default ConseillerSelect
