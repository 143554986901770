import React from 'react'
import {connect} from 'react-redux'
import { useRouteMatch,Switch,Route } from 'react-router-dom'
import PretHome from "pages/Banking/Prets/PretHome"
import AddPret from 'pages/Banking/Prets/addprets'
import PretFicheresume from 'pages/Banking/Prets/components/PretFicheresume'
import PretList from 'pages/Banking/Prets/PretList'
import PretDetails from 'pages/Banking/Prets/PretDetails'

function Epret() {
    const {path} =useRouteMatch()
  return (
    <div>
      <Switch>
            <Route path={`${path}/add-pret`} component={AddPret} name='Nouvelle cotation' />
            <Route path={`${path}/pret-liste`} component={PretList} name='Gestion des demandes de cotaion' />
            <Route path={`${path}/pret-fiche-resume`} component={PretFicheresume} name='Fiche résumé'  />
            <Route path={`${path}/pret-details/:id`} component={PretDetails} name='Détail du pret'  />
            <Route path={`${path}`} component={PretHome} name='Gestion des prestation' />
      </Switch>
     </div>
  )
}

const mapActionsToProps = {}
const mapStateProps =(state)=>({
    user:state.user,
    ui: state.UI,
 })
export default connect(mapStateProps,mapActionsToProps)(Epret)  
