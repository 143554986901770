import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/llv/components/customInputStyle.js";
import MuiPhoneNumber from 'material-ui-phone-number'
const useStyles = makeStyles(styles);

export default function CustomPhoneInput(props) {
  const classes = useStyles();
  const {
    formControlProps,
    label,
    id,
    name,
    labelProps,
    inputProps,
    error,
    success,
    type,
    value,
    input,
    ...custom
  } = props;

  const  handleOnChange=(value)=> {
    if(input){
      props.input.onChange(value)
    }else{
      props.onChange(value)
    }
 }
  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.hasOwnProperty('className')? (formControlProps.className + " " + classes.formControl):classes.formControl}
    >
        <MuiPhoneNumber
           defaultCountry={'ci'} 
           onlyCountries={['ci','fr']}
           regions={['africa','europe']}
           onChange={handleOnChange}
           id={id}
           name={name}
           label={label}
           className={classes.labelClasses}
           InputLabelProps={{
               shrink: true, 
               inputProps: { max:10},  
           }}
           disableCountryCode
           placeholder='00 00 00 00 00'
           value={input?input.value:value&&value}
          {...custom}
        />  
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomPhoneInput.propTypes = {
  label: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool
};
