import { Box, CircularProgress, Divider, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import { getDetailPret } from 'redux/actions/apiActions';
import EditIcon from '@material-ui/icons/Edit';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { change, getFormValues, reduxForm } from 'redux-form';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useParams } from "react-router-dom";
import dayjs from 'dayjs';
import CustumModal from 'components/CustumModal';

const PretDetails = ({user: { credentials },ui:{layoutRoot},...resProps}) => {
  const [data,setDataPret] = useState()
  const params = useParams()
  const [loading,setLoading] = useState(false)
  const handleGetDate =()=>{
    setLoading(true)
    getDetailPret(params?.id).then(datapret=>{
      if(datapret){
        setDataPret(datapret)
        setLoading(false)
      }else{
        setLoading(false)
      }
    }).catch(err=>{
      console.log(err);
      setLoading(false)
    })
  }
  useEffect(()=>{
    handleGetDate()
  },[params])
const [openadh,setOpenadh] = useState(false)
const handleAdhClose =(e,reason)=>{
  if(reason === '')
    return
  setOpenadh(false)
}
  return (
    <div className='relative max-w-screen-md mx-auto mt-4'>
      <Paper className="mb-3 border-l-8 border-l-primary-main p-2" variant="outlined">
            <div className='flex flex-row items-center'>
                <Tooltip title="Retour à la liste"> 
                    <IconButton onClick={()=>resProps.history.push(`${layoutRoot}/epret`)} color='primary'>
                        <ArrowBackIcon style={{fontSize:40}} />
                    </IconButton>
                </Tooltip>
                <Typography variant='h6'>Détails cotation #{params?.id}</Typography>
              
            </div>
        </Paper>
      {(data && data.length !==0) ?(<>
        <Paper className='flex flex-col p-2 my-2'>
            <div className='flex flex-row justify-between items-center py-2'>
              <Typography variant='h6'>
                ADHERENT
              </Typography>
              <IconButton>
                  <EditIcon />
              </IconButton>
            </div>
            <Divider />
            <div className='flex flex-row justify-center gap-2'>
                <div className='flex flex-col items-end'>
                    <span className='flex flex-row justify-end font-bold italic'>Civilité : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Nom : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Prénom : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Né(e) le : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Né(e) à : </span>
                    <span className='flex flex-row justify-end font-bold italic'>{data&&data.adherent.naturepiece} : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Lieu de residence : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Adresse postale  : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Téléphone : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Mobile : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Adresse email : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Profession : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Secteur activités : </span>
                </div>
                <div className='flex flex-col items-start'>
                    <span>{data&&(data.adherent.civilite || "_")}</span>
                    <span>{data&&(data.adherent.nom || "_")}</span>
                    <span>{data&&(data.adherent.prenom || "_")}</span>
                    <span>{data&&dayjs(data.adherent.datenaissance).format('DD/MM/YYYY')}</span>
                    <span>{data&&(data.adherent.lieunaissance || "_")}</span>
                    <span>{data&&data.adherent.numeropiece}</span>
                    <span>{data&&data.adherent.lieuresidence}</span>
                    <span>{data&&(data.adherent.bp || "_")}</span>
                    <span>{data&&(data.adherent.telephone || "_")}</span>
                    <span>{data&&(data.adherent.mobile1 || "")}{data&&(data.adherent.mobile2 || "-")}</span>
                    <span>{data&&(data.adherent.email || "_")}</span>
                    <span>{data&&(data.adherent.profession || "_")}</span>
                    <span>{data&&(data.adherent.employeur || "_")}</span>
                </div>
            </div>
           
        </Paper>
        <Paper className='flex flex-col p-2 my-2'>
            <div className='flex flex-row justify-between items-center py-2'>
              <Typography variant='h6'>
                DETAILS DE LA COTATION
              </Typography>
              <IconButton>
                  <EditIcon />
              </IconButton>
            </div>
            <Divider />
            <div className='flex flex-row justify-start gap-2'>
                <div className='flex flex-col items-end'>
                    <span className='flex flex-row justify-end font-bold italic'>#Ref : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Type de prêt : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Montant (XOF) : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Durée (Mois) : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Date Cotation : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Statut : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Prime emprunteur : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Prime risque  : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Remboursement : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Mise en place : </span>
                    <span className='flex flex-row justify-end font-bold italic'>Echéeance : </span>
                    
                </div>
                <div className='flex flex-col items-start'>
                    <span>{data&&(data.pret.id || "_")}</span>
                    <span>{data&&(data.pret.typepret || "_")}</span>
                    <span>{data&&(data.pret.montantpret || "_")}</span>
                    <span>{data&&(data.pret.duree || "_")}</span>
                    <span>{data&&dayjs(data.pret.saisiele).format('DD/MM/YYYY')}</span>
                    <span>{data&&(data.pret.etat || "_")}</span>
                    <span>{data&&data.pret.primeht}</span>
                    <span>{data&&data.pret.primerisque}</span>
                    <span>{data&&(data.pret.remboursement || "_")}</span>
                    <span>{data&&(dayjs(data.pret.miseenplaceeffective).format('DD/MM/YYYY') || "_")}</span>
                    <span>{data&&(dayjs(data.pret.dateecheancefin).format('DD/MM/YYYY') || "-")}</span>
                    
                </div>
            </div>
        </Paper>
        <Paper className='flex flex-col p-2'>
            <div className='flex flex-row justify-between items-center py-2'>
              <Typography variant='h6'>
                ETAT DE SANTE
              </Typography>
              <IconButton>
                  <EditIcon />
              </IconButton>
            </div>
            <Divider />
            <div>
          
                  <div className='flex flex-row gap-2'>
                    <div className='flex flex-row gap-2'>
                     
                        <span className='flex flex-row justify-end font-bold italic'>
                          Taille (cm) :
                        </span>
                        <Typography variant="subtitle1">
                          {`\xa0 ${(data&&data.pret.taille )&& data.pret.primerisque}`}
                        </Typography>
                    </div>
                    <div className='flex flex-row gap-2'>
                        <span className='flex flex-row justify-end font-bold italic'>
                          Poids(Kg) :
                        </span>
                        <Typography variant="subtitle1">
                          {`\xa0 ${(data&&data.pret.poids )&& data.pret.poids}`}
                        </Typography>
                     
                    </div>
                  </div>

                  <div className='flex flex-row gap-2'>
                    <div className='flex flex-row gap-2'>
              
                        <span className='flex flex-row justify-end font-bold italic'>
                          Fumeur ?
                        </span>
                        <Typography variant="subtitle1">
                        {`\xa0 ${(data&&data.pret.fumezvous )&& data.pret.fumezvous}`}
                        </Typography>
                     
                    </div>
                    {(data &&data.pret.fumezvous && data.pret.fumezvous === 'Oui') && (
                      <div className='flex flex-row gap-2'>
                          <span className='flex flex-row justify-end font-bold italic'>  
                            Nombre cirgarette/jour ?
                          </span>
                          <Typography variant="subtitle1">
                            {`\xa0 ${(data&&data.pret.nbCigaretteJour )&& data.pret.nbCigaretteJour}`}
                          </Typography>
                      </div>
                    )}
                  </div>
                  <div className='flex flex-row gap-2'>
                    <div className='flex flex-row gap-2'>
                        <span className='flex flex-row justify-end font-bold italic'>
                          Buvez vous l'alcool ?
                        </span>
                        <Typography variant="subtitle1">
                          {(data && data.pret.buvezVous) && (`\xa0 ${data.pret.buvezVous && (data.pret.buvezVous==="Non"?"Pas du tout":(data.pret.buvezVous==="Partiel"?"A l'ocassion":"Régulièrement (au moins une fois par semaine)"))}`)}
                        </Typography>
                    </div>
                    <div className='flex flex-row gap-2'>
                        <span className='flex flex-row justify-end font-bold italic'>
                          Vos distractions :
                        </span>
                        <Typography variant="subtitle1">
                          {`\xa0 ${(data&&data.pret.distraction )&& data.pret.distraction}`}
                        </Typography>
                    </div>
                  </div>

                  <div className='flex flex-row gap-2'>
                    <div className='flex flex-row gap-2'>
                        <span className='flex flex-row justify-end font-bold italic'>
                          Etes-vous atteint d'une infirmité ?
                        </span>
                        <Typography variant="subtitle1">
                          {`\xa0 ${(data&&data.pret.estinfirme )&& data.pret.estinfirme}`}
                        </Typography>
                    
                    </div>
      
                    {(data&&data.pret.estinfirme && data.pret.estinfirme === 'Oui') && (
                       <div className='flex flex-row gap-2'>
                          <span className='flex flex-row justify-end font-bold italic'>
                            Nature infirmité
                          </span>
                          <Typography variant="subtitle1">
                            {`\xa0 ${(data&&data.pret.natureinfirmite )&& data.pret.natureinfirmite}`}
                          </Typography>
                      
                      </div>
                    )}
                  </div>
                  <div className='flex flex-row gap-2'>
                    <div className='flex flex-row gap-2'>
                        <span className='flex flex-row justify-end font-bold italic'>
                          Etes-vous en arrêt de travail?
                        </span>
                        <Typography variant="subtitle1">
                          {`\xa0 ${(data&&data.pret.estenarrettravail)&& data.pret.estenarrettravail}`}
                        </Typography>
                     
                    </div>
                    {(data&&data.pret.estenarrettravail && data.pret.estenarrettravail === 'Oui') && (
                      <>
                        <div className='flex flex-row gap-2'>
                          <div className='flex flex-row gap-2'>
                            <span className='flex flex-row justify-end font-bold italic'>
                              Date d'arrêt
                            </span>
                            <Typography variant="subtitle1">
                             
                              {`\xa0 ${(data&&data.pret.datearrettravail)&& dayjs(data.pret.datearrettravail).add(data.pret.duree, 'year').format('DD/MM/YYYY')}`}
                            </Typography>
                        </div>
                        <div className='flex flex-row gap-2'>
                            <span className='flex flex-row justify-end font-bold italic'>
                              Motif de l'arrêt
                            </span>
                            <Typography variant="subtitle1">
                              {`\xa0 ${(data&&data.pret.motifarret)&& data.pret.motifarret}`}
                            </Typography>
                        </div>
                        <div className='flex flex-row gap-2'>
                            <span className='flex flex-row justify-end font-bold italic'>
                              Date réprise prévu
                            </span>
                            <Typography variant="subtitle1">
                            {`\xa0 ${(data&&data.pret.datereprisetravail)&& dayjs(data.pret.datereprisetravail).add(data.pret.duree, 'year').format('DD/MM/YYYY')}`}
                            
                            </Typography>
                        </div>
                        <div className='flex flex-row gap-2'>
                            <span className='flex flex-row justify-end font-bold italic'>
                              Cause de l'arrêt
                            </span>
                            <Typography variant="subtitle1">
                              {`\xa0 ${(data&&data.pret.causearrettravail)&& data.pret.causearrettravail}`}
                            </Typography>
                         
                        </div>
                        <div className='flex flex-row gap-2'>
                            <span className='flex flex-row justify-end font-bold italic'>
                              Date de la cause
                            </span>
                            <Typography variant="subtitle1">
                              {`\xa0 ${(data&&data.pret.datecausetravail)&& dayjs(data.pret.datecausetravail).add(data.pret.duree, 'year').format('DD/MM/YYYY')}`}
                            </Typography>
                        </div>
                        </div>
                      </>
                    )}
                  </div>
                  <Divider />
                  {(data&&data.questionnaire) && (
                    <Table  aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">#</TableCell>
                          <TableCell align="center">Questions</TableCell>
                          <TableCell align="center">Reponse</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.questionnaire.map((question,index) => (
                          <TableRow key={question.id}>
                            <TableCell align="center" component="td" scope="row">{index+1}</TableCell>
                            <TableCell align="left" component="td" scope="row">{question.nature}</TableCell>
                            <TableCell align="left" component="td" scope="row">{question.reponse}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
              
            </div>
        </Paper>
        <Paper className='flex flex-col p-2'>
            <div className='flex flex-row justify-between items-center py-2'>
              <Typography variant='h5'>
                GARANTIE YAKO
              </Typography>
              <IconButton>
                  <EditIcon />
              </IconButton>
            </div>
            <Divider />
            <div></div>
        </Paper>
      </>) :(
        <Paper>
          <Typography> Aucun résultat</Typography>
          <IconButton>
            <RotateLeftIcon />
          </IconButton>
        </Paper>
      )}
      
      {loading&&<div className='absolute top-0 bottom-0 right-0 flex justify-center items-center'>
        <CircularProgress />
      </div>}

{/*<CustumModal open={openadh} handleClose ={handleAdhClose} title={`Mise à jour de l'adhérent ${adherent?.id}`} data={adherent || {}}>

      </CustumModal>*/}
    </div>
  )
}



const updateField = (field, data) => (dispatch) => dispatch(change('addPret', field, data))
const mapPropsActions = {
    updateField,
}

const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    ui: state.UI,
    data: getFormValues('addPret')(state),
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({form:'addPret', destroyOnUnmount:false})(PretDetails))