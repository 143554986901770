import React ,{useState,useReducer,useEffect} from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import withWidth from '@material-ui/core/withWidth';
import { Typography,Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {useSelector,connect} from 'react-redux';
import {formValueSelector} from 'redux-form'
import {getFiliations} from 'redux/actions/apiActions'
import CustomInput from 'components/CustomInput/CustomInput'
import CustomRadio from 'components/CustomRadio/CustomRadio'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import CustomDate from 'components/CustomDate/CustomDate'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { Formik, Form } from "formik";
import * as yup from "yup";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  rootList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start'
  },
  rootTabs: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
  

function ProfilModalForm(props) {
  const {open,handleClose,handleAddAssure,mesAssures} = props

  const classes = useStyles();
    //get value off villes
    let  mesVilles = useSelector(state=>state.api.villes)
    if(mesVilles.length===0){
      mesVilles=JSON.parse(localStorage.APIVilles);
    }
    //get filaiation value
    const [filiations, setFiliations] = useState(null)
    useEffect(() => {
      getFiliations().then((fil)=>{
        setFiliations(fil);
      })
    }, [])
    
    
    let assureSchema = yup.object().shape({
      nomAssure: yup.string().required("nom assuré(e) ne doit pas être vide").min(2, "Nom souscripteur trop court"),
      prenomAssure: yup.string().required("Prénom assuré(e) ne doit pas être vide").min(2, "Prénom souscripteur trop court"),
      numeroCniAssure: yup.string().required("N° de pièce obligatoire").min(2, "N° de picèce  trop court"),
      naturePiece: yup.string().required("Nature de pièce obligatoire").min(2, "N° de picèce  trop court"),
      emailAssure: yup.string()
      .email("Adresse email incorrect")
      .required("Adresse email obligatoire"),
      dateNaissanceAssure: yup.date().required("Date de naissance obligatoire")
      .min(moment('01-01-1920','DD-MM-YYYY'),'La date de naissance doit être inféririeure au 01/01/1920'),
      telephoneAssure:yup.string().required("Numéro de téléphone obligatoire")
    });

    const submit=(values)=>{
      let dataAssure =JSON.stringify(values,null,2);
      dataAssure = JSON.parse(dataAssure)
      handleAddAssure(dataAssure)
    }
    return (
      <Formik
        initialValues={{
        numeroCniAssure:'',
        nomAssure: '', 
        prenomAssure: '',
        dateNaissanceAssure:'',
        lieuNaissanceAssure:'',
        lieuResidenceAssure:'',
        filiationAssure:'',
        telephoneAssure:'',
        emailAssure:'',
        monIndex:0}}
        validationSchema={assureSchema}
       onSubmit={(values)=>submit(values)}
      >
         {({errors,handleReset,handleSubmit,isValid,dirty,isSubmitting,setFieldValue,handleChange, touched,values})=>(
          <Form>
            <Dialog fullWidth maxWidth={props.width} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Ajouter un assuré</DialogTitle>
                <Divider />
               
                <DialogContent> 
                
                    <GridContainer>
                      <GridItem xs={12} sm={12} md ={12} lg={12}>
                        <GridContainer>
                          <GridItem xs={12} sm={4} md ={4} lg={4}>
                            <CustomInput
                                required
                                label="Numéro pièce d'indentité"
                                id="numeroCniAssure"
                                name="numeroCniAssure"
                                variant="outlined"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                value={values.numeroCniAssure}
                                onChange={handleChange}
                                autoFocus
                                error={errors.numeroCniAssure && touched.numeroCniAssure}
                                helperText={
                                errors.numeroCniAssure && touched.numeroCniAssure
                                    ? errors.numeroCniAssure
                                    : null
                                }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={8} lg={8}>
                            <CustomRadio 
                              label="Nature de la pièce"
                              id="naturePiece"
                              name="naturePiece"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              value={values.naturePiece}
                              row
                              onChange={handleChange}
                              autoFocus
                              options={[{"label":"CNI","value":"CNI"},{"label":"Attestation","value":"AT"},{"label":"Passport","value":"Passport"}]}  
                              error={errors.naturePiece && touched.naturePiece}
                              helperText={
                                errors.naturePiece && touched.naturePiece
                                    ? errors.naturePiece
                                    : null
                                }
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={5}>
                                <CustomInput
                                    required
                                    label="Nom assuré"
                                    id="nomAssure"
                                    name="nomAssure"
                                    variant="outlined"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    value={values.nomAssure}
                                    onChange={handleChange}
                                    autoFocus
                                    error={errors.nomAssure && touched.nomAssure}
                                    helperText={
                                    errors.nomAssure && touched.nomAssure
                                        ? errors.nomAssure
                                        : null
                                    }
                                />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={7}>
                                <CustomInput
                                    required
                                    label="Prénom assuré"
                                    id="prenomAssure"
                                    name="prenomAssure"
                                    variant="outlined"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    value={values.prenomAssure}
                                    onChange={handleChange}
                                    autoFocus
                                    error={errors.prenomAssure && touched.prenomAssure}
                                    helperText={
                                    errors.prenomAssure && touched.prenomAssure
                                        ? errors.prenomAssure
                                        : null
                                    }
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={5}>
                              <CustomDate
                                  required
                                  label="Date de naissance"
                                  id="dateNaissanceAssure"
                                  name="dateNaissanceAssure"
                                  formControlProps={{
                                      fullWidth: true,
                                  }}
                                  InputProps={{
                                      type:'date',
                                      value:values.dateNaissanceAssure
                                  }}
                                  onChange={handleChange}
                                  autoFocus
                                  error={errors.dateNaissanceAssure && touched.dateNaissanceAssure}
                                    helperText={
                                      errors.dateNaissanceAssure && touched.dateNaissanceAssure
                                        ? errors.dateNaissanceAssure
                                        : null
                                    }
                              />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={7}>
                              <CustomSelect
                                  label="Lieu de naissance"
                                  id="lieuNaissanceAssure"
                                  name="lieuNaissanceAssure"
                                  formControlProps={{
                                      fullWidth: true
                                  }}
                                  value={values.lieuNaissanceAssure.MonLibelle}
                                  options={mesVilles}
                                  onChange={handleChange}
                                  error={errors.lieuNaissanceAssure && touched.lieuNaissanceAssure}
                                  helperText={
                                      errors.lieuNaissanceAssure && touched.lieuNaissanceAssure
                                        ? errors.lieuNaissanceAssure
                                        : null
                                    }
                              />
                          </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem  xs={12} sm={6} md ={6} lg={6}>
                          <CustomSelect
                                  label="Lieu de résidence"
                                  id="lieuResidenceAssure"
                                  name="lieuResidenceAssure"
                                  formControlProps={{
                                      fullWidth: true
                                  }}
                                  value={values.lieuResidenceAssure.MonLibelle}
                                  options={mesVilles}
                                  onChange={handleChange}
                                  error={errors.lieuResidenceAssure && touched.lieuResidenceAssure}
                                  helperText={
                                      errors.lieuResidenceAssure && touched.lieuResidenceAssure
                                      ? errors.lieuResidenceAssure
                                      : null
                                  }
                            />
                        </GridItem>
                        <GridItem  xs={12} sm={6} md ={6} lg={6}>
                          <CustomSelect
                              label="Lien de parenté"
                              id="filiationAssure"
                              name="filiationAssure"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              value={values.filiationAssure.MonLibelle}
                              options={filiations}
                              onChange={handleChange}
                              error={errors.filiationAssure && touched.filiationAssure}
                              helperText={
                                  errors.filiationAssure && touched.filiationAssure
                                  ? errors.filiationAssure
                                  : null
                              }
                            />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem  xs={12} sm={4} md ={4} lg={4}>
                          <CustomInput
                              required
                              label="Téléphone"
                              id="telephoneAssure"
                              name="telephoneAssure"
                              variant="outlined"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              value={values.telephoneAssure}
                              onChange={handleChange}
                              autoFocus
                              error={errors.telephoneAssure && touched.telephoneAssure}
                              helperText={
                                errors.telephoneAssure && touched.telephoneAssure
                                    ? errors.telephoneAssure
                                    : null
                              }
                            />
                        </GridItem>
                        <GridItem  xs={12} sm={8} md ={8} lg={8}>
                          <CustomInput
                              required
                              label="Addresse email"
                              id="emailAssure"
                              name="emailAssure"
                              type="email"
                              variant="outlined"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              value={values.emailAssure}
                              onChange={handleChange}
                              autoFocus
                              error={errors.emailAssure && touched.emailAssure}
                              helperText={
                              errors.emailAssure && touched.emailAssure
                                  ? errors.emailAssure
                                  : null
                              }
                            />
                        </GridItem>
                      </GridContainer>
                      </GridItem>         
                    </GridContainer>
                 
                          
              </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Annuler
            </Button>
            <Button onClick={handleSubmit} color="primary" variant="contained"  disabled={!(isValid && dirty)}>
              Ajouter
            </Button>
          </DialogActions>
          
        </Dialog>
        </Form>)}
        </Formik>
    );
  }

  const selector = formValueSelector('adForm')
  const mapStateToProps = (state) => ({
    api: state.api,
    myValues:selector(state, 'produit') 
  });
  
  const mapActionsToProps = {
   
  };
  ProfilModalForm = withWidth()(ProfilModalForm)
  export default connect(mapStateToProps,mapActionsToProps)(ProfilModalForm)