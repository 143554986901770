import React, { Fragment,useEffect,useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field, reduxForm,getFormValues,change,registerField,reset } from 'redux-form'
import { connect,useDispatch } from 'react-redux';
import { Grid,Box } from '@material-ui/core'
import DocumentCard from './DocumentCard'
import Button from "components/CustomButtons/Button.js";
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import Divider from '@material-ui/core/Divider';
import {ageAssure} from 'redux/actions/userActions'
import {getPrimeGarantie,getPrimeAccessoireProduit,getCapitalReference,getPrimeReference} from 'redux/actions/apiActions'
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import ModalLoading from 'components/ModalLoading'
const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor:theme.palette.primary.main,
      borderRadius:50,
      border: 1,
      color: 'white',
      height: 48,
      padding: '0 30px',
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      marginBottom:20,
      '&:hover':{
        backgroundColor:theme.palette.secondary.main,
        boxShadow: '0 3px 5px 2px rgba(6, 6, 6, .3)',
      }
    },
  
      table: {
          minWidth: 650,
        },
        btnAdd:{
          padding:20,
          marginBottom:10,
            '.MuiButton-outlined':{
                root:{
                  focused: {
                    border: '1px solid #4A90E2'
                  },
                }
            }
        },
  
        btnNext:{
          backgroundColor:theme.palette.primary.main,
          height:40,
          width:150,
          borderRadius:20,
          color:theme.palette.primary.contrastText
        },
        assureInfoRow:{
          display:'flex',
          flexDirection:'row'
        },
        popover: {
          pointerEvents: 'none',
        },
        paper: {
          padding: theme.spacing(1),
        },
  }))
function DocumentSouscription(props) {
    const {handleSubmit, pristine, reset, submitting,data,svdata} = props
    const classes =useStyles();
    const [myErrors,setMyErrors]= useState(null)
    const [openAlert, setOpenAlert] = React.useState(false);
    const [loading,setLoading] = React.useState(false)
    const handleOpenAlert = () => {
        setOpenAlert(true);
      };
      const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenAlert(false);
        setMyErrors(null)
      };
     
      const handleSaveDoc=()=>{
        if(svdata){
          
        }else{
          
        }
      }
    const evaluationContrat = async (values) =>{
        setLoading(true)
        const {produit,assures,periodicite,duree,capitalSouscrit,dateEffet,primePrincipale}=values
        let garantieCalcul =[]
        let mesGaranties = []
        let totalPrime =0
        let surPrime =0
        let primeAss =0
        if(assures && assures.length===0){
          setLoading(false)
          setMyErrors({type:'warning',message:'Aucun assuré fourni'})
          handleOpenAlert()
          return false
        }
        if(!periodicite || periodicite==='') {
          setLoading(false)
          setMyErrors({type:'warning',message:'Veuillez renseigner la périodicité'})
          handleOpenAlert()
          return false
        }
        if(!duree || duree==='') {
          setLoading(false)
          setMyErrors({type:'warning',message:'Veuillez renseigner la durée'})
          handleOpenAlert()
          return false
        }
        
  
          switch (produit.CodeProduit) {
            case 'YKE_2008': case 'YKS_2008': case 'YKF_2008': case 'YKL_2004': case 'YKV_2004': case 'YKF_2004':
                //traitement des produit YAKO
               // assures.garantiesSouscrits =garantieCalcul
                if(!capitalSouscrit || capitalSouscrit==='') {
                  setLoading(false)
                  setMyErrors({type:'warning',message:'Veuillez renseigner le capital'})
                  handleOpenAlert()
                  return false
                }   
             
                        
                await Promise.all(assures.map(async (assure)=>{
                  //création des paramètres de selection de la prime 
                  const setParams = maGarantie=>{return new Promise((resolve,reject)=>{
                      const capitalRef = (maGarantie.estobligatoire===1)?capitalSouscrit:props.data[`capital-${maGarantie.CodeProduitGarantie}`] 
                      resolve({
                          CodeProduit:produit.CodeProduit,
                          codePeriodicite:periodicite,
                          duree:duree,
                          capitalSouscrit:capitalSouscrit,
                          dateEffet:dateEffet,
                          age:ageAssure(assure.dateNaissanceAssure),
                          codeGarantie:maGarantie.CodeProduitGarantie
                      })
                  })}
  
                // création paramètre de selection de la prime de référence par produit
                const setParamsRef = maGarantie=>{return new Promise((resolve,reject)=>{
                  const capitalRef2 = (maGarantie.estobligatoire===1)?capitalSouscrit:props.data[`capital-${maGarantie.CodeProduitGarantie}`] 
                  resolve({
                      CodeProduit:produit.CodeProduit,
                      periodicite:periodicite,
                      duree:duree,
                      primePrincipale:primePrincipale,
                      capitalgarantie:parseInt(capitalRef2),
                      age:ageAssure(assure.dateNaissanceAssure),
                      codeGarantie:maGarantie.CodeProduitGarantie
                  })
                })}
                  if(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0){
                    garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>item.checked===true&&item.CodeProduitGarantie!=='SENIOR'))
                  }else{
                    garantieCalcul = assure.garantiesSouscrits
                  }
                  
                  return await Promise.all(garantieCalcul.map(async (garantie) => {
                      return getPrimeGarantie(await setParams(garantie)).then(async(data)=>{
                        if(data){
                          if(garantie.estprincipal===1){
                            primeAss = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)
                            primeAss = (primeAss && primeAss[0])? primeAss[0].montant :0   
                          } else{
                            primeAss=0
                          }                        
                          garantie.prime = data[0].Prime
                          garantie.capitalGarantie = data[0].Capital
                          garantie.primeAccesoire =  primeAss
                          garantie.primeTotal = (parseInt(data[0].Prime)+parseInt(primeAss))
                          totalPrime+=(parseInt(data[0].Prime)+parseInt(primeAss))
                          mesGaranties.push(garantie)
                        }else{
                          const capitalRef3 = (garantie.estobligatoire===1)?capitalSouscrit:props.data[`capital-${garantie.CodeProduitGarantie}`] 
                          const autreGarantie = await getPrimeReference({
                            CodeProduit:produit.CodeProduit,
                            periodicite:periodicite,
                            duree:duree,
                            capitalgarantie:parseInt(capitalRef3),
                            age:ageAssure(assure.dateNaissanceAssure),
                            codeGarantie:garantie.CodeProduitGarantie
                        })
                        
                        if(autreGarantie && autreGarantie!==undefined && autreGarantie.length!==0 ){
                          garantie.prime = autreGarantie[0].Prime
                          garantie.capitalGarantie = autreGarantie[0].Capital
                          garantie.primeAccesoire =  0
                          garantie.primeTotal = (parseInt(autreGarantie[0].Prime))
                          totalPrime+=(parseInt(autreGarantie[0].Prime))
                          mesGaranties.push(garantie)
                        }else{
                          //affectation des prime pour la garantie senio YAKO Eternite
                           
                          if(garantie.CodeProduitGarantie ==='SENIOR' && props.data.hasOwnProperty(`prime-${garantie.CodeProduitGarantie}`)){
                           
                            garantie.prime = props.data[`prime-${garantie.CodeProduitGarantie}`]
                            garantie.capitalGarantie =""
                            garantie.primeAccesoire =  0
                            garantie.primeTotal = (parseInt(props.data[`prime-${garantie.CodeProduitGarantie}`]))    
                            totalPrime+=(parseInt(props.data[`prime-${garantie.CodeProduitGarantie}`]))
                            mesGaranties.push(garantie)
                          }
                        }
                          
                        }
                          return garantie
                      })
  
                  })).then(mesgaranties=>{
                       assure.garantiesAssures = mesgaranties
                       return assure
                  })
                })).then(async (mesAssures)=>{
                    //calcul de la primeprincipale
                    surPrime = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)
                    surPrime =(surPrime && surPrime[0])?surPrime[0].montant:0
                    if(!props.hasOwnProperty('primePrincipale')){
                      props.dispatch(registerField("adForm",'primePrincipale','Field'));
                     }
                    props.updateField('primePrincipale',totalPrime)
                    //assuré(e)s
                    if(!props.hasOwnProperty('assures')){
                      props.dispatch(registerField("adForm",'assures','Field'));
                     }
                    props.updateField('assures',mesAssures)
                   //surprime
                   if(!props.hasOwnProperty('surPrime')){
                    props.dispatch(registerField("adForm",'surPrime','Field'));
                   }
                   props.updateField('surPrime',surPrime)
                   //totalPrime
                   if(!props.hasOwnProperty('totalPrime')){
                    props.dispatch(registerField("adForm",'totalPrime','Field'));
                   }
                   props.updateField('totalPrime',(totalPrime))
                   props.onSubmit()
                   setLoading(false)
                })
              break;
            case 'CADENCE':
              
              let partPrime
              let nbGarantieObligatoire
              if(!primePrincipale || primePrincipale==='') {
                setLoading(false)
                setMyErrors({type:'warning',message:'Veuillez renseigner la prime principale'})
                handleOpenAlert()
                return false
              }
              
             
              //get capital référence
              
              await Promise.all(assures.map(async (assure)=>{
                //récupération de la garantie
                const setParamsCapital ={
                  CodeProduit:produit.CodeProduit,
                  periodicite:periodicite,
                  duree:duree,
                  primePrincipale:primePrincipale,
                  age:ageAssure(assure.dateNaissanceAssure)
                }
                getCapitalReference(setParamsCapital).then(data=>{
                  
                  if(!props.hasOwnProperty('capitalSouscrit')){
                    props.dispatch(registerField("adForm",'capitalSouscrit','Field'));
                  }
                  props.updateField('capitalSouscrit',data.capital3)
                  if(!props.hasOwnProperty('capitalSouscrit5')){
                    props.dispatch(registerField("adForm",'capitalSouscrit5','Field'));
                  }
                  props.updateField('capitalSouscrit',data.capital5)
  
                  if(!props.hasOwnProperty('cumulCotisation')){
                    props.dispatch(registerField("adForm",'cumulCotisation','Field'));
                  }
                  props.updateField('cumulCotisation',data.cumulcotisation)
                })
  
                const setParams = maGarantie=>{return new Promise((resolve,reject)=>{
                    resolve({
                        CodeProduit:produit.CodeProduit,
                        codePeriodicite:periodicite,
                        duree:duree,
                        dateEffet:dateEffet,
                        capitalSouscrit:props.data[`capital-${maGarantie.CodeProduitGarantie}`],
                        age:ageAssure(assure.dateNaissanceAssure ),
                        codeGarantie:maGarantie.CodeProduitGarantie
                    })
                })}
                
                if(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0){
                  garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>item.checked===true&&item.CodeProduitGarantie!=='SENIOR'))
                }else{
                  garantieCalcul = assure.garantiesSouscrits
                }
                
                nbGarantieObligatoire= garantieCalcul.filter(item=>item.estobligatoire===1).length
                partPrime =(parseInt(primePrincipale)/nbGarantieObligatoire)
                return await Promise.all(garantieCalcul.map(async (garantie) => {
                  if(garantie.CodeProduitGarantie==='SUR'){
                    return getPrimeGarantie(await setParams(garantie)).then(async (data)=>{
                      if(data){
                        garantie.prime = data[0].Prime
                        garantie.primeAccesoire =  0
                        garantie.primeTotal = parseInt(data[0].Prime)
                        garantie.capitalGarantie = props.data[`capital-${garantie.CodeProduitGarantie}`]
  
                        totalPrime+=parseInt(data[0].Prime)
                        mesGaranties.push(garantie)
                        if(!props.hasOwnProperty('capitalSouscrit')){
                          props.dispatch(registerField("adForm",'capitalSouscrit','Field'));
                        }
                        props.updateField('capitalSouscrit',props.data[`capital-${garantie.CodeProduitGarantie}`])
                      }
                      return garantie
                    })
                  }else if(garantie.CodeProduitGarantie==='DECESACC'){
                    const maprimeDecess =   parseInt(primePrincipale)-(parseInt(primePrincipale)*0.95)
                    garantie.prime =maprimeDecess
                    garantie.primeAccesoire =  0
                    garantie.primeTotal =maprimeDecess
                    totalPrime+=maprimeDecess
                    mesGaranties.push(garantie)
                    return garantie
                  }else{
                    if(garantie.estprincipal===1){
                      primeAss =await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)   
                      primeAss = (primeAss && primeAss.length!==0)? primeAss[0].montant :0
                    } else{
                      primeAss=0
                    }
                    garantie.prime = partPrime
                    garantie.primeAccesoire =  primeAss
                    garantie.primeTotal = (parseInt(partPrime)+parseInt(primeAss))
  
                    totalPrime+=parseInt(partPrime)+primeAss
                    mesGaranties.push(garantie)
                    return garantie
                  } 
                })).then(mesgaranties=>{
                     assure.garantiesAssures = mesgaranties
                     return assure
                })
  
              })).then(async (mesAssures)=>{
                  //calcul de la primeprincipale
                  surPrime = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)
                  surPrime =surPrime?surPrime[0].montant:0 
                  //assuré(e)s
                  if(!props.hasOwnProperty('assures')){
                    props.dispatch(registerField("adForm",'assures','Field'));
                   }
                  props.updateField('assures',mesAssures)
                 //surprime
                 if(!props.hasOwnProperty('surPrime')){
                  props.dispatch(registerField("adForm",'surPrime','Field'));
                 }
                 props.updateField('surPrime',surPrime)
                 //totalPrime
                 if(!props.hasOwnProperty('totalPrime')){
                  props.dispatch(registerField("adForm",'totalPrime','Field'));
                 }
                 setLoading(false)
                 props.updateField('totalPrime',(totalPrime+surPrime))
                 props.onSubmit()
              })
              break;
            case 'PFA_IND': case 'PFA_COL': case 'PFA_BNI':
            
              if(!primePrincipale || primePrincipale==='') {
                setLoading(false)
                setMyErrors({type:'warning',message:'Veuillez renseigner la prime principale'})
                handleOpenAlert()
                return false
              }

              await Promise.all(assures.map(async (assure)=>{
                
                const setParams = maGarantie=>{return new Promise((resolve,reject)=>{
                    resolve({
                        CodeProduit:produit.CodeProduit,
                        codePeriodicite:periodicite,
                        duree:duree,
                        dateEffet:dateEffet,
                        capitalSouscrit:props.data[`capital-${maGarantie.CodeProduitGarantie}`],
                        age:ageAssure(assure.dateNaissanceAssure ),
                        codeGarantie:maGarantie.CodeProduitGarantie
                    })
                })}
                
                 
                if(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0){
                  garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>item.checked===true&&item.CodeProduitGarantie!=='SENIOR'))
                }else{
                  garantieCalcul = assure.garantiesSouscrits
                }
                
                return await Promise.all(garantieCalcul.map(async (garantie) => {
                  if(garantie.CodeProduitGarantie==='SUR'){
                    return getPrimeGarantie(await setParams(garantie)).then(data=>{
                      if(data){
                        garantie.prime = data[0].Prime
                        garantie.primeAccesoire =  0
                        garantie.primeTotal = parseInt(data[0].Prime)
                        garantie.capitalGarantie = props.data[`capital-${garantie.CodeProduitGarantie}`]
  
                        totalPrime+=parseInt(data[0].Prime)
                        mesGaranties.push(garantie)
                        if(!props.hasOwnProperty('capitalSouscrit')){
                          props.dispatch(registerField("adForm",'capitalSouscrit','Field'));
                        }
                        props.updateField('capitalSouscrit',props.data[`capital-${garantie.CodeProduitGarantie}`])
                      
                      }
                      return garantie
                  })
                  }else if (garantie.CodeProduitGarantie==='PERF'){
                    if(garantie.estprincipal===1){
                      primeAss =await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)   
                      primeAss = (primeAss && primeAss.length!==0)? primeAss[0].montant :0
                    } else{
                      primeAss=0
                    }
                    const maprimePerf =  (parseInt(primePrincipale)*(40/100))  
                    garantie.prime =maprimePerf
                    garantie.primeAccesoire =  primeAss
                    garantie.primeTotal =maprimePerf
                    totalPrime+=maprimePerf
                    mesGaranties.push(garantie)  
                    return garantie
                  }else{
                      if(garantie.estprincipal===1){
                        primeAss =await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)   
                        primeAss = (primeAss && primeAss.length!==0)? primeAss[0].montant :0
                      } else{
                        primeAss=0
                      }
                      const maprimeSecu = (parseInt(primePrincipale)*60/100)  
                      garantie.prime =maprimeSecu
                      garantie.primeAccesoire =  primeAss
                      garantie.primeTotal =maprimeSecu
                      totalPrime+=maprimeSecu
                      mesGaranties.push(garantie)  
                  }  
                })).then(mesgaranties=>{
                     assure.garantiesAssures = mesgaranties
                     return assure
                })
  
              })).then(async (mesAssures)=>{
                  //calcul de la primeprincipale
                  surPrime = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite) 
                  surPrime = surPrime?surPrime[0].montant:0
                  //assuré(e)s
                  if(!props.hasOwnProperty('assures')){
                    props.dispatch(registerField("adForm",'assures','Field'));
                   }
                  props.updateField('assures',mesAssures)
                 //surprime
                 if(!props.hasOwnProperty('surPrime')){
                  props.dispatch(registerField("adForm",'surPrime','Field'));
                 }
                 props.updateField('surPrime',surPrime)
                 //totalPrime
                 if(!props.hasOwnProperty('totalPrime')){
                  props.dispatch(registerField("adForm",'totalPrime','Field'));
                 }
                 setLoading(true)
                 props.updateField('totalPrime',(totalPrime+surPrime))
                 props.onSubmit()
              })
              break;
            case 'PVRBNI':
                  const {dureeRente,montantRente,periodiciteRente} =values
                  if(!dureeRente || dureeRente==='') {
                      setLoading(false)
                      setMyErrors({type:'warning',message:'Veuillez renseigner la durée de la rente'})
                      handleOpenAlert()
                      return false
                  }
                  if(!montantRente || montantRente==='') {
                      setLoading(false)
                      setMyErrors({type:'warning',message:'Veuillez renseigner le montant de la rente'})
                      handleOpenAlert()
                      return false
                  }
                  if(!periodiciteRente || periodiciteRente==='') {
                      setLoading(false)
                      setMyErrors({type:'warning',message:'Veuillez renseigner la périodicite de la rente'})
                      handleOpenAlert()
                      return false
                  }
                  //determination de la prime net 1
                  const dureeCotisation = duree
                  const i_c = 0.035; const fg_c =0.05; const i_net_c = i_c*(1-fg_c)
                  let per_c =12
                  switch (periodicite) {
                      case 'M':
                          per_c=12
                          break;
                      case 'T':
                          per_c=4
                          break;
                      case 'S':
                          per_c=2
                          break;
                      default:
                          per_c=1
                          break;
                  };
                  const i_per =(Math.pow((1+i_net_c),(1/per_c)))-1
                  let v_c = 1/(1+i_per)
                  let d_c = i_per/(1+i_per)
                  let valeur_a_c=(1-Math.pow(v_c,(dureeCotisation*per_c)))/d_c

                  const tx_fg =0.04
                  const tx_fa =0.05
                  const tx_investi = 1-tx_fg-tx_fa
                  const prime_net1 = (valeur_a_c*tx_investi)

                  // determination prime rente
                  const i_r = 0.035
                  const fg_r =0.05
                  const i_net_r = i_r*(1-fg_r)
                  let per_r =12
                  switch (periodiciteRente) {
                      case 'M':
                          per_r=12
                          break;
                      case 'T':
                          per_r=4
                          break;
                      case 'S':
                          per_r=2
                          break;
                      default:
                          per_r=1
                          break;
                  };
                  const i_per_r =(Math.pow((1+i_net_r),(1/per_r)))-1
                  const v_r = 1/(1+i_per_r)
                  const d_r = i_per_r/(1+i_per_r)
                  const dureeRentea = parseInt(dureeRente)/12
                  const dureeTotal = dureeRentea+parseInt(dureeCotisation)
                  const valeur_a_r =(1-Math.pow(v_r,((dureeTotal-dureeCotisation)*per_r)))/d_r
                  const vn = Math.pow(v_r,dureeCotisation*per_r)

                  const tx_fg_r = (1+0.03)
                  const primeRelle = parseInt((parseInt(montantRente)*tx_fg_r*valeur_a_r*vn)/prime_net1)
                  await Promise.all(assures.map(async (assure)=>{

                      if(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0){
                          garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>item.checked===true&&item.CodeProduitGarantie!=='SENIOR'))
                      }else{
                          garantieCalcul = assure.garantiesSouscrits
                      }

                      return await Promise.all(garantieCalcul.map(async (garantie) => {
                          garantie.prime = primeRelle
                          garantie.primeAccesoire =  0
                          garantie.primeTotal = parseInt(primeRelle)
                          garantie.capitalGarantie = 0

                          totalPrime+=primeRelle
                          return garantie

                      })).then(mesgaranties=>{
                          assure.garantiesAssures = mesgaranties
                          return assure
                      })

                  })).then(async (mesAssures)=>{
                      //calcul de la primeprincipale

                      if(!props.hasOwnProperty('assures')){
                          props.dispatch(registerField("adForm",'primePrincipale','Field'));
                      }
                      props.updateField('primePrincipale',primeRelle)

                      surPrime = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)
                      surPrime =0; //surPrime?surPrime[0].montant:0
                      //assuré(e)s

                      if(!props.hasOwnProperty('assures')){
                          props.dispatch(registerField("adForm",'assures','Field'));
                      }
                      props.updateField('assures',mesAssures)
                      //surprime
                      if(!props.hasOwnProperty('surPrime')){
                          props.dispatch(registerField("adForm",'surPrime','Field'));
                      }
                      props.updateField('surPrime',surPrime)
                      //totalPrime
                      if(!props.hasOwnProperty('totalPrime')){
                          props.dispatch(registerField("adForm",'totalPrime','Field'));
                      }
                      setLoading(true)
                      props.updateField('totalPrime',(totalPrime+surPrime))
                      props.onSubmit()
                  })
              break;
            default:
              break;
          }
         
      }
    return (
      <Fragment>
      {!loading?(
      
            <form onSubmit={handleSubmit}>
            <Field 
              id='documents'
              name='documents'
              component={DocumentCard}
              documentType={documents}
            />
            <Divider component="hr" className={classes.hTrait}/>
            <Grid container direction="row" justify="space-between" alignItems="center">        
             
                <Button type="button"  onClick={props.handleBack}>
                  Retour
                </Button>              
                <Button type= 'submit' color='primary' onClick={handleSubmit(handleSaveDoc)} disabled={props.data&&(props.data.hasOwnProperty('documents')? (props.data.documents.length===0?true:false):false)}>
                  Continuer
                  <NavigateNextOutlinedIcon /> 
                </Button>
            
            </Grid>
            </form>
     
      ):(
        <ModalLoading myopen={loading} />
      )}
        </Fragment> 
    )
}

const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapActionsToProps = {
  updateField
}

const documents=[
    {titre:"Bulletin de souscription",fichier:""},
    {titre:"Pièce d\'identité CNI",fichier:""},
    {titre:"Relevé d\'Identité Bancaire (RIB)",fichier:""}
]

const mapStateProps =(state)=>({
  data:getFormValues('adForm')(state),
})


export default connect(mapStateProps,mapActionsToProps)(reduxForm({form: 'adForm',destroyOnUnmount: false})(DocumentSouscription))

