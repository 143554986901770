import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { connect,useSelector } from 'react-redux'
import { reduxForm, change, Field, registerField, getFormValues } from 'redux-form';
import Paper from '@material-ui/core/Paper';
import CustomInput from "components/CustomInput/CustomInput.js";
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { green, pink } from '@material-ui/core/colors';
import CustomSelect from 'components/CustomSelect/CustomSelect.js'
import { Grid, Box } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { formatNumber } from 'application'
import dayjs from 'dayjs'
import { 
    getSearchContratsAgent, 
    getCentres, 
    getMaladies, 
    getActeurSinistre, 
    getDetailActeursSinistre, 
    pompesFunebresVille, 
    pompesFunebres,
    getFiliations,getBoxCourrierList } from 'redux/actions/apiActions'
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect'
import {compare } from 'application'
import CustomDate from 'components/CustomDate/CustomDate'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomRadio from 'components/CustomRadio/CustomRadio'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 10,
        padding: 10
    },

    loading: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonAdd: {
        width: '100%',
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 30,
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },

    buttonDetails: {
        width: '100%',
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        backgroundColor: '#fff',
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },

    buttonAddChild: {
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    buttonSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,

        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonReset: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonNewSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: "#FFF",
        color: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
        }
    },
    media: {
        height: 140,
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    dcard: {
        marginBottom: 10,
        paddingBottom: 10
    },
    paper: {
        marginBottom: 20,
        padding: 20
    },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
    },
    assureInfoRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    oroot: {
        marginBottom: 20,
        '& :hover': {
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            border: 0,
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            padding: '10 30px',
            textDecoration: 'none'
        }
    },

    sswitch: {
        width: 60,
        height: 40,
        padding: 0,
        margin: theme.spacing(1)
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            // This is the part that animates the thumb when the switch is toggled (to the right)
            transform: 'translateX(16px)',
            // This is the thumb color
            color: theme.palette.common.white,
            '& + $track': {
                // This is the track's background color (in this example, the iOS green)
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },

    },
    thumb: {
        width: 36,
        height: 36,
    },
    track: {
        borderRadius: 19,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[300],
        height: 30,
        opacity: 1,
        marginTop: 4,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    switcLabel: {
        fontSize: 18
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    loading: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonAdd: {
        width: '100%',
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 30,
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },

    buttonDetails: {
        width: '100%',
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        backgroundColor: '#fff',
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },

    buttonAddChild: {
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    buttonSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,

        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonReset: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonNewSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: "#FFF",
        color: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
        }
    },
    media: {
        height: 140,
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    dcard: {
        marginBottom: 10,
        paddingBottom: 10
    },
    paper: {
        marginBottom: 20,
        padding: 20
    },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
    },
}));

function OperationProdDetails(props) {
    const classes = useStyles();
  
    const {operData,dataForm} = props
    const [infosContrat,setInfosContrat] = useState(null)

    const handleSubmit =(values)=>{

    }
    const [state, setState] = React.useState({
        decesaccidentel: false,
        hunimationok: false,
        acteursType: null
    });
    const [assList, setAssList] = React.useState(null)
    const [checked, setChecked] = React.useState(null)
    const [expanded, setExpanded] = React.useState(false);
    const handleexpanded = (val) => {
        setExpanded(val ? val : !expanded)
    }
    const [centres, setCentres] = React.useState(null)
    const [maladies, setMaladies] = React.useState(null)
    const handleSwithcChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        if (!props.hasOwnProperty(`${event.target.name}`)) {
            props.dispatch(registerField("addOper", `${event.target.name}`, 'Field'));
        }
        props.updateField(`${event.target.name}`, event.target.checked)
    }
    const [mesVilles, setMesVilles] = React.useState(null)
    const handleVilles = () => {
        pompesFunebresVille().then(vl => {

            if (vl) setMesVilles(vl)
        })
    }
    const [filiations, setFiliations] = useState(null)
    useEffect(() => {
        getFiliations().then((fil) => { setFiliations(fil); })
    }, [])
    const [pompes, setPompes] = React.useState(null)
    const handlePompesFunebres = () => {
        if (dataForm && dataForm.hasOwnProperty('villeconservcorps')) {
            pompesFunebres(dataForm.villeconservcorps).then(pf => {
                if (pf) setPompes(pf)
            })
        } else {
            pompesFunebres().then(pf => {
                if (pf) setPompes(pf)
            })
        }
    }

    //let mesVilles = useSelector(state => state.api.villes).sort(compare)
    let professions = useSelector(state => state.api.professions).sort(compare)

    const handleCentre = () => {
        getCentres().then(res => {
            if (res) setCentres(res);
        })
    }
    const handleMaladie = () => {
        getMaladies().then(res => {
            if (res) setMaladies(res);
        })
    }

    const handleActeurSinistres = () => {
        getActeurSinistre().then(res => {
            if (res) {
                let acteurs = []
                acteurs = res.map(act => {
                    return { label: act.libelle, value: act.codetypedecces }
                })
                acteurs.push({ label: "AUTRE", value: "AUTRE" })
                if (acteurs && acteurs.length !== 0) {
                    setState({ ...state, acteursType: acteurs });
                }
            }
        })
    }
    useEffect(() => {
        handleCentre()
        handleMaladie()
        handleActeurSinistres()
        handleVilles()
        handlePompesFunebres()
    }, [])


    const handleActeursSinsitre = () => {
        if (dataForm.hasOwnProperty('typeacteur') && dataForm.hasOwnProperty('codeproposition')) {

            getDetailActeursSinistre({ type: dataForm.typeacteur, proposition: dataForm.codeproposition }).then(dataRes => {

                if (dataRes) {
                    if (dataRes.length > 1) {
                        setAssList(dataRes)
                        handleexpanded()
                    } else {
                        if (!props.hasOwnProperty('nomdefunt')) {
                            props.dispatch(registerField("addOper", 'nomdefunt', 'Field'));
                        }
                        props.updateField('nomdefunt', dataRes[0].Nom)

                        if (!props.hasOwnProperty('prenomdefunt')) {
                            props.dispatch(registerField("addOper", 'prenomdefunt', 'Field'));
                        }
                        props.updateField('prenomdefunt', dataRes[0].Prenom)

                        if (!props.hasOwnProperty('datenaissancedefunt')) {
                            props.dispatch(registerField("addOper", 'datenaissancedefunt', 'Field'));
                        }
                        props.updateField('datenaissancedefunt', dayjs(dataRes[0].DateNaissance).format('YYYY-MM-DD'))

                        if (!props.hasOwnProperty('lieuresidencedefunt')) {
                            props.dispatch(registerField("addOper", 'lieuresidencedefunt', 'Field'));
                        }
                        props.updateField('lieuresidencedefunt', dataRes[0].Lieuresidence)

                        if (!props.hasOwnProperty('lieunaissancedefunt')) {
                            props.dispatch(registerField("addOper", 'lieunaissancedefunt', 'Field'));
                        }
                        props.updateField('lieunaissancedefunt', dataRes[0].Lieunaissance)

                        if (!props.hasOwnProperty('professiondefunt')) {
                            props.dispatch(registerField("addOper", 'professiondefunt', 'Field'));
                        }
                        props.updateField('professiondefunt', dataRes[0].Profession)
                        handleexpanded()
                    }
                }
            })
        }
    }
    const handleSelectAssInList = (dataAss) => {
        setChecked(null)
        if (dataAss) {
            setChecked(dataAss.CodePersonne)
            if (!props.hasOwnProperty('nomdefunt')) {
                props.dispatch(registerField("addOper", 'nomdefunt', 'Field'));
            }
            props.updateField('nomdefunt', dataAss.Nom)

            if (!props.hasOwnProperty('prenomdefunt')) {
                props.dispatch(registerField("addOper", 'prenomdefunt', 'Field'));
            }
            props.updateField('prenomdefunt', dataAss.Prenom)

            if (!props.hasOwnProperty('datenaissancedefunt')) {
                props.dispatch(registerField("addOper", 'datenaissancedefunt', 'Field'));
            }
            props.updateField('datenaissancedefunt', dayjs(dataAss.DateNaissance).format('YYYY-MM-DD'))

            if (!props.hasOwnProperty('lieuresidencedefunt')) {
                props.dispatch(registerField("addOper", 'lieuresidencedefunt', 'Field'));
            }
            props.updateField('lieuresidencedefunt', dataAss.Lieuresidence)

            if (!props.hasOwnProperty('lieunaissancedefunt')) {
                props.dispatch(registerField("addOper", 'lieunaissancedefunt', 'Field'));
            }
            props.updateField('lieunaissancedefunt', dataAss.Lieunaissance)

            if (!props.hasOwnProperty('professiondefunt')) {
                props.dispatch(registerField("addOper", 'professiondefunt', 'Field'));
            }
            props.updateField('professiondefunt', dataAss.Profession)
        }

    }

    const [courrierBox, setCourrierBox] = useState(null)
    useEffect(() => {
        getBoxCourrierList().then((bx) => { setCourrierBox(bx); })
    }, [])

    const handleSetCodeBox = () => {
        if (dataForm && dataForm.hasOwnProperty('box') &&  (courrierBox && courrierBox.length !== 0)) {
            const cbox = courrierBox.filter(item => item.MonLibelle === dataForm.box)[0]
            if (cbox) {
                if (!props.hasOwnProperty('codebox')) {
                    props.dispatch(registerField("addOper", 'codebox', 'Field'));
                }
                props.updateField('codebox', cbox.CodeBox)
            }
        }
    }


    useEffect(() => {
        if(dataForm && dataForm.hasOwnProperty('typeacteur')){
            handleActeursSinsitre()
        }
        if(dataForm && dataForm.hasOwnProperty('villeconservcorps')){
            if (dataForm) handlePompesFunebres()
        }
        handleSetCodeBox()
    }, [dataForm])
   
    const loadData =()=>{
        if (!props.hasOwnProperty('id')) {
            props.dispatch(registerField("addOper", 'id', 'Field'));
        }
        props.updateField('id', operData['id'])

        if (!props.hasOwnProperty('codeoperation')) {
            props.dispatch(registerField("addOper", 'codeoperation', 'Field'));
        }
        props.updateField('codeoperation', operData['codeoperation'])

        if (!props.hasOwnProperty('idcourrier')) {
            props.dispatch(registerField("addOper", 'idcourrier', 'Field'));
        }
        props.updateField('idcourrier', operData['idcourrier'])

        if (!props.hasOwnProperty('codecourrier')) {
            props.dispatch(registerField("codecourrier", 'codecourrier', 'codecourrier'));
        }
        props.updateField('codecourrier', operData['codecourrier'])

        if (!props.hasOwnProperty('idproposition')) {
            props.dispatch(registerField("addOper", 'idproposition', 'Field'));
        }
        props.updateField('idproposition', operData['idproposition'])

        if (!props.hasOwnProperty('codeproposition')) {
            props.dispatch(registerField("addOper", 'codeproposition', 'Field'));
        }
        props.updateField('codeproposition', operData['codeproposition'])

        if (!props.hasOwnProperty('refcontrat')) {
            props.dispatch(registerField("addOper", 'refcontrat', 'Field'));
        }
        props.updateField('refcontrat', operData['refcontrat'])

        if (!props.hasOwnProperty('codeproduit')) {
            props.dispatch(registerField("addOper", 'codeproduit', 'Field'));
        }
        props.updateField('codeproduit', operData['codeproduit'])

        if (!props.hasOwnProperty('libelleproduit')) {
            props.dispatch(registerField("addOper", 'libelleproduit', 'Field'));
        }
        props.updateField('libelleproduit', operData['libelleproduit'])

        if (!props.hasOwnProperty('codeproduitformule')) {
            props.dispatch(registerField("addOper", 'codeproduitformule', 'Field'));
        }
        props.updateField('codeproduitformule', operData['codeproduitformule'])

        if (!props.hasOwnProperty('libelleproduitformule')) {
            props.dispatch(registerField("addOper", 'libelleproduitformule', 'Field'));
        }
        props.updateField('libelleproduitformule', operData['libelleproduitformule'])


        if (!props.hasOwnProperty('dateoperation')) {
            props.dispatch(registerField("addOper", 'dateoperation', 'Field'));
        }
        props.updateField('dateoperation', operData['dateoperation'])

        if (!props.hasOwnProperty('codetypeoperation')) {
            props.dispatch(registerField("addOper", 'codetypeoperation', 'Field'));
        }
        props.updateField('codetypeoperation', operData['codetypeoperation'])

        if (!props.hasOwnProperty('libelletypeoperation')) {
            props.dispatch(registerField("addOper", 'libelletypeoperation', 'Field'));
        }
        props.updateField('libelletypeoperation', operData['libelletypeoperation'])

        if (!props.hasOwnProperty('nomdeclarant')) {
            props.dispatch(registerField("addOper", 'nomdeclarant', 'Field'));
        }
        props.updateField('nomdeclarant', operData['nomdeclarant'])

        if (!props.hasOwnProperty('prenomdeclarant')) {
            props.dispatch(registerField("addOper", 'prenomdeclarant', 'Field'));
        }
        props.updateField('prenomdeclarant', operData['prenomdeclarant'])

        if (!props.hasOwnProperty('mobiledeclarant')) {
            props.dispatch(registerField("addOper", 'mobiledeclarant', 'Field'));
        }
        props.updateField('mobiledeclarant', operData['mobiledeclarant'])

        if (!props.hasOwnProperty('mobile1declarant')) {
            props.dispatch(registerField("addOper", 'mobile1declarant', 'Field'));
        }
        props.updateField('mobile1declarant', operData['mobile1declarant'])

        if (!props.hasOwnProperty('emaildeclarant')) {
            props.dispatch(registerField("addOper", 'emaildeclarant', 'Field'));
        }
        props.updateField('emaildeclarant', operData['emaildeclarant'])

        if (!props.hasOwnProperty('filiationdeclarant')) {
            props.dispatch(registerField("addOper", 'filiationdeclarant', 'Field'));
        }
        props.updateField('filiationdeclarant', operData['filiationdeclarant'])

        if (!props.hasOwnProperty('lieunaissancedeclarant')) {
            props.dispatch(registerField("addOper", 'lieunaissancedeclarant', 'Field'));
        }
        props.updateField('lieunaissancedeclarant', operData['lieunaissancedeclarant'])

        if (!props.hasOwnProperty('branche')) {
            props.dispatch(registerField("addOper", 'branche', 'Field'));
        }
        props.updateField('branche', operData['branche'])

        if (!props.hasOwnProperty('codeagence')) {
            props.dispatch(registerField("addOper", 'codeagence', 'Field'));
        }
        props.updateField('codeagence', operData['codeagence'])

        if (!props.hasOwnProperty('nomagence')) {
            props.dispatch(registerField("addOper", 'nomagence', 'Field'));
        }
        props.updateField('nomagence', operData['nomagence'])

        if (!props.hasOwnProperty('naturesinistre')) {
            props.dispatch(registerField("addOper", 'naturesinistre', 'Field'));
        }
        props.updateField('naturesinistre', operData['naturesinistre'])


        if (!props.hasOwnProperty('lieusinistre')) {
            props.dispatch(registerField("addOper", 'lieusinistre', 'Field'));
        }
        props.updateField('lieusinistre', operData['lieusinistre'])

        if (!props.hasOwnProperty('datesinistre')) {
            props.dispatch(registerField("addOper", 'datesinistre', 'Field'));
        }
        props.updateField('datesinistre', operData['datesinistre'])

        if (!props.hasOwnProperty('lieuconservcorps')) {
            props.dispatch(registerField("addOper", 'lieuconservcorps', 'Field'));
        }
        props.updateField('lieuconservcorps', operData['lieuconservcorps'])

        if (!props.hasOwnProperty('dateinhumation')) {
            props.dispatch(registerField("addOper", 'dateinhumation', 'Field'));
        }
        props.updateField('dateinhumation', operData['dateinhumation'])


        if (!props.hasOwnProperty('lieuinhumation')) {
            props.dispatch(registerField("addOper", 'lieuinhumation', 'Field'));
        }
        props.updateField('lieuinhumation', operData['lieuinhumation'])

        if (!props.hasOwnProperty('causesinistre')) {
            props.dispatch(registerField("addOper", 'causesinistre', 'Field'));
        }
        props.updateField('causesinistre', operData['causesinistre'])

        if (!props.hasOwnProperty('nomdefunt')) {
            props.dispatch(registerField("addOper", 'nomdefunt', 'Field'));
        }
        props.updateField('nomdefunt', operData['nomdefunt'])

        if (!props.hasOwnProperty('prenomdefunt')) {
            props.dispatch(registerField("addOper", 'prenomdefunt', 'Field'));
        }
        props.updateField('prenomdefunt', operData['prenomdefunt'])

        if (!props.hasOwnProperty('datenaissancedefunt')) {
            props.dispatch(registerField("addOper", 'datenaissancedefunt', 'Field'));
        }
        props.updateField('datenaissancedefunt', operData['datenaissancedefunt'])

        if (!props.hasOwnProperty('lieunaissancedefunt')) {
            props.dispatch(registerField("addOper", 'lieunaissancedefunt', 'Field'));
        }
        props.updateField('lieunaissancedefunt', operData['lieunaissancedefunt'])

        if (!props.hasOwnProperty('professiondefunt')) {
            props.dispatch(registerField("addOper", 'professiondefunt', 'Field'));
        }
        props.updateField('professiondefunt', operData['professiondefunt'])

        if (!props.hasOwnProperty('empyeurdefunt')) {
            props.dispatch(registerField("addOper", 'empyeurdefunt', 'Field'));
        }
        props.updateField('empyeurdefunt', operData['empyeurdefunt'])

        if (!props.hasOwnProperty('lieuresidencedefunt')) {
            props.dispatch(registerField("addOper", 'lieuresidencedefunt', 'Field'));
        }
        props.updateField('lieuresidencedefunt', operData['lieuresidencedefunt'])


        if (!props.hasOwnProperty('etattraitement')) {
            props.dispatch(registerField("addOper", 'etattraitement', 'Field'));
        }
        props.updateField('etattraitement', operData['etattraitement'])

        if (!props.hasOwnProperty('daterdv')) {
            props.dispatch(registerField("addOper", 'daterdv', 'Field'));
        }
        props.updateField('daterdv', operData['daterdv'])

        if (!props.hasOwnProperty('numbon')) {
            props.dispatch(registerField("addOper", 'numbon', 'Field'));
        }
        props.updateField('numbon', operData['numbon'])

        if (!props.hasOwnProperty('etape')) {
            props.dispatch(registerField("addOper", 'etape', 'Field'));
        }
        props.updateField('etape', operData['etape'])

        if (!props.hasOwnProperty('codetypedeces')) {
            props.dispatch(registerField("addOper", 'codetypedeces', 'Field'));
        }
        props.updateField('codetypedeces', operData['codetypedeces'])

        if (!props.hasOwnProperty('groupeoperation')) {
            props.dispatch(registerField("addOper", 'groupeoperation', 'Field'));
        }
        props.updateField('groupeoperation', operData['groupeoperation'])

        if (!props.hasOwnProperty('adherent')) {
            props.dispatch(registerField("addOper", 'adherent', 'Field'));
        }
        props.updateField('adherent', operData['adherent'])


        if (!props.hasOwnProperty('delaitraitement')) {
            props.dispatch(registerField("addOper", 'delaitraitement', 'Field'));
        }
        props.updateField('delaitraitement', operData['delaitraitement'])

        if (!props.hasOwnProperty('datefinprevu')) {
            props.dispatch(registerField("addOper", 'datefinprevu', 'Field'));
        }
        props.updateField('datefinprevu', operData['datefinprevu'])

        if (!props.hasOwnProperty('dateeffet')) {
            props.dispatch(registerField("addOper", 'dateeffet', 'Field'));
        }
        props.updateField('dateeffet', operData['dateeffet'])

        if (!props.hasOwnProperty('codebox')) {
            props.dispatch(registerField("addOper", 'codebox', 'Field'));
        }
        props.updateField('codebox', operData['codebox'])


        if (!props.hasOwnProperty('box')) {
            props.dispatch(registerField("addOper", 'box', 'Field'));
        }
        props.updateField('box', operData['box'])

        if (!props.hasOwnProperty('observation')) {
            props.dispatch(registerField("addOper", 'observation', 'Field'));
        }
        props.updateField('observation', operData['observation'])

        if (!props.hasOwnProperty('villeconservcorps')) {
            props.dispatch(registerField("addOper", 'villeconservcorps', 'Field'));
        }
        props.updateField('villeconservcorps', operData['villeconservcorps'])

        if (!props.hasOwnProperty('codepompesfunebres')) {
            props.dispatch(registerField("addOper", 'codepompesfunebres', 'Field'));
        }
        props.updateField('codepompesfunebres', operData['codepompesfunebres'])

        if (!props.hasOwnProperty('localconservcorps')) {
            props.dispatch(registerField("addOper", 'localconservcorps', 'Field'));
        }
        props.updateField('localconservcorps', operData['localconservcorps'])
    }
 
    useEffect(()=>{
        loadData()
        if(operData && operData.hasOwnProperty('idproposition')){
            getSearchContratsAgent({idcontrat:operData['idproposition']}).then(res=>{
                setInfosContrat(res['details'][0])
            })
        }
    },[operData])

    return (
        <div>
            {infosContrat&&<Card className={classes.root} variant="outlined">
                <CardContent>
                    <Typography variant='h5'>CONTRATS</Typography>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <Box m={1} className={classes.assureInfoRow}>
                                <Typography variant="subtitle1">
                                    {`\xa0 ${infosContrat['Adherent'] || ""}`}
                                </Typography>
                            </Box>
                            <Box m={1} className={classes.assureInfoRow}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    #ID :
                                </Typography>
                                <Typography variant="subtitle1">
                                    {`\xa0 ${operData['idproposition'] || ""}`}
                                </Typography>
                            </Box>
                            <Box m={1} className={classes.assureInfoRow}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Proposition n° :
                                </Typography>
                                <Typography variant="subtitle1">
                                    {`\xa0 ${infosContrat['CodeProposition'] || ""}`}
                                </Typography>
                            </Box>
                            
                            <Box m={1} className={classes.assureInfoRow}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    N° police :
                                </Typography>
                                <Typography variant="subtitle1">
                                    {`\xa0 ${infosContrat['CodePolice'] || ""}`}
                                </Typography>
                            </Box>
                            <Box m={1} className={classes.assureInfoRow}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Date d'effet :
                                </Typography>
                                <Typography variant="subtitle1">
                                    {`\xa0 ${infosContrat['DateEffet'] || ""}`}
                                </Typography>
                            </Box>
                            <Box m={1} className={classes.assureInfoRow}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Date échéance :
                                </Typography>
                                <Typography variant="subtitle1">
                                    {`\xa0 ${infosContrat['DateEcheance'] || ""}`}
                                </Typography>
                            </Box>
                            <Box m={1} className={classes.assureInfoRow}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Capital :
                                </Typography>
                                <Typography variant="subtitle1">
                                    {`\xa0 ${infosContrat['Capital'] ? formatNumber(parseInt(infosContrat['Capital']).toString()) : ""}`}
                                </Typography>
                            </Box>
                            <Box m={1} className={classes.assureInfoRow}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Produit :
                                </Typography>
                                <Typography variant="subtitle1">
                                    {`\xa0 ${infosContrat['Produit'] || ""}`}
                                </Typography>
                            </Box>
                            
                            <Box m={1} className={classes.assureInfoRow}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Prime :
                                </Typography>
                                <Typography variant="subtitle1">
                                    {`\xa0 ${infosContrat['Prime'] ? formatNumber(parseInt(infosContrat['Prime']).toString()) : ""}`}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Box m={1} className={classes.assureInfoRow}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Nombre d'émission :
                                </Typography>
                                <Typography variant="subtitle1">
                                    {`\xa0 ${infosContrat['NombreEmission'] ? formatNumber(parseInt(infosContrat['NombreEmission']).toString()) : ""}`}
                                </Typography>
                            </Box>
                            <Box m={1} className={classes.assureInfoRow}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Montant d'émission :
                                </Typography>
                                <Typography variant="subtitle1">
                                    {`\xa0 ${infosContrat['MontantEmission'] ? formatNumber(parseInt(infosContrat['MontantEmission']).toString()) : ""}`}
                                </Typography>
                            </Box>
                            <Box m={1} className={classes.assureInfoRow}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Nombre d'encaissement :
                                </Typography>
                                <Typography variant="subtitle1">
                                    {`\xa0 ${infosContrat['NombreEncaissement'] ? formatNumber(parseInt(infosContrat['NombreEncaissement']).toString()) : ""}`}
                                </Typography>
                            </Box>
                            <Box m={1} className={classes.assureInfoRow}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Montant d'encaissement :
                                </Typography>
                                <Typography variant="subtitle1">
                                    {`\xa0 ${infosContrat['MontantEncaissement'] ? formatNumber(parseInt(infosContrat['MontantEncaissement']).toString()) : ""}`}
                                </Typography>
                            </Box>
                            <Box m={1} className={classes.assureInfoRow}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Nombre d'impayés :
                                </Typography>
                                <Typography variant="subtitle1">
                                    {`\xa0 ${infosContrat['NombreImpayes'] ? formatNumber(parseInt(infosContrat['NombreImpayes']).toString()) : ""}`}
                                </Typography>
                            </Box>
                            <Box m={1} className={classes.assureInfoRow}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Montant d'impayés :
                                </Typography>
                                <Typography variant="subtitle1">
                                    {`\xa0 ${infosContrat['MontantImpayes'] ? formatNumber(parseInt(infosContrat['MontantImpayes']).toString()) : ""}`}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>}
            {(operData && operData['groupeoperation'] === 'SIN') ?(
                <form onSubmit={handleSubmit}>
                    <Paper elevation={3} className={classes.paper}>
                            <Typography variant='h5'>OPERATION</Typography>
                            <Divider />
                            <LabelledOutline label="Description du sinistre">
                            <GridContainer>
                                <GridItem sm={12} xs={12} md={6} lg={6}>
                                    <Field
                                        required
                                        id="naturesinistre"
                                        name="naturesinistre"
                                        component={CustomSimpleSelect}
                                        label="Quelle nature du sinistre ?"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
        
                                        options={['DECES', 'INVALIDITE']}
                                    />
                                    {centres && <Field
                                        required
                                        label="Dans quel etablissement le sinistre a eu lieu ?"
                                        id="lieusinistre"
                                        name="lieusinistre"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        component={CustomSelect}
                                        options={centres}
                                        variant="outlined"
                                    />}
                                    {maladies && <Field
                                        required
                                        label="Quelle est la cause du sinistre ?"
                                        id="causesinistre"
                                        name="causesinistre"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        component={CustomSelect}
                                        options={maladies}
                                        variant="outlined"
                                    />}
                                    <LabelledOutline label="Type de sinistre">
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                classes={{ label: classes.switcLabel }}
                                                control={<Switch classes={{
                                                    root: classes.sswitch,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked,
                                                }} checked={state.decesaccidentel} onChange={handleSwithcChange} color="primary" name="decesaccidentel" />}
                                                label="Le décès est-il accidentel ?"
                                                labelPlacement="start"
                                            />
                                            <FormControlLabel
                                                classes={{ label: classes.switcLabel }}
                                                control={<Switch classes={{
                                                    root: classes.sswitch,
                                                    switchBase: classes.switchBase,
                                                    thumb: classes.thumb,
                                                    track: classes.track,
                                                    checked: classes.checked,
                                                }} checked={state.hunimationok} onChange={handleSwithcChange} color="primary" name="hunimationok" />}
                                                label="L'hunimation à t'elle déjà eu lieu ?"
                                                labelPlacement="start"
                                            />
                                        </FormGroup>
                                    </LabelledOutline>
                                </GridItem>
                                <GridItem sm={12} xs={12} md={6} lg={6}>
                                    <Field
                                        required
                                        id="datesinistre"
                                        name="datesinistre"
                                        component={CustomDate}
                                        label="A quelle date le sinistre a eu lieu ?"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        InputProps={{
                                            type: 'date',
                                        }}
                                    />
                                    <LabelledOutline label="Lieu de concervation du corps">
                                        {mesVilles && <Field
                                            label="Ville"
                                            id="villeconservcorps"
                                            name="villeconservcorps"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            component={CustomSelect}
                                            options={mesVilles}
                                            variant="outlined"
                                        />}
                                        {pompes && <Field
                                            required
                                            id="lieuconservcorps"
                                            name="lieuconservcorps"
                                            component={TextField}
                                            label="Etablissement"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            select
                                            variant="outlined"
                                        >
                                            <MenuItem value="0">
                                                <em>Aucun</em>
                                            </MenuItem>
                                            {pompes.map((option) => (
                                                <MenuItem key={option.id} value={option.designation}>
                                                    {`${option.designation}-${option.commune}(${option.localisation})`}
                                                </MenuItem>
                                            ))}
                                        </Field>}
                                    </LabelledOutline>
        
                                    <Field
                                        required
                                        id="dateinhumation"
                                        name="dateinhumation"
                                        component={CustomDate}
                                        label="Quelle est la date l'enterrement ?"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        InputProps={{
                                            type: 'date',
                                        }}
                                    />
                                    <Field
                                        required
                                        id="lieuinhumation"
                                        name="lieuinhumation"
                                        component={CustomInput}
                                        label="Quel est le lieu de l'entrerrement ?"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        type='texte'
                                        variant="outlined"
                                    />
                                </GridItem>
                            </GridContainer>
                        </LabelledOutline>
                    </Paper>
                    <Paper elevation={3} className={classes.paper}>

                        <LabelledOutline label="Type acteur au contrat">
                            <Field
                                required
                                label=""
                                id="typeacteur"
                                name="typeacteur"
                                options={state.acteursType && state.acteursType}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                row
                                component={CustomRadio}
                            />
                        </LabelledOutline>
                        <Accordion expanded={expanded} onChange={handleexpanded}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant='h5'>{(assList && assList.length !== 0) ? "Selectionnez le défunt" : "Informations sur le défunt"}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {(assList && assList.length !== 0) ? (<TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" >#</TableCell>
                                                <TableCell align="center" >Nom</TableCell>
                                                <TableCell align="center">Né(e) le</TableCell>
                                                <TableCell align="center">Lieu de naissance</TableCell>
                                                <TableCell align="center">Lieu de residence</TableCell>
                                                <TableCell align="center">Profession</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {assList.map(ass => (
                                                <TableRow key={ass.CodePersonne}>
                                                    <TableCell align="center" component="th" scope="row">
                                                        <Checkbox
                                                            checked={(checked === ass.CodePersonne)}
                                                            onChange={(e) => handleSelectAssInList(ass)}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">{`${ass.Nom} ${ass.Prenom}`}</TableCell>
                                                    <TableCell align="center">{dayjs(ass.DateNaissance.toString()).isValid() ? dayjs(ass.DateNaissance).format('DD/MM/YYYY') : ""}</TableCell>
                                                    <TableCell align="center">{ass.Lieunaissance}</TableCell>
                                                    <TableCell align="center">{ass.lieuresidence}</TableCell>
                                                    <TableCell align="center">{ass.Profession}</TableCell>
                                                </TableRow>
                                            ))}
        
                                        </TableBody>
                                    </Table>
                                </TableContainer>) : (<GridContainer>
                                    <GridItem sm={12} xs={12} md={6} lg={6}>
                                        <Field
                                            id="nomdefunt"
                                            name="nomdefunt"
                                            component={CustomInput}
                                            label="Nom du défunt"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            type='texte'
                                            variant="outlined"
                                        />
                                        <Field
                                            id="datenaissancedefunt"
                                            name="datenaissancedefunt"
                                            label="Date de naissance"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            component={CustomDate}
                                            InputProps={{
                                                type: 'date',
                                            }}
                                        />
                                        <Field
                                            label="Lieu de résidence"
                                            id="lieuresidencedefunt"
                                            name="lieuresidencedefunt"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            component={CustomSelect}
                                            options={mesVilles}
                                            variant="outlined"
        
                                        />
                                        <Field
                                            label="Profession"
                                            id="professiondefunt"
                                            name="professiondefunt"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            component={CustomSelect}
                                            options={professions}
        
                                        />
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={6} lg={6}>
                                        <Field
                                            id="prenomdefunt"
                                            name="prenomdefunt"
                                            component={CustomInput}
                                            label="Préom du défunt"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            type='texte'
                                            variant="outlined"
                                        />
                                        <Field
                                            label="Lieu de naissance"
                                            id="lieunaissancedefunt"
                                            name="lieunaissancedefunt"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            component={CustomSelect}
                                            options={mesVilles}
                                            variant="outlined"
        
                                        />
                                        <Field
                                            label="Secteur d'activité"
                                            id="secteuractivite"
                                            name="secteuractivite"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
        
                                            options={secteurActivites}
                                            component={CustomSelect}
                                        />
                                    </GridItem>
                                </GridContainer>)}
                            </AccordionDetails>
                        </Accordion>
                    </Paper>
                    <Paper elevation={3} className={classes.paper}>
                            <Typography variant='h5'>DECLARANT</Typography>
                            <Divider />
                        <GridContainer>
                            <GridItem sm={12} xs={12} md={6} lg={6}>
                                <Field
                                    required
                                    id="nomdeclarant"
                                    name="nomdeclarant"
                                    component={CustomInput}
                                    label="Nom"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    type='texte'
                                    variant="outlined"
                                />
                                <Field
                                    required
                                    id="prenomdeclarant"
                                    name="prenomdeclarant"
                                    component={CustomInput}
                                    label="Prénom"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    type='texte'
                                    variant="outlined"
                                />
                                <Field
                                    label="Lien de parenté"
                                    id="filiationdeclarant"
                                    name="filiationdeclarant"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    component={CustomSelect}
                                    options={filiations}
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem sm={12} xs={12} md={6} lg={6}>
                                <Field
                                    id="emaildeclarant"
                                    name="emaildeclarant"
                                    component={CustomInput}
                                    label="Email"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    type='email'
                                    variant="outlined"
                                />
                                <Field
                                    label="Téléphone"
                                    id="telephonedeclarant"
                                    name="telephonedeclarant"
                                    variant="outlined"
                                    component={CustomPhoneInput}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    </Paper>
                </form>
            ):(
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography variant='h5'>OPERATION</Typography>
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                                <Box m={1} className={classes.assureInfoRow}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Type :
                                    </Typography>
                                    <Typography variant="h6">
                                        {`\xa0 ${operData['libelletypeoperation'] || ""}`}
                                    </Typography>
                                </Box>
                                <Box m={1} className={classes.assureInfoRow}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Date opération :
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {`\xa0 ${dayjs(operData['dateoperation']).format('DD/MM/YYYY')}`}
                                    </Typography>
                                </Box>
                                <Box m={1} className={classes.assureInfoRow}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Délai traitement :
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {`\xa0 ${operData['delaitraitement'] || ""} jour(s)`}
                                    </Typography>
                                </Box>
                                <Box m={1} className={classes.assureInfoRow}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Date fin traitement prévu :
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {`\xa0 ${operData['delaitraitement'] ? dayjs().add(parseInt(dataForm['delaitraitement']), 'day').format('DD/MM/YYYY') : ""}`}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        
                    </CardContent>
                </Card>
            )}
        
        </div>
    )
}


const updateField = (field, data) => (dispatch) => dispatch(change('addOper', field, data))
const mapPropsActions = {
    updateField,
}
const mapStateToProps = (state) => ({
    user: state.user,
    api: state.api,
    dataForm: getFormValues('addOper')(state),
});
export default connect(mapStateToProps, mapPropsActions)(reduxForm({ form: 'addOper', destroyOnUnmount: false })(OperationProdDetails))


const secteurActivites = [
    { "MonLibelle": 'Agroalimentaire' },
    { "MonLibelle": 'Banque / Assurance' },
    { "MonLibelle": 'Bois / Papier / Carton / Imprimerie' },
    { "MonLibelle": 'BTP / Matériaux de construction' },
    { "MonLibelle": 'Édition / Communication / Multimédia' },
    { "MonLibelle": 'Électronique / Électricité' },
    { "MonLibelle": 'Études et conseils' },
    { "MonLibelle": 'Industrie pharmaceutique' },
    { "MonLibelle": 'Informatique / Télécoms' },
    { "MonLibelle": 'Machines et équipements / Automobile' },
    { "MonLibelle": 'Métallurgie / Travail du métal' },
    { "MonLibelle": 'Plastique / Caoutchouc' },
    { "MonLibelle": 'Services aux entreprises' },
    { "MonLibelle": 'Textile / Habillement / Chaussure' },
    { "MonLibelle": 'Transports / Logistique' },
    { "MonLibelle": 'Autre' }
]