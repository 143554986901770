import React, { useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from "components/CustomInput/CustomInput.js";
import { updatePret } from 'redux/actions/apiActions'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  assureInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-between'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

}));

function DialogBox({ data, dopen, dinfo: { dTitle, dmode, dTexteButton, dmessage }, ...props }) {
  const classes = useStyles();

  const [myAlerte, setMyAlerte] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      aMessage: null,
      aopen: false,
      amode: null,
    });

  const handleClickOpen = () => {
    props.handleDopen(true);
  };
  const handleClose = () => {
    props.handleDopen(false);
  };


  return (
    <Dialog
      open={dopen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='md'
    >
      <DialogTitle id="alert-dialog-title">{dTitle ? dTitle : ""}</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            {(dmode === 1) && (
              <CustomInput
                required
                label="Motif"
                id="motif"
                name="motif"
                formControlProps={{
                  fullWidth: true
                }}
                variant="outlined"
                onChange={props.handleChange}
              />

            )}
            {(dmode === 2) && (
              <Typography variant='h5'>{dmessage}</Typography>
            )}
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Abandonner
        </Button>
        <Button onClick={props.daction} size="large" color="primary" variant="contained" autoFocus>
          {dTexteButton}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
const mapStateToProps = (state) => ({
  api: state.api,
});
const mapActionsToProps = {
  updatePret
}

export default connect(mapStateToProps, mapActionsToProps)(reduxForm({ form: 'addPret', destroyOnUnmount: false, })(DialogBox))