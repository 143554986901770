import React,{useState,useEffect} from 'react'
import { useSelector,connect } from 'react-redux'
import OperationAccueil from './OperationAccueil'
import AddOperation from 'pages/Operations/AddOperation'
import OperationList from 'pages/Operations/OperationList'
function OperationContainer(props) {
  const [fonctionShow,setFonctionShow] =useState('HOME')
  const handleCliqueFonction =(show,md,cdata)=>{
      if(show === 'UP' ){
        setMode('UP')
      }else{
        setMode(md)
      }
      setData(cdata)
      setFonctionShow(show)
  }
  const [data,setData] =useState(null)
  const [mode,setMode] =useState('ADD')
 const currentmode = useSelector((state)=>state.UI.mode)
 useEffect(()=>{
  if(currentmode==='UP'){
    setFonctionShow('ADD')
  }
 },[currentmode])
  return (
    <div>
        {(fonctionShow==='HOME')&&<OperationAccueil  handleCliqueFonction={handleCliqueFonction} />}
        {(fonctionShow==='ADD')&&<AddOperation mode={mode} history={props.history} handleCliqueFonction={handleCliqueFonction} />}
        {(fonctionShow==='LIST')&&<OperationList   handleCliqueFonction={handleCliqueFonction} />}
    </div>
  )
}

export default OperationContainer