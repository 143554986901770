import React, { useEffect, useState, useReducer } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import { connect } from 'react-redux'
import { reduxForm, change } from 'redux-form';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from "components/CustomButtons/Button.js";
import { getOperationByUser, updateOperation } from 'redux/actions/apiActions'
import dayjs from 'dayjs'
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MaterialTable from 'material-table'
import FilterListIcon from '@material-ui/icons/FilterList';
import CircularProgress from '@material-ui/core/CircularProgress';
import XLSX from 'xlsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import AssignmentIcon from '@material-ui/icons/Assignment';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AttachmentIcon from '@material-ui/icons/Attachment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import {
    getEquipesReseau,
    getEquipesZones,
    getZonesReseau,
    getZonesNoDispatch,
    getEquipesNoDispatch
} from 'redux/actions/settingsAction'
import RefreshIcon from '@material-ui/icons/Refresh';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import OperationValidate from './OperationValidate';
import RejetForm from './RejetForm';
import AlertPage from 'components/AlertPage'
import ImportDocumentList from 'pages/Producteur/Propositions/components/ImportDocumentList'
import ImportDocument from 'pages/Banking/Propositions/ImportDocument'
const exportToExcel = (columns, data) => {
    // determining header labels
    const columnInfo = columns.reduce(
        (acc, column) => {
            const headerLabel = column.title || column.field;
            acc.header.push(headerLabel);
            acc.map[column.field] = headerLabel;
            return acc;
        },
        { map: {}, header: [] }
    );
    const mappedData = data.map(row =>
        Object.entries(row).reduce((acc, [key, value]) => {
            if (columnInfo.map[key]) {
                acc[columnInfo.map[key]] = value;
            }
            return acc;
        }, {})
    );

    var ws = XLSX.utils.json_to_sheet(mappedData, {
        header: columnInfo.header, // garanties the column order same as in material-table
    });

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* generate an XLSX file and triggers the browser to generate the download*/
    XLSX.writeFile(wb, `LLV-liste-Operation.xlsx`);
};

const CustumLinearProgress = withStyles((theme) => ({
    root: {
        height: 20,
        borderRadius: 10,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 10,
        backgroundColor: props => props.currentColor,
    },
}))(LinearProgress);


function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
const useStyles = makeStyles(theme => ({
    paper: {
        marginBottom: 20,
        padding: 20
    },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
    },

    hearderContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerTitle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    headerFilter: {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    headerState: {
        display: 'flex',
        flex: 2,
        justifyContent: 'center',
        alignItems: 'center'
    },
    headerButton: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    tablibOper: {
        display: 'flex',
        flexDirection: 'column'
    },
    filterContainer: {
        display: 'flex',
        flexWrap: "wrap"
    },
    filterItem: {
        display: 'flex',
        flexDirection: 'column'

    },
    filterBtn: {
        display: 'flex',
        flex: 1
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: 10
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    buttonSearch: {
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,

        color: '#FFF',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
        }
    }
}));

function OperationProdList(props) {
    const { etats, handleCurrentView, user: { credentials } } = props
    const [showMoreFilter, setShowMoreFilter] = useState(false)
    const [loading, setLoading] = useState(false)
    const [operList, setOperList] = useState(null)
    const [titre, setTitre] = useState("")
    const [copen, setCppen] = React.useState(false);
    const [ropen, setRopen] = React.useState(false);
    const [currentData, setCurrentData] = React.useState()
    const handleCclickOpen = () => {
        setCppen(true);
    };
    const handleCclose = () => {
        setCppen(false);
    };

    const handleRclickOpen = () => {
        setRopen(true);
    };
    const handleRclose = () => {
        setRopen(false);
    };

    //retour à l'accueil
    const handleBackOpeHome = () => {
        handleCurrentView("HOME")
    }
    const handleClickFilter = () => {
        setShowMoreFilter(!showMoreFilter)
    }
    let grtitre = (etats.hasOwnProperty('mode')) && (etats.mode === "SIN" ? "Liste des déclaration de sinistre" : (etats.mode === "SIN" ? "Liste des demandes de prestation" : "Listes des demandes de modification"))
    const classes = useStyles();
    //Gestion paramètres d'actualisation
    const [filterField, setFilterField] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            partenaire: credentials ? credentials.codereseau : 0,
            zone: [5, 7, 10].includes(credentials.coderole) ? credentials.codezone : 0,
            agence: [5, 10].includes(credentials.coderole) ? credentials.codeequipe : 0,
            groupeoperation: etats['mode'] || null,
            etape: etats['etape'] || null,
            groupe: 'ETAPE'
        });
    const handleFilterChange = (event) => {
        const { name, value } = event.target
        setFilterField({
            [name]: value
        })
    }
    const [zoneList, setZoneList] = useState([])
    const handleZoneList = () => {
        if (filterField.partenaire && filterField.partenaire !== '') {
            getZonesReseau(filterField.partenaire).then(res => {
                if (res) {
                    setZoneList(res)
                }
            })
        } else {
            getZonesNoDispatch().then(res => {
                if (res) {
                    setZoneList(res)
                }
            })
        }
    }

    //Gestion des documents
    const [anchorDocEl, setAnchorDocEl] = React.useState(null);
    const handleDocumentClick = (event,dt) => {
        setCurrentData(dt)
        setAnchorDocEl(event.currentTarget);
    };
    const handleDocumentClose = () => {
        setAnchorDocEl(null);
    };

   
    //récupération des agence/equipe
    const [agenceList, setAgenceList] = useState([])
    const handleAgenceList = () => {
        if (filterField.partenaire && filterField.partenaire !== '') {
            getEquipesReseau(filterField.partenaire).then(res => {
                if (res) {
                    setAgenceList(res)
                }
            })
        } else if (filterField.zone && filterField.zone !== '') {
            getEquipesZones(filterField.zone).then(res => {
                if (res) {
                    setAgenceList(res)
                }
            })
        } else {
            getEquipesNoDispatch().then(res => {
                if (res) {
                    setAgenceList(res)
                }
            })
        }
    }
    useEffect(() => {
        handleZoneList()
    }, [filterField.partenaire])
    useEffect(() => {
        handleAgenceList()
    }, [filterField.zone, filterField.partenaire])
    const handleOperList = () => {
        setLoading(true)
        switch (etats.etape) {
            case 1:
                setTitre("En attente de transmission")
                break;
            case 2:
                setTitre("En attente d'acceptation")
                break;
            case 3:
                setTitre("Validée/Acceptée")
                break;
            default:
                setTitre("")
                break;
        }
        getOperationByUser(filterField).then(res => {
            if (res) {
                setOperList(res)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }).catch(err => {
            console.log(err);
            setLoading(false)
        })
    }
    useEffect(() => {
        handleOperList()
    }, [etats])
    const [topen, setTopen] = useState(false)
    const handleTOpen = () => {
        setTopen(true)
    }
   
    const handleSaveOperation = (values) => {
        if (values) {
            let mesDonnees
            mesDonnees = JSON.stringify(values, null, 2)
            mesDonnees = JSON.parse(mesDonnees)
            setLoading(true)
            updateOperation(mesDonnees).then(async (res) => {
                if (res) {
                    setCppen(false)
                    setSvData(res)
                    setCurrentData(res)
                    setLoading(false)
                    handleAlertOpen("Enregistrement effectué avec succès", true, handleTOpen)
                } else {
                    setLoading(false)
                    handleCclose()
                    handleAlertOpen("Erreur lors de l'enregistrement", false, handleAlertClose)
                }
            }).catch(err => {
                console.log(err);
                setLoading(false)
                handleCclose()
                handleAlertOpen("Erreur lors de l'enregistrement", false, handleAlertClose)
            })
        }
    }
    const [alertInfo, setAlertInfo] = useState({})
    const handleAlertClose = (action = null) => {
        setAlertInfo({ open: false, message: "", succes: false })
        action && action()
    }
    const handleAlertOpen = (message, succes = false, action) => {
        setAlertInfo({ open: true, message: message, succes: succes, action: action })
    }
    const [svData, setSvData] = useState(null)
    const handleShowValidate = (dt) => {
        setSvData(dt)
        props.handleSetData(dt)
        handleCurrentView("VALIDATE")
    }
    const handleRejet = (dt) => {
        setSvData(dt)
        handleRclickOpen()
    }
    
    const handleFicheresume = (dt) => {
        setSvData(dt)
        props.handleSetData(dt)
        handleCurrentView("FICHE")
    }
    
    const [dlopen,setDopen] = useState(false)
    const handleDlopen =()=>{
        setDopen(true)
    }
    const handleDlClose =()=>{
        setDopen(false)
    }
    const handleListeDocuments = () => {
        handleDlopen()
    }
    const handleUploadDoc =(data)=>{
        if(data){
            {/*
          validationPropositionDocument({refs:[data.id]}).then(docRes=>{
            if(docRes){
              setMyErrors({type:'success',message: "Document téléchargé avec succès"})
              setOpenAlert(true)
            }else{
              console.log(docRes);
              setMyErrors({type:'error',message: "Erreur lors du téléchargement"})
              setOpenAlert(true)
            }
          }).catch(err=>{
            console.log(err);
            setMyErrors({type:'error',message: "Erreur lors du téléchargement"})
            setOpenAlert(true)
          })*/}
        }
    }
  
    const handleUploadDocindiv =(data,refDoc)=>{
        if(data && refDoc){
          {/*validationPropositionDocumentIndiv({refs:[data.id]},refDoc).then(docRes=>{
            if(docRes){
              setMyErrors({type:'success',message: "Document téléchargé avec succès"})
              setOpenAlert(true)
            }else{
              setMyErrors({type:'error',message: "Erreur lors du téléchargement"})
              setOpenAlert(true)
            }
          }).catch(err=>{
            console.log(err);
            setMyErrors({type:'error',message: "Erreur lors du téléchargement"})
            setOpenAlert(true)
          })*/}
        }
    }
   
    const [openi,setOpeni] = useState(false)
    const handleOpeni =()=>{
        setOpeni(true)
    }
    const handleClosei =()=>{
        setOpeni(false)
    }
    return (
        <div>
            <AlertPage open={alertInfo['open'] || false} action={alertInfo['action'] || null} message={alertInfo['message'] || ""} succes={alertInfo['succes'] || false} handleAlertOpen={handleAlertOpen} handleAlertClose={handleAlertClose} />
            {copen && <OperationValidate open={copen} handleClickOpen={handleCclickOpen} handleClose={handleCclose} data={svData ? svData : currentData} handleSaveOperation={handleSaveOperation} handleAlertOpen={handleAlertOpen} handleAlertClose={handleAlertClose} />}
            {ropen && <RejetForm open={ropen} handleClose={handleRclose} data={svData ? svData : currentData} handleSaveOperation={handleSaveOperation} />}
            
            {(dlopen && currentData)&&<ImportDocumentList open={dlopen} source='OP' handleOpen={handleDlopen} handleClose={handleDlClose} handleUploadDoc={handleUploadDoc} handleUploadDocindiv={handleUploadDocindiv}  data={currentData} /> }
            {(openi && currentData)&&<ImportDocument open={openi} source='OP' handleOpen={handleOpeni} handleClose={handleClosei} data={currentData} />}
            
            <Paper elevation={0} className={classes.paper}>
                <div className={classes.headerTitle}>
                    <Typography className={classes.heading}>{grtitre}</Typography>
                </div>
                <Divider />
            </Paper>
            <Paper elevation={0} className={classes.paper}>
                <div className={classes.hearderContainer}>
                    <div className={classes.headerFilter} >
                        <Tooltip title="Retour" placement="bottom-start">
                            <IconButton onClick={handleBackOpeHome} color="primary" aria-label="Retour">
                                <ArrowBackIcon fontSize='large' />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={showMoreFilter ? "Masquer le filtrer" : "Plus de filtre"} placement="bottom-start">
                            <IconButton onClick={handleClickFilter} color={showMoreFilter ? "primary" : "secondary"} aria-label="Filtre">
                                <FilterListIcon fontSize='large' />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Paper>
            {showMoreFilter && <Paper elevation={0} className={classes.paper}>
                <LabelledOutline label="Filtres">
                    <div className={classes.filterContainer}>
                        <div className={classes.filterItem}>
                            <TextField
                                id="saisiedu"
                                name="saisiedu"
                                label="Saisie du"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                            <TextField
                                id="saisieau"
                                name="saisieau"
                                label="Au"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                        </div>
                        <Divider orientation='vertical' />
                        <div className={classes.filterItem}>
                            <TextField
                                id="operationdu"
                                name="operationdu"
                                label="Opération du"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                            <TextField
                                id="operationau"
                                name="operationau"
                                label="Au"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                        </div>
                        <Divider orientation='vertical' />
                        <div className={classes.filterItem}>
                            <TextField
                                id="tranmisdu"
                                name="tranmisdu"
                                label="Transmis du"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                            <TextField
                                id="tranmisau"
                                name="tranmisau"
                                label="Au"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                        </div>
                        <Divider orientation='vertical' />
                        <div className={classes.filterItem}>
                            <TextField
                                id="accepterdu"
                                name="accepterdu"
                                label="Accepté du"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                            <TextField
                                id="accepterau"
                                name="accepterau"
                                label="Au"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                        </div>
                        <Divider orientation='vertical' />
                        <div className={classes.filterItem}>
                            <TextField
                                id="rejetdu"
                                name="rejetdu"
                                label="Réjét du"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                            <TextField
                                id="rejetau"
                                name="rejetau"
                                label="Au"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                        </div>
                        <Divider orientation='vertical' />
                        <div className={classes.filterItem}>
                            {([9, 8].includes(credentials.coderole)) && <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Zone / inspection</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="zone"
                                    name="zone"
                                    value={filterField.zone}
                                    onChange={handleFilterChange}
                                    label="Zone / Inspection"
                                >
                                    <MenuItem value="0">
                                        <em>Aucun</em>
                                    </MenuItem>
                                    {(zoneList && zoneList.length !== 0) && (zoneList.map(zone => <MenuItem key={`${zone.id}`} value={zone.id}>{zone.libelle}</MenuItem>))}
                                </Select>
                            </FormControl>}
                            {([7, 8, 9].includes(credentials.coderole)) && <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Agence/Equipe</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="agence"
                                    name="agence"
                                    value={filterField.agence}
                                    onChange={handleFilterChange}
                                    label="Agence/Equipe"
                                >
                                    <MenuItem value="0">
                                        <em>Aucun</em>
                                    </MenuItem>
                                    {(agenceList && agenceList.length !== 0) && agenceList.map(ag => (<MenuItem key={ag.id} value={`${ag.id}`}>{ag.libelleequipe}</MenuItem>))}

                                </Select>
                            </FormControl>}
                        </div>
                        <div className={classes.filterBtn}>
                            <Button variant='outlined' color='primary' className={classes.buttonSearch} onClick={handleOperList} >
                                <RefreshIcon fontSize="large" /> ACTUALISER
                            </Button>
                        </div>
                    </div>
                </LabelledOutline>
            </Paper>}
            {!loading ? (
                <Paper elevation={0} className={classes.paper}>
                    <MaterialTable
                        title={titre}
                        columns={[
                            {
                                field: 'libelletypeoperation',
                                render: rowData => (<div className={classes.tablibOper}>
                                    <Typography variant='h5'>{rowData.libelletypeoperation}</Typography>
                                    <Typography variant='caption'>{`Ref : ${rowData.codeoperation} \n`}</Typography>
                                    <Typography variant='caption'>{`Date :${dayjs(rowData.dateoperation).format('DD/MM/YYYY')}`}</Typography>
                                    {rowData.delaitraitement && <Typography variant='caption'>{`Traitement : ${rowData.delaitraitement} jour(s)`}</Typography>}
                                    {rowData.datefinprevu && <Typography variant='caption'>{`Fin traitement :${dayjs(rowData.datefinprevu).format('DD/MM/YYYY')}`}</Typography>}
                                </div>)

                            },
                            {
                                field: "idproposition",
                                render: rowData => (
                                    <div className={classes.tablibOper}>
                                        <Typography variant='h5'>{rowData.adherent}</Typography>
                                        <Typography variant='caption'>{`#ID : ${rowData.idproposition}`}</Typography>
                                        <Typography variant='caption'>{`Produit :${rowData.libelleproduit}`}</Typography>
                                        {rowData.dateeffet && <Typography variant='caption'>{`Date d' effet :${dayjs(rowData.dateeffet).format('DD/MM/YYYY')}`}</Typography>}
                                    </div>
                                ),
                                filtering: false
                            },

                        ]}

                        data={operList ? operList : []}
                        localization={{
                            toolbar: {
                                searchPlaceholder: 'Trouver une opération',
                                searchTooltip: 'Trouver une opération',
                                exportTitle: 'Exportation',
                                exportAriaLabel: 'Exportation',
                                exportCSVName: 'Exporter en Excel',
                                exportPDFName: 'Exporter en PDF',
                            },
                            body: {
                                emptyDataSourceMessage: 'Aucune donnée trouvée !'
                            },
                            pagination: {
                                labelRowsSelect: 'Ligne(s)',
                                labelDisplayedRows: '{count} sur {from}-{to}',
                                firstTooltip: 'Prémière page',
                                previousTooltip: 'Précédent',
                                nextTooltip: 'Suivant',
                                lastTooltip: 'Dernière page'
                            }
                        }}
                        options={{
                            filtering: true,
                            selection: true,
                            selectionProps: rowData => ({
                                disabled: rowData.etape === 3,
                                color: 'primary'
                            }),
                            exportButton: true,
                            actionsColumnIndex: -1,
                            exportCsv: (columns, data) => exportToExcel(columns, data, "LLV-Liste-opération"),
                            searchFieldStyle: {
                                fontSize: 18,
                                width: '100%',
                                height: 50,
                            },

                            pageSize: 20,
                            pageSizeOptions: [20, 40, 80, 100, 200, 300, 400, 500, 1000]
                        }}

                        actions={[
                            {
                                icon: () => <AttachFileIcon />,
                                tooltip: <Typography variant='h6'>Documents</Typography>,
                                position: "row",
                                onClick:(event,rowData)=> handleDocumentClick(event,rowData)
                            },
                            {
                                icon: () => <AssignmentIcon />,
                                tooltip: <Typography variant='h6'>Détails</Typography>,
                                position: "row",
                                onClick: (event, rowData) => handleFicheresume(rowData),
                            },
                            etats.etape === 2 && {
                                icon: () => <CheckIcon color="primary" />,
                                tooltip: <Typography variant='h6'>Valider</Typography>,
                                position: "row",
                                onClick: (event, rowData) => handleShowValidate(rowData),
                            },
                            etats.etape === 2 && {
                                icon: () => <CancelScheduleSendIcon color="secondary" />,
                                tooltip: <Typography variant='h6'>Rejeter</Typography>,
                                position: "row",
                                onClick: (event, rowData) => handleRejet(rowData),
                            }
                        ]}
                    />
                    <Menu
                        id="documents-menu"
                        anchorEl={anchorDocEl}
                        keepMounted={true}
                        open={Boolean(anchorDocEl)}
                        onClose={handleDocumentClose}
                    >
                        <MenuItem onClick={()=>handleListeDocuments()}>

                            <ListItemIcon>
                                <InsertDriveFileIcon color='primary' fontSize='large' />
                            </ListItemIcon>
                            <Typography variant="inherit">Liste des documents attachés</Typography>
                        </MenuItem>
                        <MenuItem onClick={handleOpeni}>
                            <ListItemIcon>
                                <AttachmentIcon color='primary' fontSize='large' />
                            </ListItemIcon>

                            <Typography variant="inherit">Joindre un documents</Typography>
                        </MenuItem>
                    </Menu>
                </Paper>
            ) : (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress color="secondary" /></div>)}
        </div>
    )
}
const cetape = ['Annulée', 'En attente de tranmission', 'En attente de validation', 'Acceptée', 'Réjétée']
const cetattrt = ["Aucune action", "En attente de traitement", "Début du Traitement", "Fin du traitement", "Soumission", "Chèque/OV", "Chèque/OV signé", "Chèque OV remis", "Opération archivé"]
const cetatmod = ["Aucune action", "En attente de traitement", "Début du Traitement", "Fin du traitement"]

const updateField = (field, data) => (dispatch) => dispatch(change('addOper', field, data))
const mapPropsActions = {
    updateField,
}
const mapStateToProps = (state) => ({
    user: state.user,
});
export default connect(mapStateToProps, mapPropsActions)(reduxForm({ form: 'addOper', destroyOnUnmount: false })(OperationProdList))
