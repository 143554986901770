import React, { useState } from 'react'
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from '@material-ui/lab'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import TextField from '@material-ui/core/TextField'
import styles from "assets/jss/llv//components/customInputStyle.js";
const useStyles = makeStyles(styles);

export default function  CustomAgenceSelect(props)  {
    
    const classes = useStyles();
    const {formControlProps,label,id,name,labelProps,inputProps,options,error,success,children,value,input,...custom} = props
    
    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
      });

      const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true
      });

      const marginTop = classNames({
        [classes.marginTop]: label === undefined
      });

    const [currentValue, setCurrentValue] = useState(input?input.value:value)
    
    const handleChange =(event)=>{
        if(event){
            setCurrentValue(event.target.value)
            if(input){
              input.onChange(event.target.value)
            }else {
              props.onChange(event)
            }
        }
    }
    
    const handleSelect =(e,v)=>{
     
        const target ={}
        target.name = e
        target.value = v?v.NOM_LONG:""
        
        setCurrentValue(v?v.NOM_LONG:"")
        if(input){
          input.onChange(v?v.NOM_LONG:"")
        }else{
          props.onChange({target})
        }
    }

    return (
        <FormControl
            {...formControlProps}
            className={formControlProps.className + " " + classes.formControl}
        >
       
        <Autocomplete
            options={options}
            getOptionLabel={option => option.NOM_LONG}
            inputValue ={currentValue}
            onInputChange={handleChange}
            onChange ={(e,v)=>handleSelect(name,v)}
            renderInput={params => (
                <TextField 
                    {...params}
                    id={id}
                    name={name}
                    variant="outlined"
                    label={label} 
                    fullWidth
                    className={classes.labelClasses}
                    InputLabelProps={{
                        shrink: true,
                        
                    }}
                    {...custom}
                />
            )}
        />
         {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
        
    )
}

CustomAgenceSelect.propTypes = {
    label: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool
  };