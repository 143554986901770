import React, { useReducer,useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from "components/CustomInput/CustomInput.js";
import {updatePret} from 'redux/actions/apiActions'
import Button from '@material-ui/core/Button';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import dayjs from 'dayjs';
import {reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import CustomAlert from 'components/CustomAlert/CustomAlert'

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    assureInfoRow:{
      display:'flex',
      flexDirection:'row',
      justifyContent:'flex-between'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    
  }));
  
 function DialogBox ({data,dopen,dinfo:{dTitle,dmode,dTexteButton},...props}){
    const classes = useStyles();
    const [inputField, setInputField] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      moftifrejet:null
    });
    const [myAlerte, setMyAlerte]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
    aMessage:null,
    aopen:false,
    amode:null,
    });
    const handleAlerte = (aMessage,aopen,amode)=>{
    setMyAlerte({
        ['message']:aMessage,
        ['open']:aopen,
        ['mode']:amode
        })
    }
    const handleChange =(event)=>{
      const  {name,value}=event.currentTarget
      setInputField({
        [name]:value
      })
    }
    const handleClickOpen = () => {
      props.handleDopen(true);
    };
    const handleClose = () => {
      props.handleDopen(false);
    };
    const handleRejet = ()=>{
      const mydata ={
        id:data.id,
        typeop:"RJ",
        moftifrejet:inputField.moftifrejet,
        daterejet:dayjs().format('YYYY-MM-DD'),
        etat:0,
        modifiele:dayjs().format('YYYY-MM-DD'),
      }
      props.updatePret(mydata).then(reponse=>{
        if(reponse){
          handleAlerte("Opération effecturé avec succès",true,"success")
          handleClose()
        }else{
          handleAlerte("Erreur lors de l'opération",true,"error")
        }
      })
    }
    return(
      <Dialog
          open={dopen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='md'
        >
          <DialogTitle id="alert-dialog-title">{dTitle}</DialogTitle>
          <DialogContent>
          <CustomAlert open={myAlerte.open} message={myAlerte.message} mode={myAlerte.mode} />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <CustomInput
                          required
                          label="Motif"
                          id="moftifrejet"
                          name="moftifrejet"
                          formControlProps={{
                              fullWidth: true
                          }}
                          variant="outlined"
                        onChange ={handleChange}
                      />
                </GridItem>
              </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Abandonner
            </Button>
            <Button onClick={dmode===1&&handleRejet} disabled={inputField.moftifrejet===null} size="large"  color="primary" variant="contained" autoFocus>
              {dTexteButton}
            </Button>
          </DialogActions>
        </Dialog>
    )
  }
  const mapStateToProps = (state) => ({
    api: state.api,
  });
const mapActionsToProps = {
  updatePret
}
  
  export default connect(mapStateToProps,mapActionsToProps)(reduxForm({form:'addPret',destroyOnUnmount:false,})(DialogBox))