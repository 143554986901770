import React, { useReducer,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from "@material-ui/core/Typography";
import MaterialTable from 'material-table';
import {connect} from 'react-redux'
import Button from 'components/CustomButtons/Button'
import {Add} from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';

import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInput'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import {updatePrivilege,getPrivilegeModule,deletePrivilege} from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));

  
function PrivilegesModule(props){
    const {moduleData,eopen,handleCloseEquipe}=props
    const classes = useStyles();
    const theme = useTheme();
    const [privilegesModule,setPrivilegesModule]=React.useState([])
    const [editer,setEditer]=React.useState(false)
    const handleCurrentPrivilege =(row)=>{
        setInputField({
            ['codeprivilege']:row.codeprivilege,
            ['libelle']:row.libelle,
            ['codemodule']:moduleData.id,
            ['idprivilege']:row.id
        })
        handleEditer(true)
    }
    const handleEditer =(etat)=>{
        setEditer(etat)
    }

    useEffect(() => {
        getPrivilegeModule(moduleData.id).then(data=>{if(data){setPrivilegesModule(data);}})
    }, [])
    
  const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
    codeprivilege:"",
    libelle:"",
    codemodule:moduleData.id,
    idprivilege:""
    });
 
    
  const handleChange = (event)=>{
    const {name,value} = event.target
    setInputField({
        [name]:value
    })
  }
  const handleSavePrivilege =()=>{
    if(inputField.codemodule==='')return
    if(inputField.codeprivilege.trim()==='')return
    if(inputField.libelle.trim()==='')return
    updatePrivilege(inputField.idprivilege,inputField).then(data=>{
        if(data){
            getPrivilegeModule(moduleData.id).then(data=>{if(data){setPrivilegesModule(data);}})
            handleEditer(false)
        }else{
            return false
        }
    }).catch(err=>{
        console.log(err);
        return
    })
  }
const handleDeletePrivilege =(row)=>{
    deletePrivilege(row.id).then(datapr=>{
        if(datapr){
            getPrivilegeModule(moduleData.id).then(data=>{if(data){setPrivilegesModule(data);}})
        }
    })
}
    return (
        <Dialog
            maxWidth='md'
            open={eopen} 
            disableBackdropClick 
            disableBackdropClick
            fullWidth
            onClose={handleCloseEquipe} 
            aria-describedby="alert-dialog-description"
            aria-labelledby="form-dialog-title">
            <DialogTitle id="alert-dialog-title" > 
                <div style={{display:'flex',flexDirection:'row', justifyContent:'space-between'}}>
                    <Typography variant='h5'> Droits & privilèges  - {moduleData.libelle}</Typography>
                    <IconButton edge="start" color="inherit" onClick={handleCloseEquipe} aria-label="close"><CloseIcon /></IconButton>
                </div>
                </DialogTitle>
            <DialogContent>
                {editer?(<GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4} lg={4}>
                                <CustomInput
                                    required
                                    autoFocus
                                    variant="outlined"
                                    id="codeprivilege"
                                    name='codeprivilege'
                                    label="code"
                                    type="text"
                                    value = {inputField.codeprivilege}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={handleChange}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8} lg={8}>
                                <CustomInput
                                    autoFocus
                                    required
                                    variant="outlined"
                                    id="libelle"
                                    name ='libelle'
                                    onChange={handleChange}
                                    label="Libelle"
                                    type="text"
                                    value = {inputField.libelle}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6} lg={6}><Button color="default" onClick={()=>handleEditer(false)}  style={{width:'100%',marginBottom:20}}> Annuler</Button></GridItem>
                            <GridItem xs={12} sm={6} md={6} lg={6}><Button color="primary" onClick={handleSavePrivilege} disabled={(inputField.codeprivilege==='' || inputField.libelle==='' || inputField.codemodule==='')} style={{width:'100%',marginBottom:20}}> Enregistrer</Button></GridItem>
                        </GridContainer>
                        
                    </GridItem>
                </GridContainer>):(
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <MaterialTable
                            columns={[
                            { title: '#', field: 'id',hidden:true },
                            { title: 'Code', field: 'codeprivilege',hidden:true},
                            { title: 'Libelle', field: 'libelle'}
                            ]}
                            data={privilegesModule}
                            localization={{
                            toolbar:{
                                searchPlaceholder:'Rechercher',
                                searchTooltip:'Rechercher'
                            },
                            body: {
                                emptyDataSourceMessage: 'Aucune donnée trouvée !'
                            },
                            pagination: {
                                labelRowsSelect: 'Ligne(s)',
                                labelDisplayedRows: '{count} sur {from}-{to}',
                                firstTooltip: 'Prémière page',
                                previousTooltip: 'Précédent',
                                nextTooltip: 'Suivant',
                                lastTooltip: 'Dernière page'
                            }
                            }}
                            
                            options={{
                                exportButton: true,
                                header:false,
                                actionsColumnIndex: -1,
                                toolbar:false
                            }}
                            actions={[
                                {
                                  icon: 'edit',
                                  tooltip: 'modifier',
                                  onClick: (event, rowData) => handleCurrentPrivilege(rowData)
                                },
                                {
                                    icon: 'delete',
                                    tooltip: 'Delete User',
                                    onClick: (event, rowData) => handleDeletePrivilege(rowData)
                                  }
                              ]}      
                                
                        />
                    </GridItem>
                </GridContainer>
                )}
                
            </DialogContent>
      </Dialog>
    )
}
PrivilegesModule.propTypes = {
    zoneData: PropTypes.object.isRequired,
    eopen: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    settings: state.settings,
  });
  const mapActionsToProps = {}
export default connect(mapStateToProps,mapActionsToProps)(PrivilegesModule)