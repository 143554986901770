import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
// MUI Stuff
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
// Redux stuff
import { connect } from 'react-redux';
import { loginUser } from '../../redux/actions/userActions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { TramOutlined } from '@material-ui/icons';
import Footer from 'components/Footer/Footer'
import logo from 'assets/images/logoYnovGreen.png'
const styles = (theme) => ({
  ...theme.spread,
  root: {
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center'

  },


  paper: {
    margin: theme.spacing(8, 4),
    backgroundColor: 'rgba(255, 255, 255,0.8)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width:'50%',
    borderRadius:10,
    padding:20,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
    [theme.breakpoints.up('md')]: {
      width:'50%',
      margin: theme.spacing(1, 1),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      width:'100%',
      margin: theme.spacing(1, 1),
    },
  },
 

  form: {
    marginTop: 20,
    marginBottom:20,
    width:'100%'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: 60,
    borderRadius:10,
    color: 'white',
    [theme.breakpoints.down('md')]: {
      height: 40,
    },
  },
  textField: {
    height: 60,
    fontSize: 20,
    marginBottom:20,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  logo:{
    maxWidth: '30%',
    margin:20,
    [theme.breakpoints.down('md')]: {
      maxWidth: '40%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth:'50%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth:'100%',
    },
  }
});


class Login extends React.Component {
  state = {
    login: '',
    password: '',
    rememberMe: false,
    errors: null,
    loading: false,
    open: false
  }


  handleClickOpen = () => {
    this.setState({
      open: true
    })
  };

  handleClose = () => {
    this.setState({
      open: false
    })
  };

  //redirection après connexion
  redirect = (redirValue) => {
    this.props.history.push(`/${redirValue}`)
  }

  handleConnexion = (event) => {
    event.preventDefault();

    this.setState({
      loading: true
    })
    if (this.state.login === '') {
      this.handleClickOpen()
      this.setState({
        loading: false,
        errors: { login: true, text: "Veuillez renseigner un login valide" }
      })
      return
    }
    if (this.state.password === '') {
      this.handleClickOpen()
      this.setState({
        loading: false,
        errors: { password: true, text: "Veuillez renseigner un mot de passe valide" }
      })

      return
    }
    const userData = {
      login: this.state.login,
      password: this.state.password
    };
    this.props.loginUser(userData).then(resData => {
      if (resData) {
        this.setState({
          loading: false,
        })
        this.redirect(resData)
      } else {
        this.setState({
          loading: false,
          errors: { connexion: true, text: this.props.ui.errors.error },
          open: TramOutlined
        })
      }
    }).catch(err => {
      console.log(err);
      this.setState({
        loading: false,
        errors: { connexion: true, text: "Erreur inconnue lors de la connexion" },
        open: true
      })
    })
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const {
      classes, ui
    } = this.props
    const { errors, loading } = this.state;
    // const isvalide = (this.state.login && this.state.password)
    return (
      <div className={classes.root}>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"ERREUR"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Alert variant="filled" severity="error">
                {errors && errors.text}
              </Alert>
            </DialogContentText>
          </DialogContent>
          <DialogActions>

            <Button onClick={this.handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Paper className={classes.paper}>
           
            
              <img 
                className={classes.logo}
                src={logo} 
                title="logo" 
                alt="logo" 
              />
       

            <form className={classes.form} onSubmit={this.handleConnexion}>
              <TextField
                id="login"
                name="login"
                type="text"
                label="Nom d'utilisateur (email,ID,Code)"
                className={classes.textField}
                helperText={errors && errors.login}
                error={(errors && errors.login) ? true : false}
                value={this.state.login}
                onChange={this.handleChange}
                variant="outlined"
                fullWidth
              />
              <TextField

                id="password"
                name="password"
                type="password"
                label="Mot de passe"
                className={classes.textField}
                helperText={errors && errors.password}
                error={(errors && errors.password) ? true : false}
                value={this.state.password}
                onChange={this.handleChange}
                variant="outlined"
                fullWidth
              />
              <Button
                type='submit'
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                SE CONNECTER
              </Button>

            </form>
        </Paper>

      <Footer className={classes.footer} />
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired

};

const mapStateToProps = (state) => ({
  user: state.user,
  ui: state.UI
});
const mapActionsToProps = {
  loginUser
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(Login));




