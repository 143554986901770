import React,{useEffect,useReducer} from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { reduxForm,change,getFormValues } from 'redux-form';
import {connect,useDispatch} from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { green, pink } from '@material-ui/core/colors';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import { Divider } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import {getProducteurData} from 'redux/actions/apiActions'
import {formatNumber} from 'application'
import { IconButton } from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) =>({
    root: {
      marginBottom:20,
      '& :hover':{
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        padding: '10 30px',
        textDecoration:'none'
      }
    },
    chip: {
        margin: theme.spacing(0.5),
        fontSize:20
      },
    media: {
      height: 140,
    },
    avatar: {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        alignSelf:'center'
      },
      pink: {
        color:theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },
      green: {
        color: '#fff',
        backgroundColor: green[500],
      },
      large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
      },
      line:{
          marginBottom:20
      },
      buttonDetails:{
        width:'100%',
        borderWidth:1,
        borderColor:theme.palette.secondary.main,
        backgroundColor:'#fff',
        color:theme.palette.secondary.main,
        '&:hover':{
          borderColor:theme.palette.primary.main,
        }
      },
  }));
function ProdFonctions(props){
    const classes = useStyles();
    const {worker:{producteurAllData},ui:{loading}} =props
    const dispatch = useDispatch()
    const [producteurData,setProducteurData]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
        propoEnAttente:"",
        propoEnAttenteDteials:"",
        propoAccepterNM:"",
        propoAccepterNMDetails:'',
        propoAccepterM:"",
        propoAccepterMdetails:'',
        propoRejeter:"",
        propoRejeterDetails:'',
        total:0 
    });
    const displayProducteurDetail=(mode)=>{
        let titre=""
        switch (mode) {
            case 'ENATTENTE':
                dispatch({type:"SET_PROD_CURRENT_DETAIL",payload:producteurData.propoEnAttenteDteials})
                titre="Liste des propositions saisies non transmis"
                break;
            case 'ACCEPTERNM':
                dispatch({type:"SET_PROD_CURRENT_DETAIL",payload:producteurData.propoAccepterNMDetails})
                titre="Liste des propositions en attente de validation"
                break;
            case 'ACCEPTERM':
                dispatch({type:"SET_PROD_CURRENT_DETAIL",payload:producteurData.propoAccepterMdetails})
                titre="Liste des propositions acceptées migré"
                break;
            case 'REJETER':
                dispatch({type:"SET_PROD_CURRENT_DETAIL",payload:producteurData.propoRejeterDetails})
                titre="Liste des propositions réjétées"
                break;
            default:
                break;
        }
        props.history.push('/producteur/proposition-details',{mode:mode,titre:titre})
    }

    const handleGetDataProducteur =()=>{
        dispatch({type:"LOADING_UI"})
        getProducteurData().then(resData=>{
            if(resData){
                dispatch({type:"SET_PRODUCTEUR_DATA",payload:resData.dataProduction})
                dispatch({type:"STOP_LOADING_UI"})
            }else{
                dispatch({type:"STOP_LOADING_UI"})
            }
        }).catch(err=>{
            console.log(err);
            dispatch({type:"STOP_LOADING_UI"})
        })
    }
    useEffect(() => {
        if(!producteurAllData){
            handleGetDataProducteur()
        }
      
    }, [])

    useEffect(()=>{
        if(producteurAllData){
        
            const {propoEnAttente,propoEnAttenteDetails,propoAccepterNM,propoAccepterNMDetails,propoAccepterM,propoAccepterMDetails,propoRejeter,propoRejeterDetails} = producteurAllData
            setProducteurData({
                ['propoEnAttente']:propoEnAttente,
                ['propoEnAttenteDteials']:propoEnAttenteDetails,
                ['propoAccepterNM']:propoAccepterNM,
                ['propoAccepterNMDetails']:propoAccepterNMDetails,
                ['propoAccepterM']:propoAccepterM,
                ['propoAccepterMdetails']:propoAccepterMDetails,
                ['propoRejeter']:propoRejeter,
                ['propoRejeterDetails']:propoRejeterDetails
            });
        }
    },[producteurAllData])
  
    return(
        <div>
             <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div style={{display:'flex',justifyContent:"space-between"}}>  
                        <Typography varaiant='h2' component='h2'>GESTION DES PROPOSITIONS</Typography>
                        <IconButton onClick={handleGetDataProducteur}><RefreshIcon fontSize="large" /></IconButton>
                    </div>                  
                    <Divider light className={classes.line} />
                    {!loading?(<GridContainer>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                           
                                <CardActionArea onClick ={()=>displayProducteurDetail('ENATTENTE')}>
                                    <CardContent>
                                        <div className={classes.avatar}>
                                            {(producteurData.propoEnAttente!=='' && producteurData.propoEnAttente!==0)?<Chip
                                        label={formatNumber(producteurData.propoEnAttente.Nombre)}
                                        className={classes.chip}
                                        />:<Avatar aria-label="recipe" className={clsx(classes.large)}><HourglassFullIcon /></Avatar>
                                    }
                                            
                                        </div>
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                        Saisie non transmis
                                        </Typography>
                                        <Button className={classes.buttonDetails}>Voir le detail</Button>
                                    </CardContent>
                                </CardActionArea>
                           
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea  onClick={event => displayProducteurDetail('ACCEPTERNM')}>
                                    <CardContent>
                                   
                                        <div className={classes.avatar}>
                                            {(producteurData.propoAccepterNM!=='' && producteurData.propoAccepterNM!==0)?<Chip
                                            label={formatNumber(producteurData.propoAccepterNM.Nombre)}
                                            className={classes.chip}
                                            color="primary"
                                            />:<Avatar aria-label="recipe" className={clsx(classes.large)}><ThumbUpIcon /></Avatar>}
                                        </div>
                                        
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                            En attentes de validations
                                        </Typography>
                                        <Button className={classes.buttonDetails}>Voir le detail</Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea  onClick={event => displayProducteurDetail('ACCEPTERM')}>
                                    <CardContent>
                                        <div className={classes.avatar}>
                                            {(producteurData.propoAccepterM!=='' && producteurData.propoAccepterM!==0)?<Chip
                                            label={formatNumber(producteurData.propoAccepterM.Nombre)}
                                            className={classes.chip}
                                            />:<Avatar aria-label="recipe" className={clsx(classes.large)}><DoneIcon /></Avatar>}
                                        </div>
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                            Acceptées migrées
                                        </Typography>
                                        <Button className={classes.buttonDetails}>
                                               Voir le detail
                                        </Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea onClick={event => displayProducteurDetail('REJETER')}>                                
                                    <CardContent>
                                        <div className={classes.avatar}>
                                            {(producteurData.propoRejeter!=='' && producteurData.propoRejeter!==0)?<Chip
                                            label={formatNumber(producteurData.propoRejeter.Nombre)}
                                            className={classes.chip}
                                            />:<Avatar aria-label="recipe" className={clsx(classes.large)}><CancelIcon /></Avatar>}
                                        </div>
                                    
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                        Propositions réjétées
                                        </Typography>
                                        <Button className={classes.buttonDetails}>Voir le detail</Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                    </GridContainer>):(<div style={{display:"flex",alignItems:'center',justifyContent:'center'}}><CircularProgress size={60} color="primary" /></div>)}
                </GridItem>
        </div>
    )
}


const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapPropsActions ={
    updateField,
}
const mapStateToProps = (state) => ({
    worker:state.worker,
    api: state.api,
    user:state.user,
    ui:state.UI,
    data:getFormValues('adForm')(state)
  });

  export default connect(mapStateToProps,mapPropsActions)(reduxForm({destroyOnUnmount: false,})(ProdFonctions))